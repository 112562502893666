import axios from "axios";
const config = require(`../../environment/development`).config;
let cancelTokenQuote;
let cancelTokenOrder;

export const sendOrderCreationMailToAdmin = async (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-type": "application/json",
      };

      let response = await axios.post(
        `${config.backEnd}/mail/order-creation-to-admin`,
        data,
        { headers: header }
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const fetchQuotesForParameters = async (token, data, isCancellable) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelTokenQuote != typeof undefined && isCancellable) {
        cancelTokenQuote.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-type": "application/json",
      };

      cancelTokenQuote = axios.CancelToken.source();

      const response = await axios.get(
        `${config.backEnd}/fetch-quote-for-parameters`,
        {
          cancelTokenQuote: cancelTokenQuote.token,
          headers,
          params: data,
        }
      );

      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};

export const fetchOrdersForParameters = async (token, data, isCancellable) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof cancelTokenOrder != typeof undefined && isCancellable) {
        cancelTokenOrder.cancel();
      }

      let headers = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-type": "application/json",
      };

      cancelTokenOrder = axios.CancelToken.source();

      const response = await axios.get(
        `${config.backEnd}/fetch-orders-for-parameters`,
        {
          cancelTokenOrder: cancelTokenOrder.token,
          headers,
          params: data,
        }
      );

      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
};



export const changeDefaultMeasurmentUnitUser = async (token, userId, defaultUnit) => {
  try {
    let headers = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-type": "application/json",
    };

    const response = await axios.put(`${config.backEnd}/change-default-measurment-unit/${userId}`, {
      defaultUnit
    }, { headers });
    return response.data;
  } catch (error) {
    return false;
  }
}

export const markDefaultAddress = async (token, addressId, userId) => {
  try {
    let headers = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-type": "application/json",
    };
    const response = await axios.put(`${config.backEnd}/mark-default-address/${addressId}`, {
      userId,
    }, {
      headers,
    });
    return response.data;

  } catch (error) {
    return false;
  }
};

export const submitTargetCost = async (id, targetCost) => {
  try {
    const response = await axios.put(`${config.backEnd}/submit-target-cost/${id}`, { targetCost });
    return response.data;
  } catch (error) {
    return false;
  }
}

export const checkCreateUser = async (email) => {

  if (cancelTokenOrder) {
    cancelTokenOrder.cancel();
  }
  cancelTokenOrder = axios.CancelToken.source();
  try {
    const response = await axios.post(`${config.backEnd}/check-create-user`, { email }, {
      cancelToken: cancelTokenOrder.token,
    });
    return response.data;
  } catch (error) {
    return false;
  }
}


export const getUserComapnyInfo = async (id) => {
  try {
    const response = await axios.get(`${config.backEnd}/get-company-info/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
}