import React, { useState } from "react";
import loader from "../images/loader.gif";

export const  Loading = () => {
  return (
    <>
      <div className="try-load">
        <img src={loader} />
      </div>
    </>
  );
};
