import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import fileSaver from "file-saver";
import { extractURLAndEncodedFilename } from '../../utils/helper';
import { DownloadIcon } from '../Icons';
import { CrossIcon } from '../GlobalProjectNameComponent';

function ConsignmentPhotoViewer({ images, altText,handleConsignmentFileDelete, serverType }) {
    const [show, setShow] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    let email = localStorage.getItem('email');

    const handleClose = () => setShow(false);
    const handleShow = (index) => {
        setCurrentIndex(index);
        setShow(true);
    };

    const handleNext = () => {
        setCurrentIndex((currentIndex + 1) % images.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((currentIndex - 1 + images.length) % images.length);
    };

    const handleImageDownload = (index) => {
        if (images[index].name && (images[index] instanceof File || images[index] instanceof Blob)) {
            const url = URL.createObjectURL(images[index]);
            const downloadName = images[index].name;
            const link = document.createElement('a');
            link.href = url;
            link.download = downloadName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            let url = "";
            if(serverType==='supplier'){
                url = `${process.env.REACT_APP_SUPPLIER_BACKEND}/` + images[index].path;
            }else{
                url = `${process.env.REACT_APP_API_ENDPOINT}/` + images[index].path;
            }
            download(images[index].originalname, url)
        }
    }

    const download = async (name, url) => {
        const data = extractURLAndEncodedFilename(url);
        let fileUrl = `${data.url}/${data.encodedFilename}`;
        fileSaver.saveAs(fileUrl, name);
    };

    const handleDelete = (currentIndex)=>{
        if(images.length==1){
            setShow(false)
        }
        handleConsignmentFileDelete(currentIndex);
        if(currentIndex>=1){
            setCurrentIndex(currentIndex-1)
        }else{
            setCurrentIndex(images.length-1)
        }
    }

    return (
        <div className=' flex justify-start items-center gap-x-5 flex-wrap'>
            {images?.map((image, index) => (
                image?.filename ? (
                    <img
                        key={index}
                        src={serverType=='supplier'? 
                             `${process.env.REACT_APP_SUPPLIER_BACKEND}/public/uploads/` +image?.filename
                             :`${process.env.REACT_APP_API_ENDPOINT}/public/uploads/` +image?.filename
                        }
                        alt={altText}
                        onClick={() => handleShow(index)}
                        height="100px"
                        width="150px"
                        style={{
                            margin: "5px 3px",
                        }}
                    />
                ) : (
                    <img
                        key={index}
                        src={(image instanceof File || image instanceof Blob) ? URL.createObjectURL(image) : ""}
                        alt={altText}
                        onClick={() => handleShow(index)}
                        height="100px"
                        width="150px"
                        style={{
                            margin: "5px 3px",
                        }}
                    />
                )
            ))}

            <Modal
                show={show}
                onHide={handleClose}
                centered
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{fontSize:'15px'}}>Consignment Photo Name:  {images[currentIndex]?.filename ? images[currentIndex]?.originalname : images[currentIndex]?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='flex justify-center items-center gap-x-1'>
                    {images && images[currentIndex]?.filename ? (
                        <img
                            src={serverType==="supplier"?
                            `${process.env.REACT_APP_SUPPLIER_BACKEND}/public/uploads/` +images[currentIndex]?.filename
                            :`${process.env.REACT_APP_API_ENDPOINT}/public/uploads/` +images[currentIndex]?.filename
                            }
                            alt={altText} style={{ height: "60vh", maxWidth: '100%' }}
                        />
                    ) : (
                        <img
                            // src={(image instanceof File || image instanceof Blob)?URL.createObjectURL(image):""}
                            src={images && (images[currentIndex] instanceof File || images[currentIndex] instanceof Blob) && URL.createObjectURL(images[currentIndex])}
                            alt={altText} style={{ height: "60vh", maxWidth: '100%' }}
                        />
                    )}

                    {/* <img src={images[currentIndex]} alt={altText} style={{ height: "60vh", maxWidth: '100%' }} /> */}
                    <div style={{ position: 'absolute', top: '45%', left: '2%', cursor: 'pointer', fontSize: '100px', backgroundColor: 'lightgray', padding: '0px 10px', opacity: 0.5 }} onClick={handlePrevious}>
                        <span style={{ opacity: 1 }}>&#8249;</span>
                    </div>
                    <div style={{ position: 'absolute', top: '45%', right: '2%', cursor: 'pointer', fontSize: '100px', backgroundColor: 'lightgray', padding: '0px 10px', opacity: 0.5 }} onClick={handleNext}>
                        <span style={{ opacity: 1 }}>&#8250;</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <button className='req-quote-btn flex justify-center items-center gap-x-2 ' onClick={() => handleImageDownload(currentIndex)}>
                        <DownloadIcon />
                        Download
                    </button>
                    {email.split("@")[1] == "8xparts.com" ?
                        <>
                            <button className='req-quote-btn' style={{ background: '#DC712B' }} onClick={() => handleDelete(currentIndex)}>
                                Delete
                            </button>
                        </> : <></>}

                    <button className='req-quote-btn flex justify-center items-center gap-x-2 ' style={{ background: 'gray' }} onClick={handleClose}>
                    <CrossIcon additionalClasses='h-5 !text-white'/>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ConsignmentPhotoViewer;
