import { createContext, useContext, useReducer } from "react";

const adminContext = createContext();

const initialState = {
  isSideBarHidden: false,
  quoteActiveFilter: "all-quote",
  orderActiveFilter: "all-order",
  rfqActiveFilter: "all-rfq",
  invoiceActiveFilter: "all-invoice",
  billActiveFilter: 'all-bill',
  purchaseOrderActiveFilter: "all-po",
  dashboardFilter: "customer",
  material: [],
  subMaterial: [],
  surfaceFinish: [],
  surfaceTreatment: [],
  layers: [],
  partMarking: [],
  tolerance: [],
  isQuotationMenuActive: true,
  isOrderMenuActive: true,
  isMasterMenuActive: false,
  isUserMenuActive: false,
  isCustomerMenuActive: false,
  isSupplierMenuActive: false,
  isZohoMenuActive: false,
  isInvoiceBillingMenuActive: false,
  isCncMasterActive: false,
};

export const ADMIN_ACTIONS = {
  TOGGLE_SIDEBAR: "toggle-sidebar",
  UPDATE_QUOTE_ACTIVE_FILTER: "update-quote-active-filter",
  UPDATE_ORDER_ACTIVE_FILTER: "update-order-active-filter",
  UPDATE_RFQ_ACTIVE_FILTER: "update-rfq-active-filter",
  UPDATE_INVOICE_ACTIVE_FILTER: "update-invoice-active-filter",
  UPDATE_BILL_ACTIVE_FILTER: "update-bill-active-filter",
  UPDATE_PURCHASE_ORDER_ACTIVE_FILTER: "update-purchase-order-active-filter",
  UPDATE_DASHBOARD_FILTER: "update-dashboard-filter",
  UPDATE_MATERIAL: "update-material",
  UPDATE_SUBMATERIAL: "update-submaterial",
  UPDATE_SURFACE_FINISH: "update-surface-finish",
  UPDATE_SURFACE_TREATMENT: "update-surface-treatment",
  UPDATE_LAYER: "update-layer",
  UPDATE_PART_MARKING: "update-marking",
  UPDATE_TOLERANCE: "update-tolerance",
  UPDATE_MASTERS: "update-masters",
  UPDATE_QUOTATION_MENU: "update-quotation-menu",
  UPDATE_ORDER_MENU: "update-order-menu",
  UPDATE_MASTERS_MENU: "update-master-menu",
  UPDATE_USER_MENU: "update-user-menu",
  UPDATE_CUSTOMER_MENU: "update-customer-menu",
  UPDATE_SUPPLIER_MENU: "update-supplier-menu",
  UPDATE_ZOHO_MENU: "update-zoho-menu",
  UPDATE_INVOICE_BILLING_MENU: "update-invoice-billing-menu",
  UPDATE_CNC_MASTER: "UPDATE_CNC_MASTER",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ADMIN_ACTIONS.TOGGLE_SIDEBAR:
      return { ...state, isSideBarHidden: !state.isSideBarHidden };
    case ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER:
      return { ...state, quoteActiveFilter: action.payload };
    case ADMIN_ACTIONS.UPDATE_ORDER_ACTIVE_FILTER:
      return { ...state, orderActiveFilter: action.payload };
    case ADMIN_ACTIONS.UPDATE_RFQ_ACTIVE_FILTER:
      return { ...state, rfqActiveFilter: action.payload };
    case ADMIN_ACTIONS.UPDATE_INVOICE_ACTIVE_FILTER:
      return { ...state, invoiceActiveFilter: action.payload };
    case ADMIN_ACTIONS.UPDATE_BILL_ACTIVE_FILTER:
      return { ...state, billActiveFilter: action.payload };
    case ADMIN_ACTIONS.UPDATE_PURCHASE_ORDER_ACTIVE_FILTER:
      return { ...state, purchaseOrderActiveFilter: action.payload };
    case ADMIN_ACTIONS.UPDATE_MATERIAL:
      return { ...state, material: action.payload };
    case ADMIN_ACTIONS.UPDATE_SUBMATERIAL:
      return { ...state, subMaterial: action.payload };
    case ADMIN_ACTIONS.UPDATE_SURFACE_FINISH:
      return { ...state, surfaceFinish: action.payload };
    case ADMIN_ACTIONS.UPDATE_SURFACE_TREATMENT:
      return { ...state, surfaceTreatment: action.payload };
    case ADMIN_ACTIONS.UPDATE_LAYER:
      return { ...state, layers: action.payload };
    case ADMIN_ACTIONS.UPDATE_PART_MARKING:
      return { ...state, partMarking: action.payload };
    case ADMIN_ACTIONS.UPDATE_TOLERANCE:
      return { ...state, tolerance: action.payload };
    case ADMIN_ACTIONS.UPDATE_MASTERS:
      return { ...state, ...action.payload };
    case ADMIN_ACTIONS.UPDATE_DASHBOARD_FILTER:
      return { ...state, dashboardFilter: action.payload };
    case ADMIN_ACTIONS.UPDATE_QUOTATION_MENU:
      return { ...state, isQuotationMenuActive: !state.isQuotationMenuActive };
    case ADMIN_ACTIONS.UPDATE_ORDER_MENU:
      return { ...state, isOrderMenuActive: !state.isOrderMenuActive };
    case ADMIN_ACTIONS.UPDATE_MASTERS_MENU:
      return { ...state, isMasterMenuActive: !state.isMasterMenuActive };
    case ADMIN_ACTIONS.UPDATE_USER_MENU:
      return { ...state, isUserMenuActive: !state.isUserMenuActive };
    case ADMIN_ACTIONS.UPDATE_CUSTOMER_MENU:
      return { ...state, isCustomerMenuActive: !state.isCustomerMenuActive };
    case ADMIN_ACTIONS.UPDATE_SUPPLIER_MENU:
      return { ...state, isSupplierMenuActive: !state.isSupplierMenuActive };
    case ADMIN_ACTIONS.UPDATE_ZOHO_MENU:
      return { ...state, isZohoMenuActive: !state.isZohoMenuActive };
    case ADMIN_ACTIONS.UPDATE_INVOICE_BILLING_MENU:
      return { ...state, isInvoiceBillingMenuActive: !state.isInvoiceBillingMenuActive };
    case ADMIN_ACTIONS.UPDATE_CNC_MASTER:
      return { ...state, isCncMasterActive: !state.isCncMasterActive }
    default:
      return state;
  }
};



export const AdminContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <adminContext.Provider value={{ state, dispatch }}>
      {children}
    </adminContext.Provider>
  );
};

export const useAdminContext = () => {
  return useContext(adminContext);
};
