import React from "react";
import tickwhite from "../../../../images/tick-white.svg";
import { currencyFormatter } from "../../../../utils/helper";
import './ShowPriceBox.css'; // External CSS for custom styling

const ShowPriceBox = (props) => {
  const { shipMethod,value } = props;
  return (
    <div
      className="pr-radio  "
      key={props.value}
      onClick={() => {
        props.updateCommanValueOnChange('selectedShipMethod', props.value);
      }}
    >
      <label className={`btn box-in border-primaryColor ${shipMethod==value && 'border !border-primaryColor border-2'} `} style={{ minHeight: '50px', height: 'auto', display: 'flex', justifyContent: 'space-evenly' }}>
        <span className="triangle-0">
          <img src={tickwhite} alt="tick" className="tick-icon" />
        </span>

        <span>
          <span className="text-xl font-semibold text-gray-800">
            {currencyFormatter(props.price ? (Number(props.price)).toFixed(2) : 0)}
          </span>
        </span>
        <span className="text-xs text-gray-500">(Shipped in {props.business})</span>
      </label>
    </div>
  );
};

export default ShowPriceBox;
