import React, {
  useMemo,
  useRef,
} from "react";

import Measure from "./Measure";
import Line from "./Line";

import { useThree, useFrame } from "react-three-fiber";

import { Vector3 } from "three";

export default function BoundingBox({box, visible, lineColor, measureColor, measureScale}) {
  const boxRef = useRef();

  const boxSize = useMemo(() => box?.getSize(new Vector3()).length(), [box]);

  const {camera} = useThree();
  const sceneRef = useRef();
  useFrame(({gl}) => {
    gl.autoClear = false;
    gl.clippingPlanes = [];
    gl.render(sceneRef.current, camera);
  }, 4);

  return (
    <scene
      ref={sceneRef}
      visible={visible}
      >
        { box
        ? <>
            <box3Helper
              ref={boxRef}
              args={[box]}
            >
              <lineBasicMaterial
                attach="material"
                color={lineColor}
              />
            </box3Helper>
            <> {/* X */}
              <Line
                point1={[box.min.x, box.min.y, box.max.z + boxSize * 0.00]}
                point2={[box.min.x, box.min.y, box.max.z + boxSize * 0.15]}
                color={lineColor}
                linewidth={1}
              />
              <Measure
                point1={[box.min.x, box.min.y, box.max.z + boxSize * 0.10]}
                point2={[box.max.x, box.min.y, box.max.z + boxSize * 0.10]}
                scale={measureScale}
                color={measureColor}
                linewidth={1}
                textAnchorY="bottom"
              />
              <Line
                point1={[box.max.x, box.min.y, box.max.z + boxSize * 0.00]}
                point2={[box.max.x, box.min.y, box.max.z + boxSize * 0.15]}
                color={lineColor}
                linewidth={1}
              />
            </>
            <> {/* Y */}
              <Line
                point1={[box.max.x + boxSize * 0.00, box.min.y, box.min.z]}
                point2={[box.max.x + boxSize * 0.15, box.min.y, box.min.z]}
                color={lineColor}
                linewidth={1}
              />
              <Measure
                point1={[box.max.x + boxSize * 0.10, box.min.y, box.min.z]}
                point2={[box.max.x + boxSize * 0.10, box.max.y, box.min.z]}
                scale={measureScale}
                color={measureColor}
                linewidth={1}
              />
              <Line
                point1={[box.max.x + boxSize * 0.00, box.max.y, box.min.z]}
                point2={[box.max.x + boxSize * 0.15, box.max.y, box.min.z]}
                color={lineColor}
                linewidth={1}
              />
            </>
            <> {/* Z */}
              <Line
                point1={[box.max.x + boxSize * 0.00, box.min.y, box.max.z]}
                point2={[box.max.x + boxSize * 0.15, box.min.y, box.max.z]}
                color={lineColor}
                linewidth={1}
              />
              <Measure
                point1={[box.max.x + boxSize * 0.10, box.min.y, box.max.z]}
                point2={[box.max.x + boxSize * 0.10, box.min.y, box.min.z]}
                scale={measureScale}
                color={measureColor}
                linewidth={1}
                textAnchorY="bottom"
              />
              <Line
                point1={[box.max.x + boxSize * 0.00, box.min.y, box.min.z]}
                point2={[box.max.x + boxSize * 0.15, box.min.y, box.min.z]}
                color={lineColor}
                linewidth={1}
              />
            </>
        </>
        : null
      }
    </scene>
  );
}
