import React from "react";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import "./Modal.scss";

export default function SimpleModal(props) {
  return (
    <div>
      <Modal
        open={props.isShowModal}
        onClose={props.onCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onBackdropClick={props.onCloseModal}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableRestoreFocus={true}
        className="Modal"
      >
        <div
          // style={props.style ? props.style : { position: "absolute" }}
          className={props.className ? props.className : "ModalData"}
        >
          {props.showClose && (
            <div
              className="close"
              onClick={props.onCloseModal}
              style={props.styleIcon ? props.styleIcon : {}}
            >
              {props.icon ? (
                <img src={props.icon} width="12px" alt={""} />
              ) : (
                <CloseIcon
                  style={{ color: "#000000", opacity: "0.8", fontSize: "20px" }}
                />
              )}
            </div>
          )}
          {props.children}
        </div>
      </Modal>
    </div>
  );
}
