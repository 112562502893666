import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const AdminPrivateRoute = () => {
    let isLoggedIn = localStorage.getItem('isLoggedIn') || null;
    let token = localStorage.getItem('Token') ||  null;
    if((!token) || (!isLoggedIn))
    {
        console.log("cam here 1122")
        return <Navigate to="/login" replace={true} />;
    }
    const decode = jwt_decode(token);
    if((!decode) || decode.userType=='user')
    {   
        console.log("cam here 14531")
        return <Navigate to="/login" replace={true} />;
    }

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return  <Outlet /> ;
}

export default AdminPrivateRoute;