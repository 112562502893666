import React, {
  useContext,
  useRef,
  useState,
} from "react";

import CADViewer from "./CADViewer";
import AnalysisResultsPanel from "./AnalysisResultsPanel";

import { Canvas } from 'react-three-fiber';

import { Context, ContextProvider } from './Context';

import { BackendToolbar } from "./Toolbar";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ToggleButton from "@material-ui/lab/ToggleButton";

import { LoadFileDropZone } from "./LoadFileButton";
import { useApiFunctions, useFixCanvas, useTweenUpdate } from "./endCommons";

function Main({openCascadeWorkerClass, addFileFromURLRef, addFileFromArrayBufferRef, setActiveFileIndexRef}) {
  const {state, dispatch} = useContext(Context);
  const [overlayContent, setOverlayContent] = useState();

  useFixCanvas();
  useTweenUpdate();
  useApiFunctions(state, addFileFromArrayBufferRef, addFileFromURLRef, dispatch, setActiveFileIndexRef);

  const controlsRef = useRef();
  const cameraRef = useRef();
  const [grid, setGrid] = useState();
  const [boundingBox, setBoundingBox] = useState();
  const [resetCamera, setResetCamera] = useState({});
  const [renderStyle, setRenderStyle] = useState();

  const [analysisPanelShown, setAnalysisPanelShown] = useState(false);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <LoadFileDropZone>
        <div
          style={{
            flexGrow: 5,
            flexBasis: 0,
            position: "relative",
          }}
        >
          <Context.Consumer>
            {
              value => (
                <Canvas
                  orthographic
                  id="viewport"
                  colorManagement
                >
                  <Context.Provider
                    value={value}
                  >
                    <CADViewer
                      setOverlayContent={setOverlayContent}
                      grid={grid}
                      boundingBox={boundingBox}
                      resetCamera={resetCamera}
                      renderStyle={renderStyle}
                      openCascadeWorkerClass={openCascadeWorkerClass}
                      controlsRef={controlsRef}
                      cameraRef={cameraRef}
                    />
                  </Context.Provider>
                </Canvas>
              )
            }
          </Context.Consumer>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              pointerEvents: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {overlayContent}
          </div>
          <BackendToolbar
            onGrid={x => setGrid(x)}
            onBoundingBox={x => setBoundingBox(x)}
            onResetCamera={x => setResetCamera(x)}
            onRenderStyle={x => setRenderStyle(x)}
            controlsRef={controlsRef}
            cameraRef={cameraRef}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: analysisPanelShown ? 2 : 0,
            flexBasis: 0,
            justifyContent: "center",
            alignItems: "flex-start",
            transition: "all 0.25s",
            position: "relative",
          }}
        >
          <ToggleButton
            value="enabled"
            style={{
              flexGrow: 1,
              padding: 0,
              width: "16px",
            }}
            selected={analysisPanelShown}
            onChange={() => setAnalysisPanelShown(x => !x)}
          >
            { analysisPanelShown ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon style={{marginRight: "-10px"}} /> }
          </ToggleButton>
          <AnalysisResultsPanel
            style={{
              flexGrow: 1,
              flexBasis: 0,
              overflow: "auto",
              position: "absolute",
              top: 0,
              left: "16px",
              right: 0,
              bottom: 0,
            }}
          />
        </div>
      </LoadFileDropZone>
    </div>
  );
}

function Backend({openCascadeWorkerClass, addFileFromURLRef, addFileFromArrayBufferRef, setActiveFileIndexRef}) {
  return (
    <ContextProvider>
      <Main
        openCascadeWorkerClass={openCascadeWorkerClass}
        addFileFromURLRef={addFileFromURLRef}
        addFileFromArrayBufferRef={addFileFromArrayBufferRef}
        setActiveFileIndexRef={setActiveFileIndexRef}
      />
    </ContextProvider>
  );
}

export default Backend;
