import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { useState } from "react";
import { addPartMaking } from '../../../utils/actions/allactions';
import { validateAdmin, getToken,numberInputOnWheelPreventChange } from "../../../utils/helper";
import { toast } from "react-toastify";
import useDocumentTitle from "../../../utils/useDocumentTitle";

function AddPartMakingMaster() {
  const token = getToken();
  const navigate = useNavigate();
  useDocumentTitle('Add Part Marking')
  const [error, setError] = useState("");
  const [initialValue, setInitialValue] = useState({
    name: '',
    PMFactor: ''
  });



  const validatePartMakingVal = () => {
    if (!initialValue.name ||
      !initialValue.PMFactor) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    validateAdmin(token, navigate);
  }, []);

  const handleAddPartMaking = async () => {
    setError("")
    const isPartMakingValid = validatePartMakingVal();

    if (!isPartMakingValid) {
      setError("Enter valid values for all the field")
    } else {
      const addedPartMaking = await addPartMaking(token, initialValue);
      
      if(addedPartMaking?.data?.data?.code==11000){
        toast.error("Part Marking already exists")
      }else{
        navigate('/admin/partMaking');
        toast.success("Part Marking added successfully")
      }
    }
  }


  return (
    <div  className="admin-container">
      <div>
        <Sidebar />
      </div>
      <div className="admin-panel-container">
        <h1 className="center-heading">  Add Part Marking   </h1>

        <div className="edit-company-container">
          <div>
            <label htmlFor="name" > Name </label>: &nbsp;
            <input
              name="name"
              type="text"
              placeholder="Enter name"
              onChange={(e) => { setInitialValue({ ...initialValue, name: e.target.value }) }}
            />
          </div>

          <div>
            <label htmlFor="PMFactor" > PMFactor </label>: &nbsp;
            <input
              name="PMFactor"
              type="number"
              onWheel={numberInputOnWheelPreventChange}
              placeholder="Enter PMFactor"
              value={initialValue.PMFactor}
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={(e) => { setInitialValue({ ...initialValue, PMFactor: e.target.value }) }}
            />
          </div>

          <div className="center-heading">
            <button className="button-search" onClick={handleAddPartMaking}>  Create </button>
          </div>
          {
            error ?
              <div style={{ color: 'red', fontSize: '15px', textAlign: 'center', marginTop: '10px' }}>{error}</div>
              : null
          }
        </div>
      </div>
    </div>
  )
}
export default AddPartMakingMaster;



