
import gearimg from "../../images/dash-img.png";
import secure from "../../images/secure-icon.svg";
import { Link } from "react-router-dom";
import {
  Container,
  Button,
  Modal,
} from "react-bootstrap";
import jwtDecode from "jwt-decode";
import { getSaveQuotes } from "../../utils/actions/allactions";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/header";
import Video from '../../Video/Dainsta.mp4'
import ReactPlayer from 'react-player'
import jwt_decode from 'jwt-decode';

const Dashboard = () => {
  let token = localStorage.getItem('Token');
  let userName = localStorage.getItem('firstName');
  const [save, setSave] = useState([]);
  const [user, setUser] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const loggedInUser = localStorage.getItem("Token");
  if (loggedInUser) {
    var userfirstName = jwtDecode(localStorage.getItem("Token"))?.firstName;
    var userlastName = jwtDecode(localStorage.getItem("Token"))?.lastName;
    var userId = jwtDecode(localStorage.getItem("Token"))?._id;
  }
  const navigate = useNavigate();
  const handleLogout = () => {
    setUser({});
    localStorage.removeItem("Token");
    localStorage.removeItem("_id");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("pathAfterLogin");
    console.log("cam here 11455345")
    navigate("/login");
  };
  const saveQuote = async () => {
    try {
      let token = localStorage.getItem("Token");
      let getsave = await getSaveQuotes(token);
      if (getsave == false) {
        throw {
          message: "Something went wrong during save Quotation",
        };
      }
      setSave(getsave.data);
    } catch (err) {
      let message =
        err && err.message
          ? err.message
          : "Something went wrong during fetch getPatrons";
      toast.error(message);
      return false;
    }
  };
  useEffect(() => {
    let decode;
    if (token) {
      decode = jwt_decode(token);
      if (!decode) {
        navigate('/');
        toast.error('Invalid Token found!');
      }
      else if (decode.userType == 'admin') {
        navigate('/');
      }
      else {
        saveQuote();
      }
    }
    else {
      navigate('/');
    }
  }, []);

  return (
    <div>
      <div className="content-wrapper">
        {loggedInUser ? (
          <div>
            <div>
              <Header activeTab="/" />

              <div className="intro-bar">
                <div className="int-left">
                  <h3>
                    Hey {userName ? userName : ""}{" "}
                    Welcome!
                  </h3>
                  <p>The power of digital manufacturing at your fingertips</p>
                </div>
                <div className="int-right"></div>
              </div>

              <div className="dashboard-holder">
                <Container className="sm-container">
                  <div className="dashboard-in">
                    <div className="dash-left">
                      <div className="ds-heading">
                        Delivering high-quality custom mechanical parts to you at
                        lightning speed.{" "}
                      </div>
                      <p>
                        <span>
                          <img src={secure} />
                        </span>{" "}
                        Your uploads are secure with us.
                      </p>
                      <div className="ds-btn">
                        {" "}
                        <Link to="/project-name" className="quote-btn">
                          Get Instant Quotation
                        </Link>{" "}
                      </div>
                      {/* <div className="ds-btn">
                        {" "}
                        <Link to="/get-instant-quote" className="quote-btn">
                          Get Instant Quotation
                        </Link>{" "}
                      </div> */}
                      <div className="ds-link">
                        {" "}
                        <Link to="" onClick={handleShow}>
                          <span>

                          </span>{" "}
                          Watch how it works
                        </Link>{" "}
                      </div>
                    </div>
                    <div className="dash-img">
                      {" "}
                      <img src={gearimg} />{" "}
                    </div>
                  </div>
                </Container>
              </div>
            </div>
            <Modal size="lg" show={show} onHide={handleClose}>
              <Modal.Body>
                <ReactPlayer
                url={Video}
                playing={true}
                loop={true} 
                controls={true}
                width='100%'
                height="600px"
                />

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>

  );
};
export default Dashboard;
