import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents';
import { getAllMaterialData, getCncMachineDataById, getMachineDataById, getMrrByMachineAndMaterialId, upsertMachine, upsetMrrValues } from '../../utils/actions/adminActions';
import { Form } from 'react-bootstrap';

const MachineMaterialSelect = () => {

    const [allMaterials, setAllMaterials] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [data, setData] = useState({});
    const navigate = useNavigate();

    const location = useLocation();

    if (!location.state || !location?.state?._id) {
        navigate("/admin/machine-cnc");
        return;
    }

    const { _id } = location.state;



    useEffect(() => {
        const { state } = location;


        if (!state) return;
        const { _id } = state;
        if (!_id) return;
        (async () => {
            try {

                const response = await getAllMaterialData();
                if (!response || !response.success) {
                    throw new Error();
                }
                const { data: materialData } = response;
                setAllMaterials(materialData);

                const machineResponse = await getCncMachineDataById(_id);
                // console.log("Response :",machineResponse);
                if (!machineResponse || !machineResponse.success) {
                    throw new Error();
                }
                const machineData = machineResponse?.data;
                setData(machineData);
            } catch (error) {
                toast.error("Unable To Get  Data At The Moment");
            }
        })()


    }, [location.search]);


    useEffect(() => {
        if (!data?._id) return;


        (async () => {

            try {
                const response = await upsertMachine(data);
                if (!response || !response.success) {
                    throw new Error();
                }
            } catch (error) {

                console.error("Error ", error);
            }

        })()

    }, [data])


    return (
        <div className='w-screen my-2'>
            <h1 className='text-center text-2xl font-bold my-2 '>Materials </h1>
            <table class="table-auto  w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
                <thead class="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                    <tr>
                        <th class="px-4 py-2 border border-gray-200 ">Checkbox</th>
                        <th class="px-4 py-2 border border-gray-200 ">Material</th>
                        <th class="px-4 py-2 border border-gray-200">Grade </th>
                        <th class="px-4 py-2 border border-gray-200">Description </th>
                        <th class="px-4 py-2 border border-gray-200">Density (g/cm³) </th>
                        <th class="px-4 py-2 border border-gray-200">Manual Review </th>
                        <th class="px-4 py-2 border border-gray-200">Roughint Mrr </th>
                        <th class="px-4 py-2 border border-gray-200">Finishing Mrr </th>
                        <th class="px-4 py-2 border border-gray-200">Update </th>
                    </tr>
                </thead>
                <tbody>
                    {allMaterials.length
                        ? allMaterials.map((item, index) => (
                            <tr
                                key={index}
                                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                    } hover:bg-gray-200 transition duration-200`}
                            >
                                <td className="px-4 py-2 border border-gray-200">
                                    <Form.Check
                                        type='checkbox'
                                        id={`checkbox-${index}`}
                                        label=''
                                        checked={data?.cnc_materials?.includes(item?._id)}
                                        onChange={(e) => {
                                            setData((prevData) => {
                                                const newSurfaceTreatment = [...prevData.cnc_materials];
                                                const itemIndex = newSurfaceTreatment.indexOf(item?._id);
                                                if (itemIndex > -1) {
                                                    newSurfaceTreatment.splice(itemIndex, 1);
                                                } else {
                                                    newSurfaceTreatment.push(item?._id);
                                                }

                                                return {
                                                    ...prevData,
                                                    cnc_materials: newSurfaceTreatment,
                                                };
                                            });
                                        }}


                                    />
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_grade || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material_desc || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material_density_gms_cm3 || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material_review ? "true" : "false"}
                                </td>
                                <ShowMrrValuesUpdate
                                    machineId={_id}
                                    materialId={item?._id}
                                />
                            </tr>
                        ))
                        : (
                            <tr>
                                <td
                                    colSpan="3"
                                    className="px-4 py-2 text-center text-gray-500 border border-gray-200"
                                >
                                    No data available
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>

        </div>
    )
}

export default AdminDashboardComponents(MachineMaterialSelect);




function ShowMrrValuesUpdate({ machineId, materialId }) {

    if (!machineId || !materialId) return null;

    console.log("Material Id :", materialId);
    console.log("Machine Id :", machineId);
    const [data, setData] = useState({
        cnc_roughing_MRR_mm3_per_min: 0,
        cnc_finishing_MRR_mm3_per_min: 0,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await getMrrByMachineAndMaterialId(machineId, materialId);
                if (!response || !response.success) {
                    throw new Error();
                };
                const { data } = response;
                if (data) {
                    setData(data);
                }

            } catch (error) {
                toast.error("Unable To Fetch Data At The Moment");
            }
        })()
    }, [])

    function handleUpsert() {
        (async () => {
            try {
                const response = await upsetMrrValues({ machineId, materialId, ...data });
                const { data: newData } = response;
                if (newData) {
                    setData(data);
                }
                toast.success("Updated Succesfully");
            } catch (error) {
                console.log(error);
                toast.error('Unable To Update Data At The Moment');
            }
        })()
    }


    return (
        <Fragment>
            <td className="px-4 py-2 border border-gray-200">
                <input
                    type="number"
                    name="cnc_roughing_MRR_mm3_per_min"
                    value={data.cnc_roughing_MRR_mm3_per_min}
                    onChange={handleInputChange}
                    className="w-full px-2 py-1 border border-gray-300 rounded"
                />
            </td>
            <td className="px-4 py-2 border border-gray-200">
                <input
                    type="number"
                    name="cnc_finishing_MRR_mm3_per_min"
                    value={data.cnc_finishing_MRR_mm3_per_min}
                    onChange={handleInputChange}
                    className="w-full px-2 py-1 border border-gray-300 rounded"
                />
            </td>
            <td>
                <button onClick={() => handleUpsert()} className='bg-yellow-500 rounded mx-2 p-1 text-white'>
                    Update
                </button>
            </td>
        </Fragment>
    );
}

