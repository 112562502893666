import React, { useState } from 'react';

const TailwindModalComponent = ({isModalOpen,setIsModalOpen,ModalText,leftButtonText,rightButtonText,onLeftbuttonClick,onRightButtonClick}) => {

  return (
    <div>
      {isModalOpen && (
        <div id="popup-modal" className="fixed inset-0 z-50 flex justify-center items-center w-full h-screen bg-gray-800 bg-opacity-50">
          <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow">
              <button 
                type="button" 
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center" 
                onClick={()=>setIsModalOpen(false)}>
                <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7L1 13" />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>

              <div className="p-4 text-center">
                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500">{ModalText}</h3>

                <button 
                  onClick={onLeftbuttonClick} 
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5">
                  {leftButtonText}
                </button>

                <button 
                  onClick={onRightButtonClick} 
                  className="py-2.5 px-5 ml-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-100">
                 {rightButtonText}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TailwindModalComponent;
