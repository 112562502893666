import { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { getLayerThickess } from "../../utils/actions/allactions";
import { useNavigate } from "react-router-dom";
import { getToken, validateAdmin } from "../../utils/helper";
import useDocumentTitle from "../../utils/useDocumentTitle";
// import AdminLoadingComponent from "../../LoadingComponents/AdminLoadingComponent";
import AdminLoadingComponent from "../../components/LoadingComponents/AdminLoadingComponent";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";


const LayerThicknessMaster = () => {
  let navigate = useNavigate();
  useDocumentTitle("Layer Thickness Master");
  const [data, setData] = useState(null);
  let token = getToken();
  const fetchLayerData = async () => {
    try {
      const layerData = await getLayerThickess(token);
      setData(layerData);
    } catch (error) {
      toast.error("Unable To Fetch Layer Thickness Data");
    }
   
  };
  useEffect(() => {
    validateAdmin(token, navigate);
    fetchLayerData();
  }, []);
  const handleCreateNew = () => {
    navigate("/admin/add-layer-thickness");
  };
  const handeEditLayerThicknessMaster = (id) => {
    navigate(`/admin/edit-layer-thickness?id=${id}`);
  };

  return (
    <div className="admin-container">
      <Sidebar />
      <div className="right-container">
        <div className="center-heading">
          <h1 className="text-2xl font-bold"> Layer Thickness </h1>

          <div className="search-bar">
            <div className="row">
              <div className="col-md-9"></div>
              <div className="col-md-3">
                <div className="search-btn">
                  <button onClick={handleCreateNew} className="pr-edit-btn">
                    Create New
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="quotes-table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Name{" "}
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    LT Factor
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {data && (
                <tbody>
                  {data.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.name ? item.name : ""}</td>
                        <td>{item.LTFactor ? item.LTFactor : "NA"}</td>
                        <td>
                          <Button
                            onClickFunction={() =>
                              handeEditLayerThicknessMaster(item._id)
                            }
                            text={"Edit / Deactivate"}
                            additionalClassName={
                              "border-red-500  px-4 py-2 bg-yellow-500 shadow-sm text-white rounded text-sm"
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
              {!data && <AdminLoadingComponent rows={3} columns={3} />}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayerThicknessMaster;
