import React, { useState } from 'react';
import Dropzone from "react-dropzone";
import { CREATE_QUOTE_ACTION, useCreateQuoteContext } from '../../../../context/create.quote.context';
import uploadImg from "../../../../images/upload.svg";
import { commanFields, maxFileUploadSize } from '../../../../utils/constant';
import { storage } from "../../../../utils/firebase";
import { getFileExtension } from '../../../../utils/helper';
// import { getThumbnail } from '../../../../utils/actions/allactions';
import { triggerFunc, triggerFuncLink } from '../../../../utils/trigger';
import { setSameForAllField, setValues } from '../../../../context/create.quote.actions';
import { toast } from 'react-toastify';
import AutomatedProcessLinesUI from '../AutomatedProcessLinesUI/AutomatedProcessLinesUI';
import { Col, Form, Row } from 'react-bootstrap';
import ManualProcessLinesUI from '../ManualProcessLinesUI/ManualProcessLinesUI';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Switch from "react-input-switch";
import { ArrowRightLeft, EditIcon, PlusIcon } from '../../../../components/Icons';
import EditProjectName from '../../../../components/EditProjectName/EditProjectName';

const CenterSection = ({
  setIsInvalidFiles,
  setIsProcessing,
  setIsGlobeLoader,
  files,
  setFiles,
  isQuoteChanged,
  setIsQuoteChanged,
  bounding,
  setBounding,
  masterThreeDTechnologyData,
  isEditQuote,
  boxDimension,
  processing,
  globeLoader,
  sample,
  setSample,
  setTwoDFiles,
  twoDFiles,
  setIsInchSelected,
  isInchSelected,
  onSubmit,
  updateCommanValueOnChange,
  OnDropFunction,
  onSelectfile,
  updateValueOnChange,
  updateQuote,
  selectedQuote,
}) => {
  const [isEditingProjectName, setIsEditingProjectName] = useState(false);
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();
  const [isProjectNameEditModalOpen, setisProjectNameModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const repeatOrder = searchParams.get('RepeatOrder') == "true" ? true : false;
  const isRepeatOrderWithUpdate = searchParams.get('isRepeatOrderWithUpdate') == "true" ? true : false;
  let lineItemTemplate = {
    selectedFile: "",
    Notes: "",
    Qty: 1,
    description: "",
    MaterialType: "",
    SubMaterial: "",
    file: "",
    layer: "",
    partMaking: "",
    surfaceFinish: "",
    surfaceTreatment: "",
    tolerance: "",
    price: 0,
    price1: 0,
    price2: 0,
    partName: "",
    linePartName: "",
  };
  const { quoteId } = useParams();
  const navigate = useNavigate()



  const handleDelete = (index) => {
    console.log("Index :", index);
    try {
      let afterFltr = createQuoteState?.values.filter((val, i) => i !== index)
      let fileName = (createQuoteState?.values[index]?.selectedFile?.name || createQuoteState?.values[index]?.file?.originalname) + index;
      let boundingAfterFltr = bounding.filter((val, i) => i !== index)

      setValues([...afterFltr], createQuoteDispatch, createQuoteState)
      setBounding(boundingAfterFltr)

      let afterDeleteFile = files?.filter((val, i) => i !== index);

      if (quoteId) {
        for (let val of afterDeleteFile) {
          let ext = getFileExtension(val?.originalname || val?.selectedFile?.path);
          if (ext == 'stp' || ext == 'step' || ext == 'iges' || ext == 'stl' ||
            ext == 'STP' || ext == 'STEP' || ext == 'IGES' || ext == 'STL'
          ) {
            setIsInvalidFiles(false);
            continue;
          }
          else {
            break;
          }
        }
      }
      else if (!quoteId) {
        for (let val of afterDeleteFile) {
          let ext = getFileExtension(val?.name || val?.selectedFile?.path);
          if (ext == 'stp' || ext == 'step' || ext == 'iges' || ext == 'stl' ||
            ext == 'STP' || ext == 'STEP' || ext == 'IGES' || ext == 'STL'
          ) {
            setIsInvalidFiles(false);
            continue;
          }
          else {
            break;
          }
        }
      }
      setFiles([...afterDeleteFile]);
      try {
        let local2DFiles = { ...twoDFiles };
        delete local2DFiles[index];
        for (let i = index + 1; i < Object.keys(local2DFiles).length + 1; i++) {
          // console.log('local2D fiels at i-1',local2DFiles[i - 1])
          // console.log('local2D fiels at i',local2DFiles[i])
          local2DFiles[i - 1] = local2DFiles[i];
          delete local2DFiles[i];
        }
        setTwoDFiles({ ...local2DFiles });
      } catch (err) {
        console.log('err', err);
      }
    }
    catch (err) {
      console.log("err", err)
    }
  }


  async function createFile(url, type) {
    if (typeof window === "undefined") return; // make sure we are in the browser
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: type || "",
      path: "SamplePart.stp",
    };
    let fileName = url?.slice(url.lastIndexOf("/") + 1);
    let filewa = new File([data], fileName, {
      type: type || "",
      path: "SamplePart.stp",
    });
    return new File([data], fileName, metadata);
  }


  const checkIfProcessSet = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await createFile(
          "https://dainstamakeimages.s3.eu-west-1.amazonaws.com/8XSamplePart.stp",
          ""
        );

        onSelectfile([response]);
        setFiles([
          files,
          Object.assign(response, {
            isNotFromMongoDB: true,
          }),
        ]);
        return resolve(true);
      } catch (err) {
        console.log(err);
        return resolve(false);
      }
    });
  };


  const onClickSampleUpload = async () => {
    try {
      const response = await checkIfProcessSet();
      console.log("Response from check IF ProcesSer")
      setSample(true);
      if (!response) return;

      // here we add logic to upload sample file
    } catch (err) {
      console.log("error ", err);
      return err;
    }
  };


  const handleSameForAllField = (newValue) => {
    setSameForAllField(newValue, createQuoteDispatch, createQuoteState)
  }

  const handleAddItem = () => {
    let localValues = [...createQuoteState?.values];
    localValues.push(lineItemTemplate);
    setValues(localValues, createQuoteDispatch, createQuoteState)
  };

  const handleProjectNameSave = (updatedProjectName) => {
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.UPDATE_PROJECT_NAME,
      payload: updatedProjectName,
    })
  }

  const handleNewVersionClick = () => {
    updateQuote("new")
  }

  const handleVersionChange = (selectedItem) => {
    console.log("Selected Item:", selectedItem);
    if(selectedItem?._id){
      navigate('/create-instant-quotation/'+selectedItem?._id)
    }else{
      toast.error("Invalid version selected. Please refresh the page and try again.")
    }
  };

  // console.log('selectedQuote?.allVersionForRefId', selectedQuote?.allVersionForRefId)


  return (
    <div className="sm:col-span-3 p-4">
      {createQuoteState?.values?.length ?
        <>
          <div className='mb-4 flex space-between'>

            <div className="flex items-center w-full ">
              <h1 className='flex items-center font-bold  '>{createQuoteState?.commanValues?.projectNumber}{createQuoteState?.commanValues?.projectNamePreFix} - {createQuoteState?.commanValues?.projectName} <span onClick={() => setisProjectNameModalOpen(true)}><EditIcon
                additionalClass={'!h-5'}
              /></span> </h1>
            </div>
            <div className='flex'>
              <Form.Select
                className="w-[130px] mr-2"
                onChange={(e) => {
                  console.log('values in onChange', e.target.value);
                  const selectedVersion = Number(e.target.value); // Convert value to a number
                  const selectedItem = selectedQuote?.allVersionForRefId?.find(
                    item => item.version === selectedVersion
                  );
                  handleVersionChange(selectedItem);
                }}
                defaultValue={selectedQuote?.version}
              >
                {selectedQuote?.allVersionForRefId?.map(item => (
                  <option key={item.version} value={item.version}>
                    {item.version}
                  </option>
                ))}
              </Form.Select>


              <button className='py-1 px-2 bg-primaryColor text-white rounded flex items-center justify-between' onClick={handleNewVersionClick}>
                <PlusIcon additionalClass={'h-4'} />
                <span className='text-[13px] w-[70px]'>New Version</span>
              </button>
            </div>
          </div>

          {createQuoteState?.commanValues?.selectedProcess <= 2 ? <>
            <div className="flex items-center space-x-14 my-3">
              {/* MM-Inch Toggle */}
              <div className="flex items-center space-x-2 text-sm">
                <span
                  className={`transition-colors ${isInchSelected ? "text-gray-500" : "font-semibold text-black"
                    }`}
                >
                  (mm)
                </span>
                <Switch
                  styles={{
                    track: {
                      backgroundColor: isInchSelected ? "#2B96DC" : "white",
                    },
                    trackChecked: {
                      backgroundColor: "#2B96DC",
                    },
                    button: {
                      backgroundColor: !isInchSelected ? "#2B96DC" : "white",
                    },
                  }}
                  className={`custom-switch w-8 h-5 border rounded-full transition-all ${!isInchSelected && "border-primaryColor"
                    }`}
                  on="true"
                  value={isInchSelected?.toString()}
                  onChange={() => setIsInchSelected(!isInchSelected)}
                  off="false"
                  aria-label="Toggle between mm and inch"
                />
                <span
                  className={`transition-colors ${isInchSelected ? "font-semibold text-black" : "text-gray-500"
                    }`}
                >
                  (inch)
                </span>
              </div>

              {/* Apply to All Parts Toggle */}
              <div className="flex items-center space-x-2 text-sm">
                <Switch
                  styles={{
                    track: {
                      backgroundColor: createQuoteState?.commanValues.sameForAllField
                        ? "#2B96DC"
                        : "white",
                    },
                    trackChecked: {
                      backgroundColor: "#2B96DC",
                    },
                    button: {
                      backgroundColor: !createQuoteState?.commanValues.sameForAllField
                        ? "#2B96DC"
                        : "white",
                    },
                  }}
                  className={`custom-switch w-8 h-5 border rounded-full transition-all ${!createQuoteState?.commanValues.sameForAllField &&
                    "border-primaryColor"
                    }`}
                  on="true"
                  value={createQuoteState?.commanValues.sameForAllField?.toString()}
                  onChange={() =>
                    handleSameForAllField(!createQuoteState?.commanValues.sameForAllField)
                  }
                  off="false"
                  aria-label="Toggle apply to all parts"
                />
                <span
                  className={`transition-colors ${createQuoteState?.commanValues.sameForAllField
                    ? "font-semibold text-black"
                    : "text-gray-500"
                    }`}
                >
                  Apply selected specifications to all parts
                </span>
              </div>
            </div>

          </> : <></>}
          {/* {
            console.log("Values :", createQuoteState?.values)
          } */}
          {(createQuoteState?.commanValues?.selectedProcess == 1 || createQuoteState?.commanValues?.selectedProcess == 2) ?
            <>
              <div className="product-thumb border -translate-y-4 px-2 py-1">
                {createQuoteState?.values &&
                  createQuoteState?.values?.length > 0 &&
                  createQuoteState?.values?.map((singleValue, index) => {
                    return (
                      <AutomatedProcessLinesUI
                        setIsInvalidFiles={setIsInvalidFiles}
                        masterThreeDTechnologyData={masterThreeDTechnologyData}
                        key={`machingPrinting-${index}`}
                        singleValue={singleValue}
                        index={index}
                        onDelete={handleDelete}
                        files={files}
                        setFiles={setFiles}
                        isEditQuote={isEditQuote}
                        boxDimension={boxDimension}
                        processing={processing}
                        globeLoader={globeLoader}
                        keyValue={index + 1}
                        sample={sample}
                        values={createQuoteState?.values}
                        setValues={setValues}
                        isInvalidFiles={createQuoteState?.commanValues?.isInvalidFiles}
                        selectedProcess={createQuoteState?.commanValues.selectedProcess}
                        updateValueOnChange={updateValueOnChange}
                        commanValues={createQuoteState?.commanValues}
                        updateCommanValueOnChange={updateCommanValueOnChange}
                        isQuoteChanged={isQuoteChanged}
                        setIsQuoteChanged={setIsQuoteChanged}
                        sameForAllField={createQuoteState?.commanValues.sameForAllField}
                        setSameForAllField={handleSameForAllField}
                        twoDFiles={twoDFiles}
                        setTwoDFiles={setTwoDFiles}
                        setIsInchSelected={setIsInchSelected}
                        isInchSelected={isInchSelected}
                      />
                    );
                  })}
              </div>
            </> :
            <>
              <div>
                <div className="pro-dm">
                  <h5 className='my-3'>
                    Enter description with parts specification
                  </h5>
                  <div className="parts-table">
                    <table>
                      <tbody>
                        <tr>
                          <th className="cursor-pointer text-xs">File Name</th>
                          <th className="cursor-pointer text-xs">Description</th>
                          <th className="cursor-pointer text-xs">Notes</th>
                          <th className="cursor-pointer text-xs">Quantity</th>
                          <th className="cursor-pointer text-xs"></th>
                        </tr>
                        {createQuoteState?.values &&
                          createQuoteState?.values.length > 0 &&
                          createQuoteState?.values.map((singleValue, index) => {
                            return (
                              <ManualProcessLinesUI
                                singleValue={singleValue}
                                onSubmit={onSubmit}
                                keyValue={index + 1}
                                key={`sheetInjectionInput-${index}`}
                                files={files}
                                onDelete={handleDelete}
                                index={index}
                                values={createQuoteState?.values}
                                selectedProcess={createQuoteState?.commanValues?.selectedProcess}
                                updateValueOnChange={updateValueOnChange}
                                commanValues={createQuoteState?.commanValues}
                                updateCommanValueOnChange={updateCommanValueOnChange}
                              />
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="btm-buttons">
                <Row>
                  <Col md={2}>
                    <div className="price-box">
                      <button
                        onClick={handleAddItem}
                        className={`border  ${createQuoteState?.commanValues?.isTargetCostActivated ? "col-span-6" : "col-span-12"} cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2 rounded-sm`}
                      >
                        Add Item
                      </button>

                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span style={{ fontSize: "12px", color: "red" }}>
                      *If you want you add an item without file, you can
                      click on the "Add Item" button above
                    </span>
                  </Col>
                </Row>
              </div>
            </>
          }




        </>
        :
        <>
          <div
            className="upload-req -ml-10 h-2/3"
          >
            {createQuoteState?.values && createQuoteState?.values.length === 0 && (
              <Dropzone
                onDrop={(e) => OnDropFunction(e)}
                // minSize={1024}
                // maxSize={25 * 1024 * 1024} //25mb
                disabled={createQuoteState?.commanValues?.selectedProcess === 0 ? true : false}
                multiple={true}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps({ className: " upload-box dropzone h-full w-full" })}
                  >
                    <span className="up-icon">
                      <img src={uploadImg} alt="upload" />
                    </span>
                    <span
                      className={
                        createQuoteState?.commanValues?.selectedProcess === 0 ? "error" : "inp-txt"
                      }
                      style={{ fontSize: "16px" }}
                    >
                      {createQuoteState?.commanValues?.selectedProcess === 0
                        ? "Please select a Process to continue..."
                        : "Drop files here to upload"}
                    </span>
                    <div className="file-button">
                      <input {...getInputProps()} />

                      <label
                        className="file-input__label"
                        htmlFor="file-input"
                      >
                        <span>Select a file</span>
                      </label>
                    </div>
                    {createQuoteState?.commanValues?.selectedProcess === 1 ||
                      createQuoteState?.commanValues?.selectedProcess === 2 ? (
                      <p>
                        Instant Quotation for - .stp, .step files. Other file
                        format are also accepted (RFQ)
                      </p>
                    ) : null}
                  </div>
                )}
              </Dropzone>
            )}
          </div>
        </>}


      {createQuoteState?.values &&
        createQuoteState?.values.length === 0 &&
        (createQuoteState?.commanValues?.selectedProcess >= 3 ? (
          ""
        ) : (
          <div className='upload-req w-full -mt-10 p-0 bg-red-100'>
            <div className="sample-box flex items-center">
              <div className="text">
                Not yet ready to upload your files at the moment? Use our
                sample part file to try out our instant quotation tool
              </div>
              <button
                disabled={false}
                onClick={() => onClickSampleUpload()}
                className="upload-btn p-1 "
              >
                Upload Sample Part
              </button>
            </div>
          </div>


        ))}
      <EditProjectName
        show={isProjectNameEditModalOpen}
        currentName={createQuoteState?.commanValues?.projectName}
        onSave={handleProjectNameSave}
        onCancel={() => setisProjectNameModalOpen(false)}
      />


      <div className="privacy-text">
        Please review our terms of use carefully, as uploading certain files, including weapons, weapon components, or items regulated under ITAR, EAR , or EU Dual-Use regulations, is strictly prohibited.
      </div>
    </div>
  )
}

export default CenterSection