import React, { useContext, useEffect, useState } from "react";
import partImage from "../../images/thumb.png";
import settingw from "../../images/setting-white.svg";
import orderIcon from "../../images/orderIcon.svg";
import chatico from "../../images/chat.svg";
import shippingIcon from "../../images/shippingIcon.svg";
import tickgreen from "../../images/tick-green.svg";
import tickwhite from "../../images/tick-white.svg";
import downloadImage from "../../images/download.svg";
import backImg from "../../images/back.svg";
import teamImage from "../../images/group-svgrepo-com.svg";
import DownloadImage from "../../images/download.svg";
import closeImg from "../../images/close.svg";
import viewImage from "../../images/view_icon.svg";
import starsIcon from "../../images/stars.svg";
import starsIconGrey from "../../images/stars-grey.svg";
import downArrowIcon from "../../images/downArrow.svg";
import upArrowIcon from "../../images/upArrow.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { useIntercom } from "react-use-intercom";
import { Row, Col, Form, Button, Modal, Tabs, Tab } from "react-bootstrap";
import Header from "../../components/Header/header";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { processType, raiseIssueSteps } from "../../utils/constant";
import {
  downloadPdf,
  getPurchaseOrderRelatedIssueUser,
  getQualityRelatedIssueUser,
  submitRaiseIssue,
  getIssues,
  getTrackingDetails,
  handleSubmitFeedBack,
  updateOrderRating,
  fetchOrderById,
  handleDownloadCocForOrder,
} from "../../utils/actions/allactions";
import fileSaver from "file-saver";
import moment from "moment";
import { config } from "../../environment/development";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import OrderTable from "../../components/OrderTable/OrderTable";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import qs from "query-string";
import { ChatRefContext } from "../../context/chatRef.context";
import {
  currencyFormatter,
  extractURLAndEncodedFilename,
} from "../../utils/helper";
import TeamCollaboration from "../../components/TeamCollaboration/TeamCollaboration";
import {
  getInvoicesForSalesOrder,
  handleDownloadCocForInvoice,
  handleDownloadIndividualInvoice,
} from "../../utils/actions/commanActions";
import ConsignmentPhotoViewer from "../../components/ConsignmentPhotoViewer/ConsignmentPhotoViewer";
import {
  DownloadIcon,
  EyeIcon,
  StartIcon,
  StartIconYellow,
} from "../../components/Icons";
import { Loading } from "../../components/Loading";
import CustomLoadingComponent from "../../CustomLoaderComponent/CustomLoadingComponent";
import { Loader } from "@chatscope/chat-ui-kit-react";

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const OrderDetails = () => {
  let navigate = useNavigate();
  let { state } = useLocation();
  let token = localStorage.getItem("Token");
  let decode;
  const { setSelectedChatRefId } = useContext(ChatRefContext);
  const [expanded, setExpanded] = React.useState(false);
  const [key, setKey] = useState("final-order");
  const [raiseIssueModal, setRaiseIssueModal] = useState(false);
  const [raiseIssueStepCount, setRaiseIssueStepCount] = useState(0);
  const [issueFileAttachment, setIssueFileAttachment] = useState([]);
  const [isPartIssue, setIsPartIssue] = useState(true);
  const [issueDescription, setIssueDescription] = useState("");
  const [qualityRelatedIssue, setQualityRelatedIssue] = useState([]);
  const [orderRelatedIssue, setOrderRelatedIssue] = useState([]);
  const [existIssue, setExistingIssue] = useState(false);
  const [dhlShipmentInformation, setDhlShipmentInformation] = useState({});
  const [showDeliveryTrackingInformation, setShowDeliveryTrackingInformation] =
    useState(false);
  const [showShipmentPartsData, setShowShipmentPartsData] = useState(false);
  const [orders, setOrders] = useState([]);
  const [partsWithIssue, setPartsWithIssue] = useState([]);
  const [deliveryTrackingLink, setDeliveryTackingLink] = useState("");
  const [open, setOpen] = useState(false);
  const { search } = window.location;
  const param = qs.parse(search);
  const [invoicesForOrder, setInvoicesForOrder] = useState([]);
  const [invoiceToViewDocs, setInvoiceToViewDocs] = useState(null);

  const [loading, setLoading] = useState(false);

  const [selectedQuoteForTeam, setSelectedQuoteForTeam] = useState({});
  const [teamMemberModalShow, setTeamMemberModalShow] = useState(false);
  let userId = localStorage.getItem("_id");
  let email = localStorage.getItem("email");

  const showDrawer = () => {
    setOpen(true);
    setSelectedChatRefId(orders?.quoteId?.RefId);

    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "chatOpen",
      //   userId: userId,
      //   quoteRefId: orders?.quoteId?.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }
  };
  const onClose = () => {
    setOpen(false);
    setSelectedChatRefId(null);
  };
  const [qualityDocModal, setQualityDocModal] = useState(false);
  const [consignmentModal, setConsignmentModal] = useState(false);
  const [isValidUser, setIsValidUser] = useState(true);
  const [pdfDownloadingLoading,setPdfDownloadingLoading] = useState(false);


  let { _id } = useParams();

  useEffect(() => {
    setLoading(true);
    const userEmail = localStorage.getItem("email");

    fetchOrderById(_id, token)
      .then((res) => {
        const newOrder = res?.data?.data;
        fetchSecondaryData(newOrder._id);
        let localQualityDoc = newOrder.qualityDocuments;
        let localConsignmentPhoto = newOrder.consignmentPhoto;
        // localQualityDoc = localQualityDoc.filter(item => item.isAdminApproved)
        // localConsignmentPhoto = localConsignmentPhoto.filter(item => item.isAdminApproved)
        setOrders({
          ...newOrder,
          consignmentPhoto: localConsignmentPhoto,
          qualityDocuments: localQualityDoc,
        });
        setDeliveryTackingLink(newOrder.deliveryTrackingLink);
        setPartsWithIssue(newOrder.quoteId.partsData);

        if (newOrder?.email?.split("@")[1] != userEmail?.split("@")[1]) {
          setIsValidUser(false);
        }
      })
      .catch((err) => {
        console.log(
          "Error in order details in 150 on" +
            moment(Date.now()).format("DD MMM YYYY hh:mm:ss a a"),
          err
        );
        return err;
      })
      .finally(()=>
      {
          setLoading(false);
      })

    if (param?.dropDown == "true") {
      setExpanded(param?.dropDownName);
    }

    if (param?.modal == "true") {
      if (param?.modalName == "RaiseIssue") {
        setExpanded("panel4");
        setRaiseIssueModal(true);
      }
    }
  }, []);

  const fetchSecondaryData = async (orderId) => {
    try {
      getInvoicesForSalesOrder(token, orderId).then((res) => {
        if (res.status) {
          let filteredInvoices = res?.data?.filter((item) => item.isSent);
          setInvoicesForOrder(filteredInvoices);
        }
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  function isUrlValid(str) {
    str = str ? str : "";
    if (!str.startsWith("http://") && !str.startsWith("https://")) {
      str = "http://" + str;
    }
    var a = document.createElement("a");
    a.href = str;
    return a.host && a.host != window.location.host;
  }
  const handleTrackingDetailsClose = () => {
    setShowDeliveryTrackingInformation(false);
  };

  const handleDeliveryTrackingNumber = async () => {
    try {
      let userId = localStorage.getItem("_id");
      if (window.location.host === "make.8xparts.com") {
        // window?.dataLayer?.push({
        //   event: "trackShipment",
        //   userId: userId,
        //   salesOrderRefId: orders.RefId,
        //   referenceId:
        //     email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
        // });
      }
      if (!isNaN(deliveryTrackingLink)) {
        const externalLink = `https://www.dhl.com/ie-en/home/tracking/tracking-express.html?submit=1&tracking-id=${deliveryTrackingLink}`;
        window.open(externalLink, "_blank");
        // if(Object.keys(dhlShipmentInformation).length===0){
        //   let response = await getTrackingDetails(deliveryTrackingLink)
        //   setDhlShipmentInformation(response.shipments[0])
        // }
        // setShowDeliveryTrackingInformation(true);
      }
    } catch (err) {
      toast.error("Please enter valid tracking details");
    }
  };

  const handleTabChange = (key) => {
    if (key === "quote-version") {
      navigate("/quote-versions/" + orders?.quoteId?.RefId);
    }
  };

  async function fetchData() {
    try {
      let poRelatedIssueResponse = await getPurchaseOrderRelatedIssueUser(
        token
      );
      let qualityRelatedIssueResponse = await getQualityRelatedIssueUser(token);
      let allIssue = await getIssues(token);

      let localOrderIssue = [];
      if (allIssue.data.length > 0) {
        allIssue.data.map((item, i) => {
          if (item?.orderId?._id === (state?.order?._id || _id)) {
            localOrderIssue.push(item);
          }
        });
      }
      if (localOrderIssue.length > 0) {
        setExistingIssue(localOrderIssue[0]);
      }

      setOrderRelatedIssue(poRelatedIssueResponse.data.data);
      setQualityRelatedIssue(qualityRelatedIssueResponse.data.data);
    } catch (err) {
      console.log(
        "error in order details 222 on" +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
      return err;
    }
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let handleFrmURL = (url, name) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  let handleDownload = () => {
    // let additionalCost = orders?.quoteId?.selectedShipMethod==0?orders?.quoteId?.additionalCost1:orders?.quoteId?.selectedShipMethod==1?orders?.quoteId?.additionalCost2:orders?.quoteId?.additionalCost3;
    // let shippingCharge = orders?.quoteId?.selectedShipMethod==0?orders?.quoteId?.shippingCharge1:orders?.quoteId?.selectedShipMethod==1?orders?.quoteId?.shippingCharge2:orders.quoteId?.shippingCharge3;
    if (!orders.expectedDelivery) {
      alert("Invoice will be downloadable once the order is delivered");
      return;
    }

    // let userId = localStorage.getItem('_id');
    // if (window.location.host === 'make.8xparts.com') {
    //   window?.dataLayer?.push({
    //     event: 'downloadInvoice',
    //     userId: userId,
    //     salesOrderRefId: orders?.RefId,
    //     invoiceNumber: 'ToBeImplementedLater',
    //   })
    // }

    let data = {
      fname: orders?.userId.firstName,
      lname: orders?.userId.lastName,
      email: orders?.userId.email,
      orderId: orders?.RefId,
      total: orders?.subTotal - orders?.additionalCost - orders?.shippingCharge,
      shipToFirstName: orders?.shippingAddress?.firstName,
      shipToLastName: orders?.shippingAddress?.lastName,
      addressline1: orders?.shippingAddress?.addressLineOne,
      addressline2: orders?.shippingAddress?.addressLineTwo,
      city: orders?.shippingAddress?.city,
      state: orders?.shippingAddress?.state,
      country: orders?.shippingAddress?.country,
      zipcode: orders?.shippingAddress?.postalCode,
      partData: JSON.stringify(orders?.quoteId.partsData),
      process: orders?.quoteId.selectedProcess,
      price: orders?.quoteId.price,
      price1: orders?.quoteId.price1,
      price2: orders?.quoteId.price2,
      selectedShipMethod: orders?.quoteId.selectedShipMethod,
      sheetMetalAndInjectionDesc: orders?.quoteId.sheetAndInjectionDiscription,
      sheetMetalAndInjectionMP: orders?.quoteId.sheetAndInjectionManualPrice,
      sheetAndInjectionQuantity: orders?.quoteId.sheetAndInjectionQuantity,
      forInvoice: true,
      invoiceDate: orders?.expectedDelivery
        ? orders.expectedDelivery
        : orders?.createdAt,
      hidePrice: orders?.quoteId.hidePrice,
      poNumber: orders?.purchaseOrderNumber,
      isQrTrackingRequested: orders?.quoteId?.isQrTrackingRequested,
      isDimensionalReportRequested:
        orders?.quoteId?.isDimensionalReportRequested,
      isConformanceCertificateRequested:
        orders?.quoteId?.isConformanceCertificateRequested,
      additionalCost: orders?.additionalCost,
      shippingCharge: orders?.shippingCharge,
      leadTime:
        orders?.quoteId?.selectedShipMethod == 0
          ? orders?.quoteId.leadTime1
          : orders?.quoteId?.selectedShipMethod == 1
          ? orders?.quoteId.leadTime2
          : orders?.quoteId.leadTime3,
      projectName: orders?.quoteId.projectName,
      adjustmentValue: orders?.adjustmentValue,
    };

    downloadPdf(data, token)
      .then((res) => {
        fileSaver.saveAs(
          `${config.backEnd}/public/docs/` + res?.data?.data,
          `invoice-${orders?.RefId}.pdf`
        );
      })
      .catch((err) => {
        console.log(
          "error in order details 288 on:: " +
            moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
          err
        );
      });
  };

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      decode = jwt_decode(token);
      if (!decode) {
        navigate("/login");
        toast.error("Invalid Token found!");
      } else if (decode) {
        if (decode.userType == "user") {
          // getProfileDetails(token)
          fetchData();
        } else if (decode.userType == "admin") {
          navigate("/admin");
          // toast.error('Unauthorized Access!');
        } else {
          navigate("/login");
          // toast.error('Unauthorized Access!');
        }
      }
    }
  }, [orders]);

  const handleRaiseIssueModal = () => {
    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "raiseIssue",
      //   userId: userId,
      //   salesOrderRefId: orders.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }
    setRaiseIssueModal(true);
  };

  const handleQualityDocModal = (invoice) => {
    setInvoiceToViewDocs(invoice);
    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "viewQualityDocument",
      //   userId: userId,
      //   salesOrderRefId: orders?.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }
    setQualityDocModal(true);
  };
  const handleConsignmentModal = (invoice) => {
    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "viewConsignmentPhotos",
      //   userId: userId,
      //   salesOrderRefId: orders.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }
    if (invoice.consignmentPhotos.length > 0) {
      setInvoiceToViewDocs(invoice);
      setConsignmentModal(true);
    } else {
      toast.error(
        "Consignment photos pending upload. Kindly wait as we prepare them. Thank you for your patience."
      );
    }
  };
  const handleSelectPartWithIssue = (index) => {
    if (!isPartIssue) {
      toast.error(
        'You cannot select any parts when you have selected the "Not a part issue. Purchase/ order related issue" checkbox '
      );
      return;
    }
    const localPartsWithIssue = [...partsWithIssue];
    localPartsWithIssue[index].selected = !localPartsWithIssue[index].selected;
    setPartsWithIssue(localPartsWithIssue);
  };

  const handleQualityRelatedIssueSelect = (index) => {
    const localQualityRelatedIssue = [...qualityRelatedIssue];
    localQualityRelatedIssue[index].selected =
      !localQualityRelatedIssue[index].selected;
    setQualityRelatedIssue(localQualityRelatedIssue);
  };

  const handlePORelatedIssueSelect = (index) => {
    const localPORelatedIssues = [...orderRelatedIssue];
    localPORelatedIssues[index].selected =
      !localPORelatedIssues[index].selected;
    setOrderRelatedIssue(localPORelatedIssues);
  };

  const handleNotPartIssueSelect = () => {
    setIsPartIssue(!isPartIssue);
    if (isPartIssue) {
      partsWithIssue.forEach((item) => {
        item.selected = false;
      });
    }
  };

  const handleSubmitIssue = async () => {
    let qualityRelatedIssueSelected = qualityRelatedIssue.filter(
      (item) => item.selected
    );
    let orderRelatedIssueSelected = orderRelatedIssue.filter(
      (item) => item.selected
    );
    let partsWithIssueSelected = partsWithIssue.filter((item) => item.selected);

    let formData = new FormData();
    formData.append("orderId", orders?._id);
    formData.append("orderRefId", orders?.RefId);
    formData.append(
      "qualityRelatedIssue",
      JSON.stringify(qualityRelatedIssueSelected)
    );
    formData.append(
      "orderRelatedIssue",
      JSON.stringify(orderRelatedIssueSelected)
    );
    formData.append("isPartIssue", isPartIssue);
    formData.append("partsWithIssue", JSON.stringify(partsWithIssueSelected));
    formData.append("issueDescription", issueDescription);

    for (let i = 0; i < issueFileAttachment.length; i++) {
      formData.append("issueFileAttachment", issueFileAttachment[i]);
    }

    try {
      setRaiseIssueModal(false);
      let response = await submitRaiseIssue(token, formData);
      if (
        response.statusCode == 200 &&
        Object.keys(response.data) &&
        response.data.orderId
      ) {
        setExistingIssue(response.data);
      }
      if (response.statusCode === 200) {
        toast.success("Issue submitted successfully");
        setIsPartIssue(true);
        setIssueDescription("");
        setQualityRelatedIssue([]);
        setPartsWithIssue([]);
        setOrderRelatedIssue([]);
        setIssueFileAttachment([]);
        setRaiseIssueStepCount(0);
      }
    } catch (err) {
      toast.error(
        "Something went wrong while submitting the issue.Please try again later"
      );
    }
  };

  const handleRaiseIssueNext = () => {
    if (raiseIssueStepCount == 2) {
      if (issueDescription == "" || issueDescription == false) {
        toast.error("Issue description is required.");
        return;
      }
      let response = confirm("Are you sure you want to submit the issue?");
      if (response) {
        handleSubmitIssue();
      }
    } else {
      let countOfSelectPartIssue = partsWithIssue.reduce((acc, item) => {
        if (item.selected) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);

      if (countOfSelectPartIssue === 0 && isPartIssue) {
        toast.error(
          "If there is no issue in any parts, kindly select the not a part issue option"
        );
        return;
      }

      setRaiseIssueStepCount(raiseIssueStepCount + 1);
    }
  };

  const handleRaiseIssueBack = () => {
    if (raiseIssueStepCount == 0) {
    } else {
      setRaiseIssueStepCount(raiseIssueStepCount - 1);
    }
  };

  const handleRaiseIssueClose = () => {
    setRaiseIssueModal(false);
  };

  const handleViewQualityDocClose = () => {
    setInvoiceToViewDocs(null);
    setQualityDocModal(false);
  };

  const handleViewConsignmentClose = () => {
    setInvoiceToViewDocs(null);
    setConsignmentModal(false);
  };

  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  const handleIssueFileAttachmentAdd = (files) => {
    let filesForCheck = Array.from(files);
    let isAllFilesValid = true;
    filesForCheck.forEach(file => {
        if (file.size > 10485760) {
          isAllFilesValid = false;
        }
      })

    if (!isAllFilesValid) {
      toast.error("File size should be less than 10 MB");
      return;
    }

    let localIssueAttachment = [...issueFileAttachment];
    const filesUploaded = Object.values(files);
    for (let i = 0; i < filesUploaded.length; i++) {
      localIssueAttachment.push(filesUploaded[i]);
    }
    setIssueFileAttachment(localIssueAttachment);
  };

  const handleDeleteIssueAttachment = (index) => {
    let localIssueAttachment = [...issueFileAttachment];
    localIssueAttachment.splice(index, 1);
    setIssueFileAttachment(localIssueAttachment);
  };

  const handleRating = async (rating, key) => {
    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "submitFeedback",
      //   userId: userId,
      //   salesOrderRefId: orders.RefId,
      // });
    }
    setOrders({ ...orders, [key]: rating });
    const response = await updateOrderRating(token, {
      orderId: orders?._id,
      [key]: rating,
    });
  };

  const handleFeedBackCommentChange = (e) => {
    setOrders({ ...orders, feedBackComment: e.target.value });
  };

  const handleFeedBackSubmit = async () => {
    setLoading(true);
    try {
      let userId = localStorage.getItem("_id");
      if (window.location.host === "make.8xparts.com") {
        // window?.dataLayer?.push({
        //   event: "submitFeedback",
        //   userId: userId,
        //   salesOrderRefId: orders.RefId,
        // });
      }
      const response = await handleSubmitFeedBack(token, orders);
      if (response.status == 200) {
        toast.success("Feedback submitted successfully");
      } else {
        toast.error("Something went wrong while submitting feedback");
      }
      
    } catch (error) {
      toast.error("Failed To Submit FeetBack");
    }
    finally{
      setLoading(false);
    }
   
  };

  const handleTeamModal = async (selectedQuote) => {
    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "teamCollaboration",
      //   userId: userId,
      //   quoteRefId: selectedQuote.RefId,
      // });
    }
    setSelectedQuoteForTeam(selectedQuote);
    setTeamMemberModalShow(true);
  };

  const handleDownloadCOC1 = async () => {
    try {
      const response = await handleDownloadCocForOrder(orders._id);
      if (response.status) {
        let url = `${config.backEnd}/public/docs/${response.data}`;
        download(`Certificate_Of_Conformace-${orders?.RefId}.pdf`, url);
      }
    } catch (err) {
      toast.error("Something went wrong while downloading the pdf file.");
      console.log(
        "err in order admin 952 on" +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleDownloadCOC = async () => {
    try {
      const response = await handleDownloadCocForInvoice(
        token,
        invoiceToViewDocs._id
      );

      if (response.status) {
        let url = `${config.backEnd}/public/docs/${response.data}`;
        download(
          `Certificate_Of_Conformace-${invoiceToViewDocs.invoiceNumber}.pdf`,
          url
        );
      }
    } catch (err) {
      toast.error("Something went wrong while downloading the pdf file.");
      console.log(
        "err in order admin 952 on" +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const updateQuoteData = (selectedQuote) => {
    setOrders({ ...orders, quoteId: selectedQuote });
  };

  let handleDownloadInvoice = async (invoice) => {
    setPdfDownloadingLoading(true);
    try {
      let userId = localStorage.getItem("_id");
      if (window.location.host === "make.8xparts.com") {
        // window?.dataLayer?.push({
        //   event: "downloadInvoice",
        //   userId: userId,
        //   salesOrderRefId: orders?.RefId,
        //   invoiceNumber: invoice.invoiceNumber,
        //   referenceId:
        //     email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
        // });
      }
      const downloadInvoiceResponse = await handleDownloadIndividualInvoice(
        token,
        invoice._id
      );
      if (downloadInvoiceResponse.status) {
        // download(invoiceDetailState.invoiceData.invoiceNumber,downloadInvoiceResponse.data);
        if (downloadInvoiceResponse.status) {
          let url = `${config.backEnd}/public/docs/${downloadInvoiceResponse.data}`;
          download(`Invoice-${invoice.invoiceNumber}.pdf`, url);
        }
      }
    } catch (err) {
      toast.error("Failed to download invoice!");
    } finally {
      setPdfDownloadingLoading(false);
    }
  };

  return (
    <div className="content-wrapper">
      {loading && <Loading>Downloading Please Wait ...</Loading>}
      <Header />
      {!isValidUser ? (
        <>
          <div className="dashboard-holder">
            <div className="sm-container">
              <div className="dashboard-option">
                <div className="dash-left">
                  <div className="ds-heading">
                    <Link to="/saved-quote">
                      <p className="req-quote-btn">Go To Saved Quotes </p>
                    </Link>
                  </div>
                </div>

                <div style={{ textAlign: "center" }}>
                  <h2>Order information is not available</h2>
                  <br />
                  <p>Your dont have access to this order details.</p>
                </div>
              </div>
              <div className="privacy-text">
                <strong>Privacy:</strong> 8xParts 3D secures all uploaded files,
                protecting your intellectual property. If you can’t upload your
                files at the moment, use our sample part to try out our instant
                quoting and DfM tools or contact us on{" "}
                <a href="mailto: info@8xparts.com">info@8xparts.com</a> to sign
                an NDA.
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Chat
            RefId={state?.order?.RefId || orders.RefId}
            open={open}
            onDrawerClose={onClose}
          />

          <TeamCollaboration
            teamMemberModalShow={teamMemberModalShow}
            selectedQuoteForTeam={selectedQuoteForTeam}
            setTeamMemberModalShow={setTeamMemberModalShow}
            fetchDataForUser={() => null}
            updateQuoteData={updateQuoteData}
          />
          <Modal
            size="sm"
            dialogClassName="qoute-version-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={consignmentModal}
            onHide={handleViewConsignmentClose}
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{ fontSize: "15px" }}
              >
                Pre-Consignment Photos
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="raise-issue-div ">
                {invoiceToViewDocs?.consignmentPhotos?.length > 0 ? (
                  <ConsignmentPhotoViewer
                    images={invoiceToViewDocs?.consignmentPhotos}
                    altText={"consignment-photo"}
                    handleConsignmentFileDelete={null}
                    serverType="customer"
                  />
                ) : (
                  <></>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="raise-issue-footer" style={{ width: "100%" }}>
                <span className="left-modal-footer"></span>
                <div>
                  <div
                    onClick={handleViewConsignmentClose}
                    className="req-quote-btn"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    Close
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
          <Modal
            size="sm"
            dialogClassName="qoute-version-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={qualityDocModal}
            onHide={handleViewQualityDocClose}
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{ fontSize: "15px" }}
              >
                Quality Documents
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="raise-issue-div ">
                <div className="col">
                  <>
                    <table>
                      <tbody>
                        <tr>
                          <th
                            style={{ textAlign: "left", paddingLeft: "20px" }}
                          >
                            <p> Document Name</p>{" "}
                          </th>
                          <th
                            style={{ textAlign: "left", paddingLeft: "20px" }}
                          >
                            <p> File </p>{" "}
                          </th>
                          <th
                            style={{ textAlign: "left", paddingLeft: "20px" }}
                          >
                            <p> Download</p>
                          </th>
                        </tr>
                        {invoiceToViewDocs?.qualityDocuments.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td
                                style={{
                                  textAlign: "left",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  width: "300px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <p>{item.name}</p>
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  width: "300px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <p>{item?.file?.originalname}</p>
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  width: "300px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <button
                                  className="white-btn"
                                  onClick={() =>
                                    download(
                                      item?.file?.originalname,
                                      `${config.backEnd}/` + item?.file?.path
                                    )
                                  }
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              width: "300px",
                              paddingLeft: "20px",
                            }}
                          >
                            <p>Certificate Of Conformance</p>
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              width: "300px",
                              paddingLeft: "20px",
                            }}
                          >
                            <p>{`Certificate_Of_Conformace-${orders?.RefId}.pdf`}</p>
                          </td>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              width: "300px",
                              paddingLeft: "20px",
                            }}
                          >
                            <button
                              className="white-btn"
                              onClick={handleDownloadCOC}
                            >
                              Download
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="raise-issue-footer" style={{ width: "100%" }}>
                <span className="left-modal-footer"></span>
                <div>
                  <div
                    onClick={handleViewQualityDocClose}
                    className="req-quote-btn"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    Close
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
          <Modal
            size="sm"
            dialogClassName="qoute-version-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={raiseIssueModal}
            onHide={handleRaiseIssueClose}
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{ fontSize: "15px" }}
              >
                Raise an issue
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="raise-issue-div ">
                <div className="progress-dots">
                  {/* +(raiseIssueStepCount===2)?'active':(raiseIssueStepCount>2)?"complete":"inactive" */}
                  <span
                    className={
                      raiseIssueStepCount === 0
                        ? "dot active"
                        : raiseIssueStepCount > 0
                        ? "dot complete"
                        : "dot inactive"
                    }
                  ></span>
                  <span
                    className={
                      raiseIssueStepCount === 1
                        ? "dot active"
                        : raiseIssueStepCount > 1
                        ? "dot complete"
                        : "dot inactive"
                    }
                  ></span>
                  <span
                    className={
                      raiseIssueStepCount === 2
                        ? "dot active"
                        : raiseIssueStepCount > 2
                        ? "dot complete"
                        : "dot inactive"
                    }
                  ></span>
                </div>
                <h5>
                  Step {`${raiseIssueStepCount + 1}`} of 3 :{" "}
                  {raiseIssueSteps[raiseIssueStepCount]}
                </h5>
                {raiseIssueStepCount === 0 ? (
                  <div className="raise-issue-part-div">
                    {partsWithIssue?.map((item, index) => {
                      return (
                        <div key={index} className="raise-issue-item">
                          <div className="price-box">
                            <div
                              className={
                                item.selected ? "box-pr pr-checked" : "box-pr"
                              }
                              onClick={(e) => handleSelectPartWithIssue(index)}
                            >
                              <div className="pr-radio">
                                <label
                                  className="btn box-in"
                                  htmlFor="btnradio4"
                                >
                                  <span className="triangle-0">
                                    <img src={tickwhite} />
                                  </span>
                                  <span className="item-name">
                                    {item.file?.filename
                                      ? item.file?.filename
                                      : item.file?.originalname
                                      ? item.file?.originalname
                                      : "Item Description (No file was uploaded):\n" +
                                        item.description}
                                  </span>
                                  <span className="qty-div">
                                    Qty:{item.Qty}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="raise-issue-check-div">
                      <div className="rd-left rd-box">
                        <input
                          type="checkbox"
                          id="testA"
                          name="radio-group"
                          checked={!isPartIssue}
                          onChange={handleNotPartIssueSelect}
                        />
                        <label htmlFor="testA">
                          Not a part issue. Purchase/ order related issue
                        </label>
                      </div>
                    </div>
                  </div>
                ) : raiseIssueStepCount === 1 ? (
                  <div>
                    <div className="quality-related-issue">
                      <p>Quality related</p>
                      {qualityRelatedIssue.length > 0
                        ? qualityRelatedIssue.map((item, index) => {
                            return (
                              <div key={index} className="raise-issue-item">
                                <div className="price-box">
                                  <div
                                    className={
                                      !item.selected
                                        ? "box-pr"
                                        : "box-pr pr-checked"
                                    }
                                    onClick={(e) =>
                                      handleQualityRelatedIssueSelect(index)
                                    }
                                  >
                                    <div className="pr-radio">
                                      <label
                                        className="btn box-in"
                                        htmlFor="btnradio4"
                                      >
                                        <span className="triangle-0">
                                          <img src={tickwhite} />
                                        </span>
                                        <span className="item-name">
                                          {item.name}
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>

                    <div className="quality-related-issue">
                      <p>Purchase/Order related</p>
                      {orderRelatedIssue.length > 0
                        ? orderRelatedIssue.map((item, index) => {
                            return (
                              <div key={index} className="raise-issue-item">
                                <div className="price-box">
                                  <div
                                    className={
                                      !item.selected
                                        ? "box-pr"
                                        : "box-pr pr-checked"
                                    }
                                    onClick={(e) =>
                                      handlePORelatedIssueSelect(index)
                                    }
                                  >
                                    <div className="pr-radio">
                                      <label
                                        className="btn box-in"
                                        htmlFor="btnradio4"
                                      >
                                        <span className="triangle-0">
                                          <img src={tickwhite} />
                                        </span>
                                        <span className="item-name">
                                          {item.name}
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                ) : (
                  <div className="issue-description-div">
                    <div className="issue-description">
                      <textarea
                        placeholder="Write issue description here"
                        value={issueDescription}
                        onChange={(e) => setIssueDescription(e.target.value)}
                      />
                    </div>
                    <div className="downloadables">
                      <input
                        type="file"
                        id="BtnBrowseHidden"
                        name="files"
                        style={{ display: "none" }}
                        multiple
                        onChange={(e) => {
                          handleIssueFileAttachmentAdd(e.target.files);
                        }}
                      />
                      <label htmlFor="BtnBrowseHidden" id="LblBrowse">
                        <p>+ Add Attachment</p>
                      </label>

                      {issueFileAttachment.length > 0
                        ? issueFileAttachment.map((item, i) => {
                            return (
                              <div key={i} className="issue-file-div">
                                {item.name}
                                <img
                                  src={closeImg}
                                  height="15px"
                                  onClick={() => {
                                    handleDeleteIssueAttachment(i);
                                  }}
                                />
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="raise-issue-footer" style={{ width: "100%" }}>
                <span
                  className="left-modal-footer"
                  onClick={() => (window.location = "mailto:info@8xparts.com")}
                >
                  Contact Customer Service
                </span>
                <div>
                  <span
                    className="raise-issue-back-btn"
                    onClick={handleRaiseIssueBack}
                  >
                    Back
                  </span>
                  <div
                    onClick={handleRaiseIssueNext}
                    className="req-quote-btn"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    {raiseIssueStepCount === 2 ? "Submit" : "Next"}
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>

          <div className="intro-bar">
            <div
              className="int-left"
              style={{ display: "flex", flexWrap: "wrap", flex: "0 0 34%" }}
            >
              <div
                className="left-item my-2"
                style={{ marginRight: "20px", cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <img src={backImg} height="15px" />
              </div>

              <div className="left-item">
                <h3>Order details</h3>
                <p>Order No. #{state?.order?.RefId || orders?.RefId}</p>
              </div>
            </div>

            <div
              className="int-right"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <button
                onClick={() => handleTeamModal(orders?.quoteId)}
                className=" px-10 border rounded  py-2 flex gap-x-2 items-center  hover:bg-slate-100"
              >
                <img src={teamImage} alt="download" className="h-5" />
                <span className="text-primaryColor">Team</span>
              </button>
              <div
                className="px-10 border rounded  py-2 hover:bg-slate-100"
                style={{ cursor: "pointer" }}
                onClick={showDrawer}
              >
                <ChatButton
                  RefId={state?.order?.RefId || orders.RefId}
                  showDrawer={showDrawer}
                  additionalClass={
                    "bg-transparent flex gap-x-2 items-stretch !text-primaryColor"
                  }
                />
              </div>

              {/* {(orders?.status == 'refunded' || orders?.status == 'cancelled') ? <></> :
                <>
                  <button onClick={handleDownload} className="chat-btn white-btn">
                    <img
                      src={DownloadImage}
                      alt="download"
                      style={{ paddingRight: "5px", paddingBottom: "2px" }}
                    />
                    Invoice
                  </button>
                </>} */}
            </div>
          </div>

          <div className="dashboard-holder">
            <div className="md-container">
              <div className="common-bx">
                <div className="process-bar-bx  upload-req green-line or-details-row">
                  <span className="process-ico green-fill">
                    <img src={settingw} />
                  </span>
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    className="requirement-box"
                  >
                    <AccordionSummary
                      // expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <div className="accordion-title">
                        <div className="accordion-icon">
                          <span
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <h5>Requirements uploaded</h5>
                              <div className="cnc-box">
                                <p>
                                  {moment(orders?.quoteId?.createdAt).format(
                                    "DD MMM YYYY"
                                  )}
                                </p>
                                <div className="cnc-box">
                                  {processType.map((val, i) => {
                                    if (
                                      val.id == orders?.quoteId?.selectedProcess
                                    ) {
                                      return <p key={i}>{val.name}</p>;
                                    } else {
                                      return;
                                    }
                                  })}
                                </div>
                              </div>
                            </span>
                            <div style={{ marginRight: "30px" }}>
                              <span
                                className="text"
                                style={{ color: "#858585" }}
                              >
                                Project Name
                              </span>
                              <div className="name">
                                {orders?.quoteId?.projectName || "Not Found"}
                              </div>
                            </div>
                          </span>
                          <img
                            src={
                              expanded === "panel1"
                                ? upArrowIcon
                                : downArrowIcon
                            }
                            alt="arrowIcon"
                            style={{ height: "8px", marginRight: "10px" }}
                          />
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {orders?.quoteId ? (
                        <div className="version-tabs">
                          <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => {
                              setKey(k);
                              handleTabChange(k);
                            }}
                            className="mb-3 qoute-version-tabs"
                          >
                            <Tab eventKey="final-order" title={`Final Order`}>
                              <OrderTable
                                selectedQuote={orders?.quoteId}
                                selectedShipmentMethod={
                                  orders?.quoteSelectedShipMethod
                                }
                                orderDetails={orders}
                              />
                            </Tab>
                            <Tab
                              eventKey="quote-version"
                              title={`View Quotation Versions`}
                            ></Tab>
                          </Tabs>
                        </div>
                      ) : (
                        <>
                          <p>Loading</p>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="process-bar-bx  upload-req green-line or-details-row">
                  <span className="process-ico green-fill">
                    <img src={orderIcon} />
                  </span>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <div className="accordion-title">
                        <div className="accordion-icon">
                          <span
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <h5>Order and Payment</h5>
                              <div className="cnc-box">
                                <p>
                                  <p>
                                    Order placed on{" "}
                                    {moment(orders?.createdAt).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                </p>
                              </div>
                            </span>
                            <div
                              style={{
                                marginRight: "30px",
                                display: "flex",
                                width: "50%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="shipto">
                                <span className="ship">Ship to</span>
                                <div className="name">
                                  {orders.shippingAddress?.firstName
                                    ? orders.shippingAddress?.firstName +
                                      " " +
                                      orders.shippingAddress?.lastName
                                    : "Loading"}
                                </div>
                              </div>
                              <div className="total">
                                <span
                                  className="text"
                                  style={{ color: "#858585" }}
                                >
                                  Total
                                </span>
                                <div className="amount">
                                  {" "}
                                  {orders.subTotal
                                    ? currencyFormatter(
                                        orders?.tax
                                          ? (
                                              orders?.subTotal +
                                              (orders?.subTotal * orders?.tax) /
                                                100 +
                                              Number(orders.adjustmentValue)
                                            ).toFixed(2)
                                          : orders?.subTotal +
                                              Number(orders.adjustmentValue)
                                      )
                                    : "Loading"}
                                </div>
                              </div>
                            </div>
                          </span>
                          <img
                            src={
                              expanded === "panel2"
                                ? upArrowIcon
                                : downArrowIcon
                            }
                            alt="arrowIcon"
                            style={{ height: "8px", marginRight: "10px" }}
                          />
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="pro-bar">
                        <div className="pro-text">
                          <div className="pro-bar-one"></div>
                          <div className="pro-bar-two">
                            <div className="col" style={{ marginTop: "10px" }}>
                              <h6>Shipping Address:</h6>
                              <div className="desc-part">
                                <p>
                                  {orders.shippingAddress?.firstName +
                                    " " +
                                    orders.shippingAddress?.lastName}
                                </p>

                                <p>{orders.shippingAddress?.addressLineOne}</p>

                                <p>{orders.shippingAddress?.addressLineTwo}</p>

                                <p>
                                  {orders.shippingAddress?.city +
                                    ", " +
                                    orders.shippingAddress?.state +
                                    ", " +
                                    orders.shippingAddress?.country}
                                </p>

                                <p>{orders.shippingAddress?.postalCode}</p>
                              </div>
                            </div>

                            <div className="col" style={{ marginTop: "10px" }}>
                              <h6>Payment Information</h6>
                              <p>
                                {orders?.transactionId
                                  ? "Card Payment"
                                  : "Pay later was selected for this part"}
                              </p>
                            </div>
                            <div className="col" style={{ marginTop: "10px" }}>
                              <h6>PO Number</h6>
                              <p>
                                {orders?.purchaseOrderNumber ||
                                  "Not Updated by Admin yet"}
                              </p>
                            </div>
                            <div className="col" style={{ marginTop: "10px" }}>
                              <h6>PO Document</h6>
                              <p
                                style={{ color: "dodgerblue" }}
                                className="flex justify-start my-2 gap-x-2"
                              >
                                {orders?.poDocumentFile ? (
                                  <div
                                  onClick={() =>
                                    handleFrmURL(
                                      `${config.backEnd}/` +
                                        orders?.poDocumentFile?.path,
                                      orders?.poDocumentFile?.originalname
                                    )
                                  }
                                  
                                  className="flex gap-x-2 cursor-pointer">
                                    <span>
                                    {orders?.poDocumentFile?.originalname}{" "}
                                    </span>
                                    <span
                                     
                                    >
                                      <DownloadIcon additionalClass={'h-4'}/>
                                     
                                    </span>

                                  </div>
                                ) : (
                                  "Not Updated by Admin yet "
                                )}
                              </p>
                            </div>
                            <div className="accordion-total">
                              <h6>Order Summary</h6>
                              <div className="subtotal">
                                <p>Item(s) Subtotal : </p>
                                <p>
                                  {" "}
                                  {currencyFormatter(
                                    Number(
                                      orders?.subTotal -
                                        orders?.shippingCharge -
                                        orders?.additionalCost
                                    ).toFixed(2)
                                  )}
                                </p>
                              </div>
                              <div className="subtotal">
                                <p>Shipping & Duties : </p>
                                <p>
                                  {" "}
                                  {currencyFormatter(orders?.shippingCharge)}
                                </p>
                              </div>

                              {orders?.quoteId?.isQrTrackingRequested ? (
                                <>
                                  <div className="subtotal">
                                    <p>QR Tracking : </p>
                                    <p>
                                      {currencyFormatter(
                                        orders?.additionalCost
                                      )}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              <div className="subtotal">
                                <p>Taxes ( {orders?.tax}% ) : </p>
                                <p>
                                  {" "}
                                  {orders?.tax > 0
                                    ? currencyFormatter(
                                        (
                                          (orders?.subTotal * orders?.tax) /
                                          100
                                        )?.toFixed(2)
                                      )
                                    : 0}
                                </p>
                              </div>

                              {orders?.adjustmentValue != 0 ? (
                                <>
                                  <div className="subtotal">
                                    <p>Adjustments : </p>
                                    <p>
                                      {currencyFormatter(
                                        orders?.adjustmentValue
                                      )}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              <div className="total">
                                <p>Total : </p>
                                <p>
                                  {currencyFormatter(
                                    (
                                      orders?.subTotal +
                                      (orders?.subTotal * orders?.tax) / 100 +
                                      Number(orders.adjustmentValue)
                                    ).toFixed(2)
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                {/* <div className={orders?.shippedDate?"process-bar-bx upload-req or-details-row green-line":"process-bar-bx upload-req or-details-row grey-line" }>
              <span className={orders?.shippedDate?"process-ico green-fill":"process-ico grey-fill"}>
                <img src={shippingIcon} />
              </span>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel2bh-header"
                >
                  <div>
                    <div className="accordion-title">
                      <div className="accordion-icon">
                        <h5>Shipping & Duties</h5>
                        <img
                          src={
                            expanded === "panel3" ? upArrowIcon : downArrowIcon
                          }
                          alt="arrowIcon"
                          style={{height:'8px',marginRight:'10px'}}
                        />
                      </div>
                    </div>
                    <div className='shipping-details'>
                      <p>{orders?.shippedDate && new Date(orders?.shippedDate) > new Date(Date.now())
                        ? `Shipping by ${moment(
                          orders?.shippedDate
                        ).format("DD MMM YYYY")}`
                        : orders?.shippedDate 
                          ? `Shipping on ${moment(
                            orders?.shippedDate
                          ).format("DD MMM YYYY")}`
                          : "Shipping Pending"}</p>
                    </div>  
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                <div className="or-details-row">
                <div className="right-btns">
                      {orders?.consignmentPhoto?.length > 0?
                        <button className="white-btn" style={{marginLeft:'auto'}} onClick={handleConsignmentModal}>View Pre-Consignment Photos</button>
                      :
                        <></>
                      }
                    </div>
                  <div className="pro-bar">
                    <div className="pro-text">
                      <div className="pro-bar-one"></div>

                      <div className="pro-bar-two">
                        <div className="col">
                          <h6>Shipping Date</h6>
                          <div className="desc-part">
                            <p>
                              {orders?.shippedDate &&
                                orders?.isCompleted == false
                                ? `${moment(
                                  orders?.shippedDate
                                ).format("DD MMM YYYY")}`
                                : orders?.shippedDate
                                  ? `${moment(
                                    orders?.shippedDate
                                  ).format("DD MMM YYYY")}`
                                  : "Shipping Pending"}
                            </p>
                          </div>
                        </div>
                        <div className="col">

                          {
                            isUrlValid(deliveryTrackingLink)?
                            <a className="trk-order" target="_blank" href={deliveryTrackingLink} onClick={()=> {
                              let userId = localStorage.getItem('_id');
                              if (window.location.host === 'make.8xparts.com') {
                              window?.dataLayer?.push({
                                event: 'trackShipment',
                                userId: userId,
                                salesOrderRefId:orders.RefId,
                              })
                              }
                            }}>Track Order</a>
                            :(!isNaN(deliveryTrackingLink) && deliveryTrackingLink!='')? <div className="trk-order" onClick={handleDeliveryTrackingNumber}>Track Order</div>
                            :deliveryTrackingLink!=''?<div>  
                              <div className="trk-unavailable">
                              <div> <p>Order Tracking Information :</p></div> 
                                <div> <p>{deliveryTrackingLink}</p></div>                                  
                               </div>  
                            </div>
                            :
                            <div className="trk-unavailable">  
                              <button disabled className="">Order Tracking Unavailable</button>
                              <div> <p>*You will be able to track the order once tracking detail is updated by admin</p></div>  
                            </div>
                          }
                        </div>
                      </div>

                      {
                  (Object.keys(dhlShipmentInformation).length>0 && showDeliveryTrackingInformation)?
                  <div className="shipment-details">
                    <div className="shipment-details-header">
                      <div>
                          <p>Waybill Number : <strong>{" "+dhlShipmentInformation.id}</strong></p>
                          <p>Status : {dhlShipmentInformation.status?.description}</p>
                          {dhlShipmentInformation.status?.status==="delivered"?<a href={dhlShipmentInformation.details.proofOfDelivery?.documentUrl}>Get Proof of Delivery</a>:null}
                      </div>
                      <div>
                        <p>
                          {new Date(dhlShipmentInformation.events[0]?.timestamp).toLocaleString('en-US', {
                            dateStyle: 'full',
                            timeStyle: 'short',
                            hour12: false,
                          }) }
                        </p>

                        <span>
                          <p>Origin service area : </p>
                          <a href={dhlShipmentInformation.origin.servicePoint?.url}><h6>{dhlShipmentInformation.origin.address?.addressLocality}</h6></a>
                        </span>

                        <span>
                          <p>Destination Service Area:</p>
                          <a href={dhlShipmentInformation.destination.servicePoint?.url}><h6>{dhlShipmentInformation.destination.address?.addressLocality}</h6></a>
                        </span>
                      </div>
                        
                      <div>
                        {dhlShipmentInformation.details.pieceIds?.length} Piece/Pieces <img src={showShipmentPartsData?upArrowIcon:downArrowIcon} onClick={() => {
                                                                                        setShowShipmentPartsData(!showShipmentPartsData)
                                                                                      }}
                                                                                      />
                        {showShipmentPartsData?
                        <>
                          {dhlShipmentInformation.details.pieceIds.map((item,i)=>{
                             return <p style={{fontSize:"12px"}}>{item}</p>
                          })}
                        </>
                        :null}
                      </div>
                    </div>



                    <div className="tracking-details-div">
                          <table>
                            <thead>
                            <tr className="table-headers">
                              <th></th>
                              <th>
                              {new Date(dhlShipmentInformation.events[0]?.timestamp).toLocaleString('en-US', {
                                dateStyle: 'full',
                                timeStyle: 'short',
                                hour12: false,
                              }) }
                              </th>
                              <th>
                                Location
                              </th>
                              <th>
                                Time
                              </th>
                              <th>
                                Pieces
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                              {dhlShipmentInformation.events.map((item,i)=>{
                                return<tr key={i} className="details-row">
                                        <td className="sr-no-td">{dhlShipmentInformation.events?.length-i}</td>
                                        <td className="description-td">{item.description}</td>
                                        <td>{item.location.address?.addressLocality}</td>
                                        <td>{new Date(item.timestamp).toLocaleString()}</td>
                                        <td className="piece-td">{dhlShipmentInformation.details.pieceIds?.length} Pieces</td>
                                      </tr>
                              })}

                            </tbody>
                          </table>
                          <button className="trk-order" style={{marginTop:'30px'}} onClick={handleTrackingDetailsClose} >Close</button>
                    </div>
                  </div>
                  :null
                  }
                    </div>
                  </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div> */}
                {/* className={orders?.shippedDate?"process-bar-bx upload-req or-details-row green-line":"process-bar-bx upload-req or-details-row grey-line" } */}

                <div
                  className={
                    invoicesForOrder.length > 0
                      ? "process-bar-bx  upload-req green-line or-details-row"
                      : "process-bar-bx  upload-req grey-line or-details-row"
                  }
                >
                  <span className="process-ico green-fill">
                    <img src={orderIcon} />
                  </span>
                  <span
                    className={
                      invoicesForOrder.length
                        ? "process-ico green-fill"
                        : "process-ico grey-fill"
                    }
                  >
                    <img src={tickwhite} />
                  </span>

                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                  >
                    <AccordionSummary
                      aria-controls="panel4bh-content"
                      id="panel4bh-header"
                    >
                      <div className="accordion-title">
                        <div className="accordion-icon">
                          <h5 className="deliveryTitle">Invoice</h5>
                          <img
                            src={
                              expanded === "panel4"
                                ? upArrowIcon
                                : downArrowIcon
                            }
                            alt="arrowIcon"
                            style={{ height: "8px", marginRight: "10px" }}
                          />
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="or-details-row">
                        {(orders.createdAt &&
                          moment(orders?.createdAt).isBefore("2024-01-01")) ||
                        (orders.completedOn &&
                          moment(orders?.completedOn).isBefore("2024-01-01")) ||
                        (orders?.expectedDelivery &&
                          moment(orders?.expectedDelivery).isBefore(
                            "2024-01-01"
                          )) ? (
                          <p>
                            As of January 1, 2024, we have transitioned to a new
                            system. If an order was delivered or closed before
                            this date, some documents may not be accessible.
                            Please contact{" "}
                            <a href="mailto:info@8xparts.com">
                              info@8xparts.com
                            </a>{" "}
                            with your order reference to obtain the necessary
                            documents.
                          </p>
                        ) : (
                          <></>
                        )}

                        {invoicesForOrder.length > 0 ? (
                          <>
                            <div className="table-container border px-2 py-1">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Invoice Number</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Ship To</th>
                                    <th>Sent Date</th>
                                    <th>Due Date</th>
                                    <th className="text-center">Download</th>
                                    <th className="text-center">
                                      Consignment Photo
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Quality Document
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {invoicesForOrder?.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td className="downloadables ">
                                          <Link
                                            className="text-primaryColor"
                                            to={`/admin/invoice-details/${item._id}`}
                                          >
                                            {item.invoiceNumber}
                                          </Link>
                                        </td>
                                        <td>
                                          {currencyFormatter(
                                            item.invoiceTotalAmount
                                          )}
                                        </td>
                                        <td>{item.status}</td>
                                        <td>
                                          {orders?.shippingAddress?.firstName +
                                            " " +
                                            orders?.shippingAddress?.lastName}
                                        </td>
                                        <td>
                                          {item.sentDate
                                            ? moment(item.sentDate).format(
                                                "DD MMM YYYY"
                                              )
                                            : ""}
                                        </td>
                                        <td>
                                          {moment(
                                            item.sentDate || item.createdAt
                                          )
                                            .add(item?.paymentTerm, "days")
                                            .format("DD MMM YYYY")}
                                        </td>

                                        <td className="flex justify-center items-center  ">
                                          <div
                                            onClick={() =>
                                              handleDownloadInvoice(item)
                                            }
                                          >
                                            <DownloadIcon />
                                          </div>
                                        </td>
                                        <td className="text-center  ">
                                          <div
                                            onClick={() =>
                                              handleConsignmentModal(item)
                                            }
                                            className=" flex justify-center items-center"
                                          >
                                            <EyeIcon />
                                          </div>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          <div
                                            onClick={() =>
                                              handleQualityDocModal(item)
                                            }
                                            className="flex justify-center items-center"
                                          >
                                            {/* <img
                                            src={viewImage}
                                            style={{
                                              height: "100%",
                                              cursor: "pointer",
                                            }}

                                          /> */}
                                            <EyeIcon />
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          <>
                            <span>No invoices are received for this order</span>
                          </>
                        )}

                        {/* <div className="right-btns">
                      {orders?.qualityDocuments?.length > 0|| orders?.shippedDate?
                        <button className="white-btn" style={{marginLeft:'auto'}} onClick={handleQualityDocModal}>View Quality Documents</button>
                      :
                        <></>
                      }
                      {(existIssue && Object.keys(existIssue).length > 0 && !existIssue.isIssueClosed) ?
                        <button className="white-btn" style={{marginLeft:'auto'}} onClick={() => alert("Issue already raised for this order.For details please get in touch with our customer service executive if the issue is not resolved.")}>Issue Raised</button>
                      :
                      orders?.expectedDelivery?
                        <>
                          <button className="white-btn" style={{marginLeft:'auto'}} onClick={handleRaiseIssueModal}>Raise an issue</button>
                        </>
                        :
                       <></>            
                      }
                      <button className="white-btn" style={{marginLeft:'auto'}} onClick={handleRaiseIssueModal}>Raise an issue</button>
                      
                    </div>
                    <div className="pro-bar">
                      <div className="pro-text">
                        <div className="pro-bar-two">
                          <div className="col">
                            <h6>Status </h6>
                            {(orders?.isCompleted || (orders?.expectedDelivery && new Date(orders?.expectedDelivery)<= new Date(Date.now()))) ? (
                              <p>Delivered</p>
                            ) : (
                              <p>Pending</p>
                            )}
                          </div>
                          <div className="col">
                            <h6>Delivered Date</h6>
                            <p>
                              {orders?.expectedDelivery ? 
                               new Date(orders?.expectedDelivery)> new Date(Date.now())? `Delivery by ${moment(
                                    orders?.expectedDelivery
                                  ).format("DD MMM YYYY")}`
                                :  `Delivered on ${moment(
                                    orders?.expectedDelivery
                                  ).format("DD MMM YYYY")}`
                                : "Delivery Pending"}
                            </p>
                          </div>
                          <div className="col">
                            <h6>Delivery Comment</h6>
                            <p>
                              {orders?.deliveryComment &&
                              orders?.deliveryComment !== ""
                                ? orders?.deliveryComment
                                : "Comments not available"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                {/* THis is feedback and rating */}
                <div className="order-pay-box">
                  <span
                    className={
                      orders?.feedBackComment ||
                      orders?.qualityRating ||
                      orders?.valueForMoneyRating ||
                      orders?.overAllRating
                        ? "order-ico green-fill"
                        : "order-ico grey-fill"
                    }
                  >
                    <img src={tickwhite} />
                  </span>
                  <Accordion
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                  >
                    <AccordionSummary
                      aria-controls="panel4bh-content"
                      id="panel4bh-header"
                    >
                      <div className="accordion-title">
                        <div className="accordion-icon">
                          <h5 className="deliveryTitle">Ratings & Feedback</h5>
                          <img
                            src={
                              expanded === "panel5"
                                ? upArrowIcon
                                : downArrowIcon
                            }
                            alt="arrowIcon"
                            style={{ height: "8px", marginRight: "10px" }}
                          />
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="or-details-row">
                        <div className="right-btns">
                          {existIssue &&
                          Object.keys(existIssue).length > 0 &&
                          !existIssue.isIssueClosed ? (
                            <button
                              className="white-btn"
                              style={{ marginLeft: "auto" }}
                              onClick={() =>
                                alert(
                                  "Issue already raised for this order.For details please get in touch with our customer service executive if the issue is not resolved."
                                )
                              }
                            >
                              Issue Raised
                            </button>
                          ) : orders?.isCompleted ? (
                            <>
                              <button
                                className="white-btn"
                                style={{ marginLeft: "auto" }}
                                onClick={handleRaiseIssueModal}
                              >
                                Raise an issue
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <>
                          <div className="pro-bar " style={{ width: "100%" }}>
                            <div className="pro-text py-3">
                              <h6 style={{fontSize:"17px"}} className="font-medium  ">
                                Your feedback is very valuable to us! Please
                                share your feedback below:
                              </h6>
                              <div className="flex flex-wrap gap-y-2 mt-6">
                                <div className="col  flex flex-col gap-y-1  ">
                                  <h6 className="">Quality</h6>
                                  <div className="flex gap-x-1">
                                    {Array(5)
                                      .fill()
                                      .map((item, idx) => {
                                        return (
                                          <StartIcon
                                            onClickFunction={() =>
                                              handleRating(
                                                idx + 1,
                                                "qualityRating"
                                              )
                                            }
                                            isFilled={
                                              idx < orders?.qualityRating
                                            }
                                          />
                                        );
                                      })}
                                  </div>
                                </div>

                                <div className="col flex flex-col gap-y-1  ">
                                  <h6>Value for money</h6>
                                  <div className="flex gap-x-1">
                                    {Array(5)
                                      .fill()
                                      .map((item, idx) => {
                                        return (
                                          <StartIcon
                                            onClickFunction={() =>
                                              handleRating(
                                                idx + 1,
                                                "valueForMoneyRating"
                                              )
                                            }
                                            isFilled={
                                              idx < orders.valueForMoneyRating
                                            }
                                          />
                                        );
                                      })}
                                  </div>
                                </div>

                                <div className="col flex flex-col gap-y-1">
                                  <h6>Overall Rating</h6>
                                  <div className="flex gap-x-1">
                                    {Array(5)
                                      .fill()
                                      .map((item, idx) => {
                                        return (
                                          <StartIcon
                                            onClickFunction={() =>
                                              handleRating(
                                                idx + 1,
                                                "overAllRating"
                                              )
                                            }
                                            isFilled={
                                              idx < orders.overAllRating
                                            }
                                          />
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col ms-3 ">
                              <label
                                htmlFor="feedback"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Comment / Suggestions:
                              </label>
                              <textarea
                                id="feedback"
                                cols="30"
                                rows="5"
                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                style={{ maxWidth: "95%" }}
                                placeholder="Enter your feedback here"
                                value={orders?.feedBackComment}
                                onChange={handleFeedBackCommentChange}
                              />
                              <button
                                onClick={handleFeedBackSubmit}
                                className=" my-2 h-9 px-10   bg-primaryColor text-white rounded"
                              >
                                Submit{" "}
                              </button>
                            </div>
                          </div>
                        </>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                {/* upload requirement close */}
              </div>

              <div className="privacy-text">
                <strong>Privacy:</strong> 8xParts 3D secures all uploaded files,
                protecting your intellectual property. If you can’t upload your
                files at the moment, use our sample part to try out our instant
                quoting and DfM tools or contact us on{" "}
                <a href="mailto: info@8xparts.com">info@8xparts.com</a> to sign
                an NDA.
              </div>
            </div>
          </div>
        </>
      )}
      {pdfDownloadingLoading && <CustomLoadingComponent
      textArray={['Your document is being prepared. This might take a moment',`Hold tight! We're processing your PDF and it'll be ready shortly.`,'Generating your PDF...']}
      LoaderComponent={<Loader/>}
      
      />}
    </div>
  );
};
export default OrderDetails;
