import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Suspense,
} from "react";

import { Context } from "./Context";

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import axes from "./axes.glb";

import {
  useFrame,
  useLoader,
  useThree,
} from "react-three-fiber";

import {
  Color,
  Vector3,
} from "three";

import { setCameraPosition } from "./setCameraPosition";

export default function HeadsUpDisplay({controls}) {
  const scene = useRef()
  const { camera, size } = useThree()
  useFrame(({gl}) => {
    scene.current.scale.set(3/camera.zoom, 3/camera.zoom, 3/camera.zoom);
    scene.current.position.copy(camera.position);

    const camIn = new Vector3(0, 0, -1);
    camIn.applyQuaternion(camera.quaternion);
    scene.current.position.addScaledVector(camIn, 100/camera.zoom);  

    const camRight = new Vector3(1, 0, 0);
    camRight.applyQuaternion(camera.quaternion);
    scene.current.position.addScaledVector(camRight, size.width/2/camera.zoom);
    scene.current.position.addScaledVector(camRight, -70/camera.zoom);

    const camUp = new Vector3(0, 1, 0);
    camUp.applyQuaternion(camera.quaternion);
    scene.current.position.addScaledVector(camUp, -size.height/2/camera.zoom);
    scene.current.position.addScaledVector(camUp, 70/camera.zoom);

    gl.autoClear = false;
    gl.clippingPlanes = [];
    gl.clearDepth();
    gl.render(scene.current, camera);
  }, 100);
  
  return (
    <scene ref={scene}>
      <ambientLight
        intensity={0.3}
      />
      <directionalLight
        intensity={0.25}
        position={[0.25, 1.5, 0.5]}
      />
      <group>
        <Suspense fallback={null}>
          <Axes
            controls={controls}
          />
        </Suspense>
      </group>
    </scene>
  );
}

function Axes({controls}) {
  const {state} = useContext(Context);
  const {nodes, materials} = useLoader(GLTFLoader, axes);

  const [hoverXP, setHoverXP] = useState(false);
  const [hoverXN, setHoverXN] = useState(false);
  const [hoverYP, setHoverYP] = useState(false);
  const [hoverYN, setHoverYN] = useState(false);
  const [hoverZP, setHoverZP] = useState(false);
  const [hoverZN, setHoverZN] = useState(false);

  const {camera} = useThree();
  
  const originalColors = useRef();
  useEffect(() => {
    originalColors.current = {
      "x+": materials["x+"].color.clone(),
      "x-": materials["x-"].color.clone(),
      "y+": materials["y+"].color.clone(),
      "y-": materials["y-"].color.clone(),
      "z+": materials["z+"].color.clone(),
      "z-": materials["z-"].color.clone(),
    }
  }, [materials]);

  useEffect(() => {
    const highlightColor = new Color(1, 1, 0);
    materials["x+"].color = hoverXP ? highlightColor : originalColors.current["x+"];
    materials["x-"].color = hoverXN ? highlightColor : originalColors.current["x-"];
    materials["y+"].color = hoverYP ? highlightColor : originalColors.current["y+"];
    materials["y-"].color = hoverYN ? highlightColor : originalColors.current["y-"];
    materials["z+"].color = hoverZP ? highlightColor : originalColors.current["z+"];
    materials["z-"].color = hoverZN ? highlightColor : originalColors.current["z-"];
  }, [hoverXP, hoverXN, hoverYP, hoverYN, hoverZP, hoverZN, materials]);

  return (
    <group dispose={null}>
      <mesh
        material={materials["x+"]}
        geometry={nodes['x+'].geometry}
        onPointerOver={e => {
          e.stopPropagation();
          setHoverXP(true);
        }}
        onPointerOut={e => {
          e.stopPropagation();
          setHoverXP(false);
        }}
        onClick={e => {
          e.stopPropagation();
          setCameraPosition(state.files[state.activeFileIndex]?.properties?.aabb, camera, controls.current, new Vector3(1, 0, 0), new Vector3(0, 1, 0));
        }}
      />
      <mesh material={materials["x-"]}
        geometry={nodes["x-"].geometry}
        onPointerOver={e => {
          e.stopPropagation();
          setHoverXN(true);
        }}
        onPointerOut={e => {
          e.stopPropagation();
          setHoverXN(false);
        }}
        onClick={e => {
          e.stopPropagation();
          setCameraPosition(state.files[state.activeFileIndex]?.properties?.aabb, camera, controls.current, new Vector3(-1, 0, 0), new Vector3(0, 1, 0));
        }}
      />
      <mesh material={materials["z+"]}
        geometry={nodes["z+"].geometry}
        rotation={[0, -Math.PI / 2, 0]}
        onPointerOver={e => {
          e.stopPropagation();
          setHoverZP(true);
        }}
        onPointerOut={e => {
          e.stopPropagation();
          setHoverZP(false);
        }}
        onClick={e => {
          e.stopPropagation();
          setCameraPosition(state.files[state.activeFileIndex]?.properties?.aabb, camera, controls.current, new Vector3(0, 0, 1), new Vector3(0, 1, 0));
        }}
      />
      <mesh material={materials["z-"]}
        geometry={nodes["z-"].geometry}
        rotation={[0, -Math.PI / 2, 0]}
        onPointerOver={e => {
          e.stopPropagation();
          setHoverZN(true);
        }}
        onPointerOut={e => {
          e.stopPropagation();
          setHoverZN(false);
        }}
        onClick={e => {
          e.stopPropagation();
          setCameraPosition(state.files[state.activeFileIndex]?.properties?.aabb, camera, controls.current, new Vector3(0, 0, -1), new Vector3(0, 1, 0));
        }}
      />
      <mesh material={materials["y+"]}
        geometry={nodes["y+"].geometry}
        rotation={[0, 0, Math.PI / 2]}
        onPointerOver={e => {
          e.stopPropagation();
          setHoverYP(true);
        }}
        onPointerOut={e => {
          e.stopPropagation();
          setHoverYP(false);
        }}
        onClick={e => {
          e.stopPropagation();
          setCameraPosition(state.files[state.activeFileIndex]?.properties?.aabb, camera, controls.current, new Vector3(0, 1, 0), new Vector3(0, 0, -1));
        }}
      />
      <mesh material={materials["y-"]}
        geometry={nodes["y-"].geometry}
        rotation={[0, 0, Math.PI / 2]}
        onPointerOver={e => {
          e.stopPropagation();
          setHoverYN(true);
        }}
        onPointerOut={e => {
          e.stopPropagation();
          setHoverYN(false);
        }}
        onClick={e => {
          e.stopPropagation();
          setCameraPosition(state.files[state.activeFileIndex]?.properties?.aabb, camera, controls.current, new Vector3(0, -1, 0), new Vector3(0, 0, 1));
        }}
      />
      <mesh
        material={materials["xcross"]}
        rotation={[0, 0, 0]}
        geometry={nodes['xcross'].geometry}
      />
      <mesh
        material={materials["xtip"]}
        rotation={[0, 0, 0]}
        geometry={nodes['xtip'].geometry}
      />
      <mesh
        material={materials["ycross"]}
        rotation={[0, 0, Math.PI / 2]}
        geometry={nodes['ycross'].geometry}
      />
      <mesh
        material={materials["ytip"]}
        rotation={[0, 0, Math.PI / 2]}
        geometry={nodes['ytip'].geometry}
      />
      <mesh
        material={materials["zcross"]}
        rotation={[0, -Math.PI / 2, 0]}
        geometry={nodes['zcross'].geometry}
      />
      <mesh
        material={materials["ztip"]}
        rotation={[0, -Math.PI / 2, 0]}
        geometry={nodes['ztip'].geometry}
      />
    </group>
  );
}
