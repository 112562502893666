import React from 'react';

export const Context = React.createContext(true);

let initialState = {
  files: [],
  activeFileIndex: -1,
};

let reducer = (state, action) => {
  switch (action.type) {
    case "clear-files":
      return { ...state, files: [], activeFileIndex: -1 };
    case "set-activeFileIndex":
      return { ...state, activeFileIndex: action.payload };
    case "push-file":
      return {
        ...state,
        files: [
          ...state.files, {
            ...action.payload,
          }
        ],
      };
    case "set-file-properties":
      const newState = { ...state };
      newState.files = [ ...newState.files ];
      newState.files[action.payload.fileIndex] = {
        ...newState.files[action.payload.fileIndex],
        properties: {
          ...action.payload.properties,
        },
      };
      return newState;
    default:
      return { ...state };
  }
};

export function ContextProvider(props) {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  return (
    <Context.Provider value={value}>{props.children}</Context.Provider>
  );
}
