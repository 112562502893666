import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { useState } from "react";
import { addSurfaceFinish } from '../../../utils/actions/allactions';
import { validateAdmin, getToken,numberInputOnWheelPreventChange } from "../../../utils/helper";
import { toast } from "react-toastify";
import useDocumentTitle from "../../../utils/useDocumentTitle";

function AddSurfaceFinishMaster() {
  const token = getToken();
  const navigate = useNavigate();
  useDocumentTitle('Add Surface Finish')
  const [error, setError] = useState("");
  const [initialValue, setInitialValue] = useState({
    name: '',
    SfFactor: ''
  });


  const validatedSurfaceFinish = () => {
    if (!initialValue.name ||
      !initialValue.SfFactor) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    validateAdmin(token, navigate);
  }, []);

  const handleAddSurfaceFinish = async () => {
    // console.log(initialValue)
    setError('');
    const isSurfaceFinishValid = validatedSurfaceFinish();

    if (!isSurfaceFinishValid) {
      setError("Enter valid values for all the field")
    } else {
      const addedSufraceFinish = await addSurfaceFinish(token, initialValue);
      if(addedSufraceFinish?.data?.data?.code==11000){
        toast.error("Surface Finish already exists")
      }else{
          navigate('/admin/surface-finish');
        toast.success("Surface Finish added successfully")
      }
    }
  }


  return (
    <div  className="admin-container">
      <div>
        <Sidebar />
      </div>
      <div className="admin-panel-container">
        <h1 className="center-heading">  Add Surface Finish </h1>

        <div className="edit-company-container">
          <div>
            <label htmlFor="name" > Name </label>: &nbsp;
            <input
              name="name"
              type="text"
              placeholder="Enter  name"
              onChange={(e) => { setInitialValue({ ...initialValue, name: e.target.value }) }}
            />
          </div>

          <div>
            <label htmlFor="SfFactor" > SfFactor </label>: &nbsp;
            <input
              name="SfFactor"
              type="number"
              onWheel={numberInputOnWheelPreventChange}
              placeholder="Enter SfFactor"
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={(e) => { setInitialValue({ ...initialValue, SfFactor: e.target.value }) }}
            />
          </div>

          <div className="center-heading">
            <button className="button-search" onClick={handleAddSurfaceFinish}>  Create </button>
          </div>
          {
            error ?
              <div style={{ color: 'red', fontSize: '15px', textAlign: 'center', marginTop: '10px' }}>{error}</div>
              : null
          }
        </div>
      </div>
    </div>
  )
}
export default AddSurfaceFinishMaster;








