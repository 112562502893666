import React, { useState, useEffect, useContext, Fragment } from "react";
import process from "../../images/process.svg";
import settingw from "../../images/setting-white.svg";
import info from "../../images/info.svg";
import tickgreen from "../../images/tick-green.svg";
import closeImg from "../../images/close.svg";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { calculatePriceOfCertifications, miliMeterToInchFactor, processType } from "../../utils/constant";
import moment from "moment";
import {
  fetchQuoteById,
  getAllAddress,
  deleteAddress,
  getProfileDetails,
  fetchQuoteByIdForQuoteVersion,
  shareQuoteToEmails,
} from "../../utils/actions/allactions";
import Header from "../../components/Header/header";
import jwt_decode from "jwt-decode";
import AddressModal from "../../components/AddressModal/AddressModal";
import AddressList from "../../components/AddressModal/AddressList";
import shareico from "../../images/share.svg";
import ChatButton from "../../Chat/ChatButton";
import PhoneInput from "react-phone-input-2";
import Chat from "../../Chat";
import { ChatRefContext } from "../../context/chatRef.context";
import { currencyFormatter } from "../../utils/helper";
import { Loading } from "../../components/Loading";
import {
  DeleteIcon,
  EditIcon,
  InfoIcon,
  ShareIcon,
} from "../../components/Icons";
import TailwindModalComponent from "../../components/TailwindModalComponent/TailwindModelComponent";
import { getUserComapnyInfo, markDefaultAddress } from "../../utils/actions/userActions";

const StepThree = () => {
  const { setSelectedChatRefId } = useContext(ChatRefContext);
  const navigate = useNavigate();
  let params = useParams();
  let location = useLocation();
  let fName = localStorage.getItem("firstName");
  let lName = localStorage.getItem("lastName");
  let usrEmail = localStorage.getItem("email");
  let [check, setCheck] = useState(false);
  const [showA, setShowA] = React.useState(false);
  const handleOneClose = () => setShowA(false);
  let [ptc, setPtc] = useState(false);
  const [showTwo, setTwoShow] = React.useState(false);
  const handleTwoClose = () => setTwoShow(false);
  const [saveQuote, setSaveQuote] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let _id = params._id;
  const [open, setOpen] = useState(false);
  let loggedInUserEmail = localStorage.getItem("email");
  let userId = localStorage.getItem("_id");
  const [recentAddress, setRecentAddress] = useState([]);
  const [allAddresses, setAllAddresses] = useState([]);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [editId, setEditId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [showAddressList, setShowAddressList] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [fetchedQuoteDetails, setFetchedQuoteDetails] = useState();
  const [shareQuoteModalShow, setShareQuoteModalShow] = useState(false);
  const [emailToShareQuote, setEmailToShareQuote] = useState(
    new Set([loggedInUserEmail])
  );
  const [userCompanyData, setUserCompanyData] = useState(null);


  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem("isLoggedIn")
    const userId = localStorage.getItem("_id");
    if (isUserLoggedIn && userId) {
      (async () => {
        try {

          const response = await getUserComapnyInfo(userId);
          console.log("Response :", response);
          if (!response || !response.success) {
            throw new Error();
          };

          const { payload: { companySiteId } } = response;

          console.log("User Company Data :", companySiteId);

          setUserCompanyData(companySiteId);


        } catch (error) {
          // toast.error("Error While Fetching Company Info ");
        }
      })()
    }
  }, []);




  const [inputEmail, setInputEmail] = useState("");
  let token = localStorage.getItem("Token");
  let profileDetails = localStorage.getItem("profileDetails");
  profileDetails = JSON.parse(profileDetails);
  const [values, setValues] = useState({
    firstName: fName,
    lastName: lName,
    email: usrEmail,
    mobileNumber: "",
    companyName: "",
    vatNumber: "",
    addressline1: "",
    addressline2: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    tax: 0,
    subTotal: 0,
    quoteId: "",
    industry: [],
  });
  const [profileData, setProfileData] = useState({});
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    companyName: "",
    vatNumber: "",
    addresses: "",
    finalTotalPrice: "",
    addressline1: "",
    addressline2: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    subTotal: "",
  });

  let mailformat =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


  useEffect(() => {
    // Handle the browser back button press
    const handleBackButton = (event) => {
      event.preventDefault();
      alert('Back button pressed');
    };

    // Add event listener for popstate (which is triggered on back button)
    window.addEventListener('popstate', handleBackButton);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);


  const showDrawer = () => {
    setOpen(true);
    setSelectedChatRefId(fetchedQuoteDetails?.RefId);
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "chatOpen",
      //   userId: userId,
      //   quoteRefId: fetchedQuoteDetails?.RefId,
      //   referenceId:
      //     usrEmail?.split("@")[0] + usrEmail?.split("@")[1]?.split(".")[0],
      // });
    }
  };
  const onClose = () => {
    setOpen(false);
    setSelectedChatRefId(null);
  };

  const addNewHandler = () => {
    setShowAddressModal(true);
  };
  const showAddressListHandler = () => {
    setShowAddressList(true);
  };
  const editHandler = (id) => {
    setShowAddressModal(true);
    setIsEdit(true);
    setEditId(id);
  };
  const deleteData = async (_id) => {
    const confirm = window.confirm("Are Your Sure");
    if (confirm) {
      try {
        if (values.shippingAddress == _id) {
          setValues({ ...values, shippingAddress: "" });
        }
        const payload = {
          _id,
        };
        let response = await deleteAddress({ payload }, token);
        const newData = recentAddress.filter((address, index) => {
          return address._id !== _id;
        });
        setRecentAddress(newData);
        fetchData();

        if (response.data.status == false) {
          toast.error("something went wrong");
        } else if (response.data.status == true) {
          toast.success("Address Deleted Successfully");
        }
      } catch (error) {
        console.log(
          "error in step 3 on 186" +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a a"),
          error
        );
      }
    }
  };

  const handleShareQuoteModalOpen = () => {
    setShareQuoteModalShow(true);
  };

  const handleShareQuoteClose = () => {
    setShareQuoteModalShow(false);
  };

  const handleQuotationShare = async () => {
    try {
      if (emailToShareQuote.size === 0) {
        toast.error(
          "Please enter atleast one mail to share the quotation details"
        );
        return;
      } else {
        if (window.location.host === "make.8xparts.com") {
          // window?.dataLayer?.push({
          //   event: "shareQuotation",
          //   userId: userId,
          //   quoteRefId: fetchedQuoteDetails.RefId,
          //   referenceId:
          //     usrEmail?.split("@")[0] + usrEmail?.split("@")[1]?.split(".")[0],
          // });
        }

        let data = {
          quoteId: fetchedQuoteDetails._id,
          selectedShipMethod: fetchedQuoteDetails.selectedShipMethod,
          emailToShareQuote: [...emailToShareQuote],
        };
        let token = localStorage.getItem("Token");
        let response = await shareQuoteToEmails(token, data);
        if (response.statusCode == 200) {
          setShareQuoteModalShow(false);
          setEmailToShareQuote(new Set([loggedInUserEmail]));
          toast.success("Quotation details shared successfully");
        } else {
          toast.error("Something went wrong while sharing the quote details.");
        }
      }
    } catch (err) {
      toast.error("Something went wrong.Please try again later.");
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleAddButtonToReceiverList = () => {
    let response = validateEmail(inputEmail);
    if (response) {
      if (emailToShareQuote.has(inputEmail)) {
        toast.error("Email already added to receivers list");
      } else {
        let localEmailList = new Set(emailToShareQuote);
        localEmailList.add(inputEmail);
        setEmailToShareQuote(localEmailList);
        setInputEmail("");
      }
    } else {
      toast.error("Please enter a valid email");
    }
  };

  function ValidateEmail(mail) {
    if (mail.match(mailformat)) {
      return true;
    }
    return false;
  }

  const handleSubmit = async () => {
    try {
      if (!ptc) {
        toast.error("Prices of all parts are not updated by admin!");
        return;
      }
      if (
        values.firstName === "" ||
        values.lastName === "" ||
        values.email === "" ||
        values.mobileNumber === "" ||
        values.companyName === "" ||
        !values.shippingAddress ||
        !values.email.includes("@") ||
        !values.email.split("@")[1].includes(".") ||
        values.email.split("@")[1].split(".")[1].length < 2
      ) {
        setError({
          ...error,
          firstName:
            values.firstName === ""
              ? "Please Enter First Name"
              : error.firstName,

          shippingAddress: !values.shippingAddress
            ? "Please Select a Shipping Address"
            : "",
          lastName:
            values.lastName === "" ? "Please Enter Last Name" : error.lastName,
          email:
            values.email === ""
              ? "Please Enter Email"
              : !ValidateEmail(values.email)
                ? "Please Enter Valid Email"
                : error?.email,
          mobileNumber:
            values.mobileNumber === ""
              ? "Please Enter Mobile Number"
              : error.mobileNumber,
          companyName:
            values.companyName === ""
              ? "Please Enter Company Name"
              : error.companyName,
          vatNumber:
            values.vatNumber === ""
              ? "Please Enter Tax Number"
              : error.vatNumber,
          addressline1:
            values.addressline1 === ""
              ? "Please Enter address"
              : error.addressline1,
          zipCode:
            values.zipCode === "" ? "Please Enter Zip Code" : error.zipCode,
          city: values.city === "" ? "Please Enter City" : error.city,
          state: values.state === "" ? "Please Enter State" : error.state,
          country:
            values.country === "" ? "Please Enter Country" : error.country,
        });
        //additional Code For Better Error
        // alert("Please Fill All Info");

        if (!values.mobileNumber) {
          toast.error("Please Enter Valid Mobile Number");
        }
        else if (!values?.shippingAddress) {
          toast.error("Please Select A Address");
        }
        window.scrollTo({
          top: 100,
          behavior: "smooth",
        })
        return false;
      } else if (check == false) {
        toast.error("Please Agree with Terms of Sale.");
        return false;
      } else {
        console.log("Values :", values);
        const addressId = values?.shippingAddress;
        await markDefaultAddress(token, addressId, userId);
        navigate("/payments/" + _id, {
          state: { billing: { ...values, tax, subTotal }, part: saveQuote },
        });
      }
    } catch (err) {
      return err;
    }
  };

  let handleCheckBox = () => {
    setCheck(!check);
  };

  async function fetchData() {
    try {
      const response = await getAllAddress(token);
      setAllAddresses(response.data);
      let reversedData = response.data.reverse();
      const selectedAddress = reversedData.find((address) => address.defaultSelected);
      const lastTwo = [];
      if (selectedAddress) {
        console.log(selectedAddress);
        setValues((pre) => {
          return {
            ...pre, shippingAddress: selectedAddress?._id, addressline1: selectedAddress.addressLineOne,
            addressline2: selectedAddress.addressLineTwo,
            city: selectedAddress.city,
            country: selectedAddress.country,
            state: selectedAddress.state,
            zipCode: selectedAddress.postalCode
          }
        })
        lastTwo.push(selectedAddress);
        const secondAddress = reversedData.find((item) => item._id != selectedAddress?._id);
        secondAddress && lastTwo.push(secondAddress);
      }
      else {

        if (reversedData[0]) {
          lastTwo.push(reversedData[0]);
        }
        if (reversedData[1]) {
          lastTwo.push(reversedData[1]);
        }
      }


      // console.log("Address :",reversedData);
      // let lastTwo = reversedData.slice(0, 2);
      setRecentAddress(lastTwo);

    } catch (err) {
      console.log("Error In Fetch Data");
      return err;
    }
  }

  useEffect(() => {
    fetchData();
  }, [showAddressModal]);

  const fetchedQuoteDetailsById = async () => {
    try {
      let details = await fetchQuoteByIdForQuoteVersion(_id, token);
      setFetchedQuoteDetails(details.data.data);
    } catch (err) {
      toast.error("Something went wrong while fetching quotes details.");
    }
  };
  console.log("Saved Quotation: ", fetchedQuoteDetails);
  useEffect(() => {
    const fetchDataAsync = async () => {

      try {

        let token = localStorage.getItem("Token");

        console.log("TokenToken :", token);

        if (!token) {
          navigate("/");
          return;
        }

        let decode;
        try {
          decode = jwt_decode(token);
        } catch (error) {
          navigate("/");
          return;
        }

        if (!decode) {
          navigate("/");
          return;
        }

        if (decode.userType === "admin") {
          navigate("/admin");
          return;
        }

        if (decode.userType !== "user") {
          navigate("/");
          return;
        }
        // At this point, decode.userType === 'user', so proceed with the API calls and state setters
        setIsLoading(true);
        const data = await getProfileDetails(token);
        const profileData = {
          tax: data?.companySiteId?.taxes,
          vatNumber: data?.companySiteId?.vatNumber,
          firstName: data?.firstName,
          lastName: data?.lastName,
          mobileNumber: data?.companySiteId?.phoneNumber,
          email: data?.email,
          companyName: data?.companySiteId?.companyName,
          industry: data?.companySiteId?.companySite,
        };
        setProfileData(profileData);
        setTax(profileData.tax);
        setValues((values) => ({
          ...values,
          ...profileData,
        }));

        await fetchedQuoteDetailsById();
        await fetchData();

        if (location.state !== null) {
          setSaveQuote(location.state.part);
          setValues((values) => ({
            ...values,
            ...location.state.billing,
            firstName: fName,
            lastName: lName,
            email: usrEmail,
          }));
        } else {
          const response = await fetchQuoteById(_id, token);
          if (response.data.status !== true) {
            navigate("/saved-quote");
            toast.error("Details not found!");
            return;
          }

          const partsData = response.data.data.partsData;
          const selectedShipMethod = response.data.data.selectedShipMethod;

          const isPtc = partsData.some((val) => {
            return (
              (selectedShipMethod === 0 && val.price > 0) ||
              (selectedShipMethod === 1 && val.price1 > 0) ||
              (selectedShipMethod === 2 && val.price2 > 0)
            );
          });

          setPtc(isPtc);

          const subTotal =
            response.data.data.selectedProcess <= 2
              ? response.data.data.stManualPrice
              : response.data.data.sheetAndInjectionManualPrice;

          const additionalCost =
            selectedShipMethod === 0
              ? response.data.data.additionalCost1
              : selectedShipMethod === 1
                ? response.data.data.additionalCost2
                : response.data.data.additionalCost3;

          const shippingCharge =
            selectedShipMethod === 0
              ? response.data.data.shippingCharge1
              : selectedShipMethod === 1
                ? response.data.data.shippingCharge2
                : response.data.data.shippingCharge3;

          let totalValue =
            Number(additionalCost || 0) + Number(shippingCharge || 0);

          const { certifications = [] } = response?.data?.data;
          // console.log("Response :",response.data);
          // console.log('Certifications :',certifications);
          if (certifications.length) {
            totalValue += calculatePriceOfCertifications(response?.data?.data);
          }



          partsData.forEach((el) => {
            const price =
              selectedShipMethod === 0
                ? el.price * el.Qty
                : selectedShipMethod === 1
                  ? el.price1 * el.Qty
                  : el.price2 * el.Qty;
            totalValue += price;
          });

          setSubTotal(Number(totalValue.toFixed(2)));

          if (
            response.data.data.selectedProcess === 2 &&
            response.data.data.hidePrice
          ) {
            const subTotalValue = parseFloat(response.data.data.stManualPrice);
            setValues((values) => ({
              ...values,
              ...profileData,
              subTotal: Number(subTotalValue.toFixed(2)),
              quoteId: _id,
              firstName: fName,
              lastName: lName,
              email: usrEmail,
              companyName: response.data.data.userId.companySiteId?.companyName,
              mobileNumber:
                response?.data?.data?.userId?.companySiteId?.phoneNumber,
              industry:
                response?.data?.data?.userId?.companySiteId?.companySite,
              vatNumber: response?.data?.data?.userId?.companySiteId?.vatNumber,
            }));
          } else {
            const taxValue =
              selectedShipMethod === 0
                ? parseFloat(response.data.data.price)
                : selectedShipMethod === 1
                  ? parseFloat(response.data.data.price1)
                  : parseFloat(response.data.data.stManualPrice);

            setValues((values) => ({
              ...values,
              ...profileData,
              subTotal: Number(taxValue.toFixed(2)),
              quoteId: _id,
              firstName: fName,
              lastName: lName,
              email: usrEmail,
              companyName: response.data.data.userId.companySiteId?.companyName,
              mobileNumber:
                response?.data?.data?.userId?.companySiteId?.phoneNumber,
              industry:
                response?.data?.data?.userId?.companySiteId?.companySite,
              vatNumber: response?.data?.data?.userId?.companySiteId?.vatNumber,
            }));
          }

          setSaveQuote(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDataAsync();
  }, []);

  const getProcess = (process) => {
    if (process) {
      return processType[process - 1].name;
    }
  };

  const handleDeleteShareEmail = (item) => {
    let localEmailList = new Set(emailToShareQuote);
    localEmailList.delete(item);
    setEmailToShareQuote(localEmailList);
  };

  return (
    <div className="content-wrapper">
      <Header />
      {isLoading ? <Loading /> : ""}
      <Chat
        RefId={fetchedQuoteDetails?.RefId}
        open={open}
        onDrawerClose={onClose}
      />
      {showAddressModal && (
        <AddressModal
          addressModal={showAddressModal}
          setShowAddressModal={setShowAddressModal}
          setRecentAddress={setRecentAddress}
          recentAddress={recentAddress}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          _id={editId}
        />
      )}
      {showAddressList && (
        <AddressList
          showAddressList={showAddressList}
          error={error}
          setError={setError}
          setValues={setValues}
          values={values}
          setShowAddressList={setShowAddressList}
          setRecentAddress={setRecentAddress}
          recentAddress={recentAddress}
        />
      )}
      <div className="dashboard-holder">
        <div className="sm-container">
          <div className="common-bx">
            <div className="process-bar-bx green-line">
              <span className="process-ico">
                <img src={process} />
              </span>
              <h5>Select a process</h5>
              <div className="process-thumbs">
                <p>{getProcess(saveQuote.selectedProcess)}</p>
              </div>
              <div
                className="process-thumbs"
                style={{ position: "absolute", right: "0", top: "10px" }}
              >
                <h6 className="text">Project Name</h6>
                <p>{saveQuote?.projectName}</p>
              </div>
            </div>

            {/* upload requirement start */}

            <div className="upload-req green-line">
              <span className="process-ico green-fill">
                <img src={settingw} />
              </span>
              <h5>Upload Requirement</h5>
              <p>{saveQuote?.partsData?.length} Parts uploaded</p>
            </div>

            <div className="order-pay-box">
              <span className="order-ico green-border">
                <img src={tickgreen} />
              </span>
              <h5 className="op-1">Order & Pay</h5>

              <div className="order-deatils-bx">
                <Row>
                  <Col md={7}>
                    <div className="billing-box">
                      <h6>Billing Information</h6>
                      <div className="billing-address-container">
                        <div className="billing-form">
                          <Row>
                            <div className="fieldsContainer">
                              <Col md={6}>
                                <div className="mb-3">
                                  <label>First Name</label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter First Name"
                                    value={values?.firstName}
                                    onChange={(e) => {
                                      setError({
                                        ...error,
                                        firstName: null,
                                      });
                                      setValues({
                                        ...values,
                                        firstName: e.target.value,
                                      });
                                    }}
                                  />
                                  {error.firstName && (
                                    <span className="error">
                                      {error.firstName}
                                    </span>
                                  )}
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <label>Last Name</label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Last Name"
                                    value={
                                      values.lastName ? values.lastName : ""
                                    }
                                    onChange={(e) => {
                                      setError({
                                        ...error,
                                        lastName: null,
                                      });
                                      setValues({
                                        ...values,
                                        lastName: e.target.value,
                                      });
                                    }}
                                  />
                                  {error.lastName && (
                                    <span className="error">
                                      {error.lastName}
                                    </span>
                                  )}
                                </div>
                              </Col>
                            </div>
                            <div className="mobile">
                              <Col md={6}>
                                <div className="mb-3">
                                  <label>Email Address</label>
                                  <Form.Control
                                    type="email"
                                    placeholder="Enter Email Address"
                                    value={values.email}
                                    onChange={(e) => {
                                      setError({
                                        ...error,
                                        email: "",
                                      });
                                      setValues({
                                        ...values,
                                        email: e.target.value,
                                      });
                                    }}
                                  />
                                  {error.email && (
                                    <span className="error">{error.email}</span>
                                  )}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <label>Mobile Number</label>
                                  <PhoneInput
                                    country={"us"}
                                    value={values?.mobileNumber}
                                    onChange={(value, data) => {
                                      setError({
                                        ...error,
                                        mobileNumber: null,
                                      });
                                      setValues({
                                        ...values,
                                        mobileNumber: value,
                                      });
                                    }}
                                  />
                                  {error.mobileNumber && (
                                    <span className="error">
                                      {error.mobileNumber}
                                    </span>
                                  )}
                                </div>
                              </Col>
                            </div>
                            <div className="company">
                              {userCompanyData && userCompanyData?.domain != '*' ? <Col md={3}>
                                <div className="mb-3">
                                  <label>Company Name</label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Company Name"
                                    value={values?.companyName}
                                    onChange={(e) => {
                                      setError({
                                        ...error,
                                        companyName: null,
                                      });
                                      setValues({
                                        ...values,
                                        companyName: e.target.value,
                                      });
                                    }}
                                  />
                                  {error.companyName && (
                                    <span className="error">
                                      {error.companyName}
                                    </span>
                                  )}
                                </div>
                              </Col> : null}
                              {values?.industry?.length && userCompanyData && userCompanyData?.domain != '*' ? (
                                <Col md={3}>
                                  <div className="mb-3">
                                    <label>Site</label>
                                    <Form.Select
                                      name="cars"
                                      id="industry"
                                      className="industry"
                                    >
                                      {values?.industry?.map((el, id) => {
                                        return (
                                          <option value="industry" key={id}>
                                            {el}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                    {error.companyName && (
                                      <span className="error">
                                        {error.companyName}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                              ) : null}

                              {userCompanyData && userCompanyData?.domain != '*' ? <Col md={3}>
                                <div className="mb-3">
                                  <label>Tax Number</label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Tax Number"
                                    value={values.vatNumber}
                                    onChange={(e) => {
                                      setError({
                                        ...error,
                                        vatNumber: null,
                                      });
                                      setValues({
                                        ...values,
                                        vatNumber: e.target.value,
                                      });
                                    }}
                                  />
                                  {error.vatNumber && (
                                    <span className="error">
                                      {error.vatNumber}
                                    </span>
                                  )}
                                </div>
                              </Col> : null}
                            </div>
                          </Row>
                          <TailwindModalComponent />
                          <div className="address-bar mt-4">
                            <h6>Address</h6>
                            <div className="billing-form">
                              <div className="address">
                                {recentAddress.map((el, id) => (
                                  <div
                                    className="w-full my-2 flex items-center justify-between px-2 text-xs md:text-sm border py-1"
                                    key={id}
                                  >
                                    <div className="flex  items-center">
                                      <input
                                        className="btn-radio"
                                        type="radio"
                                        value={el._id}
                                        checked={
                                          values.shippingAddress == el._id
                                        }
                                        name="rd"
                                        style={{ marginRight: "5px" }}
                                        onChange={(e) => {
                                          setError({
                                            ...error,
                                            shippingAddress: "",
                                          });
                                          setValues({
                                            ...values,
                                            shippingAddress: el._id,
                                            addressline1: el.addressLineOne,
                                            addressline2: el.addressLineTwo,
                                            city: el.city,
                                            country: el.country,
                                            state: el.state,
                                            zipCode: el.postalCode,
                                          });
                                        }}
                                      />
                                      <div className="address text-gray-500 font-medium text-xs">
                                        <div className="name !text-sm ">
                                          {el?.firstName} {el?.lastName}
                                        </div>
                                        <div className="add">
                                          {el?.addressLineOne}{" "}
                                          {el?.addressLineTwo} {el?.city}{" "}
                                          {el?.state} {el.postalCode}{" "}
                                          {el.country}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex gap-x-2">
                                      <div
                                        className="flex    text-slate-500  text-sm px-1 py-1 mx-1 justify-center  items-center gap-x-1"
                                        onClick={() => editHandler(el._id)}
                                      >
                                        <EditIcon additionalClass="!h-4 " />
                                      </div>
                                      <div
                                        className="flex justify-center  items-center text-red-500  text-sm px-2 py-1 gap-x-1"
                                        onClick={() => deleteData(el._id)}
                                      >
                                        <DeleteIcon additionalClass="!h-4" />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {error.shippingAddress && (
                                  <span className="error">
                                    {error.shippingAddress}
                                  </span>
                                )}
                                <div className="w-full flex  px-2 py-1 justify-between">
                                  {allAddresses?.length == 0 ? (
                                    <div
                                      style={{
                                        cursor: "auto",
                                        textDecoration: "none",
                                        opacity: "0.7",
                                      }}
                                      className="text-slate-500   px-2 underline"
                                    >
                                      No Addresses available
                                    </div>
                                  ) : (
                                    <div
                                      onClick={showAddressListHandler}
                                      className="text-slate-500  cursor-pointer   px-2"
                                    >
                                      See all address
                                    </div>
                                  )}
                                  {allAddresses?.length >= 5 ? (
                                    <div
                                      style={{ cursor: "auto", opacity: "0.5" }}
                                      className="text-slate-500   px-2"
                                    >
                                      + Add New Address
                                    </div>
                                  ) : (
                                    <div
                                      // className="addNew"
                                      onClick={addNewHandler}
                                      className="text-slate-500 cursor-pointer   px-2"
                                    >
                                      + Add New Address
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="quote-right">
                      <div className="qoute-box">
                        <h6>
                          Quotation Summary{" "}
                          <span style={{ display: "flex", fontSize: "13px" }}>
                            (
                            {fetchedQuoteDetails?.RefId +
                              " V-" +
                              fetchedQuoteDetails?.version}
                            )
                          </span>
                        </h6>
                        <div className="pd-items">
                          {fetchedQuoteDetails?.selectedProcess == 1 ||
                            fetchedQuoteDetails?.selectedProcess == 2 ? (
                            fetchedQuoteDetails &&
                              fetchedQuoteDetails.partsData &&
                              fetchedQuoteDetails?.partsData?.length ? (
                              fetchedQuoteDetails?.partsData.map((val, i) => {
                                return (
                                  <div className="item-box" key={i}>
                                    <div className="item-bar">
                                      <div className="left">
                                        <img
                                          className="h-12 border border-gray-300"
                                          src={val?.thumbnail || 'https://three-d-files-thumbnail.s3.eu-west-1.amazonaws.com/thumbnails/3d.png'}
                                        />
                                        <span
                                          className="qt-text"
                                          style={{
                                            wordWrap: "break-word",
                                            wordBreak: "break-all",
                                            whiteSpace: "normal",
                                          }}
                                        >
                                          {(
                                            val.file?.originalname ||
                                            "No File Uploaded"
                                          )?.length > 25
                                            ? val.file?.originalname.substring(
                                              0,
                                              25
                                            ) + "..."
                                            : val.file?.originalname ||
                                            "No File Uploaded"}
                                          <p>
                                            {saveQuote?.selectedProcess &&
                                              processType.map((val, i) => {
                                                if (
                                                  val.id ==
                                                  saveQuote.selectedProcess
                                                ) {
                                                  return val.name;
                                                }
                                              })}
                                          </p>
                                        </span>
                                      </div>
                                      <div className="absolute right-3 grid grid-cols-2  w-48 items-center gap-x-14 text-sm ">
                                        <div className="text-[13px] flex item-center justify-center">(
                                          <span>{currencyFormatter(
                                            saveQuote?.selectedShipMethod === 0
                                              ? (
                                                Number(val?.price)
                                              ).toFixed(2)
                                              : saveQuote?.selectedShipMethod ===
                                                1
                                                ? (
                                                  Number(val?.price1)
                                                ).toFixed(2)
                                                : saveQuote?.selectedShipMethod ===
                                                  2
                                                  ? (
                                                    Number(val?.price2)
                                                  ).toFixed(2)
                                                  : 0
                                          )}</span>
                                          <span> x </span>
                                          <span>{val?.Qty}</span>
                                          )
                                        </div>

                                        <span className="font-bold "> {currencyFormatter(
                                          saveQuote?.selectedShipMethod === 0
                                            ? (
                                              Number(val?.price) *
                                              Number(val?.Qty)
                                            ).toFixed(2)
                                            : saveQuote?.selectedShipMethod ===
                                              1
                                              ? (
                                                Number(val?.price1) *
                                                Number(val?.Qty)
                                              ).toFixed(2)
                                              : saveQuote?.selectedShipMethod ===
                                                2
                                                ? (
                                                  Number(val?.price2) *
                                                  Number(val?.Qty)
                                                ).toFixed(2)
                                                : 0
                                        )}</span>

                                      </div>
                                    </div>
                                    {console.log("FetchedQuoteDetails", fetchedQuoteDetails)}
                                    <div className="desc-part-quote-version quote-summary">
                                      {fetchedQuoteDetails.selectedProcess != 2 ?
                                        <>
                                          {val?.description ? (
                                            <li>{val?.description}</li>
                                          ) : (
                                            ""
                                          )}
                                          {val?.MaterialType ? (
                                            <li>{val?.MaterialType?.name}</li>
                                          ) : (
                                            ""
                                          )}
                                          {val?.SubMaterial ? (
                                            <li>{val?.SubMaterial.name}</li>
                                          ) : (
                                            ""
                                          )}
                                          {val?.surfaceFinish ? (
                                            <li>{val?.surfaceFinish.name}</li>
                                          ) : (
                                            ""
                                          )}
                                          {val?.surfaceTreatment ? (
                                            <li>{val?.surfaceTreatment.name}</li>
                                          ) : (
                                            ""
                                          )}
                                          {val?.tolerance ? (
                                            <li>{val?.tolerance.name}</li>
                                          ) : (
                                            ""
                                          )}
                                          {val?.partMarking ? (
                                            <li>{val?.partMarking.name}</li>
                                          ) : (
                                            ""
                                          )}
                                          {val?.noOfThread ? (
                                            <li>{val?.noOfThread}</li>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                        :
                                        <>
                                          <li>
                                            {val.threeDTechnology &&
                                              val.threeDTechnology?.name
                                            }
                                          </li>
                                          {/* <li>
                                            {val.threeDMachine &&
                                              val.threeDMachine?.name
                                            }
                                          </li> */}
                                          <li>
                                            {val.threeDMaterial &&
                                              val.threeDMaterial?.name
                                            }
                                          </li>
                                          <li>
                                            {val.threeDPostProcessing &&
                                              val.threeDPostProcessing?.name
                                            }
                                          </li>
                                        </>}
                                      {(val.XDimensionValue && val.YDimensionValue && val.ZDimensionValue) ? (
                                        <Fragment>
                                          <li>
                                            {`${val.XDimensionValue} x ${val.YDimensionValue} x ${val.ZDimensionValue} (mm) / ${(val.XDimensionValue / miliMeterToInchFactor).toFixed(2)} x ${(val.YDimensionValue / miliMeterToInchFactor).toFixed(2)} x ${(val.ZDimensionValue / miliMeterToInchFactor).toFixed(2)} (inches)`}
                                          </li>
                                          <li>
                                            Volume : {val?.singleVolume?.toFixed(2)} cc
                                          </li>
                                        </Fragment>
                                      ) : ""}
                                    </div>
                                    <p></p>
                                  </div>
                                );
                              })
                            ) : (
                              ""
                            )
                          ) : saveQuote.selectedProcess >= 3 ? (
                            saveQuote &&
                              saveQuote.partsData &&
                              saveQuote?.partsData?.length ? (
                              saveQuote?.partsData.map((val, i) => {
                                return (
                                  <div className="item-box" key={i}>
                                    <div className="item-bar">
                                      <div className="left">
                                        <span className="img-box"></span>
                                        <span className="qt-text">
                                          {(
                                            val.file?.originalname ||
                                            val.linePartName ||
                                            "No File Uploaded"
                                          )?.length > 25
                                            ? (
                                              val.file?.originalname ||
                                              val.linePartName ||
                                              "No File Uploaded"
                                            ).substring(0, 25) + "..."
                                            : val.file?.originalname ||
                                            val.linePartName ||
                                            "No File Uploaded"}
                                          <p>
                                            {saveQuote?.selectedProcess &&
                                              processType.map((item, i) => {
                                                if (
                                                  item.id ==
                                                  saveQuote.selectedProcess
                                                ) {
                                                  return item.name;
                                                }
                                              })}
                                          </p>
                                        </span>
                                      </div>
                                      <div className="right">
                                        {currencyFormatter(
                                          saveQuote?.selectedShipMethod === 0
                                            ? (
                                              Number(val?.price) *
                                              Number(val?.Qty)
                                            ).toFixed(2)
                                            : saveQuote?.selectedShipMethod ===
                                              1
                                              ? (
                                                Number(val?.price1) *
                                                Number(val?.Qty)
                                              ).toFixed(2)
                                              : saveQuote?.selectedShipMethod ===
                                                2
                                                ? (
                                                  Number(val?.price2) *
                                                  Number(val?.Qty)
                                                ).toFixed(2)
                                                : 0
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className="desc-part-quote-version"
                                      style={{
                                        textAlign: "left",
                                        wordWrap: "break-word",
                                        wordBreak: "break-all",
                                        whiteSpace: "normal",
                                        width: "100%",
                                      }}
                                    >
                                      <ul>
                                        {val?.description ? (
                                          <li>{val?.description}</li>
                                        ) : (
                                          ""
                                        )}
                                        {val?.Notes ? (
                                          <li>{val?.Notes}</li>
                                        ) : (
                                          ""
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              ""
                            )
                          ) : (
                            <></>
                          )}

                          {saveQuote.isQrTrackingRequested ? (
                            <>
                              <div className="item-box">
                                <div className="item-bar">
                                  <div className="left">
                                    <span className="qt-text pl-0">
                                      <h6>Certification : </h6>
                                    </span>
                                  </div>
                                  <div className="right">
                                    {" "}
                                    {currencyFormatter(
                                      calculatePriceOfCertifications(fetchedQuoteDetails)
                                    )}
                                  </div>
                                </div>
                                {/* <div className="item-bar">
                                  <div className="left">
                                    <span className="qt-text pl-0">
                                      <h6>QR Tracking : </h6>
                                    </span>
                                  </div>
                                  <div className="right">
                                    {" "}
                                    {currencyFormatter(
                                      saveQuote.selectedShipMethod == 0
                                        ? saveQuote.additionalCost1 || 0
                                        : saveQuote.selectedShipMethod == 1
                                          ? saveQuote.additionalCost2 || 0
                                          : saveQuote.additionalCost3 || 0
                                    )}
                                  </div>
                                </div> */}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {fetchedQuoteDetails?.certifications.length ? <div className="item-box">
                            <div
                              className="item-bar"
                              style={{ marginBottom: "0px" }}
                            >
                              <div className="left">
                                <span className="qt-text pl-0">
                                  <h6>Certification Cost : </h6>
                                </span>
                              </div>
                              <div className="right">
                                {currencyFormatter(
                                  calculatePriceOfCertifications(fetchedQuoteDetails)
                                )}
                              </div>
                            </div>
                          </div> : null}

                          <div className="item-box">
                            <div
                              className="item-bar"
                              style={{ marginBottom: "0px" }}
                            >
                              <div className="left">
                                <span className="qt-text pl-0">
                                  <h6>Shipping & Duties : </h6>
                                </span>
                              </div>
                              <div className="right">
                                {currencyFormatter(
                                  saveQuote.selectedShipMethod == 0
                                    ? (saveQuote.shippingCharge1 || 0)
                                    : saveQuote.selectedShipMethod == 1
                                      ? (saveQuote.shippingCharge2 == 0)
                                      : (saveQuote.shippingCharge3 == 0)
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="item-box">
                            <div className="item-bar">
                              <div className="left">
                                <span className="qt-text pl-0">
                                  <h6>Lead time </h6>
                                  <p>
                                    Shipping :-{" "}
                                    {saveQuote?.selectedShipMethod == 0
                                      ? `${Math.floor(
                                        saveQuote.leadTime1 / 7
                                      )}-${Math.floor(saveQuote.leadTime1 / 7) +
                                      1
                                      } weeks`
                                      : saveQuote?.selectedShipMethod == 1
                                        ? `${Math.floor(
                                          saveQuote.leadTime2 / 7
                                        )}-${Math.floor(saveQuote.leadTime2 / 7) +
                                        1
                                        } weeks`
                                        : `${Math.floor(
                                          saveQuote.leadTime3 / 7
                                        )}-${Math.floor(saveQuote.leadTime3 / 7) +
                                        1
                                        } weeks`}
                                  </p>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="subtotal-bx">
                            <ul>
                              <li>
                                <span>Subtotal </span>{" "}
                                <span>
                                  {currencyFormatter(subTotal && subTotal)

                                  }
                                </span>{" "}
                              </li>
                              <li>
                                <span>Taxes ({tax}%)</span>{" "}
                                <span>
                                  {tax > 0
                                    ? currencyFormatter(
                                      (subTotal / tax).toFixed(2)
                                    )
                                    : 0}
                                </span>{" "}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="total-amt">
                          <span>Total</span>
                          <span>
                            {" "}
                            {currencyFormatter(
                              (
                                subTotal + (tax > 0 ? subTotal / tax : 0)
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="check-sub">
                        <div className="agree-check">
                          <input
                            type="checkbox"
                            id="agree-check"
                            name="checkbox"
                            value="Apple"
                            onChange={handleCheckBox}
                          />
                          <label htmlFor="agree-check"></label>
                          <p>
                            I agree with the{" "}
                            <a
                              href={`${window.location.origin}/terms-of-service`}
                            >
                              Terms of Sale
                            </a>
                            . I have reviewed my representations and confirm
                            that neither my files nor my parts are: (i) weapons
                            or (ii) classified as dual-use or otherwise export
                            controlled. I have read and understood the{" "}
                            <a
                              href={`${window.location.origin}/privacy-policy`}
                            >
                              Privacy Policy
                            </a>
                            .
                          </p>
                        </div>
                        <p className="flex gap-x-2 items-center my-2">
                          <InfoIcon />
                          <span className="text-red-400">
                            This quote is now saved in Saved Quotes
                          </span>
                        </p>
                        <div className="price-box">
                          <div className="button-bx">
                            {" "}
                            <button
                              className="process-btn "
                              onClick={() => handleSubmit()}
                            >
                              <span>Submit and Proceed</span>
                            </button>
                            <div className="my-2  flex h-10 gap-x-2  ">
                              <span className="grow " onClick={showDrawer}>
                                <ChatButton
                                  RefId={fetchedQuoteDetails?.RefId}
                                  showDrawer={showDrawer}
                                  color={"#2B96DC"}
                                />
                              </span>
                              <Link
                                to="#"
                                className="flex grow  justify-center gap-x-2 items-center bg-primaryColor text-white"
                                onClick={handleShareQuoteModalOpen}
                              >
                                <ShareIcon />
                                <span>Share</span>
                              </Link>
                            </div>
                          </div>
                          <div className="btm-buttons"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            {/* upload requirement close */}
          </div>

          <div className="privacy-text">
            <strong>Privacy:</strong> 8xParts 3D secures all uploaded files,
            protecting your intellectual property. If you can’t upload your
            files at the moment, use our sample part to try out our instant
            quoting and DfM tools or contact us on{" "}
            <a href="mailto: info@8xparts.com">info@8xparts.com</a> to sign an
            NDA.
          </div>
        </div>
      </div>

      {/* address list popup */}

      <Modal show={showA} onHide={handleOneClose} className="address-pop">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "15px" }}>
            All Addresses <span>(15)</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="address-sl">
            <h5>Select Address</h5>
            <div className="address-list-in">
              <div className="ad-box">
                <div className="rd-left rd-box">
                  <input type="radio" id="testA" name="radio-group" checked />
                  <label htmlFor="testA"></label>
                </div>
                <div className="ad-text">
                  <div className="name">John Cena</div>
                  <p>
                    A/504, ShreeHari Complex, Teesgaonpada, Near Ashok School,
                    Kalyan, Maharashtra 421306, India
                  </p>
                </div>
                <div className="action-text">
                  <ul>
                    <li>
                      {" "}
                      <Link to="">Edit</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="">Remove</Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="ad-box">
                <div className="rd-left rd-box">
                  <input type="radio" id="testB" name="radio-group" checked />
                  <label htmlFor="testB"></label>
                </div>
                <div className="ad-text">
                  <div className="name">John Cena</div>
                  <p>
                    A/504, ShreeHari Complex, Teesgaonpada, Near Ashok School,
                    Kalyan, Maharashtra 421306, India
                  </p>
                </div>
                <div className="action-text">
                  <ul>
                    <li>
                      {" "}
                      <Link to="">Edit</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="">Remove</Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="ad-box">
                <div className="rd-left rd-box">
                  <input type="radio" id="testC" name="radio-group" checked />
                  <label htmlFor="testC"></label>
                </div>
                <div className="ad-text">
                  <div className="name">John Cena</div>
                  <p>
                    A/504, ShreeHari Complex, Teesgaonpada, Near Ashok School,
                    Kalyan, Maharashtra 421306, India
                  </p>
                </div>
                <div className="action-text">
                  <ul>
                    <li>
                      {" "}
                      <Link to="">Edit</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="">Remove</Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="ad-box">
                <div className="rd-left rd-box">
                  <input type="radio" id="testD" name="radio-group" checked />
                  <label htmlFor="testD"></label>
                </div>
                <div className="ad-text">
                  <div className="name">John Cena</div>
                  <p>
                    A/504, ShreeHari Complex, Teesgaonpada, Near Ashok School,
                    Kalyan, Maharashtra 421306, India
                  </p>
                </div>
                <div className="action-text">
                  <ul>
                    <li>
                      {" "}
                      <Link to="">Edit</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="">Remove</Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="foot-left">
            {" "}
            <Link to="">+ Add New Address</Link>{" "}
          </div>
          <div className="foot-right">
            <Button onClick={handleOneClose} className="close-btn">
              Back
            </Button>
            <Button onClick={handleOneClose} className="submit-btn">
              Done
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* address list popup close */}

      {/* add address popup */}

      <Modal show={showTwo} onHide={handleTwoClose} className="address-pop">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "15px" }}>New Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="address-sl">
            <div className="address-pop-form">
              <form>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control type="text" placeholder="First Name" />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control type="text" placeholder="Last name" />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control type="text" placeholder="Last name" />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Address line 1</Form.Label>
                      <Form.Control type="text" placeholder="Enter here" />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Address line 2</Form.Label>
                      <Form.Control type="text" placeholder="Enter here" />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Zip/Postal Code</Form.Label>
                      <Form.Control type="text" placeholder="Enter code" />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>City</Form.Label>
                      <Form.Select aria-label="Default select example">
                        <option>Select</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>State/Province</Form.Label>
                      <Form.Select aria-label="Default select example">
                        <option>Select</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Country</Form.Label>
                      <Form.Select aria-label="Default select example">
                        <option>Select</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="foot-left"></div>
          <div className="foot-right">
            <Button onClick={handleTwoClose} className="close-btn">
              Back
            </Button>
            <Button onClick={handleTwoClose} className="submit-btn">
              Add
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* add address popup close */}

      {/* Share quote modal */}
      <Modal
        size="sm"
        dialogClassName="qoute-version-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={shareQuoteModalShow}
        onHide={handleShareQuoteClose}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "15px" }}
          >
            Share Quotation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="share-quote-div">
            <h6>
              Emails entered below will receive an email with Quotation details
              in their mailbox.
            </h6>
            <div className="add-email-div">
              <input
                placeholder="Enter the email to share the quotation details"
                value={inputEmail}
                onChange={(e) => setInputEmail(e.target.value)}
              />
              <button onClick={handleAddButtonToReceiverList}>
                + Add Email
              </button>
            </div>
            <div className="share-content-div">
              <h6>Receiver's List</h6>
              <div className="email-list-share-div">
                {emailToShareQuote.size > 0
                  ? Array.from(emailToShareQuote).map((item, index) => {
                    return (
                      <span className="email-item" key={index}>
                        {item}{" "}
                        <img
                          src={closeImg}
                          className="close-img"
                          onClick={(e) => handleDeleteShareEmail(item)}
                        />
                      </span>
                    );
                  })
                  : null}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="share-quote-div-footer">
            <div className="share-button" onClick={handleQuotationShare}>
              Share
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default StepThree;
