import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { useState } from "react";
import {
  getMaterialDetailsById,
  updateMaterial,
} from "../../../utils/actions/allactions";
import {
  validateAdmin,
  numberInputOnWheelPreventChange,
  getToken,
} from "../../../utils/helper";
import { toast } from "react-toastify";
import useDocumentTitle from "../../../utils/useDocumentTitle";
import SkeltonLoadingComponent from "../../../components/LoadingComponents/SkeltonLoadingComponent";

const EditMaterialMaster = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const materialId = searchParams.get("id") || null;
  const token = getToken();
  const navigate = useNavigate();
  useDocumentTitle("Edit Material");
  const [initialValue, setInitialValue] = useState(null);
  const [error, setError] = useState("");
  console.log(initialValue);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getMaterialDetailsById(materialId);

        if (data?.success) {
          setInitialValue(data.data);
        } else {
          setError("Failed To Fetch Data");
        }
      } catch (error) {
        toast.error("Unable To Fetch Material Details");
      }
    };
    fetchData();
  }, []);

  const validateMaterialVal = () => {
    if (
      !initialValue.name ||
      initialValue.is3DPrinting == null ||
      initialValue.is3DPrinting === "" ||
      initialValue.isMachining == null ||
      initialValue.isMachining === "" ||
      initialValue.isSheetMetal == null ||
      initialValue.isSheetMetal === "" ||
      initialValue.isInjectionMoulding == null ||
      initialValue.isInjectionMoulding === "" ||
      initialValue.isActive == null ||
      initialValue.isActive === ""
    ) {
      return false;
    }
    return true;
  };

  const handleEditMaterial = async () => {
    setError("");
    const isMaterialDataValid = validateMaterialVal();

    if (!isMaterialDataValid) {
      setError("Enter valid values for all the field");
    } else {
      try {
        const updatedMaterial = await updateMaterial(token, initialValue);

        if (updatedMaterial?.data?.data?.code == 11000) {
          toast.error("Material already exists");
        } else {
          navigate("/admin/material");
          toast.success("Material updated successfully");
        }
      } catch (error) {
        toast.error("Unable To Update Master Material Data");
      }
    }
  };
  return (
    <div className="admin-container">
      <div>
        <Sidebar />
      </div>
      <div className="admin-panel-container">
        <h1 className=" text-center my-2 font-bold text-xl">
          {" "}
          Update Material{" "}
        </h1>
        {initialValue ? (
          <div className="edit-company-container">
            <div>
              <label htmlFor="name"> Name </label>: &nbsp;
              <input
                name="name"
                type="text"
                placeholder="Enter  name"
                value={initialValue.name}
                onChange={(e) => {
                  setInitialValue({ ...initialValue, name: e.target.value });
                }}
              />
            </div>
            <div className="admin-status-div">
              <label htmlFor="is3dPrinting">Is 3d Printing?</label>: &nbsp;
              <input
                name="is3dPrinting"
                type="radio"
                value="true"
                checked={initialValue.is3DPrinting ? true : ""}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    is3DPrinting: e.target.value === "true" ? true : "",
                  });
                }}
              />
              Yes
              <input
                name="is3dPrinting"
                type="radio"
                value="false"
                checked={!initialValue.is3DPrinting ? true : null}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    is3DPrinting: e.target.value === "false" ? false : "",
                  });
                }}
              />
              No
            </div>

            <div className="admin-status-div">
              <label htmlFor="isMachining">Is Machining?</label>: &nbsp;
              <input
                name="isMachining"
                type="radio"
                value="true"
                checked={initialValue.isMachining ? true : ""}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    isMachining: e.target.value === "true" ? true : "",
                  });
                }}
              />
              Yes
              <input
                name="isMachining"
                type="radio"
                value="false"
                checked={!initialValue.isMachining ? true : null}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    isMachining: e.target.value === "false" ? false : "",
                  });
                }}
              />
              No
            </div>
            <div className="admin-status-div">
              <label htmlFor="isSheetMetal">Is SheetMetal?</label>: &nbsp;
              <input
                name="isSheetMetal"
                type="radio"
                value="true"
                checked={initialValue.isSheetMetal ? true : ""}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    isSheetMetal: e.target.value === "true" ? true : null,
                  });
                }}
              />
              Yes
              <input
                name="isSheetMetal"
                type="radio"
                value="false"
                checked={!initialValue.isSheetMetal ? true : null}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    isSheetMetal: e.target.value === "false" ? false : null,
                  });
                }}
              />
              No
            </div>

            <div className="admin-status-div">
              <label htmlFor="isInjectionMoulding">Is InjectionMoulding?</label>
              : &nbsp;
              <input
                name="isInjectionMoulding"
                type="radio"
                value="true"
                checked={initialValue.isInjectionMoulding ? true : ""}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    isInjectionMoulding:
                      e.target.value === "true" ? true : null,
                  });
                }}
              />
              Yes
              <input
                name="isInjectionMoulding"
                type="radio"
                value="false"
                checked={!initialValue.isInjectionMoulding ? true : null}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    isInjectionMoulding:
                      e.target.value === "false" ? false : null,
                  });
                }}
              />
              No
            </div>

            <div>
              <label htmlFor="threeDMaterialCost"> 3D Material Cost </label>:
              &nbsp;
              <input
                name="threeDMaterialCost"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                placeholder="Enter 3D Material Cost"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                value={
                  initialValue.threeDMaterialCost
                    ? initialValue.threeDMaterialCost
                    : ""
                }
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    threeDMaterialCost: e.target.value,
                  });
                }}
              />
            </div>

            <div>
              <label htmlFor="threeD_Material_Density">
                {" "}
                3D Material Density{" "}
              </label>
              : &nbsp;
              <input
                name="threeD_Material_Density"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                placeholder="Enter 3D Material Density"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                value={
                  initialValue.threeD_Material_Density
                    ? initialValue.threeD_Material_Density
                    : ""
                }
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    threeD_Material_Density: e.target.value,
                  });
                }}
              />
            </div>

            <div>
              <label htmlFor="threeDExcessVolume"> 3D Excess Volume </label>:
              &nbsp;
              <input
                name="threeDExcessVolume"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                placeholder="Enter 3D Excess Volume"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                value={
                  initialValue.threeDExcessVolume
                    ? initialValue.threeDExcessVolume
                    : ""
                }
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    threeDExcessVolume: e.target.value,
                  });
                }}
              />
            </div>

            <div>
              <label htmlFor="threeDPrintingHourly"> 3D Printing Hourly </label>
              : &nbsp;
              <input
                name="threeDPrintingHourly"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                placeholder="Enter 3D Printing Hourly "
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                value={
                  initialValue.threeDPrintingHourly
                    ? initialValue.threeDPrintingHourly
                    : ""
                }
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    threeDPrintingHourly: e.target.value,
                  });
                }}
              />
            </div>

            <div>
              <label htmlFor="threeDPrintingRate"> 3D Printing Rate </label>:
              &nbsp;
              <input
                name="threeDPrintingRate"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                placeholder="Enter 3D Printing Rate "
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                value={
                  initialValue.threeDPrintingRate
                    ? initialValue.threeDPrintingRate
                    : ""
                }
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    threeDPrintingRate: e.target.value,
                  });
                }}
              />
            </div>

            <div>
              <label htmlFor="threeDRecoatingRate"> 3D Recoating Rate </label>:
              &nbsp;
              <input
                name="threeDRecoatingRate"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                placeholder="Enter 3D Recoating Rate "
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                value={
                  initialValue.threeDRecoatingRate
                    ? initialValue.threeDRecoatingRate
                    : ""
                }
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    threeDRecoatingRate: e.target.value,
                  });
                }}
              />
            </div>

            <div className="admin-status-div">
              <label htmlFor="isActive">Status</label>: &nbsp;
              <input
                name="isActive"
                type="radio"
                value="true"
                checked={initialValue.isActive ? true : ""}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    isActive: e.target.value === "true" ? true : null,
                  });
                }}
              />
              Yes
              <input
                name="isActive"
                type="radio"
                value="false"
                checked={!initialValue.isActive ? true : null}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    isActive: e.target.value === "false" ? false : null,
                  });
                }}
              />
              No
            </div>
            <div className="center-heading">
              <button className="button-search" onClick={handleEditMaterial}>
                {" "}
                Update{" "}
              </button>
            </div>
            {error ? (
              <div
                style={{
                  color: "red",
                  fontSize: "15px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {error}
              </div>
            ) : null}
          </div>
        ) : (
          <SkeltonLoadingComponent />
        )}
      </div>
    </div>
  );
};

export default EditMaterialMaster;
