import React, { useEffect, useState } from 'react'
import { useCreateQuoteContext } from '../../../../context/create.quote.context';
import { currencyFormatter } from '../../../../utils/helper';
import ShowPriceBox from '../ShowPriceBox/ShowPriceBox';
import { ArrowIcon, DocIcon, DownloadIcon, ForwardToPurchaseIcon, MessageIcon, PlusIcon, ProccedToCheckOutIconComponent, ShareIcon, UsersIcon } from '../../../../components/Icons';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { getAllCertifications } from '../../../../utils/actions/commanActions';
import { toast } from 'react-toastify';
import CertificationModal from '../../../../components/CertificationModal/CertificationModal';
import { calculatePriceOfCertifications } from '../../../../utils/constant';

const RightSection = ({
    updateCommanValueOnChange,
    onSubmit,
    handleDraftQuoteFunctionality,
    selectedQuote,
}) => {
    const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();


    // console.log("Quotation State :", createQuoteState);



    let props = createQuoteState;
    const [certificationsData, setCertificationData] = useState([]);
    const { quoteId } = useParams();
    let _id = quoteId;
    const calculateDeliveryTime = (day, time) => {
        try {
            let startDate = moment(time).add(day, "days").format("DD MMM YYYY");
            return "by " + startDate;
        } catch (err) {
            return "";
        }
    };
    const [searchParams] = useSearchParams();
    const funcName = searchParams.get("functionalityName");
    const [isOpen, setIsOpen] = useState(false);
    const repeatOrder = searchParams.get("RepeatOrder") == "true" ? true : false;
    const navigate = useNavigate();
    const [isCertificationsModalOpen, setIsCertificationModalOpen] = useState(false);

    const [priceToAdd, setPriceToAdd] = useState(0);

    useEffect(() => {
        (async () => {
            const response = await getAllCertifications();
            if (!response || !response.success) {
                toast.error("Unable To Fetch Data ");
                return;
            };

            const { data } = response;
            console.log(data);
            setCertificationData(data);

        })();

    }, []);


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="sm:col-span-1 p-4 border-l border-gray-300 shadow-sm">
            <div>
                <div className="price-tabs flex flex-col" style={{ marginBottom: "20px" }}>
                    {createQuoteState?.commanValues.price > 0 ? (
                        <div
                            className={
                                createQuoteState.commanValues.selectedShipMethod == 0
                                    ? "box-pr pr-checked"
                                    : "box-pr"
                            }
                        >
                            <ShowPriceBox
                                price={
                                    createQuoteState?.commanValues.price &&
                                        createQuoteState?.commanValues.price > 0
                                        ? Number(createQuoteState.commanValues.price + createQuoteState?.commanValues?.certificationCost).toFixed(2)
                                        : 0
                                }
                                business={
                                    `${Math.floor(createQuoteState?.commanValues?.leadTime1 / 7)}-${Math.floor(createQuoteState?.commanValues?.leadTime1 / 7) + 1
                                    }` + ` weeks`
                                }
                                Shipped={`Shipped ${calculateDeliveryTime(
                                    createQuoteState?.commanValues?.leadTime1,
                                    Date.now()
                                )}`}
                                updateCommanValueOnChange={updateCommanValueOnChange}
                                value={0}
                                id="1"
                                shipMethod={createQuoteState.commanValues.selectedShipMethod}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    {createQuoteState?.commanValues.price1 > 0 ? (
                        <div
                            className={
                                createQuoteState.commanValues.selectedShipMethod == 1
                                    ? "box-pr pr-checked"
                                    : "box-pr"
                            }
                        >
                            <ShowPriceBox
                                price={
                                    createQuoteState?.commanValues.price1 &&
                                        createQuoteState?.commanValues.price1 > 0
                                        ? Number(createQuoteState.commanValues.price1 + createQuoteState?.commanValues?.certificationCost).toFixed(2)
                                        : 0
                                }
                                business={
                                    `${Math.floor(createQuoteState?.commanValues?.leadTime2 / 7)}-${Math.floor(createQuoteState?.commanValues?.leadTime2 / 7) + 1
                                    }` + ` weeks`
                                }
                                Shipped={`Shipped ${calculateDeliveryTime(
                                    createQuoteState?.commanValues?.leadTime2,
                                    Date.now()
                                )}`}
                                updateCommanValueOnChange={updateCommanValueOnChange}
                                value={1}
                                id="2"
                                shipMethod={createQuoteState.commanValues.selectedShipMethod}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    {createQuoteState && createQuoteState.stManualPrice ? (
                        <div
                            className={
                                createQuoteState.commanValues.selectedShipMethod == 2
                                    ? "box-pr pr-checked"
                                    : "box-pr"
                            }
                        >
                            <ShowPriceBox
                                price={
                                    createQuoteState &&
                                        createQuoteState.stManualPrice &&
                                        createQuoteState.stManualPrice > 0
                                        ? Number(createQuoteState.stManualPrice + createQuoteState?.commanValues?.certificationCost).toFixed(2) + createQuoteState?.commanValues?.certificationCost
                                        : 0
                                }
                                business={
                                    `${Math.floor(createQuoteState?.commanValues?.leadTime3 / 7)}-${Math.floor(createQuoteState?.commanValues?.leadTime3 / 7) + 1
                                    }` + ` weeks`
                                }
                                Shipped={`Shipped ${calculateDeliveryTime(
                                    createQuoteState?.commanValues?.leadTime3,
                                    Date.now()
                                )}`}
                                updateCommanValueOnChange={
                                    createQuoteState.updateCommanValueOnChange
                                }
                                value={2}
                                id="3"
                                shipMethod={createQuoteState.commanValues.selectedShipMethod}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                    {
                        (!createQuoteState?.commanValues?.price
                            && !createQuoteState?.commanValues.price1
                            && !createQuoteState.stManualPrice) ?
                            <p className='text-red-500 '>
                                A manual review is required for the uploaded file(s) in the parts.
                                Please use the chat functionality for further assistance.
                            </p> : null
                    }


                    <CertificationModal
                        show={isCertificationsModalOpen}
                        certifications={certificationsData}
                        onClose={() => setIsCertificationModalOpen(false)}
                    />

                    <div className="flex flex-col ">
                        <div onClick={() => setIsCertificationModalOpen(true)} className='my-4 flex items-center justify-center gap-x-2 border py-2'>
                            <PlusIcon additionalClass={'h-5'} />
                            <p>Add Certifications</p>
                        </div>


                        {((createQuoteState?.commanValues.price && (createQuoteState?.commanValues.leadTime1 != null || createQuoteState?.commanValues.leadTime1 != undefined)) || (createQuoteState?.commanValues.price1 && (createQuoteState?.commanValues.leadTime2 != null || createQuoteState?.commanValues.leadTime2 != undefined))) ?

                            <button
                                onClick={(e) => onSubmit(e, { ptc: true })}
                                className={`border  ${createQuoteState?.commanValues?.isTargetCostActivated ? "col-span-6" : "col-span-12"} cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2 `}
                            >
                                <span>
                                    {"Proceed To Checkout"}
                                </span>
                                <ProccedToCheckOutIconComponent />
                            </button> : null
                        }
                        {(!createQuoteState?.commanValues.price && !createQuoteState?.commanValues.price1 && !createQuoteState?.commanValues.price2) ?

                            <button
                                onClick={(e) => onSubmit(e, { saveQuote: true })}
                                className={`border  ${createQuoteState?.commanValues?.isTargetCostActivated ? "col-span-6" : "col-span-12"} cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2 `}
                            >
                                <span>
                                    {"Request Review"}
                                </span>
                                <ForwardToPurchaseIcon />
                            </button> : null
                        }
                        {(selectedQuote?.isTargetCostActivated && !selectedQuote?.userTargetCost && !selectedQuote?.myorders) ?

                            <button
                                onClick={(e) => handleDraftQuoteFunctionality({
                                    functionalityName: 'stc'
                                })}
                                className="border col-span-6 cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2"
                            >
                                <span>
                                    Submit Target Cost
                                </span>
                                <DocIcon additionalClasses={'h-5'} />
                            </button> : selectedQuote?.isTargetCostActivated && selectedQuote?.userTargetCost && !selectedQuote?.myorders ? <button
                                onClick={(e) => handleDraftQuoteFunctionality({
                                    functionalityName: 'stc'
                                })}
                                className="border col-span-6 cursor-pointer bg-slate-500 text-white h-10  w-full items-center flex justify-center gap-x-2"
                            >
                                <span>
                                    Target Cost Submitted
                                </span>
                                <DocIcon additionalClasses={'h-5'} />
                            </button> : null
                        }

                        {((createQuoteState?.commanValues.price && (createQuoteState?.commanValues.leadTime1 != null || createQuoteState?.commanValues.leadTime1 != undefined)) || (createQuoteState?.commanValues.price1 && (createQuoteState?.commanValues.leadTime2 != null || createQuoteState?.commanValues.leadTime2 != undefined))) ?

                            <button
                                onClick={(e) => handleDraftQuoteFunctionality({
                                    functionalityName: 'ftp'
                                })}
                                className="border col-span-6 cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2 mt-1"
                            >
                                <span>
                                    Forward To Purchaser
                                </span>
                                <ForwardToPurchaseIcon additionalClasses={'mb-1'} />
                            </button> : null
                        }
                        {

                            <button
                                onClick={(e) => handleDraftQuoteFunctionality({
                                    functionalityName: 'chat'
                                })}
                                className="border cursor-pointer col-span-3 bg-primaryColor text-white p-2 w-full items-center flex justify-center gap-x-2 mt-1"
                            >
                                <span>
                                    Chat
                                </span>
                                <MessageIcon />
                            </button>
                        }
                        <div className="relative">
                            <button
                                onClick={toggleDropdown}
                                className="w-full  h-10 px-2 flex items-center bg-slate-200 hover:bg-slate-300 text-black justify-center border rounded-none mt-1"
                            >
                                <span className="text-[12px] whitespace-nowrap ">More Options</span>
                                <ArrowIcon additionalClasses="h-4 ml-2" />
                            </button>

                            {isOpen ? (
                                <div className="absolute z-20 left-0 mt-2 w-full bg-slate-100 text-black border border-white rounded shadow-lg">
                                    {/* Conditional Download Option */}
                                    {((props.commanValues.price && props.commanValues.leadTime1) ||
                                        (props.commanValues.price1 && props.commanValues.leadTime2)) ? (
                                        <button
                                            onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'dq' })}
                                            className="w-full flex justify-center items-center gap-x-6 px-4 py-2 text-sm hover:bg-slate-200"
                                        >
                                            <span className="text-[12px]">Download</span>
                                            <DownloadIcon additionalClass={'!h-4'} />
                                        </button>
                                    ) : null}
                                    {/* Team Option */}
                                    <button
                                        onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'teams' })}
                                        className="w-full flex justify-center items-center gap-x-6 px-4 py-2 text-sm hover:bg-slate-200"
                                    >
                                        <span className="text-[12px]">Team</span>
                                        <UsersIcon additionalClasses={'h-4'} />
                                    </button>
                                    {((props.commanValues.price && props.commanValues.leadTime1) ||
                                        (props.commanValues.price1 && props.commanValues.leadTime2)) ? (
                                        <button
                                            onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'share' })}
                                            className="w-full flex justify-center items-center gap-x-6 px-4 py-2 text-sm hover:bg-slate-200"
                                        >
                                            <span className="text-[12px]">Share</span>
                                            <ShareIcon additionalClass={'!h-4'} />
                                        </button>
                                    ) : null}
                                    {
                                        _id && !funcName ? selectedQuote.isCancelled ?
                                            <button
                                                onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'move-to-progress' })}
                                                className="w-full  h-10 px-2 flex items-center hover:bg-slate-300 text-black justify-center border rounded-none"
                                            >
                                                <span className="text-[12px] whitespace-nowrap ">Move To Progress</span>
                                                <ArrowIcon additionalClasses="h-4 ml-2" />
                                            </button> :
                                            <button
                                                onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'archive' })}
                                                className="w-full  h-10 px-2 flex items-center  hover:bg-slate-300 text-black justify-center  rounded-none"
                                            >
                                                <span className="text-[12px] whitespace-nowrap ">Archive</span>
                                                <ArrowIcon additionalClasses="h-4 ml-2" />
                                            </button> :
                                            null

                                    }
                                </div>

                            ) : null}
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default RightSection