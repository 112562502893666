import React, {
  useMemo,
  useRef,
} from "react";

import {
  Color,
  Vector3,
} from "three";

import { useUpdate, useFrame } from "react-three-fiber";

export default function Line({point1, point2, color, linewidth}) {
  const point1Vec = useMemo(() => Array.isArray(point1)
    ? new Vector3(point1[0], point1[1], point1[2])
    : point1,
    [point1]);
  const point2Vec = useMemo(() => Array.isArray(point2)
    ? new Vector3(point2[0], point2[1], point2[2])
    : point2,
    [point2]);

  const geomRef = useUpdate(geom => {
    geom.vertices = [
      Array.isArray(point1) ? new Vector3(point1[0], point1[1], point1[2]) : point1,
      Array.isArray(point2) ? new Vector3(point2[0], point2[1], point2[2]) : point2,
    ];
    geom.verticesNeedUpdate = true;
  }, [point1, point2]);

  const mainRef = useRef();
  useFrame(() => {
    const p1 = point1Vec?.current;
    const p2 = point2Vec?.current;
    if(p1?.isVector3 || p2?.isVector3) {
      const point1 = point1Vec?.current || point1Vec;
      const point2 = point2Vec?.current || point2Vec;
      if(!mainRef.current) return;
      mainRef.current.geometry.vertices = [
        Array.isArray(point1) ? new Vector3(point1[0], point1[1], point1[2]) : point1,
        Array.isArray(point2) ? new Vector3(point2[0], point2[1], point2[2]) : point2,
      ];
      mainRef.current.geometry.verticesNeedUpdate = true;
      return;
    }
  });

  return (
    <>
      <line
        frustumCulled={false}
        ref={mainRef}
      >
        <geometry
          attach="geometry"
          ref={geomRef}
        />
        <lineBasicMaterial
          attach="material"
          color={color || new Color(0.025, 0.025, 0.025)}
          linewidth={linewidth}
        />
      </line>
    </>
  );
}
