import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { useState } from "react";
import {
  getToleranceDetailsById,
  updateTolerance,
} from "../../../utils/actions/allactions";
import {
  validateAdmin,
  numberInputOnWheelPreventChange,
  getToken,
} from "../../../utils/helper";
import { toast } from "react-toastify";
import useDocumentTitle from "../../../utils/useDocumentTitle";
import SkeltonLoadingComponent from "../../../components/LoadingComponents/SkeltonLoadingComponent";

const EditToleranceMaster = () => {
  const token = getToken();
  const navigate = useNavigate();
  useDocumentTitle("Edit Tolerance");
  const [initialValue, setInitialValue] = useState(null);
  const [error, setError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getToleranceDetailsById(id);
        if (data && data.succes) {
          setInitialValue(data.data);
        }
      } catch (error) {
        setError("Unable To Fetch Data");
      }
    };
    fetchData();
  }, []);

  const validateToleranceVal = () => {
    // if (!initialValue.name || !initialValue.TolFactor) {
    //   return false;
    // }
    // return true;
    return !(!initialValue.name || !initialValue.TolFactor);
  };

  const handleEditTolerance = async () => {
    setError("");
    const isToleranceValid = validateToleranceVal();

    if (!isToleranceValid) {
      setError("Enter valid values for all the field");
      return;
    }
    try {
      const updatedTolerance = await updateTolerance(token, initialValue);

      if (updatedTolerance?.data?.data?.code == 11000) {
        toast.error("Tolerance already exists");
      } else {
        navigate("/admin/tolerance");
        toast.success("Tolerance updated successfully");
      }
    } catch (error) {
      console.error("Error Triggered By Edit Tolerance Master In Line 66");
    }
  };

  return (
    <div className="admin-container">
      <div>
        <Sidebar />
      </div>
      <div className="admin-panel-container">
        <h1 className="center-heading text-2xl font-bold my-2">
          {" "}
          Edit Tolerance{" "}
        </h1>
        {initialValue ? (
          <div className="edit-company-container">
            <div>
              <label htmlFor="name"> Name </label>: &nbsp;
              <input
                name="name"
                type="text"
                placeholder="Enter  name"
                value={initialValue.name}
                onChange={(e) => {
                  setInitialValue({ ...initialValue, name: e.target.value });
                }}
              />
            </div>

            <div>
              <label htmlFor="TolFactor"> TolFactor </label>: &nbsp;
              <input
                name="TolFactor"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                placeholder="Enter TolFactor"
                value={initialValue.TolFactor || ""}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    TolFactor: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <SkeltonLoadingComponent />
        )}

        <div className="center-heading  ">
          <button className="button-search" onClick={handleEditTolerance}>
            {" "}
            Update{" "}
          </button>
        </div>

        {error ? (
          <div
            style={{
              color: "red",
              fontSize: "15px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            {error}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EditToleranceMaster;
