import React from 'react';
import PhotoViewer from '../../../components/PhotoViewer/PhotoViewer';
import img1 from "../ProjectsImages/Casting/img (1).jpg";
import img2 from "../ProjectsImages/Casting/img (2).jpg";
import img3 from "../ProjectsImages/Casting/img (3).jpg";
import img4 from "../ProjectsImages/Casting/img (4).jpg";
import img5 from "../ProjectsImages/Casting/img (5).jpg";
import img6 from "../ProjectsImages/Casting/img (6).jpg";
import logo from "../../../images/logo.svg";
import { Link } from "react-router-dom";

const CastingProjects = () => {
    const imageArr = [
        img1,
        img2,
        img3,
        img4,
        img5,
        img6
      ];

    return (
        <>
      <div className="pvtop-bar">
        <Link to="">
          <img src={logo} />
        </Link>{" "}
        <Link to="/projects" className="back-btn">
          Back
        </Link>
      </div>
      <div className="csr-section">
        <h4 style={{ textAlign: "center" }}>Casting Projects</h4>
          <span>
            <PhotoViewer images={imageArr} altText={`image`} />
          </span>
      </div>
      
    </>
    );
}

export default CastingProjects;
