import React from 'react';
import {  useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Header from '../../components/Header/header';
import jwt_decode from 'jwt-decode';
import {
        fetchQuoteById,
        OptimizedQuoteEmail,
        getOrderForQuotationId
      } from '../../utils/actions/allactions';


const ManualQuote = () => {
  let token = localStorage.getItem('Token');
  const loggedInUser = localStorage.getItem("Token");
  const [selectedQuote,setSelectedQuote] = useState();
  const [isOrderAlreadyCreated,setIsOrderAlreadyCreated] = useState(false);
  let { _id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    let decode;
    if (token) {
      decode = jwt_decode(token);
      if (!decode) {
        navigate('/');
        toast.error('Invalid Token found!');
      }else{
        if(_id){
            fetchData()
        }else{
            toast.error("Required information not available");
            navigate('/');
        }
      }
    }
    else {
      navigate('/');
    }
  }, []);

  const fetchData = async ()=>{
    const response = await fetchQuoteById(_id,token);
    if(response?.data?.data){
        setSelectedQuote(response.data.data);
        let orderResponse = await getOrderForQuotationId(token,response?.data?.data?._id);
        if(orderResponse.status){
          setIsOrderAlreadyCreated(true);
        }else{
          setIsOrderAlreadyCreated(false);
        }
    }else{
        toast.error("Quotation not found!")
        navigate("/saved-quote");
    }
    
  }

  const handleOptimizeQuote = async () => {
    try{
      if(isOrderAlreadyCreated){
        toast.error("Order is already created for this quotation. Please create new project.")
        return;
      }
        let optimizeResponse =await OptimizedQuoteEmail(_id, token);
        // console.log("OPtimized respomnse",optimizeResponse)
        if(optimizeResponse.status){
            toast.success("Manual quote requested successfully");
            navigate('/saved-quote');
        }
    }catch(err){
        toast.error('Something went wrong!');
        navigate('/saved-quote');
    }
  }


    return (
        <div>
           <div className="content-wrapper">
        {loggedInUser ? (
          <div>
            <div>
              <Header />
              <div className="dashboard-holder">
                <div className="sm-container">
                  <div className="dashboard-option">
                    <div className="dash-left">
                      <div className="ds-heading">
                        You are about request a manual review for quotation with details given below:
                      </div>
                      <div>
                        <p>Ref ID : {selectedQuote?.RefId} V-{selectedQuote?.version}</p>
                        <p>Project Name : {selectedQuote?.projectName}</p> 
                      </div>
                    </div>
                    <div>
                        Please click "Ok" to proceed . Our applications team will review this manually and get back to you shortly.
                    </div>
                    <div style={{display:'flex'}}>
                        <button className='req-quote-btn' style={{minWidth:'200px'}}  onClick={() => handleOptimizeQuote()}>
                            Ok
                        </button>
                        <button className='req-quote-btn' style={{minWidth:'200px',marginLeft:'30px'}}  onClick={() => navigate('/saved-quote')}>
                            Go to Saved Quotes
                        </button>
                    </div>
                
                  </div>   
                  <div className="privacy-text">
                      <strong>Privacy:</strong> 8xParts 3D secures all uploaded files,
                      protecting your intellectual property. If you can’t upload your
                      files at the moment, use our sample part to try out our instant
                      quoting and DfM tools or contact us on <a href="mailto: info@8xparts.com">info@8xparts.com</a> to sign an NDA.
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
        </div>
    );
}

export default ManualQuote;
