import React from 'react';
import Processing from '../../../../images/processing.svg';
import GettingQuote from '../../../../images/getting-quote.svg';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../../components/Loading';

const QuotationLoader = ({processing,selectedProcess,globeLoader,isLoading}) => {
  const {quoteId} = useParams();
  return (
    <>
        {
        processing && selectedProcess < 3 ? (
          <div className="processing" style={{ zIndex: 2 }}>
            <div><img src={Processing} alt='processing' /></div>
            <div>
              <h2 className="font-bold text-xl">{quoteId ? "Loading " : "Uploading"} your part...</h2>
              <div style={{ padding: '0 20px' }}>Dynamic algorithm is processing your design to provide an instant quotation for you.</div>
            </div>
          </div>
        ) : ((globeLoader && selectedProcess < 3)) && (
          <div className="processing" style={{ zIndex: 2 }}>
            <div><img src={GettingQuote} alt='instant quote' /></div>
            <div>
              <h2 className="font-bold text-xl">Getting Instant Quotation</h2>
              <p>Fetching the best price for you.</p>
            </div>
          </div>
        )
      }
      {isLoading ? <Loading /> : ""}
    </>
  )
}

export default QuotationLoader