import logo from "../../images/logo.png";
import tick from "../../images/tick.svg";
import cornerone from "../../images/corner-one.svg";
import cornertwo from "../../images/corner-two.svg";
import cornerthree from "../../images/corner-three.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import {
    checkEmail,
    loginViaPassword,
    triggerLoginOTP,
    loginViaOTP,
    registerNewUser,
    forgotPassword,
} from "../../utils/actions/loginactions";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import jwt_decode from "jwt-decode";
import qs from "query-string";
import { numberInputOnWheelPreventChange } from "../../utils/helper";
import { firebaseSignOnLoginSuccess } from "../../utils/firebase";
import moment from "moment";
import { CrossIcon } from "../Icons";
import { checkCreateUser } from "../../utils/actions/userActions";

const SignInModal = ({ show, onSignIn, onClose, userEmail }) => {


    // console.log('User Email Is :', userEmail);


    if (!show) return null;
    const navigate = useNavigate();
    let token = localStorage.getItem("Token");
    let decode;

    const [values, setValues] = useState({
        email: userEmail,
        password: "",
        firstName: "",
        lastName: "",
        companyName: "",
        companySiteId: "",
        companySite: "",
        otp: "",
    });


    useEffect(() => {
        if (userEmail && show) {
            checkIsEmail();
        }

    }, [show, userEmail])

    const [emailCheck, setEmailCheck] = useState({});
    const [isEmailCheck, setIsEmailCheck] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [otpTriggered, setOtpTriggered] = useState(false);
    const [check, setCheck] = useState(true);

    const { search } = window.location;
    const param = qs.parse(search);

    const [errors, setErrors] = useState({
        email: "",
        password: "",
    });
    const [isloading, setIsLoading] = useState(false);



    const checkIsEmail = async () => {

        const id = localStorage.getItem("_id");
        const token = localStorage.getItem("Token");
        setIsLoading(true);
        if (!id || !token) {
            const response = await checkCreateUser(values.email);
            console.log("Response :", response);
            if (!response || !response.success) {
                throw new Error();
            };
            const { data } = response;
            const { _id, token } = data;
            if (_id) {
                localStorage.setItem("_id", _id);
            }
            if (token) {
                localStorage.setItem("Token", JSON.stringify(token));
            }
        }


        try {
            const payload = {
                email: values.email,
            };
            const response = await checkEmail({ payload });
            if (response.statuscode !== 200) {
                toast.error(response.message);
                setIsLoading(false);
                return;
            }
            setEmailCheck(response.data);
            setValues({
                ...values,
                companySiteId: response.data?.company?._id,
                companySite: response.data?.sites[0],
            });
            if (response.data.isAdmin || !response.data.isVerified) {
                if (!response.data.isNewUser) {
                    triggerOTP();
                    setOtpTriggered(true);
                    setShowOtp(true);
                }
            }
            setIsEmailCheck(true);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            let message =
                err && err.message ? err.message : "Something went wrong during login";
            toast.error(message);
            return false;
        }
    };
    const loginPassword = async () => {
        setIsLoading(true);
        try {
            let response;
            if (otpTriggered) {
                const payload = {
                    email: values.email,
                    otp: values.otp,
                    signedInFor7Days: check,
                };
                if (!values.otp) {
                    toast.error("Please enter the OTP");
                    setIsLoading(false);
                    return;
                }

                response = await loginViaOTP({ payload });
            } else {
                const payload = {
                    email: values.email,
                    password: values.password,
                    signedInFor7Days: check,
                };

                if (!values.password) {
                    toast.error("Please enter the password");
                    setIsLoading(false);
                    return;
                }
                response = await loginViaPassword({ payload });
            }

            if (window.location.host === "make.8xparts.com") {
                // window?.dataLayer?.push({
                //   event: "loginUser",
                //   userId: response?.data?._id,
                //   referenceId:
                //     response?.data?.email?.split("@")[0] +
                //     response?.data?.email?.split("@")[1]?.split(".")[0],
                // });
            }
            if (response.statuscode === 200) {
                toast.success(response.message);
                localStorage.setItem("Token", JSON.stringify(response?.data?.token));
                localStorage.setItem("firstName", response?.data?.firstName);
                localStorage.setItem("lastName", response?.data?.lastName);
                localStorage.setItem("email", response?.data?.email);
                localStorage.setItem("_id", response?.data?._id);
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("7DayLogin", check);
                localStorage.setItem(
                    "tokenValidTill",
                    new Date(Date.now() + 86400000 * 30).valueOf()
                );
                onSignIn();
                onClose();
                // sessionStorage.setItem("isLoggedIn", true);
                // firebaseSignOnLoginSuccess();
                // let pathToRedirect = JSON.parse(localStorage.getItem("pathAfterLogin"));
                // if (pathToRedirect) {
                //     // console.log('---------test7-----------',`${pathToRedirect.pathname}${pathToRedirect.search}`)
                //     let pathname = pathToRedirect.pathname;
                //     if (search.includes("?chat=true")) {
                //         if (response?.data?.email?.includes("@8xparts.com")) {
                //             pathname = "/admin/quotes";
                //         }
                //     }
                //     // console.log('---------test6-----------',`${pathToRedirect.pathname}${pathToRedirect.search}`)
                //     localStorage.removeItem("pathAfterLogin");

                //     (`${pathname}${pathToRedirect.search}`);
                // } else {
                //     if (param.redirect_url) {
                //         // console.log('---------test2-----------',param.redirect_url)
                //         navigate(`${param.redirect_url}${search}`, { replace: true });
                //     } else {
                //         navigate("/saved-quote", { replace: true });
                //     }
                // }
            } else {
                toast.error(response.message);
            }
            setIsLoading(false);
        } catch (err) {
            let message =
                err && err.message ? err.message : "Something went wrong during login";
            toast.error(message);
            setIsLoading(false);
            return false;
        }
    };

    const triggerOTP = async () => {
        setShowOtp(true);
        setIsLoading(true);
        try {
            const payload = {
                email: values.email,
            };
            const response = await triggerLoginOTP({ payload });
            setOtpTriggered(true);
            toast.success("One Time Password Sent To Your Entered Email Id");
            setIsLoading(false);
        } catch (err) {
            let message =
                err && err.message ? err.message : "Something went wrong during login";
            toast.error(message);
            setIsLoading(false);
            return false;
        }
    };

    function checkPassword(password) {
        if (
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@@#\$%\^&\*])(?=.{6,})/.test(
                password
            )
        ) {
            return true;
        }
        return false;
    }

    const registerUser = async () => {

        setIsLoading(true);
        try {
            let payload;
            const tempUserId = localStorage.getItem("_id");
            if (values.companySiteId) {
                const {
                    email,
                    password,
                    firstName,
                    lastName,
                    companySiteId,
                    companySite,
                } = values;
                payload = {
                    email: email,
                    password: password,
                    firstName: firstName,
                    lastName: lastName,
                    companySiteId: companySiteId,
                    userCompanySite: companySite,
                    _id: tempUserId,
                };
                if (
                    !password ||
                    !firstName ||
                    !lastName ||
                    !companySiteId ||
                    !companySite
                ) {
                    toast.error("Please enter the required details");
                    setIsLoading(false);
                    return;
                }

                if (!checkPassword(password)) {
                    toast.error("Please enter valid password");
                    setIsLoading(false);
                    return;
                }
            } else if (emailCheck.isAdmin) {
                const { email, firstName, lastName } = values;
                payload = {
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                };
                if (!firstName || !lastName) {
                    toast.error("Please enter the required details");
                    setIsLoading(false);
                    return;
                }
            } else {
                const { email, password, firstName, lastName, companyName } = values;
                const tempUserId = localStorage.getItem("_id");
                payload = {
                    email: email,
                    password: password,
                    firstName: firstName,
                    lastName: lastName,
                    companyName: companyName,
                    _id: tempUserId,

                };
                const isEmailValid = isValidEmail(payload.email);

                if (payload?.email && payload?.email.split("@")[1] == '8xparts.com') {
                    toast.error("The requested domain is not allowed.");
                    return;
                }



                if (!isEmailValid) {
                    toast.error("Please Enter A Valid Email Id");
                    return;
                }



                if (!password || !firstName || !lastName) {
                    toast.error("Please enter the required details");
                    setIsLoading(false);
                    return;
                }
                if (!checkPassword(password)) {
                    toast.error("Please enter valid password");
                    setIsLoading(false);
                    return;
                }
            }

            const response = await registerNewUser({ payload });
            toast.success(response.message);

            setShowOtp(true);
            setOtpTriggered(true);
            setIsLoading(false);
            setIsRegistered(true);
        } catch (err) {
            console.log("Error :", err);
            let message =
                err && err.message ? err.message : "Something went wrong during login";
            toast.error(message);
            setIsLoading(false);
            return false;
        }
    };


    const isShow = () => {
        const { isNewUser, isAdmin } = emailCheck;

        if (!isNewUser && isEmailCheck) {
            if (showPassword || showOtp) {
                return false;
            }
            return true;
        }

        if (!isNewUser && isAdmin) return true;
    };

    const handleonChange = (e) => {
        setValues({ ...values, companySite: e.target.value });
    };

    const onKeyDown = (e) => {
        const code = e.keyCode ? e.keyCode : e.which;
        if (code == 13) {
            e.preventDefault();
            if (!emailCheck.isNewUser) {
                if (showPassword || showOtp) loginPassword();
                else {
                    checkIsEmail();
                }
            } else {
                if ((otpTriggered || !emailCheck.isAdmin) && showOtp) {
                    loginPassword();
                } else registerUser();
            }
        }
    };

    const resetPage = () => {
        setIsEmailCheck(false);
        setValues({
            email: "",
            password: "",
            firstName: "",
            lastName: "",
            companyName: "",
            companySiteId: "",
            companySite: "",
            otp: "",
        });
        setEmailCheck({});
        setIsEmailCheck(false);
        setShowPassword(false);
        setShowOtp(false);
        setIsRegistered(false);
        setOtpTriggered(false);
        setCheck(true);
        setErrors({
            email: "",
            password: "",
        });
        setIsLoading(false);
    };

    const triggerSetPassword = async () => {
        try {
            const payload = {
                email: values.email,
            };
            const response = await forgotPassword({ payload });
            toast.success(response.message);
            // console.log(response);
        } catch (err) {
            console.log(
                "error in loginjs 508 on" +
                moment(Date.now()).format("DD MMM YYYY hh:mm:ss a a"),
                err
            );
            toast.error(response.message);
        }
    };


    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ">
            <div className="log-right bg-white p-5 relative ">
                <div onClick={onClose} className="absolute top-5 right-10">
                    <CrossIcon
                        additionalClasses={'h-5'}
                    />
                </div>
                <div className="change-login">
                    <div className="form-heading">Sign in to your account</div>
                </div>
                <form className="login-form-bx">
                    <div className="mb-3">
                        <div className="email-and-change-container">
                            <label
                                htmlFor="exampleInputEmail1"
                                className="form-label-email"
                            >
                                Email
                            </label>
                            {/* {isEmailCheck && (
                                <div onClick={() => resetPage()}>
                                    {" "}
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="form-label-email"
                                    >
                                        Not your email ? Click here
                                    </label>
                                </div>
                            )} */}
                        </div>
                        <input
                            autoComplete="username"
                            type="email"
                            className="form-control"
                            id="exampleInputEmails1"
                            placeholder="Enter here"
                            aria-describedby="emailHelp"
                            value={values.email}
                            // disabled={isEmailCheck}
                            onChange={(e) => {
                                setValues({ ...values, email: e.target.value });
                            }}
                            onKeyDown={(e) => onKeyDown(e)}
                        />
                        {errors.email && <span className="error">{errors.email}</span>}
                    </div>


                    {emailCheck.isNewUser && !showOtp ? (
                        <div className="names-field">
                            <div className="mb-3">
                                <label className="form-label">First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter here"
                                    value={values.firstName}
                                    onChange={(e) => {
                                        setValues({ ...values, firstName: e.target.value });
                                    }}
                                />
                                {errors.password && (
                                    <span className="error">{errors.password}</span>
                                )}
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter here"
                                    value={values.lastName}
                                    onChange={(e) => {
                                        setValues({ ...values, lastName: e.target.value });
                                    }}
                                />
                                {errors.password && (
                                    <span className="error">{errors.password}</span>
                                )}
                            </div>
                        </div>
                    ) : null}

                    {(isEmailCheck &&
                        emailCheck.isNewUser &&
                        !emailCheck.isAdmin &&
                        !showOtp) ||
                        showPassword ? (
                        <div className="mb-3">
                            <div className="email-and-change-container">
                                <label
                                    htmlFor="exampleInputPassword1"
                                    className="form-label"
                                >
                                    {emailCheck.isNewUser ? "Set Password" : "Password"}
                                </label>
                                {!emailCheck.isNewUser && (
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="form-label-email"

                                    >
                                        Forgot Password ? <span className="text-primaryColor" onClick={triggerSetPassword}>Click here</span>
                                    </label>
                                )}
                            </div>
                            <input
                                autoComplete="current-password"
                                type="password"
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="Enter here"
                                value={values.password}
                                onChange={(e) => {
                                    setValues({ ...values, password: e.target.value });
                                }}
                                onKeyDown={(e) => onKeyDown(e)}
                            />
                            {emailCheck.isNewUser && (
                                <div className="password-validation">
                                    <p>
                                        A minimum 6 characters password contains a combination
                                        of{" "}
                                    </p>
                                    <ul>
                                        <li>uppercase and lowercase letter </li>
                                        <li>number </li>
                                        <li>At least 1 special character.</li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    ) : null}
                    {showOtp ? (
                        <div className="mb-3">
                            <label className="form-label">Enter OTP</label>
                            <input
                                type="number"
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="Enter here"
                                value={values.otp}
                                onWheel={numberInputOnWheelPreventChange}
                                onChange={(e) => {
                                    setValues({ ...values, otp: e.target.value });
                                }}
                                onKeyDown={(e) => onKeyDown(e)}
                            />
                            <div className="mt-2 resend-otp-container">
                                <span className="resend-otp" onClick={triggerOTP}>
                                    Resend OTP
                                </span>
                            </div>
                            {errors.password && (
                                <span className="error">{errors.password}</span>
                            )}
                        </div>
                    ) : null}
                    {isShow() && (
                        <div className=" flex gap-x-2 my-2">
                            {emailCheck?.isVerified ? (
                                <div
                                    className="bg-primaryColor text-white py-1 px-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setShowPassword(true)}
                                >
                                    Login via Password
                                </div>
                            ) : null}
                            <div
                                className="bg-primaryColor text-white py-1 px-2"
                                style={{ cursor: "pointer" }}
                                onClick={triggerOTP}
                            >
                                Login via OTP
                            </div>
                        </div>
                    )}
                    <div className="mb-3">
                        <div className="check-signup">
                            <p>
                                By signing in, you confirm that you've read and accepted our{" "}
                                <Link to="/terms-of-service" className="text-primaryColor underline" state={{ from: "login" }}>
                                    Terms of Services{" "}
                                </Link>{" "}
                                and{" "}
                                <Link to="/privacy-policy" className="text-primaryColor underline" state={{ from: "login" }}>
                                    Privacy Policy
                                </Link>
                                .
                            </p>
                        </div>
                    </div>
                    {isloading ? (
                        <Loading />
                    ) : !emailCheck.isNewUser ? (
                        !isShow() && (
                            <div
                                href="#"
                                style={{ cursor: "pointer" }}
                                className="bg-primaryColor text-white w-28 text-center py-1 "
                                onClick={
                                    showPassword || showOtp ? loginPassword : checkIsEmail
                                }
                            >
                                Submit
                            </div>
                        )
                    ) : (
                        <div
                            href="#"
                            style={{ cursor: "pointer" }}
                            className="bg-primaryColor text-white w-28 text-center py-1"
                            onClick={
                                (otpTriggered || !emailCheck.isAdmin) && showOtp
                                    ? loginPassword
                                    : registerUser
                            }
                        >
                            Submit
                        </div>
                    )}
                </form>
                {(showPassword || showOtp) && (
                    <div className="change-login">
                        <div className="signin-week">
                            <input
                                type="checkbox"
                                checked={check}
                                onClick={() => setCheck(!check)}
                            />
                            <div className="signin-text">Stay signed in for a week</div>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            {isEmailCheck &&
                                (showPassword || showOtp) &&
                                !emailCheck.isNewUser &&
                                !emailCheck.isAdmin && (
                                    <div
                                        onClick={
                                            !showPassword
                                                ? () => {
                                                    setShowPassword(true);
                                                    setShowOtp(false);
                                                    setOtpTriggered(false);
                                                }
                                                : () => {
                                                    triggerOTP();
                                                    setShowPassword(false);
                                                }
                                        }
                                        className="login-text"
                                    >
                                        {!showPassword && !emailCheck.isNewUser
                                            ? "Login via Password"
                                            : "Login via OTP"}
                                    </div>
                                )}
                        </div>
                    </div>
                )}
                <div className="signin-holder">
                    {/* <h6>
            {" "}
            <span>Or Sign in using</span>{" "}
          </h6> */}
                    {/* <div className="sign-social">
            <a href="#" className="fb-box">
              <span>
                <i Name="fa fa-facebook-square" aria-hidden="true"></i>
              </span>{" "}
              Facebook
            </a>
            <a href="#" className="ld-box">
              <span>
                <i className="fa fa-linkedin-square" aria-hidden="true"></i>
              </span>{" "}
              LinkedIn
            </a>
          </div> */}
                    {/* <div className="signup-bx">
            Don’t have an account? <Link to="/sign-up">Sign up</Link>
          </div> */}
                </div>
            </div>
        </div>
    );
};

export default SignInModal;



function isValidEmail(email) {
    // Regular expression to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if email is a string and matches the pattern
    return typeof email === "string" && emailRegex.test(email);
}