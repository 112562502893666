import { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { getPartMaking } from "../../utils/actions/allactions";
import { useNavigate } from "react-router-dom";
import { getToken, validateAdmin } from "../../utils/helper";
import useDocumentTitle from "../../utils/useDocumentTitle";
import AdminLoadingComponent from "../../components/LoadingComponents/AdminLoadingComponent";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";

const PartMakingMaster = () => {
  let navigate = useNavigate();
  useDocumentTitle("Part Marking Master");
  const [data, setData] = useState(null);
  let token = getToken();

  const fetchPartMaking = async () => {
    try {
      const partMakingData = await getPartMaking(token);
      setData(partMakingData);
      
    } catch (error) {
        toast.error("Unable To Fetch PartMaking Data");
    }
  
  };

  useEffect(() => {
    validateAdmin(token, navigate);
    fetchPartMaking();
  }, []);

  const handleCreateNew = () => {
    navigate("/admin/add-partmaking-master");
  };

  const handleEditPartMakingMaster = (id) => {
    navigate(`/admin/edit-partmaking-master?id=${id}`);
  };

  return (
    <div className="admin-container">
      
      <div className="right-container">
        <div className="center-heading">
          <h1 className="text-2xl font-bold"> Part Marking Master</h1>
          <div className="search-bar">
            <div className="row">
              <div className="col-md-9"></div>
              <div className="col-md-3">
                <div className="search-btn">
                  <button onClick={handleCreateNew} className="pr-edit-btn">
                    Create New
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="quotes-table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Name{" "}
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Factor
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {data ? (
                <tbody>
                  {data.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.name || ""}</td>
                        <td>{item.PMFactor || "NA"}</td>
                        <td>
                          <Button
                            text={"Edit /Deactivate"}
                            additionalClassName={
                              "border-red-500  px-4 py-2 bg-yellow-500 shadow-sm text-white rounded text-sm"
                            }
                            onClickFunction={() =>
                              handleEditPartMakingMaster(item._id)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <AdminLoadingComponent rows={6} columns={3} />
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardComponents(PartMakingMaster);
