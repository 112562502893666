import logo from "../../images/logo.svg";
import tick from "../../images/tick.svg";
import cornerone from "../../images/corner-one.svg";
import cornertwo from "../../images/corner-two.svg";
import cornerthree from "../../images/corner-three.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import { ResetPassword } from "../../utils/actions/loginactions";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { firebaseSignOnLoginSuccess } from "../../utils/firebase";
toast.configure();

const NewPassword = () => {
  const location = useLocation();
  const [values, setValues] = useState({
    newPassword: "",
    confirmPassword: "",
    userId: "",
  });
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  function CheckPassword(password) {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@@#\$%\^&\*])(?=.{6,})/.test(
        password
      )
    ) {
      return true;
    }
    return false;
  }
  const { id } = useParams();
  const handleSubmit = async (e) => {
    setIsLoading(true);
    try {

      e.preventDefault();
      values.userId = id;
      if (values.newPassword == "" || values.confirmPassword == "") {

        setErrors({
          ...errors,

          newPassword:
            values.newPassword === ""
              ? "Invalid Password"
              : errors?.newPassword,
          confirmPassword:
            values.confirmPassword === ""
              ? "Invalid ConfirmPassword"
              : errors.confirmPassword,
        });
        setIsLoading(false);
        return;
      }
      else if(!CheckPassword(values.newPassword)){
        setErrors({
          ...errors,
          confirmPassword: 'Please match below mentioned requirements to create a new password!' 
        })
        setIsLoading(false)
      }
      else if (values.newPassword != values.confirmPassword){
    
        toast.error("New Password does not match with your confirm password!");
        setIsLoading(false)
      }
      else{
      const resetPassId = new URLSearchParams(location.search).get('resetPassId')
      let response = await ResetPassword({ payload: { ...values, resetPassId } });
      if (response.status === false) {
        throw {
          message:
            response.message || "Something went wrong during change Password.",
        };
      }
      if (response.status === true) {
        toast.success("Password Reset success");
        localStorage.setItem("Token", JSON.stringify(response?.data?.token));
        localStorage.setItem(
          "firstName",
          response?.data?.firstName
        );
        localStorage.setItem(
          "lastName",
          response?.data?.lastName
        );
        localStorage.setItem("email", response?.data?.email);
        localStorage.setItem("_id", response?.data?._id);
        localStorage.setItem("isLoggedIn", true);
        firebaseSignOnLoginSuccess();
        navigate("/saved-quote", { replace: true });
        setIsLoading(false);
      }
      }
    } catch (err) {
      setIsLoading(false);
      let message =
        err && err.message
          ? err.message
          : "Something went wrong during change password";
      toast.error(message);
      return;
    }
  };


  return (
    <div className="login-main">
      <div className="left-shape">
        <img src={cornerone} />
      </div>
      <div className="right-bt-shape">
        <img src={cornertwo} />
      </div>
      <div className="right-top-shape">
        <img src={cornerthree} />
      </div>
      <div className="sm-container" style={{padding:'0px 10%'}}>
        <div className="login-in">
          <div className="log-left">
            <div className="logo-tp">
              {" "}
              <img src={logo} />{" "}
            </div>
            <h2>
              Order Custom Parts in less than 5 mins
            </h2>
            <p>
              From prototypes to end parts. Get your parts into production in
              less than 5 minutes. Fast and hassle-free.
            </p>
            <div className="log-points">
              <ul>
                <li>
                  <span className="icon">
                    <img src={tick} />
                  </span>{" "}
                  Build your instant quote.
                </li>
                <li>
                  <span className="icon">
                    <img src={tick} />
                  </span>{" "}
                  Send your parts to production in 5 minutes.
                </li>
                <li>
                  <span className="icon">
                    <img src={tick} />
                  </span>{" "}
                  Never worry about quality again.
                </li>
              </ul>
            </div>
          </div>
          <div className="log-right forgot-section">
            <div className="form-heading">Reset Password</div>
            {/* <p>
              Don't worry. Resetting your password is easy, just tell us the
              email address you registered with us.
            </p> */}
            <form className="login-form-bx">
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  New Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputEmails1"
                  placeholder="Enter here"
                  aria-describedby="emailHelp"
                  value={values.newPassword}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      newPassword: null,
                    });
                    setValues({ ...values, newPassword: e.target.value });
                  }}
                />
                {errors.newPassword && (
                  <span className="error">{errors.newPassword}</span>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Enter here"
                  aria-describedby="emailHelp"
                  value={values.confirmPassword}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      confirmPassword: null,
                    });
                    setValues({ ...values, confirmPassword: e.target.value });
                  }}
                />
                {errors.confirmPassword && (
                  <span className="error">{errors.confirmPassword}</span>
                )}
              </div>
              <div className="help-block errors">
                <p>
                  A minimum 6 characters password contains a combination of{" "}
                </p>
                <ul>
                  <li>uppercase and lowercase letter </li>
                  <li>number </li>
                  <li>At least 1 special character.</li>
                </ul>
              </div>
              {isloading ? (
                <Loading />
              ) : (
                <Link to="" className="btn-submit" onClick={handleSubmit}>
                  Update Password
                </Link>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewPassword;
