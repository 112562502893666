import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";

const maxLength = 10;

const ProjectComponent = ({
  project,
  index,
  handleEditClick,
  handleEditableChange,
  handleUpdateClick,
  handleCancelClick,
}) => {
  const firstUnderscoreIndex = project.projectName.indexOf("_");
  const serialNumber = project.projectName.substring(0, firstUnderscoreIndex);
  const editableText = project.projectName.substring(firstUnderscoreIndex + 1);
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (project.isEditing) {
      setShowModal(true);
    }
  }, [project]);

  const handleClose = () => {
    setShowModal(false);
    handleCancelClick(index); // Cancel editing when modal closes
  };

  return (
    <>
      {/* Normal Display */}
      { (
        <div className="flex justify-between w-full px-2.5 py-2 items-center">
          <Link
            style={{ fontSize: "12px", fontWeight: 700 }}
            className="text-primaryColor"
            to={`/quote-versions/${project.RefId}`}
          >
            <span>{serialNumber}_</span>
            <span>
              {editableText.length > maxLength
                ? editableText.slice(0, maxLength) + "..."
                : editableText}
            </span>
          </Link>
          <svg
            onClick={() => handleEditClick(index)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-pen h-4"
          >
            <path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" />
          </svg>
        </div>
      )}

      {/* Modal for Editing */}
      {project.isEditing && (
        <Modal
          show={showModal}
          onHide={handleClose}
          centered
          size="sm" 
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Project Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column align-items-center">
              <p>{serialNumber}_</p>
              <Form.Group className="w-100">
                <Form.Control
                  ref={inputRef}
                  type="text"
                  value={project.editableProjectName}
                  onChange={(e) => handleEditableChange(e, index)}
                  className="text-center text-muted"
                  style={{ fontSize: "14px" }}
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer className="flex items-center justify-end">
            <Button
              // variant="success"
              className="bg-primaryColor hover:bg-primaryColor"
              onClick={() => {
                setShowModal(false);
                handleUpdateClick(index);
              }}
            >
              Update
            </Button>
            <Button variant="danger" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ProjectComponent;


export const CorrectIcon = ({ onclickFunction }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-check h-4 text-green-500 mx-2"
      onClick={onclickFunction}
    >
      <path d="M20 6 9 17l-5-5" />
    </svg>
  );
};

export const CrossIcon = ({ onclickFunction, additionalClasses }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class={`lucide lucide-plus h-4   rotate-45 text-red-500 ${additionalClasses}`}
      onClick={onclickFunction}
    >
      <path d="M5 12h14" />
      <path d="M12 5v14" />
    </svg>
  );
};
