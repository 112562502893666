import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import upload from "../../../images/upload.svg";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import setting from "../../../images/setting-green.svg";
import saveico from "../../../images/save-ico.svg";
import shareico from "../../../images/share.svg";
import closeImg from "../../../images/close.svg";
import Dropdown from "react-bootstrap/Dropdown";
import {
  Link,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import ShowPriceBox from "./ShowPriceBox";
import moment from "moment";
import { useEffect, useState } from "react";
import { currencyFormatter } from "../../../utils/helper";
import { shareQuoteToEmails } from "../../../utils/actions/allactions";
import QRTrackingHoverInfo from "../../../components/QRTrackingHoverInfo/QRTrackingHoverInfo";
import { Loading } from "../../../components/Loading";
import { ArrowIcon, ArrowRight, DocIcon, DollarIcon, DownloadIcon, ForwardToPurchaseIcon, LucidLoader, MessageIcon, ProccedToCheckOutIconComponent, SaveChangeIconComponent, SendIconComponent, SettingIcon, ShareIcon, UsersIcon } from "../../../components/Icons";

const SelectFileSection = (props) => {
  // console.log("Selected File Section Props :",props);
  const { handleDraftQuoteFunctionality, selectedQuote } = props;
  // console.log(handleDraftQuoteFunctionality("data"));
  let loggedInUserEmail = localStorage.getItem("email");
  let email = localStorage.getItem("email");
  let userId = localStorage.getItem("_id");
  let [isInvalidFilesInEditQuote, setIsInvalidFilesInEditQuote] =
    useState(false);
  const [shareQuoteModalShow, setShareQuoteModalShow] = useState(false);
  const [newQuoteId, setNewQuoteId] = useState(null);
  const [emailToShareQuote, setEmailToShareQuote] = useState(
    new Set([loggedInUserEmail])
  );
  const [inputEmail, setInputEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isActionClicked, setIsActionClicked] = useState(false);
  let lineItemTemplate = {
    selectedFile: "",
    Notes: "",
    Qty: 1,
    description: "",
    MaterialType: "",
    SubMaterial: "",
    file: "",
    layer: "",
    partMaking: "",
    surfaceFinish: "",
    surfaceTreatment: "",
    tolerance: "",
    price: 0,
    price1: 0,
    price2: 0,
    partName: "",
    linePartName: "",
  };
  const [searchParams] = useSearchParams();
  const repeatOrder = searchParams.get("RepeatOrder") == "true" ? true : false;
  const navigate = useNavigate();
  let { _id } = useParams();
  const funcName = searchParams.get("functionalityName");
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };




  useEffect(() => {
    if (props.selectedProcess == 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [props.selectedProcess]);

  const handleQuotationShare = async () => {
    try {
      let userId = localStorage.getItem("_id");
      if (window.location.host === "make.8xparts.com") {
        // window?.dataLayer?.push({
        //   event: "shareQuotation",
        //   userId: userId,
        //   quoteRefId: props.quoteRef || props.savedQuoteRefId,
        //   referenceId:
        //     email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
        // });
      }
      if (emailToShareQuote.size === 0) {
        toast.error(
          "Please enter atleast one mail to share the quotation details"
        );
        return;
      } else {
        let data = {
          quoteId: props.quoteId,
          selectedShipMethod: props.commanValues.selectedShipMethod,
          emailToShareQuote: [...emailToShareQuote],
        };
        let token = localStorage.getItem("Token");
        let response = await shareQuoteToEmails(token, data);
        if (response.statusCode == 200) {
          setShareQuoteModalShow(false);
          setEmailToShareQuote(new Set([loggedInUserEmail]));
          toast.success("Quotation details shared successfully");
        } else {
          toast.error("Something went wrong while sharing the quote details.");
        }
      }
    } catch (err) {
      toast.error("Something went wrong.Please try again later.");
    }
  };

  const handleShareQuoteClose = () => {
    setShareQuoteModalShow(false);
  };

  const handleShareQuoteModalOpen = async () => {
    try {
      if (!props.quoteId) {
        const userResponse = confirm(
          "To use the share quote functionality you need to save the quote. Please confirm if you want to save the quote with current details?"
        );
        if (userResponse) {
          let response = await props.onSubmit(
            null,
            { saveQuote: true },
            { functionalityName: "Share" }
          );
        }
        // window.location.reload();
        return;
      } else {
        setShareQuoteModalShow(true);
      }
    } catch (error) {
      toast.error("Error In Select File Section");
    }
  };

  const handleAddButtonToReceiverList = () => {
    let response = validateEmail(inputEmail);
    if (response) {
      if (emailToShareQuote.has(inputEmail)) {
        toast.error("Email already added to receivers list");
      } else {
        let localEmailList = new Set(emailToShareQuote);
        localEmailList.add(inputEmail);
        setEmailToShareQuote(localEmailList);
        setInputEmail("");
      }
    } else {
      toast.error("Please enter a valid email");
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleDeleteShareEmail = (item) => {
    let localEmailList = new Set(emailToShareQuote);
    localEmailList.delete(item);
    setEmailToShareQuote(localEmailList);
  };

  const OnDropFunction = (files) => {
    try {
      if (repeatOrder) {
        let userConfirmation = confirm(
          "Are you sure you want to change the selected files for the previous quotation? This action will generate a new version of the quotation, which will require admin review before you can proceed with creating an order."
        );
        if (userConfirmation) {
          navigate(`/get-instant-quote/${_id}?isRepeatOrderWithUpdate=true`);
          return;
        }
        return;
      }
      let isAllFilesValid = true;
      files.forEach(file => {
        if (file.size > 10485760) {
          isAllFilesValid = false;
        }
      });

      if (isAllFilesValid) {
        props.onSelectfile(files);
      } else {
        toast.error("You can upload files with a maximum size of 10MB.");
      }
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  const onClickSampleUpload = async () => {
    try {
      let userId = localStorage.getItem("_id");
      if (window.location.host === "make.8xparts.com") {
        // window?.dataLayer?.push({
        //   event: "uploadSamplePart",
        //   userId: userId,
        //   referenceId:
        //     email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
        // });
      }
      const response = await checkIfProcessSet();
      props.setSample(true);
      if (!response) return;

      // here we add logic to upload sample file
    } catch (err) {
      console.log("error ", err);
      return err;
    }
  };
  async function createFile(url, type) {
    if (typeof window === "undefined") return; // make sure we are in the browser
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
      type: type || "",
      path: "SamplePart.stp",
    };
    let fileName = url?.slice(url.lastIndexOf("/") + 1);
    let filewa = new File([data], fileName, {
      type: type || "",
      path: "SamplePart.stp",
    });
    return new File([data], fileName, metadata);
  }

  const checkIfProcessSet = async () => {
    return new Promise(async (resolve) => {
      try {
        if (props.selectedProcess === 0) {
          toast.error("Please select a Process to continue!");
          props.setIsProcessNotSelected(true);
          return resolve(false);
        }
        const response = await createFile(
          "https://dainstamakeimages.s3.eu-west-1.amazonaws.com/8XSamplePart.stp",
          ""
        );
        props.onSelectfile([response]);
        props?.setFiles([
          ...props.file,
          Object.assign(response, {
            isNotFromMongoDB: true,
          }),
        ]);
        return resolve(true);
      } catch (err) {
        console.log(err);
        return resolve(false);
      }
    });
  };

  useEffect(() => {
    if (props.saveForFunctionality) {
      if (props.saveForFunctionality == "Share") {
        setShareQuoteModalShow(true);
      }
    }
  }, [props.saveForFunctionality]);

  useEffect(() => {
    if (props.quoteId) {
      props.values.map((item) => {
        let ext = getFileExtension(
          item.file?.originalname || item.selectedFile?.path
        );
        if (
          !(
            ext == "stp" ||
            ext == "step" ||
            ext == "iges" ||
            ext == "stl" ||
            ext == "STP" ||
            ext == "STEP" ||
            ext == "IGES" ||
            ext == "STL"
          )
        ) {
          setIsInvalidFilesInEditQuote(true);
        } else {
          setIsInvalidFilesInEditQuote(false);
        }
      });
    }
  }, [props.values]);

  let getFileExtension = (filename) => {
    return filename?.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const calculateDeliveryTime = (day, time) => {
    try {
      let startDate = moment(time).add(day, "days").format("DD MMM YYYY");
      return "by " + startDate;
    } catch (err) {
      return "";
    }
  };

  const handleAddItem = () => {
    if (props.step == 1) {
      props.setStep(2);
    }

    let localValues = [...props.values];
    localValues.push(lineItemTemplate);
    props.setValues(localValues);
  };
  const handleCertificateofconformanceClicked = (e) => {
    if (repeatOrder) {
      let userConfirmation = confirm(
        "Are you sure you want to change the selected files for the previous quotation? This action will generate a new version of the quotation, which will require admin review before you can proceed with creating an order."
      );
      if (userConfirmation) {
        navigate(`/get-instant-quote/${_id}?isRepeatOrderWithUpdate=true`);
        return;
      }
    }

    props.setIsQuoteChanged(true);
    if (props.commanValues.isConformanceCertificateRequested) {
      props.setCommanValues({
        ...props.commanValues,
        isConformanceCertificateRequested:
          !props.commanValues.isConformanceCertificateRequested,
      });
    } else {
      props.setCommanValues({
        ...props.commanValues,
        isConformanceCertificateRequested:
          !props.commanValues.isConformanceCertificateRequested,
      });
    }
  };
  const handleDimensionalInspectionReportClicked = (e) => {
    if (repeatOrder) {
      let userConfirmation = confirm(
        "Are you sure you want to change the selected files for the previous quotation? This action will generate a new version of the quotation, which will require admin review before you can proceed with creating an order."
      );
      if (userConfirmation) {
        navigate(`/get-instant-quote/${_id}?isRepeatOrderWithUpdate=true`);
        return;
      }
    }
    props.setIsQuoteChanged(true);
    if (props.commanValues.isDimensionalReportRequested) {
      props.setCommanValues({
        ...props.commanValues,
        isDimensionalReportRequested:
          !props.commanValues.isDimensionalReportRequested,
      });
    } else {
      props.setCommanValues({
        ...props.commanValues,
        isDimensionalReportRequested:
          !props.commanValues.isDimensionalReportRequested,
      });
    }
  };

  const handleQRTrackingClicked = (e) => {
    if (repeatOrder) {
      let userConfirmation = confirm(
        "Are you sure you want to change the selected files for the previous quotation? This action will generate a new version of the quotation, which will require admin review before you can proceed with creating an order."
      );
      if (userConfirmation) {
        navigate(`/get-instant-quote/${_id}?isRepeatOrderWithUpdate=true`);
        return;
      }
    }
    props.setIsQuoteChanged(true);
    if (
      props.commanValues.hidePrice ||
      props.isInvalidFiles ||
      props.commanValues.price == 0
    ) {
      props.setCommanValues({
        ...props.commanValues,
        isQrTrackingRequested: !props.commanValues.isQrTrackingRequested,
      });
    } else {
      let totalQuantityOfItems = 0;
      for (let i = 0; i < props.values.length; i++) {
        totalQuantityOfItems += props.values[i].Qty;
      }

      if (props.commanValues.price > 0 && !props.commanValues.hidePrice) {
        if (props.commanValues.isQrTrackingRequested) {
          props.setCommanValues({
            ...props.commanValues,
            isQrTrackingRequested: !props.commanValues.isQrTrackingRequested,
            price: Number(props.commanValues.price) - 5 * totalQuantityOfItems,
            price1:
              props.selectedProcess == 1
                ? Number(props.commanValues.price1) - 5 * totalQuantityOfItems
                : 0,
            additionalCost1: 0,
            additionalCost2: 0,
          });
        } else {
          console.log(
            "am ehere values of is qr treacking requested 444",
            !props.commanValues.isQrTrackingRequested
          );
          props.setCommanValues({
            ...props.commanValues,
            isQrTrackingRequested: !props.commanValues.isQrTrackingRequested,
            price: Number(props.commanValues.price) + 5 * totalQuantityOfItems,
            price1:
              props.selectedProcess == 1
                ? Number(props.commanValues.price1) - 5 * totalQuantityOfItems
                : 0,
            additionalCost1: 5 * totalQuantityOfItems,
            additionalCost2: 5 * totalQuantityOfItems,
          });
        }
      }
    }
  };

  return (
    <div>
      {isLoading ? <Loading /> : ""}
      <span className="process-ico">
        <img src={setting} alt="setting" />
      </span>
      <div className="upload-in">
        <h5>Upload Requirement</h5>
        <Row>
          <Col md={7}>
            <div>
              {props.values && props.values.length === 0 && (
                <Dropzone
                  onDrop={(e) => OnDropFunction(e)}
                  minSize={1024}
                  maxSize={10 * 1024 * 1024} //10mb
                  disabled={props.selectedProcess === 0 ? true : false}
                  multiple={true}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps({ className: " upload-box dropzone" })}
                    >
                      <span className="up-icon">
                        <img src={upload} alt="upload" />
                      </span>
                      <span
                        className={
                          props.selectedProcess === 0 ? "error" : "inp-txt"
                        }
                        style={{ fontSize: "16px" }}
                      >
                        {props.selectedProcess === 0
                          ? "Please select a Process to continue..."
                          : "Drop files here to upload"}
                      </span>
                      <div className="file-button">
                        <input {...getInputProps()} />

                        <label
                          className="file-input__label"
                          htmlFor="file-input"
                        // onClick={() => checkIfProcessSet()}
                        >
                          <span>Select a file</span>
                        </label>
                      </div>
                      {props.selectedProcess === 1 ||
                        props.selectedProcess === 2 ? (
                        <p>
                          Instant Quotation for - .stp, .step files. Other file
                          format are also accepted (RFQ)
                        </p>
                      ) : null}
                    </div>
                  )}
                </Dropzone>
              )}
              {props.values && props.values.length > 0 && (
                <Dropzone
                  onDrop={(e) => OnDropFunction(e)}
                  minSize={1024}
                  maxSize={10 * 1024 * 1024}
                  disabled={props.selectedProcess === 0 ? true : false}
                  multiple={true}
                  reject="*.js"
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps({
                        className: " upload-box step-to-upload dropzone my-4  flex flex-col",
                      })}
                    >
                      <div className="up-text">
                        <span className="inp-txt">
                          Drop files here to upload or
                        </span>
                        <div className="file-button">
                          <input {...getInputProps()} />
                          <label htmlFor="file-input">
                            {" "}
                            <span>Browse</span>
                          </label>
                        </div>
                      </div>
                      {props.sample ? (
                        <p>Sample File Selected!</p>
                      ) : (
                        <p>{props.values.length} Files Selected!</p>
                      )}
                      {(props.selectedProcess === 1 ||
                        props.selectedProcess === 2) && (
                          <p>
                            Instant Quotation for - .stp, .step files. Other file
                            format are also accepted (RFQ)
                          </p>
                        )}
                    </div>
                  )}
                </Dropzone>
              )}
            </div>
            {props.values &&
              props.values.length === 0 &&
              (props.selectedProcess >= 3 ? (
                ""
              ) : (
                <div className="sample-box flex items-center">
                  <div className="text">
                    Not yet ready to upload your files at the moment? Use our
                    sample part file to try out our instant quotation tool
                  </div>
                  <button
                    disabled={false}
                    onClick={() => onClickSampleUpload()}
                    className="upload-btn p-1 "
                  >
                    Upload Sample Part
                  </button>
                </div>
              ))}
          </Col>

          {/* For CNC [Price not updated  by admin] below */}

          {props.selectedProcess === 1 &&
            props.stManualPrice === 0 &&
            props.values.length ? (
            <>
              <Col md={5}>
                <div>
                  <div className="price-box">
                    <Row>
                      <Col md={12}>
                        <Card
                          border="light"
                          style={{
                            width: "100%",
                            border: "0px solid rgba(255, 0, 0, 0)",
                          }}
                        >
                          <Card.Body style={{ padding: "0" }}>
                            {console.log("Invalid Files", !!props.isInvalidFiles)}
                            {console.log("isInvalidFilesInEditQuote", !!isInvalidFilesInEditQuote)}
                            {console.log("props.commanValues.hidePrice", !!props.commanValues.hidePrice)}
                            <Card.Title>
                              {props.isInvalidFiles ||
                                isInvalidFilesInEditQuote ||
                                props.commanValues.hidePrice ? (
                                <>
                                  <div className="price-box">
                                    <div className="total-price">
                                      <span>Important !</span>
                                    </div>
                                  </div>
                                  <Row>
                                    <Col md={12}>
                                      <Card
                                        border="light"
                                        style={{ width: "100%" }}
                                      >
                                        <Card.Body>
                                          <Card.Title>
                                            {props.cncError}
                                          </Card.Title>
                                        </Card.Body>
                                      </Card>
                                      <h5></h5>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <div>
                                  <div className="price-tabs">
                                    {props?.commanValues.price > 0 ? (
                                      <div
                                        className={
                                          props.commanValues
                                            .selectedShipMethod == 0
                                            ? "box-pr pr-checked"
                                            : "box-pr"
                                        }
                                      >
                                        <ShowPriceBox
                                          price={
                                            props?.commanValues.price &&
                                              props?.commanValues.price > 0
                                              ? Number(
                                                props.commanValues.price
                                              ).toFixed(2)
                                              : 0
                                          }
                                          business={
                                            `${Math.floor(
                                              props?.commanValues?.leadTime1 / 7
                                            )}-${Math.floor(
                                              props?.commanValues?.leadTime1 /
                                              7
                                            ) + 1
                                            }` + ` weeks`
                                          }
                                          Shipped={`Shipped ${calculateDeliveryTime(
                                            props?.commanValues?.leadTime1,
                                            Date.now()
                                          )}`}
                                          updateCommanValueOnChange={
                                            props.updateCommanValueOnChange
                                          }
                                          value={0}
                                          id="1"
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {props?.commanValues.price1 > 0 ? (
                                      <div
                                        className={
                                          props.commanValues
                                            .selectedShipMethod == 1
                                            ? "box-pr pr-checked"
                                            : "box-pr"
                                        }
                                      >
                                        <ShowPriceBox
                                          price={
                                            props?.commanValues.price1 &&
                                              props?.commanValues.price1 > 0
                                              ? Number(
                                                props.commanValues.price1
                                              ).toFixed(2)
                                              : 0
                                          }
                                          business={
                                            `${Math.floor(
                                              props?.commanValues?.leadTime2 / 7
                                            )}-${Math.floor(
                                              props?.commanValues?.leadTime2 /
                                              7
                                            ) + 1
                                            }` + ` weeks`
                                          }
                                          Shipped={`Shipped ${calculateDeliveryTime(
                                            props?.commanValues?.leadTime2,
                                            Date.now()
                                          )}`}
                                          updateCommanValueOnChange={
                                            props.updateCommanValueOnChange
                                          }
                                          value={1}
                                          id="2"
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              )}
                            </Card.Title>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <button
                      onClick={(e) => props.onSubmit(e, { saveQuote: true })}
                      className="border cursor-pointer bg-primaryColor text-white h-10 rounded w-full items-center flex justify-center gap-x-2"
                    >
                      <span>
                        {props.quoteId
                          ? repeatOrder
                            ? "Proceed To Checkout"
                            : "Save Changes"
                          : "Send To Review"}
                      </span>
                      {props.quoteId ? (
                        repeatOrder ? (
                          <ProccedToCheckOutIconComponent />
                        ) : (
                          <SaveChangeIconComponent />
                        )
                      ) : (
                        <SendIconComponent />
                      )}
                    </button>
                  </div>
                </div>
              </Col>
            </>
          ) : (
            ""
          )}

          {/* For CNC below */}
          {props.selectedProcess == 1 && props.stManualPrice > 0 ? (
            <>
              <Col md={5}>
                <div>
                  <div className="price-box">
                    {props.isInvalidFiles ||
                      isInvalidFilesInEditQuote ||
                      props.commanValues.hidePrice ? (
                      <>
                        <div className="price-box">
                          <div className="total-price">
                            <span>Important !</span>
                          </div>
                        </div>
                        <Row>
                          <Col md={12}>
                            <Card border="light" style={{ width: "100%" }}>
                              <Card.Body>
                                <Card.Title>{props.cncError}</Card.Title>
                              </Card.Body>
                            </Card>
                            <h5></h5>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{ flex: 1, marginRight: "10px" }}
                            className="total-price"
                          >
                            <span>Sub Total 2</span>
                            <span>
                              {currencyFormatter(
                                props.commanValues.selectedShipMethod == 0
                                  ? Number(props.commanValues.price).toFixed(
                                    2
                                  ) || 0
                                  : props.commanValues.selectedShipMethod == 1
                                    ? Number(props.commanValues.price1).toFixed(
                                      2
                                    ) || 0
                                    : Number(props.stManualPrice).toFixed(2)
                              )}
                            </span>
                          </div>
                          <div
                            className="btm-buttons"
                            style={{ marginTop: "5px" }}
                          >
                            {/* Col added to add the share button in the 3D process */}
                            <Row className="btm-buttons-container">
                              <Col>
                                <Link
                                  to=""
                                  className="br-btn sq-btn"
                                  onClick={handleShareQuoteModalOpen}
                                >
                                  <span>
                                    <span className="ico">
                                      <img src={shareico} />
                                    </span>
                                    Share
                                  </span>
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="price-tabs">
                          {props?.commanValues.price > 0 ? (
                            <div
                              className={
                                props.commanValues.selectedShipMethod == 0
                                  ? "box-pr pr-checked"
                                  : "box-pr"
                              }
                            >
                              <ShowPriceBox
                                price={
                                  props?.commanValues.price &&
                                    props?.commanValues.price > 0
                                    ? Number(props.commanValues.price).toFixed(
                                      2
                                    )
                                    : 0
                                }
                                business={
                                  `${Math.floor(
                                    props?.commanValues?.leadTime1 / 7
                                  )}-${Math.floor(
                                    props?.commanValues?.leadTime1 / 7
                                  ) + 1
                                  }` + ` weeks`
                                }
                                Shipped={`Shipped ${calculateDeliveryTime(
                                  props?.commanValues?.leadTime1,
                                  Date.now()
                                )}`}
                                updateCommanValueOnChange={
                                  props.updateCommanValueOnChange
                                }
                                value={0}
                                id="1"
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {props?.commanValues.price1 > 0 ? (
                            <div
                              className={
                                props.commanValues.selectedShipMethod == 1
                                  ? "box-pr pr-checked"
                                  : "box-pr"
                              }
                            >
                              <ShowPriceBox
                                price={
                                  props?.commanValues.price1 &&
                                    props?.commanValues.price1 > 0
                                    ? Number(props.commanValues.price1).toFixed(
                                      2
                                    )
                                    : 0
                                }
                                business={
                                  `${Math.floor(
                                    props?.commanValues?.leadTime2 / 7
                                  )}-${Math.floor(
                                    props?.commanValues?.leadTime2 / 7
                                  ) + 1
                                  }` + ` weeks`
                                }
                                Shipped={`Shipped ${calculateDeliveryTime(
                                  props?.commanValues?.leadTime2,
                                  Date.now()
                                )}`}
                                updateCommanValueOnChange={
                                  props.updateCommanValueOnChange
                                }
                                value={1}
                                id="2"
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          {props.stManualPrice > 0 ? (
                            <div
                              className={
                                props.commanValues.selectedShipMethod == 2
                                  ? "box-pr pr-checked"
                                  : "box-pr"
                              }
                            >
                              <ShowPriceBox
                                price={
                                  props &&
                                    props.stManualPrice &&
                                    props.stManualPrice > 0
                                    ? props.stManualPrice
                                    : 0
                                }
                                business={
                                  `${Math.floor(
                                    props?.commanValues?.leadTime3 / 7
                                  )}-${Math.floor(
                                    props?.commanValues?.leadTime3 / 7
                                  ) + 1
                                  }` + ` weeks`
                                }
                                Shipped={`Shipped ${calculateDeliveryTime(
                                  props?.commanValues?.leadTime3,
                                  Date.now()
                                )}`}
                                updateCommanValueOnChange={
                                  props.updateCommanValueOnChange
                                }
                                value={2}
                                id="3"
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    )}

                    {/* <div className="btm-buttons">
                      <Row className="btm-buttons-container">
                        <Col>
                          <span
                            to="#"
                            className="br-btn sq-btn"
                            onClick={(e) =>
                              props.onSubmit(e, { saveQuote: true })
                            }
                          >
                            <span>
                              <span className="ico">
                                <img src={saveico} />
                              </span>
                              {props.quoteId
                                ? repeatOrder
                                  ? "Proceed To Checkout"
                                  : "Save Changes"
                                : "Send To Review"}
                            </span>
                          </span>
                        </Col>
                      </Row>
                    </div> */}
                    <button
                      onClick={(e) => props.onSubmit(e, { saveQuote: true })}
                      className="border cursor-pointer bg-primaryColor text-white h-10 rounded w-full items-center flex justify-center gap-x-2"
                    >
                      <span>
                        {props.quoteId
                          ? repeatOrder
                            ? "Proceed To Checkout"
                            : "Save Changes"
                          : "Send To Review"}
                      </span>
                      {props.quoteId ? (
                        repeatOrder ? (
                          <ProccedToCheckOutIconComponent />
                        ) : (
                          <SaveChangeIconComponent />
                        )
                      ) : (
                        <SendIconComponent />
                      )}
                    </button>
                  </div>
                </div>
              </Col>
            </>
          ) : (
            ""
          )}

          {/* For manual process below */}
          {props.selectedProcess >= 3 ? (
            <Col md={5}>
              <div>
                <div className="price-box">
                  {repeatOrder ? (
                    <>
                      <div className="price-tabs">
                        {props?.commanValues.price > 0 ? (
                          <div
                            className={
                              props.commanValues.selectedShipMethod == 0
                                ? "box-pr pr-checked"
                                : "box-pr"
                            }
                          >
                            <ShowPriceBox
                              price={
                                props?.commanValues.price &&
                                  props?.commanValues.price > 0
                                  ? Number(props.commanValues.price).toFixed(2)
                                  : 0
                              }
                              business={
                                `${Math.floor(
                                  props?.commanValues?.leadTime1 / 7
                                )}-${Math.floor(
                                  props?.commanValues?.leadTime1 / 7
                                ) + 1
                                }` + ` weeks`
                              }
                              Shipped={`Shipped ${calculateDeliveryTime(
                                props?.commanValues?.leadTime1,
                                Date.now()
                              )}`}
                              updateCommanValueOnChange={
                                props.updateCommanValueOnChange
                              }
                              value={0}
                              id="1"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {props?.commanValues.price1 > 0 ? (
                          <div
                            className={
                              props.commanValues.selectedShipMethod == 1
                                ? "box-pr pr-checked"
                                : "box-pr"
                            }
                          >
                            <ShowPriceBox
                              price={
                                props?.commanValues.price1 &&
                                  props?.commanValues.price1 > 0
                                  ? Number(props.commanValues.price1).toFixed(2)
                                  : 0
                              }
                              business={
                                `${Math.floor(
                                  props?.commanValues?.leadTime2 / 7
                                )}-${Math.floor(
                                  props?.commanValues?.leadTime2 / 7
                                ) + 1
                                }` + ` weeks`
                              }
                              Shipped={`Shipped ${calculateDeliveryTime(
                                props?.commanValues?.leadTime2,
                                Date.now()
                              )}`}
                              updateCommanValueOnChange={
                                props.updateCommanValueOnChange
                              }
                              value={1}
                              id="2"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {props.sheetAndInjectionManualPrice > 0 ? (
                          <div
                            className={
                              props.commanValues.selectedShipMethod == 2
                                ? "box-pr pr-checked"
                                : "box-pr"
                            }
                          >
                            <ShowPriceBox
                              price={
                                props &&
                                  props.sheetAndInjectionManualPrice &&
                                  props.sheetAndInjectionManualPrice > 0
                                  ? props.sheetAndInjectionManualPrice
                                  : 0
                              }
                              business={
                                `${Math.floor(
                                  props?.commanValues?.leadTime3 / 7
                                )}-${Math.floor(
                                  props?.commanValues?.leadTime3 / 7
                                ) + 1
                                }` + ` weeks`
                              }
                              Shipped={`Shipped ${calculateDeliveryTime(
                                props?.commanValues?.leadTime3,
                                Date.now()
                              )}`}
                              updateCommanValueOnChange={
                                props.updateCommanValueOnChange
                              }
                              value={2}
                              id="3"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="price-tabs justify-content-md-center">
                        <Col md={12}>
                          <Card border="light" style={{ width: "100%" }}>
                            <Card.Body>
                              <Card.Title>
                                Please upload the drawing files and other
                                details as applicable. Our Applications
                                Engineering team will be in touch with you
                                shortly, once we receive the request.
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                      </div>
                    </>
                  )}

                  <div className="btm-buttons">
                    {props.values.length ? (
                      <>
                        <Row className="btm-buttons-container">
                          {/* Code added to show request Quotation in Sheet metal */}
                          <Col>
                            <button
                              onClick={(e) =>
                                props.onSubmit(e, { saveQuote: true })
                              }
                              className="border cursor-pointer bg-primaryColor text-white h-10 rounded w-full items-center flex justify-center gap-x-2"
                            >
                              <span>
                                {props.quoteId
                                  ? repeatOrder
                                    ? "Proceed To Checkout"
                                    : "Save Changes"
                                  : "Send To Review"}
                              </span>
                              {props.quoteId ? (
                                repeatOrder ? (
                                  <ProccedToCheckOutIconComponent />
                                ) : (
                                  <SaveChangeIconComponent />
                                )
                              ) : (
                                <SendIconComponent />
                              )}
                            </button>
                          </Col>
                          {props.quoteId ? (
                            <>
                              <Col>
                                <span
                                  to=""
                                  className="br-btn sq-btn"
                                  onClick={handleShareQuoteModalOpen}
                                >
                                  <span>
                                    <span className="ico">
                                      <img src={shareico} />
                                    </span>
                                    Share
                                  </span>
                                </span>
                              </Col>
                            </>
                          ) : null}
                        </Row>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={handleAddItem}
                          className="br-btn sq-btn"
                        >
                          Add Item
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}

          {/* For 3D Printing below */}
          {props.values &&
            props.values.length > 0 &&
            props.selectedProcess === 2 &&
            !props.isInvalidFiles &&
            !props.commanValues.hidePrice ? (
            <Col md={5}>
              <div>
                <div className="price-tabs" style={{ marginBottom: "20px" }}>
                  {props?.commanValues.price > 0 ? (
                    <div
                      className={
                        props.commanValues.selectedShipMethod == 0
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                    >
                      <ShowPriceBox
                        price={
                          props?.commanValues.price &&
                            props?.commanValues.price > 0
                            ? Number(props.commanValues.price).toFixed(2)
                            : 0
                        }
                        business={
                          `${Math.floor(props?.commanValues?.leadTime1 / 7)}-${Math.floor(props?.commanValues?.leadTime1 / 7) + 1
                          }` + ` weeks`
                        }
                        Shipped={`Shipped ${calculateDeliveryTime(
                          props?.commanValues?.leadTime1,
                          Date.now()
                        )}`}
                        updateCommanValueOnChange={
                          props.updateCommanValueOnChange
                        }
                        value={0}
                        id="1"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {props?.commanValues.price1 > 0 ? (
                    <div
                      className={
                        props.commanValues.selectedShipMethod == 1
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                    >
                      <ShowPriceBox
                        price={
                          props?.commanValues.price1 &&
                            props?.commanValues.price1 > 0
                            ? Number(props.commanValues.price1).toFixed(2)
                            : 0
                        }
                        business={
                          `${Math.floor(props?.commanValues?.leadTime2 / 7)}-${Math.floor(props?.commanValues?.leadTime2 / 7) + 1
                          }` + ` weeks`
                        }
                        Shipped={`Shipped ${calculateDeliveryTime(
                          props?.commanValues?.leadTime2,
                          Date.now()
                        )}`}
                        updateCommanValueOnChange={
                          props.updateCommanValueOnChange
                        }
                        value={1}
                        id="2"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {props && props.stManualPrice && props.stManualPrice > 0 ? (
                    <div
                      className={
                        props.commanValues.selectedShipMethod == 2
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                    >
                      <ShowPriceBox
                        price={
                          props &&
                            props.stManualPrice &&
                            props.stManualPrice > 0
                            ? Number(props.stManualPrice).toFixed(2)
                            : 0
                        }
                        business={
                          `${Math.floor(props?.commanValues?.leadTime3 / 7)}-${Math.floor(props?.commanValues?.leadTime3 / 7) + 1
                          }` + ` weeks`
                        }
                        Shipped={`Shipped ${calculateDeliveryTime(
                          props?.commanValues?.leadTime3,
                          Date.now()
                        )}`}
                        updateCommanValueOnChange={
                          props.updateCommanValueOnChange
                        }
                        value={2}
                        id="3"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="grid grid-cols-12 gap-x-1 gap-y-2">
                  {((props.commanValues.price && (props.commanValues.leadTime1 != null || props.commanValues.leadTime1 != undefined)) || (props.commanValues.price1 && (props.commanValues.leadTime2 != null || props.commanValues.leadTime2 != undefined))) ?

                    <button
                      onClick={(e) => props.onSubmit(e, { ptc: true })}
                      className={`border  ${selectedQuote?.isTargetCostActivated ? "col-span-6" : "col-span-12"} cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2`}
                    >
                      <span>
                        {"Proceed To Checkout"}
                      </span>
                      <ProccedToCheckOutIconComponent />
                    </button> : null
                  }
                  {(selectedQuote?.isTargetCostActivated && !selectedQuote?.userTargetCost && !selectedQuote?.myorders) ?

                    <button
                      onClick={(e) => handleDraftQuoteFunctionality({
                        functionalityName: 'stc'
                      })}
                      className="border col-span-6 cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2"
                    >
                      <span>
                        Submit Target Cost
                      </span>
                      <DocIcon additionalClasses={'h-5'} />
                    </button> : selectedQuote?.isTargetCostActivated && selectedQuote?.userTargetCost && !selectedQuote?.myorders ? <button
                      onClick={(e) => handleDraftQuoteFunctionality({
                        functionalityName: 'stc'
                      })}
                      className="border col-span-6 cursor-pointer bg-slate-500 text-white h-10  w-full items-center flex justify-center gap-x-2"
                    >
                      <span>
                        Target Cost Submitted

                      </span>
                      <DocIcon additionalClasses={'h-5'} />
                    </button> : null
                  }

                  {((props.commanValues.price && (props.commanValues.leadTime1 != null || props.commanValues.leadTime1 != undefined)) || (props.commanValues.price1 && (props.commanValues.leadTime2 != null || props.commanValues.leadTime2 != undefined))) ?

                    <button
                      onClick={(e) => handleDraftQuoteFunctionality({
                        functionalityName: 'ftp'
                      })}
                      className="border col-span-6 cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2"
                    >
                      <span>
                        Forward To Purchaser
                      </span>
                      <ForwardToPurchaseIcon additionalClasses={'mb-1'} />
                    </button> : null
                  }
                  {

                    <button
                      onClick={(e) => handleDraftQuoteFunctionality({
                        functionalityName: 'chat'
                      })}
                      className="border cursor-pointer col-span-3 bg-primaryColor text-white   w-full items-center flex justify-center gap-x-2"
                    >
                      <span>
                        Chat
                      </span>
                      <MessageIcon />
                    </button>
                  }
                  <div className="relative col-span-3">
                    <button
                      onClick={toggleDropdown}
                      className="w-full  h-10 px-2 flex items-center bg-slate-200 hover:bg-slate-300 text-black justify-center border rounded-none"
                    >
                      <span className="text-[12px] whitespace-nowrap ">More Options</span>
                      <ArrowIcon additionalClasses="h-4 ml-2" />
                    </button>

                    {isOpen ? (
                      <div className="absolute z-20 left-0 mt-2 w-32 bg-slate-100 text-black border border-white rounded shadow-lg">
                        {/* Conditional Download Option */}
                        {((props.commanValues.price && props.commanValues.leadTime1) ||
                          (props.commanValues.price1 && props.commanValues.leadTime2)) ? (
                          <button
                            onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'dq' })}
                            className="w-full flex justify-center items-center gap-x-2 px-4 py-2 text-sm hover:bg-slate-200"
                          >
                            <span className="text-[12px]">Download</span>
                            <DownloadIcon additionalClass={'!h-4'} />
                          </button>
                        ) : null}
                        {/* Team Option */}
                        <button
                          onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'teams' })}
                          className="w-full flex justify-center items-center gap-x-2 px-4 py-2 text-sm hover:bg-slate-200"
                        >
                          <span className="text-[12px]">Team</span>
                          <UsersIcon additionalClasses={'h-4'} />
                        </button>
                        {((props.commanValues.price && props.commanValues.leadTime1) ||
                          (props.commanValues.price1 && props.commanValues.leadTime2)) ? (
                          <button
                            onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'share' })}
                            className="w-full flex justify-center items-center gap-x-2 px-4 py-2 text-sm hover:bg-slate-200"
                          >
                            <span className="text-[12px]">Share</span>
                            <ShareIcon additionalClass={'!h-4'} />
                          </button>
                        ) : null}
                        {
                          _id && !funcName ? selectedQuote.isCancelled ?
                            <button
                              onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'move-to-progress' })}
                              className="w-full  h-10 px-2 flex items-center bg-slate-200 hover:bg-slate-300 text-black justify-center border rounded-none"
                            >
                              <span className="text-[12px] whitespace-nowrap ">Move To Progress</span>
                              <ArrowIcon additionalClasses="h-4 ml-2" />
                            </button> :
                            <button
                              onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'archive' })}
                              className="w-full  h-10 px-2 flex items-center bg-slate-200 hover:bg-slate-300 text-black justify-center border rounded-none"
                            >
                              <span className="text-[12px] whitespace-nowrap ">Archive</span>
                              <ArrowIcon additionalClasses="h-4 ml-2" />
                            </button> :
                            null

                        }
                      </div>

                    ) : null}
                  </div>


                </div>

              </div>
            </Col>
          ) : (
            ""
          )}
          {/* For 3D printing ends here */}

          {/* For 3D printing with not supported file formats */}
          {props.selectedProcess == 2 &&
            props.values.length &&
            (props.isInvalidFiles || isInvalidFilesInEditQuote) &&
            props.stManualPrice == 0 ? (
            <Col md={5}>
              <div>
                <div className="price-box">
                  <div className="total-price">
                    <span>Important !</span>
                  </div>
                </div>

                <div className="price-box">
                  <Row>
                    <Col md={12}>
                      <Card border="light" style={{ width: "100%" }}>
                        <Card.Body>
                          <Card.Title>{props.threeDError}</Card.Title>
                        </Card.Body>
                      </Card>
                      <h5></h5>
                    </Col>
                  </Row>
                  <div className="btm-buttons">
                    <Row className="btm-buttons-container">
                      <div className="-mt-5">
                        <button
                          onClick={(e) =>
                            props.onSubmit(e, { saveQuote: true })
                          }
                          className="border !mb-3 z-10 cursor-pointer bg-primaryColor text-white h-10  w-full items-center flex justify-center gap-x-2"
                        >
                          <span>
                            {props.quoteId
                              ? repeatOrder
                                ? "Proceed To Checkout "
                                : "Save Changes"
                              : "Send To Review  "}
                          </span>
                          {props.quoteId ? (
                            repeatOrder ? (
                              <ProccedToCheckOutIconComponent />
                            ) : (
                              <SaveChangeIconComponent />
                            )
                          ) : (
                            <SendIconComponent />
                          )}
                        </button>
                        <div className="grid grid-cols-12 gap-x-2">
                          {

                            <button
                              onClick={(e) => handleDraftQuoteFunctionality({
                                functionalityName: 'chat'
                              })}
                              className="border  cursor-pointer col-span-6 h-10 bg-primaryColor text-white   w-full items-center flex justify-center gap-x-2"
                            >
                              <span>
                                Chat
                              </span>
                              <MessageIcon />
                            </button>
                          }
                          <div className="relative col-span-6 border">
                            <button
                              onClick={toggleDropdown}
                              className=" h-10 px-2 w-full flex items-center bg-slate-200 hover:bg-slate-300 text-black justify-center border rounded-none"
                            >
                              <span className="text-[12px] whitespace-nowrap ">More Options</span>
                              <ArrowIcon additionalClasses="h-4 ml-2" />
                            </button>

                            {isOpen ? (
                              <div className="absolute left-0 mt-2 w-32 bg-slate-100 text-black border border-white rounded shadow-lg">

                                {(((props.commanValues.price && props.commanValues.leadTime1) || (props.commanValues.price1 && props.commanValues.leadTime2))) ? (
                                  <button
                                    onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'dq' })}
                                    className="w-full flex justify-center items-center gap-x-2 px-4 py-2 text-sm hover:bg-slate-200"
                                  >
                                    <span className="text-[12px]">Download</span>
                                    <DownloadIcon additionalClass={'!h-4'} />
                                  </button>
                                ) : null}
                                {/* Team Option */}
                                <button
                                  onClick={() => handleDraftQuoteFunctionality({ functionalityName: 'teams' })}
                                  className="w-full flex justify-center items-center gap-x-2 px-4 py-2 text-sm hover:bg-slate-200"
                                >
                                  <span className="text-[12px]">Team</span>
                                  <UsersIcon additionalClasses={'h-4'} />
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>


                      {props.quoteId &&
                        props.askFO == false &&
                        !props.isInvalidFiles ? (
                        <div md={4}></div>
                      ) : props.quoteId && props.askFO == true ? (
                        <div style={{ marginTop: "5px" }}>
                          <button disabled={true} className="br-btn oqr-btn">
                            <span>Manual Quotation Requested</span>
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}

          {/* For 3D printing with not supported file formats ends here */}

          {/* For 3D printing with not supported file formats and price updated by admin starts here */}
          {props.selectedProcess == 2 &&
            props.stManualPrice > 0 &&
            props.isInvalidFiles == true ? (
            <Col md={5}>
              <div>
                <div className="price-box">
                  <div className="price-tabs">
                    {props?.commanValues.price > 0 ? (
                      <div
                        className={
                          props.commanValues.selectedShipMethod == 0
                            ? "box-pr pr-checked"
                            : "box-pr"
                        }
                      >
                        <ShowPriceBox
                          price={
                            props?.commanValues.price &&
                              props?.commanValues.price > 0
                              ? Number(props.commanValues.price).toFixed(2)
                              : 0
                          }
                          business={
                            `${Math.floor(
                              props?.commanValues?.leadTime1 / 7
                            )}-${Math.floor(props?.commanValues?.leadTime1 / 7) + 1
                            }` + ` weeks`
                          }
                          Shipped={`Shipped ${calculateDeliveryTime(
                            props?.commanValues?.leadTime1,
                            Date.now()
                          )}`}
                          updateCommanValueOnChange={
                            props.updateCommanValueOnChange
                          }
                          value={0}
                          id="1"
                        />
                      </div>
                    ) : (
                      <></>
                    )}

                    {props?.commanValues.price1 > 0 ? (
                      <div
                        className={
                          props.commanValues.selectedShipMethod == 1
                            ? "box-pr pr-checked"
                            : "box-pr"
                        }
                      >
                        <ShowPriceBox
                          price={
                            props?.commanValues.price1 &&
                              props?.commanValues.price1 > 0
                              ? Number(props.commanValues.price1).toFixed(2)
                              : 0
                          }
                          business={
                            `${Math.floor(
                              props?.commanValues?.leadTime2 / 7
                            )}-${Math.floor(props?.commanValues?.leadTime2 / 7) + 1
                            }` + ` weeks`
                          }
                          Shipped={`Shipped ${calculateDeliveryTime(
                            props?.commanValues?.leadTime2,
                            Date.now()
                          )}`}
                          updateCommanValueOnChange={
                            props.updateCommanValueOnChange
                          }
                          value={1}
                          id="2"
                        />
                      </div>
                    ) : (
                      <></>
                    )}

                    {props.stManualPrice > 0 ? (
                      <div
                        className={
                          props.commanValues.selectedShipMethod == 2
                            ? "box-pr pr-checked"
                            : "box-pr"
                        }
                      >
                        <ShowPriceBox
                          price={
                            props &&
                              props.stManualPrice &&
                              props.stManualPrice > 0
                              ? props.stManualPrice
                              : 0
                          }
                          business={
                            `${Math.floor(
                              props?.commanValues?.leadTime3 / 7
                            )}-${Math.floor(props?.commanValues?.leadTime3 / 7) + 1
                            }` + ` weeks`
                          }
                          Shipped={`Shipped ${calculateDeliveryTime(
                            props?.commanValues?.leadTime3,
                            Date.now()
                          )}`}
                          updateCommanValueOnChange={
                            props.updateCommanValueOnChange
                          }
                          value={2}
                          id="3"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="btm-buttons">
                    <Row className="btm-buttons-container">
                      <Col>
                        <Link
                          to=""
                          className="br-btn sq-btn"
                          onClick={handleShareQuoteModalOpen}
                        >
                          <span>
                            <span className="ico">
                              <img src={shareico} />
                            </span>
                            Share
                          </span>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          ) : (
            ""
          )}
          {/* For 3D printing with not supported file formats and price updated by admin ends here */}
          {props.values && props.values.length === 0 && <Col md={5}></Col>}
        </Row>

        {props.values.length > 0 ? (
          <div className="my-2">
            <h5>Documentation required : </h5>
            <div className=" p-2 flex test justify-start gap-x-5 gap-y-3 flex-col md:flex-row items-start md:items-center">
              <div class="flex items-center">
                <input
                  type="checkbox"
                  id="sign-check-cc"
                  name="checkbox"
                  value={props.commanValues.isConformanceCertificateRequested}
                  checked={props.commanValues.isConformanceCertificateRequested}
                  class="w-4 h-4 text-blue-600 text-sm bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  onChange={(e) => {
                    if (repeatOrder) {
                      let userConfirmation = confirm("Are you sure you want to change the selected files for the previous quotation? This action will generate a new version of the quotation, which will require admin review before you can proceed with creating an order.");
                      if (userConfirmation) {
                        navigate(`/get-instant-quote/${_id}?isRepeatOrderWithUpdate=true`)

                      }
                      return;
                    }

                    props.setIsQuoteChanged(true)
                    if (props.commanValues.isConformanceCertificateRequested) {
                      props.setCommanValues({
                        ...props.commanValues,
                        isConformanceCertificateRequested: !props.commanValues.isConformanceCertificateRequested
                      });
                    } else {
                      props.setCommanValues({
                        ...props.commanValues,
                        isConformanceCertificateRequested: !props.commanValues.isConformanceCertificateRequested
                      });
                    }
                  }}
                />
                <label
                  for="sign-check-cc"
                  class="ml-3 text-[15px] font-medium text-gray-900 "
                >
                  Certificate of conformance
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="sign-check-dr"
                  name="checkbox"
                  value={props.commanValues.isDimensionalReportRequested}
                  checked={props.commanValues.isDimensionalReportRequested}
                  onChange={(e) => {
                    if (repeatOrder) {
                      let userConfirmation = confirm("Are you sure you want to change the selected files for the previous quotation? This action will generate a new version of the quotation, which will require admin review before you can proceed with creating an order.");
                      if (userConfirmation) {
                        navigate(`/get-instant-quote/${_id}?isRepeatOrderWithUpdate=true`)

                      }
                      return;
                    }
                    props.setIsQuoteChanged(true)
                    if (props.commanValues.isDimensionalReportRequested) {
                      props.setCommanValues({
                        ...props.commanValues,
                        isDimensionalReportRequested: !props.commanValues.isDimensionalReportRequested
                      });
                    } else {
                      props.setCommanValues({
                        ...props.commanValues,
                        isDimensionalReportRequested: !props.commanValues.isDimensionalReportRequested
                      });
                    }

                  }}
                />
                <label htmlFor="sign-check-dr" className="text-[15px] text-sm font-medium ">
                  Dimensional Inspection Report
                </label>
              </div>
              {props.selectedProcess == 1 && <div className="flex justify-around items-center gap-x-2 ">
                <input
                  type="checkbox"
                  id="sign-check-qr"
                  name="checkbox"
                  value={props.commanValues.isQrTrackingRequested}
                  checked={props.commanValues.isQrTrackingRequested}
                  disabled={!props.isValidValuesForQR}
                  onChange={(e) => {
                    if (repeatOrder) {
                      let userConfirmation = confirm("Are you sure you want to change the selected files for the previous quotation? This action will generate a new version of the quotation, which will require admin review before you can proceed with creating an order.");
                      if (userConfirmation) {
                        navigate(`/get-instant-quote/${_id}?isRepeatOrderWithUpdate=true`)
                      }
                      return;
                    }
                    props.setIsQuoteChanged(true);
                    if (props.commanValues.hidePrice || props.isInvalidFiles || props.commanValues.price == 0) {
                      props.setCommanValues({ ...props.commanValues, isQrTrackingRequested: !props.commanValues.isQrTrackingRequested });
                    } else {
                      let totalQuantityOfItems = 0;
                      for (let i = 0; i < props.values.length; i++) {
                        totalQuantityOfItems += props.values[i].Qty;
                      }

                      if (props.commanValues.price > 0 && !props.commanValues.hidePrice) {
                        if (props.commanValues.isQrTrackingRequested) {
                          props.setCommanValues({
                            ...props.commanValues,
                            isQrTrackingRequested: !props.commanValues.isQrTrackingRequested,
                            price: Number(props.commanValues.price) - (5 * totalQuantityOfItems),
                            price1: props.selectedProcess == 1 ? (Number(props.commanValues.price1) - (5 * totalQuantityOfItems)) : 0,
                            additionalCost1: 0,
                            additionalCost2: 0,
                          });
                        } else {
                          console.log('am ehere values of is qr treacking requested 444', !props.commanValues.isQrTrackingRequested)
                          props.setCommanValues({
                            ...props.commanValues,
                            isQrTrackingRequested: !props.commanValues.isQrTrackingRequested,
                            price: Number(props.commanValues.price) + (5 * totalQuantityOfItems),
                            price1: props.selectedProcess == 1 ? (Number(props.commanValues.price1) - (5 * totalQuantityOfItems)) : 0,
                            additionalCost1: 5 * totalQuantityOfItems,
                            additionalCost2: 5 * totalQuantityOfItems,
                          });
                        }
                      }
                    }
                  }}
                />
                <label htmlFor="sign-check-qr" className="text-sm font-medium">
                  QR Tracking{" "}
                </label>
                <QRTrackingHoverInfo />
              </div>}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <Modal
        size="sm"
        dialogClassName="qoute-version-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={shareQuoteModalShow}
        onHide={handleShareQuoteClose}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "15px" }}
          >
            Share Quotation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="share-quote-div">
            <h6>
              Emails entered below will receive an email with Quotation details
              in their mailbox.
            </h6>
            <div className="add-email-div">
              <input
                placeholder="Enter the email to share the quotation details"
                value={inputEmail}
                onChange={(e) => setInputEmail(e.target.value)}
              />
              <button onClick={handleAddButtonToReceiverList}>
                + Add Email
              </button>
            </div>
            <div className="share-content-div">
              <h6>Receiver's List</h6>
              <div className="email-list-share-div">
                {emailToShareQuote.size > 0
                  ? Array.from(emailToShareQuote).map((item, index) => {
                    return (
                      <span className="email-item" key={index}>
                        {" "}
                        {item}{" "}
                        <img
                          src={closeImg}
                          className="close-img"
                          onClick={(e) => handleDeleteShareEmail(item)}
                        />
                      </span>
                    );
                  })
                  : null}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="share-quote-div-footer">
            <div className="share-button" onClick={handleQuotationShare}>
              Share
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default SelectFileSection;
