import React, { useContext, useEffect, useRef, useState } from "react";
import {
  updateQuoteAdmin,
  downloadPdf,
  sendQuotationToCustomerEmail,
  fetchQuoteByRefId,
  getallAddressAdmin,
  fetchQuoteByIdForQuoteVersion,
  newCheckout,
  updateQuoteShipMethod,
  handleQuoteCancel,
  moveQuoteToProgressStatus,
  updateQuoteAdminComment,
  updateQuotationNotesAndTnc,
  handleMarkAsSend,
  handleCommonAddAddressForUser,
  handleCommonDeleteAddressForUser,
  handleCommonEditAddressForUser,
} from "../../utils/actions/allactions";
import {
  validateAdmin,
  numberInputOnWheelPreventChange,
  currencyFormatter,
  extractURLAndEncodedFilename,
} from "../../utils/helper";
import { fetchRFQForQuotation } from "../../utils/actions/supplierActions";
import {
  createRFQ,
  cancelRFQForQuotation,
} from "../../utils/actions/supplierActions";
import { useParams, useNavigate, Link } from "react-router-dom";
import Sidebar from "../Sidebar";
import { toast } from "react-toastify";
import _ from "lodash";
import { config } from "../../environment/development";
import fileSaver from "file-saver";
import downloadImg from "../../images/download.svg";
import tickGreenImage from "../../images/tick-green.svg";
import editImage from "../../images/edit.svg";
import deleteImageEnabled from "../../images/reddelete.svg";
import redCancelImage from "../../images/close_red.svg";
import { processType, quoteVersionDescriptions } from "../../utils/constant";
import { Modal, Button, Row, Col, Form, FormControl } from "react-bootstrap";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import { ChatRefContext } from "../../context/chatRef.context";
import moment from "moment";
import whiteLoader from "../../images/white-loader.gif";
import useDocumentTitle from "../../utils/useDocumentTitle";
import AddressModal from "../../components/CommanAddressModal/AddressModal";
import CaptchaModal from "../../components/CaptchaModal/CaptchaModal";
import TeamCollaboration from "../../components/TeamCollaboration/TeamCollaboration";
import { activateTargetCostForUser, getSalesOrdersForAllQuotation } from "../../utils/actions/adminActions";
import { handleProjectNameChange } from "../../utils/actions/commanActions";
import { Loading } from "../../components/Loading";
import { DownloadIcon, EditIcon, LucidLoader } from "../../components/Icons";
import { CrossIcon } from "../../components/GlobalProjectNameComponent";
import { Loader } from "@chatscope/chat-ui-kit-react";

const AdminQuote = () => {
  let { _id } = useParams();
  useDocumentTitle("Quote Details");
  let navigate = useNavigate();
  let token = localStorage.getItem("Token");
  const [leadTime, setLeadTime] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState();
  const [allVersionForRefId, setAllVersionForRefId] = useState([]);
  const [selectedShipMethod, setSelectedShipMethod] = useState("");
  let [showModal, setShowModal] = useState(false);
  const [showFollowerModal, setShowFollowerModal] = useState(false);
  const handleClose = () => {
    setVersionDescriptionValue("");
    setShowModal(false);
  };

  const [open, setOpen] = useState(false);
  const { setSelectedChatRefId, selectedChatRefId, setChatType } =
    useContext(ChatRefContext);
  const [poDocument, setPoDocument] = useState(null);
  const [poNumber, setPoNumber] = useState("");
  const [showSoModal, setShowSoModal] = useState(false);
  const [linesTotal, setLinesTotal] = useState([]);
  const [versionDescriptionValue, setVersionDescriptionValue] = useState("");
  const [rFQForQuotation, setRFQForQuotation] = useState(null);
  const [salesOrder, setSalesOrder] = useState({});
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isChanged, setIsChanged] = useState({
    quotationNotes: false,
    partInfo: false,
    additionalCost: false,
    leadtime: false,
    shippingCharge: false,
  });
  const [isQuotationDownloading, setQuotationDownloading] = useState(false);

  const [showDQModal, setShowDQModal] = useState(false);
  const [functionalityForDQ, setFunctionalityForDQ] = useState("");
  const [quotationNotes, setQuotationNotes] = useState("");
  const [allOrdersForProject, setAllOrdersForProject] = useState([]);
  const [quotationTnc, setQuotationTnc] = useState(
    `Terms and Conditions :


GENERAL TERMS


PRICE

1. Unless otherwise agreed all prices are quoted in dollar.
2. The prices quoted are valid for a period of thirty (30) days after which 8xParts Limited shall have the right to adjust quoted prices upon written notice to Buyer.
3. Quoted prices do not include any applicable local, state, federal or foreign taxes and any such taxes
4. Quote is subject to change within reason for any unforeseen reason, such as material global price change or currency fluctuation.


DELIVERY

1. Delivery timelines indicated in the Contract are subject amongst other factors on receipt of information or inputs from You, required by 8xParts Limited for successful execution of the Order.


INTELLECTUAL PROPERTY RIGHTS

Any patent, design, copyright, trademark or other intellectual property right (“IPR”) related to the 3D model or design or drawing is provided by You is and shall remain Your sole and exclusive property. 8xParts Limited will not acquire any ownership right or ownership title in such IPR. 8xParts Limited is hereby granted with a non-exclusive, non-transferable, limited license to use the IPR subject to the following: (i) The IPR may be used only in conjunction with manufacture of the Product(s) under the Contract; (ii) the IPR shall be kept strictly confidential; (iii) the IPR shall not be copied, reverse engineered, or modified; and (iv) 8xParts Limited’s right to use the IPR shall terminate immediately when the specified equipment is no longer used by Purchaser or when otherwise terminated.


WARRANTY

1. 8xParts Limited warrants that the Products shall be free of defects in title, material and workmanship for a period of Thirty (30) days from the date of delivery (“Warranty Period”).
2. 8xParts Limited does not warrant Products:
  i. Against normal wear and tear;
  ii. Used in a manner contrary to 8xParts Limited’s instructions;Documentation required :
  iii. Comprising of material provided by or a design specified by You and the defect relates to either the material or the design, as applicable; or
  iv. Repaired, maintained, or modified against or otherwise not in compliance with 8xParts Limited ’s recommendations or instructions.

CHANGE ORDERS

You may propose changes in the delivery schedule or specification of Products order under the Contract (“Change Order”) by contacting the 8xParts Limited sales team. Some changes requested by You may result in a variation in the delivery schedule and/or increase in the prices agreed in the Order Confirmation. Such changes will be communicated to You in writing before 8xParts Limited accepts the Change Order.`
  );

  // New state for address selected for quotation download
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [addressValues, setAddressValues] = useState({});
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    companyName: "",
    vatNumber: "",
    addresses: "",
    finalTotalPrice: "",
    addressline1: "",
    addressline2: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    subTotal: "",
  });
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const [isEditingProjectName, setIsEditingProjectName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  console.log("Selected Quote :", selectedQuote);
  const [isCostUpdatedbyAdminWhenTargetCostIsAvailable, setIsCostUpdatedByAdminWhenTargetCostIsAvailable] = useState(false);

  const handleTargetCostAsk = async () => {
    if (!selectedQuote) {
      toast.error("Quotation Not Found");
      return;
    }
    try {

      const response = await activateTargetCostForUser(token, selectedQuote?._id);

      if (!response || !response?.success) {
        throw new Error();
      };
      toast.success(`Email sent to user for Target cost`);
      fetchAllQoutesForRefId();

    } catch (error) {
      toast.error("Unable To Ask Target Cost At The Moment");
    }

  }

  const handleEditClick = () => {
    let project = { ...selectedQuote };
    const firstUnderscoreIndex = project.projectName.indexOf("_");
    const editableText = project.projectName.substring(
      firstUnderscoreIndex + 1
    );

    setSelectedQuote({
      ...selectedQuote,
      previousProjectName: selectedQuote.projectName,
      editableProjectName: editableText,
    });

    setIsEditingProjectName(true);
  };

  const handleEditableChange = (e) => {
    const { value } = e.target;
    setSelectedQuote({ ...selectedQuote, editableProjectName: value });
  };

  const handleUpdateClick = async (index) => {
    let localSelectedQuote = { ...selectedQuote };
    if (localSelectedQuote.editableProjectName.trim() === "") {
      alert("Project name cannot be empty");
      return;
    }

    localSelectedQuote = {
      ...localSelectedQuote,
      projectName: `${localSelectedQuote.projectName.split("_")[0]
        }_${localSelectedQuote.editableProjectName.trim()}`,
    };

    setSelectedQuote({ ...localSelectedQuote });

    let data = {
      RefId: localSelectedQuote.RefId,
      projectName: localSelectedQuote.projectName,
    };

    const response = await handleProjectNameChange(token, data);
    if (response.status) {
      toast.success(response.message);
      // fetchQuotesForUser();
      setIsEditingProjectName(false);
    } else {
      toast.error(response.message);
    }
  };

  const handleCancelClick = () => {
    setIsEditingProjectName(false);
    setSelectedQuote({ ...selectedQuote, editableProjectName: "" });
  };

  const handleCloseCaptchaModal = () => setShowCaptchaModal(false);

  const handledAddAddress = async (addressData) => {
    let data = {
      userId: selectedQuote.userId._id,
      addressData,
    };
    const response = await handleCommonAddAddressForUser(token, data);
    if (response.status) {
      let localAllUserAddress = addressValues?.address
        ? [...addressValues.address]
        : [];
      localAllUserAddress.push(response.data);
      setAddressValues({ ...addressValues, address: localAllUserAddress });
      setShowDQModal(true);
    }
  };

  const handleAddressOnEdit = async (newFormData, index) => {
    let allAddresses = addressValues.address ? addressValues.address : [];
    let address = allAddresses[index];
    address = { ...address, ...newFormData };
    const response = await handleCommonEditAddressForUser(token, address);
    if (response.status) {
      allAddresses[index] = response.data;
      let addressValueData = {};
      if (addressValues.shippingAddress == response.data._id) {
        addressValueData = {
          addressline1: response.data.addressLineOne,
          addressline2: response.data.addressLineTwo,
          city: response.data.city,
          country: response.data.country,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          state: response.data.state,
          zipCode: response.data.postalCode,
        };
      }
      setAddressValues({
        ...addressValues,
        ...addressValueData,
        address: allAddresses,
      });
      setShowDQModal(true);
    }
  };

  const addNewHandler = () => {
    setShowAddressModal(true);
    setShowDQModal(false);
  };

  const editHandler = (index) => {
    setShowAddressModal(true);
    setIsEdit(true);
    setEditId(index);
    setShowDQModal(false);
  };

  const deleteData = async (i) => {
    if (i > -1) {
      const addressToDelete = addressValues.address[i];
      let localAllAddress = addressValues?.address
        ? [...addressValues.address]
        : [];
      const addressDeleteResponse = await handleCommonDeleteAddressForUser(
        token,
        addressToDelete._id
      );
      if (addressDeleteResponse.status) {
        localAllAddress.splice(i, 1);
        setAddressValues({ ...addressValues, address: localAllAddress });
      }
    }
  };

  const handleUpdateQuotationNotesAndTnc = async () => {
    try {
      let data = {
        quoteId: selectedQuote._id,
        quotationNotes,
        quotationTnc,
      };

      const response = await updateQuotationNotesAndTnc(token, data);
      if (response.status) {
        toast.success(response.message);
        setSelectedQuote({
          ...selectedQuote,
          quotationNotes: response?.data?.quotationNotes,
          quotationTnc: response?.data?.quotationTnc,
        });
        setIsChanged({ ...isChanged, quotationNotes: false });
      }
    } catch (err) {
      console.log(
        "Error in admin quote 137 on: " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleTeamModalShow = async () => {
    setShowFollowerModal(true);
  };

  const handlePoModalClose = () => {
    setShowSoModal(false);
  };

  const handlePoDocumentDelete = () => {
    setPoDocument(null);
  };

  const showDrawer = () => {
    setOpen(true);
    setSelectedChatRefId(selectedQuote.RefId);
    setChatType("customer");
  };

  const onClose = () => {
    setOpen(false);
    setChatType(null);
    setSelectedChatRefId(null);
  };

  useEffect(() => {
    validateAdmin(token, navigate);
    fetchAllQoutesForRefId();
    fetchSupplierData();
  }, []);

  useEffect(() => {
    let line1Total = 0,
      line2Total = 0,
      line3Total = 0;

    selectedQuote?.partsData?.forEach((item) => {
      line1Total += Number(item.Qty) * Number(item.price);
      line2Total += Number(item.Qty) * Number(item.price1);
      line3Total += Number(item.Qty) * Number(item.price2);
    });

    setLinesTotal([line1Total, line2Total, line3Total]);
    setQuotationNotes(selectedQuote?.quotationNotes || quotationNotes);
  }, [selectedQuote]);

  let getFileExtension = (filename) => {
    return filename?.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const fetchSupplierData = async () => {
    try {
      const fetchRFQResponse = await fetchRFQForQuotation(_id, token);
      if (fetchRFQResponse.data.status) {
        setRFQForQuotation(fetchRFQResponse?.data.data);
      }
    } catch (err) {
      console.log(
        "err in admin quote 283 on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  console.log("isLoaderActive", isLoaderActive);

  const fetchAllQoutesForRefId = async () => {
    try {
      setIsLoading(true);
      const selectedQuoteDetails = await fetchQuoteByIdForQuoteVersion(
        _id,
        token
      );
      const allVersions = await fetchQuoteByRefId(
        selectedQuoteDetails.data.data.RefId,
        token
      );
      setAllVersionForRefId(allVersions.data.data);
      setSelectedQuote(selectedQuoteDetails.data.data);

      let data = [];
      allVersions.data.data.forEach((item) => data.push(item._id));
      const response = await getSalesOrdersForAllQuotation(token, data);
      if (response.status) {
        setAllOrdersForProject(response.data);
        let filteredOrder = response.data.filter(
          (item) => item.quoteId._id == selectedQuoteDetails.data.data._id
        );
        if (filteredOrder.length) {
          setSalesOrder(filteredOrder[0]);
        }
      }

      let localLeadTime = [
        selectedQuoteDetails.data.data.leadTime1,
        selectedQuoteDetails.data.data.leadTime2,
        selectedQuoteDetails.data.data.leadTime3,
      ];
      setLeadTime(localLeadTime);

      let fetchedUserAddress = await getallAddressAdmin(token);

      let localUserAddresses = [];
      fetchedUserAddress.map((address) => {
        if (
          address?.userId?._id === selectedQuoteDetails?.data?.data?.userId?._id
        ) {
          localUserAddresses.push(address);
        }
      });
      if (localUserAddresses.length > 0) {
        setAddressValues({
          ...addressValues,
          address: [...localUserAddresses],
        });
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const validateData = () => {
    if (
      selectedQuote?.selectedProcess === 1 ||
      selectedQuote?.selectedProcess === 2
    ) {
      if (
        (selectedQuote?.stManualPrice === undefined ||
          selectedQuote?.stManualPrice === "" ||
          selectedQuote?.stManualPrice === 0) &&
        (selectedQuote?.price === undefined ||
          selectedQuote?.price === "" ||
          selectedQuote?.price === 0) &&
        (selectedQuote?.price1 === undefined ||
          selectedQuote?.price1 === "" ||
          selectedQuote?.price1 === 0)
      ) {
        return false;
      }

      if (
        (selectedQuote.price > 0 &&
          (leadTime[0] === 0 || !leadTime[0] || leadTime[0] === "")) ||
        (selectedQuote.price1 > 0 &&
          (leadTime[1] === 0 || !leadTime[1] || leadTime[1] === "")) ||
        (selectedQuote.stManualPrice > 0 &&
          (leadTime[2] === 0 || !leadTime[2] || leadTime[2] === ""))
      ) {
        return false;
      }

      if (
        (selectedQuote.price > 0 &&
          (linesTotal[0] === 0 || !linesTotal[0] || linesTotal[0] === "")) ||
        (selectedQuote.price1 > 0 &&
          (linesTotal[1] === 0 || !linesTotal[1] || linesTotal[1] === "")) ||
        (selectedQuote.stManualPrice > 0 &&
          (linesTotal[2] === 0 || !linesTotal[2] || linesTotal[2] === ""))
      ) {
        return false;
      }
    } else {
      if (
        (selectedQuote?.sheetAndInjectionManualPrice === undefined ||
          selectedQuote?.sheetAndInjectionManualPrice === "" ||
          selectedQuote?.sheetAndInjectionManualPrice === 0) &&
        (selectedQuote?.price === undefined ||
          selectedQuote?.price === "" ||
          selectedQuote?.price === 0) &&
        (selectedQuote?.price1 === undefined ||
          selectedQuote?.price1 === "" ||
          selectedQuote?.price1 === 0)
      ) {
        return false;
      }

      if (
        (selectedQuote.price > 0 &&
          (leadTime[0] === 0 || !leadTime[0] || leadTime[0] === "")) ||
        (selectedQuote.price1 > 0 &&
          (leadTime[1] === 0 || !leadTime[1] || leadTime[1] === "")) ||
        (selectedQuote.sheetAndInjectionManualPrice > 0 &&
          (leadTime[2] === 0 || !leadTime[2] || leadTime[2] === ""))
      ) {
        return false;
      }

      if (
        (selectedQuote.price > 0 &&
          (linesTotal[0] === 0 || !linesTotal[0] || linesTotal[0] === "")) ||
        (selectedQuote.price1 > 0 &&
          (linesTotal[1] === 0 || !linesTotal[1] || linesTotal[1] === "")) ||
        (selectedQuote.sheetAndInjectionManualPrice > 0 &&
          (linesTotal[2] === 0 || !linesTotal[2] || linesTotal[2] === ""))
      ) {
        return false;
      }
    }

    if (selectedQuote.price) {
      let isAllZero = true;
      for (let i = 0; i < selectedQuote.partsData.length; i++) {
        if (selectedQuote.partsData[i].price > 0) {
          isAllZero = false;
        }
      }
      if (isAllZero) {
        return false;
      }
    }

    if (selectedQuote.price1) {
      let isAllZero = true;
      for (let i = 0; i < selectedQuote.partsData.length; i++) {
        if (selectedQuote.partsData[i].price1 > 0) {
          isAllZero = false;
        }
      }
      if (isAllZero) {
        return false;
      }
    }

    if (selectedQuote.stManualPrice) {
      let isAllZero = true;
      for (let i = 0; i < selectedQuote.partsData.length; i++) {
        if (selectedQuote.partsData[i].price2 > 0) {
          isAllZero = false;
        }
      }
      if (isAllZero) {
        return false;
      }
    }

    if (selectedQuote.sheetAndInjectionManualPrice) {
      let isAllZero = true;
      for (let i = 0; i < selectedQuote.partsData.length; i++) {
        if (selectedQuote.partsData[i].price2 > 0) {
          isAllZero = false;
        }
      }
      if (isAllZero) {
        return false;
      }
    }

    return true;
  };

  const handleSendEmail = async () => {
    try {
      if (
        isChanged.partInfo ||
        isChanged.additionalCost ||
        isChanged.leadtime ||
        isChanged.shippingCharge
      ) {
        toast.error(
          "There are some unsaved changes. Please update order before sending the quotation to customer email."
        );
        return;
      }
      let selectedShipMethodsToSendEmail = [];
      if (selectedQuote.price) {
        selectedShipMethodsToSendEmail.push(0);
      }
      if (selectedQuote.price1) {
        selectedShipMethodsToSendEmail.push(1);
      }

      if (
        selectedQuote.stManualPrice ||
        selectedQuote.sheetAndInjectionManualPrice
      ) {
        selectedShipMethodsToSendEmail.push(2);
      }

      if (selectedShipMethodsToSendEmail.length == 0) {
        toast.error("No Price is updated,Please update atleast one price.");
        setIsLoaderActive(false);
        return;
      }

      let adminResponse = false;
      if (selectedQuote.isEmailSent) {
        adminResponse = confirm(
          "Quotation email is already sent to customer for this quote. Are you sure you want to send the email again?"
        );
      } else {
        adminResponse = confirm(
          "Are you sure you want to send the quotation details to user email?"
        );
      }
      if (!adminResponse) return;
      setIsLoaderActive(true);
      setShowDQModal(false);

      let fileInfoForQuote = [];
      for (let i = 0; i < selectedShipMethodsToSendEmail.length; i++) {
        let pdfData = {
          fname: selectedQuote?.userId.firstName,
          lname: selectedQuote?.userId.lastName,
          email: selectedQuote?.userId.email,
          quoteId: selectedQuote?.RefId,
          total: selectedQuote?.price,
          addressline1: addressValues.addressline1,
          addressline2: addressValues.addressline2,
          city: addressValues.city,
          state: addressValues.state,
          country: addressValues.country,
          zipcode: addressValues.zipCode,
          partData: JSON.stringify(selectedQuote?.partsData),
          process: selectedQuote?.selectedProcess,
          price: selectedQuote?.price,
          price1: selectedQuote?.price1,
          price2: selectedQuote?.price2,
          selectedShipMethod: selectedShipMethodsToSendEmail[i],
          sheetMetalAndInjectionDesc:
            selectedQuote?.sheetAndInjectionDiscription,
          sheetMetalAndInjectionMP: selectedQuote?.sheetAndInjectionManualPrice,
          sheetAndInjectionQuantity: selectedQuote?.sheetAndInjectionQuantity,
          forInvoice: false,
          hidePrice: selectedQuote?.hidePrice,
          isQrTrackingRequested: selectedQuote?.isQrTrackingRequested,
          isDimensionalReportRequested:
            selectedQuote?.isDimensionalReportRequested,
          isConformanceCertificateRequested:
            selectedQuote?.isConformanceCertificateRequested,
          additionalCost:
            selectedShipMethodsToSendEmail[i] == 0
              ? selectedQuote.additionalCost1
              : selectedShipMethodsToSendEmail[i] == 1
                ? selectedQuote.additionalCost2
                : selectedQuote.additionalCost3,
          shippingCharge:
            selectedShipMethodsToSendEmail[i] == 0
              ? selectedQuote.shippingCharge1
              : selectedShipMethodsToSendEmail[i] == 1
                ? selectedQuote.shippingCharge2
                : selectedQuote.shippingCharge3,
          leadTime:
            selectedShipMethodsToSendEmail[i] == 0
              ? selectedQuote.leadTime1
              : selectedShipMethodsToSendEmail[i] == 1
                ? selectedQuote.leadTime2
                : selectedQuote.leadTime3,
          projectName: selectedQuote?.projectName,
          date: selectedQuote?.createdAt,
          version: selectedQuote?.version,
          quotationNotes: selectedQuote?.quotationNotes,
          quotationTnc: selectedQuote?.quotationTnc,
        };

        let quoteCost = 0;

        if (selectedShipMethodsToSendEmail[i] == 0) {
          quoteCost = Number(selectedQuote.price).toFixed(2);
        } else if (selectedShipMethodsToSendEmail[i] == 1) {
          quoteCost = Number(selectedQuote.price1).toFixed(2);
        } else {
          if (selectedQuote.selectedProcess <= 2) {
            quoteCost = Number(selectedQuote.stManualPrice).toFixed(2);
          } else {
            quoteCost = Number(
              selectedQuote.sheetAndInjectionManualPrice
            ).toFixed(2);
          }
        }

        let responseFromDownloadPdf = await downloadPdf(pdfData, token);
        if (responseFromDownloadPdf.data.data) {
          fileInfoForQuote.push({
            fileName: responseFromDownloadPdf.data.data,
            quoteCost,
          });
        }
      }

      let data = {
        fname: selectedQuote?.userId.firstName,
        email: selectedQuote?.userId.email,
        quoteRefId: selectedQuote?.RefId,
        quoteId: selectedQuote._id,
        price: selectedQuote?.price || 0,
        price1: selectedQuote?.price1 || 0,
        stManualPrice: selectedQuote.stManualPrice || 0,
        sheetAndInjectionManualPrice:
          selectedQuote.sheetAndInjectionManualPrice,
        hidePrice: selectedQuote?.hidePrice,
        partsLength: selectedQuote?.partsData.length,
        process: selectedQuote?.selectedProcess,
        partData: selectedQuote?.partsData,
        fileInfoForQuote,
      };
      let response = await sendQuotationToCustomerEmail(token, data);
      console.log("response", response);
      if (response.status == false) {
        setIsLoaderActive(false);
        toast.error("Something went wrong while sending email to customer.");
        return;
      } else {
        toast.success("Quotation sent to email successfully.");
        setIsLoaderActive(false);
        let localAllVersionForRefId = [...allVersionForRefId];
        localAllVersionForRefId.forEach((item) => {
          if (item._id == selectedQuote._id) {
            item.isEmailSent = true;
          }
        });
        setAllVersionForRefId(localAllVersionForRefId);
        setSelectedQuote({ ...selectedQuote, isEmailSent: true });
      }
    } catch (err) {
      setIsLoaderActive(false);
      toast.error(err.message || "Something went wrong");
    }
  };

  const handleQuoteSend = async () => {
    try {
      if (
        isChanged.partInfo ||
        isChanged.additionalCost ||
        isChanged.shippingCharge ||
        isChanged.leadtime
      ) {
        toast.error(
          "Please update the quotation before downloading the quote."
        );
        return;
      }

      let shipMethodWithPrice = [];
      if (selectedQuote.price) {
        shipMethodWithPrice.push(0);
      }
      if (selectedQuote.price1) {
        shipMethodWithPrice.push(1);
      }

      if (
        selectedQuote.stManualPrice ||
        selectedQuote.sheetAndInjectionManualPrice
      ) {
        shipMethodWithPrice.push(2);
      }

      if (shipMethodWithPrice.length == 0) {
        toast.error("No Price is updated,Please update atleast one price.");
        return;
      }

      let data = {
        quoteId: selectedQuote._id,
      };

      let response = await handleMarkAsSend(token, data);
      let localAllVersionForRefId = [...allVersionForRefId];
      localAllVersionForRefId.forEach((item) => {
        if (item._id == response?.data?._id) {
          item.isSent = response?.data?.isSent;
        }
      });
      setAllVersionForRefId(localAllVersionForRefId);
      setSelectedQuote({ ...selectedQuote, isSent: response?.data?.isSent });
      toast.success("Quotation is now mark as sent");
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleUpdateQuote = async (updateOption) => {
    try {
      if (!versionDescriptionValue) {
        toast.error("Please select the value for  version description");
        return;
      }

      // setShowModal(false);
      let isDataValid = validateData();
      if (!isDataValid) {
        toast.error("Please enter valid value for LeadTime and Item Price");
        return;
      }

      let adminUserResponse = confirm("Are you sure you want to continue?");
      if (adminUserResponse) {
        let data = {
          partsData: selectedQuote?.partsData,
          _id: selectedQuote?._id,
          process: selectedQuote?.selectedProcess,
          stmp: selectedQuote?.stManualPrice || 0,
          sheetAndInjectionManualPrice:
            selectedQuote?.sheetAndInjectionManualPrice || 0,
          leadTime: leadTime,
          price: selectedQuote?.price || 0,
          price1: selectedQuote?.price1 || 0,
          updateOption: updateOption,
          additionalCost1: selectedQuote.additionalCost1,
          additionalCost2: selectedQuote.additionalCost2,
          additionalCost3: selectedQuote.additionalCost3,
          shippingCharge1: selectedQuote.shippingCharge1,
          shippingCharge2: selectedQuote.shippingCharge2,
          shippingCharge3: selectedQuote.shippingCharge3,
          adminComment: selectedQuote?.adminComment,
          versionDescription: versionDescriptionValue,
          isTargetCostUpdatedByAdmin:isCostUpdatedbyAdminWhenTargetCostIsAvailable,
        };

        let response = await updateQuoteAdmin(data, token);
        let updatedQuote = response?.data;

        if (response?.status == false) {
          throw {
            message: "Something went wrong while updating price!",
          };
        } else {
          toast.success("Price update successfully");
          setShowModal(false);
          setSelectedQuote(updatedQuote);
          if (updateOption == "new") {
            let localAllQuoteVersion = [...allVersionForRefId, updatedQuote];
            localAllQuoteVersion.sort((a, b) => {
              return b.version - a.version;
            });
            setAllVersionForRefId(localAllQuoteVersion);
          } else {
            let localAllQuoteVersion = [...allVersionForRefId];
            localAllQuoteVersion.forEach((item, index) => {
              if (item?._id === updatedQuote?._id) {
                localAllQuoteVersion[index] = updatedQuote;
              }
            });

            localAllQuoteVersion.sort((a, b) => {
              return b.version - a.version;
            });

            console.log("allQuotaitonVerson", localAllQuoteVersion);

            setAllVersionForRefId([...localAllQuoteVersion]);
          }
          navigate(`/admin/admin-quote/${updatedQuote?._id}`);
          fetchSupplierDataForQuoteId(updatedQuote?._id);
          setIsChanged({
            ...isChanged,
            partInfo: false,
            additionalCost: false,
            leadtime: false,
            shippingCharge: false,
          });
        }
      }
    } catch (err) {
      console.log("err", err);
      let message =
        err && err.message
          ? err.message
          : "Something went wrong during fetch getSaveQuoteData";
      return false;
    }
  };

  const handleLeadTimeChange = (event, index) => {
    setIsChanged({ ...isChanged, leadtime: true });
    let localLeadTimeCopy = [...leadTime];
    let leadTimeAtIndex = { ...leadTime[index] };
    leadTimeAtIndex = event.target.value;
    localLeadTimeCopy[index] = leadTimeAtIndex;
    setLeadTime(localLeadTimeCopy);
    setSelectedQuote({
      ...selectedQuote,
      leadTime1: localLeadTimeCopy[0],
      leadTime2: localLeadTimeCopy[1],
      leadTime3: localLeadTimeCopy[2],
    });
  };

  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };
  const handleQMShowClick = (functionality) => {
    setFunctionalityForDQ(functionality);
    if (functionality == "download-quote") {
      if (selectedShipMethod === "") {
        toast.error("Select a cost for which you want to download the quote.");
        return;
      }
    }
    setShowDQModal(true);
  };

  let handleDownload = () => {
    if (!addressValues.shippingAddress) {
      toast.error(
        "Please select a valid shipping address to download quotation."
      );
      return;
    }
    let data = {
      fname: addressValues.firstName,
      lname: addressValues.lastName,
      email: selectedQuote?.userId.email,
      quoteId: selectedQuote?.RefId,
      total: selectedQuote?.price,
      addressline1: addressValues.addressline1,
      addressline2: addressValues.addressline2,
      city: addressValues.city,
      state: addressValues.state,
      country: addressValues.country,
      zipcode: addressValues.zipCode,
      partData: JSON.stringify(selectedQuote?.partsData),
      process: selectedQuote?.selectedProcess,
      price: selectedQuote?.price,
      price1: selectedQuote?.price1,
      price2: selectedQuote?.price2,
      selectedShipMethod: selectedShipMethod,
      sheetMetalAndInjectionDesc: selectedQuote?.sheetAndInjectionDiscription,
      sheetMetalAndInjectionMP: selectedQuote?.sheetAndInjectionManualPrice,
      sheetAndInjectionQuantity: selectedQuote?.sheetAndInjectionQuantity,
      forInvoice: false,
      hidePrice: selectedQuote?.hidePrice,
      isQrTrackingRequested: selectedQuote?.isQrTrackingRequested,
      isDimensionalReportRequested: selectedQuote?.isDimensionalReportRequested,
      isConformanceCertificateRequested:
        selectedQuote?.isConformanceCertificateRequested,
      additionalCost:
        selectedQuote?.selectedShipMethod == 0
          ? selectedQuote.additionalCost1
          : selectedQuote?.selectedShipMethod == 1
            ? selectedQuote.additionalCost2
            : selectedQuote.additionalCost3,
      shippingCharge:
        selectedQuote?.selectedShipMethod == 0
          ? selectedQuote.shippingCharge1
          : selectedQuote?.selectedShipMethod == 1
            ? selectedQuote.shippingCharge2
            : selectedQuote.shippingCharge3,
      leadTime:
        selectedQuote?.selectedShipMethod == 0
          ? selectedQuote.leadTime1
          : selectedQuote?.selectedShipMethod == 1
            ? selectedQuote.leadTime2
            : selectedQuote.leadTime3,
      projectName: selectedQuote?.projectName,
      date: selectedQuote?.createdAt,
      version: selectedQuote?.version,
      quotationNotes: selectedQuote?.quotationNotes,
      quotationTnc: selectedQuote?.quotationTnc,
    };
    setShowDQModal(false);
    setQuotationDownloading(true);
    let priceValue =
      selectedQuote?.selectedShipMethod == 0
        ? selectedQuote.price
        : selectedQuote?.selectedShipMethod == 1
          ? selectedQuote.price1
          : selectedQuote.selectedProcess <= 2
            ? selectedQuote.stManualPrice
            : selectedQuote.sheetAndInjectionManualPrice;
    let fileName = `${selectedQuote.RefId}-V-${selectedQuote.version}-${Number(
      priceValue
    ).toFixed(2)}.pdf`;

    downloadPdf(data, token)
      .then((res) => {
        fileSaver.saveAs(
          `${config.backEnd}/public/docs/` + res.data.data,
          fileName
        );
      })
      .catch((err) => {
        console.log(
          "error in admin quote 610 on:: " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
          err
        );
      })
      .finally(() => {
        setQuotationDownloading(false);
      });
  };

  const handleBack = () => {
    let adminResponse = confirm(
      "Information will be lost, are you sure you want to go back ?"
    );
    if (adminResponse) {
      navigate(-1);
    }
  };

  useEffect(() => {
    handleChangeQuoteVersion(_id);
  }, [_id]);

  const handleChangeQuoteVersion = (selectedQuoteId) => {
    allVersionForRefId.forEach((quote) => {
      if (quote._id == selectedQuoteId) {
        setSelectedQuote(quote);
        let localLeadTime = [quote.leadTime1, quote.leadTime2, quote.leadTime3];
        setLeadTime(localLeadTime);
        fetchSupplierDataForQuoteId(quote._id);
        let line1Total = 0,
          line2Total = 0,
          line3Total = 0;
        quote?.partsData?.forEach((item) => {
          line1Total += Number(item.Qty) * Number(item.price);
          line2Total += Number(item.Qty) * Number(item.price1);
          line3Total += Number(item.Qty) * Number(item.price2);
        });
        setLinesTotal([line1Total, line2Total, line3Total]);
        setQuotationNotes(quote?.quotationNotes || quotationNotes);
        setQuotationTnc(quote?.quotationTnc || quotationTnc);

        let filteredOrders = allOrdersForProject.filter(
          (item) => item.quoteId._id == quote._id
        );
        if (filteredOrders.length) {
          setSalesOrder(filteredOrders[0]);
        } else {
          setSalesOrder(null);
        }
      }
    });
  };

  const fetchSupplierDataForQuoteId = async (quoteId) => {
    try {
      const fetchRFQResponse = await fetchRFQForQuotation(quoteId, token);
      if (fetchRFQResponse.data.status) {
        setRFQForQuotation(fetchRFQResponse.data.data);
      } else {
        setRFQForQuotation(null);
      }
    } catch (err) {
      console.log(
        "err in admin quote 682 on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleQuotePriceSelection = (e) => {
    setSelectedShipMethod(Number(e.target.value));
    setSelectedQuote({ ...selectedQuote, selectedShipMethod: e.target.value });
  };

  const handleEditQuote = () => {
    navigate(`/create-instant-quotation/${selectedQuote?._id}`);
  };

  const handleMoveToProgress = async () => {
    try {
      let data = {
        quoteId: selectedQuote._id,
      };
      const response = await moveQuoteToProgressStatus(token, data);
      if (response.status) {
        setSelectedQuote({
          ...selectedQuote,
          isCancelled: false,
        });

        let localAllVersionForRefId = [...allVersionForRefId];
        localAllVersionForRefId.forEach((item) => {
          if (item._id == selectedQuote._id) {
            item.isCancelled = false;
          }
        });
        setAllVersionForRefId(localAllVersionForRefId);

        toast.success(response.message);
      } else {
        toast.error("Something went wrong.Please try again later!");
      }
    } catch (e) {
      toast.error(e.message || "Something went wrong.Please try again later!");
    }
  };

  const handleCancelQuoteClick = async () => {
    try {
      if (selectedQuote.isCancelled) {
        toast.error("Quotation is already cancelled");
        return;
      }

      if (selectedQuote.myorders) {
        toast.error("Sales order for this quotation is already created.");
        return;
      }
      const quoteCancelResponse = confirm(
        "Are you sure that you want to cancel this version of  quotation?"
      );

      if (quoteCancelResponse) {
        let data = {
          quoteId: selectedQuote._id,
        };
        const response = await handleQuoteCancel(token, data);
        if (response.status) {
          const adminResponse = confirm(
            "Do you want to cancel the RFQ associated with the quotation?"
          );
          if (adminResponse) {
            let quotationIdsForRFQ = [selectedQuote._id];
            await cancelRFQForQuotation(token, {
              quoteIds: quotationIdsForRFQ,
            });
          }
          setSelectedQuote({
            ...selectedQuote,
            isCancelled: true,
          });

          let localAllVersionForRefId = [...allVersionForRefId];
          localAllVersionForRefId.forEach((item) => {
            if (item._id == selectedQuote._id) {
              item.isCancelled = true;
            }
          });
          setAllVersionForRefId(localAllVersionForRefId);

          toast.success(response.message);
        } else {
          toast.error("Something went wrong.Please try again later!");
        }
      } else {
        return;
      }
    } catch (err) {
      console.log("error", err);
      toast.error("Something went wrong.Please try again later");
    }
  };

  const handleProceedToPO = async () => {
    if (!poNumber || !poDocument) {
      toast.error("Po Number and Po document are required to create an order.");
      return;
    }

    if (selectedShipMethod === "") {
      toast.error("Select the cost for which you want to place an order.");
      return;
    }

    if (
      selectedQuote?.selectedProcess <= 2 &&
      ((selectedShipMethod == 0 && selectedQuote?.price == 0) ||
        (selectedShipMethod == 1 && selectedQuote?.price1 == 0) ||
        (selectedShipMethod == 2 && selectedQuote?.stManualPrice == 0))
    ) {
      toast.error("Invalid selection of price.");
      return;
    }

    if (
      selectedQuote?.selectedProcess >= 3 &&
      ((selectedShipMethod == 0 && selectedQuote?.price == 0) ||
        (selectedShipMethod == 1 && selectedQuote?.price1 == 0) ||
        (selectedShipMethod == 2 &&
          selectedQuote?.sheetAndInjectionManualPrice == 0))
    ) {
      toast.error("Invalid selection of price.");
      return;
    }

    if (
      addressValues?.address?.length === 0 ||
      !addressValues?.address?.length
    ) {
      toast.error(
        "User does not have any address added in the system. Please add atleast one address in order to place an order for the user"
      );
      return;
    }
    let userResponse = confirm(
      "Are you sure you want to proceed with the selected version?"
    );
    if (userResponse) {
      try {
        let data = {
          quoteId: selectedQuote?._id,
          selectedShipMethod: selectedShipMethod,
        };
        await updateQuoteShipMethod(token, data);

        let formData = new FormData();
        formData.append("firstName", selectedQuote?.userId.firstName);
        formData.append("lastName", selectedQuote?.userId.lastName);
        formData.append("email", selectedQuote?.userId.email);
        formData.append("mobileNumber", selectedQuote?.userId.phoneNumber);
        formData.append(
          "companyName",
          selectedQuote?.userId.companySiteId.companyName
        );
        formData.append(
          "vatNumber",
          selectedQuote?.userId.companySiteId.vatNumber
        );
        formData.append("addressline1", "");
        formData.append("addressline2", "");
        formData.append("zipCode", "");
        formData.append("city", "");
        formData.append("state", "");
        formData.append("country", "");
        formData.append("shippingAddress", addressValues?.address[0]._id);
        formData.append("tax", selectedQuote?.userId.companySiteId.taxes);
        formData.append(
          "subTotal",
          selectedShipMethod === 0
            ? selectedQuote?.price
            : selectedShipMethod === 1
              ? selectedQuote?.price1
              : selectedQuote?.selectedProcess >= 3
                ? selectedQuote?.sheetAndInjectionManualPrice
                : selectedQuote?.stManualPrice
        );
        formData.append("quoteSelectedShipMethod", selectedShipMethod);
        formData.append("quoteId", selectedQuote?._id);
        formData.append("file", poDocument);
        formData.append("purchaseNumber", poNumber);
        formData.append("orderNumberLater", true);

        newCheckout(formData, token)
          .then((res) => {
            if (res.response?.data?.data?.message) {
              toast.error(res.response.data.data.message);
            }
            if (res.data.status == false) {
              toast.error("Something went wrong while placing the order");
            }
            navigate("/admin/orders/" + res.data.data._id, {
              state: { order: res.data.data },
            });
          })
          .catch((err) => {
            toast.error(err);
          });
      } catch (err) {
        console.log("err", err);
        return err;
      }
    }
  };

  const handleAdditionalCharge = (e, index) => {
    setIsChanged({ ...isChanged, additionalCost: true });
    let additionalCostValue = Number(e.target.value);
    let totalPrice0 = 0;
    let totalPrice1 = 0;
    let totalPrice2 = 0;
    selectedQuote?.partsData.map((item, i) => {
      totalPrice0 += Number(item.price) * Number(item.Qty);
      totalPrice1 += Number(item.price1) * Number(item.Qty);
      totalPrice2 += Number(item.price2) * Number(item.Qty);
      return;
    });

    if (index == 1) {
      setSelectedQuote({
        ...selectedQuote,
        additionalCost1: additionalCostValue,
        price: totalPrice0 + additionalCostValue,
      });
    } else if (index == 2) {
      setSelectedQuote({
        ...selectedQuote,
        additionalCost2: additionalCostValue,
        price1: totalPrice1 + additionalCostValue,
      });
    } else {
      if (selectedQuote?.selectedProcess <= 2) {
        setSelectedQuote({
          ...selectedQuote,
          additionalCost3: additionalCostValue,
          stManualPrice: totalPrice2 + additionalCostValue,
        });
      } else {
        setSelectedQuote({
          ...selectedQuote,
          additionalCost3: additionalCostValue,
          sheetAndInjectionManualPrice: totalPrice2 + additionalCostValue,
        });
      }
    }
  };

  const handleShippingChargeChange = (e, index) => {
    setIsChanged({ ...isChanged, shippingCharge: true });
    let shippingChargeValue = Number(e.target.value);

    let totalPrice0 = 0;
    let totalPrice1 = 0;
    let totalPrice2 = 0;
    selectedQuote?.partsData.map((item, i) => {
      totalPrice0 += Number(item.price) * Number(item.Qty);
      totalPrice1 += Number(item.price1) * Number(item.Qty);
      totalPrice2 += Number(item.price2) * Number(item.Qty);
      return;
    });

    if (index == 1) {
      setSelectedQuote({
        ...selectedQuote,
        shippingCharge1: shippingChargeValue,
        price:
          totalPrice0 +
          (selectedQuote?.additionalCost1 ? selectedQuote?.additionalCost1 : 0),
      });
    } else if (index == 2) {
      setSelectedQuote({
        ...selectedQuote,
        shippingCharge2: shippingChargeValue,
        price1:
          totalPrice1 +
          (selectedQuote?.additionalCost2 ? selectedQuote?.additionalCost2 : 0),
      });
    } else {
      if (selectedQuote?.selectedProcess <= 2) {
        setSelectedQuote({
          ...selectedQuote,
          shippingCharge3: shippingChargeValue,
          stManualPrice:
            totalPrice2 +
            (selectedQuote?.additionalCost3
              ? selectedQuote?.additionalCost3
              : 0),
        });
      } else {
        setSelectedQuote({
          ...selectedQuote,
          shippingCharge3: shippingChargeValue,
          sheetAndInjectionManualPrice:
            totalPrice2 +
            (selectedQuote?.additionalCost3
              ? selectedQuote?.additionalCost3
              : 0),
        });
      }
    }
  };

  const handleUpdateAdminComment = async () => {
    try {
      let data = {
        RefId: selectedQuote.RefId,
        adminComment: selectedQuote.adminComment,
      };
      const response = await updateQuoteAdminComment(data);
      if (response.status) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(
        "err in admin quote 921 on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleProceedToSOModal = () => {
    if (
      isChanged.partInfo ||
      isChanged.additionalCost ||
      isChanged.shippingCharge ||
      isChanged.leadtime
    ) {
      toast.error("Please update the quotation before downloading the quote.");
      return;
    }

    if (selectedShipMethod === "") {
      toast.error("Select the cost for which you want to place an order.");
      return;
    }
    setShowSoModal(true);
  };

  const handleCreateRFQ = async () => {
    try {
      if (
        isChanged.partInfo ||
        isChanged.additionalCost ||
        isChanged.shippingCharge ||
        isChanged.leadtime
      ) {
        toast.error(
          "Please update the quotation before downloading the quote."
        );
        return;
      }

      let partsData = [];
      const parts = selectedQuote.projectName.split("_");
      let projectName = parts.slice(1).join("_");
      projectName = prompt(
        "Enter the project name for selected supplier.",
        projectName
      );
      if (projectName?.length > 0) {
        selectedQuote.partsData.forEach((item) => {
          let partDataAtIndex = {
            materialType: item.MaterialType?._id,
            subMaterial: item.SubMaterial?._id,
            surfaceFinish: item.surfaceFinish?._id,
            surfaceTreatment: item.surfaceTreatment?._id,
            tolerance: item.tolerance?._id,
            partMarking: item.partMarking?._id,
            layer: item.layer?._id,
            qty: item.Qty,
            partNotes: item.Notes,
            description: item.description,
            noOfThread: item.noOfThread,
          };
          partsData.push(partDataAtIndex);
        });

        let data = {
          quotationId: selectedQuote._id,
          quotationRefId: `${selectedQuote.RefId}-V-${selectedQuote.version}`,
          selectedProcess: selectedQuote.selectedProcess,
          isQrTrackingRequested: selectedQuote.isQrTrackingRequested,
          isDimensionalReportRequested:
            selectedQuote.isDimensionalReportRequested,
          isConformanceCertificateRequested:
            selectedQuote.isConformanceCertificateRequested,
          partsData,
          rfqStatus: "active",
          projectName: `${selectedQuote.RefId}-V-${selectedQuote.version}-${projectName}`,
        };

        let rFQResponse = await createRFQ(data, token);
        if (rFQResponse.data.status) {
          toast.success(rFQResponse.data.message);
          navigate(`/admin/rfq-details/${rFQResponse.data.data._id}`);
        } else {
          toast.error(rFQResponse.data.message);
          return;
        }
      } else {
        toast.error("Enter valid Project Name");
        return;
      }
    } catch (err) { }
  };

  const handleGoToRFQClick = () => {
    navigate(`/admin/rfq-details/${rFQForQuotation._id}`);
  };

  const handleUpdateButtonClick = () => {
    if (selectedQuote.isCancelled) {
      toast.error("Quote is already cancelled.");
      return;
    }

    let isAdditionalCostValid = true;

    if (selectedQuote.isQrTrackingRequested) {
      if (selectedQuote.price && !(selectedQuote.additionalCost1 > 0)) {
        isAdditionalCostValid = false;
      }
      if (selectedQuote.price1 && !(selectedQuote.additionalCost2 > 0)) {
        isAdditionalCostValid = false;
      }

      if (
        (selectedQuote.stManualPrice && !(selectedQuote.additionalCost3 > 0)) ||
        (selectedQuote.sheetAndInjectionManualPrice &&
          !(selectedQuote.additionalCost3 > 0))
      ) {
        isAdditionalCostValid = false;
      }
    }

    if (!isAdditionalCostValid) {
      let adminResponse = confirm(
        "QR code is Requested. Are you sure you want to keep additionalCost as zero?"
      );
      if (!adminResponse) {
        return;
      }
    }

    let isShippingCostValid = true;

    if (selectedQuote.price && !(selectedQuote.shippingCharge1 > 0)) {
      isShippingCostValid = false;
    }
    if (selectedQuote.price1 && !(selectedQuote.shippingCharge2 > 0)) {
      isShippingCostValid = false;
    }
    if (
      (selectedQuote.stManualPrice && !(selectedQuote.shippingCharge3 > 0)) ||
      (selectedQuote.sheetAndInjectionManualPrice &&
        !(selectedQuote.shippingCharge3 > 0))
    ) {
      isShippingCostValid = false;
    }

    if (!isShippingCostValid) {
      let adminResponse = confirm(
        "Shipping charge for one of the cost is zero. Are you sure you want to proceed?"
      );
      if (!adminResponse) {
        return;
      }
    }

    setShowModal(true);
  };

  const handleVersionDescriptionSelect = (e) => {
    setVersionDescriptionValue(e.target.value);
  };

  const handleItemInformationChange = (index, field, value) => {
    try {
      let localPartsData = [...selectedQuote?.partsData];
      setIsChanged({ ...isChanged, partInfo: true });
      localPartsData[index][field] = value;

      let totalPrice0 = 0;
      let totalPrice1 = 0;
      let totalPrice2 = 0;
      let thirdPrice = 0;

      if (
        field == "price" ||
        field == "price1" ||
        field == "price2" ||
        field == "Qty"
      ) {
        if ((field == 'price' || field == 'price1' || field == 'price2') && selectedQuote?.userTargetCost) {
          setIsCostUpdatedByAdminWhenTargetCostIsAvailable(true);
        }




        localPartsData.forEach((item, i) => {
          totalPrice0 += Number(item.price) * Number(item.Qty);
          totalPrice1 += Number(item.price1) * Number(item.Qty);
          totalPrice2 += Number(item.price2) * Number(item.Qty);
        });
        thirdPrice =
          selectedQuote?.selectedProcess <= 2
            ? {
              stManualPrice:
                totalPrice2 +
                (selectedQuote?.additionalCost3
                  ? selectedQuote?.additionalCost3
                  : 0),
            }
            : {
              sheetAndInjectionManualPrice:
                totalPrice2 +
                (selectedQuote?.additionalCost3
                  ? selectedQuote?.additionalCost3
                  : 0),
            };
      }

      setSelectedQuote({
        ...selectedQuote,
        partsData: localPartsData,
        price:
          totalPrice0 +
          (selectedQuote?.additionalCost1 ? selectedQuote?.additionalCost1 : 0),
        price1:
          totalPrice1 +
          (selectedQuote?.additionalCost2 ? selectedQuote?.additionalCost2 : 0),
        ...thirdPrice,
      });
    } catch (err) {
      console.log("Error", err);
    }
  };

  const renderProjectName = () => {
    try {
      const firstUnderscoreIndex = selectedQuote?.projectName?.indexOf("_");
      const serialNumber = selectedQuote?.projectName.substring(
        0,
        firstUnderscoreIndex
      );
      const editableText = selectedQuote?.projectName.substring(
        firstUnderscoreIndex + 1
      );

      if (isEditingProjectName) {
        return (
          <>
            <div className="flex items-center justify-center  text-sm ">
              <span className="w-full">{serialNumber}_</span>
              <Form.Control
                type="text"
                value={selectedQuote?.editableProjectName}
                onChange={(e) => handleEditableChange(e)}
                style={{
                  margin: " 0px 4px",
                  height: "25px",
                  cursor: "pointer",
                  fontSize: "13px",
                }}
              />
              <span onClick={() => handleUpdateClick()}>
                <EditIcon additionalClass={"!h-4 ms-2 text-primaryColor"} />
              </span>

              <span onClick={() => handleCancelClick()}>
                <CrossIcon additionalClass={"!h-4 ms-2 text-primaryColor"} />
              </span>
            </div>
          </>
        );
      }

      return (
        <div className="flex items-center">
          <Link to={``}>
            <span>{serialNumber}_</span>
            <span>
              {editableText?.length >= 25
                ? editableText?.slice(0, 10) + "..."
                : editableText}
            </span>
          </Link>

          <span onClick={() => handleEditClick()}>
            <EditIcon additionalClass={"!h-4 ms-2 text-primaryColor"} />
          </span>
        </div>
      );
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <div className="admin-container">
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        ""
      )}

      <CaptchaModal
        showModal={showCaptchaModal}
        handleClose={handleCloseCaptchaModal}
        handleSendEmail={handleSendEmail}
        buttonText="Send Email"
      />
      {showAddressModal && (
        <AddressModal
          addressModal={showAddressModal}
          setShowAddressModal={setShowAddressModal}
          recentAddress={addressValues?.address}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          id={editId}
          values={addressValues}
          handledAddAddress={handledAddAddress}
          handleAddressOnEdit={handleAddressOnEdit}
        />
      )}

      <TeamCollaboration
        teamMemberModalShow={showFollowerModal}
        selectedQuoteForTeam={selectedQuote}
        setTeamMemberModalShow={setShowFollowerModal}
        fetchDataForUser={fetchAllQoutesForRefId}
        updateQuoteData={setSelectedQuote}
      />
      <Modal
        size="sm"
        dialogClassName="qoute-version-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "15px" }}
            className="font-bold"
          >
            Do you want to create a new version or update the exist quote?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "left" }}>
            <h6 className="my-1 font-medium">Select the version description: </h6>
            <Form.Select
              defaultValue={versionDescriptionValue || "default"}
              onChange={handleVersionDescriptionSelect}
            >
              <option disabled value="default">
                Select an option
              </option>
              {quoteVersionDescriptions?.map((item, i) => {
                return (
                  <option value={item.id} key={i}>
                    {item.value + `  ( ${item.id} )`}
                  </option>
                );
              })}
            </Form.Select>
          </div>

          <div style={{ textAlign: "center" }}>
            <h6 style={{ textAlign: "left", margin: "30px 0 -10px 0" }}>
              Select Update Option:{" "}
            </h6>

            <Button
              className="req-quote-btn"
              onClick={() => handleUpdateQuote("new")}
            >
              New Version
            </Button>

            {!selectedQuote?.myorders ? (
              <>
                <Button
                  style={{ marginLeft: "20px" }}
                  className="req-quote-btn"
                  onClick={() => handleUpdateQuote("update")}
                >
                  Update Existing
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>

          {selectedQuote?.myorders ? (
            <>
              <p style={{ color: "red", marginTop: "10px" }}>
                Cannot update the existing quotation since an order has already
                been created for that quotation.
              </p>
            </>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="req-quote-btn" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="sm"
        dialogClassName="qoute-version-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showSoModal}
        onHide={handlePoModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "15px" }}
          >
            Enter PO Number and Upload PO Document for Sales Order
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <div className="mb-6">
                <label>Purchase Order Number</label>
                <Form.Control
                  type="text"
                  placeholder="Enter PO Number"
                  value={poNumber ? poNumber : ""}
                  onChange={(e) => {
                    setPoNumber(e.target.value);
                  }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-6">
                <label> PO Document</label>
                {poDocument ? (
                  <div className="downloadables">
                    {poDocument?.name ? (
                      <>
                        <a
                          className="downloadables"
                          href={URL.createObjectURL(poDocument)}
                          download={poDocument?.name}
                        >
                          <span>{poDocument.name} </span>
                          <img src={downloadImg} style={{ height: "15px" }} />
                        </a>{" "}
                        &nbsp; &nbsp;
                        <img
                          src={deleteImageEnabled}
                          style={{ cursor: "pointer", height: "18px" }}
                          alt="delete"
                          height="18px"
                          onClick={handlePoDocumentDelete}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="ul-files">
                      <div className="file-button">
                        <input
                          type="file"
                          name="file-input"
                          id="file-input"
                          className="file-input__input"
                          onChange={(e) => {
                            let filesForCheck = Array.from(e.target.files);
                            let isAllFilesValid = true;
                            filesForCheck.forEach((file) => {
                              if (file.size > 10485760) {
                                isAllFilesValid = false;
                              }
                            });

                            if (!isAllFilesValid) {
                              toast.error(
                                "File size should be less than 10 MB"
                              );
                              return;
                            }
                            setPoDocument(e.target.files[0]);
                          }}
                        />
                        <label htmlFor="file-input" className=" white-btn">
                          {" "}
                          <span>Browse</span>
                        </label>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="req-quote-btn" onClick={handleProceedToPO}>
            Proceed To Sales Order
          </Button>
          <Button className="req-quote-btn" onClick={handlePoModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        dialogClassName="d-flex align-items-center justify-content-center custom-modal-view-order2"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showDQModal}
        onHide={() => setShowDQModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-sm"
            style={{ fontSize: "15px" }}
          >
            {functionalityForDQ == "download-quote"
              ? "Download quotation"
              : "Send To Email"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="address-bar mt-4">
            <h6 className="font-bold  my-2">
              Select shipping address for quotation
            </h6>
            <div className="billing-form">
              <div className="address">
                {addressValues?.address?.map((el, id) => (
                  <div className="singleAddress" key={id}>
                    <div style={{ display: "flex" }}>
                      <input
                        className="btn-radio"
                        type="radio"
                        value={el._id}
                        checked={addressValues.shippingAddress == el._id}
                        name="rd"
                        style={{ marginRight: "5px" }}
                        onChange={(e) => {
                          setError({
                            ...error,
                            shippingAddress: "",
                          });
                          setAddressValues({
                            ...addressValues,
                            shippingAddress: el._id,
                            addressline1: el.addressLineOne,
                            addressline2: el.addressLineTwo,
                            city: el.city,
                            country: el.country,
                            state: el.state,
                            zipCode: el.postalCode,
                            firstName: el.firstName,
                            lastName: el.lastName,
                          });
                        }}
                      />
                      <div className="address">
                        <div className="name">
                          {el?.firstName} {el?.lastName}
                        </div>
                        <div className="add">
                          {el?.addressLineOne} {el?.addressLineTwo} {el?.city}{" "}
                          {el?.state} {el.postalCode} {el.country}
                        </div>
                      </div>
                    </div>
                    <div className="buttons">
                      <div className="edit" onClick={() => editHandler(id)}>
                        Edit
                      </div>
                      |
                      <div className="delete" onClick={() => deleteData(id)}>
                        Delete
                      </div>
                    </div>
                  </div>
                ))}

                <div className="allAddress">
                  <div className="link"></div>
                  {addressValues?.address?.length >= 5 ? (
                    <div
                      className="addNew"
                      style={{ cursor: "auto", opacity: "0.5" }}
                    >
                      + Add New Address
                    </div>
                  ) : (
                    <div className="addNew" onClick={addNewHandler}>
                      + Add New Address
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {functionalityForDQ == "download-quote" ? (
            <Button onClick={handleDownload} className="req-quote-btn">
              Download Quotation
            </Button>
          ) : functionalityForDQ == "send-to-email" ? (
            <Button
              onClick={() => {
                setShowDQModal(false);
                setShowCaptchaModal(true);
              }}
              className="req-quote-btn"
            >
              Send To Email
            </Button>
          ) : (
            ""
          )}

          <Button
            onClick={() => setShowDQModal(false)}
            className="req-quote-btn"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Chat RefId={selectedQuote?.RefId} open={open} onDrawerClose={onClose} />
      <Sidebar />
      <span style={{ width: "100%" }}>
        <div className="center-heading">
          <h1 className="text-2xl font-bold  mt-3">Quote Details</h1>
        </div>

        <div className="right-container">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              marginTop: "-20px",
            }}
          >
            <div className="qoute-version-block">
              <label className="qoute-version-label">Project Name :</label>
              <p
                style={{
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  whiteSpace: "normal",
                  width: "400px",
                }}
              >
                {/* {selectedQuote?.projectName} */}
                {renderProjectName()}
              </p>
            </div>

            <div className="qoute-version-block">
              <label className="qoute-version-label">Selected Process :</label>
              <p
                style={{
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  whiteSpace: "normal",
                  width: "300px",
                }}
              >
                {selectedQuote
                  ? processType.map((val, i) => {
                    if (val.id == selectedQuote?.selectedProcess) {
                      return val.name;
                    }
                  })
                  : null}
              </p>
            </div>

            <div className="qoute-version-block">
              <label className="qoute-version-label">
                Documentation required :
              </label>
              {selectedQuote?.partsData?.length > 0 ? (
                <div>
                  <div
                    className="apply-check"
                    style={{ minWidth: "200px", width: "100%" }}
                  >
                    <input
                      type="checkbox"
                      id="sign-check-cc"
                      name="checkbox"
                      value={selectedQuote?.isConformanceCertificateRequested}
                      checked={selectedQuote?.isConformanceCertificateRequested}
                      disabled
                    />
                    <label htmlFor="sign-check-cc" style={{ fontSize: "14px" }}>
                      Certificate of conformance
                    </label>
                  </div>

                  <div
                    className="apply-check"
                    style={{ minWidth: "200px", width: "100%" }}
                  >
                    <input
                      type="checkbox"
                      id="sign-check-dr"
                      name="checkbox"
                      value={selectedQuote?.isDimensionalReportRequested}
                      checked={selectedQuote?.isDimensionalReportRequested}
                      disabled
                    />
                    <label htmlFor="sign-check-dr" style={{ fontSize: "14px" }}>
                      Dimensional Inspection Report
                    </label>
                  </div>

                  <div
                    className="apply-check"
                    style={{ minWidth: "200px", width: "100%" }}
                  >
                    <input
                      type="checkbox"
                      id="sign-check-qr"
                      name="checkbox"
                      value={selectedQuote?.isQrTrackingRequested}
                      checked={selectedQuote?.isQrTrackingRequested}
                      disabled
                    />
                    <label htmlFor="sign-check-qr" style={{ fontSize: "14px" }}>
                      QR tracking
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            style={{
              maxHeight: "150px",
              overflowY: "auto",
              margin: "10px 0px",
              border: "1px solid gray",
            }}
          >
            <table
              className="qoute-version-table-admin"
              style={{ border: "1px solid black", width: "100%" }}
            >
              <thead>
                <tr className="heading-row">
                  <th>Select Version</th>
                  <th>Version Description</th>
                  <th>Date</th>
                  <th>Cost 1</th>
                  <th>LeadTime 1</th>
                  <th>Cost 2</th>
                  <th>LeadTime 2</th>
                  <th>User Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {allVersionForRefId?.map((quoteItem, index) => {
                  const versionDescriptionText = quoteItem.versionDescription
                    ? `${quoteVersionDescriptions.find(
                      (item) => item.id === quoteItem.versionDescription
                    )?.value || "Not found"
                    }  ( ${quoteItem.versionDescription} )`
                    : "Not found";

                  const userName = (
                    <>
                      {(quoteItem?.userId?.firstName || "") +
                        " " +
                        (quoteItem?.userId?.lastName || "")}
                      <br />
                      {quoteItem?.userId
                        ? `(${quoteItem.userId.email?.split("@")[0]})`
                        : ""}
                    </>
                  );

                  let quotationStatus = "";
                  if (quoteItem.myorders && !quoteItem.isCancelled) {
                    quotationStatus = "Completed";
                  } else if (
                    (moment(Date.now()).diff(quoteItem.createdAt) /
                      (1000 * 3600 * 24) >
                      45 &&
                      !quoteItem.myorders) ||
                    quoteItem.isCancelled
                  ) {
                    quotationStatus = "Cancelled";
                  } else if (
                    !quoteItem.myorders &&
                    moment(Date.now()).diff(quoteItem.createdAt) /
                    (1000 * 3600 * 24) <=
                    45 &&
                    !quoteItem.isCancelled
                  ) {
                    quotationStatus = "In Progress";
                    if (
                      ((quoteItem.price == 0 &&
                        quoteItem.price1 == 0 &&
                        quoteItem.stManualPrice == 0 &&
                        quoteItem.sheetAndInjectionManualPrice == 0) ||
                        (quoteItem.selectedProcess != 2 &&
                          !quoteItem.isUpdatedByAdmin)) &&
                      !quoteItem.isCancelled &&
                      !quoteItem.isSent
                    ) {
                      quotationStatus = "In Review";
                    } else if (quoteItem.isSent) {
                      quotationStatus = "Sent";
                    }
                  }

                  return (
                    <tr
                      key={index}
                      className={
                        quoteItem._id === selectedQuote._id
                          ? "highlight-row"
                          : ""
                      }
                      onClick={() =>
                        navigate(`/admin/admin-quote/${quoteItem._id}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <td>Version {quoteItem.version}</td>
                      <td>{versionDescriptionText}</td>
                      <td>{moment(quoteItem.createdAt).format('YYYY-MM-DD')}</td>
                      <td>{currencyFormatter(quoteItem.price)}</td>
                      <td>{quoteItem.leadTime1}</td>
                      <td>{currencyFormatter(quoteItem.price1)}</td>
                      <td>{quoteItem.leadTime2}</td>
                      <td>{userName}</td>
                      <td>{quotationStatus}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {selectedQuote ? (
            <div className="quotes-table">
              <table className="table">
                {selectedQuote?.selectedProcess >= 3 ? (
                  <>
                    <thead>
                      <tr>
                        <th scope="col">File Name</th>
                        <th scope="col">Part Description</th>
                        <th scope="col">Notes</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Cost 1</th>
                        <th scope="col">Cost 2</th>
                        <th scope="col">Cost 3</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedQuote?.partsData?.length > 0 &&
                        selectedQuote?.partsData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                }}
                              >
                                <div
                                  className="prd-thumb"
                                  style={{ color: "dodgerblue" }}
                                >
                                  <p style={{ color: "dodgerblue" }}>
                                    {item.file
                                      ? item.file.originalname
                                      : item.selectedFile
                                        ? item.selectedFile?.path
                                        : item.linePartName || "No file uploaded"}
                                  </p>
                                  <span
                                    style={{ cursor: "pointer" }}
                                    className="mx-2"
                                    onClick={(e) => {
                                      let extension = getFileExtension(
                                        item?.file?.originalname ||
                                        item.selectedFile?.path
                                      );
                                      let url = `${config.backEnd}/public/uploads/${item._id}.${extension}`;
                                      download(
                                        item?.file?.originalname ||
                                        item.selectedFile?.path,
                                        url
                                      );
                                    }}
                                  >
                                    {item.file || item.selectedFile ? (
                                      <DownloadIcon />
                                    ) : null}
                                  </span>
                                </div>
                              </td>

                              <td
                                style={{
                                  textAlign: "left",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  width: "250px",
                                }}
                              >
                                <FormControl
                                  type="text"
                                  value={item?.description}
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "description",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter part description"
                                />
                              </td>

                              <td
                                style={{
                                  textAlign: "left",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  width: "250px",
                                }}
                              >
                                <FormControl
                                  type="text"
                                  value={item?.Notes}
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "Notes",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter part notes"
                                />
                              </td>

                              <td>
                                <FormControl
                                  type="number"
                                  value={item?.Qty}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "Qty",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Qty"
                                  onWheel={numberInputOnWheelPreventChange}
                                  style={{ minWidth: "50px" }}
                                />
                              </td>
                              <td>
                                <FormControl
                                  placeholder="Enter price"
                                  value={item?.price ? item?.price : ""}
                                  type="number"
                                  onWheel={numberInputOnWheelPreventChange}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "price",
                                      e.target.value
                                    )
                                  }
                                  min={0}
                                />
                              </td>
                              <td>
                                <FormControl
                                  placeholder="Enter price"
                                  value={item?.price1 ? item?.price1 : ""}
                                  type="number"
                                  onWheel={numberInputOnWheelPreventChange}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "price1",
                                      e.target.value
                                    )
                                  }
                                  min={0}
                                />
                              </td>

                              <td>
                                <FormControl
                                  placeholder="Enter price"
                                  value={item.price2 ? item.price2 : ""}
                                  type="number"
                                  onWheel={numberInputOnWheelPreventChange}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "price2",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}

                      <tr className="leadTimeRow">
                        <td></td>
                        <td></td>
                        <td id="leadTimeTitle">Lines Total</td>
                        <td></td>
                        <td>
                          {currencyFormatter(
                            linesTotal[0] ? linesTotal[0].toFixed(2) : 0
                          )}
                        </td>
                        <td>
                          {currencyFormatter(
                            linesTotal[1] ? linesTotal[1].toFixed(2) : 0
                          )}
                        </td>
                        <td>
                          {currencyFormatter(
                            linesTotal[2] ? linesTotal[2].toFixed(2) : 0
                          )}
                        </td>
                      </tr>

                      <tr className="leadTimeRow">
                        <td></td>
                        <td></td>
                        <td id="leadTimeTitle">Additional Charges</td>
                        <td></td>
                        <td>
                          <FormControl
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={
                              selectedQuote?.additionalCost1
                                ? selectedQuote?.additionalCost1
                                : ""
                            }
                            placeholder="Charge"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleAdditionalCharge(e, 1)}
                            min={0}
                          />
                        </td>

                        <td>
                          <FormControl
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={
                              selectedQuote?.additionalCost2
                                ? selectedQuote?.additionalCost2
                                : ""
                            }
                            placeholder="Charge"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleAdditionalCharge(e, 2)}
                            min={0}
                          />
                        </td>

                        <td>
                          <FormControl
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={
                              selectedQuote?.additionalCost3
                                ? selectedQuote?.additionalCost3
                                : ""
                            }
                            placeholder="Charge"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleAdditionalCharge(e, 3)}
                          />
                        </td>
                      </tr>

                      <tr className="leadTimeRow">
                        <td></td>
                        <td></td>
                        <td id="leadTimeTitle">Lead Time</td>
                        <td></td>
                        <td>
                          <FormControl
                            type="number"
                            onWheel={numberInputOnWheelPreventChange}
                            style={{ width: "100%" }}
                            value={leadTime[0] ? leadTime[0] : ""}
                            placeholder="Days"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleLeadTimeChange(e, 0)}
                          />
                          <p style={{ marginLeft: "10px" }}>
                            {leadTime[0]
                              ? `(${Math.floor(leadTime[0] / 7)}-${Math.floor(leadTime[0] / 7) + 1
                              } weeks)`
                              : "NA"}{" "}
                          </p>
                        </td>

                        <td>
                          <FormControl
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={leadTime[1] ? leadTime[1] : ""}
                            placeholder="Days"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleLeadTimeChange(e, 1)}
                          />
                          <p style={{ marginLeft: "10px" }}>
                            {leadTime[1]
                              ? `(${Math.floor(leadTime[1] / 7)}-${Math.floor(leadTime[1] / 7) + 1
                              } weeks)`
                              : "NA"}{" "}
                          </p>
                        </td>
                        <td>
                          <FormControl
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={leadTime[2] ? leadTime[2] : ""}
                            placeholder="Days"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleLeadTimeChange(e, 2)}
                          />
                          <p>
                            {leadTime[2]
                              ? leadTime[2] +
                              " Days" +
                              `(${Math.floor(leadTime[2] / 7)}-${Math.floor(leadTime[2] / 7) + 1
                              } weeks)`
                              : "NA"}{" "}
                          </p>
                        </td>
                      </tr>

                      <tr className="leadTimeRow">
                        <td></td>
                        <td></td>
                        <td id="leadTimeTitle">Shipping & Duties</td>
                        <td></td>
                        <td>
                          <FormControl
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={
                              selectedQuote?.shippingCharge1
                                ? selectedQuote?.shippingCharge1
                                : ""
                            }
                            placeholder="Shipping"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleShippingChargeChange(e, 1)}
                            min={0}
                          />
                        </td>

                        <td>
                          <FormControl
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={
                              selectedQuote?.shippingCharge2
                                ? selectedQuote?.shippingCharge2
                                : ""
                            }
                            placeholder="Shipping"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleShippingChargeChange(e, 2)}
                            min={0}
                          />
                        </td>
                        <td>
                          <FormControl
                            type="number"
                            style={{ width: "100%" }}
                            onWheel={numberInputOnWheelPreventChange}
                            value={
                              selectedQuote?.shippingCharge3
                                ? selectedQuote?.shippingCharge3
                                : ""
                            }
                            placeholder="Shipping"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => handleShippingChargeChange(e, 3)}
                            min={0}
                          />
                        </td>
                      </tr>

                      <tr className="leadTimeRow">
                        <td></td>
                        <td></td>
                        <td id="leadTimeTitle">Total</td>
                        <td></td>
                        <td>
                          <h6>
                            {currencyFormatter(
                              selectedQuote?.price
                                ? (
                                  selectedQuote?.price +
                                  selectedQuote?.shippingCharge1
                                )?.toFixed(2)
                                : 0
                            )}
                          </h6>
                        </td>

                        <td>
                          <h6>
                            {currencyFormatter(
                              selectedQuote?.price1
                                ? (
                                  selectedQuote?.price1 +
                                  selectedQuote?.shippingCharge2
                                ).toFixed(2)
                                : 0
                            )}
                          </h6>
                        </td>
                        <td>
                          <h6>
                            {currencyFormatter(
                              selectedQuote?.selectedProcess === 1 ||
                                selectedQuote?.selectedProcess === 2
                                ? (
                                  selectedQuote?.stManualPrice +
                                  selectedQuote?.shippingCharge3
                                ).toFixed(2)
                                : (
                                  selectedQuote?.sheetAndInjectionManualPrice +
                                  selectedQuote?.shippingCharge3
                                ).toFixed(2)
                            )}
                          </h6>
                        </td>
                      </tr>
                    </tbody>
                  </>
                ) : (
                  <>
                    <thead>
                      <tr>
                        <th scope="col"> 3D File </th>
                        <th scope="col"> 2D File </th>
                        <th scope="col">Part Description</th>
                        <th scope="col">Notes</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Cost 1</th>
                        <th scope="col">Cost 2</th>
                        <th scope="col">Cost 3</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedQuote?.partsData.length > 0 &&
                        selectedQuote?.partsData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  minWidth: "150px",
                                  cursor: "pointer",
                                }}
                              // onClick={(e) => {
                              //   alert("hey");
                              //   return;
                              //   e.preventDefault();
                              //   let extension = getFileExtension(
                              //     item.file.originalname
                              //   );
                              //   // alert(item._id);
                              //   // return;
                              //   let url = `${config.backEnd}/public/uploads/${item._id}.${extension}`;
                              //   download(item.file.originalname, url);
                              // }}
                              >
                                <div>
                                  <p className=" flex  items-center  gap-x-2 ">
                                    <span className="!text-primaryColor">
                                      {(item.file
                                        ? item.file.originalname
                                        : item.selectedFile
                                          ? item.selectedFile?.path
                                          : "Uploaded Sample File"
                                      )?.length > 25
                                        ? (item.file
                                          ? item.file.originalname
                                          : item.selectedFile
                                            ? item.selectedFile?.path
                                            : "Uploaded Sample File"
                                        ).substring(0, 25) + "..."
                                        : item.file
                                          ? item.file.originalname
                                          : item.selectedFile
                                            ? item.selectedFile?.path
                                            : "Uploaded Sample File"}
                                    </span>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        let extension = getFileExtension(
                                          item.file.originalname
                                        );
                                        let url = `${config.backEnd}/public/uploads/${item._id}.${extension}`;
                                        download(item.file.originalname, url);
                                      }}
                                    >
                                      <DownloadIcon
                                        additionalClass={"text-primaryColor"}
                                      />
                                    </span>
                                  </p>
                                </div>
                              </td>

                              <td
                                style={{
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  minWidth: "150px",
                                }}
                              >
                                <div style={{ color: "dodgerblue" }}>
                                  <p className=" flex  items-center  gap-x-2 ">
                                    {item.twoDFile ? (
                                      <>
                                        <span className="inline-block text-primaryColor ">
                                          {(item.twoDFile
                                            ? item.twoDFile.originalname
                                            : "Uploaded Sample File"
                                          )?.length > 25
                                            ? (item.twoDFile
                                              ? item.twoDFile.originalname
                                              : "Uploaded Sample File"
                                            ).substring(0, 25) + "..."
                                            : item.twoDFile
                                              ? item.twoDFile.originalname
                                              : "Uploaded Sample File"}
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              let url = `${config.backEnd}/${item.twoDFile.path}`;
                                              download(
                                                item.twoDFile.originalname,
                                                url
                                              );
                                            }}
                                          >
                                            <DownloadIcon
                                              additionalClass={
                                                "text-primaryColor inline-block"
                                              }
                                            />
                                          </span>
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span style={{ color: "#757575" }}>
                                          No 2D File Uploaded
                                        </span>
                                      </>
                                    )}
                                  </p>
                                </div>
                              </td>

                              <td
                                style={{
                                  textAlign: "left",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  width: "250px",
                                }}
                              >
                                <div
                                  className="desc-part"
                                  style={{ width: "100%" }}
                                >
                                  <FormControl
                                    type="text"
                                    value={item?.description}
                                    onChange={(e) =>
                                      handleItemInformationChange(
                                        index,
                                        "description",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Enter part description"
                                  />
                                  {selectedQuote.selectedProcess == 1 ?
                                    <>
                                      {item?.MaterialType ? (
                                        <p>{item?.MaterialType?.name}</p>
                                      ) : (
                                        ""
                                      )}
                                      {item?.SubMaterial ? (
                                        <p>{item?.SubMaterial.name}</p>
                                      ) : (
                                        ""
                                      )}
                                      {item?.surfaceFinish ? (
                                        <p>{item?.surfaceFinish.name}</p>
                                      ) : (
                                        ""
                                      )}
                                      {item?.surfaceTreatment ? (
                                        <p>{item?.surfaceTreatment.name}</p>
                                      ) : (
                                        ""
                                      )}
                                      {item?.tolerance ? (
                                        <p>{item?.tolerance.name}</p>
                                      ) : (
                                        ""
                                      )}
                                      {item?.partMarking ? (
                                        <p>{item?.partMarking.name}</p>
                                      ) : (
                                        ""
                                      )}
                                      {item?.noOfThread ? (
                                        <p>{item?.noOfThread}</p>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                    :
                                    <>
                                      <p>
                                        {item.threeDTechnology &&
                                          item.threeDTechnology?.name
                                        }
                                      </p>
                                      <p>
                                        {item.threeDMachine &&
                                          item.threeDMachine?.name
                                        }
                                      </p>
                                      <p>
                                        {item.threeDMaterial &&
                                          item.threeDMaterial?.name
                                        }
                                      </p>
                                      <p>
                                        {item.threeDPostProcessing &&
                                          item.threeDPostProcessing?.name
                                        }
                                      </p>

                                    </>}

                                </div>
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                  wordWrap: "break-word",
                                  wordBreak: "break-all",
                                  whiteSpace: "normal",
                                  width: "250px",
                                }}
                              >
                                <FormControl
                                  type="text"
                                  value={item?.Notes}
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "Notes",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter part notes"
                                />
                              </td>

                              <td>
                                <FormControl
                                  type="number"
                                  value={item?.Qty}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "Qty",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Qty"
                                  onWheel={numberInputOnWheelPreventChange}
                                  style={{ minWidth: "50px" }}
                                />
                              </td>
                              <td>
                                <FormControl
                                  placeholder="Enter price"
                                  value={item?.price ? item?.price : ""}
                                  type="number"
                                  onWheel={numberInputOnWheelPreventChange}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "price",
                                      e.target.value
                                    )
                                  }
                                  min={0}
                                />
                              </td>
                              <td>
                                <FormControl
                                  placeholder="Enter price"
                                  value={item?.price1 ? item?.price1 : ""}
                                  type="number"
                                  onWheel={numberInputOnWheelPreventChange}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "price1",
                                      e.target.value
                                    )
                                  }
                                  min={0}
                                />
                              </td>

                              <td>
                                <FormControl
                                  placeholder="Enter price"
                                  value={item.price2 ? item.price2 : ""}
                                  type="number"
                                  onWheel={numberInputOnWheelPreventChange}
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onChange={(e) =>
                                    handleItemInformationChange(
                                      index,
                                      "price2",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          );
                        })}
                      {selectedQuote?.selectedProcess === 1 ||
                        selectedQuote?.selectedProcess === 2 ? (
                        <>
                          <tr className="leadTimeRow">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td id="leadTimeTitle">Lines Total</td>
                            <td></td>
                            <td>
                              {currencyFormatter(
                                linesTotal[0] ? linesTotal[0].toFixed(2) : 0
                              )}
                            </td>
                            <td>
                              {currencyFormatter(
                                linesTotal[1] ? linesTotal[1].toFixed(2) : 0
                              )}
                            </td>
                            <td>
                              {currencyFormatter(
                                linesTotal[2] ? linesTotal[2].toFixed(2) : 0
                              )}
                            </td>
                          </tr>
                          <tr className="leadTimeRow">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td id="leadTimeTitle">Additional Charges</td>
                            <td></td>
                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={
                                  selectedQuote?.additionalCost1
                                    ? selectedQuote?.additionalCost1
                                    : ""
                                }
                                placeholder="Charge"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) => handleAdditionalCharge(e, 1)}
                                min={0}
                              />
                            </td>

                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={
                                  selectedQuote?.additionalCost2
                                    ? selectedQuote?.additionalCost2
                                    : ""
                                }
                                placeholder="Charge"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) => handleAdditionalCharge(e, 2)}
                                min={0}
                              />
                            </td>

                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={
                                  selectedQuote?.additionalCost3
                                    ? selectedQuote?.additionalCost3
                                    : ""
                                }
                                placeholder="Charge"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) => handleAdditionalCharge(e, 3)}
                              />
                            </td>
                          </tr>

                          <tr className="leadTimeRow">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td id="leadTimeTitle">Lead Time</td>
                            <td></td>
                            <td>
                              <FormControl
                                type="number"
                                onWheel={numberInputOnWheelPreventChange}
                                style={{ width: "100%" }}
                                value={leadTime[0] ? leadTime[0] : ""}
                                placeholder="Days"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) => handleLeadTimeChange(e, 0)}
                              />
                              <p
                                style={{ marginLeft: "10px", fontSize: "14px" }}
                              >
                                {leadTime[0]
                                  ? `(${Math.floor(leadTime[0] / 7)}-${Math.floor(leadTime[0] / 7) + 1
                                  } week)`
                                  : "NA"}{" "}
                              </p>
                            </td>

                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={leadTime[1] ? leadTime[1] : ""}
                                placeholder="Days"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) => handleLeadTimeChange(e, 1)}
                              />
                              <p
                                style={{ marginLeft: "10px", fontSize: "14px" }}
                              >
                                {leadTime[1]
                                  ? `(${Math.floor(leadTime[1] / 7)}-${Math.floor(leadTime[1] / 7) + 1
                                  } week)`
                                  : "NA"}{" "}
                              </p>
                            </td>

                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={leadTime[2] ? leadTime[2] : ""}
                                placeholder="Days"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) => handleLeadTimeChange(e, 2)}
                              />
                              <p
                                style={{ marginLeft: "10px", fontSize: "14px" }}
                              >
                                {leadTime[2]
                                  ? `(${Math.floor(leadTime[2] / 7)}-${Math.floor(leadTime[2] / 7) + 1
                                  } week)`
                                  : "NA"}{" "}
                              </p>
                            </td>
                          </tr>

                          <tr className="leadTimeRow">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td id="leadTimeTitle">Shipping & Duties</td>
                            <td></td>
                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={
                                  selectedQuote?.shippingCharge1
                                    ? selectedQuote?.shippingCharge1
                                    : ""
                                }
                                placeholder="Shipping"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) =>
                                  handleShippingChargeChange(e, 1)
                                }
                                min={0}
                              />
                            </td>

                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={
                                  selectedQuote?.shippingCharge2
                                    ? selectedQuote?.shippingCharge2
                                    : ""
                                }
                                placeholder="Shipping"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) =>
                                  handleShippingChargeChange(e, 2)
                                }
                                min={0}
                              />
                            </td>
                            <td>
                              <FormControl
                                type="number"
                                style={{ width: "100%" }}
                                onWheel={numberInputOnWheelPreventChange}
                                value={
                                  selectedQuote?.shippingCharge3
                                    ? selectedQuote?.shippingCharge3
                                    : ""
                                }
                                placeholder="Shipping"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                onChange={(e) =>
                                  handleShippingChargeChange(e, 3)
                                }
                                min={0}
                              />
                            </td>
                          </tr>

                          <tr className="leadTimeRow">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td id="leadTimeTitle">Total</td>
                            <td></td>
                            <td>
                              <h6>
                                {currencyFormatter(
                                  selectedQuote?.price
                                    ? (
                                      selectedQuote?.price +
                                      selectedQuote?.shippingCharge1
                                    ).toFixed(2)
                                    : 0
                                )}
                              </h6>
                            </td>

                            <td>
                              <h6>
                                {currencyFormatter(
                                  selectedQuote?.price1
                                    ? (
                                      selectedQuote?.price1 +
                                      selectedQuote?.shippingCharge2
                                    ).toFixed(2)
                                    : 0
                                )}
                              </h6>
                            </td>

                            <td>
                              <h6>
                                {currencyFormatter(
                                  selectedQuote?.selectedProcess === 1 ||
                                    selectedQuote?.selectedProcess === 2
                                    ? (
                                      selectedQuote?.stManualPrice +
                                      selectedQuote?.shippingCharge3
                                    ).toFixed(2)
                                    : (
                                      selectedQuote?.sheetAndInjectionManualPrice +
                                      selectedQuote?.shippingCharge3
                                    ).toFixed(2)
                                )}
                              </h6>
                            </td>
                          </tr>
                          {selectedQuote?.userTargetCost ? <tr className="leadTimeRow">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td id="leadTimeTitle">Requested Target Cost</td>
                            <td></td>
                            <td>
                              <h6>
                                {currencyFormatter(
                                  selectedQuote?.userTargetCost
                                    ? (
                                      selectedQuote?.userTargetCost +
                                      selectedQuote?.shippingCharge1
                                    ).toFixed(2)
                                    : 0
                                )}
                              </h6>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr> : null}
                        </>
                      ) : null}
                    </tbody>
                  </>
                )}
              </table>
              <div
                style={{ textAlign: "left", display: "flex", width: "100%" }}
              >
                <div style={{ width: "100%", flex: 1, marginRight: "10px" }}>
                  <h6 className="font-medium my-2 ">
                    Quotation Notes :&nbsp;&nbsp;
                  </h6>
                  <FormControl
                    as="textarea"
                    rows="3"
                    style={{ width: "100%" }}
                    placeholder="Enter notes here"
                    value={quotationNotes}
                    onChange={(e) => setQuotationNotes(e.target.value)}
                  />
                </div>
                <div style={{ width: "100%", flex: 1 }}>
                  <h6 className="font-medium my-2">
                    Quotation Tnc :&nbsp;&nbsp;
                  </h6>
                  <FormControl
                    as="textarea"
                    rows="3"
                    style={{ width: "100%" }}
                    placeholder="Enter Tnc here"
                    value={quotationTnc}
                    onChange={(e) => setQuotationTnc(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <div className="row quote-buttons admin-quote-buttons">
                  <button
                    className="update-button"
                    style={{ width: "180px" }}
                    onClick={handleUpdateQuotationNotesAndTnc}
                  >
                    Update Quote Note & TnC
                  </button>
                </div>
              </div>
              <div style={{ textAlign: "left" }}>
                <h6 className="font-medium my-2">
                  Admin Comments :&nbsp;&nbsp;
                </h6>
                <FormControl
                  as="textarea"
                  rows="3"
                  style={{ width: "100%" }}
                  placeholder="Enter comment here"
                  value={selectedQuote?.adminComment}
                  onChange={(e) =>
                    setSelectedQuote({
                      ...selectedQuote,
                      adminComment: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <div className="row quote-buttons admin-quote-buttons">
                  <button
                    className="update-button"
                    onClick={handleUpdateAdminComment}
                  >
                    Update Comment
                  </button>
                  {!selectedQuote?.isCancelled ? (
                    <>
                      <button
                        className="update-button"
                        style={{ leftMargin: "20px" }}
                        onClick={handleUpdateButtonClick}
                      >
                        Update
                      </button>
                      <button
                        className="update-button"
                        onClick={handleEditQuote}
                      >
                        Edit Quote{" "}
                      </button>

                      <select
                        name="download-quote-cost-select"
                        defaultValue="default"
                        onChange={(e) => handleQuotePriceSelection(e)}
                        // className="download-quote-cost-select"
                        placeholder="Select price to download quote"
                        className="w-fit h-[30px] mt-[10px]  text-black bg-slate-200 border   rounded-sm"
                      >
                        <option disabled value="default">
                          Select Cost
                        </option>
                        <option className="hover:bg-primaryColor" value="0">
                          Cost 1
                        </option>
                        <option className="hover:bg-primaryColor" value="1">
                          Cost 2
                        </option>
                        <option className="hover:bg-primaryColor" value="2">
                          Cost 3
                        </option>
                      </select>

                      <button
                        className="update-button flex items-center justify-center "
                        onClick={() => {
                          if (
                            isChanged.partInfo ||
                            isChanged.additionalCost ||
                            isChanged.shippingCharge ||
                            isChanged.leadtime
                          ) {
                            toast.error(
                              "Please update the quotation before downloading the quote."
                            );
                            return;
                          }

                          handleQMShowClick("download-quote");
                        }}
                      >
                        {!isQuotationDownloading ? (
                          <span>Download Quote</span>
                        ) : (
                          <LucidLoader additionalClass={"animate-spin"} />
                        )}
                      </button>
                      <button
                        className="update-button flex items-center justify-center"
                        // onClick={handleSendEmail}
                        onClick={() => {
                          if (
                            isChanged.partInfo ||
                            isChanged.additionalCost ||
                            isChanged.shippingCharge ||
                            isChanged.leadtime
                          ) {
                            toast.error(
                              "Please update the quotation before downloading the quote."
                            );
                            return;
                          }
                          handleQMShowClick("send-to-email");
                        }}
                      >
                        {!isLoaderActive ? (
                          "Send To Email"
                        ) : (
                          <LucidLoader additionalClass={"animate-spin"} />
                        )}
                      </button>

                      {!selectedQuote.isSent ? (
                        <>
                          <button
                            className="update-button"
                            onClick={handleQuoteSend}
                          >
                            Mark as Sent
                          </button>
                        </>
                      ) : (
                        <></>
                      )}

                      {salesOrder?._id ? (
                        <>
                          <button
                            className="update-button"
                            onClick={() =>
                              navigate(`/admin/orders/${salesOrder?._id}`)
                            }
                          >
                            Go to SO
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="update-button"
                            onClick={handleProceedToSOModal}
                          >
                            Proceed to SO
                          </button>
                        </>
                      )}
                      {
                        ((selectedQuote?.price || selectedQuote?.price1 || selectedQuote?.price2 || selectedQuote?.stManualPrice)
                          &&
                          (!selectedQuote?.isTargetCostActivated)) ?
                          <button
                            onClick={handleTargetCostAsk}
                            className="update-button whitespace-nowrap"
                          >
                            Ask Target Cost
                          </button> : null
                      }

                      <button className="update-button">
                        <div onClick={showDrawer}>
                          <ChatButton
                            RefId={selectedQuote?.RefId}
                            showDrawer={showDrawer}
                            color={"white"}
                          />
                        </div>
                      </button>

                      {rFQForQuotation == null ? (
                        <button className="update-button" onClick={handleCreateRFQ}>
                          Send to Suppliers{" "}
                        </button>
                      ) : (
                        <button
                          className="update-button"
                          onClick={handleGoToRFQClick}
                        >
                          Go To RFQ{" "}
                        </button>
                      )}
                      <button
                        className="update-button"
                        onClick={handleTeamModalShow}
                      >
                        Team
                      </button>
                      <button
                        className="update-button"
                        onClick={handleCancelQuoteClick}
                      >
                        Cancel Quote{" "}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="update-button"
                        onClick={handleMoveToProgress}
                      >
                        Move to Progress
                      </button>
                    </>
                  )}

                  <button className="update-button" onClick={handleBack}>
                    Back{" "}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <h5>Loading</h5>
          )}
        </div>
      </span>
    </div>
  );
};
export default AdminQuote;
