import {
  Box3,
  Vector3,
} from "three";
import TWEEN from "@tweenjs/tween.js";

export const setCameraPosition = (aabb, camera, controls, view, up) => {
  if(!aabb) {
    aabb = new Box3();
    aabb.set(new Vector3(-10, -10, -10), new Vector3(10, 10, 10));
  }
  if(!view) {
    view = new Vector3(1, 1, 1).normalize();
  }
  if(!up) {
    up = new Vector3(0, 1, 0);
  }

  const size = aabb.getSize(new Vector3()).length();
  const center = aabb.getCenter(new Vector3());

  controls.minZoom = 100 / size;
  controls.panSpeed = 25 / size;
  controls.rotateSpeed = 2.5;

  camera.updateProjectionMatrix();

  const duration = 500;
  const offsetFactor = 0.15;

  const camPos = camera.position.clone();
  const camPosEnd = center.clone().add(view.clone().setLength(size*10)).add(new Vector3(0, -offsetFactor*size, 0));
  new TWEEN.Tween(camPos)
    .to(camPosEnd, duration)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .onUpdate(() => {
      camera.position.copy(camPos);
      camera.updateProjectionMatrix();
    })
    .start();

  const camTarget = controls.target.clone();
  const camTargetEnd = center.clone().add(new Vector3(0, -offsetFactor*size, 0));
  new TWEEN.Tween(camTarget)
    .to(camTargetEnd, duration)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .onUpdate(() => {
      controls.target.copy(camTarget);
      camera.updateProjectionMatrix();
    })
    .start();

  const zf = {
    zoom: camera.zoom,
    far: camera.far,
  }
  const zfEnd = {
    zoom: 500 / size,
    far: size * 10 * 5,
  }
  new TWEEN.Tween(zf)
    .to(zfEnd, duration)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .onUpdate(() => {
      camera.zoom = zf.zoom;
      camera.far = zf.far;
      camera.updateProjectionMatrix();
    })
    .start();

  const camUp = camera.up.clone();
  const camUpEnd = up;
  new TWEEN.Tween(camUp)
    .to(camUpEnd, duration)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .onUpdate(() => {
      camera.up.copy(camUp);
      camera.updateProjectionMatrix();
    })
    .start();
};
