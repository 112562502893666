import { withStyles } from '@material-ui/core/styles';
import ToggleButton from "@material-ui/lab/ToggleButton";

const MyToggleButton = withStyles({
  root: {
    backgroundColor: "rgba(250, 250, 250, 0.85)",
    '&:hover': {
      backgroundColor: "rgba(225, 225, 225, 0.85)",
    }
  },
  selected: {
    backgroundColor: "rgba(200, 200, 200, 0.85) !important",
  }
})(ToggleButton);
export default MyToggleButton;
