import { useState, useEffect, useContext, useReducer } from "react";
import { getAdminUsers } from "../../utils/actions/allactions";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-paginate";
import waitingImage from "../../images/waiting.svg";
import tickGreenImage from "../../images/tick-green.svg";
import settingImage from "../../images/setting-green.svg";
import cancelledImage from "../../images/close_red.svg";
import HoverInfo from "../../components/HoverInfo/HoverInfo";
import { ADMIN_ACTIONS, useAdminContext } from "../../context/admin.context";
import {
  changeRFQAdminOwner,
  fetchFilteredRFQs,
  fetchRfqForProjectNameSearch,
} from "../../utils/actions/supplierActions";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import { ChatRefContext } from "../../context/chatRef.context";
import { currencyFormatter, validateAdmin } from "../../utils/helper";
import { Form } from "react-bootstrap";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { Loading } from "../../components/Loading";
import allValuesImage from "../../images/all-entity.svg";
import { fetchQuotationDataForRFQ } from "../../utils/actions/adminActions";

const RFQs = () => {
  const navigate = useNavigate();
  useDocumentTitle("RFQs");
  const [allRFQs, setAllRFQs] = useState([]);

  const { state, dispatch } = useAdminContext();
  const { setSelectedChatRefId, selectedChatRefId, setChatType, chatType } =
    useContext(ChatRefContext);
  const [adminUsers, setAdminUsers] = useState([]);
  const [directFilterVal, setDirectFilterVal] = useState("");
  const [directRFQResult, setDirectRFQResult] = useState([]);
  const [loading, setLoading] = useState(false);
  let token = localStorage.getItem("Token");

  const initialQuoteState = {
    createdFrom: "",
    createdTo: "",
    userName: "",
    adminOwnerId: "",
    sortBy: "createdAt",
    sortOrder: 1,
    pageNum: 1,
    totalPages: 0,
    quoteList: [],
    quotationCount: {},
  };

  const QUOTE_ACTIONS = {
    UPDATE_CREATED_FROM: "update-created-from",
    UPDATE_CREATED_TO: "update-created-to",
    UPDATE_USER_NAME: "update-user-name",
    UPDATE_ADMIN_OWNER_ID: "update-owner-id",
    UPDATE_SORT_BY: "update-sort-by",
    UPDATE_SORT_ORDER: "update-sort-order",
    UPDATE_PAGE_NUM: "update-page-num",
    UPDATE_TOTAL_PAGE: "update-total-page",
    UPDATE_QUOTE_LIST: "update-quotes",
    UPDATE_QUOTE_COUNT: "update-quote-count",
    UPDATE_QUOTE_FILTER: "update-quote-filter",
  };

  const quotationReducer = (state, action) => {
    switch (action.type) {
      case QUOTE_ACTIONS.UPDATE_CREATED_FROM:
        return { ...state, createdFrom: action.payload };
      case QUOTE_ACTIONS.UPDATE_CREATED_TO:
        return { ...state, createdTo: action.payload };
      case QUOTE_ACTIONS.UPDATE_USER_NAME:
        return { ...state, userName: action.payload };
      case QUOTE_ACTIONS.UPDATE_ADMIN_OWNER_ID:
        return { ...state, adminOwnerId: action.payload };
      case QUOTE_ACTIONS.UPDATE_SORT_BY:
        return { ...state, sortBy: action.payload };
      case QUOTE_ACTIONS.UPDATE_SORT_ORDER:
        return { ...state, sortOrder: action.payload };
      case QUOTE_ACTIONS.UPDATE_PAGE_NUM:
        return { ...state, pageNum: action.payload };
      case QUOTE_ACTIONS.UPDATE_TOTAL_PAGE:
        return { ...state, totalPages: action.payload };
      case QUOTE_ACTIONS.UPDATE_QUOTE_LIST:
        return { ...state, quoteList: action.payload };
      case QUOTE_ACTIONS.UPDATE_QUOTE_COUNT:
        return { ...state, quotationCount: action.payload };
      case QUOTE_ACTIONS.UPDATE_QUOTE_FILTER:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };

  const [quotationState, quotationDispatch] = useReducer(
    quotationReducer,
    initialQuoteState
  );

  useEffect(() => {
    validateAdmin(token, navigate);
    if (token) {
      async function fetchAdminUser() {
        try {
          let response = await getAdminUsers(token);
          setAdminUsers(response);
        } catch (error) {
          toast.error(error);
        }
      }
      fetchAdminUser();
    }
  }, []);

  useEffect(() => {
    // handleSubmit();
    const fetchData = async () => {
      try {
        setLoading(true);
        let data = {
          createdFrom: quotationState.createdFrom,
          createdTo: quotationState.createdTo,
          userName: quotationState.userName,
          adminOwnerId: quotationState.adminOwnerId,
          sortBy: quotationState.sortBy,
          sortOrder: quotationState.sortOrder,
          pageNum: quotationState.pageNum,
          quotationFilter: state.rfqActiveFilter,
        };
        const response = await fetchFilteredRFQs(token, data);

        let quotationIdArr = [];
        response.data.quotations.forEach((item) =>
          quotationIdArr.push(item.quotationId)
        );

        const quotationDataResponse = await fetchQuotationDataForRFQ(
          token,
          quotationIdArr
        );

        if (quotationDataResponse.status) {
          response.data.quotations.forEach((rfqItem) => {
            let attachedQuotation = quotationDataResponse.data.filter(
              (quotationItem) => quotationItem._id === rfqItem.quotationId
            )[0];
            if (attachedQuotation) {
              rfqItem.quotationId = attachedQuotation;
            }
            // })
          });
        }

        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_QUOTE_LIST,
          payload: response?.data?.quotations,
        });
        let quotationBucketCount = response?.data?.quoteCategoryCount;
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_QUOTE_COUNT,
          payload: quotationBucketCount,
        });
        let totalPages = Math.ceil(
          Number(
            state.rfqActiveFilter == "active-rfq"
              ? quotationBucketCount.activeCount
              : state.rfqActiveFilter == "progress"
                ? quotationBucketCount.progressCount
                : state.rfqActiveFilter == "completed"
                  ? quotationBucketCount.completeCount
                  : state.rfqActiveFilter == "cancelled"
                    ? quotationBucketCount.cancelledCount
                    : quotationBucketCount.allCount
          ) / 10
        );

        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_TOTAL_PAGE,
          payload: totalPages || 0,
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log("err", err);
      }
    };

    fetchData();
  }, [
    quotationState.createdFrom,
    quotationState.createdTo,
    quotationState.userName,
    quotationState.adminOwnerId,
    quotationState.sortBy,
    quotationState.sortOrder,
    quotationState.pageNum,
    state.rfqActiveFilter,
  ]);

  const showDrawer = (id) => {
    setSelectedChatRefId(id);
    setChatType("supplier");
  };

  const onClose = () => {
    setChatType(null);
    setSelectedChatRefId(null);
  };

  const handleAdminOwnerChange = async (e, rfqId) => {
    try {
      let adminUserSelected = adminUsers.filter(
        (item) => item._id == e.target.value
      )[0];
      let adminResponse = confirm(
        `Are you sure you want to assign this quotation to ${adminUserSelected.firstName + " " + adminUserSelected.lastName
        } ? `
      );
      if (!adminResponse) {
        return;
      }
      let data = {
        rfqId: rfqId,
        adminId: e.target.value,
      };
      let rfqResponse = await changeRFQAdminOwner(token, data);
      if (rfqResponse.status) {
        let localQuotationList = [...quotationState.quoteList];
        localQuotationList.forEach((item) => {
          if (item._id == rfqId) {
            item.adminOwnerId = adminUserSelected._id;
          }
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_QUOTE_LIST,
          payload: localQuotationList,
        });
        fetchData();
        toast.success("Admin owner changed successfully.");
      }
    } catch (err) {
      console.log(
        "Error in 86 Quotes on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  let handleActivePage = async (value) => {
    let pageNum = value?.selected + 1 || 1;
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_PAGE_NUM,
      payload: pageNum,
    });
  };

  const handleBucketFilterClick = (field) => {
    dispatch({
      type: ADMIN_ACTIONS.UPDATE_RFQ_ACTIVE_FILTER,
      payload: field,
    });

    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_QUOTE_FILTER,
      payload: {
        createdFrom: "",
        createdTo: "",
        userName: "",
        adminOwnerId: "",
        sortBy: "",
        sortOrder: 1,
        pageNum: 1,
        totalPages: 0,
      },
    });
  };

  let handleRefresh = () => {
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_QUOTE_FILTER,
      payload: {
        createdFrom: "",
        createdTo: "",
        userName: "",
        adminOwnerId: "",
        sortBy: "createdAt",
        sortOrder: -1,
        pageNum: 1,
        totalPages: 0,
      },
    });
  };

  let handleSort = (field) => {
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_SORT_BY,
      payload: field,
    });

    let sortStatus =
      quotationState.sortBy == field
        ? quotationState.sortOrder == 1
          ? -1
          : 1
        : 1;
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_SORT_ORDER,
      payload: sortStatus,
    });
  };

  const handleRFQSearch = async (e) => {
    try {
      setDirectFilterVal(e.target.value);
      if (!e.target.value) {
        setDirectRFQResult([]);
        return;
      }
      let rfqResponse = await fetchRfqForProjectNameSearch(
        token,
        e.target.value
      );
      if (rfqResponse.status) {
        setDirectRFQResult(rfqResponse.data);
      } else {
        setDirectRFQResult([]);
        toast.error("Something went wrong while fetching the data.");
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <div
      className="admin-container"
      style={{
        width: "100%",
        overflowX: "auto",
        backgroundColor: "#f4e6dd",
        minHeight: "100vh",
      }}
    >
      <Sidebar />
      <div style={{ height: "auto" }}>{loading ? <Loading /> : ""}</div>
      <span style={{ width: "100%" }}>
        <div className="center-heading">
          <h1 className="text-2xl font-bold mt-3">RFQs</h1>
          <div className="right-container ">
            <div className="bucketFilterDiv">
              <div
                className={`bucketFilterOptions ${state.rfqActiveFilter == "all-rfq" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("all-rfq")}
              >
                <span className="textBox">
                  <p>All RFQ</p>
                  <h5>{quotationState?.quotationCount?.allCount}</h5>
                </span>
                <img className="filterIcon" src={allValuesImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.rfqActiveFilter == "active-rfq" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("active-rfq")}
              >
                <span className="textBox">
                  <p>Active RFQ</p>
                  <h5>{quotationState?.quotationCount?.activeCount || 0}</h5>
                </span>
                <img className="filterIcon" src={waitingImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.rfqActiveFilter == "progress" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("progress")}
              >
                <span className="textBox">
                  <p>In Progress RFQ</p>
                  <h5>{quotationState?.quotationCount?.progressCount || 0}</h5>
                </span>
                <img className="filterIcon" src={settingImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.rfqActiveFilter == "completed" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("completed")}
              >
                <span className="textBox">
                  <p>Completed RFQ</p>
                  <h5>{quotationState?.quotationCount?.completeCount || 0}</h5>
                </span>
                <img className="filterIcon" src={tickGreenImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.rfqActiveFilter == "cancelled" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("cancelled")}
              >
                <span className="textBox">
                  <p>Cancelled RFQ</p>
                  <h5>{quotationState?.quotationCount?.cancelledCount || 0}</h5>
                </span>
                <img className="filterIcon" src={cancelledImage} />
              </div>
            </div>

            <div className="search-bar">
              <div
                className="row"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <div className="search-block">
                  <label className="sr-label">Admin Owner</label>
                  <Form.Select
                    aria-label="Default select example"
                    value={quotationState?.adminOwnerId || "default"}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      dispatch({
                        type: ADMIN_ACTIONS.UPDATE_RFQ_ACTIVE_FILTER,
                        payload: "all-rfq",
                      });
                      quotationDispatch({
                        type: QUOTE_ACTIONS.UPDATE_ADMIN_OWNER_ID,
                        payload: e.target.value,
                      });
                    }}
                  >
                    <option value="default" disabled>
                      {" "}
                      Select owner
                    </option>
                    {adminUsers.map((val, i) => {
                      return (
                        <option key={i} value={val._id}>
                          {val.firstName + " " + val.lastName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>

                <div className="search-block">
                  <label className="sr-label">Ref ID</label>

                  <input
                    type="text"
                    className="form-control"
                    value={directFilterVal}
                    placeholder="Search Ref ID"
                    onChange={handleRFQSearch}
                  />
                  {directFilterVal?.trim()?.length > 0 &&
                    directRFQResult.length > 0 ? (
                    <div className="direct-search-drop-down-div ">
                      <table className="search-table">
                        <thead>
                          <tr>
                            <th>Project Name</th>
                            <th>Created Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {directRFQResult.length > 0 &&
                            directRFQResult?.map((val, i) => {
                              return (
                                <tr
                                  key={i}
                                  className="direct-search-drop-down"
                                  onClick={() =>
                                    window.open(
                                      `/admin/rfq-details/${val?._id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  <td style={{ textAlign: "left" }}>
                                    {val?.projectName}
                                  </td>
                                  <td>
                                    {moment(val.createdAt).format(
                                      "DD MMM YYYY"
                                    )}
                                  </td>
                                  <td>{val.rfqStatus}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="search-block">
                  <label className="sr-label">RFQ Created From</label>
                  <input
                    type="date"
                    value={quotationState.createdFrom}
                    className="form-control"
                    onChange={(e) => {
                      dispatch({
                        type: ADMIN_ACTIONS.UPDATE_RFQ_ACTIVE_FILTER,
                        payload: "all-rfq",
                      });
                      quotationDispatch({
                        type: QUOTE_ACTIONS.UPDATE_CREATED_FROM,
                        payload: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="search-block">
                  <label className="sr-label">RFQ Created To</label>
                  <input
                    type="date"
                    value={quotationState.createdTo}
                    className="form-control"
                    onChange={(e) => {
                      dispatch({
                        type: ADMIN_ACTIONS.UPDATE_RFQ_ACTIVE_FILTER,
                        payload: "all-rfq",
                      });
                      quotationDispatch({
                        type: QUOTE_ACTIONS.UPDATE_CREATED_TO,
                        payload: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="search-btn" style={{ marginTop: "10px" }}>
                  <button onClick={handleRefresh} className="button-search">
                    Reset all filters
                  </button>
                </div>
              </div>
            </div>
            <Chat
              RefId={selectedChatRefId}
              open={selectedChatRefId?.length > 0}
              onDrawerClose={onClose}
            />
            <div className="quotes-table">
              <table className="table">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("projectName")}
                    >
                      Project Name{" "}
                      {quotationState.sortBy == "projectName"
                        ? quotationState.sortOrder == 1
                          ? "↓"
                          : "↑"
                        : "↓"}
                    </th>
                    <th scope="col" style={{ cursor: "pointer" }}>
                      {" "}
                      Admin Owner{" "}
                    </th>
                    <th scope="col" style={{ cursor: "pointer" }}>
                      (Quote)Created At{" "}
                    </th>
                    <th scope="col" style={{ cursor: "pointer" }}>
                      Days(Quote)
                    </th>
                    <th
                      scope="col"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort("createdAt")}
                    >
                      (RFQ)Created At{" "}
                      {quotationState.sortBy == "createdAt"
                        ? quotationState.sortOrder == 1
                          ? "↓"
                          : "↑"
                        : "↓"}
                    </th>
                    <th scope="col" style={{ cursor: "pointer" }}>
                      Days(RFQ){" "}
                    </th>
                    <th scope="col" style={{ cursor: "pointer" }}>
                      Cost 1
                    </th>
                    <th scope="col" style={{ cursor: "pointer" }}>
                      Cost 2{" "}
                    </th>
                    <th scope="col" style={{ cursor: "pointer" }}>
                      Cost 3
                    </th>
                    {/* <th scope="col">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {quotationState.quoteList?.length > 0 &&
                    quotationState.quoteList?.map((item, index) => {
                      return (
                        <tr key={index} style={{ backgroundColor: "#fff" }}>
                          <td
                          >
                            <div className="flex justify-start items-center gap-x-2  ">

                              <Link to={`/admin/rfq-details/${item?._id}`} className="text-primaryColor">
                                {item.projectName
                                  ? item.projectName
                                  : "Project name not available"}
                              </Link>{" "}
                              <HoverInfo content={item.adminComment} />
                            </div>
                            {state.rfqActiveFilter == "all-rfq" &&
                              item?.rfqStatus ? (
                              <div className="ms-2">
                                <br />
                                {`(${item?.rfqStatus})`}
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              value={item?.adminOwnerId || "default"}
                              style={{ width: "100%", marginTop: "-10px" }}
                              onChange={(e) => {
                                handleAdminOwnerChange(e, item._id);
                              }}
                            >
                              <option value="default" disabled>
                                {" "}
                                Select owner
                              </option>
                              {adminUsers.map((val, i) => {
                                return (
                                  <option key={i} value={val._id}>
                                    {val.firstName + " " + val.lastName}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </td>
                          <td>
                            {moment(item?.quotationId?.createdAt).format(
                              "DD MMM YYYY"
                            )}
                          </td>
                          <td>
                            {Math.abs(
                              moment(item?.quotationId?.createdAt).diff(
                                Date.now(),
                                "days"
                              )
                            )}
                          </td>
                          <td>
                            {moment(item.createdAt).format("DD MMM YYYY")}
                          </td>

                          <td>
                            {Math.abs(
                              moment(item?.createdAt).diff(Date.now(), "days")
                            )}
                          </td>
                          <td>
                            <span>
                              {item?.supplierRFQData?.length >= 1 ? (
                                <>
                                  {item?.supplierRFQData[0]?.isDeclined ? (
                                    <>
                                      <span
                                        style={{
                                          color: "red",
                                          fontWeight: "bolder",
                                        }}
                                      >
                                        Declined
                                      </span>
                                      <br />
                                    </>
                                  ) : (
                                    <>
                                      <span>
                                        {currencyFormatter(
                                          Number(
                                            item.supplierRFQData[0].price
                                          ).toFixed(2)
                                        )}
                                      </span>
                                      <br />
                                      <span>{`${item.supplierRFQData[0]?.leadTime
                                        }D (${Math.floor(
                                          item.supplierRFQData[0]?.leadTime / 7
                                        )}-${Math.floor(
                                          item.supplierRFQData[0]?.leadTime / 7
                                        ) + 1
                                        } weeks)`}</span>
                                      <br />
                                    </>
                                  )}

                                  <span className="whitespace-nowrap">
                                    {
                                      item.supplierRFQData[0]?.supplierId
                                        ?.supplierRefId
                                    }{" "}
                                    - (
                                    {
                                      item.supplierRFQData[0]?.supplierId
                                        ?.address?.country
                                    }
                                    )
                                  </span>
                                  <br />
                                  <span
                                    onClick={() =>
                                      showDrawer(
                                        item.supplierRFQData[0]
                                          ?.supplierRFQRefId
                                      )
                                    }
                                    type="button"
                                    className="up-button bg-primaryColor h-7 mt-2"
                                  >
                                    <ChatButton
                                      RefId={
                                        item.supplierRFQData[0]
                                          ?.supplierRFQRefId
                                      }
                                      showDrawer={showDrawer}
                                    />
                                  </span>
                                </>
                              ) : (
                                <>
                                  <p>NA</p>
                                </>
                              )}
                            </span>
                          </td>
                          <td>
                            <span>
                              {item?.supplierRFQData?.length >= 2 ? (
                                <>
                                  {item.supplierRFQData[1].isDeclined ? (
                                    <>
                                      <span
                                        style={{
                                          color: "red",
                                          fontWeight: "bolder",
                                        }}
                                      >
                                        Declined
                                      </span>
                                      <br />
                                    </>
                                  ) : (
                                    <>
                                      <span>
                                        {currencyFormatter(
                                          Number(
                                            item.supplierRFQData[1].price
                                          ).toFixed(2)
                                        )}
                                      </span>
                                      <br />
                                      <span>{`${item.supplierRFQData[1]?.leadTime
                                        }D (${Math.floor(
                                          item.supplierRFQData[1]?.leadTime / 7
                                        )}-${Math.floor(
                                          item.supplierRFQData[1]?.leadTime / 7
                                        ) + 1
                                        } weeks)`}</span>
                                      <br />
                                    </>
                                  )}

                                  <span className="whitespace-nowrap">
                                    {
                                      item.supplierRFQData[1]?.supplierId
                                        ?.supplierRefId
                                    }{" "}
                                    - (
                                    {
                                      item.supplierRFQData[1]?.supplierId
                                        ?.address?.country
                                    }
                                    )
                                  </span>
                                  <br />
                                  <span
                                    onClick={() =>
                                      showDrawer(
                                        item.supplierRFQData[1]
                                          ?.supplierRFQRefId
                                      )
                                    }
                                    type="button"
                                    // className="up-button"
                                    // style={{
                                    //   marginTop: "3px 0px",
                                    //   textAlign: "center",
                                    //   background: "white",
                                    //   color: "#2B96DC",
                                    //   width: "100px",
                                    //   border: "1px solid #a8e5ff",
                                    // }}
                                    className="up-button bg-primaryColor h-7 mt-2"
                                  >
                                    <ChatButton
                                      RefId={
                                        item.supplierRFQData[1]
                                          ?.supplierRFQRefId
                                      }
                                      showDrawer={showDrawer}
                                    />
                                  </span>
                                </>
                              ) : (
                                <>
                                  <p>NA</p>
                                </>
                              )}
                            </span>
                          </td>
                          <td>
                            <span>
                              {item?.supplierRFQData?.length >= 3 ? (
                                <>
                                  {item.supplierRFQData[2].isDeclined ? (
                                    <>
                                      <span
                                        style={{
                                          color: "red",
                                          fontWeight: "bolder",
                                        }}
                                      >
                                        Declined
                                      </span>
                                      <br />
                                    </>
                                  ) : (
                                    <>
                                      <span>
                                        {currencyFormatter(
                                          Number(
                                            item.supplierRFQData[2].price
                                          ).toFixed(2)
                                        )}
                                      </span>
                                      <br />
                                      <span className="!text-[12px]">{`${item.supplierRFQData[2]?.leadTime
                                        }D (${Math.floor(
                                          item.supplierRFQData[2]?.leadTime / 7
                                        )}-${Math.floor(
                                          item.supplierRFQData[2]?.leadTime / 7
                                        ) + 1
                                        } weeks)`}</span>
                                      <br />
                                    </>
                                  )}

                                  <span>
                                    {
                                      item.supplierRFQData[2]?.supplierId
                                        ?.supplierRefId
                                    }{" "}
                                    - (
                                    {
                                      item.supplierRFQData[2]?.supplierId
                                        ?.address?.country
                                    }
                                    )
                                  </span>
                                  <br />
                                  <span
                                    onClick={() =>
                                      showDrawer(
                                        item.supplierRFQData[2]
                                          ?.supplierRFQRefId
                                      )
                                    }
                                    type="button"
                                    className="up-button bg-primaryColor h-7 mt-2  "


                                  >
                                    <ChatButton
                                      RefId={
                                        item.supplierRFQData[2]
                                          ?.supplierRFQRefId
                                      }
                                      showDrawer={showDrawer}
                                    />
                                  </span>
                                </>
                              ) : (
                                <>
                                  <p>NA</p>
                                </>
                              )}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="floatRight">
              <Pagination
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                pageCount={quotationState?.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handleActivePage}
                containerClassName="pagination"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                previousLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
              />
            </div>
          </div>
        </div>
      </span>
    </div>
  );
};

export default RFQs;
