import { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { getMaterialMaster } from "../../utils/actions/allactions";
import { useNavigate } from "react-router-dom";
import { getToken, validateAdmin } from "../../utils/helper";
import useDocumentTitle from "../../utils/useDocumentTitle";
import AdminLoadingComponent from "../../components/LoadingComponents/AdminLoadingComponent";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";
const MaterialMaster = () => {
  let navigate = useNavigate();
  useDocumentTitle("Material Master");
  const [data, setData] = useState(null);
  let token = getToken();

  const fetchMaterialData = async () => {
    try {
      const materialMasterData = await getMaterialMaster(token);
    setData(materialMasterData);
      
    } catch (error) {
        toast.error(`Unable To Fetch Get Material Master  `)
    }
    
  };

  useEffect(() => {
    validateAdmin(token, navigate);
    fetchMaterialData();
  }, []);

  const handleCreateNew = () => {
    navigate("/admin/add-material-master");
  };

  const handleEditMaterialMaster = (id) => {
    navigate(`/admin/edit-material-master?id=${id}`);
  };
  
  return (
    <div className="admin-container">
      <div className="right-container">
        <div className="center-heading">
          <h1 className="text-2xl font-bold "> Material Master </h1>
          <div className="search-bar">
            <div className="row">
              <div className="col-md-9"></div>
              <div className="col-md-3">
                <div className="search-btn">
                  <button onClick={handleCreateNew} className="pr-edit-btn">
                    Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="quotes-table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Name{" "}
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Is3DPrinting
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    IsMachining
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    3DMaterialCost
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    3DMaterialDensity
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    3DExcessVolume
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    3DPrintingHourly
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    3DPrintingRate
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    3DRecoatingRate
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {data ? (
                <tbody>
                  {data.map((item, i) => {
                    console.log(item);
                    return (
                      <tr key={i}>
                        <td>{item.name || ""}</td>
                        <td>{String(!!item.is3DPrinting).toUpperCase()}</td>
                        <td>{String(!!item.isMachining).toUpperCase()}</td>
                        <td>{item.threeDMaterialCost || "NA"}</td>
                        <td>{item.threeD_Material_Density || "NA"}</td>
                        <td>{item.threeDExcessVolume || "NA"}</td>
                        <td>{item.threeDPrintingHourly || "NA"}</td>
                        <td>
                          {item.threeDPrintingRate
                           || "NA"}
                        </td>
                        <td>{item.threeDRecoatingRate || "NA"}</td>
                        <td>
                          {" "}
                          <Button onClickFunction={()=>handleEditMaterialMaster(item._id)} text={'Edit / Deactivate'} additionalClassName={' bg-yellow-500 shadow-sm text-white rounded'} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <AdminLoadingComponent rows={12} columns={10} />
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardComponents(MaterialMaster);
