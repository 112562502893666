import React, { useEffect, useRef } from "react";

const AutoResizingTextarea = ({ value, onChange, name, maxLength = 200 }) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [value]);

    return (
        <div className="flex flex-col items-center p-2">
            <textarea
                ref={textareaRef}
                value={value}
                onChange={(e) => {
                    if (e.target.value.length <= maxLength) {
                        onChange(e);
                    }
                }}
                name={name}
                className="w-full max-w-lg p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none overflow-hidden"
                placeholder={`Type up to ${maxLength} characters...`}
            />
            {/* <div className="text-sm text-gray-500 mt-1">
                {value.length}/{maxLength} characters
            </div> */}
        </div>
    );
};

export default AutoResizingTextarea;
