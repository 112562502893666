import React, { useContext, useState } from "react";
// import { OrdersContext } from '../context/orders.context';
import { processType } from "../../utils/constant";
import moment from "moment";
import ChatButton from "../../Chat/ChatButton";
import { ChatRefContext } from "../../context/chatRef.context";
import Chat from "../../Chat/index";
import { currencyFormatter } from "../../utils/helper";
import tickwhite from "../../images/tick-white.svg";
import teamImage from "../../images/group-svgrepo-com.svg";
import editImage from "../../images/edit.svg";
import downloadImage from "../../images/download.png";
import {
  downloadPdf,
  handleCommonDeleteAddressForUser,
} from "../../utils/actions/allactions";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import fileSaver from "file-saver";
import { config } from "../../environment/development";
import { Link } from "react-router-dom";
import redCancelImage from "../../images/close_red.svg";
import tickGreenImage from "../../images/tick-green.svg";
import { handleProjectNameChange } from "../../utils/actions/commanActions";
import TeamCollaboration from "../TeamCollaboration/TeamCollaboration";
import GlobalProjectNameComponent from "../GlobalProjectNameComponent/index";
import { BrainMachine, CalenderIcon, DownloadIcon, UserIcon } from "../Icons";

const MyQuoteTable = (props) => {
  let token = localStorage.getItem("Token");
  const {
    quotationsList,
    selectedShipMethodMap,
    myQuotesDispatch,
    MY_QUOTE_ACTIONS,
    addressValues,
    setAddressValues,
    setShowAddressModal,
    setIsEdit,
    setEditId,
    fetchQuotesForUser,
    myQuotesState,
    versionData,
    activeTab,
  } = props;
  const { selectedChatRefId, setSelectedChatRefId } =
    useContext(ChatRefContext);
  const [teamMemberModalShow, setTeamMemberModalShow] = useState(false);
  const [selectedQuoteForTeam, setSelectedQuoteForTeam] = useState({});
  const [showDQModal, setShowDQModal] = useState(false);
  const [selectedQuoteForDownload, setSelectedQuoteForDownload] = useState("");
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    companyName: "",
    vatNumber: "",
    addresses: "",
    finalTotalPrice: "",
    addressline1: "",
    addressline2: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    subTotal: "",
  });
  const [editIndex, setEditIndex] = useState(-1);
  let email = localStorage.getItem("email");

  const showDrawer = (id) => {
    setSelectedChatRefId(id);
    let userId = localStorage.getItem('_id');
    if (window.location.host === 'make.8xparts.com') {

      // window?.dataLayer?.push({
      //   event: 'chatOpen',
      //   userId: userId,
      //   quoteRefId: id,
      //   referenceId: email?.split('@')[0] + email?.split('@')[1]?.split(".")[0],
      // });
    }
  };

  const onClose = () => {
    setSelectedChatRefId(null);
  };

  const handleShipMethodChange = (id, shipMethodAtIndex) => {
    let localShipMethodMap = selectedShipMethodMap;
    localShipMethodMap.set(id, shipMethodAtIndex);
    myQuotesDispatch({
      type: MY_QUOTE_ACTIONS.UPDATE_SELECTED_SHIP_MAP,
      payload: localShipMethodMap,
    });
  };

  const handleTeamModal = async (selectedQuote) => {
    let userId = localStorage.getItem('_id');
    if (window.location.host === 'make.8xparts.com') {
      // window?.dataLayer?.push({
      //   event: 'teamCollaboration',
      //   userId: userId,
      //   quoteRefId: selectedQuote.RefId,
      //   referenceId: email?.split('@')[0] + email?.split('@')[1]?.split(".")[0],
      // });
    }

    setSelectedQuoteForTeam(selectedQuote);
    setTeamMemberModalShow(true);
  };

  const updateQuoteData = (updatedQuote) => {
    let localQuotationsList = [...quotationsList];

    localQuotationsList.forEach((item) => {
      if (item.RefId == updatedQuote.RefId) {
        item.followers = updatedQuote.followers;
      }
    });
    myQuotesDispatch({
      type: MY_QUOTE_ACTIONS.UPDATE_QUOTES_TO_SHOW,
      payload: localQuotationsList,
    });
  };

  const addNewHandler = () => {
    setShowAddressModal(true);
    setShowDQModal(false);
  };

  const editHandler = (index) => {
    setShowAddressModal(true);
    setIsEdit(true);
    setEditId(index);
    setShowDQModal(false);
  };

  const deleteData = async (i) => {
    if (i > -1) {
      const addressToDelete = addressValues.address[i];
      let localAllAddress = addressValues?.address
        ? [...addressValues.address]
        : [];
      const addressDeleteResponse = await handleCommonDeleteAddressForUser(
        token,
        addressToDelete._id
      );
      if (addressDeleteResponse.status) {
        localAllAddress.splice(i, 1);
        setAddressValues({ ...addressValues, address: localAllAddress });
      }
    }
  };

  let handleDownload = () => {
    if (!addressValues.shippingAddress) {
      toast.error(
        "Please select a valid shipping address to download quotation."
      );
      return;
    }
    let index = -1;
    for (let i = 0; i < quotationsList?.length; i++) {
      if (quotationsList[i]._id == selectedQuoteForDownload._id) {
        index = i;
        break;
      }
    }

    if (
      (selectedShipMethodMap.get(quotationsList[index]._id) == 0 &&
        !quotationsList[index].price) ||
      (selectedShipMethodMap.get(quotationsList[index]._id) == 1 &&
        !quotationsList[index].price1) ||
      (selectedShipMethodMap.get(quotationsList[index]._id) == 2 &&
        !(
          quotationsList[index].stManualPrice ||
          quotationsList[index].sheetAndInjectionManualPrice
        ))
    ) {
      toast.error(
        "Quotation price is not updated.Please contact admin through to get the pricing."
      );
      return;
    }

    if (
      quotationsList[index].isUpdatedByAdmin &&
      !quotationsList[index].isSent &&
      !quotationsList[index].myorders
    ) {
      toast.error(
        "Quotation price is not updated.Please contact admin through to get the pricing."
      );
      return;
    }


    let userId = localStorage.getItem('_id');
    if (window.location.host === 'make.8xparts.com') {
      // window?.dataLayer?.push({
      //   event: 'downloadQuote',
      //   userId: userId,
      //   quoteRefId: quotationsList[index].RefId,
      //   referenceId: email?.split('@')[0] + email?.split('@')[1]?.split(".")[0],
      // });
    }

    let data = {
      fname: addressValues.firstName,
      lname: addressValues.lastName,
      email: localStorage.getItem("email"),
      quoteId: quotationsList[index].RefId,
      total: quotationsList[index].price,
      addressline1: addressValues.addressline1,
      addressline2: addressValues.addressline2,
      city: addressValues.city,
      state: addressValues.state,
      country: addressValues.country,
      zipcode: addressValues.zipCode,
      partData: JSON.stringify(quotationsList[index].partsData),
      process: quotationsList[index].selectedProcess,
      price: quotationsList[index].price,
      price1: quotationsList[index].price1,
      price2: quotationsList[index].price2,
      selectedShipMethod: selectedShipMethodMap.get(
        selectedQuoteForDownload._id
      ),
      sheetMetalAndInjectionDesc:
        quotationsList[index].sheetAndInjectionDiscription,
      sheetMetalAndInjectionMP:
        quotationsList[index].sheetAndInjectionManualPrice,
      sheetAndInjectionQuantity:
        quotationsList[index].sheetAndInjectionQuantity,
      manualPrice: quotationsList[index].stManualPrice,
      forInvoice: false,
      hidePrice: quotationsList[index].hidePrice,
      isQrTrackingRequested: quotationsList[index].isQrTrackingRequested,
      isDimensionalReportRequested:
        quotationsList[index].isDimensionalReportRequested,
      isConformanceCertificateRequested:
        quotationsList[index].isConformanceCertificateRequested,
      additionalCost:
        selectedShipMethodMap.get(quotationsList[index]._id) == 0
          ? quotationsList[index].additionalCost1
          : selectedShipMethodMap.get(quotationsList[index]._id) == 1
            ? quotationsList[index].additionalCost2
            : quotationsList[index].additionalCost3,
      shippingCharge:
        selectedShipMethodMap.get(quotationsList[index]._id) == 0
          ? quotationsList[index].shippingCharge1
          : selectedShipMethodMap.get(quotationsList[index]._id) == 1
            ? quotationsList[index].shippingCharge2
            : quotationsList[index].shippingCharge3,
      leadTime:
        selectedShipMethodMap.get(quotationsList[index]._id) == 0
          ? quotationsList[index].leadTime1
          : selectedShipMethodMap.get(quotationsList[index]._id) == 1
            ? quotationsList[index].leadTime2
            : quotationsList[index].leadTime3,
      projectName: quotationsList[index].projectName,
      date: quotationsList[index].createdAt,
      version: quotationsList[index].version,
      quotationNotes: quotationsList[index].quotationNotes,
      quotationTnc: quotationsList[index].quotationTnc,
    };

    let priceValue =
      selectedShipMethodMap.get(quotationsList[index]._id) == 0
        ? quotationsList[index].price
        : selectedShipMethodMap.get(quotationsList[index]._id) == 1
          ? quotationsList[index].price1
          : quotationsList[index].selectedProcess <= 2
            ? quotationsList[index].stManualPrice
            : quotationsList[index].sheetAndInjectionManualPrice;
    let fileName = `${quotationsList[index].RefId}-V-${quotationsList[index].version
      }-${Number(priceValue).toFixed(2)}.pdf`;
    downloadPdf(data, token)
      .then((res) => {
        fileSaver.saveAs(
          `${config.backEnd}/public/docs/` + res?.data?.data,
          fileName
          // `quote-${quotationsList[index].RefId}.pdf`
        );
      })
      .catch((err) => {
        console.log(
          "error in  my quote 312  :: " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a a"),
          err
        );
      });
  };

  const handleVersionDropDownClick = (event, index) => {
    let quoteToChange = myQuotesState.quotesToShow[index];
    let localQuotationsList = myQuotesState.quotesToShow;
    for (let i = 0; i < localQuotationsList.length; i++) {
      if (localQuotationsList[i].RefId == quoteToChange.RefId) {
        let newQuoteData = myQuotesState.quotationsRelatedToRefIds.filter(
          (element) =>
            element.RefId == localQuotationsList[i].RefId &&
            element.version == event.target.value
        );
        if (newQuoteData.length == 0) {
          toast.error("Something went wrong please refresh the page.");
          return;
        } else {
          localQuotationsList[i] = newQuoteData[0];
        }
      }
    }
    myQuotesDispatch({
      type: MY_QUOTE_ACTIONS.UPDATE_QUOTES_TO_SHOW,
      payload: localQuotationsList,
    });
  };

  const handleEditClick = (index) => {
    if (editIndex !== -1) {
      alert("Cannot edit multiple projects at once");
      return;
    }

    let project = myQuotesState.quotesToShow[index];
    const firstUnderscoreIndex = project.projectName.indexOf("_");
    const editableText = project.projectName.substring(
      firstUnderscoreIndex + 1
    );
    setEditIndex(index);
    const updatedProjects = myQuotesState.quotesToShow.map((quote, i) =>
      i === index
        ? { ...quote, isEditing: true, previousProjectName: quote.projectName, editableProjectName: editableText }
        : quote
    );
    myQuotesDispatch({
      type: MY_QUOTE_ACTIONS.UPDATE_QUOTES_TO_SHOW,
      payload: updatedProjects,
    });
  };

  const handleEditableChange = (e, index) => {
    const { value } = e.target;
    const updatedProjects = myQuotesState.quotesToShow.map((quote, i) =>
      i === index ? { ...quote, editableProjectName: value } : quote
    );
    myQuotesDispatch({
      type: MY_QUOTE_ACTIONS.UPDATE_QUOTES_TO_SHOW,
      payload: updatedProjects,
    });
  };

  const handleUpdateClick = async (index) => {
    const updatedProjects = myQuotesState.quotesToShow.map((quote, i) => {
      if (i === index && quote.editableProjectName.trim() === "") {
        alert("Project name cannot be empty"); // Show an alert or handle the error message
        return quote; // Return the original quote without changes
      } else if (i === index) {
        return {
          ...quote,
          isEditing: false,
          projectName: `${quote.projectName.split("_")[0]
            }_${quote.editableProjectName.trim()}`,
        };
      }
      return quote;
    });

    let updatedProject = updatedProjects[index];
    let data = {
      RefId: updatedProject.RefId,
      projectName: updatedProject.projectName,
    };

    const response = await handleProjectNameChange(token, data);
    if (response.status) {
      toast.success(response.message);
      fetchQuotesForUser();
    } else {
      toast.error(response.message);
    }

    setEditIndex(-1);
  };

  const handleCancelClick = (index) => {
    const updatedProjects = myQuotesState.quotesToShow.map((quote, i) =>
      i === index
        ? { ...quote, isEditing: false, editableProjectName: "" }
        : quote
    );
    myQuotesDispatch({
      type: MY_QUOTE_ACTIONS.UPDATE_QUOTES_TO_SHOW,
      payload: updatedProjects,
    });
    setEditIndex(-1);
  };

  const renderProjectName = (project, index) => {
    const firstUnderscoreIndex = project.projectName.indexOf("_");
    const serialNumber = project.projectName.substring(0, firstUnderscoreIndex);
    const editableText = project.projectName.substring(
      firstUnderscoreIndex + 1
    );
    if (project.isEditing) {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              fontSize: "13px",
            }}
          >
            <span
              style={{
                fontSize: "13px",
                width: "100px",
              }}
            >
              {serialNumber}_
            </span>
            <Form.Control
              type="text"
              value={project.editableProjectName}
              onChange={(e) => handleEditableChange(e, index)}
              style={{
                margin: " 0px 4px",
                height: "25px",
                cursor: "pointer",
                fontSize: "13px",
              }}
            />
            <span onClick={() => handleUpdateClick(index)}>
              <img
                src={tickGreenImage}
                style={{
                  margin: " 0px 4px",
                  height: "13px",
                  cursor: "pointer",
                }}
              />
            </span>

            <span onClick={() => handleCancelClick(index)}>
              <img
                src={redCancelImage}
                style={{
                  margin: " 0px 4px",
                  height: "13px",
                  cursor: "pointer",
                }}
              />
            </span>
          </div>
        </>
      );
    }
    return (
      <>
        <Link to={`/quote-versions/${project.RefId}`}>
          <span>{serialNumber}_</span>
          <span>{editableText}</span>
        </Link>

        <span onClick={() => handleEditClick(index)}>
          <img
            src={editImage}
            style={{
              marginLeft: "10px",
              marginBottom: "4px",
              height: "16px",
              cursor: "pointer",
            }}
          />
        </span>
      </>
    );
  };

  return (
    <div className="table-container">
      <Chat
        RefId={selectedChatRefId}
        open={selectedChatRefId?.length > 0}
        onDrawerClose={onClose}
      />
      <TeamCollaboration
        teamMemberModalShow={teamMemberModalShow}
        selectedQuoteForTeam={selectedQuoteForTeam}
        setTeamMemberModalShow={setTeamMemberModalShow}
        fetchDataForUser={fetchQuotesForUser}
        updateQuoteData={updateQuoteData}
      />

      <Modal
        size="md"
        dialogClassName="d-flex align-items-center justify-content-center custom-modal-view-order2"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showDQModal}
        onHide={() => setShowDQModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" style={{ fontSize: '15px' }}>
            Download quotation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="address-bar mt-4">
            <h6>Select option for which you want to download the quotation:</h6>
            <div style={{ display: "flex", marginBottom: "20px" }}>
              {((selectedQuoteForDownload.isUpdatedByAdmin && selectedQuoteForDownload.isSent) || selectedQuoteForDownload.isRepeatOrderQuotation || selectedQuoteForDownload.myorders) ? (
                <>
                  <span className="price-tab-my-quote">
                    {selectedQuoteForDownload.price > 0 ? (
                      <div
                        className={
                          selectedShipMethodMap.get(
                            selectedQuoteForDownload._id
                          ) == 0
                            ? "box-pr pr-checked"
                            : "box-pr"
                        }
                        onClick={() =>
                          handleShipMethodChange(
                            selectedQuoteForDownload._id,
                            0
                          )
                        }
                      >
                        <div className="pr-radio">
                          <label className="btn box-in">
                            {selectedShipMethodMap.get(
                              selectedQuoteForDownload._id
                            ) == 0 ? (
                              <span className="triangle-0">
                                {" "}
                                <img src={tickwhite} />
                              </span>
                            ) : null}
                            <span className="price-sp">
                              {selectedQuoteForDownload.price
                                ? currencyFormatter(
                                  (
                                    selectedQuoteForDownload.price +
                                    selectedQuoteForDownload.shippingCharge1
                                  ).toFixed(2)
                                )
                                : 0}
                            </span>
                            <span className="vld-bx">
                              {`${Math.floor(
                                selectedQuoteForDownload.leadTime1 / 7
                              )}-${Math.floor(
                                selectedQuoteForDownload.leadTime1 / 7
                              ) + 1
                                }`}{" "}
                              weeks
                            </span>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </span>
                </>
              ) : (
                <></>
              )}

              {((selectedQuoteForDownload.isUpdatedByAdmin && selectedQuoteForDownload.isSent) || selectedQuoteForDownload.myorders || selectedQuoteForDownload.isRepeatOrderQuotation) ? (
                <>
                  {" "}
                  <span className="price-tab-my-quote">
                    {selectedQuoteForDownload.price1 > 0 ? (
                      <div
                        className={
                          selectedShipMethodMap.get(
                            selectedQuoteForDownload._id
                          ) == 1
                            ? "box-pr pr-checked"
                            : "box-pr"
                        }
                        onClick={() =>
                          handleShipMethodChange(
                            selectedQuoteForDownload._id,
                            1
                          )
                        }
                      >
                        <div className="pr-radio">
                          <label className="btn box-in">
                            {selectedShipMethodMap.get(
                              selectedQuoteForDownload._id
                            ) == 1 ? (
                              <span className="triangle-0">
                                {" "}
                                <img src={tickwhite} />
                              </span>
                            ) : null}
                            <span className="price-sp">
                              {selectedQuoteForDownload.price1
                                ? currencyFormatter(
                                  (
                                    selectedQuoteForDownload.price1 +
                                    selectedQuoteForDownload.shippingCharge2
                                  ).toFixed(2)
                                )
                                : 0}
                            </span>
                            <span className="vld-bx">
                              {`${Math.floor(
                                selectedQuoteForDownload.leadTime2 / 7
                              )}-${Math.floor(
                                selectedQuoteForDownload.leadTime2 / 7
                              ) + 1
                                }`}{" "}
                              weeks
                            </span>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </span>
                </>
              ) : (
                <></>
              )}
            </div>

            <h6>Select shipping address for quotation:</h6>
            <div className="billing-form">
              <div className="address">
                {addressValues?.address?.map((el, id) => (
                  <div className="singleAddress" key={id}>
                    <div style={{ display: "flex" }}>
                      <input
                        className="btn-radio"
                        type="radio"
                        value={el._id}
                        checked={addressValues.shippingAddress == el._id}
                        name="rd"
                        style={{ marginRight: "5px" }}
                        onChange={(e) => {
                          setError({
                            ...error,
                            shippingAddress: "",
                          });
                          setAddressValues({
                            ...addressValues,
                            shippingAddress: el._id,
                            addressline1: el.addressLineOne,
                            addressline2: el.addressLineTwo,
                            city: el.city,
                            country: el.country,
                            state: el.state,
                            zipCode: el.postalCode,
                            firstName: el.firstName,
                            lastName: el.lastName,
                          });
                        }}
                      />
                      <div className="address">
                        <div className="name">
                          {el?.firstName} {el?.lastName}
                        </div>
                        <div className="add">
                          {el?.addressLineOne} {el?.addressLineTwo} {el?.city}{" "}
                          {el?.state} {el.postalCode} {el.country}
                        </div>
                      </div>
                    </div>
                    <div className="buttons">
                      <div className="edit" onClick={() => editHandler(id)}>
                        Edit
                      </div>
                      |
                      <div className="delete" onClick={() => deleteData(id)}>
                        Delete
                      </div>
                    </div>
                  </div>
                ))}

                <div className="allAddress">
                  <div className="link"></div>
                  {addressValues?.address?.length >= 5 ? (
                    <div
                      className="addNew"
                      style={{ cursor: "auto", opacity: "0.5" }}
                    >
                      + Add New Address
                    </div>
                  ) : (
                    <div className="addNew" onClick={addNewHandler}>
                      + Add New Address
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDownload} className="req-quote-btn">
            Download Quotation
          </Button>

          <Button
            onClick={() => setShowDQModal(false)}
            className="req-quote-btn"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <table className="">
        <thead>
          <tr>
            <th className="text-center">PROJECT NAME </th>
            <th className="text-center">PROCESS</th>
            <th className="text-center">OWNER</th>
            <th className="text-center">CREATED ON</th>
            <th className="text-center" style={{ width: "80px", minWidth: '80px' }}>VERSION</th>
            <th className="text-center" style={{ textAlign: 'center' }}>OPTION 1</th>
            <th className="text-center" style={{ textAlign: 'center' }}>OPTION 2</th>
            <th className="text-center" style={{ width: "80px" }}>CHAT</th>
            <th className="text-center" style={{ width: "60px" }}>TEAM</th>
            <th className="text-center" style={{ width: "60px" }}>DOWNLOAD</th>
          </tr>
        </thead>
        <tbody>
          {quotationsList.length > 0 &&
            quotationsList.map((item, i) => {
              return (
                <tr key={i}>
                  <td
                    style={{
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                      whiteSpace: "normal",
                      width: "auto",
                    }}
                  >
                    <GlobalProjectNameComponent project={item} index={i} handleEditClick={handleEditClick} handleEditableChange={handleEditableChange} handleUpdateClick={handleUpdateClick} handleCancelClick={handleCancelClick} />
                    {/* {renderProjectName(item,i)} */}
                  </td>
                  <td className="flex flex-wrap justify-between items-center px-2"><p>
                    {item?.selectedProcess ? processType[item?.selectedProcess - 1].name : null}
                  </p> <BrainMachine /></td>
                  <td className=" px-2">
                    <span>{item?.userId?.firstName + " " + item?.userId?.lastName}</span>
                    <UserIcon />
                  </td>
                  <td className="flex justify-between items-center px-2 py-3 ">{moment(item.createdAt).format("DD MMM YYYY")} <CalenderIcon /></td>
                  <td style={{ textAlign: 'center', padding: "2px 10px" }}>
                    <Form.Select
                      style={{ fontSize: "13px", fontWeight: "400" }}
                      value={item.version}
                      onChange={(e) => handleVersionDropDownClick(e, i)}
                    >
                      {versionData[activeTab][item.RefId]?.map((num) => (
                        <option key={num} value={num}>
                          {num}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {((item.isUpdatedByAdmin && item.isSent) || item.selectedProcess == 2) || item.myorders || item.isRepeatOrderQuotation ? (
                      <>
                        {item.price > 0 ? (
                          <>
                            <div>
                              {item.price
                                ? currencyFormatter(
                                  (item.price + item.shippingCharge1).toFixed(
                                    2
                                  )
                                )
                                : 0}
                            </div>
                            <div style={{ fontSize: '12px', color: 'gray' }}>
                              (
                              {`${Math.floor(item.leadTime1 / 7)}-${Math.floor(item.leadTime1 / 7) + 1
                                }`}{" "}
                              weeks )
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {((item.isUpdatedByAdmin && item.isSent) || item.selectedProcess == 2) || item.myorders || item.isRepeatOrderQuotation ? (
                      <>
                        {item.price1 > 0 ? (
                          <>
                            <div>
                              {item.price1
                                ? currencyFormatter(
                                  (
                                    item.price1 + item.shippingCharge2
                                  ).toFixed(2)
                                )
                                : 0}
                            </div>
                            <div style={{ fontSize: '12px', color: 'gray' }}>
                              (
                              {`${Math.floor(item.leadTime2 / 7)}-${Math.floor(item.leadTime2 / 7) + 1
                                }`}{" "}
                              weeks)
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </td>

                  <td className="text-center ">
                    <ChatButton
                      RefId={item.RefId}
                      showDrawer={() => showDrawer(item.RefId)}
                      isTextHide={true}
                      imageHeight={16}
                    />
                  </td>
                  <td className="">
                    <span onClick={() => handleTeamModal(item)} className="flex items-center justify-center">
                      <img
                        src={teamImage}
                        style={{
                          // marginRight: "5px",
                          // marginBottom: "4px",
                          height: "18px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {(((item.isUpdatedByAdmin && item.isSent) || item.selectedProcess == 2) && (item.price || item.price1 || item.stManualPrice || item.sheetAndInjectionManualPrice) || item.myorders || item.isRepeatOrderQuotation) ?
                      <>
                        <span
                          onClick={() => {
                            setSelectedQuoteForDownload(item);
                            setShowDQModal(true);
                          }}
                          className="grid place-items-center"
                        >
                          <DownloadIcon additionalClass={'text-primaryColor'} />
                        </span>
                      </>
                      :
                      <>
                      </>}

                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {quotationsList.length == 0 ? (
        <div
          style={{
            textAlign: "center",
            fontSize: "14px",
            width: "100%",
            marginTop: "5px",
            color: "#858585",
          }}
        >
          {myQuotesState.searchQuery.length > 0
            ? "Oops! We couldn't find any quotes matching your search query."
            : "No Data Found"}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MyQuoteTable;
