import { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { getSubmaterial } from "../../utils/actions/allactions";
import { useNavigate } from "react-router-dom";
import { getToken, validateAdmin } from "../../utils/helper";
import useDocumentTitle from "../../utils/useDocumentTitle";
import AdminLoadingComponent from "../../components/LoadingComponents/AdminLoadingComponent";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";

const SubMaterialMaster = () => {
  let navigate = useNavigate();
  useDocumentTitle("SubMaterial Master");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  let token = getToken();
  let [showFltr, setShowFltr] = useState(false);
  let [fltrValues, setFltrValues] = useState({
    materialName: "",
    is3D: false,
    isMachining: false,
  });

  const fetchSubMaterial = async () => {
    try {
      const subMaterialData = await getSubmaterial(token);
    setFilteredData(subMaterialData);
    setData(subMaterialData);
      
    } catch (error) {
      toast.error("Unable To Fetch Sub Material");
    }
    
  };
  useEffect(() => {
    validateAdmin(token, navigate);
    fetchSubMaterial();
  }, []);

  const handleCreateNew = () => {
    navigate("/admin/add-submaterial-master");
  };

  const handleEditSubMaterialMaster = (id) => {
    navigate(`/admin/edit-submaterial-master?id=${id}`);
  };

  const handleMaterailNameFilter = (e) => {
    setFltrValues({ ...fltrValues, materialName: e.target.value });
    if (e.target.value.length) {
      let dataToFilter = [...data];
      let filteredArr = dataToFilter.filter((item) =>
        item?.masterMaterialId?.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
      setFilteredData(filteredArr);
      setShowFltr(true);
    } else {
      setFilteredData(data);
      setShowFltr(false);
    }
  };
  const handleIsMachiningFilter = (e) => {
    setFltrValues({ ...fltrValues, isMachining: e.target.checked || false });
    if (e.target.checked) {
      let dataToFilter = [...data];
      let filteredArr = dataToFilter.filter(
        (item) => item?.isMachining == true
      );
      setFilteredData(filteredArr);
      setShowFltr(true);
    } else {
      setFilteredData(data);
      setShowFltr(false);
    }
  };
  const handleIs3DFilter = (e) => {
    setFltrValues({ ...fltrValues, is3D: e.target.checked || false });
    if (e.target.checked) {
      let dataToFilter = [...data];
      let filteredArr = dataToFilter.filter((item) => item?.isThreeD == true);
      setFilteredData(filteredArr);
      setShowFltr(true);
    } else {
      setFilteredData(data);
      setShowFltr(false);
    }
  };

  let handleRefresh = () => {
    setFilteredData(data);
    setFltrValues({
      materialName: "",
      is3D: false,
      isMachining: false,
    });
    setShowFltr(false);
  };

  const handleSearch = () => {
    if (
      !fltrValues.materialName &&
      !fltrValues.is3D &&
      !fltrValues.isMachining
    ) {
      toast.error("Please enter value to filter data!");
      return;
    }

    let dataToFilter = [...data];

    if (fltrValues.materialName) {
      dataToFilter = dataToFilter.filter((item) =>
        item?.masterMaterialId?.name
          .toLowerCase()
          .includes(fltrValues.materialName.toLowerCase())
      );
    }
    if (fltrValues.isMachining) {
      if (fltrValues.isMachining) {
        dataToFilter = dataToFilter.filter((item) => item?.isMachining == true);
      }
    }

    if (fltrValues.is3D) {
      dataToFilter = dataToFilter.filter((item) => item?.isThreeD == true);
    }

    setFilteredData([...dataToFilter]);
    setShowFltr(true);
  };

  return (
    <div className="admin-container">
      
      <div className="right-container">
        <div className="center-heading">
          <h1 className="font-bold  text-2xl">SubMaterial Master</h1>

          <div className="search-bar">
            <div className="row">
              <div className="col-md-3">
                <div className="search-block">
                  <label className="sr-label">Material Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={fltrValues.materialName}
                    placeholder="Material name"
                    onChange={handleMaterailNameFilter}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="search-block">
                  <input
                    type="checkbox"
                    id="is-machining-check"
                    name="checkbox-machine"
                    // value={props.commanValues.sameForAllField} //commenting for same for all field
                    // checked={props.commanValues.sameForAllField}
                    value={fltrValues?.isMachining}
                    checked={fltrValues?.isMachining}
                    onChange={handleIsMachiningFilter}
                  />
                  <label
                    className="sr-label"
                    htmlFor="is-machining-check"
                    style={{ fontSize: "16px" }}
                  >
                    Is Machining
                  </label>
                </div>
              </div>
              <div className="col-md-3 flex  ">
                <div className="search-block">
                  <input
                    type="checkbox"
                    id="is-3d-check"
                    name="checkbox-3d"
                    value={fltrValues?.is3D}
                    checked={fltrValues?.is3D}
                    onChange={handleIs3DFilter}
                  />
                  <label className="sr-label" htmlFor="is-3d-check">
                    Is 3D
                  </label>
                </div>
              </div>

              <div className="col-md-3" style={{ marginTop: "10px" }}>
                <div className="search-btn">
                  {showFltr ? (
                    <button onClick={handleRefresh} className="button-search">
                      Reset all filters
                    </button>
                  ) : (
                    ""
                  )}
                  <button onClick={handleSearch} className="button-search">
                    Search
                  </button>
                </div>
              </div>

              <div className="col-md-3">
                <div className="search-btn">
                  <button onClick={handleCreateNew} className="pr-edit-btn">
                    Create New
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="quotes-table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Name{" "}
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    SubMaterial Factor
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Material
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    isMachining
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    isThreeD
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {filteredData ? (
                <tbody>
                  {filteredData.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.name ? item.name : ""}</td>
                        <td>{item.SubMatFactor ? item.SubMatFactor : "NA"}</td>
                        <td>
                          {item.masterMaterialId
                            ? item.masterMaterialId.name
                            : ""}
                        </td>
                        <td>{item.isMachining ? "Yes" : "No"}</td>
                        <td>{item.isThreeD ? "Yes" : "No"}</td>
                        <td>
                          {" "}
                          <Button
                            text={"Edit /Deactivate"}
                            additionalClassName={
                              "border-red-500  px-4 py-2 bg-yellow-500 shadow-sm text-white rounded text-sm"
                            }
                            onClickFunction={() =>
                              handleEditSubMaterialMaster(item._id)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <AdminLoadingComponent rows={12} columns={6} />
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardComponents(SubMaterialMaster);
