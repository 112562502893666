import React, { useState } from "react";
import "./HoverInfo.scss";
import infoImage from "../../images/info_blue.svg";
import { InfoIcon } from "../Icons";

const HoverInfo = ({ content }) => {
  if (!content) {
    content = "No Comment Added";
  }
  const [hover, setHover] = useState(false);

  const tooltipStyle = {
    display: hover ? "inline" : "none",
    textAlign: "left",
  };

  const toolTipDivStyle = {
    display: hover ? "inline" : "none",
  };

  const handleMouseOut = () => {
    setHover(false);
  };

  const handleMouseIn = () => {
    setHover(true);
  };

  return (
    <div className="group flex relative ">
      <InfoIcon additionalClass={"!h-4 "} />
      <p className="hidden border top-4 border-primaryColor text-primaryColor left-3  w-max text-sm group-hover:block absolute bg-white p-2 shadow-lg">
        {content}
      </p>
    </div>
  );
};

export default HoverInfo;
