import React, { useEffect, useState } from 'react'
import AdminDashboardComponents from '../../../components/HigherOrderComponents/AdminDashboardComponents'
import { Link } from 'react-router-dom';
import { delete3dProcess, getAll3dProcess } from '../../../utils/actions/adminActions';
import { toast } from 'react-toastify';
import { currencyFormatter } from '../../../utils/helper';
import { LucidLoader } from '../../../components/Icons';

const ShowAll3DProcess = () => {

    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("Token");
    const [allData, setAllData] = useState([]);


    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const data = await getAll3dProcess(token);
                if (!data || !data.success) {
                    toast.error("Failed To Load Data");
                };
                setAllData(data.data);
            } catch (error) {
                toast.error("Failed To Load Data");
            } finally {
                setLoading(false);
            }
        })()


    }, []);

    return (
        <div className='w-full '>
            <Link to={'/admin/upsert/3dprocess'} className='border p-1 px-3  font-bold bg-primaryColor text-white float-right me-4 my-3'>Create New </Link>
            <h3 className=' text-center my-3 text-2xl font-bold'>3D Technology </h3>
            <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Color
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Cost
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Description
                            </th>

                            <th scope="col" class="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allData.map((item) => <Row {...item} />)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AdminDashboardComponents(ShowAll3DProcess);



const Row = ({ name, costPerCc, color, description, manualReviewRequired ,_id}) => {
    const token = localStorage.getItem("Token")
    const [loading, setLoading] = useState(false);
    const handleDelete = async(id) => {
        setLoading(true);
        try {
            const response = await delete3dProcess(token,_id);
            console.log(response);

        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    return <tr class="bg-white">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
            {name}
        </th>
        <td class="px-6 py-4">
            {color}
        </td>
        <td class="px-6 py-4">
            {currencyFormatter(costPerCc)}
        </td>
        <td class="px-6 py-4">
            {description}
        </td>
        <td class="px-6 py-4">
            <div className='flex items-center justify-center gap-x-2'>
                <button className='h-7 px-2 bg-yellow-400 text-white rounded text-sm'>Edit</button>
                <button onClick={handleDelete} className='h-7 px-2 bg-red-500 text-white rounded text-sm  flex items-center justify-center'>
                    {
                        !loading ? <span>Delete</span> : <LucidLoader />
                    }

                </button>
            </div>
        </td>
    </tr>

}