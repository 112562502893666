import {
  useEffect,
  useRef,
} from 'react';
import TWEEN from "@tweenjs/tween.js";

export function useApiFunctions(state, addFileFromArrayBufferRef, addFileFromURLRef, dispatch, setActiveFileIndexRef) {
  const addPromiseRef = useRef();
  useEffect(() => {
    if(addPromiseRef.current !== undefined && addPromiseRef.current.from === "URL") {
      const newFileIndex = state.files.length-1;
      const {resource, init, resolve} = addPromiseRef.current;
      addPromiseRef.current = undefined;
      resolve({
        index: newFileIndex,
        promise: new Promise((resolve, reject) => {
          (async () => {
            let filename;
            if(typeof(resource) === "object") {
              filename = resource.url.split('/').pop();
            } else {
              filename = resource.split('/').pop();
            }
            const response = await fetch(resource, init);
            if(response.status < 200 || response.status >= 300) {
              reject(response);
              return;
            }
            const fileContents = await response.arrayBuffer();
            dispatch({ type: "set-file-properties", payload: {
              ...state.files[newFileIndex],
              fileIndex: newFileIndex,
              properties: {
                ...state.files[newFileIndex].properties,
                name: filename,
                content: fileContents,
                analysisProgress: 0,
                resolve,
              }
            }});
          })();
        }),
      });
    }
    if(addPromiseRef.current !== undefined && addPromiseRef.current.from === "ArrayBuffer") {
      const newFileIndex = state.files.length-1;
      const {name, arrayBuffer, resolve} = addPromiseRef.current;
      addPromiseRef.current = undefined;
      resolve({
        index: newFileIndex,
        promise: new Promise((resolve, reject) => {
          dispatch({ type: "set-file-properties", payload: {
            ...state.files[newFileIndex],
            fileIndex: newFileIndex,
            properties: {
              ...state.files[newFileIndex].properties,
              name: name,
              content: arrayBuffer,
              analysisProgress: 0,
              resolve,
            },
          }});
        }),
      });
    }
  }, [state, dispatch]);

  const setActiveFilePromiseRef = useRef();
  useEffect(() => {
    if(setActiveFilePromiseRef.current !== undefined) {
      setActiveFilePromiseRef.current.resolve();
    }
  }, [state, dispatch]);
  
  useEffect(() => {
    addFileFromURLRef.current = (type, resource, init, onProgress) => {
      const p = new Promise((resolve, reject) => {
        addPromiseRef.current = {
          from: "URL",
          resolve,
          reject,
          type, resource, init,
        };
        dispatch({
          type: "push-file",
          payload: {
            properties: {
              onProgress,
              type,
            },
          },
        });
      });
      return p;
    };
    addFileFromArrayBufferRef.current = (type, name, arrayBuffer, onProgress) => {
      const p = new Promise((resolve, reject) => {
        addPromiseRef.current = {
          from: "ArrayBuffer",
          resolve,
          reject,
          type, name, arrayBuffer,
        };
        dispatch({
          type: "push-file",
          payload: {
            properties: {
              onProgress,
              type,
            },
          },
        });
      });
      return p;
    };
    setActiveFileIndexRef.current = (index) => {
      dispatch({
        type: "set-activeFileIndex",
        payload: index,
      });
      return new Promise((resolve, reject) => {
        setActiveFilePromiseRef.current = {resolve};
      });
    };
  }, [state, addFileFromArrayBufferRef, addFileFromURLRef, dispatch, setActiveFileIndexRef]);
}

export function useFixCanvas() {
  useEffect(() => {
    document.getElementById("viewport").getElementsByTagName("canvas")[0].style.position = "absolute";
  }, []);
}

export function useTweenUpdate() {
  useEffect(() => {
    function animate(time) {
      requestAnimationFrame(animate);
      TWEEN.update(time);
    }
    requestAnimationFrame(animate);
  }, []);
}
