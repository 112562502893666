import moment from "moment";
import PriceBox from "../../components/PriceBox";
import saveico from "../../images/save-ico.svg";
import closeImg from "../../images/close.svg";
import shareico from "../../images/share.svg";
import teamImage from "../../images/group-svgrepo-com.svg";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import DownloadImage from "../../images/download.svg";
import {
  downloadPdf,
  shareQuoteToEmails,
  updateQuoteShipMethod,
  getOrderForQuotationId,
  handleCommonAddAddressForUser,
  handleCommonDeleteAddressForUser,
  handleCommonEditAddressForUser,
  getAllAddress,
  handleQuoteCancel,
  moveQuoteToProgressStatus,
} from "../../utils/actions/allactions";
import fileSaver from "file-saver";
import { config } from "../../environment/development";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import QRTrackingHoverInfo from "../../components/QRTrackingHoverInfo/QRTrackingHoverInfo";
import AddressModal from "../../components/CommanAddressModal/AddressModal";
import { ChatRefContext } from "../../context/chatRef.context";
import { currencyFormatter } from "../../utils/helper";
import TeamCollaboration from "../TeamCollaboration/TeamCollaboration";
import {
  ArchiveIcon,
  ArchiveMoveIcon,
  ArrowIcon,
  ArrowRight,
  DocIcon,
  DownloadIcon,
  EditIcon,
  ForwardToPurchaseIcon,
  Proceed,
  ShareIcon,
  UsersIcon,
} from "../Icons";
import { createNewQuotationVersionAndOrder } from "../../utils/actions/commanActions";
import ForwardToPurchaseModal from "../ForwardToPurchaserModal";
import TargetCostUserModal from "../TargetCostUserModal/TargetCostUserModal";

function QuoteVersionPriceBox({
  selectedQuote,
  handleOnClickPriceBox,
  selectedPrice,
  selectedShipMethod,
  setSelectedShipMethod,
  setSelectedQuote,
}) {
  const { selectedProcess } = selectedQuote;
  const navigate = useNavigate();
  const [errors, setErrors] = useState('');
  let token = localStorage.getItem("Token");
  const { setSelectedChatRefId } = useContext(ChatRefContext);
  let loggedInUserEmail = localStorage.getItem("email");
  const [shareQuoteModalShow, setShareQuoteModalShow] = useState(false);
  const [emailToShareQuote, setEmailToShareQuote] = useState(
    new Set([loggedInUserEmail])
  );
  const [inputEmail, setInputEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [isSubTotalZero, setIsSubTotalZero] = useState(false);
  const [selectedQuoteForTeam, setSelectedQuoteForTeam] = useState({});
  const [teamMemberModalShow, setTeamMemberModalShow] = useState(false);
  const [isOrderAlreadyCreated, setIsOrderAlreadyCreated] = useState(false);
  const [showDQModal, setShowDQModal] = useState(false);
  let email = localStorage.getItem("email");
  const [isOpen, setisOpen] = useState(false);
  const [isTargetCostModalOpen, setIsTargetCostModalOpen] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const funcName = searchParams.get("funcName");

  useEffect(() => {
    if (funcName == 'stc') {
      setIsTargetCostModalOpen(true);
    }
  }, [])


  // New state for address selected for quotation download
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [addressValues, setAddressValues] = useState({});
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    companyName: "",
    vatNumber: "",
    addresses: "",
    finalTotalPrice: "",
    addressline1: "",
    addressline2: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
    subTotal: "",
  });

  const handleArchiveQuotation = async () => {
    try {
      if (selectedQuote.isCancelled) {
        toast.error("Quotation is already archived");
        return;
      }

      if (selectedQuote.myorders) {
        toast.error("This quotation cannot be archived as the order has already been created.");
        return;
      }
      const quoteCancelResponse = confirm(
        "Are you sure that you want to archive this version of  quotation?"
      );

      if (quoteCancelResponse) {
        let data = {
          quoteId: selectedQuote._id,
        };
        const response = await handleQuoteCancel(token, data);
        if (response.status) {
          setSelectedQuote({
            ...selectedQuote,
            isCancelled: true
          });
          toast.success("Quotation achieved successfully.");
        }
      }
    } catch (err) {
      console.log('error', err);
      toast.error("Something went wrong.Please try again later")
    }
  };

  const handleMoveToProgress = async () => {
    try {
      let data = {
        quoteId: selectedQuote._id,
      };
      const response = await moveQuoteToProgressStatus(token, data);
      if (response.status) {
        setSelectedQuote({
          ...selectedQuote,
          isCancelled: false
        })
        toast.success(response.message);
      } else {
        toast.error("Something went wrong.Please try again later!");
      }
    } catch (e) {
      toast.error(e.message || "Something went wrong.Please try again later!");
    }
  };




  const handledAddAddress = async (addressData) => {
    let data = {
      userId: selectedQuote.userId._id,
      addressData,
    };
    try {
      const response = await handleCommonAddAddressForUser(token, data);
      if (response.status) {
        let localAllUserAddress = addressValues?.address
          ? [...addressValues.address]
          : [];
        localAllUserAddress.push(response.data);
        setAddressValues({ ...addressValues, address: localAllUserAddress });
        setShowDQModal(true);
      }
    } catch (error) {
      toast.error("Unable To Add Address");
    }
  };

  const handleAddressOnEdit = async (newFormData, index) => {
    let allAddresses = addressValues.address ? addressValues.address : [];
    let address = allAddresses[index];
    address = { ...address, ...newFormData };
    try {
      const response = await handleCommonEditAddressForUser(token, address);
      if (response.status) {
        allAddresses[index] = response.data;
        let addressValueData = {};
        if (addressValues.shippingAddress == response.data._id) {
          addressValueData = {
            addressline1: response.data.addressLineOne,
            addressline2: response.data.addressLineTwo,
            city: response.data.city,
            country: response.data.country,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            state: response.data.state,
            zipCode: response.data.postalCode,
          };
        }
        setAddressValues({
          ...addressValues,
          ...addressValueData,
          address: allAddresses,
        });
        setShowDQModal(true);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const addNewHandler = () => {
    setShowAddressModal(true);
    setShowDQModal(false);
  };

  const editHandler = (index) => {
    setShowAddressModal(true);
    setIsEdit(true);
    setEditId(index);
    setShowDQModal(false);
  };

  const deleteData = async (i) => {
    try {
      if (i > -1) {
        const addressToDelete = addressValues.address[i];
        let localAllAddress = addressValues?.address
          ? [...addressValues.address]
          : [];
        const addressDeleteResponse = await handleCommonDeleteAddressForUser(
          token,
          addressToDelete._id
        );
        if (addressDeleteResponse.status) {
          localAllAddress.splice(i, 1);
          setAddressValues({ ...addressValues, address: localAllAddress });
        }
      }
    } catch (error) {
      toast.error("Delete Data");
    }
  };

  const showDrawer = () => {
    setOpen(true);
    setSelectedChatRefId(selectedQuote.RefId);
    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "chatOpen",
      //   userId: userId,
      //   quoteRefId: selectedQuote.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }
  };

  const onClose = () => {
    setOpen(false);
    setSelectedChatRefId(null);
  };

  let startDate = moment();
  let after10 = startDate.add(10, "days");
  after10 = after10.format("Do MMM");

  let startDate2 = moment();
  let after15 = startDate2.add(15, "days");
  after15 = after15.format("Do MMM");

  let startDate3 = moment();
  let after20 = startDate3.add(20, "days");
  after20 = after20.format("Do MMM");

  const handleQuotationShare = async () => {
    try {
      if (emailToShareQuote.size === 0) {
        toast.error(
          "Please enter atleast one mail to share the quotation details"
        );
        return;
      } else {
        let userId = localStorage.getItem("_id");
        if (window.location.host === "make.8xparts.com") {
          // window?.dataLayer?.push({
          //   event: "shareQuotation",
          //   userId: userId,
          //   quoteRefId: selectedQuote.RefId,
          //   referenceId:
          //     email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
          // });
        }
        let data = {
          quoteId: selectedQuote._id,
          selectedShipMethod: selectedShipMethod,
          emailToShareQuote: [...emailToShareQuote],
        };
        let token = localStorage.getItem("Token");
        let response = await shareQuoteToEmails(token, data);
        if (response.statusCode == 200) {
          setShareQuoteModalShow(false);
          setEmailToShareQuote(new Set([loggedInUserEmail]));
          toast.success("Quotation details shared successfully");
        } else {
          toast.error("Something went wrong while sharing the quote details.");
        }
      }
    } catch (err) {
      toast.error("Something went wrong.Please try again later.");
    }
  };

  const handleShareQuoteClose = () => {
    setShareQuoteModalShow(false);
  };

  const handleShareQuoteModalOpen = () => {
    if (!isSubTotalZero) {
      if (!selectedQuote._id) {
        const userResponse = confirm(
          "To use the share quote functionality you need to save the quote. Please confirm if you want to save the quote with current details?"
        );
        if (userResponse) {
          let response = props.onSubmit(
            null,
            { saveQuote: true },
            { functionalityName: "Share" }
          );
        }
        return;
      } else {
        setShareQuoteModalShow(true);
      }
    } else {
      toast.error(
        "This RFQ cannot be shared. Please use chat functionality to contact the admin for updates on the prices. "
      );
    }
  };

  const handleAddButtonToReceiverList = () => {
    let response = validateEmail(inputEmail);
    if (response) {
      if (emailToShareQuote.has(inputEmail)) {
        toast.error("Email already added to receivers list");
      } else {
        let localEmailList = new Set(emailToShareQuote);
        localEmailList.add(inputEmail);
        setEmailToShareQuote(localEmailList);
        setInputEmail("");
      }
    } else {
      toast.error("Please enter a valid email");
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleDeleteShareEmail = (item) => {
    let localEmailList = new Set(emailToShareQuote);
    localEmailList.delete(item);
    setEmailToShareQuote(localEmailList);
  };

  const handleProceedToCheckout = async () => {
    if (isOrderAlreadyCreated) {

      const { RefId, version } = selectedQuote;
      const response = await createNewQuotationVersionAndOrder(token, { RefId, version });
      console.log(response);

      if (!response || !response.success) {
        toast.error("Unable To Checkout At The moment");
        return;
      }
      const { newId } = response;
      navigate("/get-instant-quote/checkout/" + newId);

    }
    let data = {
      quoteId: selectedQuote._id,
      selectedShipMethod: selectedShipMethod,
    };
    try {
      let response = await updateQuoteShipMethod(token, data);
      if (response.status == 200) {
        setSelectedShipMethod(selectedShipMethod);

        if (selectedQuote.selectedProcess < 2) {
          if (
            (selectedShipMethod == 0 && selectedQuote.price <= 0) ||
            (selectedShipMethod == 1 && selectedQuote.price1 <= 0) ||
            (selectedShipMethod == 2 && selectedQuote.stManualPrice <= 0)
          ) {
            toast.error(
              "The Price for this RFQ is not confirmed by our applications Engineering team. Please use the chat functionality to contact admin for updates on the prices."
            );
            return;
          }
        } else {
          if (
            (selectedShipMethod == 0 && selectedQuote.price <= 0) ||
            (selectedShipMethod == 1 && selectedQuote.price1 <= 0) ||
            (selectedShipMethod == 2 &&
              selectedQuote.sheetAndInjectionManualPrice == 0)
          ) {
            toast.error(
              "The Price for this RFQ is not confirmed by our applications Engineering team. Please use the chat functionality to contact admin for updates on the prices."
            );
            // The Price for this RFQ is confirmed by our applications Engineering team. To check prices for alternative processes, please initiate a new request from the dashboard.
            return;
          }
        }

        if (selectedQuote.selectedProcess <= 1) {
          if (
            selectedShipMethod <= 1 &&
            !selectedQuote.isUpdatedByAdmin &&
            !selectedQuote.isSent
          ) {
            let userResponse = confirm(
              `Our applications Engineering team is currently reviewing the quotes. We will send you an email once the prices are validated so you can proceed to place the order.`
            );
            if (userResponse) {
              navigate("/saved-quote");
              return;
            } else {
              return;
            }
          }
        }
        navigate("/get-instant-quote/checkout/" + selectedQuote._id);
      } else {
        toast.error("Something went wrong while updating quote information");
      }
    } catch (error) {
      toast.error("Unable To Proceed To Checkout");
    }

    //
  };

  let handleDownload = () => {
    if (!addressValues.shippingAddress) {
      toast.error(
        "Please select a valid shipping address to download quotation."
      );
      return;
    }

    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "downloadQuote",
      //   userId: userId,
      //   quoteRefId: selectedQuote.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }
    let data = {
      fname: addressValues.firstName,
      lname: addressValues.lastName,
      email: localStorage.getItem("email"),
      quoteId: selectedQuote.RefId,
      total: selectedQuote.price,
      addressline1: addressValues.addressline1,
      addressline2: addressValues.addressline2,
      city: addressValues.city,
      state: addressValues.state,
      country: addressValues.country,
      zipcode: addressValues.zipCode,
      partData: JSON.stringify(selectedQuote.partsData),
      process: selectedQuote.selectedProcess,
      price: selectedQuote.price,
      price1: selectedQuote.price1,
      price2: selectedQuote.price2,
      selectedShipMethod: selectedShipMethod,
      sheetMetalAndInjectionDesc: selectedQuote.sheetAndInjectionDiscription,
      sheetMetalAndInjectionMP: selectedQuote.sheetAndInjectionManualPrice,
      sheetAndInjectionQuantity: selectedQuote.sheetAndInjectionQuantity,
      manualPrice: selectedQuote.stManualPrice,
      forInvoice: false,
      hidePrice: selectedQuote.hidePrice,
      isQrTrackingRequested: selectedQuote.isQrTrackingRequested,
      isDimensionalReportRequested: selectedQuote.isDimensionalReportRequested,
      isConformanceCertificateRequested:
        selectedQuote.isConformanceCertificateRequested,
      additionalCost:
        selectedShipMethod == 0
          ? selectedQuote.additionalCost1
          : selectedShipMethod == 1
            ? selectedQuote.additionalCost2
            : selectedQuote.additionalCost3,
      shippingCharge:
        selectedShipMethod == 0
          ? selectedQuote.shippingCharge1
          : selectedShipMethod == 1
            ? selectedQuote.shippingCharge2
            : selectedQuote.shippingCharge3,
      leadTime:
        selectedShipMethod == 0
          ? selectedQuote.leadTime1
          : selectedShipMethod == 1
            ? selectedQuote.leadTime2
            : selectedQuote.leadTime3,
      projectName: selectedQuote.projectName,
      date: selectedQuote.createdAt,
      version: selectedQuote.version,
      quotationNotes: selectedQuote.quotationNotes,
      quotationTnc: selectedQuote.quotationTnc,
    };

    let priceValue =
      selectedShipMethod == 0
        ? selectedQuote.price
        : selectedShipMethod == 1
          ? selectedQuote.price1
          : selectedQuote.selectedProcess <= 2
            ? selectedQuote.stManualPrice
            : selectedQuote.sheetAndInjectionManualPrice;
    let fileName = `${selectedQuote.RefId}-V-${selectedQuote.version}-${Number(
      priceValue
    ).toFixed(2)}.pdf`;

    downloadPdf(data, token)
      .then((res) => {
        fileSaver.saveAs(
          `${config.backEnd}/public/docs/` + res?.data?.data,
          fileName
        );
      })
      .catch((err) => {
        console.log(
          "error in quote version pb on 267 on:: " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
          err
        );
      });
  };

  const checkIsSubTotalZero = () => {
    setIsSubTotalZero(false);
    if (selectedQuote.selectedProcess >= 3) {
      if (
        selectedQuote.price <= 0 &&
        selectedQuote.price1 <= 0 &&
        selectedQuote.sheetAndInjectionManualPrice <= 0
      ) {
        setIsSubTotalZero(true);
      }
    } else {
      if (
        selectedQuote.price <= 0 &&
        selectedQuote.price1 <= 0 &&
        selectedQuote.stManualPrice <= 0
      ) {
        setIsSubTotalZero(true);
      }
    }
  };

  const fetchOrderData = async () => {




    try {
      let orderResponse = await getOrderForQuotationId(
        token,
        selectedQuote?._id
      );
      if (orderResponse.status) {
        setIsOrderAlreadyCreated(true);
      } else {
        setIsOrderAlreadyCreated(false);
      }
      const fetchedAddresses = await getAllAddress(token);
      let localUserAddresses = [];
      fetchedAddresses?.data?.map((address) => {
        if (address?.userId === selectedQuote?.userId?._id) {
          localUserAddresses.push(address);
        }
      });

      setAddressValues({ ...addressValues, address: [...localUserAddresses] });
    } catch (err) {
      console.log(
        "err in quote version pb on 290" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };
  const [isForwardToPurchaserModalOpen, setIsForwardToPurchaserModalOpen] = useState(false);


  useEffect(() => {
    checkIsSubTotalZero();
    fetchOrderData();
  }, [selectedQuote]);

  const handleTeamModal = async (e, selectedQuote) => {
    e.preventDefault();

    let userId = localStorage.getItem("_id");
    if (window.location.host === "make.8xparts.com") {
      // window?.dataLayer?.push({
      //   event: "teamCollaboration",
      //   userId: userId,
      //   quoteRefId: selectedQuote?.RefId,
      //   referenceId: email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
      // });
    }

    setSelectedQuoteForTeam(selectedQuote);
    setTeamMemberModalShow(true);
  };

  const handleQMShowClick = () => {
    if (selectedShipMethod === "") {
      toast.error("Select a cost for which you want to download the quote.");
      return;
    }
    setShowDQModal(true);
  };

  return (
    <>
      <div className="qoute-version-price-div">
        {showAddressModal && (
          <AddressModal
            addressModal={showAddressModal}
            setShowAddressModal={setShowAddressModal}
            recentAddress={addressValues?.address}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            id={editId}
            values={addressValues}
            handledAddAddress={handledAddAddress}
            handleAddressOnEdit={handleAddressOnEdit}
          />
        )}
        <Chat RefId={selectedQuote.RefId} open={open} onDrawerClose={onClose} />
        <TeamCollaboration
          teamMemberModalShow={teamMemberModalShow}
          selectedQuoteForTeam={selectedQuoteForTeam}
          setTeamMemberModalShow={setTeamMemberModalShow}
          fetchDataForUser={() => null}
          updateQuoteData={setSelectedQuote}
        />
        <ForwardToPurchaseModal
          isForwardToPurchaserModalOpen={isForwardToPurchaserModalOpen}
          setIsForwardToPurchaserModalOpen={setIsForwardToPurchaserModalOpen}
          quoteId={selectedQuote?._id}
          selectedQuote={selectedQuote}
          setSelectedQuote={setSelectedQuote}

        />
        <TargetCostUserModal
          selectedQuote={selectedQuote}
          isTargetCostModalOpen={isTargetCostModalOpen}
          setIsTargetCostModalOpen={setIsTargetCostModalOpen}
          setSelectedQuote={setSelectedQuote}
        />
        <Modal
          size="md"
          dialogClassName="d-flex align-items-center justify-content-center custom-modal-view-order2"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showDQModal}
          onHide={() => setShowDQModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-modal-sizes-title-sm"
              style={{ fontSize: "15px" }}
            >
              Download quotation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="address-bar mt-4">
              <h6>Select shipping address for quotation </h6>
              <div className="billing-form">
                <div className="address">
                  {addressValues?.address?.map((el, id) => (
                    <div className="singleAddress" key={id}>
                      <div style={{ display: "flex" }}>
                        <input
                          className="btn-radio"
                          type="radio"
                          value={el._id}
                          checked={addressValues.shippingAddress == el._id}
                          name="rd"
                          style={{ marginRight: "5px" }}
                          onChange={(e) => {
                            setError({
                              ...error,
                              shippingAddress: "",
                            });
                            setAddressValues({
                              ...addressValues,
                              shippingAddress: el._id,
                              addressline1: el.addressLineOne,
                              addressline2: el.addressLineTwo,
                              city: el.city,
                              country: el.country,
                              state: el.state,
                              zipCode: el.postalCode,
                              firstName: el.firstName,
                              lastName: el.lastName,
                            });
                          }}
                        />
                        <div className="address">
                          <div className="name">
                            {el?.firstName} {el?.lastName}
                          </div>
                          <div className="add">
                            {el?.addressLineOne} {el?.addressLineTwo} {el?.city}{" "}
                            {el?.state} {el.postalCode} {el.country}
                          </div>
                        </div>
                      </div>
                      <div className="buttons">
                        <div className="edit" onClick={() => editHandler(id)}>
                          Edit
                        </div>
                        |
                        <div className="delete" onClick={() => deleteData(id)}>
                          Delete
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="allAddress">
                    <div className="link"></div>
                    {addressValues?.address?.length >= 5 ? (
                      <div
                        className="addNew"
                        style={{ cursor: "auto", opacity: "0.5" }}
                      >
                        + Add New Address
                      </div>
                    ) : (
                      <div className="addNew" onClick={addNewHandler}>
                        + Add New Address
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleDownload} className="req-quote-btn">
              Download Quotation
            </Button>

            <Button
              onClick={() => setShowDQModal(false)}
              className="req-quote-btn"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="qoute-version-optimised-price price-box">
          {console.log('condition', isSubTotalZero, selectedQuote.isSent, selectedQuote.isRepeatOrderQuotation, selectedQuote.isUpdatedByAdmin)}
          {(
            isSubTotalZero || !(((selectedQuote.isUpdatedByAdmin && selectedQuote.isSent) || selectedQuote.selectedProcess == 2) || selectedQuote.myorders || selectedQuote.isRepeatOrderQuotation)

          ) ?
            <p style={{ color: 'red' }}>  Prices are not available for this version of the saved quotation. To contact our operations team, please use the chat functionality.</p>
            :
            <></>
          }

          {(((selectedQuote.isUpdatedByAdmin && selectedQuote.isSent) || selectedQuote.selectedProcess == 2) ||
            selectedQuote.myorders ||
            selectedQuote.isRepeatOrderQuotation) &&
            !isSubTotalZero ? (
            <>
              {selectedQuote.selectedProcess >= 3 ? (
                <div className="price-tabs">
                  {selectedQuote.price > 0 ? (
                    <div
                      className={
                        selectedQuote && selectedShipMethod == 0
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                      onClick={() =>
                        handleOnClickPriceBox(
                          selectedQuote.price + selectedQuote.shippingCharge1,
                          0
                        )
                      }
                    >
                      <PriceBox
                        finalPrice={(selectedQuote.price + selectedQuote.shippingCharge1).toFixed(2)}
                        price={(selectedQuote.price + selectedQuote.shippingCharge1).toFixed(2)}
                        business={`${Math.floor(selectedQuote.leadTime1 / 7)}-${Math.floor(selectedQuote.leadTime1 / 7) + 1}` + ' weeks'} days={after10}
                        value={(selectedQuote.price + selectedQuote.shippingCharge1).toFixed(2)}
                        errors={errors}
                        setErrors={setErrors}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {selectedQuote.price1 > 0 ? (
                    <div
                      className={
                        selectedQuote && selectedShipMethod == 1
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                      onClick={() =>
                        handleOnClickPriceBox(
                          selectedQuote.price1 + selectedQuote.shippingCharge2,
                          1
                        )
                      }
                    >
                      <PriceBox
                        errors={errors}
                        setErrors={setErrors}
                        finalPrice={(
                          selectedQuote.price1 + selectedQuote.shippingCharge2
                        ).toFixed(2)}
                        price={(
                          selectedQuote.price1 + selectedQuote.shippingCharge2
                        ).toFixed(2)}
                        business={
                          `${Math.floor(selectedQuote.leadTime2 / 7)}-${Math.floor(selectedQuote.leadTime2 / 7) + 1
                          }` + " weeks"
                        }
                        days={after15}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {selectedQuote.sheetAndInjectionManualPrice > 0 && (
                    <div
                      className={
                        selectedQuote &&
                          selectedShipMethod == 2 &&
                          selectedQuote.sheetAndInjectionManualPrice &&
                          selectedQuote.sheetAndInjectionManualPrice ==
                          selectedPrice
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                      onClick={() =>
                        handleOnClickPriceBox(
                          selectedQuote.sheetAndInjectionManualPrice +
                          selectedQuote.shippingCharge3,
                          2
                        )
                      }
                    >
                      <PriceBox
                        errors={errors}
                        setErrors={setErrors}
                        finalPrice={
                          selectedQuote.sheetAndInjectionManualPrice +
                          selectedQuote.shippingCharge3
                        }
                        price={(
                          selectedQuote.sheetAndInjectionManualPrice +
                          selectedQuote.shippingCharge3
                        ).toFixed(2)}
                        business={
                          `${Math.floor(selectedQuote.leadTime3 / 7)}-${Math.floor(selectedQuote.leadTime3 / 7) + 1
                          }` + " weeks"
                        }
                        days={"N/A"}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="price-tabs">
                  {selectedQuote.price > 0 ?
                    <div className={selectedQuote && selectedShipMethod == 0 ? "box-pr pr-checked" : "box-pr"}
                      onClick={() => handleOnClickPriceBox((selectedQuote.price + selectedQuote.shippingCharge1), 0)}>
                      <PriceBox
                        finalPrice={(selectedQuote.price + selectedQuote.shippingCharge1).toFixed(2)}
                        price={(selectedQuote.price + selectedQuote.shippingCharge1).toFixed(2)}
                        business={`${Math.floor(selectedQuote.leadTime1 / 7)}-${Math.floor(selectedQuote.leadTime1 / 7) + 1}` + ' weeks'} days={after10}
                        value={selectedQuote.price + selectedQuote.shippingCharge1.toFixed(2)}
                        errors={errors}
                        setErrors={setErrors}
                      />
                    </div>
                    : <></>}

                  {selectedQuote.price1 > 0 ? (
                    <div
                      className={
                        selectedQuote && selectedShipMethod == 1
                          ? "box-pr pr-checked"
                          : "box-pr"
                      }
                      onClick={() =>
                        handleOnClickPriceBox(
                          selectedQuote.price1 + selectedQuote.shippingCharge2,
                          1
                        )
                      }
                    >
                      <PriceBox
                        errors={errors}
                        setErrors={setErrors}
                        finalPrice={(
                          selectedQuote.price1 + selectedQuote.shippingCharge2
                        ).toFixed(2)}
                        price={(
                          selectedQuote.price1 + selectedQuote.shippingCharge2
                        ).toFixed(2)}
                        business={
                          `${Math.floor(selectedQuote.leadTime2 / 7)}-${Math.floor(selectedQuote.leadTime2 / 7) + 1
                          }` + " weeks"
                        }
                        days={after15}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {selectedQuote.stManualPrice > 0 &&
                    <div className={selectedQuote && selectedShipMethod == 2 && selectedQuote.stManualPrice && (selectedQuote.stManualPrice == selectedPrice) ? "box-pr pr-checked" : "box-pr"}
                      onClick={() => handleOnClickPriceBox((selectedQuote.stManualPrice + selectedQuote.shippingCharge3), 2)}>
                      <PriceBox
                        errors={errors}
                        setErrors={setErrors}
                        finalPrice={(selectedQuote.stManualPrice + selectedQuote.shippingCharge3)}
                        price={(selectedQuote.stManualPrice + selectedQuote.shippingCharge3).toFixed(2)}
                        business={`${Math.floor(selectedQuote.leadTime3 / 7)}-${Math.floor(selectedQuote.leadTime3 / 7) + 1}` + " weeks"}
                        days={'N/A'} />
                    </div>
                  }

                </div>
              )}


              <div className="button-bx">
                <button
                  className="flex cursor-pointer rounded-sm w-full justify-center items-center gap-x-3 bg-primaryColor text-white  py-2 "
                  onClick={handleProceedToCheckout}
                >
                  <span>Proceed to checkout</span>
                  <Proceed />
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div
          className="price-box qoute-version-optimised-price"
          style={{ width: "50%" }}
        >
          {!isSubTotalZero ? <div>
            <div className={`flex   gap-x-2`}>
              <button onClick={() => setIsForwardToPurchaserModalOpen(true)} className="bg-primaryColor flex-1 h-9 flex gap-x-2 items-center justify-center text-sm text-white w-full rounded-sm ">
                <span>Forward To Purchaser</span>
                <ForwardToPurchaseIcon />

              </button>
              {selectedQuote?.isTargetCostActivated && !selectedQuote?.userTargetCost && !selectedQuote?.myorders ? <div onClick={() => setIsTargetCostModalOpen(true)} className="bg-primaryColor flex-1 h-9 flex items-center justify-center text-white gap-x-2 ">
                <span>Submit Target Cost</span>
                <DocIcon additionalClasses={'h-5'} />
              </div> : selectedQuote?.isTargetCostActivated && selectedQuote?.userTargetCost && !selectedQuote?.myorders ? <div onClick={() => setIsTargetCostModalOpen(true)} className="bg-slate-500 flex-1 h-9 flex items-center justify-center text-white gap-x-2 ">
                <span>Target Cost Submitted</span>
                <DocIcon additionalClasses={'h-5'} />
              </div> : null}
            </div>
          </div> : null}
          <div className="btm-buttons relative ">
            <Row style={{ marginBottom: "10px" }} className="flex">
              <Col className="h-9 flex-1">
                <ChatButton
                  RefId={selectedQuote.RefId}
                  showDrawer={showDrawer}
                  additionalClass={'!rounded-sm'}
                />
              </Col>
              <Col onClick={() => setisOpen((pre) => !pre)} className="bg-slate-200 flex flex-1 items-center justify-center gap-x-2 cursor-pointer">
                <span>More Options</span>
                <ArrowIcon additionalClasses={`h-4 ml-2 ${isOpen && "rotate-90 transition-all"}`} />
              </Col>
              {isOpen ? <div className="absolute z-20 left-[300px]  2xl:left-[450px] top-8 mt-2 w-60 bg-slate-100 text-black border border-white rounded shadow-lg ">
                <div>
                  <div
                    className=" w-full flex justify-between  items-center gap-x-2 px-4 py-2 text-sm hover:bg-slate-200"
                    onClick={(e) => handleTeamModal(e, selectedQuote)}
                  >
                    <span className="text-sm">Team</span>
                    <UsersIcon />
                  </div>
                </div>
                {selectedQuote._id &&
                  (((selectedQuote.isUpdatedByAdmin && selectedQuote.isSent) || selectedQuote.selectedProcess == 2) ||
                    selectedQuote.isRepeatOrderQuotation) ? (
                  <Col md={12}>
                    <Link
                      to={`/create-instant-quotation/${selectedQuote?._id}`}
                      className="flex  text-black py-2 justify-between px-4  gap-x-5  hover:bg-slate-200"
                    >
                      <span className="text-sm">Edit Quotation</span>
                      <EditIcon />
                    </Link>
                  </Col>
                ) : null}
                {!isSubTotalZero ? <Col >
                  <Link
                    className="flex  text-black hover:bg-slate-200 px-4 py-2 justify-between gap-x-5 rounded-sm"
                    onClick={handleQMShowClick}
                  >
                    <span className="text-sm whitespace-nowrap">Download Quotation   </span>
                    <DownloadIcon
                    />
                  </Link>
                </Col> : null}
                {
                  !isSubTotalZero ?
                    <Col>
                      <Link
                        className="flex hover:bg-slate-200 text-black px-4 py-2 justify-between gap-x-5 rounded-sm text-sm"
                        onClick={handleShareQuoteModalOpen}
                      >
                        <span className="text-sm">{selectedQuote._id ? "Share" : null}</span>
                        <ShareIcon />
                      </Link>
                    </Col> : null
                }
                {selectedQuote.isCancelled && !isSubTotalZero ?
                  <button
                    className="hover:bg-slate-200 h-10 sq-btn px-4 flex justify-between gap-x-2 items-center w-full text-sm"
                    onClick={handleMoveToProgress}
                  >
                    <span>Move To Progress</span>
                    <ArchiveMoveIcon additionalClasses={'h-5'} />
                  </button>
                  :
                  !isSubTotalZero &&
                  <button
                    className="h-10 hover:bg-slate-200 px-4 sq-btn flex items-center gap-x-2 justify-between  w-full text-sm"
                    onClick={handleArchiveQuotation}
                  >
                    <span> Archive</span>
                    <ArchiveIcon additionalClasses={'h-5'} />
                  </button>
                }
              </div> : null}


            </Row>
          </div>
        </div>

        <Modal
          size="sm"
          dialogClassName="qoute-version-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={shareQuoteModalShow}
          onHide={handleShareQuoteClose}
          className="border border-green-500 !flex justify-center items-center"
        >
          <Modal.Header closeButton >
            <Modal.Title
              id="contained-modal-title-vcenter "
              style={{ fontSize: "15px" }}

            >
              Share Quotation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="share-quote-div">
              <h6 className="my-2 ms-2  font-medium text-xs md:text-[15px]">
                Emails entered below will receive an email with Quotation
                details in their mailbox.
              </h6>
              <div className=" ms-2 flex md:flex-row flex-col md:justify-start md:items-center items-start gap-x-2">
                <input
                  placeholder="Enter the email  to share the quotation details  "
                  value={inputEmail}
                  onChange={(e) => setInputEmail(e.target.value)}
                  className="border border-slate-500 px-2 md:!w-96 sm:!w-56 py-2 w-36"
                />
                <button onClick={handleAddButtonToReceiverList} className="text-xs md:text-sm md:my-0 my-2  bg-primaryColor px-2 py-2 rounded text-white  ">
                  + Add Email
                </button>
              </div>
              <div className="share-content-div">
                <h6 className="ms-2">Receiver's List</h6>
                <div className="email-list-share-div">
                  {emailToShareQuote.size > 0
                    ? Array.from(emailToShareQuote).map((item, index) => {
                      return (
                        <span className="email-item" key={index}>
                          {item}{" "}
                          <img
                            src={closeImg}
                            className="close-img"
                            onClick={(e) => handleDeleteShareEmail(item)}
                          />
                        </span>
                      );
                    })
                    : null}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="share-quote-div-footer">
              <div className="share-button" onClick={handleQuotationShare}>
                Share
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
{/* 
      {selectedQuote.partsData.length > 0 ? (

        <div className="">
          <h5>Documentation required : </h5>
          <div className=" p-2 flex test justify-start gap-x-5 gap-y-3 flex-col md:flex-row items-start md:items-center">
            <div class="flex items-center">
              <input
                type="checkbox"
                id="sign-check-cc"
                name="checkbox"
                value={selectedQuote.isConformanceCertificateRequested}
                checked={selectedQuote.isConformanceCertificateRequested}
                class="w-4 h-4 text-blue-600 text-sm bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                disabled
              />
              <label
                for="sign-check-cc"
                class="ml-3 text-[15px] font-medium text-gray-900 "
              >
                Certificate of conformance
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="sign-check-dr"
                name="checkbox"
                value={selectedQuote.isDimensionalReportRequested}
                checked={selectedQuote.isDimensionalReportRequested}
                disabled
              />
              <label htmlFor="sign-check-dr" className="text-[15px] font-medium text-sm ">
                Dimensional Inspection Report
              </label>
            </div>
            {selectedProcess != 2 && <div className="flex justify-around items-center gap-x-2 ">
              <input
                type="checkbox"
                id="sign-check-qr"
                name="checkbox"
                value={selectedQuote.isQrTrackingRequested}
                checked={selectedQuote.isQrTrackingRequested}
                disabled
              />
              <label htmlFor="sign-check-qr" className="text-sm font-medium">
                QR Tracking{" "}
              </label>
              <QRTrackingHoverInfo />
            </div>}
          </div>
        </div>
      ) : (
        <></>
      )} */}
    </>
  );
}

export default QuoteVersionPriceBox;
