import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { useState } from "react";
import {
  getSurfaceFinishDetailsById,
  updateSurfaceFinish,
} from "../../../utils/actions/allactions";
import {
  validateAdmin,
  numberInputOnWheelPreventChange,
  getToken,
} from "../../../utils/helper";
import useDocumentTitle from "../../../utils/useDocumentTitle";

import { toast } from "react-toastify";
import SkeltonLoadingComponent from "../../../components/LoadingComponents/SkeltonLoadingComponent";

const EditSurfaceFinishMaster = () => {
  const token = getToken();
  const navigate = useNavigate();
  useDocumentTitle("Edit Surface Finish");
  const [initialValue, setInitialValue] = useState(null);
  const [error, setError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  useEffect(() => {
    // validateAdmin(token, navigate);c
    const fetchData = async () => {
      try {
        const data = await getSurfaceFinishDetailsById(id);
        if (data && data.succes) {
          setInitialValue(data.data);
        } else {
          setError("Failed To Fetch Data");
        }
      } catch (error) {
        toast.error("Failed To Fetch Data In Edit Surface Finish");
      }
    };
    fetchData();
  }, []);

  const validatedSurfaceFinish = () => {
    if (!initialValue.name || !initialValue.SfFactor) {
      return false;
    }
    return true;
  };

  const handleEditTolerance = async () => {
    setError("");
    const isSurfaceFinishValid = validatedSurfaceFinish();

    if (!isSurfaceFinishValid) {
      setError("Enter valid values for all the field");
      return;
    }
    try {
      const updatedSurfaceFinish = await updateSurfaceFinish(
        token,
        initialValue
      );

      if (updatedSurfaceFinish?.data?.data?.code == 11000) {
        toast.error("Surface finish already exists");
      } else {
        navigate("/admin/surface-finish");
        toast.success("Surface finish updated successfully");
      }
    } catch (error) {
      toast.error("Unable To Update Surface Finish ");
    }
  };

  return (
    <div className="admin-container">
      <div>
        <Sidebar />
      </div>
      <div className="admin-panel-container">
        <h1 className="center-heading text-2xl my-2 font-bold">
          {" "}
          Edit Surface Finish{" "}
        </h1>

        {initialValue ? (
          <div className="edit-company-container">
            <div>
              <label htmlFor="name"> Name </label>: &nbsp;
              <input
                name="name"
                type="text"
                placeholder="Enter  name"
                value={initialValue.name}
                onChange={(e) => {
                  setInitialValue({ ...initialValue, name: e.target.value });
                }}
              />
            </div>

            <div>
              <label htmlFor="SfFactor"> SfFactor </label>: &nbsp;
              <input
                name="SfFactor"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                placeholder="Enter SfFactor"
                value={initialValue.SfFactor || ""}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    SfFactor: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <SkeltonLoadingComponent />
        )}

        <div className="center-heading">
          <button className="button-search" onClick={handleEditTolerance}>
            {" "}
            Update{" "}
          </button>
        </div>

        {error ? (
          <div
            style={{
              color: "red",
              fontSize: "15px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            {error}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EditSurfaceFinishMaster;
