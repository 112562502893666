import React, { useEffect, useState } from 'react';
import Sidebar from "../Sidebar";
import {  useNavigate, useParams } from 'react-router-dom';
import { 
    getAllSuppliers,
    getSupplierUserById,
    createSupplierUser,
    updateSupplierUser,
} from '../../utils/actions/supplierActions';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import useDocumentTitle from '../../utils/useDocumentTitle';


const AddSupplierUser = () => {
    const {_id} = useParams();
    useDocumentTitle('Add/Edit Supplier User');
    const [allSuppliers,setAllSuppliers] = useState([]);
    const [userValue,setUserValue] = useState({
        firstName:'',
        lastName:'',
        email:'',
        password:'',
        isVerified:true,
        userType:'user',
        role:'general',
        accountStatus:'Active',
        supplierId:'',
        phoneNumber:'',
    });
    const [error,setError] = useState("");
    const token = localStorage.getItem("Token");
    const navigate = useNavigate();

    
    useEffect(()=>{
        fetchData();
    },[])

    const fetchData = async()=>{
        const allSupplierResponse = await getAllSuppliers(token);
       
        if(allSupplierResponse.status){
            setAllSuppliers(allSupplierResponse.data);
        }

        if(_id){
            const userResponse = await getSupplierUserById(_id,token);
            setUserValue(userResponse.data)
        }
    }

    


    const handleUpdateSupplierUser = async()=>{
        setError("")
        const isValid = validateData();
        if(isValid){
            const updatedUserResponse = await updateSupplierUser(userValue,token);
            if(updatedUserResponse.status){
                toast.success(updatedUserResponse?.message); 
                navigate('/admin/supplier-user-master')
            }else{
                toast.error(updatedUserResponse?.data?.message)
            }  
        }else{
            setError("First name, Email, supplierId, Account Status, role are required field.")
        }
    }


    const handleCreateSupplierUser =async() =>{
        setError("")
        const isValid = validateData();
        if(isValid){
            const isValidEmailResponse = isValidEmail();
            if(!isValidEmailResponse){
                setError("Enter a valid email address")
            }else{
                const createUserResponse = await createSupplierUser(userValue,token);
                if(createUserResponse.status){
                    toast.success(createUserResponse?.message); 
                    navigate('/admin/supplier-user-master')
                }else{
                    toast.error(createUserResponse?.data?.message)
                }
            }
        }else{
            setError("First name, Email, supplierId, Account Status, role are required field.")
        }
    }

    const isValidEmail = () => {
        let regex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$');
        return regex.test(userValue.email);
    }

    const validateData = () =>{
        if(!userValue.firstName || !userValue.email || !userValue.password || !userValue.supplierId ){
            return false;
        }
        return true;
    }

    return (
        <div className="admin-container">
        <Sidebar />
        <div className="admin-panel-container" >
            <h1 className="center-heading text-xl font-bold my-2">{_id?"Edit":"Add"} Supplier Users</h1>
            <div className="edit-company-container">
                <div>
                    <label htmlFor="firstName" >First Name </label>: &nbsp;
                    <input
                        name="firstName"
                        type="text"
                        placeholder="Enter first name"
                        value={userValue.firstName}
                        onChange={(e) => { setUserValue({...userValue,firstName:e.target.value})}}
                    />
                </div>

                <div>
                    <label htmlFor="lastName" >Last Name </label>: &nbsp;
                    <input
                        name="lastName"
                        type="text"
                        placeholder="Enter last name"
                        value={userValue.lastName}
                        onChange={(e) => { setUserValue({...userValue,lastName:e.target.value})}}
                    />
                </div>

                <div>
                    <label htmlFor="email" >Email </label>: &nbsp;
                    <input
                        name="email"
                        type="text"
                        placeholder="Enter email"
                        value={userValue.email}
                        disabled={_id}
                        onChange={(e) => { setUserValue({...userValue,email:e.target.value})}}
                    />
                </div>

                {_id?
                <>
                </>
                :
                <>
                    <div>
                        <label htmlFor="password" >Password </label>: &nbsp;
                        <input
                            name="password"
                            type="password"
                            placeholder="Enter password"
                            value={userValue.password}
                            onChange={(e) => { setUserValue({...userValue,password:e.target.value})}}
                        />
                    </div>
                </>}
                

                <div>
                    <label htmlFor="account-status" >Account Status</label>: &nbsp;
                    <input
                        name="account-status"
                        type="radio"
                        value="Active"
                        checked={userValue.accountStatus=="Active"}
                        onChange={(e) => {setUserValue({...userValue,accountStatus:e.target.value})}}
                    />Active
                    <input
                        name="account-status"
                        type="radio"
                        value="Inactive"
                        checked={userValue.accountStatus=="Inactive"}
                        onChange={(e) => {setUserValue({...userValue,accountStatus:e.target.value})}}
                    />Inactive
                </div>

                <div>
                    <label htmlFor="user-role" >Supplier User Type</label>: &nbsp;
                    <input
                        name="user-role"
                        type="radio"
                        value="general"
                        checked={userValue.role=="general"}
                        onChange={(e) => {setUserValue({...userValue,role:e.target.value})}}
                    />General
                    <input
                        name="user-role"
                        type="radio"
                        value="admin"
                        checked={userValue.role=="admin"}
                        onChange={(e) => {setUserValue({...userValue,role:e.target.value})}}
                    />Admin
                </div>
             
                <div>
                    <label htmlFor="supplierId">Supplier Company</label>: &nbsp;
                    <select name='supplierId' onChange={(e) => setUserValue({...userValue,supplierId:e.target.value})} value={userValue.supplierId?userValue.supplierId:'default'} defaultValue={userValue.supplierId?userValue.supplierId:'default'}>
                    <option value="default" disabled > Select supplier</option>
                    {
                        allSuppliers ? allSuppliers.map((item, index) => {
                        return <option key={index} value={item._id}  >{item.companyName}</option>
                        }) : null
                    }
                    </select>
                </div>


                <div style={{ display: 'flex', width: '100%' }}>
                    <label htmlFor="phoneNumber">Phone Number </label>: &nbsp;
                    <div  style={{ width: '40%' }}>
                        <PhoneInput
                            placeholder="Enter phone number"
                            name="phone"
                            value={userValue.phoneNumber}
                            onChange={(val)=> setUserValue({...userValue,phoneNumber:val})}
                        />
                    </div> 
                </div>
                <br/>
                {_id?
                    <>
                         <div className="center-heading">
                            <button className="button-search supplierButton" onClick={handleUpdateSupplierUser} >  Update Supplier User </button>
                        </div>
                    </>:
                    <>
                        <div className="center-heading">
                            <button className="button-search supplierButton" onClick={handleCreateSupplierUser} >  Create Supplier User </button>
                        </div>
                    </>
                    }
                    
                    {
                        error ?
                            <div style={{ color: 'red', fontSize: '15px', textAlign: 'center', marginTop: '10px' }}>{error}</div>
                            : null
                    }

                




            </div>
        </div>
    </div >
    );
}

export default AddSupplierUser;
