import React from "react";
import PhotoViewer from "../../../components/PhotoViewer/PhotoViewer";
import logo from "../../../images/logo.svg";
import { Link } from "react-router-dom";
import img1 from "../ProjectsImages/CompleteAssembly/img (1).jpg";
import img2 from "../ProjectsImages/CompleteAssembly/img (2).jpg";
import img3 from "../ProjectsImages/CompleteAssembly/img (3).jpg";
import img4 from "../ProjectsImages/CompleteAssembly/img (4).jpg";
import img5 from "../ProjectsImages/CompleteAssembly/img (5).jpg";
import img6 from "../ProjectsImages/CompleteAssembly/img (6).jpg";
import img7 from "../ProjectsImages/CompleteAssembly/img (7).jpg";
import img8 from "../ProjectsImages/CompleteAssembly/img (8).jpg";
import img9 from "../ProjectsImages/CompleteAssembly/img (9).jpg";
import img10 from "../ProjectsImages/CompleteAssembly/img (10).jpg";
import img11 from "../ProjectsImages/CompleteAssembly/img (11).jpg";
import img12 from "../ProjectsImages/CompleteAssembly/img (12).jpg";
import img13 from "../ProjectsImages/CompleteAssembly/img (13).jpg";
import img14 from "../ProjectsImages/CompleteAssembly/img (14).jpg";
import img15 from "../ProjectsImages/CompleteAssembly/img (15).jpg";

const CompleteAssemblyProjects = () => {
  const imageArr = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
  ];

  return (
    <>
      <div className="pvtop-bar">
        <Link to="">
          <img src={logo} />
        </Link>{" "}
        <Link to="/projects" className="back-btn">
          Back
        </Link>
      </div>
      <div className="csr-section">
        <h4 style={{ textAlign: "center" }}>Complete Assembly Projects</h4>

        <span>
          <PhotoViewer images={imageArr} altText={`image`} />
        </span>
      </div>
    </>
  );
};

export default CompleteAssemblyProjects;
