import React from "react";

const dummyRows = 15;

const AdminLoadingComponent = ({ rows, columns }) => {
  const numberOfRows = rows || 10;
  const numberOfColumns = columns || 10;
  return (
    <tbody className="w-full border ">
      {Array.from(Array(numberOfRows)).map((_, idx) => {
        return (
          <tr className="w-full">
            {Array.from(Array(numberOfColumns)).map((_, item) => {
              return (
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 "></div>
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default AdminLoadingComponent;
