import React from 'react';
import { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { getToken,validateAdmin } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { getQualityRelatedIssue,addQualityRelatedIssue,deleteQualityRelatedIssue } from '../../utils/actions/allactions';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { toast } from 'react-toastify';
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents';


const QualityIssue = () => {
    let navigate = useNavigate();
    useDocumentTitle('Quality Issues')
    const [data, setData] = useState([]);
    const [newQualityRelatedIssue,setNewQualityRelatedIssue] = useState('');
    let token = getToken();

    const fetchQualityRelatedIssue = async () => {
        try {
            const qualityRelatedIssueData = await getQualityRelatedIssue(token);
            setData(qualityRelatedIssueData);
            
        } catch (error) {
            toast.error("Unable To Fetch Quality Issues Data");
        }
       
    }

    useEffect(() => {
        validateAdmin(token, navigate);
        fetchQualityRelatedIssue();
    }, []);

  

    const handleQualityRelatedIssueDelete = async (item) =>{
        try {
            let response = await deleteQualityRelatedIssue(token,{id:item._id})
            setData(response.data.data);
        } catch (error) {
            console.log(error);
        }
     
    }

    const handleCreateNew = async () =>{
        let response = await addQualityRelatedIssue(token,newQualityRelatedIssue)
        setData(response.data.data);
        setNewQualityRelatedIssue({name:''});
    }


    return (
        <div className="admin-container">
       
        <div className="right-container">
            <div className="center-heading">
                <h1>  Quality Related Issue  </h1>
                <div className="quotes-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col" style={{ cursor: 'pointer' }} >Name  </th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.length > 0 &&
                                data.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item.name ? item.name : ''}</td>
                                            <td> <button className="button-edit" onClick={() => { handleQualityRelatedIssueDelete(item) }}>Delete</button></td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>

                <div className="search-bar">
                 <div className="row">
                 <div className='col-md-6'>
                     </div>
                     <div className='col-md-3'>
                         <input 
                         type="text" 
                         placeholder='Enter purchase order related issue ' 
                         style={{padding:'5px',width:'100%'}}
                         value={newQualityRelatedIssue.name}
                         onChange={(e) => {setNewQualityRelatedIssue({...newQualityRelatedIssue,name:e.target.value})}}
                         />
                     </div>
                     <div className="col-md-3" style={{textAlign:'left'}}>
                         <div className="search-btn">
                             <button onClick={handleCreateNew} className="button-search">Create New</button>
                         </div>
                     </div>
                 </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default AdminDashboardComponents(QualityIssue);
