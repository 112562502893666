import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import { useState } from "react";
import { createCompany } from '../../utils/actions/allactions';
import { toast } from "react-toastify";

import PhoneInput from 'react-phone-number-input';
import { validateAdmin,getToken,numberInputOnWheelPreventChange } from "../../utils/helper";
import useDocumentTitle from "../../utils/useDocumentTitle";

const AddCompany = () => {
    const token = getToken();
    const navigate = useNavigate();
    useDocumentTitle('Add Client Company')
    const [companyValue, setCompanyValue] = useState('');
    const [error, setError] = useState("");
    const [phoneValue, setPhoneValue] = useState('');


    useEffect(() => {
        validateAdmin(token, navigate);
    }, []);

    const validateCompanyValue = () => {
        if (!companyValue.companyName ||
            !companyValue?.companySite?.length > 0 ||
            !companyValue.address1 ||
            !companyValue.domain ||
            !companyValue.status ||
            !companyValue.country ||
            !companyValue.suffix ||
            !companyValue.paymentTerm||
            !companyValue.companyInvoiceEmail||
            !companyValue.companyEmail) {
            return false;
        }
        return true;
    }



    const handleCreateCompany = async () => {
        setError('');
        const isCompanyDataValid = validateCompanyValue();

        if (!isCompanyDataValid) {
            setError("Enter valid values for all the field. (Company name,company Site, address1,domain,status,country,suffix,vatNumber and payment terms are mandatory field)")
        }
        else if (companyValue.suffix.length !== 2) {
            setError("Suffix should contain only 2 letter")
        } else {
            const data = { ...companyValue, phoneNumber: phoneValue||"", taxes:companyValue.taxes? companyValue.taxes:0 }
            const addedCompany = await createCompany(token, data);
            toast.success("Company added successfully")
            navigate('/admin/company-master')
        }
    }


    const handleDeleteSite = (i) => {
        const updateSite = companyValue.companySite;
        if (i > -1) {
            updateSite.splice(i, 1);
        }
        setCompanyValue({ ...companyValue, companySite: updateSite })
    }

    const handledAddSite = () => {
        let addedAddress = companyValue.companySite ? companyValue.companySite : [];
        addedAddress.push(" ")
        setCompanyValue({ ...companyValue, companySite: addedAddress })
    }


    const handleSiteOnChange = (e, index) => {
        let allSites = companyValue.companySite ? companyValue.companySite : [];
        let companySite = allSites[index];
        companySite = e.target.value;
        allSites[index] = companySite;
        setCompanyValue({ ...companyValue, companySite: allSites })
    }

    return (
        <div  className="admin-container" >
            <div>
                <Sidebar />
            </div>
            <div className="admin-panel-container">
                <h1 className="center-heading text-xl font-bold mt-2">  Create Company </h1>

                <div className="edit-company-container">
                    <div>
                        <label htmlFor="companyName" >Company Name </label>: &nbsp;
                        <input
                            name="companyName"
                            className="p-2 my-2"
                            type="text"
                            placeholder="Enter company name"
                            value={companyValue.companyName}
                            onChange={(e) => { setCompanyValue({ ...companyValue, companyName: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="suffix" >Suffix</label>: &nbsp;
                        <input
                                                    className="p-2 my-2"

                            name="suffix"
                            type="text"
                            placeholder="Enter suffix for company"
                            value={companyValue.suffix}
                            onChange={(e) => { setCompanyValue({ ...companyValue, suffix: e.target.value }) }}
                        />
                    </div>



                    <div>
                        <label htmlFor="country" >Country </label>: &nbsp;
                        <input
                                                    className="p-2 my-2"

                            name="country"
                            type="text"
                            placeholder="Enter country"
                            value={companyValue.country}
                            onChange={(e) => { setCompanyValue({ ...companyValue, country: e.target.value }) }}
                        />
                    </div>


                    <div>
                        <label htmlFor="domain" >Domain </label>: &nbsp;
                        <input
                                                    className="p-2 my-2"

                            name="domain"
                            type="text"
                            value={companyValue.domain}
                            placeholder="Enter company domain"
                            onChange={(e) => { setCompanyValue({ ...companyValue, domain: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="vatNumber" >Tax Number </label>: &nbsp;
                        <input
                                                    className="p-2 my-2"

                            name="vatNumber"
                            type="text"
                            value={companyValue.vatNumber}
                            placeholder="Enter company Tax Number"
                            onChange={(e) => { setCompanyValue({ ...companyValue, vatNumber: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="paymentTerm" >Payment Terms </label>: &nbsp;
                        <input
                                                    className="p-2 my-2"

                            name="paymentTerm"
                            type="number"
                            placeholder="Enter company pay terms days"
                            value={companyValue.paymentTerm}
                            onChange={(e) => { setCompanyValue({ ...companyValue, paymentTerm: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="companyInvoiceEmail" >Accounts Payable Email </label>: &nbsp;
                        <input
                                                    className="p-2 my-2"

                            name="companyInvoiceEmail"
                            type="text"
                            placeholder="Enter company invoice email"
                            value={companyValue.companyInvoiceEmail}
                            onChange={(e) => { setCompanyValue({ ...companyValue, companyInvoiceEmail: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="companyEmail" >Company Email </label>: &nbsp;
                        <input
                                                    className="p-2 my-2"

                            name="companyEmail"
                            type="text"
                            placeholder="Enter company email"
                            value={companyValue.companyEmail}
                            onChange={(e) => { setCompanyValue({ ...companyValue, companyEmail: e.target.value }) }}
                        />
                    </div>


                    <div>
                        <label htmlFor="taxes" >Tax </label>: &nbsp;
                        <input
                                                    className="p-2 my-2"

                            name="taxes"
                            type="number"
                            onWheel={numberInputOnWheelPreventChange}
                            value={companyValue.taxes}
                            placeholder="Enter taxes"
                            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                            onChange={(e) => { setCompanyValue({ ...companyValue, taxes: e.target.value }) }}
                        />
                    </div>

                    <div style={{ display: 'flex', width: '100%' }}>
                        <div>
                            <label htmlFor="phone" >Phone </label>: &nbsp;
                        </div>
                        <div style={{ width: '40%' }}>
                            <PhoneInput
                                placeholder="Enter phone number"
                                name="phone"
                                value={phoneValue}
                                onChange={setPhoneValue}
                                className="p-2 my-2"

                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="address1" >Address1</label>: &nbsp;
                        <input
                                                    className="p-2 my-2"

                            name="address1"
                            type="text"
                            placeholder="Enter address 1"
                            value={companyValue.address1}
                            onChange={(e) => { setCompanyValue({ ...companyValue, address1: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="address2" >Address 2</label>: &nbsp;
                        <input
                                                    className="p-2 my-2"

                            name="address2"
                            type="text"
                            value={companyValue.address2}
                            placeholder="Enter address 2 "
                            onChange={(e) => { setCompanyValue({ ...companyValue, address2: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="address3" >Address3</label>: &nbsp;
                        <input
                                                    className="p-2 my-2"

                            name="address3"
                            type="text"
                            placeholder="Enter address 3"
                            value={companyValue.address3}
                            onChange={(e) => { setCompanyValue({ ...companyValue, address3: e.target.value }) }}
                        />
                    </div>


                    <div>
                        <label htmlFor="compamy-status" >Company Status</label>: &nbsp;
                        <input
                                                    className="p-2 my-2"

                            name="company-status"
                            type="radio"
                            value="Active"
                            onChange={(e) => { setCompanyValue({ ...companyValue, status: e.target.value }) }}
                        />Active

                        <input
                                                    className="p-2 my-2"

                            name="company-status"
                            type="radio"
                            value="Inactive"
                            onChange={(e) => { setCompanyValue({ ...companyValue, status: e.target.value }) }}
                        />Inactive
                    </div>

                    <div>
                        {
                            companyValue.companySite ? companyValue.companySite.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <label htmlFor="companySite" >Company Site {i + 1} </label>: &nbsp;
                                        <input
                                                                    className="p-2 my-2"

                                            name="companySite"
                                            type="text"
                                            placeholder="Enter companySite"
                                            value={item}
                                            onChange={(e) => { handleSiteOnChange(e, i) }}
                                        />
                                        <button className="button-edit" onClick={() => { handleDeleteSite(i) }}>Delete Site</button>
                                    </div>
                                )
                            }) : null
                        }

                        <button className="button-add" onClick={handledAddSite}>Add Site</button>

                    </div>




                    <div className="center-heading">
                        <button className="button-search" onClick={handleCreateCompany}>  Create Company </button>
                    </div>
                    {
                        error ?
                            <div style={{ color: 'red', fontSize: '15px', textAlign: 'center', marginTop: '10px' }}>{error}</div>
                            : null
                    }
                </div>
            </div >
        </div >
    )
}

export default AddCompany;