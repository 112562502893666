import React, { useEffect } from "react";
import { useState } from "react";
import SimpleModal from "../../../components/Modal/Modal";
import { getAllBulkPricing } from "../../../utils/actions/allactions";
import { currencyFormatter } from "../../../utils/helper";


const BulkPricingModal = ({ showBulkPricing, setShowBulkPricing, price }) => {
  const [pricingData, setPricingData] = useState([]);
  const token = localStorage.getItem("Token");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getAllBulkPricing(token);
        setPricingData(response.data);
      } catch (err) {
        console.log(err);
        return err;
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <SimpleModal
        isShowModal={showBulkPricing}
        onCloseModal={() => {
          setShowBulkPricing(false);
        }}
        showClose={true}
        className="addressModal"
      >
        <div className="title">Bulk Pricing</div>
        <div className="straightLine"></div>

        <div style={{ padding:"36px", marginLeft:"68px", width:"80%"}}>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Quantity</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Total Price</th>
                {/* <th scope="col">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {pricingData?.map((el, i) => (
                <tr key={i}>
                  <td>{el?.qty}</td>
                  <td>{currencyFormatter((price - (price * el?.percentage / 100)).toFixed(2))}</td>
                  <td>{currencyFormatter(((price - (price * el?.percentage / 100))*el?.qty).toFixed(2))}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="bottomLine"></div>
      </SimpleModal>
    </div>
  );
};

export default BulkPricingModal;
