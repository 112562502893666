import React, { useEffect, useState } from 'react';
import { getMaterial } from '../../utils/actions/allactions';
import { toast } from 'react-toastify';
import { CrossIcon } from '../Icons';

const RFQTableMasterMaterial = ({
    item,
    index,
    handleMaterialMasterChange,
    materialType,
    subMaterialType,
    surfaceTreatmentType,
    surfaceFinishType,
    toleranceType,
    partMakingType,
    layerType,
    fullMasterData,
}) => {
    const [material, setMaterial] = useState([]);
    const [subMaterial, setSubMaterial] = useState([]);
    const [tolerance, setTolerance] = useState([]);
    const [partMarking, setPartMarking] = useState([]);
    const [surfaceTreatment, setSurfaceTreatment] = useState([]);
    const [surfaceFinish, setSurfaceFinish] = useState([]);
    const [layer, setLayer] = useState([]);
    const [selectedMaterialId, setSelectedMaterialId] = useState(item.materialType || "");
    const [masterData, setMasterData] = useState(fullMasterData);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if(fullMasterData)
        {
            setMaterial(fullMasterData?.material);
            setSubMaterial(fullMasterData?.submaterial);
            setPartMarking(fullMasterData?.partMarking);
            setSurfaceFinish(fullMasterData?.surafcefinish);
            setSurfaceTreatment(fullMasterData?.surfacetreat);
            setTolerance(fullMasterData?.tolerance);
            setLayer(fullMasterData?.layer);
            setMasterData(fullMasterData);
        }
    }, [fullMasterData]);

    // Filter data based on selected material
    useEffect(() => {
        if (masterData && selectedMaterialId) {
            setSubMaterial(() =>
                masterData?.submaterial?.filter(
                    (item) => item.masterMaterialId === selectedMaterialId
                )
            );
            setSurfaceTreatment(() =>
                masterData?.surfacetreat?.filter((item) =>
                    item?.materialMasters?.includes(selectedMaterialId)
                )
            );
        }
    }, [selectedMaterialId, masterData]);

    const handleCloseModal = () => setShowModal(false);
    const handleOpenModal = () => setShowModal(true);
    const handleSave = () => {
        setShowModal(false);
    };

    return (
        <div className='ms-4 my-1'>
            <ul className='list-disc flex flex-col container-item-list'>
                {materialType && <li>{materialType}</li>}
                {subMaterialType && <li>{subMaterialType}</li>}
                {surfaceTreatmentType && <li>{surfaceTreatmentType}</li>}
                {surfaceFinishType && <li>{surfaceFinishType}</li>}
                {toleranceType && <li>{toleranceType}</li>}
                {partMakingType && <li>{partMakingType}</li>}
                {layerType && <li>{layerType}</li>}
                <li>
                    <button className='text-blue-500 hover:underline' onClick={handleOpenModal}>
                        Edit
                    </button>
                </li>
            </ul>

            {/* Modal */}
            {showModal && (
                <div
                    className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50"
                    onClick={handleCloseModal}
                >
                    <div
                        className="bg-white rounded-lg w-96 h-96 p-6 relative"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h3 className="text-lg font-semibold mb-4">Edit Material Details</h3>

                        {/* Material Type */}
                        <div className="flex justify-between items-center my-2">
                            <span className="font-medium text-[14px]">Material Type</span>
                            <select
                                value={item.materialType || ""}
                                className="h-7 border w-[150px] mx-2"
                                onChange={(e) => {
                                    handleMaterialMasterChange(e, 'materialType', index);
                                    setSelectedMaterialId(e.target.value);
                                }}
                            >
                                <option value="">Not Selected</option>
                                {material.map((materialItem) => (
                                    <option value={materialItem._id} key={materialItem._id}>
                                        {materialItem.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* SubMaterial Type */}
                        <div className="flex justify-between items-center my-2">
                            <span className="font-medium text-[14px]">SubMaterial Type</span>
                            <select
                                value={item.subMaterial || ""}
                                className="h-7 border w-[150px] mx-2"
                                onChange={(e) => handleMaterialMasterChange(e, 'subMaterial', index)}
                            >
                                <option value="">Not Selected</option>
                                {subMaterial.map((materialItem) => (
                                    <option value={materialItem._id} key={materialItem._id}>
                                        {materialItem.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Surface Treatment */}
                        <div className="flex justify-between items-center my-2">
                            <span className="font-medium text-[14px]">Surface Treatment</span>
                            <select
                                value={item.surfaceTreatment || ""}
                                className="h-7 border w-[150px] mx-2"
                                onChange={(e) => handleMaterialMasterChange(e, 'surfaceTreatment', index)}
                            >
                                <option value="">Not Selected</option>
                                {surfaceTreatment.map((materialItem) => (
                                    <option value={materialItem._id} key={materialItem._id}>
                                        {materialItem.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Surface Finish */}
                        <div className="flex justify-between items-center my-2">
                            <span className="font-medium text-[14px]">Surface Finish</span>
                            <select
                                value={item.surfaceFinish || ""}
                                className="h-7 border w-[150px] mx-2"
                                onChange={(e) => handleMaterialMasterChange(e, 'surfaceFinish', index)}
                            >
                                <option value="">Not Selected</option>
                                {surfaceFinish.map((materialItem) => (
                                    <option value={materialItem._id} key={materialItem._id}>
                                        {materialItem.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/* Tolerance */}
                        <div className="flex justify-between items-center my-2">
                            <span className="font-medium text-[14px]">Tolerance</span>
                            <select
                                value={item.tolerance || ""}
                                className="h-7 border w-[150px] mx-2"
                                onChange={(e) => handleMaterialMasterChange(e, 'tolerance', index)}
                            >
                                <option value="">Not Selected</option>
                                {tolerance.map((materialItem) => (
                                    <option value={materialItem._id} key={materialItem._id}>
                                        {materialItem.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Part Making */}
                        <div className="flex justify-between items-center my-2">
                            <span className="font-medium text-[14px]">Part Making</span>
                            <select
                                value={item.partMarking || ""}
                                className="h-7 border w-[150px] mx-2"
                                onChange={(e) => handleMaterialMasterChange(e, 'partMarking', index)}
                            >
                                <option value="">Not Selected</option>
                                {partMarking.map((materialItem) => (
                                    <option value={materialItem._id} key={materialItem._id}>
                                        {materialItem.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Layer */}
                        <div className="flex justify-between items-center my-2">
                            <span className="font-medium text-[14px]">Layer</span>
                            <select
                                value={item.layer || ""}
                                className="h-7 border w-[150px] mx-2"
                                onChange={(e) => handleMaterialMasterChange(e, 'layer', index)}
                            >
                                <option value="">Not Selected</option>
                                {layer.map((materialItem) => (
                                    <option value={materialItem._id} key={materialItem._id}>
                                        {materialItem.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <button
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                            onClick={handleCloseModal}
                        >
                            <CrossIcon/>
                        </button>
                        <div className="!my-4 flex justify-end">
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                                onClick={handleCloseModal}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-complementaryPrimaryColor text-white px-4 py-2 rounded"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RFQTableMasterMaterial;
