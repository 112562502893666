import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundComponent = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404</h1>
      <p>Oops! The page you're looking for doesn't exist.</p>
      <Link to="/login" style={{ textDecoration: 'none', color: '#007bff' }}>
        Go Back Home
      </Link>
    </div>
  );
};

export default NotFoundComponent;
