import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const lineChartData = [
    { name: 'Jan', Quotation: 300, Sales: 240, Shipped: 280, Completed: 300, Issues: 100 },
    { name: 'Feb', Quotation: 400, Sales: 320, Shipped: 360, Completed: 400, Issues: 120 },
    { name: 'Mar', Quotation: 350, Sales: 410, Shipped: 380, Completed: 390, Issues: 150 },
    { name: 'Apr', Quotation: 420, Sales: 500, Shipped: 460, Completed: 420, Issues: 180 },
    { name: 'May', Quotation: 450, Sales: 480, Shipped: 490, Completed: 450, Issues: 170 },
];

const LineChartComponent = () => {
    return (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart data={lineChartData} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Quotation" stroke="#8884d8" />
                <Line type="monotone" dataKey="Sales" stroke="#82ca9d" />
                <Line type="monotone" dataKey="Shipped" stroke="#8dd1e1" />
                <Line type="monotone" dataKey="Completed" stroke="#a4de6c" />
                <Line type="monotone" dataKey="Issues" stroke="#ff6666" />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default LineChartComponent;
