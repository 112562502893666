import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Context } from "./Context";

import { LinearProgress } from "@material-ui/core";

import { Mutex } from 'async-mutex';
import {
  useFrame,
  useThree,
} from "react-three-fiber";
import {
  TrackballControls,
  Sky,
} from 'drei';
import {
  Color,
  Vector3,
} from "three";

import HeadsUpDisplay from "./HeadsUpDisplay";
import GridFloor from "./GridFloor";
import BoundingBox from "./BoundingBox";

import Viewport from "./Viewport";

import {setCameraPosition} from "./setCameraPosition";

export default function CADViewer({setOverlayContent, grid, boundingBox, resetCamera, renderStyle, openCascadeWorkerClass, controlsRef, cameraRef}) {
  const {state, dispatch} = useContext(Context);
  const {camera} = useThree();

  cameraRef.current = useMemo(() => camera, [camera]);

  const [openCascadeWorker, setOpenCascadeWorker] = useState();
  useEffect(() => {
    const workerInstance = new openCascadeWorkerClass();
    (async () => {
      const obj = await new workerInstance.OpenCascade();
      await obj.init();
      // setInterval(() => {
      //   obj.printEmscriptenCalls();
      // }, 1000);
      setOpenCascadeWorker({
        worker: obj,
        mutex: new Mutex()
      });
    })();
  }, [dispatch, openCascadeWorkerClass]);

  useEffect(() => {
    if(state.files.length === 0 || state.activeFileIndex === -1) {
      setOverlayContent();
      return;
    }
    const file = state.files[state.activeFileIndex];
    if(file === undefined) {
      setOverlayContent();
      return;
    }

    const overlayContent = (file.properties.analysisProgress === 1) ? null : (
      <div
        style={{
          width: "25%",
          textAlign: "center",
        }}
      >
        <span
          style={{
            margin: "8px",
          }}
        >
          Analyzing
        </span>
        <LinearProgress
          variant="determinate"
          value={file.properties.analysisProgress*100}
          style={{
            width: "100%",
          }}
        />
      </div>
    );
    setOverlayContent(overlayContent);
  }, [
    state.activeFileIndex,
    state.files,
    setOverlayContent,
  ]);

  const {
    setDefaultCamera,
    size,
  } = useThree();
  useEffect(() => {
    setDefaultCamera(cameraRef.current);
    window.cam = cameraRef.current;
  }, [setDefaultCamera, cameraRef]);
  useFrame(() => cameraRef.current.updateMatrixWorld());

  const gridSize = useMemo(() => {
    const box = state.files[state.activeFileIndex]?.properties?.aabb;
    if(box === undefined) return 50;

    function orderOfMagnitude(n) {
      const order = Math.floor(Math.log(n) / Math.LN10 + 0.000000001);
      return Math.pow(10, order);
    }
    
    function roundTo(x, val) {
      return Math.ceil(x / val) * val;
    }

    const delta = Math.max(box.max.x - box.min.x, box.max.z - box.min.z)*1.1;
    const order = orderOfMagnitude(delta);
    const major = delta/order;
    return roundTo(major, 2.5) * order;
  }, [state.activeFileIndex, state.files]);

  const [originPoint, setOriginPoint] = useState(new Vector3(0, 0, 0));
  const [axesScale, setAxesScale] = useState(1);
  const [viewportReady, setViewportReady] = useState(true);

  useEffect(() => {
    if(state.files[state.activeFileIndex]?.properties?.analysisProgress !== 1) {
      setCameraPosition(null, camera, controlsRef.current);
      setAxesScale(2);
      setOriginPoint(new Vector3(0, 0, 0));
      setViewportReady(true);
      return;
    }

    const box = state.files[state.activeFileIndex]?.properties?.aabb;
    setCameraPosition(box, camera, controlsRef.current);
    const size = box.getSize(new Vector3()).length();

    const origin = new Vector3(
      box.min.x + (box.max.x - box.min.x) / 2,
      box.min.y,
      box.min.z + (box.max.z - box.min.z) / 2
    );
    setOriginPoint(origin);

    setAxesScale(size / 10);
    setViewportReady(true);
  }, [
    controlsRef,
    camera,
    state.activeFileIndex,
    state.files,
    resetCamera,
  ]);

  return <>
    <TrackballControls
      ref={controlsRef}
    />
    <orthographicCamera
      ref={cameraRef}
      left={-size.width/2}
      right={size.width/2}
      top={size.height/2}
      bottom={-size.height/2}
      onUpdate={self => self.updateProjectionMatrix()}
      near={0}
    />
    <Viewport
      openCascadeWorker={openCascadeWorker}
      renderStyle={renderStyle}
      originPoint={originPoint}
      axesScale={axesScale}
      viewportReady={viewportReady}
    />
    <HeadsUpDisplay
      controls={controlsRef}
    />
    <GridFloor
      visible={grid.enabled}
      gridSize={gridSize}
      measureScale={gridSize}
      originPoint={originPoint}
    />
    <BoundingBox
      box={state.files[state.activeFileIndex]?.properties?.aabb}
      visible={boundingBox.enabled}
      lineColor={new Color(0, 0, 0.7)}
      measureColor={new Color(0.0, 0.0, 0.5)}
      measureScale={gridSize}
    />
    <Sky
      scale={[axesScale*1e5, axesScale*1e5, axesScale*1e5]}
    />
  </>;
}
