import React from "react";

const Button = ({
  text,
  additionalClassName,
  onClickFunction,
  IconChild = null,
}) => {
  return (
    <button
      className={`px-2 translate-x-[-50px] ${IconChild && "flex justify-around gap-x-2 md:gap-x-3 items-center"}  w-max  py-2 ${additionalClassName}`}
      onClick={onClickFunction || null}
    >
      <p className="text-sm">{text || "Click"}</p>
     {IconChild && IconChild}
    </button>
  );
};

export default Button;
