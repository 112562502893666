import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { useState } from "react";
import {
  getPartsMarkingDetailsById,
  updatePartMaking,
} from "../../../utils/actions/allactions";
import {
  validateAdmin,
  numberInputOnWheelPreventChange,
  getToken,
} from "../../../utils/helper";
import { toast } from "react-toastify";
import useDocumentTitle from "../../../utils/useDocumentTitle";
import SkeltonLoadingComponent from "../../../components/LoadingComponents/SkeltonLoadingComponent";

const EditPartMakingMaster = () => {
  const token = getToken();
  const navigate = useNavigate();
  useDocumentTitle("Edit Part Marking");
  const [initialValue, setInitialValue] = useState(null);
  const [error, setError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id") || null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPartsMarkingDetailsById(id);
      if (data && data.success) {
        setInitialValue(data.data);
      } else {
        setError("Unable To Fetch Parts");
      }
      } catch (error) {
        toast.error("Unable To Fetch  Parts Making Data");
      }
      
    };
    fetchData();
  }, []);

  const validatePartMakingVal = () => {
    if (!initialValue.name || !initialValue.PMFactor) {
      return false;
    }
    return true;
  };

  const handleEditPartMaking = async () => {
    setError("");
    const isPartMakingValid = validatePartMakingVal();

    if (!isPartMakingValid) {
      setError("Enter valid values for all the field");
    } else {
      try {

        const updatedPartMaking = await updatePartMaking(token, initialValue);

      if (updatedPartMaking?.data?.data?.code == 11000) {
        toast.error("Part Marking already exists");
      } else {
        navigate("/admin/partMaking");
        toast.success("Part Marking updated successfully");
      }
      } catch (error) {
        toast.error("Unable To Update Part Marking Data");
      }
      
    }
  };

  return (
    <div className="admin-container">
      <div>
        <Sidebar />
      </div>
      <div className="admin-panel-container">
        <h1 className="text-center text-2xl my-2 font-bold leading-normal"> Edit Part Marking </h1>

        {initialValue ? (
          <div className="edit-company-container">
            <div>
              <label htmlFor="name"> Name </label>: &nbsp;
              <input
                name="name"
                type="text"
                placeholder="Enter  name"
                value={initialValue.name}
                onChange={(e) => {
                  setInitialValue({ ...initialValue, name: e.target.value });
                }}
              />
            </div>

            <div>
              <label htmlFor="PMFactor"> PMFactor </label>: &nbsp;
              <input
                name="PMFactor"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                placeholder="Enter PMFactor"
                value={initialValue.PMFactor || ""}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    PMFactor: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        ):<SkeltonLoadingComponent/>}

        <div className="center-heading my-2">
          <button className="button-search" onClick={handleEditPartMaking}>
            {" "}
            Update{" "}
          </button>
        </div>

        {error ? (
          <div
            style={{
              color: "red",
              fontSize: "15px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            {error}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EditPartMakingMaster;
