import React from "react";
import Sidebar from "../../Admin/Sidebar";

const AdminDashboardComponents = (ChildComponent,additionalStyles) => {
  return (props) => {
    return (
      <div className="flex w-[100%]" style={additionalStyles || {}}>
        <Sidebar/>
        <ChildComponent {...props}/>
      </div>
    );
  };
};

export default AdminDashboardComponents;
