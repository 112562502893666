import React from "react";
import { Button, Modal } from "react-bootstrap";
const DataModal = ({ show, handleClose, data, actionText, handleAction }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="address-pop"
      dialogClassName="d-flex align-items-center justify-content-center"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize:'15px'}}>{data?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{data?.body}</Modal.Body>
      <Modal.Footer>
        {actionText ? <>
          <Button className="req-quote-btn" onClick={handleAction}>
          {actionText}
        </Button>
        </> : <></>}
        <Button className="req-quote-btn" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default DataModal;
