import { createContext, useContext, useReducer } from "react";


const createQuotationContext = createContext();

const initialState = {
    values: [],
    commanValues: {
        projectNumber: 0,
        projectName: "UNTITLED PROJECT",
        projectNamePreFix: "GN",
        certifications: [],
        selectedProcess: 2,
        isReadyToCreateQuote: false,
        price: '',
        price1: '',
        price2: '',
        selectedShipMethod: 0,
        sheetAndInjectionDiscription: "",
        sheetAndInjectionQuantity: 0,
        hidePrice: false,
        isConformanceCertificateRequested: false,
        isDimensionalReportRequested: false,
        isQrTrackingRequested: false,
        additionalCost1: 0,
        additionalCost2: 0,
        additionalCost3: 0,
        shippingCharge1: 0,
        shippingCharge2: 0,
        shippingCharge3: 0,
        leadTime1: 10,
        leadTime2: 15,
        errors: {
            MaterialType: false,
            SubMaterial: false,
            sheetAndInjectionDiscription: false,
            sheetAndInjectionQuantity: false
        },
        certificationCost: 0,
        isUpdatedByAdmin: false,
        myorders: false,
        sameForAllField: false,
        errors: {
            MaterialType: false,
            SubMaterial: false,
            sheetAndInjectionDiscription: false,
            sheetAndInjectionQuantity: false
        },
    }
}

export const CREATE_QUOTE_ACTION = {
    UPDATE_VALUES: 'UPDATE_VALUES',
    UPDATE_COMMAN_VALUES: 'UPDATE_COMMAN_VALUES',
    ADD_REMOVE_CERTIFICATES: 'ADD_REMOVE_CERTIFICATES',
    UPDATE_PROJECT_NAME: 'UPDATE_PROJECT_NAME',
    UPDATE_PROJECT_NUMBER: 'UPDATE_PROJECT_NUMBER',
    READY_TO_CREATE_QUOTE: 'READY_TO_CREATE_QUOTE',
    RESET_CREATE_QUOTE_STATE: 'RESET_CREATE_QUOTE_STATE',
}

const reducer = (state, action) => {
    switch (action.type) {
        case CREATE_QUOTE_ACTION.UPDATE_VALUES:
            return { ...state, values: action.payload };
        case CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES:
            {
                // if (Array.isArray(state.values) && state.values.length) {
                //     const newValues = state.values.map((item) => {
                //         return { ...item, price: 0, price1: 0, price2: 0 };
                //     })
                //     return { ...state, commanValues: action.payload, values: newValues };
                // }
                return { ...state, commanValues: action.payload };
            }
        case CREATE_QUOTE_ACTION.RESET_CREATE_QUOTE_STATE:
            {
                return { ...initialState, commanValues: { ...initialState.commanValues, selectedProcess: action.payload || 2 } }
            }
        case CREATE_QUOTE_ACTION.ADD_REMOVE_CERTIFICATES: {
            const { _id, price, certificationType } = action.payload;
            // console.log(action.payload);
            const certifications = state.commanValues.certifications;
            const isAlreadySelected = certifications.includes(_id);

            const selectedProcess = state.commanValues.selectedProcess;

            const certificationPriceForQuote = (() => {
                if (certificationType == 'per line item') {
                    const itemsCount = state.values?.length;
                    return itemsCount * price;
                }

                if (certificationType == 'per order') {
                    return price;
                }
            })();
            const updatedPrice = isAlreadySelected ? state.commanValues.certificationCost - certificationPriceForQuote : state.commanValues.certificationCost + certificationPriceForQuote;

            const updatedCertifications = isAlreadySelected
                ? certifications.filter((id) => id !== _id)
                : [...certifications, _id];
            return {
                ...state,
                commanValues: {
                    ...state.commanValues,
                    certifications: updatedCertifications,
                    certificationCost: (selectedProcess == 2 || selectedProcess == 1) ? updatedPrice : state.commanValues.certificationCost,
                },
            };
        }
        case CREATE_QUOTE_ACTION.UPDATE_PROJECT_NAME: {
            const updatedProjectName = action.payload;
            return {
                ...state,
                commanValues: {
                    ...state.commanValues,
                    projectName: updatedProjectName,
                }
            }
        }
        case CREATE_QUOTE_ACTION.UPDATE_PROJECT_NUMBER: {
            const updatedProjectNumber = action.payload + 1;
            return {
                ...state,
                commanValues: {
                    ...state.commanValues,
                    projectNumber: updatedProjectNumber,
                }
            }
        }
        case CREATE_QUOTE_ACTION.READY_TO_CREATE_QUOTE: {
            const payload = action.payload;
            return {
                ...state,
                commanValues: {
                    ...state.commanValues,
                    isReadyToCreateQuote: payload,
                }
            }
        }
        default:
            return state;
    }
};


export const CreateQuoteContextProvider = ({ children }) => {
    const [createQuoteState, createQuoteDispatch] = useReducer(reducer, initialState);
    return <createQuotationContext.Provider value={{ createQuoteState, createQuoteDispatch }}>{children}</createQuotationContext.Provider>
}

export const useCreateQuoteContext = () => {
    return useContext(createQuotationContext);
};
