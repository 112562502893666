import React, { useEffect, useState } from "react";
import downloadImg from "../../images/download.svg";
import QuoteVersionPriceBox from "../QuoteVersionPriceBox/QuoteVersionPriceBox";
import { fetchQuoteByIdForQuoteVersion } from "../../utils/actions/allactions";
import { config } from "../../environment/development";
import fileSaver from "file-saver";
import {
  currencyFormatter,
  extractURLAndEncodedFilename,
} from "../../utils/helper";
import { Loading } from "../Loading";
import { toast } from "react-toastify";
import { DownloadIcon } from "../Icons";
import { calculatePriceOfCertifications, calculateVolume } from "../../utils/constant";

function QuoteTable({
  selectedQuote,
  setSelectedQuote,
  fromOrderDetails,
  showDrawer,
}) {

  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  const [fetchedQuoteDetails, setFetchedQuoteDetails] = useState();
  const [certificationsCost, setCertificationsCost] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  console.log("Fetch Quote Details :", fetchedQuoteDetails);
  let token = localStorage.getItem("Token");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [selectedShipMethod, setSelectedShipMethod] = useState(0);

  const fetchedQuoteDetailsById = async () => {
    try {
      let details = await fetchQuoteByIdForQuoteVersion(
        selectedQuote._id,
        token
      );
      const certificationsCost = calculatePriceOfCertifications(details.data.data) ? calculatePriceOfCertifications(details.data.data) : 0;
      const shippedMethod = details.data.data.selectedShipMethod;
      if (shippedMethod != null && shippedMethod != undefined) {
        if (shippedMethod == 0) {
          setShippingCost(details.data.data.shippingCharge1)
        }
        else if (shippedMethod == 1) {
          setShippingCost(details.data.data.shippingCharge2)
        }
        else {
          setShippingCost(details.data.data.shippingCharge3)
        }
      }


      setCertificationsCost(certificationsCost);
      setSelectedShipMethod(details.data.data.selectedShipMethod);
      setFetchedQuoteDetails(details.data.data);
    } catch (error) {
      console.log(error);
      toast.error("Unable To Fetch Quotes Details");
    }
  };
  console.log("Selected Quote :", selectedQuote);
  useEffect(() => {
    if (selectedQuote._id) {
      fetchedQuoteDetailsById();
    }

    if (selectedQuote.selectedProcess >= 3) {
      setSelectedPrice(selectedQuote.sheetAndInjectionManualPrice);
    } else {
      setSelectedPrice(selectedQuote.stManualPrice);
    }
  }, [selectedQuote]);

  let handleOnClickPriceBox = (price, selectedShipMethod) => {
    setSelectedPrice(price);
    setSelectedShipMethod(selectedShipMethod);
    // setSelectedQuote({ ...selectedQuote, selectedShipMethod: selectedShipMethod })
  };

  let getFileExtension = (filename) => {
    return filename?.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  if (!fetchedQuoteDetails) {
    return <Loading />;
  }

  return (
    <div>
      {!fromOrderDetails && (
        <QuoteVersionPriceBox
          showDrawer={showDrawer}
          selectedQuote={selectedQuote}
          handleOnClickPriceBox={handleOnClickPriceBox}
          selectedPrice={selectedPrice}
          selectedShipMethod={selectedShipMethod}
          setSelectedShipMethod={setSelectedShipMethod}
          setSelectedQuote={setSelectedQuote}
        />
      )}


      <div className="relative overflow-x-auto">
        <table className="qoute-version-table">
          {fetchedQuoteDetails.selectedProcess >= 3 ? (
            <>
              <thead>
                <tr className="heading-row">
                  <th scope="col">File Name</th>
                  <th scope="col">Part Description</th>
                  <th scope="col">Notes</th>
                  <th scope="col">Price</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>


                {fetchedQuoteDetails.partsData?.length > 0 &&
                  fetchedQuoteDetails.partsData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td
                          // className="downloadables"
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            width: "250px",
                          }}
                        >
                          <div className="flex items-center gap-x-2">
                            {(item.file
                              ? item.file.originalname || item.file.filename
                              : item.selectedFile
                                ? item?.selectedFile?.path ||
                                item?.selectedFile?.name
                                : item.linePartName || "No file uploaded"
                            )?.length > 25
                              ? (item.file
                                ? item.file.originalname ||
                                item.file.filename
                                : item.selectedFile
                                  ? item?.selectedFile?.path ||
                                  item?.selectedFile?.name
                                  : item.linePartName || "No file uploaded"
                              ).substring(0, 25) + "..."
                              : item.file
                                ? item.file.originalname || item.file.filename
                                : item.selectedFile
                                  ? item?.selectedFile?.path ||
                                  item?.selectedFile?.name
                                  : item.linePartName || "No file uploaded"}{" "}
                            {item.file ? (
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  let extension = getFileExtension(
                                    item.file.originalname
                                  );
                                  let url = `${config.backEnd}/public/uploads/${item._id}.${extension}`;
                                  download(item.file.originalname, url);
                                }}
                              >
                                <img src={downloadImg} />
                              </span>
                            ) : null}
                          </div>
                        </td>

                        <td
                          style={{
                            textAlign: "left",
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            width: "250px",
                          }}
                        >
                          {item.description ? item.description : ""}
                        </td>

                        <td
                          style={{
                            textAlign: "left",
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            width: "250px",
                          }}
                        >
                          {item.Notes ? item.Notes : ""}
                        </td>

                        <td>
                          {((fetchedQuoteDetails.isUpdatedByAdmin &&
                            fetchedQuoteDetails.isSent) || fetchedQuoteDetails.selectedProcess == 2) ||
                            fetchedQuoteDetails.myorders ||
                            fetchedQuoteDetails.isRepeatOrderQuotation ? (
                            <>
                              <div className="amt">
                                <p className="un-fld">
                                  {currencyFormatter(
                                    selectedShipMethod === 0
                                      ? Number(item?.price).toFixed(2)
                                      : selectedShipMethod === 1
                                        ? Number(item?.price1).toFixed(2)
                                        : Number(item?.price2).toFixed(2)
                                  )}
                                </p>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>

                        <td>{item.Qty ? item.Qty : "No quantity found"}</td>

                        <td>
                          {((fetchedQuoteDetails.isUpdatedByAdmin &&
                            fetchedQuoteDetails.isSent) || fetchedQuoteDetails.selectedProcess == 2) ||
                            fetchedQuoteDetails.isRepeatOrderQuotation ||
                            fetchedQuoteDetails.myorders ? (
                            <>
                              <div className="amt">
                                <p className="un-fld">
                                  {currencyFormatter(
                                    selectedShipMethod === 0
                                      ? (
                                        Number(item?.price) *
                                        Number(item?.Qty)
                                      ).toFixed(2)
                                      : selectedShipMethod === 1
                                        ? (
                                          Number(item?.price1) *
                                          Number(item?.Qty)
                                        ).toFixed(2)
                                        : Number(
                                          Number(item?.price2) *
                                          Number(item?.Qty)
                                        ).toFixed(2)
                                  )}
                                </p>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  })}

                {(fetchedQuoteDetails?.certifications?.length) ? (
                  <tr>
                    <td> </td>
                    <td>Certifications </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="amt">
                        <p className="un-fld">
                          {currencyFormatter(certificationsCost)}
                        </p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}

                {(selectedShipMethod == 0
                  ? fetchedQuoteDetails.shippingCharge1
                  : selectedShipMethod == 1
                    ? fetchedQuoteDetails.shippingCharge2
                    : fetchedQuoteDetails.shippingCharge3) &&
                  (((fetchedQuoteDetails.isUpdatedByAdmin &&
                    fetchedQuoteDetails.isSent) || fetchedQuoteDetails.selectedProcess == 2) ||
                    fetchedQuoteDetails.myorders ||
                    fetchedQuoteDetails.isRepeatOrderQuotation) ? (
                  <tr>
                    <td> </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='font-bold'>Shipping & Duties </td>
                    <td>
                      <div className="amt">
                        <p className="un-fld">
                          {currencyFormatter(
                            selectedShipMethod == 0
                              ? fetchedQuoteDetails.shippingCharge1
                              : selectedShipMethod == 1
                                ? fetchedQuoteDetails.shippingCharge2
                                : fetchedQuoteDetails.shippingCharge3
                          )}
                        </p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}


                {
                  (fetchedQuoteDetails?.price ||
                    fetchedQuoteDetails?.price1 ||
                    fetchedQuoteDetails?.price2) &&
                    fetchedQuoteDetails.isSent ? (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="font-bold">Total :</td>
                      <td className="font-bold">
                        {currencyFormatter(
                          selectedShipMethod === 0
                            ? (Number(fetchedQuoteDetails?.price) + certificationsCost + shippingCost).toFixed(2)
                            : selectedShipMethod === 1
                              ? (Number(fetchedQuoteDetails?.price1) + certificationsCost + shippingCost).toFixed(2)
                              : (Number(fetchedQuoteDetails?.price2) + certificationsCost + shippingCost).toFixed(2)
                        )}
                      </td>
                    </tr>
                  ) : null
                }

              </tbody>
            </>
          ) : (
            <>
              <thead>
                <tr className="heading-row">
                  <th scope="col">File Name</th>
                  <th scope="col">2D files
                  </th>
                  <th scope="col">Part Description</th>
                  <th scope="col">Notes</th>
                  <th scope="col">Price</th>
                  <th scope="col">Qty</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                {(selectedShipMethod == 0
                  ? fetchedQuoteDetails.shippingCharge1
                  : selectedShipMethod == 1
                    ? fetchedQuoteDetails.shippingCharge2
                    : fetchedQuoteDetails.shippingCharge3) &&
                  (((fetchedQuoteDetails.isUpdatedByAdmin &&
                    fetchedQuoteDetails.isSent) || fetchedQuoteDetails.selectedProcess == 2) ||
                    fetchedQuoteDetails.myorders ||
                    fetchedQuoteDetails.isRepeatOrderQuotation) ? (
                  <tr>
                    <td> </td>
                    <td>Shipping & Duties</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="amt">
                        <p className="un-fld">
                          {currencyFormatter(
                            selectedShipMethod == 0
                              ? fetchedQuoteDetails.shippingCharge1
                              : selectedShipMethod == 1
                                ? fetchedQuoteDetails.shippingCharge2
                                : fetchedQuoteDetails.shippingCharge3
                          )}
                        </p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}

                {fetchedQuoteDetails.partsData.length > 0 &&
                  fetchedQuoteDetails.partsData.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            width: "280px",
                          }}
                        >
                          <div
                            className="prd-thumb downloadables"
                          // style={{ color: "dodgerblue" }}
                          >
                            <p className="flex justify-start gap-x-3 whitespace-nowrap !text-primaryColor">
                              {(item.file ? item?.file?.originalname : "")
                                ?.length > 25
                                ? (item.file
                                  ? item?.file?.originalname
                                  : ""
                                ).substring(0, 25) + "..."
                                : item.file
                                  ? item?.file?.originalname
                                  : ""}
                              <span
                                onClick={(e) => {
                                  let extension = getFileExtension(
                                    item?.file?.originalname
                                  );
                                  let url = `${config.backEnd}/public/uploads/${item._id}.${extension}`;
                                  download(item?.file?.originalname, url);
                                }}
                              >
                                <DownloadIcon />
                              </span>
                            </p>
                          </div>
                        </td>
                        {item?.twoDFile ? <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            width: "280px",
                          }}

                        >
                          <div
                            className="prd-thumb downloadables"
                          // style={{ color: "dodgerblue" }}
                          >
                            <p className="flex justify-start gap-x-3 whitespace-nowrap !text-primaryColor">
                              {(item.twoDFile ? item?.twoDFile?.originalname : "")
                                ?.length > 25
                                ? (item.twoDFile
                                  ? item?.twoDFile?.originalname
                                  : ""
                                ).substring(0, 25) + "..."
                                : item.twoDFile
                                  ? item?.twoDFile?.originalname
                                  : ""}
                              <span
                                onClick={(e) => {
                                  let url = `${config.backEnd}/${item.twoDFile.path}`;
                                  download(
                                    item.twoDFile.originalname,
                                    url
                                  );
                                }}
                              >
                                <DownloadIcon />
                              </span>
                            </p>
                          </div>
                        </td> : <td
                          style={{
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                            whiteSpace: "normal",
                            width: "280px",
                          }}

                        >
                          <div
                            className="prd-thumb downloadables"
                          // style={{ color: "dodgerblue" }}
                          >
                              
                          </div>
                        </td>}


                        <td>
                          {/* {fetchedQuoteDetails.selectedProcess} */}
                          {console.log(item)}
                          {fetchedQuoteDetails.selectedProcess != 2 ? <div className="desc-part-quote-version">
                            <ul>
                              {item?.description ? (
                                <li>{item?.description}</li>
                              ) : (
                                ""
                              )}
                              {item?.MaterialType ? (
                                <li>{item?.MaterialType?.name}</li>
                              ) : (
                                ""
                              )}
                              {item?.SubMaterial ? (
                                <li>{item?.SubMaterial.name}</li>
                              ) : (
                                ""
                              )}
                              {item?.surfaceFinish ? (
                                <li>{item?.surfaceFinish.name}</li>
                              ) : (
                                ""
                              )}
                              {item?.surfaceTreatment ? (
                                <li>{item?.surfaceTreatment.name}</li>
                              ) : (
                                ""
                              )}
                              {item?.tolerance ? (
                                <li>{item?.tolerance.name}</li>
                              ) : (
                                ""
                              )}
                              {item?.partMarking ? (
                                <li>{item?.partMarking.name}</li>
                              ) : (
                                ""
                              )}
                              {item?.noOfThread ? (
                                <li>{item?.noOfThread}</li>
                              ) : (
                                ""
                              )}
                            </ul>
                          </div> : <div className="desc-part-quote-version">
                            <ul>
                              <li>
                                {item.threeDTechnology &&
                                  item.threeDTechnology?.name
                                }
                              </li>
                              {/* <li>
                                {item.threeDMachine &&
                                  item.threeDMachine?.name
                                }
                              </li> */}
                              <li>
                                {item.threeDMaterial &&
                                  item.threeDMaterial?.name
                                }
                              </li>
                              <li>
                                {item.threeDPostProcessing &&
                                  item.threeDPostProcessing?.name
                                }
                              </li>
                              <li>
                                {item.XDimensionValue} mm x {item.YDimensionValue} mm x {item.ZDimensionValue} mm
                              </li>
                              <li>
                                {(item.XDimensionValue * 0.0394).toFixed(2)} in x {(item.YDimensionValue * 0.0394).toFixed(2)} in x {(item.ZDimensionValue * 0.0394).toFixed(2)} in
                              </li>
                              <li>
                                Volume : {calculateVolume(item.XDimensionValue,
                                  item.YDimensionValue,
                                  item.ZDimensionValue,
                                  'mm'

                                ).toFixed(2)} mm³
                              </li>
                              <li>
                                Volume : {calculateVolume(item.XDimensionValue,
                                  item.YDimensionValue,
                                  item.ZDimensionValue,
                                  'in'

                                ).toFixed(2)} in³
                              </li>
                            </ul>
                          </div>}
                        </td>
                        <td>
                          <p className="3dcncNote">{item?.Notes}</p>{" "}
                        </td>

                        <td>
                          {((fetchedQuoteDetails.isUpdatedByAdmin &&
                            fetchedQuoteDetails.isSent) || fetchedQuoteDetails.selectedProcess == 2) ||
                            fetchedQuoteDetails.myorders ||
                            fetchedQuoteDetails.isRepeatOrderQuotation ? (
                            <>
                              <div className="amt">
                                <p className="un-fld">
                                  {currencyFormatter(
                                    selectedShipMethod === 0
                                      ? Number(item?.price).toFixed(2)
                                      : selectedShipMethod === 1
                                        ? Number(item?.price1).toFixed(2)
                                        : Number(item?.price2).toFixed(2)
                                  )}
                                </p>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>

                        <td>
                          <p className="amt">{item?.Qty}</p>{" "}
                        </td>

                        <td>
                          {((fetchedQuoteDetails.isUpdatedByAdmin &&
                            fetchedQuoteDetails.isSent) || fetchedQuoteDetails.selectedProcess == 2) ||
                            fetchedQuoteDetails.isRepeatOrderQuotation ||
                            fetchedQuoteDetails.myorders ? (
                            <>
                              <div className="amt">
                                <p className="un-fld">
                                  {currencyFormatter(
                                    selectedShipMethod === 0
                                      ? (
                                        Number(item?.price) *
                                        Number(item?.Qty)
                                      ).toFixed(2)
                                      : selectedShipMethod === 1
                                        ? (
                                          Number(item?.price1) *
                                          Number(item?.Qty)
                                        ).toFixed(2)
                                        : Number(
                                          Number(item?.price2) *
                                          Number(item?.Qty)
                                        ).toFixed(2)
                                  )}
                                </p>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  })}

                {(fetchedQuoteDetails?.certifications?.length
                  && (fetchedQuoteDetails.selectedProcess >= 3 ? (fetchedQuoteDetails.isSent || fetchedQuoteDetails?.isEmailSent) : true)
                ) ? (
                  <tr>
                    <td> </td>
                    <td>Certifications </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <div className="amt">
                        <p className="un-fld">
                          {currencyFormatter(certificationsCost)}
                        </p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {
                  (fetchedQuoteDetails?.price ||
                    fetchedQuoteDetails?.price1 ||
                    fetchedQuoteDetails?.price2) &&
                    (fetchedQuoteDetails.selectedProcess >= 3 ? (fetchedQuoteDetails.isSent || fetchedQuoteDetails?.isEmailSent) : true) ? (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="font-bold">Total :</td>
                      <td className="font-bold">
                        {currencyFormatter(
                          selectedShipMethod === 0
                            ? (Number(fetchedQuoteDetails?.price) + certificationsCost + shippingCost).toFixed(2)
                            : selectedShipMethod === 1
                              ? (Number(fetchedQuoteDetails?.price1) + certificationsCost + shippingCost).toFixed(2)
                              : (Number(fetchedQuoteDetails?.price2) + certificationsCost + shippingCost).toFixed(2)
                        )}
                      </td>
                    </tr>
                  ) : null
                }

              </tbody>
            </>
          )}
        </table>
      </div>
    </div >
  );

}

export default QuoteTable;