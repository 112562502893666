import React, { useEffect, useState } from 'react';
import { validateAdmin } from "../../utils/helper";
import { useNavigate } from 'react-router-dom';
import {
        fetchMakePortalEmails,
        fetchEmailContentByIdForMake
} from '../../utils/actions/allactions';
import {
        fetchSupplierPortalEmails,
        fetchEmailContentByIdForSupplier
} from '../../utils/actions/supplierActions';
import Sidebar from '../Sidebar';
import moment from 'moment';
import { toast } from "react-toastify";
import {Modal,Button, FormControl } from 'react-bootstrap';
import useDocumentTitle from '../../utils/useDocumentTitle';

const EmailTracking = () => {
    const [emailData,setEmailData] = useState([]);
    const [filteredData,setFilteredData] = useState([]);
    let token = localStorage.getItem("Token");
    let navigate = useNavigate();
    useDocumentTitle('Email Tracking')
    let [showFltr, setShowFltr] = useState(false);
    let [fltrValues, setFltrValues] = useState({
        receiverEmail: '',
        createdFrom: '',
        createdTo:'',
        subject:'',
        adminFilter:false,
      });
    const [isLoading,setIsLoading] = useState(false);
    const [showModal,setShowModal] = useState(false);
    const [emailContent,setEmailContent] = useState(null);
    const [isMailContentLoading,setIsMailContentLoading] = useState(false);

    const handleClose = () => {
      setShowModal(false)
      setEmailContent(null);
    };

    const handleShowBody = async(item) =>{
      try{
        // console.log(item)
        setIsMailContentLoading(true);
        setShowModal(true);
        let bodyData = '';
        if(item.source=='make'){
          const fetchEmailContentResponse = await fetchEmailContentByIdForMake(token,item._id);
          if(fetchEmailContentResponse.status){
            bodyData = fetchEmailContentResponse.data.mailContent
            setIsMailContentLoading(false);
          }
        }
        else if(item.source=='supplier'){
          const fetchEmailContentResponse = await fetchEmailContentByIdForSupplier(token,item._id);
          if(fetchEmailContentResponse.status){
            bodyData = fetchEmailContentResponse.data.mailContent
            setIsMailContentLoading(false);
          }
        }
        
        setEmailContent(bodyData);
      }
      catch(err){
        setIsMailContentLoading(false);
        console.log("Error in email tracking 65 on"+moment(Date.now()).format('DD MMM YYYY hh:mm:ss a'),err)
      } 
    }



    useEffect(()=>{
        validateAdmin(token, navigate);
        fetchEmailTrackingData();
    },[])


    const handleSort = (arrayToSort,) =>{
        let data = [...arrayToSort].sort((a, b) => {
            return moment(a['createdAt']).isAfter(b['createdAt']) ? -1 : 1
        });
  
        return data;
    }


    const fetchEmailTrackingData =async()=>{
        try{
            setIsLoading(true);
            let emailData = [];
            const makePortalEmailResponse = fetchMakePortalEmails(token);
            const supplierPortalEmailResponse = fetchSupplierPortalEmails(token);


            const response = await Promise.all([makePortalEmailResponse,supplierPortalEmailResponse]);
            if(response[0].status){
              response[0].data.forEach(item=> item.source  =  'make')
                emailData = [...emailData,...response[0].data];
            }
            if(response[1].status){
              response[1].data.forEach(item=> item.source  =  'supplier')
                emailData = [...emailData,...response[1].data];
            }

            let sortedData = handleSort(emailData);

            setEmailData([...sortedData]);
            setFilteredData([...sortedData]);
            setIsLoading(false);
        }
        catch(err){
            setIsLoading(false);
            console.log("Error in email tracking 109 on"+moment(Date.now()).format('DD MMM YYYY hh:mm:ss a'),err);
        }
    }


    const handleSearch = ()=>{
      
        if(!fltrValues.createdFrom && !fltrValues.createdTo && !fltrValues.receiverEmail && !fltrValues.subject){
            toast.error('Please enter value to filter data!');
            return;
        }
        setShowFltr(true);
        let dataToFilter = [...emailData];

        if(fltrValues.createdFrom){
            let newFltrArr = [];
            let createdate = dataToFilter.map((val, i) => {
              return val.createdAt
            })
        
            createdate.map((val, i) => {
              let changeDate = moment(val).format('YYYY-MM-DD');
              if (moment(changeDate).isSameOrAfter(fltrValues.createdFrom)) {
                newFltrArr = [...newFltrArr, val]
              }
              else {
                newFltrArr = [...newFltrArr]
              }
            })
      
            dataToFilter = dataToFilter.filter((val) => newFltrArr.includes(val.createdAt))
        }
        if(fltrValues.createdTo){
            let newFltrArr = [];
            let createdate = dataToFilter.map((val, i) => {
                return val.createdAt
            })
        
            createdate.map((val, i) => {
                let changeDate = moment(val).format('YYYY-MM-DD');
                if (moment(changeDate).isSameOrBefore(fltrValues.createdTo)) {
                newFltrArr = [...newFltrArr, val]
                }
                else {
                newFltrArr = [...newFltrArr]
                }
            })
        
            dataToFilter = dataToFilter.filter((val) => newFltrArr.includes(val.createdAt))
        }

        if(fltrValues.receiverEmail){
            let newFltrArr = [];
            let receiversEmails = dataToFilter.map((val, i) => {
            return val.receiverEmail
            })
        
            receiversEmails.map((val, i) => {
            if (val.toLowerCase().includes(fltrValues.receiverEmail.toLowerCase())) {
                newFltrArr = [...newFltrArr, val]
            }
            else {
                newFltrArr = [...newFltrArr]
            }
            })
        
            dataToFilter = dataToFilter.filter((val) => newFltrArr.includes(val.receiverEmail))
        }
        if(fltrValues.subject){
            let newFltrArr = [];
            let subjectArr = dataToFilter.map((val, i) => {
              return val.subjectOfEmail
            })
        
            subjectArr.map((val, i) => {
              if (val.toLowerCase().includes(fltrValues.subject.toLowerCase())) {
                newFltrArr = [...newFltrArr, val]
              }
              else {
                newFltrArr = [...newFltrArr]
              }
            })
        
            dataToFilter = dataToFilter.filter((val) => newFltrArr.includes(val.subjectOfEmail))
        }

        setFilteredData([...dataToFilter]);
    }

    let handleRefresh = () => {
        setFilteredData(emailData);
    
        setFltrValues({
            receiverEmail: '',
            createdFrom: '',
            createdTo:'',
            subject:'',
            adminFilter:false,
        })
        setShowFltr(false);
      }


    let handleCreatedFltr = (e,field) => {
        if(field=='createdFrom'){
          setFltrValues((val) => ({
            ...val,
            createdFrom: e.target.value,
          }))
          setShowFltr(true);
          let newFltrArr = [];
          let createdate = emailData.map((val, i) => {
            return val.createdAt
          })
      
          createdate.map((val, i) => {
            let changeDate = moment(val).format('YYYY-MM-DD');
            if (moment(changeDate).isSameOrAfter(e.target.value)) {
              newFltrArr = [...newFltrArr, val]
            }
            else {
              newFltrArr = [...newFltrArr]
            }
          })
    
          let fltrData = emailData.filter((val) => newFltrArr.includes(val.createdAt))
          setFilteredData(fltrData)

        }else{
          setFltrValues((val) => ({
            ...val,
            createdTo: e.target.value,
          }))
          setShowFltr(true);
          let newFltrArr = [];
          let createdate = emailData.map((val, i) => {
            return val.createdAt
          })
      
          createdate.map((val, i) => {
            let changeDate = moment(val).format('YYYY-MM-DD');
            if (moment(changeDate).isSameOrBefore(e.target.value)) {
              newFltrArr = [...newFltrArr, val]
            }
            else {
              newFltrArr = [...newFltrArr]
            }
          })
      
          let fltrData = emailData.filter((val) => newFltrArr.includes(val.createdAt))
         setFilteredData(fltrData);
        }
    
      }

      let handleSentToFilter = (e) => {
        setFltrValues((val) => ({
          ...val,
          receiverEmail: e.target.value,
        }))
        setShowFltr(true);
        let newFltrArr = [];
        let receiversEmails = emailData.map((val, i) => {
          return val.receiverEmail
        })
    
        receiversEmails.map((val, i) => {
          if (val.toLowerCase().includes(e.target.value.toLowerCase())) {
            newFltrArr = [...newFltrArr, val]
          }
          else {
            newFltrArr = [...newFltrArr]
          }
        })
    
        let fltrData = emailData.filter((val) => newFltrArr.includes(val.receiverEmail))
        setFilteredData(fltrData)
      }

      let handleSubjectToFilter = (e) => {
        setFltrValues((val) => ({
          ...val,
          subject: e.target.value,
        }))
        setShowFltr(true);
        let newFltrArr = [];
        let subjectArr = emailData.map((val, i) => {
          return val.subjectOfEmail
        })
    
        subjectArr.map((val, i) => {
          if (val.toLowerCase().includes(e.target.value.toLowerCase())) {
            newFltrArr = [...newFltrArr, val]
          }
          else {
            newFltrArr = [...newFltrArr]
          }
        })
    
        let fltrData = emailData.filter((val) => newFltrArr.includes(val.subjectOfEmail))
        setFilteredData(fltrData)
      }

      const handleAdminEmailFilterChange = ()=>{
        setFltrValues({...fltrValues,adminFilter: !fltrValues.adminFilter});
        let filteredData = [...emailData];
        if(!fltrValues.adminFilter){
          filteredData = filteredData.filter(item => item.receiverEmail.split('@')[1]!="8xparts.com")
        }
        setFilteredData(filteredData);   
      }

      


    return (
        <div className="admin-container">
            <Sidebar />
            <Modal
            size="sm"
            dialogClassName="qoute-version-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
            onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{fontSize:'15px'}}>
                        Email Body
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isMailContentLoading?<p style={{textAlign:'center'}}>Loading...</p>: emailContent? <div dangerouslySetInnerHTML={{ __html: emailContent }} />:<>No Body Found</>}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="req-quote-btn" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
            <div className="right-container">
                <div className="center-heading">
                    <h1>  Email Tracking  </h1>
                </div>

                <div className="search-bar">
                    <div className="row">
                        <div className="col-md-2">
                            <div className="search-block">
                            <label className="sr-label">
                                Email
                            </label>
                            <input type="text" className="form-control" value={fltrValues.receiverEmail} placeholder="Sent to" onChange={handleSentToFilter} />
                            </div>
                        </div>
                        <div className="col-md-4" >
                            <div className="search-block" style={{display:'flex',width:'100%',justifyContent:'space-evenly'}}>
                                <div>
                                <label className="sr-label">Sent Date From</label>
                                <input type="date" value={fltrValues.createdFrom} className="form-control" onChange={(e)=>handleCreatedFltr(e,'createdFrom')} />
                                </div>
                                <div>
                                <label className="sr-label">Sent Date To</label>
                                <input type="date" value={fltrValues.createdTo} className="form-control" onChange={(e)=>handleCreatedFltr(e,'createdTo')} />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="search-block" style={{marginTop:'25px'}} onClick={handleAdminEmailFilterChange} >
                              <FormControl 
                                type='checkbox' 
                                value={fltrValues.adminFilter} 
                                checked={fltrValues.adminFilter}
                              />
                              <label className="sr-label">
                                Hide admin email
                              </label>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="search-block">
                            <label className="sr-label">
                               Subject
                            </label>
                            <input type="text" className="form-control" value={fltrValues.subject} placeholder="Subject" onChange={handleSubjectToFilter}/>
                            </div>
                        </div>
 
                    </div>
                    <div className="col-md-2" style={{marginTop:'10px'}}>
                        <div className="search-btn">
                        {showFltr ? <button onClick={handleRefresh} className="button-search">Reset all filters</button> : ''}
                        <button onClick={handleSearch} className="button-search">Search</button>
                        </div>
                    </div>
                </div>

                <div className="quotes-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" style={{ cursor: 'pointer' }} >Sent To</th>
                                    <th>Time</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} >Date</th>
                                    <th scope="col" style={{ cursor: 'pointer' }} >Subject</th>
                                </tr>
                            </thead>

                            <tbody>
                                {filteredData.length>0?
                                    filteredData.map((item)=>{
                                        return <tr key={item._id}>
                                                <td style={{wordWrap:'break-word',wordBreak:'break-all',whiteSpace:'normal'}}>{item.receiverEmail}</td>
                                                <td>{moment(item.createdAt).format("hh:mm:ss a")}</td>
                                                <td>{moment(item.createdAt).format("DD MMM YYYY")}</td>
                                                <td style={{wordWrap:'break-word',wordBreak:'break-all',whiteSpace:'normal',width:'40%'}}>{item.subjectOfEmail}</td>
                                                <td><button className="up-button" onClick={()=>handleShowBody(item)}>Show Body</button></td>
                                            </tr>
                                    }) 
                                :
                                <>

                                {isLoading?
                                    <tr>
                                        <td style={{position:'absolute',top:'50%',left:'50%'}}>Loading</td>
                                    </tr>:
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>No Data Found</td>
                                        <td></td>
                                    </tr>
                                }
                                </>
                                }
                            </tbody>
                        </table>
                </div>



            </div>
        </div>
    );
}

export default EmailTracking;
