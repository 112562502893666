import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import 'firebase/app-check';
import 'firebase/auth';
import moment from "moment";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

const firebaseDb = firebase.database();

const isLoggedIn = localStorage.getItem('isLoggedIn')
if(isLoggedIn=='true'){
    firebase.auth().signInAnonymously()
    .then(() => {
        console.log("Signin Successful")
    })
    .catch((error) => {
        console.log("Error in firebasejs 31 "+moment(Date.now()).format('DD MMM YYYY hh:mm:ss a a'))
        console.log("Error Code : ",error.code);
        console.log("Error Message : ",error.message)
    });
}else{
    firebase.auth().signOut();  
}

firebase.auth().onAuthStateChanged((user) => {
    if (user) {
        console.log("Signin Successful")
        firebaseDb.ref(`/${process.env.REACT_APP_REALTIME_DATABASE_SECRET_KEY}/${user.uid}`).set(true)  
    } else {
      console.log("Firebase user is logged out")
    }
  });

export const db = firebase.database();
const storage = firebase.storage();

export { storage, firebase as default };

export const firebaseSignOnLoginSuccess = () =>{
    firebase.auth().signInAnonymously()
    .then(() => {
        console.log("Signin Successful")
    })
    .catch((error) => {
        console.log("Error in firebasejs 59 on "+moment(Date.now()).format('DD MMM YYYY hh:mm:ss a a'))
        console.log("Error Code : ",error.code);
        console.log("Error Message : ",error.message)
    });
}

export const firebaseSignOutOnLogOut = () =>{
    firebase.auth().signOut();  
}
