import React, { useEffect, useState } from "react";

const timeOutTime = 2000;

const CustomLoadingComponent = ({
  textArray,
  additionalClasses,
  LoaderComponent,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  console.log(activeIndex);

  const changeIndex = (_) => setActiveIndex((pre) => pre + 1);

  useEffect(() => {
    let intervalId = null;
    if (activeIndex !== textArray.length - 1) {
      intervalId = setTimeout(changeIndex, timeOutTime);
    }
    return () => intervalId && clearTimeout(intervalId);
  }, [textArray, activeIndex]);

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-20 ${
        additionalClasses ? additionalClasses : ""
      }`}
    >
      <div className="absolute inset-0 bg-gray-50 opacity-90 z-10"></div>

      <div className="relative text-center flex flex-col justify-center items-center z-50 opacity-100">
        {LoaderComponent ? (
          LoaderComponent
        ) : (
          <div className="animate-spin relative z-50 rounded-full h-16 w-16 border-t-4 border-primaryColor mb-4"></div>
        )}
        <p className="text-lg font-medium text-gray-700 mb-2 relative z-50">
          {textArray[activeIndex] ? textArray[activeIndex] : "Loading ..."}
        </p>
        <p className="text-sm text-gray-500">Thank you for your patience!</p>
      </div>
    </div>
  );
};

export default CustomLoadingComponent;
