import React, { useContext, useState } from "react";
import moment from "moment";
import ChatButton from "../../Chat/ChatButton";
import { ChatRefContext } from "../../context/chatRef.context";
import Chat from "../../Chat/index";
import repeatImage from "../../images/repeat.svg";
import teamImage from "../../images/group-svgrepo-com.svg";
import { Link, useNavigate } from "react-router-dom";
import TeamCollaboration from "../TeamCollaboration/TeamCollaboration";
import { calculatePriceBasedOnData, extractURLAndEncodedFilename } from "../../utils/helper";
import { processType } from "../../utils/constant";
import fileSaver from "file-saver";
import { config } from "../../environment/development";
import { DownloadIcon } from "../Icons";
import { toast } from "react-toastify";


const maxLength = 20;

const MyOrdersTable = (props) => {
  const {
    ordersList,
    myOrdersDispatch,
    MY_ORDERS_ACTIONS,
    fetchOrdersForUser,
    myOrdersState,
  } = props;
  const { selectedChatRefId, setSelectedChatRefId } =
    useContext(ChatRefContext);
  const [teamMemberModalShow, setTeamMemberModalShow] = useState(false);
  const [selectedQuoteForTeam, setSelectedQuoteForTeam] = useState({});
  const navigate = useNavigate();
  let email = localStorage.getItem("email");

  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };



  const showDrawer = (id) => {
    setSelectedChatRefId(id);
    let userId = localStorage.getItem('_id');
    if (window.location.host === 'make.8xparts.com') {
      console.log("push data layer for chatOpen")
      // window?.dataLayer?.push({
      //   event: 'chatOpen',
      //   userId: userId,
      //   quoteRefId: id,
      //   referenceId: email?.split('@')[0] + email?.split('@')[1]?.split(".")[0],
      // });
    }
  };

  const onClose = () => {
    setSelectedChatRefId(null);
  };

  const handleTeamModal = async (selectedOrder) => {
    let userId = localStorage.getItem('_id');
    if (window.location.host === 'make.8xparts.com') {
      // window?.dataLayer?.push({
      //   event: 'teamCollaboration',
      //   userId: userId,
      //   quoteRefId: selectedOrder.quoteId.RefId,
      //   referenceId: email?.split('@')[0] + email?.split('@')[1]?.split(".")[0],
      // });
    }
    setSelectedQuoteForTeam(selectedOrder.quoteId);
    setTeamMemberModalShow(true);
  };

  const updateQuoteData = (updatedQuote) => {
    let localOrderList = [...ordersList];

    localOrderList.forEach((item) => {
      if (item.quoteId.RefId == updatedQuote.RefId) {
        item.quoteId.followers = updatedQuote.followers;
      }
    });
    myOrdersDispatch({
      type: MY_ORDERS_ACTIONS.UPDATE_ORDERS_TO_SHOW,
      payload: localOrderList,
    });
  };

  return (
    <div className="table-container">
      <Chat
        RefId={selectedChatRefId}
        open={selectedChatRefId?.length > 0}
        onDrawerClose={onClose}
      />
      <TeamCollaboration
        teamMemberModalShow={teamMemberModalShow}
        selectedQuoteForTeam={selectedQuoteForTeam}
        setTeamMemberModalShow={setTeamMemberModalShow}
        fetchDataForUser={fetchOrdersForUser}
        updateQuoteData={updateQuoteData}
      />

      {console.log('orderList', ordersList)}



      <table className="">
        <thead>
          <tr>
            <th>PROJECT NAME</th>
            <th>VERSION</th>
            <th>NO. OF PARTS</th>
            <th>PO NUMBER</th>
            <th>MFG. PROCESS</th>
            <th>SHIP TO</th>
            <th>ORDER DATE</th>
            <th>ESTIMATED SHIPPING DATE</th>
            <th>TOTAL</th>
            <th style={{ width: "80px" }}>CHAT</th>
            <th style={{ width: "60px" }}>TEAM</th>
            {/* <th style={{ width: "60px" }}>DOWNLOAD</th> */}
            <th style={{ width: "60px" }}>REPEAT</th>
          </tr>
        </thead>
        <tbody>
          {ordersList.length > 0 &&
            ordersList.map((item, i) => {
              return (
                <tr key={i}>
                  <td
                    style={{
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                      whiteSpace: "normal",
                      width: "400px",
                    }}
                  >
                    <Link to={`/order-details/${item?._id}`}>
                      <span className="text-primaryColor">{

                        item?.quoteId?.projectName.length > maxLength
                          ? item?.quoteId?.projectName.slice(0, maxLength) + '...'
                          : item?.quoteId?.projectName

                      }</span>
                    </Link>
                  </td>
                  <td style={{ textAlign: 'center' }}>{item.quoteId?.version}</td>
                  <td style={{ textAlign: 'center' }}>{item.quoteId?.partsData?.length}</td>
                  <td>{item.purchaseOrderNumber ?
                    <span
                      className="text-primaryColor flex  gap-x-2 items-center"
                      onClick={() =>
                        download(
                          item.poDocumentFile
                            .originalname,
                          `${config.backEnd}/` +
                          item
                            .poDocumentFile.path
                        )
                      }
                    >
                      {item.purchaseOrderNumber}
                      <DownloadIcon additionalClass={'!h-4'} />
                    </span> : <></>}</td>
                  <td>
                    {item?.quoteId && processType.map((val) => {
                      if (val.id === item?.quoteId?.selectedProcess) {
                        return (
                          <span key={val.id} style={{ textAlign: "left" }}>
                            {val.name}
                          </span>
                        );
                      }
                      return null;
                    })}
                  </td>
                  <td>
                    {item?.shippingAddress?.firstName +
                      " " +
                      item?.shippingAddress?.lastName}
                  </td>
                  <td>{moment(item.createdAt).format("DD MMM YYYY")}</td>
                  <td>
                    {item?.customerExpectedDeliveryDate
                      ? moment(item.customerExpectedDeliveryDate).format("DD MMM YYYY")
                      : ""}
                  </td>
                  <td>{calculatePriceBasedOnData(item)}</td>
                  <td style={{ textAlign: "left" }}>
                    <ChatButton
                      RefId={item.RefId}
                      showDrawer={() => showDrawer(item.RefId)}
                      isTextHide={true}
                      imageHeight={16}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <span onClick={() => handleTeamModal(item)}>
                      <img
                        src={teamImage}
                        style={{
                          marginRight: "5px",
                          marginBottom: "4px",
                          height: "18px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </td>

                  <td style={{ textAlign: "center" }}>
                    <span
                      onClick={() => {
                        let currentDate = new Date();
                        let oneYearAgo = new Date();
                        oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
                        if (moment(item.createdAt).isBefore(oneYearAgo)) {
                          toast.error('A repeat order cannot be created for orders placed more than one year ago.');
                          return;
                        }

                        let userId = localStorage.getItem('_id');

                        if (window.location.host === 'make.8xparts.com') {
                          const emailParts = email?.split('@');
                          const referenceId = emailParts && emailParts[1] ? emailParts[0] + emailParts[1].split('.')[0] : '';

                          // window?.dataLayer?.push({
                          //   event: 'repeatSalesOrder',
                          //   userId: userId,
                          //   salesOrderRefId: item.RefId,
                          //   referenceId: referenceId,
                          // });
                        }

                        // Navigate to repeat order page
                        navigate(`/create-instant-quotation/${item.quoteId._id}?RepeatOrder=true`);
                      }}

                    >
                      <img
                        src={repeatImage}
                        style={{
                          marginRight: "5px",
                          marginBottom: "4px",
                          height: "18px",
                          cursor: "pointer",
                        }}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {ordersList.length == 0 ? (
        <div
          style={{
            textAlign: "center",
            fontSize: "14px",
            width: "100%",
            marginTop: "5px",
            color: "#858585",
          }}
        >
          {myOrdersState.searchQuery.length > 0
            ? "Oops! We couldn't find any quotes matching your search query."
            : "No Data Found"}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MyOrdersTable;
