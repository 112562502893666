import React, {
  useContext,
  useEffect,
  useRef,
} from "react";
import CADFile from "./CADFile";
import { Context } from "./Context";
import {
  useFrame,
  useThree,
} from "react-three-fiber";
import {
  Sky,
} from 'drei';

export default function Viewport({openCascadeWorker, renderStyle, originPoint, axesScale, viewportReady}) {
  const {state} = useContext(Context);

  const {camera} = useThree();

  useEffect(() => {
    camera.near = 0;
  }, [camera]);

  const scene = useRef()
  useFrame(({gl}) => {
    gl.autoClear = true;
    gl.clippingPlanes = [];
    gl.render(scene.current, camera);
  }, 1);

  return <>
    <scene
      ref={scene}
    >
      <ambientLight
        intensity={0.5}
      />
      <directionalLight
        intensity={1.0}
        position={[1, 2, 1]}
      />
      <group
        position={[originPoint.x, originPoint.y, originPoint.z]}
      >
        <Sky
          scale={[axesScale*1e5, axesScale*1e5, axesScale*1e5]}
        />
      </group>,
      <group>
        {
          state.files.map((file, i) => (
            <CADFile
              key={i}
              file={file}
              fileIndex={i}
              visible={i === state.activeFileIndex && viewportReady}
              openCascadeWorker={openCascadeWorker}
              renderStyle={renderStyle}
            />
          ))
        }
      </group>
    </scene>
  </>;
}