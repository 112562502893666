import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { useState } from "react";
import { addSubmaterial, getMaterialMaster } from '../../../utils/actions/allactions';
import { validateAdmin, getToken,numberInputOnWheelPreventChange } from "../../../utils/helper";
import { toast } from "react-toastify";
import useDocumentTitle from "../../../utils/useDocumentTitle";

function AddSubmaterialMaster() {
  const token = getToken();
  const navigate = useNavigate();
  useDocumentTitle('Add SubMaterial')
  const [error, setError] = useState("");
  const [materialMaster, setMaterialMaster] = useState([]);
  const [initialValue, setInitialValue] = useState({
    name: '',
    SubMatFactor: '',
    masterMaterialId: '',
  });

  const setMaterialMasterVal = async () => {
    const materialMasterData = await getMaterialMaster(token);
    setMaterialMaster(materialMasterData);
  }

  useEffect(() => {
    validateAdmin(token, navigate);
    setMaterialMasterVal();
  }, []);

  const validateSubmaterialVal = () => {
    if (!initialValue.name ||
      !initialValue.SubMatFactor ||
      !initialValue.masterMaterialId) {
      return false;
    }
    return true;
  }

  const handleAddSubmaterial = async () => {
    // console.log(initialValue)
    setError('');
    const isSubmaterialValid = validateSubmaterialVal();

    if (!isSubmaterialValid) {
      setError("Enter valid values for all the field")
    } else {
      const addedSubMaterial = await addSubmaterial(token, initialValue);
    
      if(addedSubMaterial?.data?.data?.code==11000){
        toast.error("Submaterial already exists")
      }else{
        navigate('/admin/subMaterial');
        toast.success("Submaterial added successfully")
      }
    }
  }

  const handleSelectMaterial = (event) => {
    const selectedMaterialName = event.target.value;
    const selectedMaterialObject = materialMaster.filter((item, i) => {
      if (item.name == selectedMaterialName) {
        return item._id;
      }
    });

    setInitialValue({ ...initialValue, masterMaterialId: selectedMaterialObject[0]._id })
  }


  return (
    <div  className="admin-container" >
      <div>
        <Sidebar />
      </div>
      <div className="admin-panel-container">
        <h1 className="center-heading">  Add Submaterial </h1>

        <div className="edit-company-container">
          <div>
            <label htmlFor="name" > Name </label>: &nbsp;
            <input
              name="name"
              type="text"
              placeholder="Enter  name"
              onChange={(e) => { setInitialValue({ ...initialValue, name: e.target.value }) }}
            />
          </div>

          <div>
            <label htmlFor="SubMatFactor" > SubMatFactor </label>: &nbsp;
            <input
              name="SubMatFactor"
              type="number"
              onWheel={numberInputOnWheelPreventChange}
              placeholder="Enter SubMatFactor"
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={(e) => { setInitialValue({ ...initialValue, SubMatFactor: e.target.value }) }}
            />
          </div>

          <div>
            <label htmlFor="masterMaterialId">Material</label>: &nbsp;
            <select name='masterMaterialId' onChange={(e) => handleSelectMaterial(e)}>
              <option value="" selected disabled > Select Material</option>
              {
                materialMaster ? materialMaster.map((item, index) => {
                  return <option key={index} value={item.name} >{item.name}</option>
                }) : null
              }
            </select>
          </div>

          <div className="admin-status-div">
                        <label htmlFor="is3dPrinting" >Is 3d Printing?</label>: &nbsp;
                        <input
                            name="is3dPrinting"
                            type="radio"
                            value="true"
                            checked={initialValue.isThreeD ? true : ''}
                            onChange={(e) => { setInitialValue({ ...initialValue, isThreeD: e.target.value === "true" ? true : "" }) }}
                        />Yes

                        <input
                            name="is3dPrinting"
                            type="radio"
                            value="false"
                            checked={!initialValue.isThreeD ? true : null}
                            onChange={(e) => { setInitialValue({ ...initialValue, isThreeD: e.target.value === "false" ? false : "" }) }}
                        />No
                    </div>

                    <div className="admin-status-div">
                        <label htmlFor="isMachining" >Is  Machining?</label>: &nbsp;
                        <input
                            name="isMachining"
                            type="radio"
                            value="true"
                            checked={initialValue.isMachining ? true : ''}
                            onChange={(e) => { setInitialValue({ ...initialValue, isMachining: e.target.value === "true" ? true : "" }) }}
                        />Yes

                        <input
                            name="isMachining"
                            type="radio"
                            value="false"
                            checked={!initialValue.isMachining ? true : null}
                            onChange={(e) => { setInitialValue({ ...initialValue, isMachining: e.target.value === "false" ? false : "" }) }}
                        />No
                    </div>

          <div className="center-heading">
            <button className="button-search" onClick={handleAddSubmaterial}>  Create </button>
          </div>
          {
            error ?
              <div style={{ color: 'red', fontSize: '15px', textAlign: 'center', marginTop: '10px' }}>{error}</div>
              : null
          }
        </div>
      </div>
    </div >
  )
}
export default AddSubmaterialMaster;




