import axios from "axios";
const config = require(`../../environment/development`).config;
let cancelToken;
let postCancelToken;

export const fetchApi = (endPoint, payload = []) => {
  return new Promise(async (resolve, reject) => {
    try {
      let queryPayload = '';
      let payload2 = '';
      for (let p = 0; p < payload.length; p++) {
        if (p === 0) {
          queryPayload = `${payload[p].key}=${payload[p].value}`
        } else {
          queryPayload = `${queryPayload}&${payload[p].key}=${payload[p].value}`
        }
      }
      if (payload2 && payload.length > 1) {
        let response = await axios.get(`${config.backEnd}/${endPoint}?${payload2}`);
      }
      let response = await axios.get(`${config.backEnd}/${endPoint}?${queryPayload}`);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const postApi = async (endPoint, payload, token) => {
  return new Promise(async (resolve, reject) => {
    try {


      let header = {
        'Authorization': `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'application/json'
      }

      let response = await axios.post(`${config.backEnd}/${endPoint}`, payload, { headers: header });
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}

export const getAddressByUserId = async (token, userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        'Authorization': `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'application/json'
      }
      let response = await axios.get(`${config.backEnd}/comman/get-address-by-user-id/${userId}`, { headers: header });
      return resolve(response?.data);
    } catch (error) {
      return reject(error);
    }
  });
}

export const handleProjectNameChange = async (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        'Authorization': `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'application/json'
      }
      let response = await axios.post(`${config.backEnd}/comman/update-project-name`, data, { headers: header });
      return resolve(response?.data);
    } catch (error) {
      return reject(error);
    }
  });
}


export const handleSendInvitationToEmail = async (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        'Authorization': `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'application/json'
      }
      let response = await axios.post(`${config.backEnd}/comman/handle-send-invitation-to-email`, data, { headers: header });
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}


export const addFollowerToQuote = async (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        'Authorization': `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'application/json'
      }
      let response = await axios.post(`${config.backEnd}/comman/add-follower-to-quote`, data, { headers: header });
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}


export const changeOwnerOfQuote = async (token, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        'Authorization': `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'application/json'
      }
      let response = await axios.post(`${config.backEnd}/comman/change-owner-of-quote`, data, { headers: header });
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}


export const fetchUsersFromSameCompany = async (data, token) => {
  try {
    let header = {
      'Authorization': `Bearer ${JSON.parse(token)}`,
      'Content-Type': 'application/json'
    }
    let response = await axios.get(`${config.backEnd}/comman/fetch-users-from-same-company/${data}`, { headers: header }).then(res => {
      return res
    }).catch(err => {
      return err
    })
    return response
  } catch (error) {
    return error
  }
}


export const deleteFollowerFromQuotation = async (token, data) => {
  try {
    let header = {
      'Authorization': `Bearer ${JSON.parse(token)}`,
      'Content-Type': 'application/json'
    }
    let response = await axios.post(`${config.backEnd}/comman/delete-follower-from-quotation`, data, { headers: header }).then(res => {
      return res.data
    }).catch(err => {
      return err
    })
    return response
  } catch (error) {
    return error
  }
}




export const getPredictedValueForPart = (data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (cancelToken) {
        cancelToken.cancel();
      }
      cancelToken = axios.CancelToken.source();
      let header = {
        'Authorization': `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'application/json'
      }
      const formData = new FormData();
      if (!data?.Xdim && !data?.Ydim && !data?.Zdim && !data?.MaterialTypeName) {
        console.log('data that got rejected', data)
        return reject(0);
      };
      formData.append('Xdim', data?.Xdim || 0);
      formData.append('Ydim', data?.Ydim || 0);
      formData.append('Zdim', data?.Zdim || 0);
      formData.append('analysisCenterOfMassX', data?.File_Analysis_Data?.analysis?.centerOfMass?.x || 0);
      formData.append('analysisCenterOfMassY', data?.File_Analysis_Data?.analysis?.centerOfMass?.y || 0);
      formData.append('analysisCenterOfMassZ', data?.File_Analysis_Data?.analysis?.centerOfMass?.z || 0);
      formData.append('analysisFacesConeArea', data?.File_Analysis_Data?.analysis?.faces?.CONE?.area || 0);
      formData.append('analysisFacesConeCount', data?.File_Analysis_Data?.analysis?.faces?.CONE?.count || 0);
      formData.append('analysisFacesCylinderArea', data?.File_Analysis_Data?.analysis?.faces?.CYLINDER?.area || 0);
      formData.append('analysisFacesCylinderCount', data?.File_Analysis_Data?.analysis?.faces?.CYLINDER?.count || 0);
      formData.append('analysisFacesPlaneArea', data?.File_Analysis_Data?.analysis?.faces?.PLANE?.area || 0);
      formData.append('analysisFacesPlaneAreaCount', data?.File_Analysis_Data?.analysis?.faces?.PLANE?.count || 0);
      formData.append('analysisFacesTorusArea', data?.File_Analysis_Data?.analysis?.faces?.TORUS?.area || 0);
      formData.append('analysisFacesTorusCount', data?.File_Analysis_Data?.analysis?.faces?.TORUS?.count || 0);
      formData.append('analysisVolume', data?.File_Analysis_Data?.analysis?.volume || 0);
      formData.append('Material', data.MaterialTypeName);
      formData.append('fileFromServer', JSON.stringify(data.fileFromServer));
      formData.append('selectedFile', data.selectedFile);

      const response = await axios.post(
        `${config.backEnd}/comman/get-predicted-value-for-part`,
        formData,
        { headers: header }
      );

      //with cancel token
      // const response = await axios.post(
      //   `${config.backEnd}/comman/get-predicted-value-for-part`,
      //   formData,
      //   { headers: header,cancelToken: cancelToken.token}
      // );
      return resolve(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error:', error);
      }
      return reject(error);
    }
  });
};


export const getPredictedValueForPartToLoad = () => {
  return new Promise(async (resolve, reject) => {
    try {

      const response = await axios.get(
        `${config.backEnd}/comman/get-predicted-value-for-part-to-load`
      );
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}


export const getInvoicesForSalesOrder = (token, orderId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(`${config.backEnd}/comman/get-invoices-for-sales-order/${orderId}`, {
        headers: header,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const handleDownloadCocForInvoice = async (token, invoiceId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'application/json'
      }
      let response = await axios.get(`${config.backEnd}/comman/download-cert-of-conformance-invoice/${invoiceId}`, { headers: header }, { responseType: 'blob' });
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}


export const handleDownloadIndividualInvoice = async (token, invoiceId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        Authorization: `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'application/json'
      }
      let response = await axios.get(`${config.backEnd}/comman/download-individual-invoice/${invoiceId}`, { headers: header }, { responseType: 'blob' });
      return resolve(response.data);
    } catch (error) {
      return reject(error);
    }
  });
}


export const createNewQuotationVersionAndOrder = async (token, data) => {
  try {

    const response = await axios.post(`${config.backEnd}/comman/create-quote-from-ordered-quotation`, data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`,
        'Content-Type': "application/json",
      }
    });
    return response.data;

  } catch (error) {
    return false;
  }
}


export const searchUserByEmail = async (token, email) => {
  try {
    if (cancelToken) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    cancelToken = axios.CancelToken.source();

    const response = await axios.get(`${config.backEnd}/comman/search-user-by-email`, {
      params: { email },
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken.token,
    });

    cancelToken = null;

    return response.data;
  } catch (error) {
    return false;
  }
};


export const forwardToPurchaserMailTriggerFunction = async (token, data) => {
  try {
    const response = await axios.post(`${config.backEnd}/comman/send-forward-to-purchaser-email`, data);
    return response.data;

  } catch (error) {
    return false;
  }
}

export const getDefaultAddressOfUser = async (id) => {
  try {
    const response = await axios.get(`${config.backEnd}/comman/get-default-address/${id}`);
    return response.data;
  } catch (error) {
    return false;
  }
}

export const getUserFollowers = async (quoteId) => {
  try {
    const response = await axios.get(`${config.backEnd}/comman/get-users-followers/${quoteId}`);
    return response.data;
  } catch (error) {
    return false;
  }
}

export const getWebisteMasterData = async () => {
  try {
    const response = await axios.get(`${config.backEnd}/comman/get-master-data`);
    return response.data;
  } catch (error) {
    return false;
  }
}


export const getAllCertifications = async () => {
  try {
    const response = await axios.get(`${config.backEnd}/comman/get-all-certificates`);
    return response.data;
  } catch (error) {
    return false;
  }
}


//cnc

export const upsertCncHeatTreatment = async (payload) => {
  try {
    const response = await axios.post(`${config.backEnd}/comman/cnc/ht/upsert`, payload);
    return response.data;
  } catch (error) {
    return false;
  }
}


export const getAllHeatTreatment = async () => {
  try {
    const response = await axios.get(`${config.backEnd}/comman/cnc/ht/get-all`);
    return response.data;
  } catch (error) {
    return false;
  }
};

export const getHeatTreatmentDataById = async (id) => {
    try {
      const response = await axios.get(`${config.backEnd}/comman/cnc/ht/getById/${id}`);
      return response.data;
    } catch (error) {
      return false;
    }
}

