import React, { useState, useEffect } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { fetchApi } from "../../../utils/actions/commanActions";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { load3DViewer } from "../../../utils/trigger";
import BulkPricingModal from "./BulkPricingModal";
import {
  getAllBulkPricing,
  getMaterial,
  getThumbnail,
} from "../../../utils/actions/allactions";
import {
  currencyFormatter,
  extractURLAndEncodedFilename,
  numberInputOnWheelPreventChange,
} from "../../../utils/helper";
import fileSaver from "file-saver";
import { storage } from "../../../utils/firebase";
import { Loading } from "../../../components/Loading";
import { DeleteIcon, DownloadIcon } from "../../../components/Icons";
import { getAllTechnology } from "../../../utils/actions/adminActions";
import { miliMeterToInchFactor } from "../../../utils/constant";

const config = require(`../../../environment/development`).config;

const MachinePrintingInput = (props) => {

  const { masterThreeDTechnologyData } = props;
  let { _id } = useParams();
  const [thumbnail, setThumbnail] = useState('https://t2d2.ai/hubfs/Features/Feature%20Icons/12%20-%203D%20Model%20Viewer.png')
  const [material, setMaterial] = useState([]);
  const [subMaterial, setSubMaterial] = useState([{ _id: 1, name: "None" }]);
  const [surfaceFinish, setSurfaceFinish] = useState([]);
  const [tolerance, setTolerance] = useState([]);
  const [surfaceTreatment, setSurfaceTreatment] = useState([]);
  const [layer, setLayer] = useState([]);
  const [partmarking, setPartMarking] = useState([]);
  const [errors, seterrors] = useState({
    X: null,
    Y: null,
    Z: null,
  });
  const [showCad, setShowCad] = useState(false);
  const [showBulkPricing, setShowBulkPricing] = useState(false);
  const [pricingData, setPricingData] = useState([]);
  const token = localStorage.getItem("Token");
  const [materialsResponse, setMaterialsResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [technologyData, setTechnologyData] = useState(masterThreeDTechnologyData || []);
  const [machineData, setMachineData] = useState([]);
  const [materialData, setMaterialData] = useState([]);
  const [surfaceFinishData, setSurfaceFinishData] = useState([]);
  const [isFirstTimeLoaded, setIsFirstTimeLoaded] = useState(true);






  useEffect(() => {

    if (!props?.singleValue?.threeDTechnology && !props?.singleValue?.threeDMachine && !props.singleValue?.threeDMaterial) {

      const firstTech = masterThreeDTechnologyData[0]?._id;
      props.updateValueOnChange(
        props.index,
        "threeDTechnology",
        firstTech,
        getFileExtension(props?.file[props?.index]?.name)
      );

    }

  }, [masterThreeDTechnologyData, props?.boxDimension, props?.file]);


  useEffect(() => {
    if (props?.singleValue?.threeDTechnology && props?.boxDimension?.length === props?.file?.length &&
      isValidFileName(
        props?.file[props.index]?.originalname ||
        props.file[props.index]?.name ||
        props.file[props.index]?.file?.originalname ||
        props?.singleValue?.file?.filename ||
        props?.file[props.index]?.selectedFile?.path ||
        props?.singleValue?.file?.path
      )) {
      const selectedTechnology = masterThreeDTechnologyData.find(({ _id }) => _id == props?.singleValue?.threeDTechnology);
      console.log("Selected Technology", selectedTechnology);
      const { machines } = selectedTechnology;
      const materialSet = {};
      const postProcessMap = {};
      const x = Math.abs(
        props?.boxDimension[props?.index][0]?.boundingBox?.xmax -
        props?.boxDimension[props?.index][0]?.boundingBox?.xmin
      ).toFixed(2);

      const y = Math.abs(
        props?.boxDimension[props?.index][0]?.boundingBox?.ymax -
        props?.boxDimension[props?.index][0]?.boundingBox?.ymin
      ).toFixed(2);

      const z = Math.abs(
        props?.boxDimension[props?.index][0]?.boundingBox?.zmax -
        props?.boxDimension[props?.index][0]?.boundingBox?.zmin
      ).toFixed(2);

      const volumeInCC = parseFloat(Number(props.boxDimension[props?.index][0]?.analysis?.volume) / 1000);

      machines?.forEach((machine) => {
        const { materials } = machine;
        const { dimensions: { xDim, yDim, zDim } } = machine;
        const machinesDimensions = [xDim, yDim, zDim];
        machinesDimensions.sort((a, b) => a - b);
        const partsDimensions = [x, y, z];
        partsDimensions.sort((a, b) => a - b);
        if (
          machinesDimensions[0] >= partsDimensions[0]
          && machinesDimensions[1] >= partsDimensions[1]
          && machinesDimensions[2] >= partsDimensions[2]

        ) {
          materials?.forEach((material) => {
            const nameOfMaterial = material?.name;
            (!materialSet[nameOfMaterial]) && (materialSet[nameOfMaterial] = material);
          })

        }

      });
      const allMaterial = Object.values(materialSet);
      const firstMaterialId = allMaterial[0]?._id;
      props.updateValueOnChange(
        props.index,
        "threeDMaterial",
        firstMaterialId,
      );
      setMaterialData(allMaterial);
      if (
        x && y && z && volumeInCC &&
        !props.values[props.index]?.XDimensionValue &&
        !props.values[props.index]?.YDimensionValue &&
        !props.values[props.index]?.ZDimensionValue &&
        !props.values[props.index]?.singleVolume
      ) {
        props.setValues((pre) => {
          return pre.map((item, idx) => {
            if (idx == props.index) {
              item.XDimensionValue = x;
              item.YDimensionValue = y;
              item.ZDimensionValue = z;
              item.singleVolume = volumeInCC;
              // console.log(item.singleVolume);
            }
            return item;
          })
        })
      }

    }
    else if (props?.singleValue?.threeDTechnology) {
      const selectedTechnology = masterThreeDTechnologyData.find(({ _id }) => _id == props?.singleValue?.threeDTechnology);
      console.log("Selected Technology", selectedTechnology);
      const { machines } = selectedTechnology;
      const materialSet = {};
      machines?.forEach((machine) => {
        const { materials } = machine;
        materials?.forEach((material) => {
          const nameOfMaterial = material?.name;
          (!materialSet[nameOfMaterial]) && (materialSet[nameOfMaterial] = material);
        })

      });
      const allMaterial = Object.values(materialSet);
      const firstMaterialId = allMaterial[0]?._id;
      props.updateValueOnChange(
        props.index,
        "threeDMaterial",
        firstMaterialId,
      );
      setMaterialData(allMaterial);
    }

  }, [props?.singleValue?.threeDTechnology]);

  useEffect(() => {
    if (props?.singleValue?.threeDMaterial) {
      console.log('ThreeDMaterial :', props?.singleValue?.threeDMaterial);
      const materialId = props?.singleValue?.threeDMaterial;
      const techId = props?.singleValue?.threeDTechnology;
      let postProcessData = [];

      const selectedTech = masterThreeDTechnologyData?.find(({ _id }) => _id == techId);
      selectedTech?.machines?.forEach((machine) => {
        const { materials } = machine;
        materials?.forEach((material) => {
          if (material?._id == materialId) {
            const { postProcess } = material;
            postProcessData = postProcess;
          }
        })
      })

      if (postProcessData.length) {
        console.log("Post Data", postProcessData);

        const allPostProcess = postProcessData;
        const firstPostProcessData = isFirstTimeLoaded ? props?.singleValue?.threeDPostProcessing : allPostProcess[0]?._id;
        props.updateValueOnChange(
          props.index,
          "threeDPostProcessing",
          firstPostProcessData
        );
        setSurfaceFinishData(allPostProcess);
      }
    }
    setIsFirstTimeLoaded(false);
  }, [props?.singleValue?.threeDMaterial]);
  // useEffect(() => {
  //   if (props?.singleValue?.threeDMaterial) {
  //     console.log('ThreeDMaterial :', props?.singleValue?.threeDMaterial);
  //     const materialId = props?.singleValue?.threeDMaterial;
  //     const techId = props?.singleValue?.threeDTechnology;
  //     let postProcessData = [];

  //     const selectedTech = masterThreeDTechnologyData?.find(({ _id }) => _id == techId);
  //     selectedTech?.machines?.forEach((machine) => {
  //       const { materials } = machine;
  //       materials?.forEach((material) => {
  //         if (material?._id == materialId) {
  //           const { postProcess } = material;
  //           postProcessData = postProcess;
  //         }
  //       })
  //     })

  //     if (postProcessData.length) {
  //       console.log("Post Data", postProcessData);

  //       const allPostProcess = postProcessData;
  //       const firstPostProcessData = props?.singleValue?.threeDPostProcessing;
  //       props.updateValueOnChange(
  //         props.index,
  //         "threeDPostProcessing",
  //         firstPostProcessData
  //       );
  //       setSurfaceFinishData(allPostProcess);
  //     }
  //   }
  //   setIsFirstTimeLoaded(false);
  // }, []);


  // useEffect(() => {
  //   if (props?.boxDimension?.length === props?.file?.length &&
  //     isValidFileName(
  //       props?.file[props.index]?.originalname ||
  //       props.file[props.index]?.name ||
  //       props.file[props.index]?.file?.originalname ||
  //       props?.singleValue?.file?.filename ||
  //       props?.file[props.index]?.selectedFile?.path ||
  //       props?.singleValue?.file?.path
  //     )) {

  //     const x = Math.abs(
  //       props?.boxDimension[props?.index][0]?.boundingBox.xmax -
  //       props?.boxDimension[props?.index][0]?.boundingBox.xmin
  //     ).toFixed(2);

  //     const y = Math.abs(
  //       props?.boxDimension[props?.index][0]?.boundingBox.ymax -
  //       props?.boxDimension[props?.index][0]?.boundingBox.ymin
  //     ).toFixed(2);

  //     const z = Math.abs(
  //       props?.boxDimension[props?.index][0]?.boundingBox.zmax -
  //       props?.boxDimension[props?.index][0]?.boundingBox.zmin
  //     ).toFixed(2);
  //     // XDimensionValue
  //     // YDimensionValue
  //     // ZDimensionValue

  //     if (
  //       x && y && z &&
  //       !props.values[props.index]?.XDimensionValue &&
  //       !props.values[props.index]?.YDimensionValue &&
  //       !props.values[props.index]?.ZDimensionValue
  //     ) {
  //       props.setValues((pre) => {
  //         return pre.map((item, idx) => {
  //           if (idx == props.index) {
  //             item.XDimensionValue = x;
  //             item.YDimensionValue = y;
  //             item.ZDimensionValue = z;
  //           }
  //           return item;
  //         })
  //       })
  //     }
  //     if (x && y && z && !props?.singleValue?.threeDTechnology && !props?.singleValue?.threeDMachine &&  !props.singleValue?.threeDMaterial 
  //       && !props.singleValue?.threeDPostProcessing 
  //     ) {
  //       for (const technology of masterThreeDTechnologyData) {
  //         const { machines } = technology;
  //         for (const machine of machines) {
  //           const { materials } = machine;
  //           const { dimensions: { xDim, yDim, zDim } } = machine;
  //           if (xDim < x || yDim < y || zDim < z) continue;
  //           const techValue = technology?._id;
  //           //setting tech data
  //           setTechnologyData(masterThreeDTechnologyData);
  //           props.updateValueOnChange(
  //             props.index,
  //             "threeDTechnology",
  //             techValue,
  //             getFileExtension(props?.file[props?.index]?.name)
  //           );
  //           //setting machine data;
  //           const machineId = machine?._id;
  //           setMachineData(machines);
  //           props.updateValueOnChange(
  //             props.index,
  //             "threeDMachine",
  //             machineId
  //           );
  //           //setting material data;
  //           setMaterialData(materials);
  //           const materialId = materials[0]?._id;
  //           props.updateValueOnChange(
  //             props.index,
  //             "threeDMaterial", materialId,
  //           );

  //           //surface finish;

  //           const postData = materials[0]?.postProcess;
  //           setSurfaceFinishData(postData);
  //           if (postData && postData?.length > 0) {
  //             const postId = postData[0]?._id;
  //             props.updateValueOnChange(
  //               props.index,
  //               "threeDPostProcessing", postId
  //             );
  //           }

  //         }
  //       }
  //     }
  //   }
  // }, [masterThreeDTechnologyData, props?.boxDimension, props?.file]);


  // useEffect(() => {
  //   if (props?.singleValue?.threeDTechnology) {
  //     const selectedTechnology = masterThreeDTechnologyData.find(({ _id }) => {
  //       return _id == props?.singleValue?.threeDTechnology;
  //     });
  //     const updatedMachies = selectedTechnology?.machines;
  //     const firstMachineId = updatedMachies[0]?._id;

  //     setMachineData(updatedMachies);
  //     props.updateValueOnChange(
  //       props.index,
  //       "threeDMachine",
  //       firstMachineId,
  //       getFileExtension(props?.file[props?.index]?.name)
  //     );
  //     props.setIsQuoteChanged(true);
  //   }


  // }, [props?.singleValue?.threeDTechnology]);

  // useEffect(() => {
  //   if (props?.singleValue?.threeDMachine) {
  //     const techId = props?.singleValue?.threeDTechnology;
  //     const machineId = props?.singleValue?.threeDMachine;
  //     const techData = masterThreeDTechnologyData?.find(({ _id }) => _id == techId);
  //     const allMachines = techData?.machines;
  //     const updatedMachines = allMachines?.find(({ _id }) => _id == machineId);
  //     const materials = updatedMachines?.materials;
  //     const firstMaterialId = materials[0]?._id;
  //     props.updateValueOnChange(
  //       props.index,
  //       "threeDMaterial",
  //       firstMaterialId,
  //       getFileExtension(props?.file[props?.index]?.name)
  //     );
  //     props.setIsQuoteChanged(true);

  //     setMaterialData(materials);
  //   }
  // }, [props?.singleValue?.threeDMachine])


  // useEffect(() => {
  //   if (props?.singleValue?.threeDMaterial) {
  //     const techId = props?.singleValue?.threeDTechnology;
  //     const machineId = props?.singleValue?.threeDMachine;
  //     const materialId = props?.singleValue?.threeDMaterial;
  //     const techData = masterThreeDTechnologyData?.find(({ _id }) => _id == techId);
  //     const allMachines = techData?.machines;
  //     const updatedMachines = allMachines?.find(({ _id }) => _id == machineId);
  //     const materials = updatedMachines?.materials;
  //     const selectedMaterial = materials.find(({ _id }) => _id == materialId);
  //     // console.log(postProcess);
  //     const firstPostProcessId = selectedMaterial?.postProcess[0]?._id;
  //     props.updateValueOnChange(
  //       props.index,
  //       "threeDPostProcessing",
  //       firstPostProcessId,
  //       getFileExtension(props?.file[props?.index]?.name)
  //     );
  //     props.setIsQuoteChanged(true);
  //     setSurfaceFinishData(selectedMaterial?.postProcess || []);

  //   }

  // }, [props?.singleValue?.threeDMaterial]);







  // useEffect(() => {
  //   if (props?.singleValue?.threeDTechnology) {
  //     const techData = masterThreeDTechnologyData.find(({ _id }) => _id == props?.singleValue?.threeDTechnology);
  //     const allMachines = techData?.machines;
  //     const updatedMachines = allMachines.filter(({ dimensions: { xDim, xDim, zDim
  //     } }) => {
  //       if
  //     })

  //   }

  // }, [props?.singleValue?.threeDTechnology])






  let indexFor2DFiles = props.index;

  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  const handleQtyChange = (value) => {
    try {
      let percent = 0;
      pricingData?.map((el) => {
        if (value >= el.qty) {
          percent = el.percentage;
        }
        return el;
      });
      props.updateValueOnChange(props.index, "Qty", parseInt(value), percent);
    } catch (err) {
      return err;
    }
  };

  const handle2DFileUpload = (e) => {
    try {
      let filesForCheck = Array.from(e.target.files);
      let isAllFilesValid = true;
      filesForCheck.forEach(file => {
        if (file.size > 10485760) {
          isAllFilesValid = false;
        }
      });

      if (!isAllFilesValid) {
        toast.error("File size should be less than 10 MB");
        return;
      }

      let local2DFiles = props.twoDFiles;
      props.setTwoDFiles({
        ...local2DFiles,
        [indexFor2DFiles]: {
          file: e.target.files[0],
          fileName: e.target.files[0].name,
          isNotFromMongoDB: true,
        },
      });
      let userId = localStorage.getItem("_id");
      let userName =
        localStorage.getItem("firstName") +
        "_" +
        localStorage.getItem("lastName") +
        "_" +
        userId;
      storage
        .ref(
          `/quotation-files/2D/${userName}/${Date.now() + "_FileName_" + e.target.files[0].name
          }`
        )
        .put(e.target.files[0]);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleDelete2DFiles = () => {
    try {
      let local2DFiles = props.twoDFiles;
      delete local2DFiles[indexFor2DFiles];

      props.setTwoDFiles({ ...local2DFiles });
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const response = await getAllBulkPricing(token);
        setPricingData(response.data);
        if (!response.status) {
          throw new Error("Failed To Get Bulk Pricing");
        }
        const res = response?.data?.find((el) => {
          if (el.qty == 1) {
            return el;
          }
        });

        if (props.selectedProcess == 2 || props.selectedProcess == 1) {
          let materialResponse = await getMaterial(token);
          if (materialResponse.status) {
            setMaterialsResponse(materialResponse?.data);
          }
        }

        setIsLoading(false);
      } catch (err) {
        console.log(err);
        toast.error(
          "Something went wrong while fetching material data. Please refresh the page to try again."
        );
        setIsLoading(false);
        return err;
      }
    }
    fetchData();
  }, []);







  useEffect(() => {
    props.updateCommanValueOnChange(
      "sameForAllField",
      props?.sameForAllField,
      getFileExtension(props?.file[props?.index]?.name)
    );
  }, [props.file]);

  useEffect(() => {
    let localValues = props.values;
    if (props.selectedProcess == 1) {
      localValues.forEach(
        (value) => (value.MaterialType = "6329c5a2413cc8e2f7a92c4c")
      );
      localValues.forEach(
        (value) => (value.SubMaterial = "6329e7fc413cc8e2f7a92c91")
      );
      localValues.forEach(
        (value) => (value.surfaceFinish = "6329be5e413cc8e2f7a92bfd")
      );
      localValues.forEach((value) => (value.surfaceTreatment = ""));
      localValues.forEach((value) => (value.tolerance = ""));
      localValues.forEach((value) => (value.partMarking = ""));
    } else if (props.selectedProcess == 2) {
      localValues.forEach(
        (value) => (value.MaterialType = "6329c5a2413cc8e2f7a92c34")
      );
      localValues.forEach(
        (value) => (value.SubMaterial = "6329e7fc413cc8e2f7a92c53")
      );
      localValues.forEach((value) => (value.surfaceFinish = ""));
      localValues.forEach((value) => (value.surfaceTreatment = ""));
      localValues.forEach((value) => (value.tolerance = ""));
      localValues.forEach((value) => (value.partMarking = ""));
    }
    props.setValues(localValues);
  }, [props.selectedProcess]);

  let getFileExtension = (filename) => {
    return filename?.slice(((filename?.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (!props.selectedProcess) {
          return;
        }

        let filteredMaterials = [];
        if (props.selectedProcess === 1 && materialsResponse?.material) {
          filteredMaterials = materialsResponse?.material.filter(
            (item) => item.isMachining
          );
        } else if (props.selectedProcess === 2 && materialsResponse?.material) {
          filteredMaterials = materialsResponse?.material.filter(
            (item) => item.is3DPrinting
          );
        }
        if (props.selectedProcess === 1 || props.selectedProcess === 2) {
          setMaterial(filteredMaterials);
          if (
            !props.singleValue.MaterialType ||
            props.singleValue.MaterialType == ""
          ) {
            return;
          }

          let filteredSubMaterial = [];
          filteredSubMaterial = materialsResponse?.submaterial
            ? materialsResponse?.submaterial.filter(
              (item) =>
                item.masterMaterialId == props.singleValue.MaterialType &&
                ((props.selectedProcess == 1 && item.isMachining) ||
                  (props.selectedProcess == 2 && item.isThreeD))
            )
            : [];

          if (filteredSubMaterial.length) {
            setSubMaterial(filteredSubMaterial);
          } else {
            setSubMaterial([{ _id: 1, name: "None" }]);
          }

          let filteredSurfaceTreatment =
            materialsResponse?.surfacetreat &&
            materialsResponse?.surfacetreat.filter(
              (item) => item.masterMaterialId == props.singleValue.MaterialType
            );
          setSurfaceTreatment(filteredSurfaceTreatment || []);
          setSurfaceFinish(materialsResponse?.surafcefinish || []);
          setPartMarking(materialsResponse?.partMarking || []);
          setTolerance(materialsResponse?.tolerance || []);
          setLayer(materialsResponse?.layer || []);
        }
      } catch (err) {
        console.log("err", err);
        return err;
      }
    }
    fetchData();
  }, [
    props.selectedProcess,
    props.singleValue.MaterialType,
    materialsResponse,
  ]);

  const fetchDataFromApi = (endPoint, payload = []) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetchApi(endPoint, payload);
        if (!response && !response.status) {
          throw { message: response.message || "Something went wrong" };
        }
        return resolve(response.data);
      } catch (err) {
        return reject(err);
      }
    });
  };

  const handleOptionChange = (type, value) => {
    try {
      switch (type) {
        case 1:
          props.updateValueOnChange(
            props.index,
            "MaterialType",
            value,
            getFileExtension(props?.file[props?.index]?.name)
          );
          props.updateValueOnChange(
            props.index,
            "SubMaterial",
            "",
            getFileExtension(props?.file[props?.index]?.name)
          );
          props.setIsQuoteChanged(true);
          break;
        case 2:
          props.updateValueOnChange(
            props.index,
            "SubMaterial",
            value,
            getFileExtension(props?.file[props?.index]?.name)
          );
          props.setIsQuoteChanged(true);
          break;
        case 3:
          props.updateValueOnChange(
            props.index,
            "surfaceFinish",
            value,
            getFileExtension(props?.file[props?.index]?.name)
          );
          props.setIsQuoteChanged(true);
          break;
        case 4:
          props.updateValueOnChange(
            props.index,
            "surfaceTreatment",
            value,
            getFileExtension(props?.file[props?.index]?.name)
          );
          props.setIsQuoteChanged(true);
          break;
        case 5:
          props.updateValueOnChange(
            props.index,
            "tolerance",
            value,
            getFileExtension(props?.file[props?.index]?.name)
          );
          props.setIsQuoteChanged(true);
          break;
        case 6:
          props.updateValueOnChange(
            props.index,
            "layer",
            value,
            getFileExtension(props?.file[props?.index]?.name)
          );
          props.setIsQuoteChanged(true);
          break;
        case 7:
          props.updateValueOnChange(
            props.index,
            "partMarking",
            value,
            getFileExtension(props?.file[props?.index]?.name)
          );
          props.setIsQuoteChanged(true);
          break;
        case 8:
          // props.updateCommanValueOnChange('sameForAllField', !props?.commanValues?.sameForAllField, getFileExtension(props?.file[props?.index]?.name)); commenting for same for all field
          props.updateCommanValueOnChange(
            "sameForAllField",
            !props?.sameForAllField,
            getFileExtension(props?.file[props?.index]?.name)
          );
          props.setIsQuoteChanged(true);
          break;
        case 9:
          props.updateValueOnChange(
            props.index,
            "noOfThread",
            value,
            getFileExtension(props?.file[props?.index]?.name)
          );
          props.setIsQuoteChanged(true);
          break;
        case 10:
          props.updateValueOnChange(
            props.index,
            "finalCost",
            value,
            getFileExtension(props?.file[props?.index]?.name)
          );

        case 11:
          props.updateValueOnChange(
            props.index,
            "threeDTechnology",
            value,
            getFileExtension(props?.file[props?.index]?.name)
          );




        case 12:
          props.updateValueOnChange(
            props.index,
            "threeDMachine",
            value,
            getFileExtension(props?.file[props?.index]?.name)
          );
          props.setIsQuoteChanged(true);
          break;

        case 13:
          props.updateValueOnChange(
            props.index,
            "threeDMaterial",
            value,
            getFileExtension(props?.file[props?.index]?.name)
          );
          props.setIsQuoteChanged(true);
          break;

        case 14:
          props.updateValueOnChange(
            props.index,
            "threeDPostProcessing",
            value,
            getFileExtension(props?.file[props?.index]?.name)
          );
          props.setIsQuoteChanged(true);
          break;
        default:
          break;
      }
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    try {
      if (
        props.singleValue.algorithmPrice &&
        props.singleValue.partMarking &&
        props.singleValue.partMarking !== ""
      ) {
        handleOptionChange(10);
      }
    } catch (err) {
      console.log(err);
    }
  }, [props.singleValue.partMarking, props.singleValue.noOfThread]);

  const handleClick = async (file) => {

    let response;
    let res;
    try {
      if (_id) {
        response = await fetchDataFromApi(`comman/fetch-quote-byId/${_id}`);
      }

      const type = getFileExtension(
        file?.name ||
        file?.filename ||
        file?.path ||
        file?.originalname ||
        response?.partsData[props.index]?.file?.originalname
      );

      if (_id) {
        res = await fetch(
          `${config.backEnd}/public/uploads/${response?.partsData[props.index]?._id
          }.${type}`
        );
      }

      if
        (props.sample ||
        type == 'stp' ||
        type == 'step' ||
        type == 'iges' ||
        type == 'stl' ||
        type == 'STP' ||
        type == 'STEP' ||
        type == 'IGES' ||
        type == 'STL'
      ) {
        setShowCad(true);
        setTimeout(() => {
          const extension =
            getFileExtension(
              file?.name ||
              file?.filename ||
              response?.partsData[props.index]?.file?.originalname
            ) || "stp";
          if (res?.ok && res?.url) {
            load3DViewer(res?.url, extension);
          } else {
            load3DViewer(file, extension);
          }
        }, 0);
      } else {
        return false;
      }
    } catch (error) {
      toast.error("Unable To  Fetch Quote By Id");
    }
  };

  const isValidFileName = (name) => {
    const type = getFileExtension(name)?.toUpperCase();
    if (
      type === "STP" ||
      type === "STEP" ||
      type === "IGES" ||
      type === "OBJ" ||
      type === "STL"
    )
      return true;

    return false;
  };

  const showBulkPricingModal = () => {
    setShowBulkPricing(true);
  };

  useEffect(() => {
    let localFiles = props.values.map((item) => {
      return item.file || item.selectedFile;
    });
    props.setFiles(localFiles);
  }, []);

  if (showCad) {
    return (
      <Modal
        contentClassName="custom-modal-style"
        show={showCad}
        onHide={() => setShowCad(false)}
      >
        <Modal.Body>
          <div id="cad-analyzer"></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCad(false)}>
            Back
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }


  return (
    <div
      className="border p-2 my-2"
      key={props.keyValue}
      // style={{ paddingTop: "15px", marginBottom: "15px" }}
      ref={props.myRef}
    >
      {isLoading && !props.processing && !props.globeLoader && <Loading />}
      <div className="flex text-sm  justify-between lg:flex-row flex-wrap flex-col items-start lg:items-center  ">
        <div className="flex md:items-center md:flex-row flex-col justify-center gap-x-3 items-start">
          {(() => {
            const ext = isValidFileName(props?.file[props.index]?.originalname || props?.file[props.index]?.name);
            if (ext) {
              return <div
                onClick={() =>
                  handleClick(
                    props?.file[props.index].selectedFile ||
                    props?.file[props.index]
                  )
                }
                className="flex flex-col !cursor-pointer "
              >
                <img
                  className="h-14"
                  src={props?.values[props.index]?.thumbnail || 'https://three-d-files-thumbnail.s3.eu-west-1.amazonaws.com/thumbnails/3d.png'}
                />
                <p className="font-bold  text-black">3D Viewer </p>
              </div>
            }
            return null;
          })()}

          <div className="flex flex-col  gap-y-2 ">
            <h6 className="flex justify-center gap-x-2">
              <span className="font-bold">File Name :</span>
              <span
                className="w-44  overflow-hidden whitespace-nowrap inline-block text-ellipsis"
                title={
                  props.file[props.index]?.name ||
                  props.singleValue?.file?.originalname ||
                  props.file[props.index]?.selectedFile?.path ||
                  props.singleValue?.file?.path ||
                  "SamplePart.stp"
                }
              >
                {(
                  props.file[props.index]?.name ||
                  props.singleValue?.file?.originalname ||
                  props.file[props.index]?.selectedFile?.path ||
                  props.singleValue?.file?.path ||
                  "SamplePart.stp"
                )}
              </span>

              <button
                className="w-max px-3 text-red-500 "
                onClick={() => props.onDelete(props.index)}
              >
                Delete{" "}
              </button>
            </h6>
            <h6>
              <span className="font-bold">Dimension : </span>
              <span>
                {props?.boxDimension?.length === props?.file?.length &&
                  isValidFileName(
                    props?.file[props.index]?.originalname ||
                    props.file[props.index]?.name ||
                    props.file[props.index]?.file?.originalname ||
                    props?.singleValue?.file?.filename ||
                    props?.file[props.index]?.selectedFile?.path ||
                    props?.singleValue?.file?.path
                  )
                  ? props?.sample || props.isEditQuote
                    ? Math.abs(
                      (props?.boxDimension[props?.index][0]?.boundingBox
                        ?.xmax -
                        props?.boxDimension[props?.index][0]?.boundingBox
                          ?.xmin) / (props.isInchSelected ? miliMeterToInchFactor : 1)
                    ).toFixed(2) +
                    " X " +
                    Math.abs(
                      (props?.boxDimension[props?.index][0]?.boundingBox
                        ?.ymax -
                        props?.boxDimension[props?.index][0]?.boundingBox
                          ?.ymin) / (props.isInchSelected ? miliMeterToInchFactor : 1)
                    ).toFixed(2) +
                    " X " +
                    Math.abs(
                      (props?.boxDimension[props?.index][0]?.boundingBox
                        ?.zmax -
                        props?.boxDimension[props?.index][0]?.boundingBox
                          ?.zmin) / (props.isInchSelected ? miliMeterToInchFactor : 1)
                    ).toFixed(2)
                    : props?.boxDimension[props?.index]?.length
                      ? Math.abs(
                        (props?.boxDimension[props?.index][0]?.boundingBox
                          ?.xmax -
                          props?.boxDimension[props?.index][0]?.boundingBox
                            ?.xmin) / (props.isInchSelected ? miliMeterToInchFactor : 1)
                      ).toFixed(2) +
                      " X " +
                      Math.abs(
                        (props?.boxDimension[props?.index][0]?.boundingBox
                          ?.ymax -
                          props?.boxDimension[props?.index][0]?.boundingBox
                            ?.ymin) / (props.isInchSelected ? miliMeterToInchFactor : 1)
                      ).toFixed(2) +
                      " X " +
                      Math.abs(
                        (props?.boxDimension[props?.index][0]?.boundingBox
                          ?.zmax -
                          props?.boxDimension[props?.index][0]?.boundingBox
                            ?.zmin) / (props.isInchSelected ? miliMeterToInchFactor : 1)
                      ).toFixed(2)
                      : null
                  : null}
                {props.isInchSelected ? " (inch)" : " (mm)"}
              </span>
              {props.selectedProcess === 2 && (
                <span className="error">
                  {errors?.X && <span className="error">{errors.X}</span>}
                </span>
              )}
            </h6>
          </div>
        </div>
        <div className="flex items-center justify-center gap-x-2">
          <label style={{ minWidth: "40px" }}>2D File</label>
          {props?.twoDFiles[indexFor2DFiles]?.file?.name ||
            props?.twoDFiles[indexFor2DFiles]?.file?.originalname ? (
            <div>
              {props?.twoDFiles[indexFor2DFiles]?.file?.name ? (
                <div className="flex gap-x-2  justify-between items-center ">
                  <a
                    className=" flex py-2 items-center justify-between gap-x-1"
                    href={URL.createObjectURL(
                      props?.twoDFiles[indexFor2DFiles]?.file
                    )}
                    download={props?.twoDFiles[indexFor2DFiles]?.file?.name}
                  >
                    <span className="text-sm !text-primaryColor">
                      {props?.twoDFiles[indexFor2DFiles]?.file?.name?.length >
                        25
                        ? (props?.twoDFiles[
                          indexFor2DFiles
                        ]?.file?.name).substring(0, 25) + "..."
                        : props?.twoDFiles[indexFor2DFiles]?.file?.name}
                    </span>{" "}
                    <DownloadIcon additionalClass={'h-4'} />
                  </a>
                  {/* &nbsp;&nbsp;&nbsp; */}
                  <div onClick={handleDelete2DFiles}>
                    <DeleteIcon
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {props?.twoDFiles[indexFor2DFiles]?.file?.originalname ? (
                <div
                  // style={{
                  //   border: "1px solid #eaedf0",
                  //   width: "100%",
                  //   borderRadius: "5px",
                  //   padding: "5px 10px",
                  //   display: "flex",
                  //   justifyContent: "space-between",
                  // }}
                  className="flex justify-center gap-x-2 items-center"
                >
                  <span
                    className="downloadables flex items-center gap-x-2 text-primaryColor"
                    onClick={() =>
                      download(
                        props?.twoDFiles[indexFor2DFiles]?.file?.originalname,
                        `${config.backEnd}/` +
                        props?.twoDFiles[indexFor2DFiles]?.file?.path
                      )
                    }
                  >

                    <span style={{ fontSize: "12px" }} className="text-primaryColor">
                      {props?.twoDFiles[indexFor2DFiles]?.file?.originalname
                        ?.length > 25
                        ? (props?.twoDFiles[
                          indexFor2DFiles
                        ]?.file?.originalname).substring(0, 25) + "..."
                        : props?.twoDFiles[indexFor2DFiles]?.file?.originalname}
                    </span>
                    <DownloadIcon additionalClass={'text-primaryColor'} />
                  </span>
                  <div onClick={handleDelete2DFiles}>
                    <DeleteIcon
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <>
              <div className="file-button !cursor-pointer relative">
                <input
                  name="file-input-2d"
                  id={`file-input-2d-${props.index}`}
                  className="file-input__input"
                  type="file"
                  onChange={handle2DFileUpload}
                />
                <label
                  className="blue-btn-ui !cursor-pointer"
                  htmlFor={`file-input-2d-${props.index}`}
                  style={{ padding: "5px" }}
                >
                  {/* {alert(props.singleValue?.twoDRequired)} */}
                  <span >Upload 2D file </span>
                  {props.singleValue?.twoDFileRequired && <span className="text-red-500  absolute  -translate-x-[70px] mt-4">(required)</span>}
                </label>
                
              </div>
            </>
          )}
        </div>
        <div className="flex items-center justify-center gap-x-3">
          <label>QTY</label>
          <div class="relative flex items-center max-w-[8rem]">
            <button
              type="button"
              id="decrement-button"
              onClick={() => {
                if (props.singleValue.Qty > 1) {
                  handleQtyChange(Number(props.singleValue.Qty) - 1);
                }
              }}
              data-input-counter-decrement="quantity-input"
              class="bg-gray-100  hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
            >
              <svg
                class="w-3 h-3 text-gray-900"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 2"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 1h16"
                />
              </svg>
            </button>
            <input
              type="number"
              onWheel={numberInputOnWheelPreventChange}
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              onChange={(e) => {
                if (!e.target.value || isNaN(e.target.value)) {
                  return;
                }
                props.setIsQuoteChanged(true);
                handleQtyChange(e.target.value);
              }}
              value={props.singleValue.Qty}
              min={1}
              id="quantity-input"
              data-input-counter
              class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5"
            />
            <button
              type="button"
              id="increment-button"
              data-input-counter-increment="quantity-input"
              onClick={() => handleQtyChange(Number(props.singleValue.Qty) + 1)}
              class="bg-gray-100  hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
            >
              <svg
                class="w-3 h-3 text-gray-900"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className=" flex flex-col items-center my-2">
          <div>
            {props.selectedProcess === 2 &&
              isValidFileName(
                props.file[props.index]?.name ||
                props?.singleValue?.file?.originalname ||
                props.file[props.index]?.file?.originalname ||
                props?.singleValue?.file?.filename ||
                props?.file[props.index]?.selectedFile?.path ||
                props?.singleValue?.file?.path
              ) &&
              props?.singleValue?.price &&
              !props.commanValues.hidePrice &&
              !props.isInvalidFiles ? (
              <div className="pro-price">
                {/* Hiding Bulk pricing for future implementation, the bulk pricing discounts are still applied */}
                {/* <p className="bl-price" onClick={showBulkPricingModal}>See Bulk Pricing</p> */}
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  <span>
                    {props.commanValues.selectedShipMethod == 0
                      ? currencyFormatter(
                        Number(
                          props.singleValue.Qty *
                          Number(props?.singleValue?.price)
                        )
                      )
                      : props.commanValues.selectedShipMethod == 1
                        ? currencyFormatter(
                          Number(
                            props.singleValue.Qty *
                            Number(props?.singleValue?.price1)
                          )
                        )
                        : currencyFormatter(
                          Number(
                            props.singleValue.Qty *
                            Number(props?.singleValue?.price2)
                          )
                        )}
                  </span>
                  <span style={{ fontSize: "12px", color: "#858585" }}>
                    {`${props.commanValues.selectedShipMethod == 0
                      ? currencyFormatter(Number(props?.singleValue?.price))
                      : props.commanValues.selectedShipMethod == 1
                        ? currencyFormatter(Number(props?.singleValue?.price1))
                        : currencyFormatter(Number(props?.singleValue?.price2))
                      } Each`}
                  </span>
                </span>
                {/* <div className="dot-btn">
              <img src={dots} alt="dot-image" />
            </div> */}
              </div>
            ) : props.selectedProcess == 2 &&
              isValidFileName(
                props.file[props.index]?.name ||
                props.file[props.index]?.file?.originalname ||
                props?.singleValue?.file?.filename ||
                props?.file[props.index]?.selectedFile?.path ||
                props?.singleValue?.file?.path ||
                props?.singleValue?.file?.originalname
              ) &&
              props?.singleValue?.price &&
              !props.commanValues.hidePrice &&
              !props.isInvalidFiles ? (
              <div className="pro-price">
                {/* Hiding Bulk pricing for future implementation, the bulk pricing discounts are still applied */}
                {/* <p className="bl-price" onClick={showBulkPricingModal}>See Bulk Pricing</p> */}
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  <span>
                    {props.commanValues.selectedShipMethod == 0
                      ? currencyFormatter(
                        Number(
                          props.singleValue.Qty *
                          Number(props?.singleValue?.price)
                        )
                      )
                      : currencyFormatter(
                        Number(
                          props.singleValue.Qty *
                          Number(props?.singleValue?.price1)
                        )
                      )}
                  </span>
                  <span style={{ fontSize: "12px", color: "#858585" }}>
                    {`${props.commanValues.selectedShipMethod == 0
                      ? currencyFormatter(Number(props?.singleValue?.price))
                      : currencyFormatter(Number(props?.singleValue?.price1))
                      } Each`}
                  </span>
                </span>
                {/* <div className="dot-btn">
            <img src={dots} alt="dot-image" />
          </div> */}
              </div>
            ) : props.selectedProcess == 2 &&
              isValidFileName(
                props.file[props.index]?.name ||
                props.file[props.index]?.file?.originalname ||
                props?.singleValue?.file?.filename ||
                props?.file[props.index]?.selectedFile?.path ||
                props?.singleValue?.file?.path ||
                props?.singleValue?.file?.originalname
              ) &&
              props?.singleValue?.price &&
              !props.commanValues.hidePrice &&
              !props.isInvalidFiles ? (
              <div className="pro-price">
                {/* Hiding Bulk pricing for future implementation, the bulk pricing discounts are still applied */}
                {/* <p className="bl-price" onClick={showBulkPricingModal}>See Bulk Pricing</p> */}
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  <span>
                    Hello
                    {props.commanValues.selectedShipMethod == 0
                      ? currencyFormatter(
                        Number(
                          props.singleValue.Qty *
                          Number(props?.singleValue?.price)
                        )
                      )
                      : currencyFormatter(
                        Number(
                          props.singleValue.Qty *
                          Number(props?.singleValue?.price1)
                        )
                      )}
                  </span>
                  <span style={{ fontSize: "12px", color: "#858585" }}>
                    {`${props.commanValues.selectedShipMethod == 0
                      ? currencyFormatter(Number(props?.singleValue?.price))
                      : currencyFormatter(Number(props?.singleValue?.price1))
                      } Each`}
                  </span>
                </span>
                {/* <div className="dot-btn">
            <img src={dots} alt="dot-image" />
          </div> */}
              </div>
            ) : (
              ""
            )}
            {props.selectedProcess === 1 &&
              isValidFileName(
                props.file[props.index]?.name ||
                props.file[props.index]?.file?.originalname ||
                props?.singleValue?.file?.filename ||
                props?.file[props.index]?.selectedFile?.path ||
                props?.singleValue?.file?.path ||
                props?.singleValue?.file?.originalname
              ) &&
              props?.singleValue?.price &&
              !props.commanValues.hidePrice &&
              !props.isInvalidFiles ? (
              <div className="pro-price">
                {/* Hiding Bulk pricing for future implementation, the bulk pricing discounts are still applied */}
                {/* <p className="bl-price" onClick={showBulkPricingModal}>See Bulk Pricing</p> */}
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  <span>
                    {props.commanValues.selectedShipMethod == 0
                      ? currencyFormatter(
                        Number(
                          props.singleValue.Qty *
                          Number(props?.singleValue?.price)
                        )
                      )
                      : currencyFormatter(
                        Number(
                          props.singleValue.Qty *
                          Number(props?.singleValue?.price1)
                        )
                      )}
                  </span>
                  <span style={{ fontSize: "12px", color: "#858585" }}>
                    {`${props.commanValues.selectedShipMethod == 0
                      ? currencyFormatter(Number(props?.singleValue?.price))
                      : currencyFormatter(Number(props?.singleValue?.price1))
                      } Each`}
                  </span>
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {showBulkPricing && (
        <BulkPricingModal
          showBulkPricing={showBulkPricing}
          setShowBulkPricing={setShowBulkPricing}
          price={Number(props?.singleValue?.algorithmPrice)}
        />
      )}
      <div className="form-box">
        <Row>
          <Col md={7}>
            <div className="form-left">
              <Form>
                {props.selectedProcess === 1 ? (
                  <Row>
                    <Col md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className={"frm-label"}>
                          Material & Type
                          {isValidFileName(props.file[props.index]?.name) ? (
                            <span className="required">*</span>
                          ) : null}
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            handleOptionChange(1, e.target.value);
                          }}
                          className={`${props?.singleValue?.errors?.MaterialType &&
                            isValidFileName(props.file[props.index].name)
                            ? "error-bg"
                            : ""
                            }`}
                          value={props.singleValue.MaterialType}
                          required={true}
                        >
                          <option value="">Select Values</option>
                          {material?.length > 0 &&
                            material?.map((singleMaterial, m) => {
                              return (
                                <option value={singleMaterial._id} key={`m-${m}`}>
                                  {singleMaterial.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                      {props?.singleValue?.errors?.MaterialType &&
                        !props.isInvalidFiles && (
                          <span className="error">
                            Please select Material Type
                          </span>
                        )}
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="frm-label">
                          Sub-Material
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            handleOptionChange(2, e.target.value);
                          }}
                          className={`${props?.singleValue?.errors?.SubMaterial &&
                            isValidFileName(props.file[props.index].name)
                            ? "error-bg"
                            : ""
                            }`}
                          value={props.singleValue.SubMaterial}
                        >
                          <option value="">Select Values</option>
                          {subMaterial.map((singleSubMaterial, index) => {
                            return (
                              <option
                                value={singleSubMaterial._id}
                                key={`f-${index}`}
                              >
                                {singleSubMaterial.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      {props?.singleValue?.errors?.SubMaterial &&
                        !props.isInvalidFiles && (
                          <span className="error">
                            Please select Sub Material Type
                          </span>
                        )}
                    </Col>

                    {props.selectedProcess === 1 && (
                      <Col md={4}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label className="frm-label">
                            Surface Finish
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => {
                              handleOptionChange(3, e.target.value);
                            }}
                            value={props.singleValue.surfaceFinish}
                          >
                            <option value="">Select Values</option>
                            {surfaceFinish?.length > 0 &&
                              surfaceFinish.map((Item, s) => {
                                return (
                                  <option value={Item._id} key={`s-${s}`}>
                                    {Item.name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    )}

                    <Col md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label className="frm-label">
                          Surface Treatment
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            handleOptionChange(4, e.target.value);
                          }}
                          value={props.singleValue?.surfaceTreatment}
                        >
                          <option value="">Select Values</option>
                          {surfaceTreatment?.map((singleTreatment, index) => {
                            return (
                              <option
                                value={singleTreatment._id}
                                key={`f-${index}`}
                              >
                                {singleTreatment.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    {props.selectedProcess === 1 && (
                      <Col md={4}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label className="frm-label">Tolerance</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => {
                              handleOptionChange(5, e.target.value);
                            }}
                            value={props.singleValue.tolerance}
                          >
                            <option value="">Select Values</option>
                            {tolerance.length > 0 &&
                              tolerance.map((Item, t) => {
                                return (
                                  <option value={Item._id} key={`f-${t}`}>
                                    {Item.name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    )}


                    <Col md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        {/* working */}
                        <Form.Label className="frm-label">
                          Part Marking
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            handleOptionChange(7, e.target.value);
                          }}
                          value={props.singleValue.partMarking}
                        >
                          <option value="">Select Values</option>
                          {partmarking?.length > 0 &&
                            partmarking.map((Item, index) => {
                              return (
                                <option key={`p-${index}`} value={Item._id}>
                                  {Item.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label className="frm-label">
                          No. of threads
                        </Form.Label>
                        <Form.Control
                          type="number"
                          onWheel={numberInputOnWheelPreventChange}
                          placeholder="Threads"
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                          }
                          value={props.singleValue.noOfThread}
                          onChange={(e) => {
                            // if (!e.target.value || isNaN(e.target.value)) {
                            //   return;
                            // }
                            handleOptionChange(9, e.target.value);
                          }}
                          min={1}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) :
                  <>

                    <Row>

                      <Col md={4}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          {/* working */}
                          <Form.Label className="frm-label">
                            Technology/Process
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => {
                              handleOptionChange(11, e.target.value);
                            }}
                            value={props?.singleValue?.threeDTechnology}
                          >
                            <option value="">Select Values</option>
                            {technologyData?.length > 0 &&
                              technologyData.map((Item, index) => {
                                return (
                                  <option key={`p-${index}`} value={Item._id}>
                                    {Item.name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>



                      <Col md={4}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          {/* working */}
                          <Form.Label className="frm-label">
                            Material
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => {
                              handleOptionChange(13, e.target.value);
                            }}
                            value={props.singleValue?.threeDMaterial}
                          // disabled={!props?.singleValue?.threeDMachine}
                          >
                            <option value="">Select Values</option>
                            {materialData?.length > 0 &&
                              materialData.map((Item, index) => {
                                return (
                                  <option key={`p-${index}`} value={Item._id}>
                                    {Item.name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col md={4}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label className="frm-label">
                            Surface Finish
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => {
                              handleOptionChange(14, e.target.value);
                            }}
                            value={props.singleValue?.threeDPostProcessing}
                            disabled={!props.singleValue?.threeDMaterial}
                          >
                            <option value="">Select Values</option>
                            {surfaceFinishData?.length > 0 &&
                              surfaceFinishData?.map((Item, index) => {
                                return (
                                  <option key={`p-${index}`} value={Item._id}>
                                    {Item.name + " : "} <span>{Item?.color != 'NA' && Item.color}</span>
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </>}
                <Row>
                  {props.keyValue == 1 ? (
                    <Col md={12}>
                      <div className="apply-check">
                        <input
                          type="checkbox"
                          id="sign-check"
                          name="checkbox"
                          // value={props.commanValues.sameForAllField} //commenting for same for all field
                          // checked={props.commanValues.sameForAllField}
                          value={props.sameForAllField}
                          checked={props.sameForAllField}
                          onChange={(e) => {
                            handleOptionChange(8, e.target.value);
                          }}
                        />
                        <label htmlFor="sign-check"></label>
                        <p>Apply to all parts</p>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Form>
            </div>
          </Col>
          <Col md={5}>
            <div className="form-left">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="frm-label">Description</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={30}
                  placeholder="Please input the part description here (Max 30 Chars)"
                  value={props.singleValue.description}
                  onChange={(e) => {
                    try {
                      props.updateValueOnChange(
                        props.index,
                        "description",
                        e.target.value
                      );
                      props.setIsQuoteChanged(true);
                    } catch (err) {
                      return err;
                    }
                  }}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="frm-label">Notes</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Please input your notes here (Max 30 Chars)"
                  value={props.singleValue.Notes}
                  maxLength={30}
                  onChange={(e) => {
                    try {
                      props.updateValueOnChange(
                        props.index,
                        "Notes",
                        e.target.value
                      );
                      props.setIsQuoteChanged(true);
                    } catch (err) {
                      return err;
                    }
                  }}
                />
              </Form.Group>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default MachinePrintingInput;
