import React, { useEffect, useState, useContext, useReducer } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { processType } from "../../utils/constant";
import Sidebar from "../Sidebar";
import moment from "moment";
import {
  capitalizedStatus,
  currencyFormatter,
  extractURLAndEncodedFilename,
} from "../../utils/helper";
import fileSaver from "file-saver";
import downloadImage from "../../images/download.svg";
import deleteImageEnabled from "../../images/reddelete.svg";
import closeImg from "../../images/close.svg";
import uploadImage from "../../images/upload.svg";
import { config } from "../../environment/development";
import {
  Row,
  Col,
  Accordion,
  Button,
  Modal,
  FormControl,
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import { ChatRefContext } from "../../context/chatRef.context";
import useDocumentTitle from "../../utils/useDocumentTitle";
import whiteLoader from "../../images/white-loader.gif";
import CaptchaModal from "../../components/CaptchaModal/CaptchaModal";
import DataModal from "../../components/DataModal/DataModal";
import { Loading } from "../../components/Loading";
import {
  changeShippingAddressForSO,
  getIssuesForSalesOrder,
  deleteDraftInvoice,
  sendInvoiceToCustomer,
  getDetailsForInvoiceDetailsPage,
  updateInvoiceQualityDoc,
  invoiceConsignMentPictureUpload,
  invoiceDeliveryLinkUpdate,
  invoiceShippingDateUpdate,
  invoiceDeliveryDateUpdate,
  markInvoiceAsPaid,
  approveInvoice,
  downloadDeliveryDocketForInvoice,
  sendInvoiceShippingEmail,
  sendInvoiceDeliveryEmail,
} from "../../utils/actions/adminActions";
import {
  addDlvryCmnt,
  downloadPdf,
  getAllPayments,
  updateShippedDateMail,
  updateDeliveryMail,
  updateOrderAdminComment,
} from "../../utils/actions/allactions";
import {
  getAddressByUserId,
  getInvoicesForSalesOrder,
  handleDownloadCocForInvoice,
  handleDownloadIndividualInvoice,
} from "../../utils/actions/commanActions";
import downloadImageBlue from "../../images/download.png";
import sendImage from "../../images/send.svg";
import ConsignmentPhotoViewer from "../../components/ConsignmentPhotoViewer/ConsignmentPhotoViewer";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";
import { DeleteIcon, DownloadIcon, LucidLoader, SendIcon } from "../../components/Icons";

const InvoiceDetails = () => {
  let navigate = useNavigate();
  useDocumentTitle("Invoice Details");
  let token = localStorage.getItem("Token");
  let { id } = useParams();
  const { selectedChatRefId, setSelectedChatRefId } =
    useContext(ChatRefContext);
  const [handleSendEmail, setHandleSendEmail] = useState(() => null);
  const [isMarkedAsPaid, setIsMarkedAsPaid] = useState(false);
  const [isPreConsignmentPhotoModalOn, setIsPreConsignmentPhotoModalOn] = useState(false);
  const [isPreConsignmentPhotoSend, setIsPreConsignmentPhotoSend] = useState(false);

  const handlePreConsignmentPhotoModalClick = (val) => {
    setIsPreConsignmentPhotoSend(val);
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_CAPTCHA_MODAL,
      payload: true,
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_EMAIL_TYPE_TO_SEND,
      payload: "shipping-email",
    });

  }

  const initialInvoiceDetailState = {
    orderData: {}, //updated
    invoiceData: {}, //updated
    userAllAddress: [],
    orderTransaction: "",
    showUserAddressModal: false,
    isChanged: {
      orderInfo: false,
      qualityDoc: false,
      consignmentPhotos: false,
      deliveryDetails: false,
      isIssueClosed: false,
      adminComment: false,
      chargesChanged: false,
      isPonDocChanged: false,
      isShippingChanged: false,
      isDeliveryChanged: false,
      isInvoiceDataChanged: false,
    },
    shippingEmailSending: false,
    deliveryEmailSending: false,
    confirmEmailSending: false,
    showDDModal: false,
    nameForDeliveryDocket: "",
    buttonTextForCaptcha: "Send Email",
    isLoading: false,
    showOTPModal: false,
    adminOTP: "",
    showCaptchaModal: false,
    emailTypeToSend: "",
    invoiceEmailRecord: { title: "", body: "" },
    showEmailRecord: false,
    deliveryTrackingInput: "",
    showDeliveryComment: false,
    isOrderClosed: false,
    poNumber: "",
    poDocument: "",
    qualityDocuments: [],
    consignmentPhotos: [],
    orderTotal: 0,
    isSendToInvoiceActive: false,
    invoicePartsData: {}, // this state contains the data to present invoices that are
    availableQtyArr: {}, // this state contains the available qty after all invoices.
    showSendInvoiceModal: false,
    emailForSendInvoice: "",
  };

  const ADMIN_INVOICE_DETAILS_ACTIONS = {
    UPDATE_ORDER: "update-order", // updated
    UPDATE_INVOICE_DATA: "update-invoice-data", //updated
    UPDATE_USER_ADDRESS: "update-user-address",
    UPDATE_ORDER_TRANSACTION: "update-order-transaction",
    TOGGLE_CAPTCHA_MODAL: "update-toggle-captcha-modal",
    UPDATE_EMAIL_TYPE_TO_SEND: "update-email-type-to-send",
    UPDATE_BUTTON_TEXT: "update-button-text",
    TOGGLE_SHOW_EMAIL_RECORD: "toggle-show-email-modal",
    TOGGLE_LOADING: "toggle-loading",
    UPDATE_INVOICE_EMAIL_RECORD: "update-email-to-show",
    UPDATE_NAME_FOR_DELIVERY_DOCKET: "update-name-for-delivery-docket",
    TOGGLE_SHOW_DD_MODAL: "toggle-show-dd-modal",
    TOGGLE_CONFIRM_EMAIL_SENDING: "toggle-confirm-email-sending",
    TOGGLE_SHIPPING_EMAIL_SENDING: "toggle-shipping-email-sending",
    TOGGLE_DELIVERY_EMAIL_SENDING: "toggle-delivery-email-sending",
    TOGGLE_SHOW_ADDRESS_MODAL: "toggle-show-address-modal",
    UPDATE_DELIVERY_TRACKING_INPUT: "update-delivery-tracking-input",
    TOGGLE_SHOW_DELIVERY_COMMENT: "toggle-show-delivery-comment",
    TOGGLE_IS_ORDER_CLOSED: "toggle-is-order-closed",
    UPDATE_PO_DOCUMENT: "update-po-document",
    UPDATE_QUALITY_DOCUMENTS: "update-quality-documents",
    UPDATE_CONSIGNMENT_PHOTOS: "update-consignment-photos",
    UPDATE_ORDER_TOTAL: "update-order-total",
    UPDATE_IS_CHANGED: "update-is-changed",
    UPDATE_INVOICES_FOR_ORDER: "update-invoice-for-order",
    UPDATE_AVAILABLE_QTY_ARR: "update-available-qty-arr",
    UPDATE_DRAFT_INVOICE_DATA_TO_SHOW: "update-draft-invoice-data-to-show",
    TOGGLE_SHOW_SEND_INVOICE_MODAL: "toggle-show-send-invoice-modal-id",
    UPDATE_EMAIL_TO_SEND_INVOICE: "update-email-to-send-invoice",
  };

  const invoicerDetailsReducer = (state, action) => {
    switch (action.type) {
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_ORDER:
        return { ...state, orderData: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA:
        return { ...state, invoiceData: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_USER_ADDRESS:
        return { ...state, userAllAddress: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_ORDER_ISSUE_TAB_KEY:
        return { ...state, orderIssueTabKey: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_ORDER_TRANSACTION:
        return { ...state, orderTransaction: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_CAPTCHA_MODAL:
        return { ...state, showCaptchaModal: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_EMAIL_TYPE_TO_SEND:
        return { ...state, emailTypeToSend: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_BUTTON_TEXT:
        return { ...state, buttonTextForCaptcha: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_EMAIL_RECORD:
        return { ...state, showEmailRecord: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING:
        return { ...state, isLoading: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_EMAIL_RECORD:
        return { ...state, invoiceEmailRecord: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_NAME_FOR_DELIVERY_DOCKET:
        return { ...state, nameForDeliveryDocket: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_DD_MODAL:
        return { ...state, showDDModal: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_CONFIRM_EMAIL_SENDING:
        return { ...state, confirmEmailSending: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHIPPING_EMAIL_SENDING:
        return { ...state, shippingEmailSending: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_DELIVERY_EMAIL_SENDING:
        return { ...state, deliveryEmailSending: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_ADDRESS_MODAL:
        return { ...state, showUserAddressModal: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_DELIVERY_TRACKING_INPUT:
        return { ...state, deliveryTrackingInput: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_DELIVERY_COMMENT:
        return { ...state, showDeliveryComment: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_IS_ORDER_CLOSED:
        return { ...state, isOrderClosed: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_PO_DOCUMENT:
        return { ...state, poDocument: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_QUALITY_DOCUMENTS:
        return { ...state, qualityDocuments: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_CONSIGNMENT_PHOTOS:
        return { ...state, consignmentPhotos: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_ORDER_TOTAL:
        return { ...state, orderTotal: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED:
        return { ...state, isChanged: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_AVAILABLE_QTY_ARR:
        return { ...state, availableQtyArr: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_DRAFT_INVOICE_DATA_TO_SHOW:
        return { ...state, draftInvoiceDataToShow: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_SEND_INVOICE_MODAL:
        return { ...state, showSendInvoiceModal: action.payload };
      case ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_EMAIL_TO_SEND_INVOICE:
        return { ...state, emailForSendInvoice: action.payload };
      default:
        return state;
    }
  };

  const [invoiceDetailState, invoiceDetailsDispatch] = useReducer(
    invoicerDetailsReducer,
    initialInvoiceDetailState
  );

  let qualityDocObj = {
    name: "",
    file: [],
  };

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      let decode = jwt_decode(token);
      if (!decode) {
        navigate("/login");
        toast.error("Invalid Token found!");
      } else if (decode) {
        if (decode.userType == "admin") {
          getInvoiceDetails();
        } else if (decode.userType == "user") {
          navigate("/saved-quote");
        } else {
          navigate("/login");
        }
      }
    }
  }, []);

  let selectedHandleSendEmail = "";
  useEffect(() => {
    let buttonText = "";
    if (invoiceDetailState.emailTypeToSend == "shipping-email") {
      selectedHandleSendEmail = () => handleShippingEmailClick(isPreConsignmentPhotoSend);
      buttonText = "Send Shipping Email";
    } else if (invoiceDetailState.emailTypeToSend == "delivery-email") {
      buttonText = "Send Delivery Email";
      selectedHandleSendEmail = handleDeliveryEmailClick;
    } else if (invoiceDetailState.emailTypeToSend == "confirmation-email") {
      selectedHandleSendEmail = handleSendEmailConfirmationClick;
      buttonText = "Send Confirmation Email";
    }
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_BUTTON_TEXT,
      payload: buttonText,
    });
    setHandleSendEmail(() => selectedHandleSendEmail);
  }, [invoiceDetailState.emailTypeToSend, isPreConsignmentPhotoSend]);

  const getInvoiceDetails = async () => {
    try {
      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING,
        payload: true,
      });
      const invoiceResponse = await getDetailsForInvoiceDetailsPage(token, id);
      getSecondaryData(
        invoiceResponse.data?.orderDetails?.userId?._id,
        invoiceResponse.data?.orderDetails._id
      );

      if (invoiceResponse.status) {
        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_ORDER,
          payload: invoiceResponse.data?.orderDetails,
        });
        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
          payload: invoiceResponse.data?.invoiceData,
        });

        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_QUALITY_DOCUMENTS,
          payload: invoiceResponse.data?.invoiceData?.qualityDocuments,
        });

        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_CONSIGNMENT_PHOTOS,
          payload: invoiceResponse.data?.invoiceData?.consignmentPhotos || [],
        });

        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_DELIVERY_TRACKING_INPUT,
          payload: invoiceResponse.data?.invoiceData?.deliveryTrackingLink,
        });

        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_IS_ORDER_CLOSED,
          payload: invoiceResponse.data?.invoiceData?.isCompleted,
        });

        //to show delivery comment box
        // invoiceDetailsDispatch({
        //   type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_DELIVERY_COMMENT,
        //   payload: orderData?.isCompleted,
        // });
      }

      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING,
        payload: false,
      });
    } catch (err) {
      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING,
        payload: false,
      });
      console.log(err);
      toast.error("Something went wrong in getInvoiceDetails");
    }
  };

  const handleClose = () =>
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_ADDRESS_MODAL,
      payload: false,
    });

  const handleNameForDeliveryDocket = (e) => {
    // setNameForDeliveryDocket(e.target.value);
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_NAME_FOR_DELIVERY_DOCKET,
      payload: e.target.value,
    });
  };
  const showDrawer = (id) => {
    setSelectedChatRefId(invoiceDetailState.orderData.RefId);
  };
  const onClose = () => {
    setSelectedChatRefId(null);
  };

  // this useeffect is use to update the records when the admin clicks on the Show Email record button
  useEffect(() => {
    let tableData =
      invoiceDetailState.invoiceData?.invoiceEmailRecord &&
      invoiceDetailState.invoiceData?.invoiceEmailRecord?.map((item, i) => {
        return (
          <tr key={i}>
            <td> {item?.sentToEmail} </td>
            <td> {item?.emailSubject} </td>
            <td>
              {" "}
              {moment(item?.updatedDate).format("DD MMM YYYY hh:mm:ss a")}{" "}
            </td>
          </tr>
        );
      });
    let tableBody = (
      <div style={{ textAlign: "center", padding: "10px" }}>
        <div className="parts-table">
          <table>
            <thead>
              <tr style={{}}>
                <th style={{ fontSize: "12px", padding: "10px" }}>Sent To</th>
                <th style={{ fontSize: "12px", padding: "10px" }}>Subject</th>
                <th style={{ fontSize: "12px", padding: "10px" }}>Date</th>
              </tr>
            </thead>
            <tbody>{tableData}</tbody>
          </table>
        </div>
      </div>
    );

    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_EMAIL_RECORD,
      payload: {
        title: "Email Records",
        body: tableBody,
      },
    });
  }, [invoiceDetailState.invoiceData?.invoiceEmailRecord]);

  const handleAddQualityDoc = () => {
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...invoiceDetailState.isChanged, qualityDoc: true },
    });
    let localQualityDocs = [...invoiceDetailState.qualityDocuments];
    if (invoiceDetailState.qualityDocuments?.length >= 3) {
      alert("You can not add more than 3 quality docs");
      return;
    } else {
      localQualityDocs.push(qualityDocObj);
    }
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_QUALITY_DOCUMENTS,
      payload: localQualityDocs,
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: {
        ...invoiceDetailState.orderData,
        qualityDocuments: localQualityDocs,
      },
    });
  };

  const handleQualityDocRemove = (index) => {
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...invoiceDetailState.isChanged, qualityDoc: true },
    });
    let localQualityDocs = [...invoiceDetailState.qualityDocuments];
    localQualityDocs.splice(index, 1);
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_QUALITY_DOCUMENTS,
      payload: localQualityDocs,
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: {
        ...invoiceDetailState.orderData,
        qualityDocuments: localQualityDocs,
      },
    });
  };

  const handleQualityDocDelete = (index) => {
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...invoiceDetailState.isChanged, qualityDoc: true },
    });
    let localQualityDocs = [...invoiceDetailState.qualityDocuments];
    localQualityDocs[index].file = [];
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_QUALITY_DOCUMENTS,
      payload: localQualityDocs,
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
      payload: {
        ...invoiceDetailState.invoiceData,
        qualityDocuments: localQualityDocs,
      },
    });
  };

  const handleQualityDocNameChange = (event, index) => {
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...invoiceDetailState.isChanged, qualityDoc: true },
    });
    let localQualityDocs = [...invoiceDetailState.qualityDocuments];
    localQualityDocs[index].name = event.target.value;
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_QUALITY_DOCUMENTS,
      payload: localQualityDocs,
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
      payload: {
        ...invoiceDetailState.invoiceData,
        qualityDocuments: localQualityDocs,
      },
    });
  };

  const handleQualityDocUpload = (event, index) => {
    let filesForCheck = Array.from(event.target.files);
    let isAllFilesValid = true;
    filesForCheck.forEach(file => {
      if (file.size > 10485760) {
        isAllFilesValid = false;
      }
    })

    if (!isAllFilesValid) {
      toast.error("File size should be less than 10 MB");
      return;
    }

    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...invoiceDetailState.isChanged, qualityDoc: true },
    });
    let localQualityDocs = [...invoiceDetailState.qualityDocuments];
    localQualityDocs[index].file = event.target.files[0];
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_QUALITY_DOCUMENTS,
      payload: localQualityDocs,
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
      payload: {
        ...invoiceDetailState.invoiceData,
        qualityDocuments: localQualityDocs,
      },
    });
  };

  const handleOrderTotalChange = () => {
    let localTotal = invoiceDetailState.orderData?.quoteId?.partsData.reduce(
      (accumulator, item) => {
        return (
          accumulator +
          Number(item.Qty) *
          Number(
            invoiceDetailState.orderData.quoteSelectedShipMethod === 0
              ? item?.price
              : invoiceDetailState.orderData.quoteSelectedShipMethod === 1
                ? item?.price1
                : item?.price2
          )
        );
      },
      0
    );

    let additionalCost = Number(
      invoiceDetailState.orderData?.additionalCost
        ? invoiceDetailState.orderData?.additionalCost
        : 0
    );
    let shippingCharge = Number(
      invoiceDetailState.orderData?.shippingCharge
        ? invoiceDetailState.orderData?.shippingCharge
        : 0
    );
    let adjustmentValue = Number(
      invoiceDetailState.orderData?.adjustmentValue
        ? invoiceDetailState.orderData?.adjustmentValue
        : 0
    );
    localTotal = Number(localTotal) + additionalCost + shippingCharge;
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: { ...invoiceDetailState.orderData, subTotal: localTotal },
    });
    let tax = invoiceDetailState.orderData?.tax
      ? invoiceDetailState.orderData.tax
      : 0;
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_ORDER_TOTAL,
      payload: Number(
        localTotal + (localTotal * tax) / 100 + adjustmentValue
      ).toFixed(2),
    });
  };

  const getSecondaryData = async (userId, orderId) => {
    try {
      getAddressByUserId(token, userId).then((res) => {
        if (res.status) {
          invoiceDetailsDispatch({
            type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_USER_ADDRESS,
            payload: res.data,
          });
        }
      });

      getIssuesForSalesOrder(token, orderId).then((res) => {
        if (res.status) {
          if (res?.data?.length > 0) {
            invoiceDetailsDispatch({
              type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_ORDER_ISSUE_TAB_KEY,
              payload: res.data?.length - 1,
            });
          }
        }
      });

      getAllPayments(token).then((res) => {
        if (res.status) {
          res?.data?.forEach((item) => {
            if (item.orderId == orderId) {
              invoiceDetailsDispatch({
                type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_ORDER_TRANSACTION,
                payload: item,
              });
            }
          });
        }
      });

      getInvoicesForSalesOrder(token, orderId).then((res) => {
        if (res.status) {
          invoiceDetailsDispatch({
            type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICES_FOR_ORDER,
            payload: res.data,
          });
        }
      });
    } catch (err) {
      console.log("err in secondary data", err);
    }
  };

  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  const handleDlvryDate = async (e) => {
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
      payload: {
        ...invoiceDetailState.invoiceData,
        deliveryDate: e.target.value,
      },
    });

    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...invoiceDetailState.isChanged, deliveryDetails: true },
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...invoiceDetailState.isChanged, deliveryDetails: true, isDeliveryChanged: true },
    });

  };

  const handleShippedDate = async (e) => {
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
      payload: {
        ...invoiceDetailState.invoiceData,
        shippedDate: e.target.value,
      },
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: {
        ...invoiceDetailState.isChanged,
        deliveryDetails: true,
        isShippingChanged: true,
      },
    });
  };

  let handleAddComment = (e) => {
    let data = {
      id: invoiceDetailState.orderData._id,
      comment: invoiceDetailState.orderData.deliveryComment,
    };
    addDlvryCmnt(token, data)
      .then((res) => { })
      .catch((err) => {
        toast.error("Failed to add delivery comment!");
      });
  };

  let handleDownloadInvoice = async () => {
    try {
      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING,
        payload: true,
      });
      const downloadInvoiceResponse = await handleDownloadIndividualInvoice(
        token,
        invoiceDetailState.invoiceData._id
      );
      if (downloadInvoiceResponse.status) {
        // download(invoiceDetailState.invoiceData.invoiceNumber,downloadInvoiceResponse.data);
        if (downloadInvoiceResponse.status) {
          let url = `${config.backEnd}/public/docs/${downloadInvoiceResponse.data}`;
          await download(
            `Invoice-${invoiceDetailState.invoiceData.invoiceNumber}.pdf`,
            url
          );
          invoiceDetailsDispatch({
            type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING,
            payload: false,
          });
        }
      }
    } catch (err) {
      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING,
        payload: false,
      });
      toast.error("Failed to download invoice!");

    }
  };

  const handleFileSelect = (files, rejectedFiles) => {
    let isAllFilesValid = true;
    files.forEach(file => {
      if (file.size > 10485760) {
        isAllFilesValid = false;
      }
    })

    if (!isAllFilesValid) {
      toast.error("You can upload files with a maximum size of 10MB.")
      return;
    }

    let localConsignmentPhoto = [...invoiceDetailState.consignmentPhotos];
    files.map((item) => {
      localConsignmentPhoto.push(item);
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_CONSIGNMENT_PHOTOS,
      payload: [...localConsignmentPhoto],
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
      payload: {
        ...invoiceDetailState.invoiceData,
        consignmentPhotos: [...localConsignmentPhoto],
      },
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...invoiceDetailState.isChanged, consignmentPhotos: true },
    });
  };

  const handleConsignmentFileDelete = (index) => {
    let localConsignmentPhoto = invoiceDetailState.consignmentPhotos;
    localConsignmentPhoto.splice(index, 1);
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_CONSIGNMENT_PHOTOS,
      payload: [...localConsignmentPhoto],
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
      payload: {
        ...invoiceDetailState.invoiceData,
        consignmentPhotos: [...localConsignmentPhoto],
      },
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...invoiceDetailState.isChanged, consignmentPhotos: true },
    });
  };

  const handleBack = () => {
    const userResponse = confirm(
      "Unsaved information will be lost, are you sure you want to go back ?"
    );
    if (userResponse) {
      navigate("/admin/orders");
    }
    return;
  };

  const handleInvoiceQualityDocUpdate = async () => {
    let formData = new FormData();
    for (let i = 0; i < invoiceDetailState.qualityDocuments?.length; i++) {
      if (
        !(
          !invoiceDetailState.qualityDocuments[i].file.filename ||
          !invoiceDetailState.qualityDocuments[i].file.name
        ) ||
        !invoiceDetailState.qualityDocuments[i].name
      ) {
        return false;
      }
      formData.append(
        "fileToUpload",
        invoiceDetailState.qualityDocuments[i].file
      );
    }
    formData.append(
      "qualityDoc",
      JSON.stringify(invoiceDetailState.qualityDocuments)
    );
    formData.append("invoiceId", invoiceDetailState.invoiceData._id);
    const updateOrder = await updateInvoiceQualityDoc(formData, token);
    return updateOrder;
  };

  const handleUpdateConsignmentPhoto = async () => {
    let formData = new FormData();
    formData.append("invoiceId", invoiceDetailState.invoiceData._id);
    formData.append(
      "consignmentPhotoData",
      JSON.stringify(invoiceDetailState.consignmentPhotos)
    );
    for (let i = 0; i < invoiceDetailState.consignmentPhotos?.length; i++) {
      formData.append(
        "consignmentPhoto",
        invoiceDetailState.consignmentPhotos[i]
      );
    }
    await invoiceConsignMentPictureUpload(formData, token);
  };

  const handleRemoveDeliveryLink = () => {
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: { ...invoiceDetailState.isChanged, deliveryDetails: true },
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
      payload: { ...invoiceDetailState.invoiceData, deliveryTrackingLink: "" },
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_DELIVERY_TRACKING_INPUT,
      payload: "",
    });
  };

  const handleShippingAddressChange = async (shippingAddressData) => {
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_ORDER,
      payload: {
        ...invoiceDetailState.orderData,
        shippingAddress: shippingAddressData,
      },
    });
    let data = {
      orderId: invoiceDetailState.orderData._id,
      shippingAddress: shippingAddressData,
    };
    let response = await changeShippingAddressForSO(token, data);
    if (response.status) {
      toast.success(response.message);
    }
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_ADDRESS_MODAL,
      payload: false,
    });
  };

  const handleDeliveryTrackingLinkUpdate = async () => {
    let data = {
      deliveryTrackingLink: invoiceDetailState.deliveryTrackingInput,
      invoiceId: invoiceDetailState.invoiceData._id,
    };

    await invoiceDeliveryLinkUpdate(token, data);
  };

  const handleUpdateDeliveryDate = () => {
    let data = {
      deliveryDate: invoiceDetailState.invoiceData.deliveryDate,
      id: invoiceDetailState.invoiceData._id,
    };
    invoiceDeliveryDateUpdate(token, data)
      .then((res) => {
        if (res.data.status == true) {
        }
      })
      .catch((err) => {
        console.log(
          "error in order admin 601 " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
          err
        );
        toast.error("Failed to update Expected Delivery Date !!");
      });
  };

  //
  const handleUpdateShippedDate = () => {
    let data = {
      shippedDate: invoiceDetailState.invoiceData.shippedDate,
      id: invoiceDetailState.invoiceData._id,
    };
    invoiceShippingDateUpdate(token, data)
      .then((res) => {
        if (res.data.status == true) {
        }
      })
      .catch((err) => {
        console.log(
          "error in order details admin 616 " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
          err
        );
        toast.error("Failed to update Shipped Date !!");
      });
  };

  function isUrlValid(str) {
    var a = document.createElement("a");
    a.href = str;
    return a.host && a.host != window.location.host;
  }

  const handleDeliveryTrackingNumber = async () => {
    try {
      const externalLink = `https://www.dhl.com/ie-en/home/tracking/tracking-express.html?submit=1&tracking-id=${invoiceDetailState.invoiceData.deliveryTrackingLink}`;
      window.open(externalLink, "_blank");
    } catch (err) {
      toast.error("Please enter valid tracking details");
    }
  };

  const handleOrderAdminCommentUpdate = async () => {
    if (invoiceDetailState.isChanged.adminComment) {
      let data = {
        orderId: invoiceDetailState.orderData._id,
        adminComment: invoiceDetailState.orderData.adminComment,
      };
      const response = await updateOrderAdminComment(data);
      if (response.status) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  };

  const handleShippingEmailClick = async (isPreConsignmentPhotoSendCheck) => {
    try {
      if (!invoiceDetailState?.invoiceData?.shippedDate) {
        toast.error(
          "Please update shipped date before sending shipping email."
        );
        return;
      }
      let adminTriggerMailResponse = confirm(
        "Are you sure you want to send shipping email to customer?"
      );
      if (adminTriggerMailResponse) {
        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHIPPING_EMAIL_SENDING,
          payload: true,
        });
        let data = {
          invoiceId: invoiceDetailState.invoiceData._id,
        };
        if (isPreConsignmentPhotoSendCheck) {
          data.preConsignmentPhotos = true;
        };
        let response = await sendInvoiceShippingEmail(token, data);
        if (response.status) {
          invoiceDetailsDispatch({
            type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
            payload: {
              ...invoiceDetailState.invoiceData,
              invoiceEmailRecord: [
                ...response.data.invoiceEmailRecord,
              ],
            },
          });
          toast.success("Shipping email sent successfully.");
        } else {
          toast.error(
            "Something went wrong while sending email. Please check email tracking or retry later."
          );
        }
        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHIPPING_EMAIL_SENDING,
          payload: false,
        });
        setIsPreConsignmentPhotoSend(false);
      }
    } catch (err) {
      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHIPPING_EMAIL_SENDING,
        payload: false,
      });
      console.log('err', err)
      toast.error("Something went wrong");
    }
  };

  const handleDeliveryEmailClick = async () => {
    try {
      if (!invoiceDetailState.invoiceData.deliveryDate) {
        toast.error(
          "Please update delivery date before sending delivery email."
        );
        return;
      }

      let adminTriggerMailResponse = confirm(
        "Are you sure you want to send delivery email to customer?"
      );
      if (adminTriggerMailResponse) {
        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_DELIVERY_EMAIL_SENDING,
          payload: true,
        });
        let data = {
          invoiceId: invoiceDetailState.invoiceData._id,
        };
        let response = await sendInvoiceDeliveryEmail(token, data);
        if (response.status) {
          invoiceDetailsDispatch({
            type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
            payload: {
              ...invoiceDetailState.invoiceData,
              invoiceEmailRecord: [
                ...response.data.invoiceEmailRecord,
              ],
            },
          });
          toast.success("Delivery email sent successfully.");
        } else {
          toast.error(
            "Something went wrong while sending email. Please check email tracking or retry later."
          );
        }
        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_DELIVERY_EMAIL_SENDING,
          payload: false,
        });
      }
    } catch (err) {
      console.log("err", err);
      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_DELIVERY_EMAIL_SENDING,
        payload: false,
      });
      toast.error("Something went wrong");
    }
  };

  const handleInvoiceUpdate = async () => {
    if (
      invoiceDetailState.orderData.status == "cancelled" ||
      invoiceDetailState.orderData.status == "refunded"
    ) {
      toast.error("Order is already cancelled.");
      return;
    }
    if ((isMarkedAsPaid || invoiceDetailState.invoiceData.status == 'paid') && !invoiceDetailState.invoiceData.paymentDate) {
      toast.error("You need to select payment date to mark the order as paid.");
      return;
    }
    if (
      invoiceDetailState.invoiceData.deliveryDate &&
      moment(new Date(invoiceDetailState.invoiceData.deliveryDate)).isBefore(
        moment(new Date(invoiceDetailState.invoiceData?.shippedDate))
      )
    ) {
      toast.error("Delivery date cannot come before shipped date");
      return;
    }

    if (
      invoiceDetailState.invoiceData.shippedDate &&
      moment(invoiceDetailState.invoiceData.shippedDate).startOf('day').isBefore(moment(invoiceDetailState.orderData.createdAt).startOf('day'))
    ) {
      toast.error(
        "Shipped date cannot be before order creation date."
      );
      return;
    }

    if (
      invoiceDetailState.invoiceData.deliveryDate &&
      moment(invoiceDetailState.invoiceData.deliveryDate).startOf('day').isBefore(moment(invoiceDetailState.orderData.createdAt).startOf('day'))
    ) {
      toast.error(
        "Delivery date cannot be before order creation date."
      );
      return;
    }


    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING,
      payload: true,
    });
    if (invoiceDetailState.isChanged.qualityDoc) {
      let response = await handleInvoiceQualityDocUpdate();
      if (response === false || response?.response?.status === 500) {
        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING,
          payload: false,
        });
        toast.error("Please enter valid quality document information");
        return;
      }
    }
    if (
      invoiceDetailState?.consignmentPhotos?.length > 0 ||
      invoiceDetailState.invoiceData?.consignmentPhotos?.length > 0 ||
      invoiceDetailState?.isChanged?.consignmentPhotos === true
    ) {
      await handleUpdateConsignmentPhoto();
    }

    if (invoiceDetailState?.isChanged?.deliveryDetails) {
      await handleDeliveryTrackingLinkUpdate();

      if (invoiceDetailState.isChanged.isShippingChanged) {
        if (!invoiceDetailState.invoiceData.shippedDate) {
          if (
            invoiceDetailState.invoiceData.deliveryDate ||
            invoiceDetailState.invoiceData.isCompleted ||
            invoiceDetailState.isOrderClosed
          ) {
            toast.error("Please enter shipped date");
            return;
          }
        }

        let adminResponse = confirm(
          "Are you sure you want to update the order shipping date?"
        );
        if (adminResponse) {
          await handleUpdateShippedDate();
          invoiceDetailsDispatch({
            type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
            payload: {
              ...invoiceDetailState.isChanged,
              isShippingChanged: false,
            },
          });
        }
      }

      if (invoiceDetailState.isChanged.isDeliveryChanged) {
        if (
          !invoiceDetailState.invoiceData.shippedDate &&
          invoiceDetailState.invoiceData.deliveryDate
        ) {
          alert("Shipping date cannot be empty if delivery date is selected.");
          return;
        }


        let adminResponse = confirm(
          "Are you sure you want to update the invoice delivery date?"
        );
        if (adminResponse) {
          await handleUpdateDeliveryDate();
          invoiceDetailsDispatch({
            type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
            payload: {
              ...invoiceDetailState.isChanged,
              isDeliveryChanged: false,
            },
          });
        }
      }

      if (invoiceDetailState.invoiceData.deliveryComment != "") {
        await handleAddComment();
      }
    }

    if (isMarkedAsPaid || invoiceDetailState.isChanged.isPaymentDateChanged) {
      try {

        let data = {
          invoiceId: invoiceDetailState.invoiceData._id,
          paymentDate: invoiceDetailState.invoiceData.paymentDate,
        }
        const response = await markInvoiceAsPaid(
          token,
          data
        );
        if (response.status) {
          invoiceDetailsDispatch({
            type: UPDATE_INVOICE_DATA,
            payload: response.data,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }

    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
      payload: {
        ...invoiceDetailState.isChanged,
        orderInfo: false,
        qualityDoc: false,
        consignmentPhotos: false,
        deliveryDetails: false,
        isIssueClosed: false,
        adminComment: false,
        chargesChanged: false,
        isShippingChanged: false,
        isDeliveryChanged: false,
        isPonDocChanged: false,
        isPaymentDateChanged: false,
      },
    });
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING,
      payload: false,
    });
    toast.success("Invoice details updated successfully");
  };

  let getFileExtension = (filename) => {
    return filename?.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const handleDownloadCOC = async () => {
    try {
      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING,
        payload: true,
      });
      const response = await handleDownloadCocForInvoice(
        token,
        invoiceDetailState.invoiceData._id
      );

      if (response.status) {
        let url = `${config.backEnd}/public/docs/${response.data}`;
        await download(
          `Certificate_Of_Conformace-${invoiceDetailState.invoiceData.invoiceNumber}.pdf`,
          url
        );
      }
      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING,
        payload: false,
      });
    } catch (err) {
      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING,
        payload: false,
      });
      toast.error("Something went wrong while downloading the pdf file.");
      console.log(
        "err in order admin 952 on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleDownloadDeliveryDocket = async (nameType) => {
    try {
      let nameForDeliveryNote = "";
      if (nameType == "owner-name") {
        nameForDeliveryNote =
          invoiceDetailState.orderData.shippingAddress.firstName +
          " " +
          invoiceDetailState.orderData.shippingAddress.lastName;
      } else {
        nameForDeliveryNote = invoiceDetailState.nameForDeliveryDocket;
      }

      let data = {
        invoiceId: invoiceDetailState.invoiceData._id,
        nameForDeliveryNote,
      };
      const response = await downloadDeliveryDocketForInvoice(token, data);
      if (response.status) {
        let url = `${config.backEnd}/public/docs/${response.data}`;
        download(
          `Delivery_Docket-${invoiceDetailState.invoiceData.invoiceNumber}.pdf`,
          url
        );
      }
    } catch (err) {
      toast.error("Something went wrong while downloading delivery docket.");
      console.log(
        "err in order admin 1071 on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleDeleteInvoice = async () => {
    try {
      let adminResponse = confirm(
        "Are you sure you want to delete this invoice?Once deleted, the invoice cannot be recovered"
      );
      if (!adminResponse) {
        return;
      }
      const response = await deleteDraftInvoice(
        token,
        invoiceDetailState.invoiceData._id
      );
      if (response.status) {
        navigate("/admin/customer-invoices");
      }
      toast.success(response.message);
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };

  const handleApproveInvoice = async () => {
    try {
      if (!invoiceDetailState.invoiceData.shippedDate) {
        toast.error("Please enter shipped date");
        return;
      }


      let adminResponse = confirm(
        "Are you sure you want to approve this invoice?"
      );
      if (!adminResponse) {
        return;
      }
      const response = await approveInvoice(
        token,
        invoiceDetailState.invoiceData._id
      );

      if (response.status) {
        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
          payload: response.data,
        });
      }
      toast.success(response.message);
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };

  const handleShowSendInvoiceModal = () => {
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_SEND_INVOICE_MODAL,
      payload: true,
    });
  };

  const handleMarkInvoiceAsPaid = async () => {
    try {
      setIsMarkedAsPaid(true)
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseShowSendInvoiceModal = () => {
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_SEND_INVOICE_MODAL,
      payload: false,
    });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSendInvoiceToCustomer = async (emailType) => {
    try {
      if (invoiceDetailState?.invoiceData?.isSent) {
        const adminResponse = confirm(
          "Invoice is already sent. Are you sure you want to send the invoice again?"
        );
        if (!adminResponse) {
          return;
        }
      }
      if (
        emailType == "entered-email" &&
        invoiceDetailState.emailForSendInvoice.length === 0
      ) {
        toast.error("Please enter email address");
        return;
      }
      if (
        emailType == "entered-email" &&
        !validateEmail(invoiceDetailState.emailForSendInvoice)
      ) {
        toast.error("Please enter valid email address");
        return;
      }
      //handling loading state
      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING,
        payload: true,
      });
      //closing the modal
      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_SEND_INVOICE_MODAL,
        payload: false,
      });

      let data = {
        invoiceId: invoiceDetailState.invoiceData._id,
        emailType: emailType,
        emailForInvoice: invoiceDetailState.emailForSendInvoice,
        orderId: invoiceDetailState.orderData._id,
        quoteId: invoiceDetailState.orderData?.quoteId._id,
        userId: invoiceDetailState.orderData?.userId._id,
      };

      const response = await sendInvoiceToCustomer(token, data);
      if (response.status) {
        toast.success(response.message);
        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_SEND_INVOICE_MODAL,
          payload: false,
        });
        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
          payload: response.data,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_LOADING,
        payload: false,
      });
    }
  };

  const handleEmailRecordModelClose = () =>
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_EMAIL_RECORD,
      payload: false,
    });

  const handleCloseCaptchaModal = () =>
    invoiceDetailsDispatch({
      type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_CAPTCHA_MODAL,
      payload: false,
    });

  const handlePaymentDateChange = (event) => {
    if (invoiceDetailState.invoiceData?.status == 'paid') {
      let adminResponse = confirm("Invoice is already paid. Are you sure you want to update the payment date?")
      if (adminResponse) {
        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
          payload: {
            ...invoiceDetailState.invoiceData,
            paymentDate: event.target.value,
          },
        })

        invoiceDetailsDispatch({
          type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
          payload: { ...invoiceDetailState.isChanged, isPaymentDateChanged: true },
        });
      }
    } else {
      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_INVOICE_DATA,
        payload: {
          ...invoiceDetailState.invoiceData,
          paymentDate: event.target.value,
        },
      })

      invoiceDetailsDispatch({
        type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
        payload: { ...invoiceDetailState.isChanged, isPaymentDateChanged: true },
      });
    }

  }

  return (
    <div className="admin-container">
      <div style={{ height: "auto" }}>
        {invoiceDetailState.isLoading ? <Loading /> : ""}
      </div>
      <DataModal
        show={invoiceDetailState.showEmailRecord}
        handleClose={handleEmailRecordModelClose}
        data={invoiceDetailState.invoiceEmailRecord}
      />

      <CaptchaModal
        showModal={invoiceDetailState.showCaptchaModal}
        handleClose={handleCloseCaptchaModal}
        handleSendEmail={handleSendEmail}
        buttonText={invoiceDetailState.buttonTextForCaptcha}
      />

      <span style={{ width: "100%" }}>
        <h1 className="center-heading text-2xl font-bold mt-2 ">Invoice Details</h1>
        <div className="right-container">
          <Modal
            size="sm"
            dialogClassName="qoute-version-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={invoiceDetailState.showDDModal}
            onHide={() =>
              invoiceDetailsDispatch({
                type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_DD_MODAL,
                payload: false,
              })
            }
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm" className="font-bold text-xl">
                Download Delivery Note
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <button
                className="req-quote-btn flex items-center justify-center gap-x-2"
                onClick={() => handleDownloadDeliveryDocket("owner-name")}
              >
                <span>Delivery Docket with Owner Name</span>
                <DownloadIcon />
              </button>
              {/* <br /> */}
              <br />
              OR
              <br />
              {/* <br /> */}
              <FormControl
                type="text"
                placeholder="Enter name for delivery docket"
                style={{ maxWidth: "300px" }}
                onChange={handleNameForDeliveryDocket}
                value={invoiceDetailState.nameForDeliveryDocket}
              />
              <button
                className="req-quote-btn flex items-center justify-center gap-x-2"
                onClick={() => {
                  if (invoiceDetailState?.nameForDeliveryDocket?.length == 0) {
                    toast.error("Enter valid name");
                    return;
                  }
                  handleDownloadDeliveryDocket("entered-name");
                }}
              >
                <span>Delivery Docket With Entered Name</span>
                <DownloadIcon />
              </button>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() =>
                  invoiceDetailsDispatch({
                    type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_DD_MODAL,
                    payload: false,
                  })
                }
                className="req-quote-btn"
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>


          <Modal
            size="sm" // Make the modal smaller
            dialogClassName="max-w-[500px] mx-auto"
            centered
            show={isPreConsignmentPhotoModalOn}
            onHide={() => setIsPreConsignmentPhotoModalOn(false)}
          >
            <Modal.Header >
              <Modal.Title className="text-center text-xl w-full">
                Do You Want To Send Pre Consignment Photo With Shipping Email
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              <button
                className="btn bg-primaryColor hover:bg-primaryColor text-white mx-2"
                onClick={() => {
                  setIsPreConsignmentPhotoModalOn(false);
                  handlePreConsignmentPhotoModalClick(true);
                }}
              >
                Yes
              </button>
              <button
                className="btn bg-red-500 hover:bg-red-500 text-white mx-2"
                onClick={() => {
                  setIsPreConsignmentPhotoModalOn(false);
                  handlePreConsignmentPhotoModalClick(false);
                }}
              >
                No
              </button>
            </Modal.Body>
          </Modal>




          <Modal
            size="md"
            dialogClassName=""
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={invoiceDetailState.showUserAddressModal}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '15px' }}>
                User Addresses
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="addresses">
                <div className="cardContainer">
                  {invoiceDetailState.userAllAddress?.map((elem, index) => {
                    return (
                      <div key={index}>
                        <div className="card">
                          <div className="addressContainer">
                            <div className="name">
                              {elem.firstName} {elem?.lastName}
                            </div>
                            <div className="address">
                              {elem?.addressLineOne}
                              <div className="landMark">
                                {" "}
                                {elem?.addressLineTwo}
                              </div>
                              <div className="state">
                                {elem.city} {elem?.state}{" "}
                              </div>
                              <div className="country">
                                {elem?.country} {elem?.postalCode}
                              </div>
                              <div className="mobile">
                                {elem?.phoneNo
                                  ? `Mobile: +${elem.dialCode}-${elem?.phoneNo}`
                                  : ""}
                              </div>
                            </div>
                          </div>
                          <div className="addressButtons">
                            <div>
                              <button
                                className="white-btn"
                                style={{ height: "25px" }}
                                onClick={() =>
                                  handleShippingAddressChange(elem)
                                }
                              >
                                Select
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose} className="req-quote-btn">
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            size="sm"
            dialogClassName="qoute-version-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={invoiceDetailState.showSendInvoiceModal}
            onHide={handleCloseShowSendInvoiceModal}
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm" className="font-bold text-xl">
                Send Invoice to Customer
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {invoiceDetailState.invoiceData?.status === "draft" ? (
                <>
                  <p style={{ color: "red" }}>
                    Note: The status of the invoice will be changed to "sent".
                  </p>
                </>
              ) : (
                <></>
              )}
              <button
                className="bg-primaryColor p-2 flex items-center justify-center text-white gap-x-2 rounded"
                onClick={() => handleSendInvoiceToCustomer("company-email")}
              >
                <div className="flex flex-col">
                  <span>Send To Company Accounts Payable Email </span>
                  <span>({invoiceDetailState?.orderData?.userId?.companySiteId?.companyInvoiceEmail ? invoiceDetailState?.orderData?.userId?.companySiteId?.companyInvoiceEmail : "Company accounts payable email not available"})</span>
                </div>
                <SendIcon />
              </button>

              <br />
              OR
              <br />
              <br />
              <FormControl
                type="text"
                placeholder="Enter email to send invoice."
                style={{ maxWidth: "300px" }}
                onChange={(e) => {
                  invoiceDetailsDispatch({
                    type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_EMAIL_TO_SEND_INVOICE,
                    payload: e.target.value,
                  });
                }}
                value={invoiceDetailState.emailForSendInvoice}
              />
              <button
                className="bg-primaryColor text-white flex items-center justify-center p-2 rounded my-2 gap-x-2"
                onClick={() => handleSendInvoiceToCustomer("entered-email")}
              >
                Send to entered email
                <SendIcon additionalClasses={'h-5'} />
              </button>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={handleCloseShowSendInvoiceModal}
                className="req-quote-btn"
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Chat
            RefId={invoiceDetailState.orderData?.RefId}
            open={selectedChatRefId?.length > 0}
            onDrawerClose={onClose}
          />

          {invoiceDetailState.orderData?.RefId && (
            <div className="order-container">
              <div className="header">
                <Row>
                  <Col>
                    <div className="left-header">
                      <p>Customer Name:</p>
                      <h6>
                        {invoiceDetailState.orderData.firstName +
                          " " +
                          invoiceDetailState.orderData.lastName}{" "}
                        <br />(
                        {invoiceDetailState.orderData?.email?.split("@")[0]})
                      </h6>
                    </div>

                    <div className="left-header">
                      <p>Shipping Address:</p>
                      {invoiceDetailState.orderData?.shippingAddress ? (
                        <>
                          <div className="addressContainer">
                            <div className="name">
                              {
                                invoiceDetailState.orderData?.shippingAddress
                                  .firstName
                              }{" "}
                              {
                                invoiceDetailState.orderData?.shippingAddress
                                  ?.lastName
                              }
                            </div>
                            <div className="address">
                              {
                                invoiceDetailState.orderData?.shippingAddress
                                  ?.addressLineOne
                              }
                              {invoiceDetailState.orderData?.shippingAddress
                                ?.addressLinetwo ? (
                                <div className="landMark">
                                  {
                                    invoiceDetailState.orderData
                                      ?.shippingAddress?.addressLinetwo
                                  }
                                </div>
                              ) : null}

                              <div className="state">
                                {
                                  invoiceDetailState.orderData?.shippingAddress
                                    ?.state
                                }{" "}
                              </div>
                              <div className="postal-code">
                                {
                                  invoiceDetailState.orderData?.shippingAddress
                                    ?.postalCode
                                }
                              </div>
                              <div className="country">
                                {
                                  invoiceDetailState.orderData?.shippingAddress
                                    ?.country
                                }
                              </div>
                              <div className="mobile">
                                {
                                  invoiceDetailState.orderData?.shippingAddress
                                    ?.phoneNo
                                }
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                      <button
                        className="white-btn"
                        onClick={() =>
                          invoiceDetailsDispatch({
                            type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_ADDRESS_MODAL,
                            payload: true,
                          })
                        }
                      >
                        Change Address
                      </button>
                    </div>

                    <div className="left-header">
                      <p>Email:</p>
                      <h6>{invoiceDetailState.orderData?.userId.email}</h6>
                    </div>

                    <div className="left-header">
                      <p>Company Name:</p>
                      <h6>
                        {
                          invoiceDetailState.orderData.userId?.companySiteId
                            ?.companyName
                        }
                      </h6>
                    </div>

                    <div className="left-header">
                      {invoiceDetailState?.invoiceData?.isSent ?
                        <>
                          <p>Sent Date :</p>
                          <h6>{moment(invoiceDetailState.invoiceData?.sentDate).format("DD MMM YYYY")}</h6>
                        </> : <></>}
                    </div>
                  </Col>
                  <Col>
                    <div className="right-header">
                      <p>Order Number:</p>
                      <h6 style={{ display: "inline", marginRight: "10px" }}>
                        #{invoiceDetailState.orderData.RefId}
                      </h6>
                    </div>
                    <div className="right-header">
                      <p>Project Name :</p>
                      <h6 style={{ display: "inline", marginRight: "10px" }}>
                        {invoiceDetailState.orderData?.quoteId?.projectName ||
                          "Not Found"}
                      </h6>
                    </div>

                    <div className="right-header po-file-container">
                      <div style={{ display: "flex" }}>
                        <div className="po-doc-left">
                          <p>PO Number:</p>
                          <div className="attach-file-bx">
                            {invoiceDetailState.orderData.purchaseOrderNumber &&
                              invoiceDetailState.poNumber !== "undefined" ? (
                              <h6>
                                {
                                  invoiceDetailState.orderData
                                    .purchaseOrderNumber
                                }{" "}
                              </h6>
                            ) : (
                              <>
                                <h6>
                                  No PO Number found. Please update in order
                                  details page.
                                </h6>
                              </>
                            )}
                          </div>

                          <p>PO Document : </p>
                          {invoiceDetailState.orderData.poDocumentFile ? (
                            <>
                              <span
                                className="downloadables flex justify-center gap-x-2"
                                onClick={() =>
                                  download(
                                    invoiceDetailState.orderData.poDocumentFile
                                      .originalname,
                                    `${config.backEnd}/` +
                                    invoiceDetailState.orderData
                                      .poDocumentFile.path
                                  )
                                }
                              >
                                {
                                  invoiceDetailState.orderData.poDocumentFile
                                    .originalname
                                }
                                <DownloadIcon />
                              </span>
                              {/* <img
                                  src={deleteImageEnabled}
                                  style={{ cursor: "pointer", height: "25px" }}
                                  alt="delete"
                                  height="25px"
                                  onClick={handlePoDocumentDelete}
                                /> */}
                            </>
                          ) : (
                            <div className="po-fl-btn">
                              {!invoiceDetailState.poDocument ? (
                                <p className="upload-po-text">
                                  Please update PO Document!
                                </p>
                              ) : (
                                <>
                                  <a
                                    className="downloadables"
                                    href={URL.createObjectURL(
                                      invoiceDetailState.poDocument
                                    )}
                                    download={
                                      invoiceDetailState.poDocument.name
                                    }
                                  >
                                    <span>
                                      {invoiceDetailState.poDocument.name}
                                    </span>
                                    <img src={downloadImage} alt="download" />
                                  </a>
                                  {/* <img
                                      src={deleteImageEnabled}
                                      style={{ cursor: "pointer" }}
                                      alt="delete"
                                      height="25px"
                                      onClick={handlePoDocumentDelete}
                                    /> */}
                                </>
                              )}
                              {/* <input
                                type="file"
                                className="po-file-input__input"
                                id="file-input"
                                onChange={(e) => {
                                  // setLocalPoDoc(e.target.files[0]);
                                  invoiceDetailsDispatch({
                                    type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_PO_DOCUMENT,
                                    payload: e.target.files[0],
                                  });
                                  // setIsPonDocChanged(true);
                                  invoiceDetailsDispatch({
                                    type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
                                    payload: {
                                      ...invoiceDetailState.isChanged,
                                      isPonDocChanged: true,
                                    },
                                  });
                                }}
                              />
                              <label
                                className="po-file-input__label"
                                htmlFor="file-input"
                              >
                                 <span>Browse</span> 
                              </label> */}
                            </div>
                          )}
                        </div>
                      </div>

                      <p>Invoice Number  :</p>
                      <h6>{invoiceDetailState?.invoiceData?.invoiceNumber}</h6>
                      <p>Invoice Created At :</p>
                      <h6>
                        {invoiceDetailState.invoiceData &&
                          moment(invoiceDetailState.invoiceData.createdAt).format(
                            "DD MMM YYYY"
                          )}
                      </h6>
                      {console.log(invoiceDetailState.invoiceData)}

                      <p>Invoice Status :</p>
                      <h6>{capitalizedStatus(invoiceDetailState.invoiceData?.status)}</h6>

                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="left-header">
                    <p className="font-medium">Admin Comments :</p>
                    <textarea
                      rows="3"
                      className="p-2"
                      style={{ width: "100%" }}
                      placeholder="Enter comment here"
                      value={invoiceDetailState.orderData?.adminComment}
                      onChange={(e) => {
                        invoiceDetailsDispatch({
                          type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
                          payload: {
                            ...invoiceDetailState.isChanged,
                            adminComment: true,
                          },
                        });
                        invoiceDetailsDispatch({
                          type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_ORDER,
                          payload: {
                            ...invoiceDetailState.orderData,
                            adminComment: e.target.value,
                          },
                        });
                      }}
                    ></textarea>
                    <button
                      className="update-button"
                      style={{ marginBottom: "30px" }}
                      onClick={handleOrderAdminCommentUpdate}
                    >
                      Update Comment
                    </button>
                    <button
                      className="update-button"
                      style={{ margin: "0px 0px 30px 30px" }}
                      onClick={() =>
                        navigate(
                          `/admin/admin-quote/${invoiceDetailState.orderData.quoteId._id}`
                        )
                      }
                    >
                      Go To Quotation
                    </button>
                    <button
                      className="update-button"
                      style={{ margin: "0px 0px 30px 30px" }}
                      onClick={() =>
                        navigate(
                          `/admin/orders/${invoiceDetailState.orderData._id}`
                        )
                      }
                    >
                      Go To SO
                    </button>
                  </div>
                </Row>
              </div>

              <Accordion>
                <div className="grid-div or-details-row">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="req-box">
                        <h6>Order Information</h6>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      {invoiceDetailState.orderData.quoteId ? (
                        <div className="table-container show-accordiion">
                          <table>
                            <>
                              <thead>
                                <tr>
                                  <th>File Name</th>
                                  <th>Part Description</th>
                                  <th>Notes</th>
                                  <th>Invoiced Qty</th>
                                  <th>Price</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoiceDetailState.orderData.quoteId.partsData
                                  ?.length > 0 &&
                                  invoiceDetailState.orderData.quoteId.partsData.map(
                                    (val, i) => {
                                      return (
                                        <tr key={i}>
                                          <td
                                            style={{
                                              textAlign: "left",
                                              wordWrap: "break-word",
                                              wordBreak: "break-all",
                                              whiteSpace: "normal",
                                              maxWidth: "150px",
                                            }}
                                          >
                                            {val.file || val?.selectedFile ? (
                                              <span
                                                className="downloadables flex gap-x-2 items-center "
                                                onClick={(e) => {
                                                  let extension =
                                                    getFileExtension(
                                                      val.file.originalname
                                                    );
                                                  let url = `${config.backEnd}/public/uploads/${val._id}.${extension}`;
                                                  download(
                                                    val.file.originalname,
                                                    url
                                                  );
                                                }}
                                              >
                                                <span>
                                                  {val.file
                                                    ? val.file.originalname
                                                    : val.selectedFile
                                                      ? val.selectedFile?.path
                                                      : val.linePartName || "No file uploaded"}
                                                </span>
                                                <DownloadIcon />
                                              </span>
                                            ) : (
                                              <span>{val.linePartName || "No file uploaded"}</span>
                                            )}
                                          </td>

                                          <td
                                            style={{
                                              textAlign: "left",
                                              wordWrap: "break-word",
                                              wordBreak: "break-all",
                                              whiteSpace: "normal",
                                              width: "auto",
                                            }}
                                          >
                                            {val?.description
                                              ? val?.description
                                              : ""}
                                            {val?.MaterialType ? (
                                              <div>
                                                {val?.MaterialType?.name}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {val?.SubMaterial ? (
                                              <div>{val?.SubMaterial.name}</div>
                                            ) : (
                                              ""
                                            )}
                                            {val?.surfaceFinish ? (
                                              <div>
                                                {val?.surfaceFinish.name}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {val?.surfaceTreatment ? (
                                              <div>
                                                {val?.surfaceTreatment.name}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {val?.tolerance ? (
                                              <div>{val?.tolerance.name}</div>
                                            ) : (
                                              ""
                                            )}
                                            {val?.partMarking ? (
                                              <div>{val?.partMarking.name}</div>
                                            ) : (
                                              ""
                                            )}
                                            {val?.noOfThread ? (
                                              <div>{val?.noOfThread}</div>
                                            ) : (
                                              ""
                                            )}
                                          </td>

                                          <td>{val?.Notes}</td>
                                          <td style={{ textAlign: "center" }}>
                                            {invoiceDetailState?.invoiceData?.partsData.filter(
                                              (item) =>
                                                item.partItemId == val?._id
                                            )[0]?.shippedQty || 0}
                                          </td>

                                          <td style={{ textAlign: "center" }}>
                                            {currencyFormatter(invoiceDetailState.orderData
                                              .quoteSelectedShipMethod === 0
                                              ? val?.price
                                              : invoiceDetailState.orderData
                                                .quoteSelectedShipMethod === 1
                                                ? val?.price1
                                                : val?.price2)}
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            <p className="amt">
                                              {currencyFormatter(
                                                (invoiceDetailState.orderData
                                                  .quoteSelectedShipMethod === 0
                                                  ? val?.price
                                                  : invoiceDetailState.orderData
                                                    .quoteSelectedShipMethod ===
                                                    1
                                                    ? val?.price1
                                                    : val?.price2) *
                                                invoiceDetailState?.invoiceData?.partsData.filter(
                                                  (item) =>
                                                    item.partItemId ==
                                                    val?._id
                                                )[0]?.shippedQty || 0
                                              )}
                                            </p>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}

                                <tr>
                                  <td> </td>
                                  <td>QR Tracking</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td style={{ textAlign: "center" }}>
                                    {
                                      currencyFormatter(invoiceDetailState?.invoiceData?.invoiceAdditionalCost)
                                    }
                                  </td>
                                </tr>

                                <tr>
                                  <td></td>
                                  <td>Shipping & Duties</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td style={{ textAlign: "center" }}>
                                    {
                                      currencyFormatter(invoiceDetailState?.invoiceData?.invoiceShippingCharge)
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>Adjustments</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td style={{ textAlign: "center" }}>
                                    {
                                      currencyFormatter(invoiceDetailState?.invoiceData?.invoiceAdjustmentValue)

                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          </table>

                          {invoiceDetailState.orderData.quoteId.partsData
                            ?.length > 0 ? (
                            <div
                              className="w-full  "
                            >
                              <h6 className="text-start text-bold my-2">Documentation required : </h6>
                              <div
                                className="flex justify-between gap-x-2 items-center w-full flex-wrap"
                              >
                                <div

                                >
                                  <input
                                    type="checkbox"
                                    id="sign-check-cc"
                                    name="checkbox"
                                    value={
                                      invoiceDetailState.orderData.quoteId
                                        .isConformanceCertificateRequested
                                    }
                                    checked={
                                      invoiceDetailState.orderData.quoteId
                                        .isConformanceCertificateRequested
                                    }
                                    disabled
                                  />
                                  <label
                                    htmlFor="sign-check-cc"
                                    style={{ fontSize: "12px" }}
                                  >
                                    Certificate of conformance
                                  </label>
                                </div>

                                <div

                                >
                                  <input
                                    type="checkbox"
                                    id="sign-check-dr"
                                    name="checkbox"
                                    value={
                                      invoiceDetailState.orderData.quoteId
                                        .isDimensionalReportRequested
                                    }
                                    checked={
                                      invoiceDetailState.orderData.quoteId
                                        .isDimensionalReportRequested
                                    }
                                    disabled
                                  />
                                  <label
                                    htmlFor="sign-check-dr"
                                    style={{ fontSize: "12px" }}
                                  >
                                    Dimensional Inspection Report
                                  </label>
                                </div>

                                <div

                                >
                                  <input
                                    type="checkbox"
                                    id="sign-check-qr"
                                    name="checkbox"
                                    value={
                                      invoiceDetailState.orderData.quoteId
                                        .isQrTrackingRequested
                                    }
                                    checked={
                                      invoiceDetailState.orderData.quoteId
                                        .isQrTrackingRequested
                                    }
                                    disabled
                                  />
                                  <label
                                    htmlFor="sign-check-qr"
                                    style={{ fontSize: "12px" }}
                                  >
                                    QR Tracking
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="flex justify-end w-full my-2 gap-x-2 ">
                            <div className="total-amount-order ">
                              <h6 className="order-price font-bold">
                                Tax({invoiceDetailState.orderData.tax}%) :
                                {currencyFormatter(
                                  Number(
                                    (((invoiceDetailState.invoiceData
                                      ?.invoiceTotalAmount -
                                      invoiceDetailState?.invoiceData
                                        ?.invoiceAdjustmentValue) /
                                      (1 +
                                        invoiceDetailState.orderData.tax / 100)) *
                                      invoiceDetailState.orderData.tax) /
                                    100
                                  ).toFixed(2)
                                )}
                              </h6>
                            </div>
                            <div className="total-amount-order ">
                              <h6 className="order-price font-bold">
                                Sub Total Amount :
                                {currencyFormatter(
                                  Number(
                                    invoiceDetailState?.invoiceData
                                      ?.invoiceTotalAmount
                                  ) - Number(
                                    (((invoiceDetailState.invoiceData
                                      ?.invoiceTotalAmount -
                                      invoiceDetailState?.invoiceData
                                        ?.invoiceAdjustmentValue) /
                                      (1 +
                                        invoiceDetailState.orderData.tax / 100)) *
                                      invoiceDetailState.orderData.tax) /
                                    100
                                  )
                                )}
                              </h6>
                            </div>
                            <div className="total-amount-order ">
                              <h6 className="order-price font-bold">
                                Total Amount :
                                {currencyFormatter(
                                  Number(
                                    invoiceDetailState?.invoiceData
                                      ?.invoiceTotalAmount
                                  )
                                )}
                              </h6>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </Accordion.Body>
                  </Accordion.Item>
                </div>

                <div className="grid-div  or-details-row ">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div className="req-box">
                        <h6>Upload Quality Documents</h6>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="qr-code-div show-accordiion">
                        {invoiceDetailState.orderData?.quoteId?.partsData
                          ?.length > 0 ? (
                          <div style={{ marginTop: "10px", textAlign: "left" }}>
                            <h6>Documentation required : </h6>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "20px",
                              }}
                            >
                              <div
                                className="apply-check"
                                style={{ width: "33%" }}
                              >
                                <input
                                  type="checkbox"
                                  id="sign-check-cc"
                                  name="checkbox"
                                  value={
                                    invoiceDetailState.orderData.quoteId
                                      .isConformanceCertificateRequested
                                  }
                                  checked={
                                    invoiceDetailState.orderData.quoteId
                                      .isConformanceCertificateRequested
                                  }
                                  disabled
                                />
                                <label
                                  htmlFor="sign-check-cc"
                                  style={{ fontSize: "15px" }}
                                >
                                  Certificate of conformance
                                </label>
                              </div>

                              <div
                                className="apply-check"
                                style={{ width: "33%" }}
                              >
                                <input
                                  type="checkbox"
                                  id="sign-check-dr"
                                  name="checkbox"
                                  value={
                                    invoiceDetailState.orderData.quoteId
                                      .isDimensionalReportRequested
                                  }
                                  checked={
                                    invoiceDetailState.orderData.quoteId
                                      .isDimensionalReportRequested
                                  }
                                  disabled
                                />
                                <label
                                  htmlFor="sign-check-dr"
                                  style={{ fontSize: "15px" }}
                                >
                                  Dimensional Inspection Report
                                </label>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <span>
                          Select quality document as per the Customer
                          requirement :{" "}
                        </span>
                        <button
                          className="white-btn"
                          style={{
                            border: "1px solid black",
                            marginLeft: "30px",
                          }}
                          onClick={handleAddQualityDoc}
                        >
                          Add quality document
                        </button>
                        {invoiceDetailState?.qualityDocuments?.length > 0
                          ? invoiceDetailState.qualityDocuments.map(
                            (item, i) => {
                              return (
                                <div key={i} className="quality-doc-div">
                                  <div className="child-item">
                                    <span>Document Name : </span>
                                    <input
                                      type="text"
                                      value={item?.name}
                                      onChange={(e) =>
                                        handleQualityDocNameChange(e, i)
                                      }
                                    />
                                  </div>
                                  {!(
                                    invoiceDetailState.qualityDocuments[i]
                                      .file.filename ||
                                    invoiceDetailState.qualityDocuments[i]
                                      .file.name
                                  ) ? (
                                    <div
                                      className="child-item flex items-center "
                                    // style={{
                                    //   display: "flex",
                                    //   justifyContent: "flex-start",
                                    //   alignItems: "flex-end",
                                    //   minWidth: "150px",
                                    // }}

                                    >
                                      <label
                                        className="upload-doc-btn"
                                        htmlFor={`upload${i}`}
                                      >
                                        Upload File
                                      </label>
                                      <input
                                        id={`upload${i}`}
                                        className="quality-doc-item-input"
                                        type="file"
                                        onChange={(e) =>
                                          handleQualityDocUpload(e, i)
                                        }
                                      // accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                      />
                                      <img
                                        src={closeImg}
                                        className="h-3 ms-2"
                                        onClick={() =>
                                          handleQualityDocRemove(i)
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="child-item"
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      <div
                                        className="downloadables"
                                      // style={{
                                      //   marginRight: "40px",
                                      //   marginTop: "5px",
                                      // }}
                                      >
                                        {invoiceDetailState.qualityDocuments[
                                          i
                                        ].file.filename ? (
                                          <div className="flex items-center justify-center gpa-x-2 text-sm">
                                            <a
                                              className="downloadables flex items-center justify-center gap-x-2"
                                              href={
                                                invoiceDetailState
                                                  .qualityDocuments[i].file
                                                  .name
                                                  ? URL.createObjectURL(
                                                    invoiceDetailState
                                                      .qualityDocuments[i]
                                                      .file
                                                  )
                                                  : (invoiceDetailState
                                                    .qualityDocuments[i]
                                                    .file.originalname,
                                                    `${config.backEnd}/${item.file.path}`)
                                              }
                                              download={
                                                invoiceDetailState
                                                  .qualityDocuments[i].file
                                                  .filename
                                              }
                                            >

                                              {
                                                invoiceDetailState
                                                  .qualityDocuments[i].file
                                                  .originalname
                                              }
                                              <DownloadIcon />
                                            </a>

                                            <DeleteIcon />
                                            <img
                                              src={closeImg}
                                              style={{
                                                marginLeft: "20px",
                                                height: "15px",
                                              }}
                                              onClick={() =>
                                                handleQualityDocRemove(i)
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <div className="flex items-center gap-x-2" >
                                            <a
                                              className="downloadables"
                                              href={
                                                invoiceDetailState
                                                  .qualityDocuments[i].file
                                                  .name
                                                  ? URL.createObjectURL(
                                                    invoiceDetailState
                                                      .qualityDocuments[i]
                                                      .file
                                                  )
                                                  : (invoiceDetailState
                                                    .qualityDocuments[i]
                                                    .file.originalname,
                                                    `${config.backEnd}/${item.file.path}`)
                                              }
                                              download={
                                                invoiceDetailState
                                                  .qualityDocuments[i].file
                                                  .filename
                                              }
                                            >
                                              {
                                                invoiceDetailState
                                                  .qualityDocuments[i].file
                                                  .name
                                              }

                                            </a>
                                            <a
                                              href={
                                                invoiceDetailState
                                                  .qualityDocuments[i].file
                                                  .name
                                                  ? URL.createObjectURL(
                                                    invoiceDetailState
                                                      .qualityDocuments[i]
                                                      .file
                                                  )
                                                  : (invoiceDetailState
                                                    .qualityDocuments[i]
                                                    .file.originalname,
                                                    `${config.backEnd}/${item.file.path}`)
                                              }
                                              download={
                                                invoiceDetailState
                                                  .qualityDocuments[i].file
                                                  .filename
                                              }
                                            >
                                              <DownloadIcon />
                                            </a>
                                            <div
                                              onClick={() =>
                                                handleQualityDocDelete(i)
                                              }>
                                              <DeleteIcon additionalClasses={'text-red-500'} />
                                            </div>
                                            <img
                                              src={closeImg}
                                              style={{
                                                marginLeft: "20px",
                                                height: "15px",
                                              }}
                                              onClick={() =>
                                                handleQualityDocRemove(i)
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )
                          : null}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className="grid-div  or-details-row">
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <div className="req-box">
                        <h6>Upload Pre-delivery Consignment Photo</h6>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="po-upload-doc-div show-accordiion">
                        <div className="upload-req po-dropzone">
                          <Dropzone
                            onDrop={handleFileSelect}
                            minSize={1024}
                            accept={{
                              "image/*": [],
                            }}
                            reject="*.js"
                            maxSize={10 * 1024 * 1024}
                            multiple={true}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps({
                                  className:
                                    " upload-box step-to-upload dropzone",
                                })}
                              >
                                <span className="up-icon">
                                  {" "}
                                  <img src={uploadImage} />{" "}
                                </span>

                                <div className="up-text">
                                  <span className="inp-txt">
                                    Drop files here to upload or
                                  </span>
                                  <div className="file-button">
                                    <input {...getInputProps()} />
                                    <span
                                      style={{
                                        color: "#2B96DC",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      Browse
                                    </span>
                                  </div>
                                </div>
                                <p>
                                  {invoiceDetailState?.consignmentPhotos
                                    ? invoiceDetailState?.consignmentPhotos
                                      ?.length
                                    : "0"}{" "}
                                  Files Selected!
                                </p>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        <div className="po-consignment-pic-right">
                          {invoiceDetailState?.consignmentPhotos &&
                            invoiceDetailState?.consignmentPhotos?.length > 0 ? (
                            <>
                              <h6
                                style={{
                                  textDecoration: "underline",
                                  marginBottom: "10px",
                                }}
                              >
                                Files Uploaded
                              </h6>
                              <div className="file-grid">
                                <ConsignmentPhotoViewer images={invoiceDetailState?.consignmentPhotos} altText={'consignment-photo'} handleConsignmentFileDelete={handleConsignmentFileDelete} serverType="customer" />

                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </div>

                <div className="grid-div  or-details-row">
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <div className="req-box">
                        <h6>Delivery Tracking </h6>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="delivery-div show-accordiion">
                        <div className="delivery-tracking-div">
                          <div>
                            <p>Delivery tracking Information:</p>
                          </div>
                          <div>
                            {invoiceDetailState.invoiceData
                              ?.deliveryTrackingLink ? (
                              <>
                                {isUrlValid(
                                  invoiceDetailState.invoiceData
                                    ?.deliveryTrackingLink
                                ) ? (
                                  <>
                                    <a
                                      href={
                                        invoiceDetailState.invoiceData
                                          ?.deliveryTrackingLink
                                      }
                                      target="_blank"
                                    >
                                      {
                                        invoiceDetailState.invoiceData
                                          ?.deliveryTrackingLink
                                      }{" "}
                                    </a>
                                    <div onClick={handleRemoveDeliveryLink} className="border ">
                                      <DeleteIcon additionalClasses={'text-red-500 h-5'} />
                                    </div>
                                  </>
                                ) : !isNaN(
                                  invoiceDetailState.invoiceData
                                    ?.deliveryTrackingLink
                                ) ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span className="flex items-center justify-center gap-x-2">
                                        <span className="">
                                          {invoiceDetailState.invoiceData
                                            ?.deliveryTrackingLink + "  "}
                                        </span>
                                        <div onClick={handleRemoveDeliveryLink}>
                                          <DeleteIcon additionalClasses={'h-4 text-red-500'} />
                                        </div>
                                      </span>
                                      <button
                                        className="update-button"
                                        onClick={handleDeliveryTrackingNumber}
                                      >
                                        Track
                                      </button>
                                    </div>
                                  </>
                                ) : invoiceDetailState.invoiceData
                                  ?.deliveryTrackingLink ? (
                                  <div className="flex items-center justify-start gap-x-2">
                                    <span >
                                      {invoiceDetailState.invoiceData
                                        ?.deliveryTrackingLink + "  "}
                                    </span>
                                    <div onClick={handleRemoveDeliveryLink}>
                                      <DeleteIcon additionalClasses={'h-4 text-red-500'} />
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <>
                                <input
                                  type="text"
                                  className="input-delivery border p-1"
                                  placeholder="Enter delivery tracking information"
                                  style={{ minWidth: "250px" }}
                                  onChange={(e) => {
                                    invoiceDetailsDispatch({
                                      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_DELIVERY_TRACKING_INPUT,
                                      payload: e.target.value,
                                    });
                                    invoiceDetailsDispatch({
                                      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
                                      payload: {
                                        ...invoiceDetailState.isChanged,
                                        deliveryDetails: true,
                                      },
                                    });
                                  }}
                                />
                              </>
                            )}
                            <br />
                            <br />
                            <p
                              style={{
                                fontSize: "12px",
                                color: "red",
                                width: "250px",
                              }}
                            >
                              *If DHL number is provided, the API will retrieve
                              the data. Otherwise, please enter the delivery
                              tracking link for other carriers.
                            </p>
                          </div>
                        </div>

                        <div className="delivery-date-div">
                          <div>
                            <p>Shipped date:</p>
                          </div>

                          <div className="sub-btn">
                            <FormControl
                              type="date"
                              className="attach-name"
                              value={
                                invoiceDetailState.invoiceData?.shippedDate
                                  ? new Date(
                                    invoiceDetailState.invoiceData?.shippedDate
                                  )
                                    .toISOString()
                                    .slice(0, 10)
                                  : ""
                              }
                              onChange={handleShippedDate}
                            />
                          </div>
                          {!invoiceDetailState.invoiceData?.isCompleted ? (
                            <>
                              {invoiceDetailState.shippingEmailSending ? (
                                <>
                                  <button
                                    className="update-button flex items-center justify-center"
                                    style={{ width: "100%", marginTop: "10px" }}
                                  >
                                    <LucidLoader additionalClass={'h-5'} />
                                  </button>
                                </>
                              ) : (
                                <>
                                  <div style={{ marginTop: "10px" }}>
                                    <button
                                      style={{ width: "100%" }}
                                      className="update-button"
                                      onClick={() => {
                                        if (
                                          !invoiceDetailState.invoiceData
                                            ?.shippedDate
                                        ) {
                                          toast.error("Enter shipping date");
                                          return;
                                        }
                                        if (
                                          invoiceDetailState?.isChanged
                                            ?.isShippingChanged
                                        ) {
                                          toast.error(
                                            "Please update order before sending shipping email."
                                          );
                                          return;
                                        }
                                        if (invoiceDetailState?.consignmentPhotos?.length != 0) {
                                          setIsPreConsignmentPhotoModalOn(true);
                                          return;
                                        }
                                        invoiceDetailsDispatch({
                                          type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_CAPTCHA_MODAL,
                                          payload: true,
                                        });
                                        invoiceDetailsDispatch({
                                          type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_EMAIL_TYPE_TO_SEND,
                                          payload: "shipping-email",
                                        });
                                      }}
                                    >
                                      Send Shipping Email
                                    </button>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="delivery-date-div">
                          <div>
                            <p>Delivered date:</p>
                          </div>

                          <div className="sub-btn">
                            <FormControl
                              type="date"
                              className="attach-name"
                              value={
                                invoiceDetailState.invoiceData?.deliveryDate
                                  ? new Date(
                                    invoiceDetailState.invoiceData?.deliveryDate
                                  )
                                    .toISOString()
                                    .slice(0, 10)
                                  : ""
                              }
                              onChange={handleDlvryDate}
                            />
                          </div>
                          {!invoiceDetailState.orderData.isCompleted ? (
                            <>
                              {invoiceDetailState.deliveryEmailSending ? (
                                <>
                                  <button
                                    className="update-button w-full flex items-center justify-center my-2"

                                  >
                                    <LucidLoader additionalClass={'h-5'} />
                                  </button>
                                </>
                              ) : (
                                <>
                                  <div style={{ marginTop: "10px" }}>
                                    <button
                                      className="update-button"
                                      onClick={() => {
                                        if (
                                          !invoiceDetailState.invoiceData?.deliveryDate
                                        ) {
                                          toast.error("Enter delivery date");
                                          return;
                                        }
                                        if (
                                          invoiceDetailState?.isChanged
                                            ?.isDeliveryChanged
                                        ) {
                                          toast.error(
                                            "Please update order before sending delivery email."
                                          );
                                          return;
                                        }
                                        // setShowCaptchaModal(true);
                                        invoiceDetailsDispatch({
                                          type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_CAPTCHA_MODAL,
                                          payload: true,
                                        });
                                        // setEmailTypeToSend("delivery-email");
                                        invoiceDetailsDispatch({
                                          type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_EMAIL_TYPE_TO_SEND,
                                          payload: "delivery-email",
                                        });
                                      }}
                                    >
                                      Send Delivery Email
                                    </button>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>

                        <div className="delivery-complete-div">
                          <div>
                            <p className="flex items-center">
                              <p>Invoice Status :</p>
                              <h6>{capitalizedStatus(invoiceDetailState.invoiceData?.status)}</h6>
                            </p>

                            {isMarkedAsPaid || invoiceDetailState.invoiceData.status == "paid" ?
                              <>
                                <div className="left-header">
                                  <p>Payment Date:</p>
                                  <FormControl
                                    type="date"
                                    onChange={handlePaymentDateChange}
                                    value={
                                      invoiceDetailState.invoiceData?.paymentDate
                                        ? new Date(
                                          invoiceDetailState.invoiceData?.paymentDate
                                        )
                                          .toISOString()
                                          .slice(0, 10)
                                        : ""
                                    }
                                  />
                                </div>
                              </> : <>
                                <button
                                  className="update-button"
                                  onClick={handleMarkInvoiceAsPaid}
                                >
                                  Mark As Paid
                                </button>
                              </>}

                          </div>
                          {/* {invoiceDetailState.orderData.isCompleted == true ||
                            invoiceDetailState.showDeliveryComment == true ? (
                            <>
                              <div className="message-box">
                                <textarea
                                  className="mesg-box"
                                  maxLength={280}
                                  placeholder="Enter Delivery comment"
                                  value={
                                    invoiceDetailState.orderData.deliveryComment
                                  }
                                  onChange={(e) => {
                                    invoiceDetailsDispatch({
                                      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_ORDER,
                                      payload: {
                                        ...invoiceDetailState.orderData,
                                        deliveryComment: e.target.value,
                                      },
                                    });
                                    invoiceDetailsDispatch({
                                      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_IS_CHANGED,
                                      payload: {
                                        ...invoiceDetailState.isChanged,
                                        deliveryDetails: true,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="message-btns">
                                <button
                                  className="update-button"
                                  onClick={() => {
                                    if (
                                      !invoiceDetailState.orderData
                                        .expectedDelivery
                                    ) {
                                      toast.error(
                                        "Please enter delivery date before closing the order."
                                      );
                                      return;
                                    }
                                    // setShowCaptchaModal(true);
                                    invoiceDetailsDispatch({
                                      type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_CAPTCHA_MODAL,
                                      payload: true,
                                    });
                                    // setEmailTypeToSend("mark-complete");
                                    invoiceDetailsDispatch({
                                      type: ADMIN_INVOICE_DETAILS_ACTIONS.UPDATE_EMAIL_TYPE_TO_SEND,
                                      payload: "mark-complete",
                                    });
                                  }}
                                >
                                  Mark Completed
                                </button>
                              </div>
                            </>
                          )} */}
                          {/* {invoiceDetailState.invoiceData?.status == 'paid' ? <>
                            <div>
                              <p>
                                <p>Payment Date :</p>
                                <h6>{moment(invoiceDetailState.invoiceData?.paymentDate).format("DD MMM YYYY")}</h6>
                              </p>
                            </div></> : <></>} */}
                        </div>
                      </div>
                      <br />
                      {invoiceDetailState.orderData.expectedDelivery && (
                        <>
                          <p>FeedBack : </p>
                          <div className="delivery-div">
                            <div className="delivery-date-div">
                              <div>
                                <p>Quality Rating : </p>
                              </div>
                              <div>
                                {invoiceDetailState.orderData?.qualityRating} /
                                5
                              </div>
                            </div>
                            <div className="delivery-date-div">
                              <div>
                                <p>Value for Money Rating : </p>
                              </div>
                              <div>
                                {
                                  invoiceDetailState.orderData
                                    ?.valueForMoneyRating
                                }{" "}
                                / 5
                              </div>
                            </div>
                            <div className="delivery-date-div">
                              <div>
                                <p>Over All Rating : </p>
                              </div>
                              <div>
                                {invoiceDetailState.orderData?.overAllRating} /
                                5
                              </div>
                            </div>
                            <div className="delivery-date-div">
                              <div>
                                <p>Comment / Suggestions</p>
                              </div>
                              <textarea
                                value={
                                  invoiceDetailState.orderData?.feedBackComment
                                }
                                disabled
                              >
                                {" "}
                              </textarea>
                            </div>
                          </div>
                        </>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
              </Accordion>
            </div>
          )}
          <div className="order-stage-div">
            <div className="flex flex-wrap gap-2">
              <button className="px-2 w-max bg-primaryColor text-white rounded h-10 flex justify-center items-center  mx-2" onClick={handleInvoiceUpdate}>
                Update Invoice
              </button>
              {invoiceDetailState.invoiceData?.status == "draft" ? (
                <>
                  <button
                    className="px-2 w-max bg-primaryColor text-white rounded h-10 flex justify-center items-center  mx-2" onClick={handleDeleteInvoice}
                  >
                    Delete Invoice
                  </button>

                  <button
                    className="px-2 w-max bg-primaryColor text-white rounded h-10 flex justify-center items-center  gap-x-1 gap-y-1" onClick={handleApproveInvoice}
                  >
                    Approve
                  </button>
                </>
              ) : (
                <></>
              )}

              <button
                className="px-2 w-max bg-primaryColor text-white rounded h-10 flex justify-center items-center  mx-2"
                onClick={handleShowSendInvoiceModal}
              >
                Send Invoice
              </button>


              <button
                className="px-2 w-max bg-primaryColor text-white rounded h-10 flex justify-center items-center  mx-2"
                onClick={() =>
                  invoiceDetailsDispatch({
                    type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_EMAIL_RECORD,
                    payload: true,
                  })
                }
              >
                Show Email Record
              </button>

              {invoiceDetailState.invoiceData?.shippedDate &&
                !invoiceDetailState.isChanged?.deliveryDetails ? (
                <>
                  <button className="px-2 w-max bg-primaryColor text-white rounded h-10 flex justify-center gap-x-1 items-center  mx-2" onClick={handleDownloadCOC}>
                    <DownloadIcon />
                    Certificate Of Conformance
                  </button>
                </>
              ) : (
                <></>
              )}

              {invoiceDetailState.invoiceData?.shippedDate &&
                !invoiceDetailState?.isChanged?.deliveryDetails ? (
                <>
                  <button
                    className="px-2 w-max bg-primaryColor text-white rounded h-10 flex justify-center items-center  gap-x-1 gap-y-1 mx-2" onClick={() =>
                      invoiceDetailsDispatch({
                        type: ADMIN_INVOICE_DETAILS_ACTIONS.TOGGLE_SHOW_DD_MODAL,
                        payload: true,
                      })
                    }
                  >
                    <DownloadIcon />
                    Delivery Docket
                  </button>
                </>
              ) : (
                <></>
              )}

              {invoiceDetailState.orderData?.status == "refunded" ||
                invoiceDetailState.orderData?.status == "cancelled" ? (
                <></>
              ) : (
                <>
                  <button
                    onClick={handleDownloadInvoice}
                    className="px-2 w-max bg-primaryColor text-white rounded h-10 flex justify-center items-center  gap-x-1 mx-2"             >
                    <DownloadIcon />
                    Download Invoice
                  </button>
                </>
              )}

              <button className="px-2 w-max bg-primaryColor text-white rounded h-10 flex justify-center items-center  mx-2" onClick={showDrawer}>
                <ChatButton
                  RefId={invoiceDetailState.orderData?.RefId}
                  showDrawer={showDrawer}
                />
              </button>
              <button className="px-2 ms-2 w-max bg-primaryColor text-white rounded h-10 flex justify-center items-center  nx-2" onClick={handleBack}>
                Back
              </button>
            </div>
          </div>
        </div>
      </span>
    </div>
  );
};
export default AdminDashboardComponents(InvoiceDetails);
