import React, { useEffect, useState } from 'react';
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents';
import { toast } from 'react-toastify';
import { deleteReviewDesign, getAllReviewDesign, upsertReviewDesing } from '../../utils/actions/adminActions';

const ReviewDesignComponent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isReload, setIsReload] = useState([]);
    const [allReviewDesignData, setReviewDesignData] = useState([]);
    const [data, setData] = useState({
        cnc_review_my_design: false,
        cnc_design_review: false,
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        (async () => {
            try {

                const response = await getAllReviewDesign();
                if (!response || !response.success) {
                    throw new Error();
                };
                const { data } = response;
                console.log(data);
                setReviewDesignData(data);

            } catch (error) {
                toast.error("Unable To Fetch Review Design At The Moment");
            }
        })()

    }, [isReload])


    const resetData = () => {
        setData({
            cnc_review_my_design: false,
            cnc_design_review: false,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        try {

            const response = await upsertReviewDesing(data);
            if (!response || !response.success) {
                throw new Error();
            }
            toast.success(`Review Design ${data?._id ? "Updated" : "Created"} Succesfully`);

        } catch (error) {
            toast.error(`Unable To ${data?._id ? "Update" : "Create"} At The Moment`);
        }
        finally {
            setIsModalOpen(false);
            resetData();
            setIsReload((pre) => !pre);
        }

    };

    const handleUpdate = (item) => {
        setData(item);
        setIsModalOpen(true);
    };
    const handleDelete = async (item) => {
        try {
            const userResposne = confirm("Are You Sure You Want To Delete ");
            if (!userResposne) return;

            const id = item?._id;
            const response = await deleteReviewDesign(id);
            if (!response || !response.success) {
                throw new Error();
            }
            toast.success("Item Deleted Succesfully");
            setIsReload((pre) => !pre);

        } catch (error) {
            toast.error("Unable To Delete Item At The Moment");
        }
    }

    return (
        <div className="w-screen relative my-2">
            <h2 className="font-bold my-4 text-center text-2xl">Review Design</h2>
            <button
                onClick={() => {
                    setIsModalOpen(true);
                    resetData();
                }}
                className="bg-primaryColor rounded absolute right-5 top-3 px-2 py-2 text-sm text-white"
            >
                Create New
            </button>
            <table class="table-auto  w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
                <thead class="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                    <tr>
                        <th class="px-4 py-2 border border-gray-200 ">Review My Design</th>
                        <th class="px-4 py-2 border border-gray-200">Manual Review </th>
                        <th class="px-4 py-2 border border-gray-200">Action </th>
                    </tr>
                </thead>
                <tbody>
                    {allReviewDesignData.length
                        ? allReviewDesignData.map((item, index) => (
                            <tr
                                key={index}
                                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                    } hover:bg-gray-200 transition duration-200`}
                            >
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_review_my_design ? "true" : "false"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_design_review ? "true" : "false"}
                                </td>

                                <td className="px-4 py-2  border border-gray-200 flex-col flex text-[14px] text-white">
                                    <button onClick={() => handleUpdate(item)} className='bg-yellow-500 rounded mx-2 p-1'>
                                        Update
                                    </button>
                                    <button onClick={() => handleDelete(item)} className='bg-red-500 rounded mx-2 p-1 my-1'>
                                        Del
                                    </button>
                                </td>
                            </tr>
                        ))
                        : (
                            <tr>
                                <td
                                    colSpan="3"
                                    className="px-4 py-2 text-center text-gray-500 border border-gray-200"
                                >
                                    No data available
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>

            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                        <h3 className="text-xl font-bold mb-4">Create Review Design</h3>

                        <label className="block mb-2">
                            <span className="text-gray-700">CNC Review My Design</span>
                            <select
                                name="cnc_review_my_design"
                                value={data.cnc_review_my_design}
                                onChange={handleChange}
                                className="w-full mt-1 px-3 py-2 border rounded-md"
                            >
                                <option value={true}>True</option>
                                <option value={false}>False</option>
                            </select>
                        </label>

                        <label className="block mb-4">
                            <span className="text-gray-700">CNC Design Review</span>
                            <select
                                name="cnc_design_review"
                                value={data.cnc_design_review}
                                onChange={handleChange}
                                className="w-full mt-1 px-3 py-2 border rounded-md"
                            >
                                <option value={true}>True</option>
                                <option value={false}>False</option>
                            </select>
                        </label>

                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={() => setIsModalOpen(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                            >
                                Close
                            </button>
                            <button
                                onClick={handleSubmit}
                                className="bg-primaryColor text-white px-4 py-2 rounded"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminDashboardComponents(ReviewDesignComponent);
