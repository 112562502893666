import React, { useEffect, useState } from "react";
import downloadImg from "../../images/download.svg";
import { config } from "../../environment/development";
import fileSaver from "file-saver";
import QRTrackingHoverInfo from "../../components/QRTrackingHoverInfo/QRTrackingHoverInfo";
import {
  currencyFormatter,
  extractURLAndEncodedFilename,
} from "../../utils/helper";
import { DownloadIcon } from "../Icons";

function OrderTable({ selectedQuote, selectedShipmentMethod, orderDetails }) {
  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  let getFileExtension = (filename) => {
    return filename?.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  if (!selectedQuote) {
    return <h5>Loading</h5>;
  } else {
    return (
      <div>
        {selectedQuote.partsData.length > 0 ? (
          <div>
            {/* <div style={{ marginTop: "10px" }}>
              <h6>Documentation required : </h6>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  flexWrap: "wrap",
                }}
              >
                <div className="apply-check" style={{ width: "33%" }}>
                  <input
                    type="checkbox"
                    id="sign-check-cc"
                    name="checkbox"
                    value={selectedQuote.isConformanceCertificateRequested}
                    checked={selectedQuote.isConformanceCertificateRequested}
                    disabled
                  />
                  <label htmlFor="sign-check-cc" style={{ fontSize: "12px" }}>
                    Certificate of conformance
                  </label>
                </div>

                <div className="apply-check" style={{ width: "33%" }}>
                  <input
                    type="checkbox"
                    id="sign-check-dr"
                    name="checkbox"
                    value={selectedQuote.isDimensionalReportRequested}
                    checked={selectedQuote.isDimensionalReportRequested}
                    disabled
                  />
                  <label htmlFor="sign-check-dr" style={{ fontSize: "12px" }}>
                    Dimensional Inspection Report
                  </label>
                </div>

                <div className="apply-check" style={{ width: "33%" }}>
                  <input
                    type="checkbox"
                    id="sign-check-qr"
                    name="checkbox"
                    value={selectedQuote.isQrTrackingRequested}
                    checked={selectedQuote.isQrTrackingRequested}
                    disabled
                  />
                  <label
                    htmlFor="sign-check-qr"
                    style={{ fontSize: "12px", display: "inline-block" }}
                  >
                    QR Tracking
                  </label>
                  <QRTrackingHoverInfo />
                </div>
              </div>
            </div> */}
            {/* ======================New-Component========================= */}
            <div className="my-2">
              <h5>Documentation required : </h5>
              <div className=" p-2 flex test justify-between gap-y-3 flex-col md:flex-row items-start md:items-center">
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    id="sign-check-cc"
                    name="checkbox"
                    value={selectedQuote.isConformanceCertificateRequested}
                    checked={selectedQuote.isConformanceCertificateRequested}
                    disabled
                    class="w-4 h-4 text-blue-600 text-sm bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label
                    for="sign-check-cc"
                    class="ml-3 text-[15px] font-medium text-gray-900 "
                  >
                    Certificate of conformance
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="sign-check-dr"
                    name="checkbox"
                    value={selectedQuote.isDimensionalReportRequested}
                    checked={selectedQuote.isDimensionalReportRequested}
                    disabled
                  />
                  <label
                    htmlFor="sign-check-dr"
                    className="text-[15px] text-sm font-medium "
                  >
                    Dimensional Inspection Report
                  </label>
                </div>
                <div className="flex justify-around items-center gap-x-2 ">
                  <input
                    type="checkbox"
                    id="sign-check-qr"
                    name="checkbox"
                    value={selectedQuote.isQrTrackingRequested}
                    checked={selectedQuote.isQrTrackingRequested}
                    disabled
                  />
                  <label
                    htmlFor="sign-check-qr"
                    className="text-sm font-medium"
                  >
                    QR Tracking{" "}
                  </label>
                  <QRTrackingHoverInfo />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div style={{ boxSizing: "border-box", overflowX: "auto" }}>
          <table className="qoute-version-table">
            {selectedQuote.selectedProcess >= 3 ? (
              <>
                <thead>
                  <tr className="heading-row">
                    <th scope="col">File Name</th>
                    <th scope="col">Part Description</th>
                    <th scope="col">Notes</th>
                    <th scope="col">Price</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedQuote.partsData?.length > 0 &&
                    selectedQuote.partsData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            className="downloadables "
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              width: "300px",
                            }}
                          >
                            <div className="flex gap-x-2">
                              {(item.file
                                ? item.file.originalname
                                : item.linePartName || "No file uploaded"
                              )?.length > 25
                                ? (item.file
                                    ? item.file.originalname
                                    : item.linePartName || "No file uploaded"
                                  ).substring(0, 25) + "..."
                                : item.file
                                ? item.file.originalname
                                : item.linePartName || "No file uploaded"}{" "}
                              {item.file ? (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    let extension = getFileExtension(
                                      item?.file?.originalname ||
                                        item.selectedFile?.path
                                    );
                                    let url = `${config.backEnd}/public/uploads/${item._id}.${extension}`;
                                    download(
                                      item?.file?.originalname ||
                                        item.selectedFile?.path,
                                      url
                                    );
                                  }}
                                >
                                  <DownloadIcon />
                                </span>
                              ) : null}
                            </div>
                          </td>

                          <td>{item.description ? item.description : ""}</td>

                          <td>{item.Notes ? item.Notes : "No notes found"}</td>

                          <td>
                            {" "}
                            <div className="amt">
                              <p className="un-fld">
                                {currencyFormatter(
                                  selectedShipmentMethod === 0
                                    ? Number(item?.price).toFixed(2)
                                    : selectedShipmentMethod === 1
                                    ? Number(item?.price1).toFixed(2)
                                    : Number(item?.price2).toFixed(2)
                                )}
                              </p>
                            </div>
                          </td>

                          <td>{item.Qty ? item.Qty : "No quantity found"}</td>

                          <td>
                            {" "}
                            <div className="amt">
                              <p className="un-fld">
                                {currencyFormatter(
                                  selectedShipmentMethod === 0
                                    ? (
                                        Number(item?.price) * Number(item?.Qty)
                                      ).toFixed(2)
                                    : selectedShipmentMethod === 1
                                    ? (
                                        Number(item?.price1) * Number(item?.Qty)
                                      ).toFixed(2)
                                    : (
                                        Number(item?.price2) * Number(item?.Qty)
                                      ).toFixed(2)
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  {selectedQuote.isQrTrackingRequested ? (
                    <tr>
                      <td> </td>
                      <td>QR Tracking</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="amt">
                          <p className="un-fld">
                            {currencyFormatter(
                              selectedQuote?.selectedShipmentMethod == 0
                                ? selectedQuote.additionalCost1
                                : selectedQuote?.selectedShipmentMethod == 1
                                ? selectedQuote.additionalCost2
                                : selectedQuote.additionalCost3
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}

                  {orderDetails.adjustmentValue != 0 ? (
                    <tr>
                      <td> </td>
                      <td>Adjustments</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="amt">
                          <p className="un-fld">
                            {currencyFormatter(orderDetails.adjustmentValue)}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </>
            ) : (
              <>
                <thead>
                  <tr className="heading-row">
                    <th scope="col">File Name</th>
                    <th scope="col">Part Description</th>
                    <th scope="col">Notes</th>
                    <th scope="col">Price</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedQuote.partsData.length > 0 &&
                    selectedQuote.partsData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{
                              textAlign: "left",
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                              whiteSpace: "normal",
                              width: "300px",
                              paddingLeft: "20px",
                            }}
                          >
                            <div
                              className="prd-thumb"
                              style={{ color: "dodgerblue" }}
                            >
                              <p
                                style={{ color: "dodgerblue" }}
                                className="flex gap-x-2 items-center !text-primaryColor"
                              >
                                {(item.file
                                  ? item.file.originalname
                                  : item.linePartName || "No file uploaded"
                                )?.length > 25
                                  ? (item.file
                                      ? item.file.originalname
                                      : item.linePartName || "No file uploaded"
                                    ).substring(0, 25) + "..."
                                  : item.file
                                  ? item.file.originalname
                                  : item.linePartName || "No file uploaded"}
                                <span
                                  style={{ cursor: "pointer" }}
                                  className="text-primaryColor"
                                  onClick={(e) => {
                                    let extension = getFileExtension(
                                      item?.file?.originalname ||
                                        item.selectedFile?.path
                                    );
                                    let url = `${config.backEnd}/public/uploads/${item._id}.${extension}`;
                                    download(
                                      item?.file?.originalname ||
                                        item.selectedFile?.path,
                                      url
                                    );
                                  }}
                                >
                                  <DownloadIcon
                                  additionalClass={'text-primaryColor'}
                                  />
                                </span>
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className="desc-part-quote-version">
                              <ul>
                                {item?.description ? (
                                  <li>{item?.description}</li>
                                ) : (
                                  ""
                                )}
                                {item?.MaterialType ? (
                                  <li>{item?.MaterialType?.name}</li>
                                ) : (
                                  ""
                                )}
                                {item?.SubMaterial ? (
                                  <li>{item?.SubMaterial.name}</li>
                                ) : (
                                  ""
                                )}
                                {item?.surfaceFinish ? (
                                  <li>{item?.surfaceFinish.name}</li>
                                ) : (
                                  ""
                                )}
                                {item?.surfaceTreatment ? (
                                  <li>{item?.surfaceTreatment.name}</li>
                                ) : (
                                  ""
                                )}
                                {item?.tolerance ? (
                                  <li>{item?.tolerance.name}</li>
                                ) : (
                                  ""
                                )}
                                {item?.partMarking ? (
                                  <li>{item?.partMarking.name}</li>
                                ) : (
                                  ""
                                )}
                                {item?.noOfThread ? (
                                  <li>{item?.noOfThread}</li>
                                ) : (
                                  ""
                                )}
                              </ul>
                            </div>
                          </td>
                          <td>
                            <div className="3dcncNote">{item?.Notes}</div>{" "}
                          </td>

                          <td>
                            {" "}
                            <div className="amt">
                              <p className="un-fld">
                                {currencyFormatter(
                                  selectedShipmentMethod === 0
                                    ? Number(item?.price).toFixed(2)
                                    : selectedShipmentMethod === 1
                                    ? Number(item?.price1).toFixed(2)
                                    : Number(item?.price2).toFixed(2)
                                )}
                              </p>
                            </div>
                          </td>

                          <td>
                            <div className="amt">{item?.Qty}</div>{" "}
                          </td>

                          <td>
                            {" "}
                            <div className="amt">
                              <p className="un-fld">
                                {currencyFormatter(
                                  selectedShipmentMethod === 0
                                    ? (
                                        Number(item?.price) * Number(item?.Qty)
                                      ).toFixed(2)
                                    : selectedShipmentMethod === 1
                                    ? (
                                        Number(item?.price1) * Number(item?.Qty)
                                      ).toFixed(2)
                                    : (
                                        Number(item?.price2) * Number(item?.Qty)
                                      ).toFixed(2)
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                      );
                    })}

                  {selectedQuote.isQrTrackingRequested ? (
                    <tr>
                      <td> </td>
                      <td>QR Tracking</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="amt">
                          <p className="un-fld">
                            {currencyFormatter(
                              selectedQuote?.selectedShipMethod == 0
                                ? selectedQuote.additionalCost1
                                : selectedQuote?.selectedShipMethod == 1
                                ? selectedQuote.additionalCost2
                                : selectedQuote.additionalCost3
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                  {orderDetails.adjustmentValue != 0 ? (
                    <tr>
                      <td> </td>
                      <td>Adjustments</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div className="amt">
                          <p className="un-fld">
                            {currencyFormatter(orderDetails?.adjustmentValue)}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </>
            )}
          </table>
        </div>
      </div>
    );
  }
}

export default OrderTable;
