import React from 'react';
import { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { getToken,validateAdmin } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { getPurchaseOrderIssue,addPurchaseOrderIssue,deletePurchaseOrderIssue} from '../../utils/actions/allactions';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { toast } from 'react-toastify';
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents';

const PurchaseOrderIssue = () => {
    useDocumentTitle('Purchase Order Issues')
    let navigate = useNavigate();
    const [data, setData] = useState([]);
    let token = getToken();
    const [newPurchaseOrderIssue,setNewPurchaseOrderIssue] = useState('');

    const fetchProductRelatedIssue = async () => {
        try {
            const purchaseOrderIssueData = await getPurchaseOrderIssue(token);
            setData(purchaseOrderIssueData);
        } catch (error) {
            toast.error("Unable To Fetch Purchase Order Issues Data");
        }
       
    }

    useEffect(() => {
        validateAdmin(token, navigate);
        fetchProductRelatedIssue();
    }, []);

    const handlePurchaseOrderRelatedIssueDelete = async (item) =>{
        let response = await deletePurchaseOrderIssue(token,{id:item._id})
        setData(response.data.data);
    }

    const handleCreateNew = async () =>{
        let response = await addPurchaseOrderIssue(token,newPurchaseOrderIssue)
        setNewPurchaseOrderIssue({name:''});
        setData(response.data.data);
    
    }


    return (
        <div  className="admin-container">
        <div className="right-container">
            <div className="center-heading">
                <h1>  Purchase Related Issues </h1>
                
                <div className="quotes-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col" style={{ cursor: 'pointer' }} >Name  </th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.length > 0 &&
                                data.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item.name ? item.name : ''}</td>
                                            <td> <button className="button-edit" onClick={() => { handlePurchaseOrderRelatedIssueDelete(item) }}>Delete</button></td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="search-bar">
                 <div className="row">
                 <div className='col-md-6'>
                     </div>
                     <div className='col-md-3'>
                         <input 
                         type="text" 
                         placeholder='Enter purchase order related issue ' 
                         style={{padding:'5px',width:'100%'}}
                         value={newPurchaseOrderIssue.name}
                         onChange={(e) => {setNewPurchaseOrderIssue({...newPurchaseOrderIssue,name:e.target.value})}}
                         />
                     </div>
                     <div className="col-md-3" style={{textAlign:'left'}}>
                         <div className="search-btn">
                             <button onClick={handleCreateNew} className="button-search">Create New</button>
                         </div>
                     </div>
                 </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default AdminDashboardComponents(PurchaseOrderIssue);
