import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import {
  handleSendInvitationToEmail,
  addFollowerToQuote,
  changeOwnerOfQuote,
  fetchUsersFromSameCompany,
  deleteFollowerFromQuotation,
  searchUserByEmail,
} from "../../utils/actions/commanActions";
import { toast } from "react-toastify";
import deleteImage from '../../images/delete.png'
import CaptchaModal from "../CaptchaModal/CaptchaModal";
import { DeleteIcon } from "../Icons";

const TeamCollaboration = ({
  teamMemberModalShow,
  setTeamMemberModalShow,
  selectedQuoteForTeam,
  fetchDataForUser,
  updateQuoteData,
}) => {
  let token = localStorage.getItem("Token");
  let loggedInUserEmail = localStorage.getItem("email");
  const [selectedUserForTeam, setSelectedUserForTeam] = useState("");
  const [nonFollowerFilter, setNonFollowerFilter] = useState("");
  const [filteredNonFollower, setFilteredNonFollower] = useState([]);
  const [nonFollowerOfQuote, setNonFollowerOfQuote] = useState([]);
  const [userNotExistForTeam, setUserNotExistForTeam] = useState(false);
  const [usersFromSameCompany, setUsersFromSameCompany] = useState([]);
  const [userInfoToSendInvitation, setUserInfoToSendInvitation] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [userSelectedToDelete, setUserSelectedToDelete] = useState("")
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailExists, setIsEmailExists] = useState(true);

  useEffect(() => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setIsEmailExists(true);

  }, [teamMemberModalShow])

  useEffect(() => {
    console.log("run run run run ");
    (async () => {
      if (email == "") {
        setIsEmailExists(true);
        return;
      }
      const response = await searchUserByEmail(token, email);
      if (response && response.success) {
        if (response.data?.length > 0) {
          setIsEmailExists(true);
        }
        else {
          setIsEmailExists(false);
        }
      }
    })()

  }, [email]);


  useEffect(() => {
    if (teamMemberModalShow && selectedQuoteForTeam) {
      const fetchDataOnLoad = async () => {
        let followers = [];
        selectedQuoteForTeam.followers.map((item) => followers.push(item?._id));
        if (!selectedQuoteForTeam?.userId?.companySiteId?._id) {
          toast.error(
            "Company information is not available.Please contact admin through chat to get this issue resolved."
          );
          return;
        }
        const userFromSameCompanyResponse = await fetchUsersFromSameCompany(
          selectedQuoteForTeam.userId.companySiteId._id,
          token
        );
        setUsersFromSameCompany(userFromSameCompanyResponse?.data?.data);

        let localUserFromSameCompany =
          userFromSameCompanyResponse?.data?.data.filter(
            (item) => !followers.includes(item._id)
          );
        setNonFollowerOfQuote(localUserFromSameCompany);
      };

      fetchDataOnLoad();
    }
  }, [teamMemberModalShow, selectedQuoteForTeam]);

  const handleTeamMemberModalClose = () => {
    setTeamMemberModalShow(!teamMemberModalShow);
    setUserInfoToSendInvitation({
      firstName: "",
      lastName: "",
      email: "",
    });
    setUserNotExistForTeam(false);
    setSelectedUserForTeam("");
  };

  const handleSelectUserForTeam = (selectedUser) => {
    setSelectedUserForTeam(selectedUser);
    setNonFollowerFilter("");
    setFilteredNonFollower([]);
  };

  const searchUser = (e) => {
    setNonFollowerFilter(e.target.value);
    let localFilteredUser = [];
    if (e.target.value.length > 0) {
      localFilteredUser = nonFollowerOfQuote.filter((item) =>
        (item.firstName + " " + item.lastName)
          .toUpperCase()
          .includes(e.target.value.toUpperCase())
      );
    }
    setFilteredNonFollower(localFilteredUser);
  };

  const handleAddUserAsFollower = async () => {
    if (!selectedUserForTeam._id) {
      toast.error("Please select a user to add as Team Member");
      return;
    }

    let userResponse = confirm(
      "Are you sure you want add the user as follower?"
    );
    if (userResponse) {
      let data = {
        userId: selectedUserForTeam._id,
        quoteId: selectedQuoteForTeam._id,
      };

      const response = await addFollowerToQuote(token, data);
      setTeamMemberModalShow(!teamMemberModalShow);
      setUserInfoToSendInvitation({
        firstName: "",
        lastName: "",
        email: "",
      });
      setSelectedUserForTeam(null);

      if (response.status) {
        toast.success(response?.message);
        updateQuoteData(response.data);
      } else {
        toast.error("Something went wrong.Please try again later.");
      }
    }
  };

  const handleChangeOwnerDropDown = async (e) => {
    if (e.target.value != "follower") {
      if (!e.target.value) {
        toast.error("Invalid selection!");
        return;
      }
      let userResponse = confirm(
        "Are you sure you change the owner of this project?"
      );
      if (userResponse) {
        let data = {
          quoteRefId: selectedQuoteForTeam.RefId,
          userId: e.target.value,
          quoteId: selectedQuoteForTeam._id,
        };
        setTeamMemberModalShow(false);
        setUserInfoToSendInvitation({
          firstName: "",
          lastName: "",
          email: "",
        });
        const response = await changeOwnerOfQuote(token, data);
        if (response.status) {
          updateQuoteData(response.data)
          fetchDataForUser();

          toast.success("Owner changed successfully");
        }
      }
    }
  };

  const handleSendInvitation = async () => {
    if (
      !email ||
      !new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}").test(
        email
      )
    ) {
      toast.error("Please enter a valid email address");
      return;
    }

    if (!isEmailExists) {
      if (!firstName) {
        toast.error("First name is required.");
        return;
      }
    }


    const domain = email.split("@")[1];
    let loggedInUserDomain = loggedInUserEmail.split("@")[1];
    if (domain !== loggedInUserEmail.split("@")[1] && loggedInUserDomain != "8xparts.com") {
      toast.error("Domain name must match for new user.");
      return;
    }

    // let userEmailFromSameCompany = [];
    // usersFromSameCompany.forEach((item) =>
    //   userEmailFromSameCompany.push(item.email)
    // );
    // if (userEmailFromSameCompany.includes(userInfoToSendInvitation.email)) {
    //   toast.error("User already exists");
    //   return;
    // }

    let userResponse = confirm(
      `Are you sure you want to send invitation for this quotation to ${email} ?`
    );
    if (userResponse) {
      const data = {
        newUserEmail: email,
        newUserFirstName: firstName,
        newUserLastName: lastName,
        quoteId: selectedQuoteForTeam._id,
        userEmail: loggedInUserEmail,
      };
      setTeamMemberModalShow(false);
      const response = await handleSendInvitationToEmail(token, data);
      if (response.status) {
        toast.success(response.message);
        updateQuoteData(response.data);
      } else {
        toast.error(response.message);
      }
    }

    setUserInfoToSendInvitation({
      firstName: "",
      lastName: "",
      email: "",
    });

    handleUserNotExistCheckBox();
  };

  const handleUserNotExistCheckBox = () => {
    setUserNotExistForTeam(!userNotExistForTeam);
  };

  const handleDeleteUserFromTeam = async () => {
    try {
      if (selectedQuoteForTeam.userId._id == userSelectedToDelete) {
        toast.error("Cannot delete owner of the project.")
        return;
      }

      let data = {
        RefId: selectedQuoteForTeam.RefId,
        userSelectedToDelete
      }

      const response = await deleteFollowerFromQuotation(token, data);
      if (response.status) {
        let localFollowers = [...selectedQuoteForTeam.followers];
        localFollowers = localFollowers.filter(item => item._id != userSelectedToDelete);
        let updatedQuote = { ...selectedQuoteForTeam, followers: localFollowers }
        updateQuoteData(updatedQuote);
        toast.success(response.message);
        setTeamMemberModalShow(false);
      } else {
        toast.error("Something went wrong, please refresh the page and try again.")
      }
    } catch (err) {
      console.log("Error", err)
    }
  }

  const handleFollowerDeleteClick = async (userId) => {
    setUserSelectedToDelete(userId)
    setShowCaptchaModal(true);
  }

  const handleCloseCaptchaModal = () => {
    setUserSelectedToDelete(null);
    setShowCaptchaModal(false);
  }

  return (
    <div>

      <CaptchaModal
        showModal={showCaptchaModal}
        handleClose={handleCloseCaptchaModal}
        handleSendEmail={handleDeleteUserFromTeam}
        buttonText={"Delete"}
      />


      {teamMemberModalShow ? (
        <>
          <Modal
            size="lg"
            dialogClassName=" w-4/5 lg:w-3/5 mx-auto"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={teamMemberModalShow}
            onHide={handleTeamMemberModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '15px' }}>
                Team Members
              </Modal.Title>
            </Modal.Header>
            <Modal.Body >
              <div className="share-quote-div">
                <h6 className="my-2 font-bold text-[14px] lg:text-xl">
                  Enter Email :
                </h6>

                <Form.Control
                  className="follower-input"
                  value={email}
                  placeholder=""
                  onChange={(e) => setEmail(e.target.value)}
                />
                {
                  !isEmailExists ? (
                    <div className="space-y-4 py-4 bg-white rounded-lg ">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          First Name:
                        </label>
                        <input
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          type="text"
                          placeholder="Enter  first name"
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Last Name:
                        </label>
                        <input
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          type="text"
                          placeholder="Enter  last name"
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        />
                      </div>
                    </div>
                  ) : null
                }
                <button onClick={handleSendInvitation} className="my-2 bg-primaryColor px-2 py-2 rounded text-white">
                  Send Invitation
                </button>



                {/* {loggedInUserEmail.split("@")[1] != "8xparts.com" ? <>
                  <div style={{ margin: "10px 0px" }}>
                    <input
                      type="checkbox"
                      id="userNotExistCheck"
                      checked={userNotExistForTeam}
                      onChange={handleUserNotExistCheckBox}
                    />{" "}
                    <label
                      htmlFor="userNotExistCheck"
                      style={{ fontSize: "12px" }}
                    >
                      User does not exist?
                    </label>
                  </div>
                  {userNotExistForTeam ? (
                    <div className="add-email-div ">
                      <p className="my-3">Enter the user details to below send invitation: </p>
                      <Form.Control
                        placeholder="First Name"
                        type="text"
                        style={{
                          fontSize: "14px",
                          padding: "5px",
                          width: "50%",
                          display: "block",
                          margin: "-10px 0px 5px 0px",
                        }}
                        value={userInfoToSendInvitation.firstName}
                        onChange={(e) =>
                          setUserInfoToSendInvitation({
                            ...userInfoToSendInvitation,
                            firstName: e.target.value,
                          })
                        }
                      />
                      <Form.Control
                        placeholder="Last Name"
                        type="text"
                        style={{
                          fontSize: "14px",
                          padding: "5px",
                          width: "50%",
                          display: "block",
                          margin: "5px 0px",
                        }}
                        value={userInfoToSendInvitation.lastName}
                        onChange={(e) =>
                          setUserInfoToSendInvitation({
                            ...userInfoToSendInvitation,
                            lastName: e.target.value,
                          })
                        }
                      />
                      <Form.Control
                        placeholder="Email"
                        type="email"
                        style={{
                          fontSize: "14px",
                          padding: "5px",
                          width: "50%",
                          display: "block",
                          margin: "5px 0px",
                        }}
                        value={userInfoToSendInvitation.email}
                        onChange={(e) =>
                          setUserInfoToSendInvitation({
                            ...userInfoToSendInvitation,
                            email: e.target.value,
                          })
                        }
                      />
                      <button
                        onClick={handleSendInvitation}
                        style={{ marginLeft: "0px" }}
                      >
                        Send Invitation
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}

                </> : <></>} */}

                <div className="share-content-div">
                  <h6 className="font-medium mb-1">Teams Members : </h6>
                  <div className="relative overflow-x-auto">
                    {selectedQuoteForTeam?.followers &&
                      selectedQuoteForTeam?.followers?.length > 0 ? (
                      <table
                        className="w-full text-sm text-left rtl:text-right text-gray-500 table-auto border "
                      // style={{ width: "100%" }}
                      >
                        <thead>
                          <tr
                            className="text-xs text-gray-700 uppercase bg-gray-50 "
                          >
                            <th className="px-6 py-3">First Name</th>
                            <th className="px-6 py-3">Last Name</th>
                            <th className="px-6 py-3">Email</th>
                            <th className="px-6 py-3">Role</th>
                            <th className="px-6 py-3"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedQuoteForTeam.followers.map((item, i) => {
                            return (
                              <tr
                                className=" bg-white border-b"
                              >
                                <td className="px-6 py-3">{item.firstName}</td>
                                <td className="px-6 py-3">{item.lastName}</td>
                                <td className="px-6 py-3">{item.email}</td>
                                <td className=" ">
                                  {item._id ==
                                    selectedQuoteForTeam.userId._id ? (
                                    "Owner"
                                  ) : (
                                    <select
                                      onChange={handleChangeOwnerDropDown}
                                      defaultValue={"follower"}
                                      className="border p-1 outline-none !w-28 "
                                    >
                                      <option value={item._id}>Owner</option>
                                      <option value="follower">Follower</option>
                                    </select>
                                  )}
                                </td>
                                <td className="px-6 py-3">
                                  {(item._id == selectedQuoteForTeam.userId._id) ? <></> :
                                    <div className="  flex items-center justify-center" onClick={() => handleFollowerDeleteClick(item._id)}>
                                      <DeleteIcon />
                                    </div>
                                  }
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <>
                        <p style={{ fontSize: "12px" }}>
                          {" "}
                          No user is added as Team Member!
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="share-quote-div-footer">
                <div
                  className="share-button"
                  onClick={handleTeamMemberModalClose}
                >
                  Close
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TeamCollaboration;
