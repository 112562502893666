import { saveCadAnalysis } from "../utils/actions/allactions";
import { createFrontend, createBackend } from "cad-analyzer-library";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils";
import "regenerator-runtime/runtime.js";

import openCascadeWorker from "comlink-loader!cad-analyzer-library/build/cad-analyzer-worker";

import { config } from "../environment/development";
import moment from "moment";
import { toast } from "react-toastify";

let boundingVal = [];

export const triggerFuncLink = async (
  file,
  ext,
  setBounding,
  setProcessing,
  fileName,
  bounding
) => {
  try {
    console.log("Starting headless analysis triggerFuncLink");
    setProcessing(true);
    
    const workerInstance = new openCascadeWorker();
    const openCascade = await new workerInstance.OpenCascade();
    await openCascade.init();
    let text;
    let type = ext.toUpperCase();
    if (type === "STP") type = "STEP";
    if (type === "STEP" || type === "IGES") {
      text = await (await fetch(file)).text();
      await openCascade.shapeFromFile(text, type);
    }

    if (type === "STL" || type === "OBJ") {
      if (type === "STL") {
        const fileContent = await (await fetch(file)).arrayBuffer();
        const loader = new STLLoader();
        try {
          const geometry = loader.parse(fileContent);
          await openCascade.shapeFromBufferGeometry(geometry);
        } catch (err) {
          console.error("Error loading STL file:", err);
        }
      }
      if (type === "OBJ") {
        const fileContent = await (await fetch(file)).text();
        const loader = new OBJLoader();
        const object = loader.parse(fileContent);
        const bufferGeometries = [];
        object.traverse((o) => {
          if (o.geometry) {
            bufferGeometries.push(o.geometry);
          }
        });
        const geometry = BufferGeometryUtils.mergeBufferGeometries(
          bufferGeometries,
          false
        );
        await openCascade.shapeFromBufferGeometry(geometry);
      }
    }
    const boundingBox = await openCascade.calculateAABB();
    // console.log("boundingBox", boundingBox);

    let analysis = await openCascade.analyze();
    // console.log("analysis", analysis);
    const data = {
      boundingBox,
      fileName: fileName || "SamplePart.stp",
      analysis,
    };

    if (
      type === "STP" ||
      type === "STEP" ||
      type === "IGES" ||
      type === "OBJ" ||
      type === "STL"
    ) {
      let token = localStorage.getItem("Token");
      const response = await saveCadAnalysis(data, token, setProcessing);
      setBounding([...boundingVal, data]);
      boundingVal = [...boundingVal, data];
    } else {
      setBounding([
        ...boundingVal,
        {
          fileName: fileName,
          boundingBox: null,
          analysis: null,
        },
      ]);
      boundingVal = [
        ...boundingVal,
        {
          fileName: fileName,
          boundingBox: null,
          analysis: null,
        },
      ];
    }
    setProcessing(false);
  } catch (err) {
    console.log("error in trigerfunlin", err);
    setProcessing(false);
    toast.error("Error",err)
  }
};

// function handleFileSelectOld(file, callback) {
//   const reader = new FileReader();
//   reader.onload = callback;
//   reader.readAsText(file);
//   return reader;
// }

function handleFileSelect(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = (error) => reject(error);
    reader.readAsText(file);
  });
}

function handleFileSelectAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
}

export const triggerFunc = async (
  file,
  extension,
  setBounding,
  setProcessing,
  bounding
) => {
  try {
    console.log("Starting headless analysis triggerFunc");
    setProcessing(true);
    const workerInstance = new openCascadeWorker();
    const openCascade = await new workerInstance.OpenCascade();
    await openCascade.init();

    let text;
    let type = extension.toUpperCase();
    if (type === "STP" || type === "STEP" || type === "IGES") {
      if (type === "STP") type = "STEP";
      text = await handleFileSelect(file);
      try {
        await openCascade.shapeFromFile(text, type);
      } catch (error) {
        console.error("Error loading STEP file:", error);
        // Handle unsupported data type error
        if (error.message === "Unsupported data type") {
          console.error("The STEP file contains unsupported data types.");
        }
      }
    } else if (type === "STL" || type === "OBJ") {
      if (type === "STL") {
        text = await handleFileSelectAsArrayBuffer(file);
        const loader = new STLLoader();
        try {
          const geometry = loader.parse(text);
          await openCascade.shapeFromBufferGeometry(geometry);
        } catch (error) {
          console.error("Error loading STL file:", error);
          // Handle error loading STL file
        }
      }
      if (type === "OBJ") {
        text = await handleFileSelect(file);
        const fileContent = text;
        const loader = new OBJLoader();
        const object = loader.parse(fileContent);
        const bufferGeometries = [];
        object.traverse((o) => {
          if (o.geometry) {
            bufferGeometries.push(o.geometry);
          }
        });
        const geometry = BufferGeometryUtils.mergeBufferGeometries(
          bufferGeometries,
          false
        );
        await openCascade.shapeFromBufferGeometry(geometry);
      }
    } else {
      console.error("Unsupported file type:", extension);
      // Handle unsupported file type
    }

    // console.log("text: " + text);
    if (text) {
      const boundingBox = await openCascade.calculateAABB();
      // console.log("boundingBox: " + boundingBox);
      let analysis = await openCascade.analyze();
      // console.log("analysis", analysis);
      const data = {
        boundingBox,
        fileName: file?.name || file?.originalname,
        analysis,
      };

      // console.log("data", data);
      // console.log("type", type);
      if (
        type === "STP" ||
        type === "STEP" ||
        type === "IGES" ||
        type === "OBJ" ||
        type === "STL"
      ) {
        let token = localStorage.getItem("Token");
        const response = await saveCadAnalysis(data, token, setProcessing);
        setBounding([...boundingVal, ...bounding, data]);
        boundingVal = [...boundingVal, data];
      } else {
        setBounding([
          ...boundingVal,
          ...bounding,
          {
            fileName: file?.name || file?.originalname,
            boundingBox: null,
            analysis: null,
          },
        ]);
        boundingVal = [
          ...boundingVal,
          {
            fileName: file?.name || file?.originalname,
            boundingBox: null,
            analysis: null,
          },
        ];
      }
    } 
    setProcessing(false);
  } catch (error) {
    console.log(error);
    toast.error("Error: " + error.message)
    setProcessing(false);
  }
};

export const load3DViewer = async (file, extension) => {
  try {
    let viewport;
    let ele = document.createElement("div");
    ele.setAttribute("id", "cad-analyzer");
    // document.body.appendChild(ele);

    viewport = await createFrontend(
      openCascadeWorker,
      document.getElementById("cad-analyzer")
    );

    viewport = await createBackend(
      openCascadeWorker,
      document.getElementById("cad-analyzer")
    );

    const onProgress = (progress) => {
      console.log("loading progress: ", progress);
    };

    let type = extension.toUpperCase();
    let resource;
    if (!file?.path && file.name != "SamplePart.stp") {
      resource = file;
    } else {
      if (file.name) {
        resource = URL.createObjectURL(file);
      } else {
        if (file?.path) {
          resource = `${config.backEnd}/public/uploads/` + file.path;
        } else if (file?.originalname) {
          resource = `${config.backEnd}/public/uploads/` + file.originalname;
        }
      }
    }

    if (type === "STP") type = "STEP";
    const loadFileResult = await viewport.addFileFromURL(
      type,
      resource,
      undefined,
      onProgress
    );

    await viewport.setActiveFileIndex(loadFileResult.index);
  } catch (err) {
    console.log(
      "error in trigger on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
      err
    );
  }
};
