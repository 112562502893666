import { useState, useEffect, useContext, useReducer, useRef } from "react";
import {
  getAdminUsers,
  changeQuoteAdminOwner,
  getAllUsers,
  getCompanies,
} from "../../utils/actions/allactions";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-paginate";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import waitingImage from "../../images/waiting.svg";
import tickGreenImage from "../../images/tick-green.svg";
import cancelledImage from "../../images/close_red.svg";
import allValuesImage from "../../images/all-entity.svg";
import settingImage from "../../images/setting-green.svg";
import sendImage from "../../images/send.svg";
import { ChatRefContext } from "../../context/chatRef.context";
import HoverInfo from "../../components/HoverInfo/HoverInfo";
import { ADMIN_ACTIONS, useAdminContext } from "../../context/admin.context";
import { currencyFormatter, validateAdmin } from "../../utils/helper";
import { Form } from "react-bootstrap";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {
  fetchFilteredQuotes,
  fetchQuotesForRefIdSearch,
} from "../../utils/actions/adminActions";
import { Loading } from "../../components/Loading";
import { ReviewTargetCostIcon, SubmitedTargetCost } from "../../components/Icons";

const Quotes = () => {
  const navigate = useNavigate();
  useDocumentTitle("Quotations");
  const { state, dispatch } = useAdminContext();
  const { selectedChatRefId, setSelectedChatRefId } =
    useContext(ChatRefContext);
  let token = localStorage.getItem("Token");
  const [adminUsers, setAdminUsers] = useState([]);
  const [directQuoteResult, setDirectQuoteResult] = useState([]);
  const [directFilterVal, setDirectFilterVal] = useState("");
  const [loading, setLoading] = useState(false);

  const [allCustomerUsers, setAllCustomerUsers] = useState([]);
  const [selectedCustomerForFilter, setSelectedCustomerForFilter] = useState(
    new Set()
  );
  const [filteredCustomerUser, setFilteredCustomerUser] = useState([]);
  const [isUserDropDownVisible, setIsUserDropDownVisible] = useState(false);

  const userDropDownRef = useRef(null);
  const companyDropDownRef = useRef(null);

  const [allCompanies, setAllCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState(new Set());
  const [isCompanyDropDownVisible, setIsCompanyDropDownVisible] =
    useState(false);

  const initialQuoteState = {
    createdFrom: "",
    createdTo: "",
    userName: "",
    adminOwnerId: "",
    sortBy: "createdAt",
    sortOrder: 1,
    pageNum: 1,
    totalPages: 0,
    quoteList: [],
    quotationCount: {},
    companyName: "",
  };

  const QUOTE_ACTIONS = {
    UPDATE_CREATED_FROM: "update-created-from",
    UPDATE_CREATED_TO: "update-created-to",
    UPDATE_USER_NAME: "update-user-name",
    UPDATE_ADMIN_OWNER_ID: "update-owner-id",
    UPDATE_SORT_BY: "update-sort-by",
    UPDATE_SORT_ORDER: "update-sort-order",
    UPDATE_PAGE_NUM: "update-page-num",
    UPDATE_TOTAL_PAGE: "update-total-page",
    UPDATE_QUOTE_LIST: "update-quotes",
    UPDATE_QUOTE_COUNT: "update-quote-count",
    UPDATE_QUOTE_FILTER: "update-quote-filter",
    UPDATE_COMPANY_NAME: "update-company-name",
  };

  const quotationReducer = (state, action) => {
    switch (action.type) {
      case QUOTE_ACTIONS.UPDATE_CREATED_FROM:
        return { ...state, createdFrom: action.payload };
      case QUOTE_ACTIONS.UPDATE_CREATED_TO:
        return { ...state, createdTo: action.payload };
      case QUOTE_ACTIONS.UPDATE_USER_NAME:
        return { ...state, userName: action.payload };
      case QUOTE_ACTIONS.UPDATE_COMPANY_NAME:
        return { ...state, companyName: action.payload };
      case QUOTE_ACTIONS.UPDATE_ADMIN_OWNER_ID:
        return { ...state, adminOwnerId: action.payload };
      case QUOTE_ACTIONS.UPDATE_SORT_BY:
        return { ...state, sortBy: action.payload };
      case QUOTE_ACTIONS.UPDATE_SORT_ORDER:
        return { ...state, sortOrder: action.payload };
      case QUOTE_ACTIONS.UPDATE_PAGE_NUM:
        return { ...state, pageNum: action.payload };
      case QUOTE_ACTIONS.UPDATE_TOTAL_PAGE:
        return { ...state, totalPages: action.payload };
      case QUOTE_ACTIONS.UPDATE_QUOTE_LIST:
        return { ...state, quoteList: action.payload };
      case QUOTE_ACTIONS.UPDATE_QUOTE_COUNT:
        return { ...state, quotationCount: action.payload };
      case QUOTE_ACTIONS.UPDATE_QUOTE_FILTER:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };

  const [quotationState, quotationDispatch] = useReducer(
    quotationReducer,
    initialQuoteState
  );

  // console.log("Quotation State :", quotationState);

  useEffect(() => {
    validateAdmin(token, navigate);
    if (token) {
      async function fetchCommonData() {
        getAdminUsers(token)
          .then((response) => setAdminUsers(response))
          .catch(toast.error);
        getAllUsers(token)
          .then((response) => setAllCustomerUsers(response))
          .catch(toast.error);
        getCompanies(token)
          .then((companyResponse) => setAllCompanies(companyResponse))
          .catch(toast.error);
      }
      fetchCommonData();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let data = {
          createdFrom: quotationState.createdFrom,
          createdTo: quotationState.createdTo,
          userName: selectedCustomerForFilter.size
            ? [...selectedCustomerForFilter].join(",")
            : "",
          companyFilter: selectedCompanies.size
            ? [...selectedCompanies].join(",")
            : "",
          adminOwnerId: quotationState.adminOwnerId,
          sortBy: quotationState.sortBy,
          sortOrder: quotationState.sortOrder,
          pageNum: quotationState.pageNum,
          quotationFilter: state.quoteActiveFilter,
        };
        const response = await fetchFilteredQuotes(token, data);

        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_QUOTE_LIST,
          payload: response?.data?.quotations,
        });
        let quotationBucketCount = response?.data?.quoteCategoryCount;
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_QUOTE_COUNT,
          payload: quotationBucketCount,
        });
        console.log(quotationBucketCount);
        let activeFilter = state.quoteActiveFilter;
        let count;

        if (activeFilter === "inreview") {
          count = quotationBucketCount.inReviewCount;
        } else if (activeFilter === "progress") {
          count = quotationBucketCount.progressCount;
        }
        else if (activeFilter == 'target-cost-requested') {
          count = quotationBucketCount?.targetCostRequested;
        } else if (activeFilter == 'target-cost-received') {
          count = quotationBucketCount?.targetCostReceived
        }
        else if (activeFilter === "sent") {
          count = quotationBucketCount.sentCount;
        } else if (activeFilter === "completed") {
          count = quotationBucketCount.completeCount;
        } else if (activeFilter === "cancelled") {
          count = quotationBucketCount.cancelledCount;
        } else {
          count = quotationBucketCount.allCount;
        }

        let totalPages = Math.ceil(Number(count) / 10);


        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_TOTAL_PAGE,
          payload: totalPages || 0,
        });
        setLoading(false);
      } catch (err) {
        if (err.message != "canceled") {
          toast.error(err.message);
          setLoading(false);
        }
        console.log("err", err);
      }
    };

    fetchData();
  }, [
    quotationState.createdFrom,
    quotationState.createdTo,
    selectedCustomerForFilter.size,
    selectedCompanies.size,
    quotationState.adminOwnerId,
    quotationState.sortBy,
    quotationState.sortOrder,
    quotationState.pageNum,
    state.quoteActiveFilter,
  ]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        userDropDownRef.current &&
        !userDropDownRef.current.contains(event.target)
      ) {
        setIsUserDropDownVisible(false);
      }

      if (
        companyDropDownRef.current &&
        !companyDropDownRef.current.contains(event.target)
      ) {
        setIsCompanyDropDownVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUserFocus = () => {
    setIsUserDropDownVisible(true);
  };

  const handleCompanyFocus = () => {
    setIsCompanyDropDownVisible(true);
  };

  const searchUser = (e) => {
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_USER_NAME,
      payload: e.target.value,
    });
    // setUserFilterValue(e.target.value);
    let localFilteredUser = [];

    if (e.target.value.length > 0) {
      localFilteredUser = allCustomerUsers.filter((item) =>
        (item.firstName + " " + item.lastName)
          .toUpperCase()
          .includes(e.target.value.toUpperCase())
      );
    }
    if (localFilteredUser.length > 0) {
      setIsUserDropDownVisible(true);
    }
    setFilteredCustomerUser(localFilteredUser);
  };

  const searchCompany = (e) => {
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_COMPANY_NAME,
      payload: e.target.value,
    });
    let localFilteredCompany = [];

    if (e.target.value.length > 0) {
      localFilteredCompany = allCompanies.filter((item) =>
        item.companyName.toUpperCase().includes(e.target.value.toUpperCase())
      );
    }
    if (localFilteredCompany.length > 0) {
      setIsCompanyDropDownVisible(true);
    }
    setFilteredCompanies(localFilteredCompany);
  };

  const handleCustomerFilterChange = (action, item) => {
    try {
      let customerId = item;
      if (!customerId) {
        return;
      }

      let localSelectedCustomerForFilter = selectedCustomerForFilter;
      quotationDispatch({
        type: QUOTE_ACTIONS.UPDATE_USER_NAME,
        payload: "",
      });
      setFilteredCustomerUser([]);

      if (action == "push") {
        localSelectedCustomerForFilter.add(customerId);
      } else if (action == "pop") {
        localSelectedCustomerForFilter.delete(customerId);
      }
      dispatch({
        type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
        payload: "all-quote",
      });
      setSelectedCustomerForFilter(localSelectedCustomerForFilter);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleCompanyFilterChange = (action, item) => {
    try {
      let companyId = item;
      if (!companyId) {
        return;
      }

      let localSelectedCompanies = selectedCompanies;
      quotationDispatch({
        type: QUOTE_ACTIONS.UPDATE_COMPANY_NAME,
        payload: "",
      });
      setFilteredCompanies([]);

      if (action == "push") {
        localSelectedCompanies.add(companyId);
      } else if (action == "pop") {
        localSelectedCompanies.delete(companyId);
      }
      dispatch({
        type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
        payload: "all-quote",
      });
      setSelectedCompanies(localSelectedCompanies);
    } catch (err) {
      console.log("err", err);
    }
  };

  const showDrawer = (id) => {
    setSelectedChatRefId(id);
  };
  const onClose = () => {
    setSelectedChatRefId(null);
  };

  const handleAdminOwnerChange = async (e, quoteId) => {
    try {
      let adminUserSelected = adminUsers.filter(
        (item) => item._id == e.target.value
      )[0];
      let adminResponse = confirm(
        `Are you sure you want to assign this quotation to ${adminUserSelected.firstName + " " + adminUserSelected.lastName
        } ? `
      );
      if (!adminResponse) {
        return;
      }
      let data = {
        quoteId: quoteId,
        adminId: e.target.value,
      };
      let quoteResponse = await changeQuoteAdminOwner(token, data);
      if (quoteResponse.status) {
        let localQuotationList = [...quotationState.quoteList];
        localQuotationList.forEach((item) => {
          if (item._id == quoteId) {
            item.adminOwnerId = adminUserSelected._id;
          }
        });
        quotationDispatch({
          type: QUOTE_ACTIONS.UPDATE_QUOTE_LIST,
          payload: localQuotationList,
        });
        toast.success("Admin owner changed successfully.");
      }
    } catch (err) {
      console.log(
        "Error in 86 Quotes on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  let handleActivePage = async (value) => {
    let pageNum = value?.selected + 1 || 1;
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_PAGE_NUM,
      payload: pageNum,
    });
  };

  const handleBucketFilterClick = (field) => {
    dispatch({
      type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
      payload: field,
    });

    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_QUOTE_FILTER,
      payload: {
        createdFrom: "",
        createdTo: "",
        userName: "",
        adminOwnerId: "",
        sortBy: "",
        sortOrder: 1,
        pageNum: 1,
        totalPages: 0,
      },
    });
  };

  let handleSort = (field) => {
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_SORT_BY,
      payload: field,
    });

    let sortStatus =
      quotationState.sortBy == field
        ? quotationState.sortOrder == 1
          ? -1
          : 1
        : 1;
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_SORT_ORDER,
      payload: sortStatus,
    });
  };

  const handleQuoteSearch = async (e) => {
    try {
      setDirectFilterVal(e.target.value);
      if (!e.target.value) {
        setDirectQuoteResult([]);
        return;
      }

      let response = await fetchQuotesForRefIdSearch(token, e.target.value);
      if (response.status) {
        setDirectQuoteResult(response.data);
      } else {
        setDirectQuoteResult([]);
        toast.error("Something went wrong while fetching the data.");
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleRefresh = () => {
    quotationDispatch({
      type: QUOTE_ACTIONS.UPDATE_QUOTE_FILTER,
      payload: {
        createdFrom: "",
        createdTo: "",
        userName: "",
        adminOwnerId: "",
        sortBy: "createdAt",
        sortOrder: -1,
        pageNum: 1,
        totalPages: 0,
      },
    });
    dispatch({
      type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
      payload: "all-quote",
    });
    setSelectedCustomerForFilter(new Set());
    setSelectedCompanies(new Set());
  };

  return (
    <div className="admin-container">
      <Sidebar />
      <div style={{ height: "auto" }}>{loading ? <Loading /> : ""}</div>
      <span style={{ width: "100%" }}>
        <div className="center-heading">
          <h1 className="text-center text-2xl my-2 font-bold">Quotations</h1>
          <div className="right-container ">
            <div className="bucketFilterDiv">
              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "all-quote" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("all-quote")}
              >
                <span className="textBox">
                  <p>All Quote</p>
                  <h5>{quotationState?.quotationCount?.allCount}</h5>
                </span>
                <img className="filterIcon" src={allValuesImage} />
              </div>

              {/* <div
                className={`bucketFilterOptions ${
                  state.quoteActiveFilter == "inreview" ? "selected" : ""
                }`}
                onClick={() => handleBucketFilterClick("progress")}
              >
                <span className="textBox">
                  <p>In Review</p>
                  <h5>{quotationState?.quotationCount?.inReviewCount}</h5>
                </span>
                <img className="filterIcon" src={waitingImage} />
              </div> */}

              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "progress" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("progress")}
              >
                <span className="textBox">
                  <p>Draft</p>
                  <h5>{quotationState?.quotationCount?.progressCount}</h5>
                </span>
                <img className="filterIcon" src={settingImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "sent" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("sent")}
              >
                <span className="textBox">
                  <p>Ready To Order</p>
                  <h5>{quotationState?.quotationCount?.sentCount}</h5>
                </span>
                <img className="filterIcon" src={sendImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "target-cost-requested" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("target-cost-requested")}
              >
                <span className="textBox">
                  <p>Target Cost Requested</p>
                  <h5>{quotationState?.quotationCount?.targetCostRequested}</h5>
                </span>
                {/* <img className="filterIcon" src={sendImage} /> */}
                <SubmitedTargetCost
                  additionalClasses={'filterIcon'}
                />
              </div>
              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "target-cost-received" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("target-cost-received")}
              >
                <span className="textBox">
                  <p>Target Cost Recieved</p>
                  <h5>{quotationState?.quotationCount?.targetCostReceived}</h5>
                </span>
                <ReviewTargetCostIcon
                  additionalClasses={'filterIcon'}
                />
              </div>

              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "completed" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("completed")}
              >
                <span className="textBox">
                  <p>Completed</p>
                  <h5>{quotationState?.quotationCount?.completeCount}</h5>
                </span>
                <img className="filterIcon" src={tickGreenImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.quoteActiveFilter == "cancelled" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("cancelled")}
              >
                <span className="textBox">
                  <p>Cancelled</p>
                  <h5>{quotationState?.quotationCount?.cancelledCount}</h5>
                </span>
                <img className="filterIcon" src={cancelledImage} />
              </div>
              <button
                onClick={() => navigate("/project-name")}
                className="pr-edit-btn"
              >
                Create New Quotation
              </button>
            </div>

            <>
              <div className="search-bar">
                <div
                  className="row"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                  }}
                >
                  <div className="search-block">
                    <label className="sr-label">Admin Owner</label>
                    <Form.Select
                      aria-label="Default select example"
                      value={quotationState?.adminOwnerId || "default"}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        dispatch({
                          type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
                          payload: "all-quote",
                        });
                        quotationDispatch({
                          type: QUOTE_ACTIONS.UPDATE_ADMIN_OWNER_ID,
                          payload: e.target.value,
                        });
                      }}
                    >
                      <option value="default" disabled>
                        {" "}
                        Select owner
                      </option>
                      {adminUsers.map((val, i) => {
                        return (
                          <option key={i} value={val._id}>
                            {val.firstName + " " + val.lastName}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                  <div className="search-block">
                    <label className="sr-label">Ref ID</label>
                    <input
                      type="text"
                      className="form-control"
                      value={directFilterVal}
                      placeholder="Search Ref ID"
                      onChange={handleQuoteSearch}
                    />
                    {directFilterVal?.trim()?.length > 0 &&
                      directQuoteResult.length > 0 ? (
                      <div className="direct-search-drop-down-div ">
                        <table className="search-table">
                          <thead>
                            <tr>
                              <th>Ref ID</th>
                              <th>Created Date</th>
                              <th>User Name</th>
                              <th>Cost 1</th>
                              <th>Cost 2</th>
                              <th>Cost 3</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {directQuoteResult.length > 0 &&
                              directQuoteResult?.map((val, i) => {
                                return (
                                  <tr
                                    key={i}
                                    className="direct-search-drop-down"
                                    onClick={() =>
                                      window.open(
                                        `/admin/admin-quote/${val?._id}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    <td style={{ textAlign: "left" }}>
                                      {val?.RefId}
                                    </td>
                                    <td>
                                      {moment(val.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {val.userId?.firstName +
                                        " " +
                                        val.userId?.lastName}
                                    </td>
                                    <td>
                                      {currencyFormatter(
                                        val?.price ? val?.price?.toFixed(2) : 0
                                      )}
                                    </td>
                                    <td>
                                      {currencyFormatter(
                                        val?.price1
                                          ? val?.price1?.toFixed(2)
                                          : 0
                                      )}
                                    </td>
                                    <td>
                                      {val.selectedProcess >= 3
                                        ? `${currencyFormatter(
                                          val.sheetAndInjectionManualPrice
                                            ? val.sheetAndInjectionManualPrice?.toFixed(
                                              2
                                            )
                                            : 0
                                        )}`
                                        : val.selectedProcess == 1 ||
                                          val.selectedProcess == 2
                                          ? `${currencyFormatter(
                                            val.stManualPrice
                                              ? val.stManualPrice?.toFixed(2)
                                              : 0
                                          )}`
                                          : `$${0}`}
                                    </td>
                                    <td>
                                      {!val.myorders &&
                                        moment(Date.now()).diff(val.createdAt) /
                                        (1000 * 3600 * 24) <=
                                        45 &&
                                        !val.isCancelled &&
                                        (val.isUpdatedByAdmin ||
                                          !(
                                            val.price == 0 &&
                                            val.price1 == 0 &&
                                            val.stManualPrice == 0 &&
                                            val.sheetAndInjectionManualPrice == 0
                                          ))
                                        ? val.isSent
                                          ? "Sent"
                                          : "In Progress"
                                        : val.myorders && !val.isCancelled
                                          ? "Completed"
                                          : (moment(Date.now()).diff(
                                            val.createdAt
                                          ) /
                                            (1000 * 3600 * 24) >
                                            45 &&
                                            !val.myorders) ||
                                            val.isCancelled
                                            ? "Cancelled"
                                            : "In Review"}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="search-block">
                    <label className="sr-label">User Filter</label>
                    <div className="filter-item">
                      <div className="share-quote-div">
                        <input
                          type="text"
                          className="form-control"
                          value={quotationState.userName}
                          placeholder={`${selectedCustomerForFilter.size} users selected`}
                          onChange={(e) => searchUser(e)}
                          onFocus={handleUserFocus}
                        />

                        {isUserDropDownVisible ? (
                          <div
                            className="follower-drop-down-div"
                            style={{
                              width: "200px",
                              border: "1px solid #ced4da",
                              borderRadius: "5px",
                              // padding: ".375rem .75rem",
                              maxHeight: "200px",
                              overflowY: "auto",
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                            ref={userDropDownRef}
                          >
                            {filteredCustomerUser.length > 0
                              ? filteredCustomerUser.map((item, i) => {
                                return (
                                  <div
                                    key={i + "fil"}
                                    className="follower-drop-down"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      margin: "2px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"sign-check" + i + "fil"}
                                      name="checkbox"
                                      value={item._id}
                                      checked={selectedCustomerForFilter.has(
                                        item._id
                                      )}
                                      onClick={() =>
                                        handleCustomerFilterChange(
                                          selectedCustomerForFilter.has(
                                            item._id
                                          )
                                            ? "pop"
                                            : "push",
                                          item._id
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={"sign-check" + i + "file"}
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                      onClick={() =>
                                        handleCustomerFilterChange(
                                          selectedCustomerForFilter.has(
                                            item._id
                                          )
                                            ? "pop"
                                            : "push",
                                          item._id
                                        )
                                      }
                                    >
                                      {item.firstName + " " + item.lastName}
                                    </label>
                                  </div>
                                );
                              })
                              : allCustomerUsers.length > 0 &&
                              allCustomerUsers.map((item, i) => {
                                return (
                                  <div
                                    key={i + "all"}
                                    className="follower-drop-down"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      margin: "2px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"sign-check" + i + "all"}
                                      name="checkbox"
                                      value={item._id}
                                      checked={selectedCustomerForFilter.has(
                                        item._id
                                      )}
                                      onClick={() =>
                                        handleCustomerFilterChange(
                                          selectedCustomerForFilter.has(
                                            item._id
                                          )
                                            ? "pop"
                                            : "push",
                                          item._id
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={"sign-check" + i + "all"}
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {item.firstName + " " + item.lastName}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="search-block">
                    <label className="sr-label">Company Filter</label>
                    <div className="filter-item">
                      <div className="share-quote-div">
                        <input
                          type="text"
                          className="form-control"
                          value={quotationState.companyName}
                          placeholder={`${selectedCompanies.size} companies selected`}
                          onChange={(e) => searchCompany(e)}
                          onFocus={handleCompanyFocus}
                        />

                        {isCompanyDropDownVisible ? (
                          <div
                            className="follower-drop-down-div"
                            style={{
                              width: "200px",
                              border: "1px solid #ced4da",
                              borderRadius: "5px",
                              // padding: ".375rem .75rem",
                              maxHeight: "200px",
                              overflowY: "auto",
                              fontSize: "12px",
                              textAlign: "left",
                            }}
                            ref={companyDropDownRef}
                          >
                            {filteredCompanies.length > 0
                              ? filteredCompanies.map((item, i) => {
                                return (
                                  <div
                                    key={i + "fil-com"}
                                    className="follower-drop-down"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      margin: "2px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"sign-check" + i + "fil-com"}
                                      name="checkbox"
                                      value={item._id}
                                      checked={selectedCompanies.has(
                                        item._id
                                      )}
                                      onClick={() =>
                                        handleCompanyFilterChange(
                                          selectedCompanies.has(item._id)
                                            ? "pop"
                                            : "push",
                                          item._id
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={"sign-check" + i + "fil-com"}
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {item.companyName}
                                    </label>
                                  </div>
                                );
                              })
                              : allCompanies?.length > 0 &&
                              allCompanies.map((item, i) => {
                                return (
                                  <div
                                    key={i + "all-com"}
                                    className="follower-drop-down"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      margin: "2px",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"sign-check" + i + "all-com"}
                                      name="checkbox"
                                      value={item._id}
                                      checked={selectedCompanies.has(
                                        item._id
                                      )}
                                      onClick={() =>
                                        handleCompanyFilterChange(
                                          selectedCompanies.has(item._id)
                                            ? "pop"
                                            : "push",
                                          item._id
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={"sign-check" + i + "all"}
                                      style={{
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                      onClick={() =>
                                        handleCompanyFilterChange(
                                          selectedCompanies.has(item._id)
                                            ? "pop"
                                            : "push",
                                          item._id
                                        )
                                      }
                                    >
                                      {item.companyName}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="search-block">
                    <label className="sr-label">Quotation Created From</label>
                    <input
                      type="date"
                      value={quotationState.createdFrom}
                      className="form-control"
                      onChange={(e) => {
                        dispatch({
                          type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
                          payload: "all-quote",
                        });
                        quotationDispatch({
                          type: QUOTE_ACTIONS.UPDATE_CREATED_FROM,
                          payload: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="search-block">
                    <label className="sr-label">Quotation Created To</label>
                    <input
                      type="date"
                      value={quotationState.createdTo}
                      className="form-control"
                      onChange={(e) => {
                        dispatch({
                          type: ADMIN_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER,
                          payload: "all-quote",
                        });
                        quotationDispatch({
                          type: QUOTE_ACTIONS.UPDATE_CREATED_TO,
                          payload: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className="search-btn" style={{ marginTop: "10px" }}>
                    <button onClick={handleRefresh} className="button-search">
                      Reset all filters
                    </button>
                  </div>
                </div>
              </div>
              <Chat
                RefId={selectedChatRefId}
                open={selectedChatRefId?.length > 0}
                onDrawerClose={onClose}
              />
              <div className="quotes-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("projectName")}
                      >
                        Project Name{" "}
                        {quotationState.sortBy == "projectName"
                          ? quotationState.sortOrder == 1
                            ? "↓"
                            : "↑"
                          : "↓"}{" "}
                      </th>
                      <th scope="col" style={{ cursor: "pointer" }}>
                        {" "}
                        Admin Owner{" "}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("createdAt")}
                      >
                        Quote Created at{" "}
                        {quotationState.sortBy == "createdAt"
                          ? quotationState.sortOrder == 1
                            ? "↓"
                            : "↑"
                          : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("userId.firstName")}
                      >
                        User Name{" "}
                        {quotationState.sortBy == "userId.firstName"
                          ? quotationState.sortOrder == 1
                            ? "↓"
                            : "↑"
                          : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("userId.companyName")}
                      >
                        Company Name{" "}
                        {quotationState.sortBy == "userId.companyName"
                          ? quotationState.sortOrder == 1
                            ? "↓"
                            : "↑"
                          : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("price")}
                      >
                        Cost 1{" "}
                        {quotationState.sortBy == "price"
                          ? quotationState.sortOrder == 1
                            ? "↓"
                            : "↑"
                          : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("price1")}
                      >
                        Cost 2{" "}
                        {quotationState.sortBy == "price1"
                          ? quotationState.sortOrder == 1
                            ? "↓"
                            : "↑"
                          : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("price2")}
                      >
                        Cost 3{" "}
                        {quotationState.sortBy == "price2"
                          ? quotationState.sortOrder == 1
                            ? "↓"
                            : "↑"
                          : "↓"}
                      </th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quotationState.quoteList?.length > 0 &&
                      quotationState.quoteList?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                width: "150px",
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                                whiteSpace: "normal",
                              }}
                            >
                              <div className="flex  items-center gap-x-2">
                                <Link className="!text-sm text-primaryColor " to={`/admin/admin-quote/${item?._id}`}>
                                  {item.projectName
                                    ? (item.versionDescription
                                      ? item.versionDescription + "-"
                                      : "") + item.projectName.slice(0, 30) + '...'
                                    : "Project name not available"}
                                </Link>
                                <HoverInfo content={item.adminComment} />
                              </div>
                              <span className="text-primaryColor">
                                {state.quoteActiveFilter == "all-quote"
                                  ? item?.quotationStatus
                                    ? `(${item?.quotationStatus})`
                                    : ""
                                  : ""}
                              </span>
                            </td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                value={item?.adminOwnerId || "default"}
                                style={{ width: "100%", marginTop: "-10px" }}
                                onChange={(e) =>
                                  handleAdminOwnerChange(e, item._id)
                                }
                              >
                                <option value="default" disabled>
                                  {" "}
                                  Select owner
                                </option>
                                {adminUsers.map((val, i) => {
                                  return (
                                    <option key={i} value={val._id}>
                                      {val.firstName + " " + val.lastName}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </td>
                            <td>
                              {moment(item.createdAt).format("DD MMM YYYY")}
                            </td>
                            <td>
                              {item.userId?.firstName +
                                " " +
                                item.userId?.lastName}
                              <br />
                              {item?.userId
                                ? `(${item?.userId?.email?.split("@")[0]})`
                                : ""}
                            </td>
                            <td>{item.userId?.companySiteId?.companyName}</td>

                            <td>
                              <span>
                                <p>
                                  {currencyFormatter(
                                    item?.price?.toFixed(2) || 0
                                  )}
                                </p>
                                <p>
                                  {item?.leadTime1
                                    ? item.leadTime1 +
                                    " Days" +
                                    `(${Math.floor(item?.leadTime1 / 7)}-${Math.floor(item?.leadTime1 / 7) + 1
                                    } weeks)`
                                    : "NA"}{" "}
                                </p>
                              </span>
                            </td>

                            <td>
                              <span>
                                <p>
                                  {currencyFormatter(
                                    item?.price1?.toFixed(2) || 0
                                  )}
                                </p>
                                <p>
                                  {item.leadTime2
                                    ? item.leadTime2 +
                                    " Days " +
                                    `(${Math.floor(item?.leadTime2 / 7)}-${Math.floor(item?.leadTime2 / 7) + 1
                                    } weeks)`
                                    : "NA"}
                                </p>
                              </span>
                            </td>

                            <td>
                              <p>
                                {item.selectedProcess >= 3
                                  ? `${currencyFormatter(
                                    item.sheetAndInjectionManualPrice.toFixed(
                                      2
                                    ) || 0
                                  )}`
                                  : item.selectedProcess == 1 ||
                                    item.selectedProcess == 2
                                    ? `${currencyFormatter(
                                      item.stManualPrice.toFixed(2) || 0
                                    )}`
                                    : `$${0}`}
                              </p>
                              <p>
                                {item.leadTime3
                                  ? item.leadTime3 +
                                  " Days" +
                                  `(${Math.floor(item?.leadTime3 / 7)}-${Math.floor(item?.leadTime3 / 7) + 1
                                  } weeks)`
                                  : "NA"}
                              </p>
                            </td>

                            <td>
                              <div>
                                <button
                                  type="button"
                                  className="up-button h-7 w-max px-3 py-2"
                                // style={{
                                //   marginTop: "3px 0px",
                                //   background: "white",
                                //   color: "#2B96DC",
                                //   width: "100px",
                                //   border: "1px solid #a8e5ff",
                                // }}
                                >
                                  <ChatButton
                                    RefId={item.RefId}
                                    showDrawer={() => showDrawer(item.RefId)}
                                    additionalClass={' '}
                                  />
                                </button>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="floatRight">
                <Pagination
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  pageCount={quotationState?.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handleActivePage}
                  containerClassName="pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  activeClassName="active"
                />
              </div>
            </>
          </div>
        </div>
      </span>
    </div>
  );
};

export default Quotes;
