import { CREATE_QUOTE_ACTION } from './create.quote.context';

export const setProjectName = (newValue, createQuoteDispatch, createQuoteState) => {
    if (!createQuoteDispatch) {
        console.error('createQuoteDispatch is null');
        return;
    }

    createQuoteDispatch({
        type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
        payload: { ...createQuoteState?.commanValues, projectName: newValue }
    });
};


export const updateSelectedProcess = (newValue, createQuoteDispatch, createQuoteState) => {
    if (!createQuoteDispatch) {
        console.error('createQuoteDispatch is null');
        return;
    }

    createQuoteDispatch({
        type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
        payload: {
            ...createQuoteState?.commanValues, selectedProcess: newValue, price: '',
            price1: '',
            price2: '',
        }
    });
};

export const setSameForAllField = (newValue, createQuoteDispatch, createQuoteState) => {
    if (!createQuoteDispatch) {
        console.error('createQuoteDispatch is null');
        return;
    }
    createQuoteDispatch({
        type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
        payload: { ...createQuoteState?.commanValues, sameForAllField: newValue }
    });
};


export const setValues = (newValue, createQuoteDispatch, createQuoteState) => {
    if (!createQuoteDispatch) {
        console.error('createQuoteDispatch is null');
        return;
    }

    createQuoteDispatch({
        type: CREATE_QUOTE_ACTION.UPDATE_VALUES,
        payload: newValue
    });
}


export const setCommanValues = (newValue, createQuoteDispatch, createQuoteState) => {
    if (!createQuoteDispatch) {
        console.error('createQuoteDispatch is null');
        return;
    }

    createQuoteDispatch({
        type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
        payload: newValue
    });
}

export const resetCreateQuoteState = (selectedProcess, createQuoteDispatch, createQuoteState) => {
    if (!createQuoteDispatch) {
        console.error('createQuoteDispatch is null');
        return;
    }

    createQuoteDispatch({
        type: CREATE_QUOTE_ACTION.RESET_CREATE_QUOTE_STATE,
        payload: selectedProcess
    });
}