import { useEffect, useState } from "react";
import { useUserContext } from "../../../../context/user.context";
import { firebaseSignOutOnLogOut } from "../../../../utils/firebase";
import { CREATE_QUOTE_ACTION, useCreateQuoteContext } from "../../../../context/create.quote.context";

const TermsOfUseModal = ({ onProceed, onCancel, email, setEmail, setIsUserLoggOut, isUserLoggOut }) => {

  const isUserLoggedIn = localStorage.getItem('isLoggedIn') ? true : false;
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();


  const handleLogout = () => {

    localStorage.removeItem("Token");
    localStorage.removeItem("_id");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("email");
    localStorage.removeItem("pathAfterLogin");
    firebaseSignOutOnLogOut();
    setIsUserLoggOut(true);
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.RESET_CREATE_QUOTE_STATE,
      payload: 2,
    })
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 sm:w-1/2">
        <h2 className="text-lg font-semibold mb-4">Terms of Use</h2>
        <p className="text-sm text-gray-700 mb-6">
          Please note: Uploading files such as weapons, weapon components, or
          items regulated under ITAR, EAR, or EU Dual-Use regulations is
          strictly prohibited. By continuing, you acknowledge and agree to
          comply with these terms.
        </p>
        <div className="my-4">
          <label
            htmlFor="email"
            className="block  mb-2 text-sm font-medium text-gray-900 "
          >
            Email
          </label>
          <div className="flex ">
            <input
              type="email"
              disabled={isUserLoggedIn}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${isUserLoggedIn && 'bg-slate-200'}`}
              placeholder="John@gmail.com"
              required
            />
            {isUserLoggedIn ? <div onClick={handleLogout} className="mx-2 px-2 bg-slate-200 text-primaryColor flex items-center justify-center">
              Logout
            </div> : null}
          </div>

        </div>
        <div className="flex justify-between">
          <button
            onClick={onProceed}
            className="bg-primaryColor text-white px-4 py-2 rounded-md hover:bg-newPrimaryColor transition"
          >
            Acknowledge and Proceed
          </button>
          <button
            onClick={onCancel}
            className="border border-blue-500 text-primaryColor px-4 py-2 rounded-md transition"
          >
            Cancel, my files may contain restricted items
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUseModal;
