import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/header";
import jwt_decode from "jwt-decode";
import useDocumentTitle from "../../utils/useDocumentTitle";
import { useCreateQuoteContext } from "../../context/create.quote.context";

const OptionDashboard = () => {
  let token = localStorage.getItem("Token");
  useDocumentTitle("8xParts | A reliable manufacturing platform");
  let userName = localStorage.getItem("firstName");
  const loggedInUser = localStorage.getItem("Token");
  let {createQuoteState}  = useCreateQuoteContext();
  let projectName = createQuoteState?.commanValues?.projectName;

  const navigate = useNavigate();

  useEffect(() => {
    let decode;
    if (token) {
      decode = jwt_decode(token);
      if (!decode) {
        navigate("/login");
        toast.error("Invalid Token found!");
      }
    } else {
      navigate("/login");
    if(!projectName){
      toast.error("Project name not found.")
      navigate('/project-name')
    }}
  }, []);


  return (
    <div>
      {loggedInUser ? (
        <div>
          <Header activeTab="/" />

          <div className="px-2 py-3">
            {/* <div>
              <div className="my-2 bg-white px-24 py-4 rounded mx-2 ">
                <h3 className="md:text-xl font-bold ">
                  Hey {userName || ""} Welcome !
                </h3>
                <p className="text-slate-500 text-[12px] md:text-[15px] ">
                  The power of digital manufacturing at your fingertips
                </p>
              </div>
            </div> */}
            <div className="bg-white my-2 mx-2 px-10 md:px-24 py-3 ">
              <p className=" text-xl lg:text-2xl my-9 font-medium ">
                Tell us what are you looking to get, and we will take it from
                there.
              </p>
              <div className="flex justify-center mb-10 flex-col md:flex-row gap-x-10 items-center px-5 w-full   ">
                <Link
                  to="/dashboard/option-one"
                  state={{ option: 1, projectName: projectName }}
                  className="h-64 bg-primaryColor p-3 md:p-5 w-80 md:w-96 border rounded my-2 text-white hover:scale-110 transition-transform  hover:shadow-xl"
                >
                  <h3 className="font-bold text-2xl text-center my-2">
                    I have 3D Models
                  </h3>
                  <ul className="text-sm list-disc ps-4 text-[16px]  lg:leading-normal">
                    <li>Instant Quotation</li>
                    <li>Ideal for CNC and 3D printed parts</li>
                    <li>Supported formats : stp, step, stl,etc.</li>
                  </ul>
                </Link>
                <Link
                  to="/get-instant-quote"
                  state={{ option: 2, projectName: projectName }}
                  className="h-64 bg-primaryColor p-3 md:p-5   w-80 md:w-96 border rounded my-2 text-white hover:scale-110 transition-transform  hover:shadow-xl"
                >
                  <h3 className="font-bold text-2xl text-center my-2">
                    I have drawings
                  </h3>
                  <ul className="text-sm list-disc ps-4 text-[16px]  lg:leading-normal">
                    <li>Quotation within 12-24 hours</li>
                    <li>
                      Ideal for CNC, 3D printing, Sheet metal parts, Assemblies,
                      bought out parts, injection molding and everyrhing else{" "}
                    </li>
                    <li>
                      Supported formats : zip files, pdf, dfg, dwg, stp, step,
                      iges, stl, etc.
                    </li>
                  </ul>
                </Link>
              </div>
            </div>
            <div className="bg-white px-4 my-3 text-sm  py-4 rounded-xl text-slate-500 shadow-sm ">
              <strong>Privacy:</strong> 8xParts 3D secures all uploaded files,
              protecting your intellectual property. If you can’t upload your
              files at the moment, use our sample part to try out our instant
              quoting and DfM tools or contact us on{" "}
              <a href="mailto: info@8xparts.com">info@8xparts.com</a> to sign an
              NDA.
            </div>
          </div>
        </div>
      ) : (
        <p>Please Login First </p>
      )}
    </div>
  );
};
export default OptionDashboard;
