import React, {
  useCallback,
  useContext,
} from "react";

import Tooltip from "@material-ui/core/Tooltip";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

import {useDropzone} from "react-dropzone";

import { Context } from "./Context";

import MyToggleButton from "./MyToggleButton";

const loadFile = (file, dispatch) => {
  const ext = file.name.split(".").pop().toUpperCase();
  let type = "UNKNOWN";
  if(ext === "STP" || ext === "STEP") {
    type = "STEP";
  }
  if(ext === "IGS" || ext === "IGES") {
    type = "IGES";
  }
  if(ext === "STL") {
    type = "STL";
  }
  if(ext === "OBJ") {
    type = "OBJ";
  }
  return new Promise((resolve, reject) => {
    if(type === "UNKNOWN") {
      window.alert("Only STP/STEP/IGS/IGES/STL/OBJ files are supported");
      reject();
      return;
    }
    var reader = new FileReader();
    reader.addEventListener('load', fileEvent => {
      dispatch({
        type: "push-file",
        payload: {
          properties: {
            name: file.name,
            type,
            content: fileEvent.target.result,
            analysisProgress: 0,
          }
        }
      });
      resolve();
    });
    reader.readAsArrayBuffer(file);
  });
}

const loadFiles = (files, state, dispatch) => {
  const filePromises = [];
  files.forEach(file => {
    filePromises.push(loadFile(file, dispatch));
  });
  Promise.all(filePromises).then(() => {
    dispatch({type: "set-activeFileIndex", payload: state.files.length+filePromises.length-1});
  });
}

export default function LoadFileButton (props) {
  const {state, dispatch} = useContext(Context);

  return (
    <Tooltip
      title={"open CAD file"}
      style={{
        pointerEvents: "all",
        position: "relative",
        ...props.style
      }}
    >
      <MyToggleButton
        value="enabled"
      >
        <input
          type="file"
          accept=".stp,.step,.igs,.iges,.stl,.obj"
          multiple
          onChange={e => {
            loadFiles(Array.from(e.target.files), state, dispatch);
          }}
          onClick={e => { 
            e.target.value = null
          }}
          style={{
            opacity: 0,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            cursor: "pointer",
            fontSize: 0,
          }}
        />
        <FolderOpenIcon />
      </MyToggleButton>
    </Tooltip>
  );
}

export function LoadFileDropZone({children}) {
  const {state, dispatch} = useContext(Context);

  const onDrop = useCallback(async files => {
    loadFiles(files, state, dispatch);
  }, [state, dispatch])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div
      {...getRootProps({
        onClick: event => event.stopPropagation()
      })}
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <input {...getInputProps()} />
      {children}
      <div
        style={{
          position: "absolute",
          pointerEvents: "none",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: isDragActive ? "rgba(255, 255, 255, 0.5)" : "unset",
        }}
      >
      </div>
    </div>
  );
}
