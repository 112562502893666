import "./App.css";
import "./common.scss";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Login from "./routes/Login/login";
import StepThree from "./routes/OrderSummary/iq-stepthree";
import Payments from "./routes/Payments/payments";
import MyOrders from "./routes/MyOrders/MyOrders";
import Myquotes from "./routes/MyQuotes/MyQuotes";
import OrderDetails from "./routes/OrderDetails/order-details";
import Profile from "./routes/Profile/profile";
import NewPassword from "./routes/SendNewPassword/sendnewpassword";
import Privacy from "./routes/Privacy/Privacy";
import Terms from "./routes/Terms/Terms";
import { useEffect } from "react";
import Quotes from "./Admin/Orders/Quotes";
import AdminQuote from "./Admin/Orders/AdminQuote";
import AdminDashboard from "./Admin/AdminDashboard";
import Order from "./Admin/Orders/Order";
import InstantQuote from "./routes/InstantQuote";
import AdminOrderDetails from "./Admin/Orders/orderDetailsAdmin";
import jwt_decode from "jwt-decode";
import PrivateRoute from "./authRoutes/Routes";
import AdminPrivateRoute from "./authRoutes/adminRoute";
import CompanyMaster from "./Admin/Users/CompanyMaster";
import UserMaster from "./Admin/Users/UserMaster";
import EditCompany from "./Admin/Users/EditCompany";
import AddCompany from "./Admin/Users/AddCompany";
import AddUser from "./Admin/Users/AddUser";
import EditUser from "./Admin/Users/EditUser";
import AdminUsers from "./Admin/Users/AdminUsers";
import LayerThicknessMaster from "./Admin/Masters/LayerThicknessMaster";
import MaterialMaster from "./Admin/Masters/MaterialMaster";
import SubMaterialMaster from "./Admin/Masters/SubMaterialMaster";
import PartMakingMaster from "./Admin/Masters/PartMakingMaster";
import SurfaceFinishMaster from "./Admin/Masters/SurfaceFinishMaster";
import SurfaceTreatmentMaster from "./Admin/Masters/SurfaceTreatmentMaster";
import ToleranceMaster from "./Admin/Masters/ToleranceMaster";
import AddLayerThicknessMaster from "./Admin/Masters/AddMasters/AddLayerThicknessMaster";
import EditLayerThicknessMaster from "./Admin/Masters/EditMasters/EditLayerThicknessMaster";
import AddMaterialMaster from "./Admin/Masters/AddMasters/AddMaterialMaster";
import EditMaterialMaster from "./Admin/Masters/EditMasters/EditMaterialMaster";
import AddSubmaterialMaster from "./Admin/Masters/AddMasters/AddSubmaterialMaster";
import EditSubmaterialMaster from "./Admin/Masters/EditMasters/EditSubmaterialMaster";
import AddPartMakingMaster from "./Admin/Masters/AddMasters/AddPartMakingMaster";
import EditPartMakingMaster from "./Admin/Masters/EditMasters/EditPartMakingMaster";
import AddSurfaceFinishMaster from "./Admin/Masters/AddMasters/AddSurfaceFinishMaster";
import AddSurfaceTreatmentMaster from "./Admin/Masters/AddMasters/AddSurfaceTreatmentMaster";
import EditSurfaceTreatmentMaster from "./Admin/Masters/EditMasters/EditSurfaceTreatmentMaster";
import AddToleranceMaster from "./Admin/Masters/AddMasters/AddToleranceMaster";
import EditToleranceMaster from "./Admin/Masters/EditMasters/EditToleranceMaster";
import EditSurfaceFinishMaster from "./Admin/Masters/EditMasters/EditSurfaceFinishMaster";
import EditAdminUser from "./Admin/Users/EditAdminUser";
import QuoteVersion from "./routes/QuoteVersions/QuoteVersion";
import PurchaseOrderIssue from "./Admin/Issues/PurchaseOrderIssue";
import QualityIssue from "./Admin/Issues/QualityIssue";
import OptionDashboard from "./routes/OptionDashboard/optionDashboard";
import "react-toggle/style.css";
import AddressModal from "./components/AddressModal/AddressModal";
import BulkPricing from "./Admin/BulkPricing";
import ViewOrderDetails from "./routes/ViewOrderDetails/viewOrderDetails";
import ProjectName from "./routes/ProjectName/projectName";
import qs from "query-string";
import Maintanence from "./routes/Maintanence";
import ManualQuote from "./routes/ManualQuote/manualQuote";
import OptionOneSelection from "./routes/OptionOneSelection/optionOneSelection";
import PurchaseOrderDetails from "./Admin/Orders/PurchaseOrderDetails";
import PurchaseOrders from "./Admin/Orders/PurchaseOrders";
import SupplierCompanyMaster from "./Admin/Users/SupplierCompanyMaster";
import SupplierUserMaster from "./Admin/Users/SupplierUserMaster";
import AddSupplierCompany from "./Admin/Users/AddSupplierCompany";
import AddSupplierUser from "./Admin/Users/AddSupplierUser";
import RFQs from "./Admin/Orders/RFQs";
import EightxAddressMaster from "./Admin/Masters/EightxAddressMaster";
import EmailTracking from "./Admin/Masters/EmailTracking";
import RFQDetails from "./Admin/Orders/RFQDetails";
import CSR from "./routes/CSR/CSR";
import { pathNameForPublicAccess } from "./utils/constant";
import Projects from "./routes/Projects/Projects";
import ProjectCategoryGrid from "./routes/Projects/ProjectCategoryGrid/ProjectCategoryGrid";
import ThreeDProjects from "./routes/Projects/ThreeDProjects/ThreeDProjects";
import CastingProjects from "./routes/Projects/CastingProjects/CastingProjects";
import CNCMachiningProjects from "./routes/Projects/CNCMachiningProjects/CNCMachiningProjects";
import PackagingProjects from "./routes/Projects/PackagingProjects/PackagingProjects";
import CompleteAssemblyProjects from "./routes/Projects/CompleteAssemblyProjects/CompleteAssemblyProjects";
import { USER_ACTIONS, useUserContext } from "./context/user.context";
import { getPredictedValueForPartToLoad } from "./utils/actions/commanActions";
import SupplierBillsMaster from "./Admin/InvoiceBill/SupplierBillsMaster";
import CustomerInvoiceMaster from "./Admin/InvoiceBill/CustomerInvoiceMaster";
import InvoiceDetails from "./Admin/InvoiceBill/InvoiceDetails";
import BillDetails from "./Admin/InvoiceBill/BillDetails";
import Dashboard from "./routes/Dashboard/dashboard";
import AdminDashboardNew from "./Admin/AdminDashboardNew";
import ShowAll3DProcess from "./Admin/3dComponent/3dProcess/ShowAll3DProcess";
import Upsert3DProcessComponent from "./Admin/3dComponent/Upsert3DProcessComponent";
import AdminTechNology from "./Admin/3dComponent/AdminTechNology";
import CreateInstantQuotation from "./routes/CreateInstantQuotation/CreateInstantQuotation";
import NotFoundComponent from "./routes/NotFoundComponent";
import Certificate from "./Admin/CertificateComponent/Certificate";
import HeatTreatMent from "./components/CncComponents/HeatTreatMent";
import SurfaceFinishComponent from "./Admin/CncComponent/SurfaceFinishComponent";
import PartMarkingComponent from "./Admin/CncComponent/PartMarkingComponent";
import ToleranceComponent from "./Admin/CncComponent/ToleranceComponent";
import InternalCorner from "./Admin/CncComponent/InternalCorner";
import ReviewDesignComponent from "./Admin/CncComponent/reviewDesignComponent";
import MaterialMasterComponent from "./Admin/CncComponent/MaterialMasterComponent";
import MaterialOptions from "./Admin/CncComponent/MaterialOptions";
import SelectSubMaterials from "./Admin/CncComponent/SelectSubMaterials";
import MachineMasterComponent from "./Admin/CncComponent/MachineMasterComponent";
import MachineOptions from "./Admin/CncComponent/MachineOptoins";
import MachineMaterialSelect from "./Admin/CncComponent/MachineMaterialSelect";
import CertificationsComponent from "./Admin/CncComponent/CertificationsComponent";
import ShippingRates from "./Admin/CncComponent/ShippingRates";

function App() {
  let loggedIn = localStorage.getItem("isLoggedIn");
  let token = localStorage.getItem("Token");
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useUserContext();

  const handleLogout = () => {
    localStorage.removeItem("Token");
    localStorage.removeItem("_id");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("isLoggedIn");
    navigate("/");
    return;
  };

  useEffect(() => {
    token = localStorage.getItem("Token");
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (!token) {
      if (!location.pathname.includes("verifyEmail")) {
        // handleLogout();
      }
    } else {
      let decode = jwt_decode(token);
      if (decode.exp * 1000 < Date.now()) {
        handleLogout();
      }
      if (decode.exp * 1000 - Date.now() < 1800) {
        // handleLogout();
      }
    }

    if (isLoggedIn != "true" && !location.pathname.includes("verifyEmail")) {
      // handleLogout();
    }

    const { search } = window.location;
    const param = qs.parse(search);
    if (param.redirected_url && token) {
      window.href = param.redirected_url + search;
    }

    if (
      pathNameForPublicAccess.includes(location.pathname) ||
      location.pathname.includes("/view-order-details/") ||
      location.pathname.includes("/projects")
    ) {
      navigate(location.pathname);
    }

    getPredictedValueForPartToLoad()
      .then(() => {
        console.log("ML API Loaded");
      })
      .catch((err) => {
        console.log("ML Error", err)
        console.log("Error on page load ML api call.");
      });
  }, []);

  useEffect(() => {
    console.log(
      window.location.host,
      "make.8xparts.com",
      window.location.host === "make.8xparts.com"
        ? "Gtm loaded"
        : "GTM not loaded"
    );
    if (window.location.host === "make.8xparts.com") {
      console.log("Load GTM here to start analytics using the new GTM script")
      // loadGTM();
    }
  }, []);

  const loadGTM = () => {
    const gtmScript = document.createElement("script");
    gtmScript.innerHTML = `
    Enter GTM script here
    `;
    document.head.appendChild(gtmScript);
  };

  return (
    <Routes>
      {/* {!loggedIn && !token && <Route path="/" element={<CreateInstantQuotation />}></Route>} */}

      <Route path="/" element={<CreateInstantQuotation />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/create-instant-quotation" element={<CreateInstantQuotation />} />
      <Route path="/create-instant-quotation/:quoteId" element={<CreateInstantQuotation />} />
      <Route path="/:id/verifyEmail" element={<NewPassword />}></Route>
      <Route path="/dashboard" element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>

      <Route path="/dashboard/option" element={<PrivateRoute />}>
        <Route path="/dashboard/option" element={<OptionDashboard />} />
      </Route>

      <Route path="/dashboard/option-one" element={<PrivateRoute />}>
        <Route path="/dashboard/option-one" element={<OptionOneSelection />} />
      </Route>

      <Route path="/project-name" element={<PrivateRoute />}>
        <Route path="/project-name" element={<ProjectName />} />
      </Route>

      <Route path="/modal" element={<AddressModal />} />

      <Route path="/get-instant-quote/checkout/:_id" element={<PrivateRoute />}>
        <Route
          path="/get-instant-quote/checkout/:_id"
          element={<StepThree />}
        />
      </Route>

      <Route path="/get-instant-quote" element={<PrivateRoute />}>
        <Route path="/get-instant-quote" element={<InstantQuote />} />
      </Route>

      <Route path="/get-instant-quote/:_id" element={<PrivateRoute />}>
        <Route path="/get-instant-quote/:_id" element={<InstantQuote />} />
      </Route>

      <Route path="/payments/:_id" element={<PrivateRoute />}>
        <Route path="/payments/:_id" element={<Payments />}></Route>
      </Route>

      <Route path="/my-orders" element={<PrivateRoute />}>
        <Route path="/my-orders" element={<MyOrders />}></Route>
      </Route>

      <Route path="/saved-quote" element={<PrivateRoute />}>
        <Route path="/saved-quote" element={<Myquotes />}></Route>
      </Route>

      <Route path="/quote-versions/:RefId" element={<PrivateRoute />}>
        <Route path="/quote-versions/:RefId" element={<QuoteVersion />}></Route>
      </Route>

      <Route path="/manual-quote/:_id" element={<PrivateRoute />}>
        <Route path="/manual-quote/:_id" element={<ManualQuote />} />
      </Route>

      <Route path="/order-details/:_id" element={<PrivateRoute />}>
        <Route path="/order-details/:_id" element={<OrderDetails />}></Route>
      </Route>

      <Route path="/profile" element={<PrivateRoute />}>
        <Route path="/profile" element={<Profile />}></Route>
      </Route>

      <Route path="/privacy-policy" element={<Privacy />}></Route>
      <Route path="/terms-of-service" element={<Terms />}></Route>
      <Route path="/Sustainability-CSR" element={<CSR />}></Route>
      <Route path="/view-order-details/:_id" element={<ViewOrderDetails />} />
      <Route path="/projects" element={<Projects />}>
        <Route index element={<ProjectCategoryGrid />} />
        <Route path="/projects/three-d-printing" element={<ThreeDProjects />} />
        <Route path="/projects/casting" element={<CastingProjects />} />
        <Route
          path="/projects/cnc-machining"
          element={<CNCMachiningProjects />}
        />
        <Route
          path="/projects/complete-assembly"
          element={<CompleteAssemblyProjects />}
        />
        <Route path="/projects/packaging" element={<PackagingProjects />} />
      </Route>

      {/* Admin routes */}

      <Route
        path="/admin/email-tracking"
        element={<AdminPrivateRoute title="Email Tracking" />}
      >
        <Route
          path="/admin/email-tracking"
          element={<EmailTracking title="Email Tracking" />}
        ></Route>
      </Route>

      <Route path="/admin/quotes" element={<AdminPrivateRoute />}>
        <Route path="/admin/quotes" element={<Quotes />}></Route>
      </Route>
      {/* =================Test-Route-Start========================== */}
      {/* <Route path="/admin/quotes" element={<AdminPrivateRoute />}>
        <Route path="/admin/quotes" element={<Quotes />}></Route>
      </Route> */}
      {/* =================Test-Route-End========================== */}

      <Route path="/admin/supplier-bills" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/supplier-bills"
          element={<SupplierBillsMaster />}
        ></Route>
      </Route>

      <Route path="/admin/customer-invoices" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/customer-invoices"
          element={<CustomerInvoiceMaster />}
        ></Route>
      </Route>
      {/* <Route path="/test" element={} /> */}

      <Route path="/admin/bill-details/:id" element={<AdminPrivateRoute />}>
        <Route path="/admin/bill-details/:id" element={<BillDetails />}></Route>
      </Route>

      <Route path="/admin/invoice-details/:id" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/invoice-details/:id"
          element={<InvoiceDetails />}
        ></Route>
      </Route>

      <Route path="/admin/rfqs" element={<AdminPrivateRoute />}>
        <Route path="/admin/rfqs" element={<RFQs />}></Route>
      </Route>

      <Route path="/admin/admin-quote/:_id" element={<AdminPrivateRoute />}>
        <Route path="/admin/admin-quote/:_id" element={<AdminQuote />}></Route>
      </Route>

      <Route path="/admin/rfq-details/:_id" element={<AdminPrivateRoute />}>
        <Route path="/admin/rfq-details/:_id" element={<RFQDetails />}></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin" element={<AdminDashboardNew />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/certificate" element={<Certificate />}></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/company-master" element={<CompanyMaster />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/bulk-pricing" element={<BulkPricing />}></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/eightx-address"
          element={<EightxAddressMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/edit-company" element={<EditCompany />}></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/add-company" element={<AddCompany />}></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/add-user" element={<AddUser />}></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/edit-user" element={<EditUser />}></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/user-master" element={<UserMaster />}></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/admin-users" element={<AdminUsers />}></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/edit-admin-user"
          element={<EditAdminUser />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/purchase-orders"
          element={<PurchaseOrders />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/supplier-company-master"
          element={<SupplierCompanyMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/supplier-user-master"
          element={<SupplierUserMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/purchase-order-details/:_id"
          element={<PurchaseOrderDetails />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/add-supplier-user"
          element={<AddSupplierUser />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/edit-supplier-user/:_id"
          element={<AddSupplierUser />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/add-supplier-company"
          element={<AddSupplierCompany />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/edit-supplier-company/:_id"
          element={<AddSupplierCompany />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/product-related-issues"
          element={<PurchaseOrderIssue />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/quality-related-issues"
          element={<QualityIssue />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/material" element={<MaterialMaster />}></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/add-material-master"
          element={<AddMaterialMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/edit-material-master"
          element={<EditMaterialMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/subMaterial"
          element={<SubMaterialMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/add-submaterial-master"
          element={<AddSubmaterialMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/edit-submaterial-master"
          element={<EditSubmaterialMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/partMaking" element={<PartMakingMaster />}></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/add-partmaking-master"
          element={<AddPartMakingMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/edit-partmaking-master"
          element={<EditPartMakingMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/surface-finish"
          element={<SurfaceFinishMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/add-surface-finish-master"
          element={<AddSurfaceFinishMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/edit-surface-finish-master"
          element={<EditSurfaceFinishMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/surface-treatment"
          element={<SurfaceTreatmentMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/add-surface-treatment-master"
          element={<AddSurfaceTreatmentMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/edit-surface-treatment-master"
          element={<EditSurfaceTreatmentMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/layer-thickness"
          element={<LayerThicknessMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/add-layer-thickness"
          element={<AddLayerThicknessMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/edit-layer-thickness"
          element={<EditLayerThicknessMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/tolerance" element={<ToleranceMaster />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/headt-treatment" element={<HeatTreatMent />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/material-cnc" element={<MaterialMasterComponent />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/surface-treatment-cnc" element={<SurfaceFinishComponent />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/part-marking-cnc" element={<PartMarkingComponent />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/tolerance-cnc" element={<ToleranceComponent />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/internal-corner" element={<InternalCorner />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/review-design" element={<ReviewDesignComponent />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/cnc/material-options" element={<MaterialOptions />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/cnc/select-sub-materials" element={<SelectSubMaterials />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/machine-cnc" element={<MachineMasterComponent />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/machine-options-cnc" element={<MachineOptions />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/materials-cnc-select" element={<MachineMaterialSelect />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/cnc-certifications" element={<CertificationsComponent />}></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route path="/admin/shipping-rates" element={<ShippingRates />}></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/add-tolerance-master"
          element={<AddToleranceMaster />}
        ></Route>
      </Route>

      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/edit-tolerance-master"
          element={<EditToleranceMaster />}
        ></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/3d-technology"
          element={<AdminTechNology />}
        ></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/3d-process"
          element={<ShowAll3DProcess />}
        ></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/3d-material"
          element={<EditToleranceMaster />}
        ></Route>
      </Route>
      <Route path="/admin" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/upsert/3dprocess"
          element={<Upsert3DProcessComponent />}
        ></Route>
      </Route>

      <Route path="/admin/orders" element={<AdminPrivateRoute />}>
        <Route path="/admin/orders" element={<Order />}></Route>
      </Route>

      <Route path="/admin/orders/:_id" element={<AdminPrivateRoute />}>
        <Route
          path="/admin/orders/:_id"
          element={<AdminOrderDetails />}
        ></Route>
      </Route>

      <Route path="*" element={<NotFoundComponent />} />
    </Routes>
  );
}

export default App;
