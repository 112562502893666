import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import { useState } from "react";
import { createUser, getCompanies } from '../../utils/actions/allactions';
import { toast } from "react-toastify";


// Phone code country
import PhoneInput from 'react-phone-number-input'
import { validateAdmin,getToken } from "../../utils/helper";
import useDocumentTitle from '../../utils/useDocumentTitle';

import AddressModal from "./AddressModal/AddressModal";

const AddUser = () => {
    const token = getToken();
    const navigate = useNavigate();
    useDocumentTitle('Add Client User')
    const [error, setError] = useState("");
    const [phoneValue, setPhoneValue] = useState('');
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        address: [],
        phoneNumber: '',
        email: '',
        userType: 'user',
        password: '',
        isVerified: true,
        userCompanySite: '',
        accountStatus:'Active'
    });

    const [showAddressModal, setShowAddressModal] = useState(false);
    const [recentAddress, setRecentAddress] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [companies, setCompanies] = useState([]);
    const [companySites, setCompaniesSite] = useState([]);
    let isActive = true;


    const fetchCompanyDetails = async () => {
        const fetchedCompanies = await getCompanies(token);
        let companyForEmail = fetchedCompanies.filter(isCompanyForEmail)
        setCompanies(companyForEmail);
        // console.log(companyForEmail.length)
        if (companyForEmail.length < 1) {
            toast.error("Company does not exist for this domain. Please recheck email domain")
        }
    }

    const isCompanyForEmail = (company) => {
        const domain = values.email.split("@")[1];
        if (company.domain === domain) {
            return company;
        }
    }


    useEffect(() => {
        validateAdmin(token, navigate);
    }, []);




    const validateUserValues = () => {
        if (!values.firstName ||
            !values.lastName ||
            !values.email ||
            !values.address.length > 0 ||
            !values.companyName ||
            !values.userCompanySite
        ) {
            return false;
        }
        return true;
    }


    const handleCreateUser = async () => {
        setError("")
        const isUserInputValid = validateUserValues();
        if (!isUserInputValid) {
            setError("Enter valid values for all the field.First name, last name,email,address,company name and company site are mandatory fields")
        } else {
            const data = { ...values, phoneNumber: phoneValue||"" }
            const createdUser = await createUser(token, data);
            // console.log(createdUser.data.data)
            if (createdUser.data.data.message) {
                toast.error(createdUser.data.data.message)
            }
            if (createdUser.data.data.code === 11000) {
                toast.error("User already exists")
            } else {
                navigate('/admin/user-master');
                toast.success("User added successfully")
            }
        }
    }



    const handledAddAddress = (addressData) => {
        let addedAddress = values.address ? values.address : [];
        addedAddress.push(addressData)
        setValues({ ...values, address: addedAddress })
    }


    const handleAddressOnChange = (newFormData, index) => {
        let allAddresses = values.address ? values.address : [];
        let address = allAddresses[index];
        address = newFormData;
        allAddresses[index] = address;
        setValues({ ...values, address: allAddresses })
    }

    // Code for Address modal in Admin

    const deleteData = async (i) => {
        const updatedAddress = values.address;
        if (i > -1) {
            updatedAddress.splice(i, 1);
        }
        setValues({ ...values, address: updatedAddress })
    }

    const editHandler = (index) => {
        setShowAddressModal(true);
        setIsEdit(true)
        setEditId(index)
    }

    const openAddressModalHandler = () => {
        if (values.address.length === 5) {
            toast.error('You can only add upto 5 Addresses');
            return;
        }
        setShowAddressModal(true);
    };

    const handleCompanySelect = (e) => {
        let selectedCompany = JSON.parse(e.target.value);
        setCompaniesSite(selectedCompany.companySite)
        setValues({ ...values, companyName: selectedCompany.companyName })
    }

    const handleSiteSelect = (e) => {
        setValues({ ...values, userCompanySite: e.target.value })
    }



    return (
        <div className="admin-container">
            {showAddressModal && (
                <AddressModal
                    addressModal={showAddressModal}
                    setShowAddressModal={setShowAddressModal}
                    setRecentAddress={setRecentAddress}
                    recentAddress={recentAddress}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    id={editId}
                    setValues={setValues}
                    values={values}
                    handledAddAddress={handledAddAddress}
                    handleAddressOnChange={handleAddressOnChange}
                />
            )}
            <div>
                <Sidebar />
            </div>
            <div className="admin-panel-container">
                <h1 className="center-heading text-xl font-bold mt-2">  Add Client User</h1>

                <div className="edit-company-container">
                    <div>
                        <label htmlFor="firstName" >First Name </label>: &nbsp;
                        <input
                            name="firstName"
                             className="p-2 my-1"
                            type="text"
                            placeholder="Enter First name"
                            value={values.firstName}
                            onChange={(e) => { setValues({ ...values, firstName: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="lastName" >Last Name </label>: &nbsp;
                        <input
                            name="lastName"
                             className="p-2 my-1"

                            type="text"
                            value={values.lastName}
                            placeholder="Enter Last name"
                            onChange={(e) => { setValues({ ...values, lastName: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="email" >Email </label>: &nbsp;
                        <input
                            name="email"
                            className="p-2 my-1"

                            type="text"
                            value={values.email}
                            placeholder="Enter Email name"
                            onBlur={fetchCompanyDetails}
                            onChange={(e) => { setValues({ ...values, email: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="password" >Password</label>: &nbsp;
                        <input
                            name="password"
                             className="p-2 my-1"

                            type="password"
                            value={values.password}
                            placeholder="Enter Password"
                            onChange={(e) => { setValues({ ...values, password: e.target.value }) }}
                        />
                    </div>

                    <div style={{ display: 'flex', width: '100%' }}>
                        <div>
                            <label htmlFor="phone" >Phone </label>: &nbsp;
                        </div>
                        <div style={{ width: '40%' }}>
                            <PhoneInput
                                placeholder="Enter phone number"
                                name="phone"
                                value={phoneValue}
                                onChange={setPhoneValue}
                                 className="p-2 my-1"

                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="companyName" >Company Name </label>: &nbsp;
                        <select  className="p-2 my-1" name='companyName' onChange={(e) => handleCompanySelect(e)}>
                            <option value="" selected disabled > Select Company</option>
                            {companies ? companies.map((company, i) => {
                                return <option key={i} value={JSON.stringify(company)}>
                                    {company?.companyName}
                                </option>
                            }) : null
                            }
                        </select>
                    </div>

                    <div>
                        <label htmlFor="companySite" >Company Site </label>: &nbsp;
                        <select  className="p-2 my-1" name='companySite' onChange={(e) => handleSiteSelect(e)}>
                            <option value="" selected disabled > Select Company Site</option>
                            {companySites ? companySites.map((companySite, i) => {
                                return <option key={i} value={companySite} >
                                    {companySite}
                                </option>
                            }) : null
                            }
                        </select>
                    </div>

                    <div>
                        <label htmlFor="compamy-status" >Account Status</label>: &nbsp;
                        <input
                                                     className="p-2 my-1"

                            name="company-status"
                            type="radio"
                            value="Active"
                            onChange={(e) => { setValues({ ...values, accountStatus: e.target.value }) }}
                            defaultChecked={isActive}
                        />Active

                        <input
                                                     className="p-2 my-1"

                            name="company-status"
                            type="radio"
                            value="Inactive"
                            defaultChecked={!isActive}
                            onChange={(e) => { setValues({ ...values, accountStatus: e.target.value }) }}
                        />Inactive
                    </div>


                    <div className="addresses">
                        <label htmlFor="address" >User Address </label>: &nbsp;
                        <div className="cardContainer">
                            <div onClick={openAddressModalHandler} className="addNewAddressCard">
                                <div
                                    className="addNewAddressText"

                                >
                                    + Add New Address
                                </div>
                            </div>

                            {values.address?.map((elem, index) => {
                                return (                  
                                    <div key={index}>
                                        <div className="card">
                                            <div className="addressContainer">
                                                <div className="name">{elem.firstName} {elem?.lastName}</div>
                                                <div className="address">
                                                    {elem?.addressLineOne}
                                                    <div className="landMark">
                                                        {" "}
                                                        {elem?.addressLineTwo}
                                                    </div>
                                                    <div className="state">{elem.city} {elem?.state} {elem?.postalCode}</div>
                                                    <div className="country">{elem?.country}</div>
                                                    <div className="mobile">
                                                      {elem?.phoneNo?  `Mobile: +${elem.dialCode}-${elem?.phoneNo}` : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addressButtons">
                                                <div className="btn" onClick={() => editHandler(index)} >Edit</div>
                                                <div className="btn" onClick={() => deleteData(index)}>Remove</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>


                    <div className="center-heading">
                        <button className="button-search" onClick={handleCreateUser} style={{ marginTop: '15px' }}>  Create User </button>
                    </div>

                    {
                        error ?
                            <div style={{ color: 'red', fontSize: '15px', textAlign: 'center', marginTop: '10px' }}>{error}</div>
                            : null
                    }

                </div>
            </div>
        </div >
    )
}

export default AddUser;