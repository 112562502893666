import React, { useEffect, useState } from 'react'
import { fetchDashboardDataForSupplier, getAllSuppliers } from '../utils/actions/supplierActions';
import { toast } from 'react-toastify';
import SkeltonLoadingComponent from '../components/LoadingComponents/SkeltonLoadingComponent';
import axios from 'axios';
import FlipNumbers from 'react-flip-numbers';
import { colorMap, colorMapForSupplier } from './AdminCharts/PieCharts';
import { CompletedIcon, DeliveredIcon, ProcessingIcon, PurchaseOrderIcon, QuotationsIcon, RFQIcon, ShippedIcon } from '../components/Icons';
import { currencyFormatter } from '../utils/helper';

const AdminSupplierComponentNew = ({
  selectedMonth,
  selectedYear,
  setCompany,
  allCompany,
}) => {
  let token = localStorage.getItem("Token");
  const [loading, setLoading] = useState(false);
  const [esdTrackerData, setEsdTrackerData] = useState();
  const [leadTimeTrackerData, setLeadTimeTrackerData] = useState();
  const [orderTrackerData, setOrderTrackerData] = useState();

  useEffect(() => {

    (async () => {
      const filterDataToFetch = {
        selectedYears: [...selectedYear.filter((item) => item.checked).map(item => item.value)],
        selectedMonths: [...selectedMonth.filter(item => item.checked).map(item => item.id)],
        selectedCompanys: [...allCompany.filter(item => item.checked).map(item => item.id)],
      };
      if (filterDataToFetch.selectedMonths.length == 0 || filterDataToFetch.selectedYears == 0) return;
      try {
        setLoading(true);
        const supplierPortalResponse = await fetchDashboardDataForSupplier(
          token,
          filterDataToFetch,
        );
        if (supplierPortalResponse.success) {
          console.log(supplierPortalResponse);
          const { EsdTrackerData, LeadTimeTrackerData, orderTracking } = supplierPortalResponse?.data;
          setEsdTrackerData(EsdTrackerData);
          setLeadTimeTrackerData(LeadTimeTrackerData);
          setOrderTrackerData(orderTracking);

        }
        setLoading(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setLoading(false);
          toast.error("Unable To Fetch Data");
        }
      }
    })();
  }, [selectedMonth, selectedYear, allCompany])


  return (
    !loading ?
      <div className='pb-10'>
        <div className='items grid ms:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 my-2'>
          {[
            { label: 'Rfq', value: (orderTrackerData?.rfq || 0), icon: <RFQIcon />, color: 'RFQ' },
            { label: 'Processing', value: (orderTrackerData?.processingPurchaseOrders || 0), icon: <ProcessingIcon />, color: 'Processing' },
            { label: 'Shipped', value: (orderTrackerData?.shippedPos || 0), icon: <ShippedIcon />, color: 'Shipped' },
            // { label: 'Delivered', value: (orderTrackerData?.deliveredPos || 0), icon: <DeliveredIcon />, color: 'Delivered' },
            { label: 'Completed', value: (orderTrackerData?.completedPos || 0), icon: <CompletedIcon />, color: 'Completed' },
            { label: 'Issues', value: (orderTrackerData?.issuePurchaseOrders || 0), icon: <PurchaseOrderIcon />, color: 'PurchaseOrder' }
          ].map((item, index) => (
            <div className='bg-white h-32 rounded-2xl flex p-1 items-center justify-center shadow-sm px-3' key={index}>
              <div className="w-2/3 mx-2 flex flex-col ms-2">
                <p className="text-[16px] font-bold lg:text-xl">{item.label}</p>
                <p className="flex justify-start">
                  <div className='my-2 font-bold'>
                    <FlipNumbers
                      className='border border-green-500'
                      height={20}
                      width={20}
                      color="black"
                      background="white"
                      play
                      duration={2}
                      perspective={120}
                      numbers={String(item.value)}
                    />
                  </div>
                </p>
              </div>
              <div className='w-12 h-12 lg:w-14 lg:h-14 text-white flex items-center justify-center rounded-full aspect-square' style={{ backgroundColor: colorMapForSupplier[item.color] }}>
                {item.icon}
              </div>
            </div>
          ))}
        </div>



        <div className='grid lg:grid-cols-2 gap-2'>
          <div className='bg-white '>
            <h3 className='text-center min-h-10 flex items-center justify-center font-bold bg-slate-200'>
              Order Tracker
            </h3>

            <div className='grid grid-cols-3 bg-complementaryPrimaryColor px-2 text-white min-h-10 items-center'>
              <span className='font-medium'>Category</span>
              <span className='font-medium'>No. Of Orders(s)</span>
              <span className='font-medium'>Total Sales Value</span>
            </div>

            <div className='grid grid-cols-3 px-2 border-b-2 min-h-11 items-center'>
              <span className='font-medium'>RFQ :</span>
              <span className='font-medium'>{orderTrackerData?.rfq || 0}</span>
              <span className='font-medium'></span>
            </div>

            <div className='grid grid-cols-3 px-2 border-b-2 min-h-11 items-center'>
              <span className='font-medium'>Processing Orders :</span>
              <span className='font-medium'>{orderTrackerData?.processingPurchaseOrders || 0}</span>
              <span className='font-medium'> {currencyFormatter(orderTrackerData?.processingOrderValue || 0)}</span>
            </div>

            <div className='grid grid-cols-3 px-2 border-b-2 min-h-11 items-center'>
              <span className='font-medium'>Shipped PO :</span>
              <span className='font-medium'>{orderTrackerData?.shippedPos || 0}</span>
              <span className='font-medium'> {currencyFormatter(orderTrackerData?.shippedPoValue || 0)}</span>
            </div>

            {/* <div className='grid grid-cols-3 px-2 border-b-2 min-h-11 items-center'>
              <span className='font-medium'>Delivered PO :</span>
              <span className='font-medium'>{orderTrackerData?.deliveredPos || 0}</span>
              <span className='font-medium'>$ {orderTrackerData?.delivedPoValue || 0}</span>
            </div> */}

            <div className='grid grid-cols-3 px-2 border-b-2 min-h-11 items-center'>
              <span className='font-medium'>Completed PO :</span>
              <span className='font-medium'>{orderTrackerData?.completedPos || 0}</span>
              <span className='font-medium'> {currencyFormatter(orderTrackerData?.completedOrderValue || 0)}</span>
            </div>

            <div className='grid grid-cols-3 px-2 border-b-2 min-h-11 items-center'>
              <span className='font-medium'>Purchase Order Issues :</span>
              <span className='font-medium'>{orderTrackerData?.issuePurchaseOrders || 0}</span>
              <span className='font-medium'> {currencyFormatter(orderTrackerData?.issuePoValue || 0)}</span>
            </div>

            <div className='grid grid-cols-3 px-2 border-b-2 min-h-11 items-center'>
              <span className='font-bold'>Total For PO :</span>
              <span className='font-bold'>{

                Number(orderTrackerData?.processingPurchaseOrders || 0) +
                  Number(orderTrackerData?.shippedPos || 0) +
                  Number(orderTrackerData?.deliveredPos || 0) +
                  Number(orderTrackerData?.completedPos || 0) +
                  Number(orderTrackerData?.issuePoValue || 0)

              }</span>
              <span className='font-bold'>
                {
                  currencyFormatter(
                    (Number(orderTrackerData?.processingOrderValue || 0) +
                      Number(orderTrackerData?.shippedPoValue || 0) +
                      Number(orderTrackerData?.delivedPoValue || 0) +
                      Number(orderTrackerData?.completedOrderValue || 0) +
                      Number(orderTrackerData?.issuePoValue || 0)))
                }
              </span>
            </div>
          </div>
          <div className='bg-white '>
            <div>
              <h3 className='text-center min-h-10 flex items-center justify-center font-medium bg-slate-200'>
                ESD Tracker
              </h3>
              <div className='flex justify-between  bg-complementaryPrimaryColor px-2 text-white min-h-10 items-center'>
                <span className='font-bold'>Category</span>
                <span className='font-bold'>No. Of Orders(s)</span>
              </div>
              <div className='flex justify-between px-2  min-h-10 items-center border-b'>
                <span className='font-medium'>ESD Overdue :</span>
                <span className='font-medium'>{esdTrackerData?.esdOverDue || 0}</span>
              </div>
              <div className='flex justify-between px-2  min-h-10 items-center border-b'>
                <span className='font-medium'>Less than 7 Days  :</span>
                <span className='font-medium'>{esdTrackerData?.lessThan7Days || 0}</span>
              </div>
              <div className='flex justify-between px-2  min-h-10 items-center border-b'>
                <span className='font-medium'>7-14 Days :</span>
                <span className='font-medium'>{esdTrackerData?.lessThan14Days || 0}</span>
              </div>
              <div className='flex justify-between px-2  min-h-10 items-center border-b'>
                <span className='font-medium'>Greater than 14 Days :</span>
                <span className='font-medium'>{esdTrackerData?.greaterThan14Days || 0}</span>
              </div>
            </div>
            <div>
              <h3 className='text-center min-h-10 flex items-center justify-center font-bold bg-slate-200'>
                Supplier Lead Time Tracker
              </h3>
              <div className='flex justify-between  bg-complementaryPrimaryColor px-2 text-white min-h-10 items-center'>
                <span className='font-bold'>Category</span>
                <span className='font-bold'>Average Data</span>
              </div>
              <div className='flex justify-between px-2  min-h-10 items-center border-b'>
                <span className='font-medium'>RFQs (days):</span>
                <span className='font-medium'>{leadTimeTrackerData?.rfqs || 0}</span>
              </div>
              <div className='flex justify-between px-2  min-h-10 items-center border-b'>
                <span className='font-medium'>Purchase Orders (days):</span>
                <span className='font-medium'>{leadTimeTrackerData?.purchaseOrders || 0}</span>
              </div>
              <div className='flex justify-between px-2  min-h-10 items-center border-b'>
                <span className='font-medium'>Late POs :</span>
                <span className='font-medium'>{leadTimeTrackerData?.latePos || 0} / {leadTimeTrackerData?.totalPosForLateOrders || 0}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
      : <SkeltonLoadingComponent />
  )
}

export default AdminSupplierComponentNew