import React, { useContext, useEffect, useMemo, useState } from "react";
import Sidebar from "./Sidebar";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../utils/useDocumentTitle";
import { Form, FormControl } from "react-bootstrap";
import { getCompanies, getAllUsers } from "../utils/actions/allactions";
import {
  fetchDashboardDataForSupplier,
  getAllSuppliers,
} from "../utils/actions/supplierActions";
import { quoteVersionDescriptions, processType } from "../utils/constant";
import moment from "moment";
import loadingImage from "../images/white-loader.gif";
import {
  calculatePriceValBasedOnData,
  calculatePriceValBasedOnDataWithoutTax,
  currencyFormatter,
} from "../utils/helper";
import { ADMIN_ACTIONS, useAdminContext } from "../context/admin.context";
import deleteImage from "../images/reddelete.svg";
import { fetchDashboardDataForMake } from "../utils/actions/adminActions";
import AdminDashBoardComponent from "../components/HigherOrderComponents/AdminDashboardComponents";
import SkeltonLoadingComponent from "../components/LoadingComponents/SkeltonLoadingComponent";
import { DropDownIcon } from "../components/Icons";

const AdminDashboard = () => {
  let navigate = useNavigate();
  useDocumentTitle("Dashboard");
  let token = localStorage.getItem("Token");
  let decode;
  // Comman state for dashboard
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState([
    { id: 0, value: 'January', checked: false },
    { id: 1, value: 'February', checked: false },
    { id: 2, value: 'March', checked: false },
    { id: 3, value: 'April', checked: false },
    { id: 4, value: 'May', checked: false },
    { id: 5, value: 'June', checked: false },
    { id: 6, value: 'July', checked: false },
    { id: 7, value: 'August', checked: false },
    { id: 8, value: 'September', checked: false },
    { id: 9, value: 'October', checked: false },
    { id: 10, value: 'November', checked: false },
    { id: 11, value: 'December', checked: false }
  ]);
  const { state, dispatch } = useAdminContext();

  // Customer states
  const [quotationData, setQuotationData] = useState([]);
  const [salesOrderData, setSalesOrderData] = useState([]);
  const [allQuotationData, setAllQuotationData] = useState([]);
  const [allIssueData, setAllIssueData] = useState([]);
  const [allCustomerCompanies, setAllCustomerCompanies] = useState([]);
  const [allCustomerUsers, setAllCustomerUsers] = useState([]);
  const [customersForFilter, setCustomersForFilter] = useState([]);
  const [filteredCustomerUser, setFilteredCustomerUser] = useState([]);
  const [selectedCustomerForFilter, setSelectedCustomerForFilter] = useState(
    new Set()
  );
  const [isMonthDropDownOn, setIsMonthDropDownOn] = useState(false);
  const [selectedCompanyForFilter, setSelectedCompanyForFilter] = useState("");
  const [customerOrderSummaryData, setCustomerOrderSummaryData] = useState({
    quotations: 0,
    salesOrders: 0,
    completedOrders: 0,
    issueSalesOrders: 0,
  });
  const [processSelectedSummaryData, setProcessSelectedSummaryData] = useState(
    Array.from({ length: 11 }, () => ({ numberOfOrder: 0, totalSalesValue: 0, totalSalesValueWithoutTax: 0 }))
  );
  const [quotationDescriptionSummaryData, setQuotationDescriptionSummaryData] =
    useState({ CC: 0, CE: 0, DU: 0, AR: 0, CU: 0, RO: 0, SP: 0, AC: 0 });
  const [leadTimeTrackerData, setLeadTimeTrackerData] = useState({
    quotations: 0,
    salesOrders: 0,
    lateOrders: 0,
    totalOrdersForLateOrders: 0,
  });
  const [ceddTrackerData, setCeddTrackerData] = useState({
    ceddOverDue: 0,
    lessThan7Days: 0,
    lessThan14Days: 0,
    greaterThan14Days: 0,
  });
  const [userFilterValue, setUserFilterValue] = useState("");
  // Supplier States
  const [selectedSupplierForFilter, setSelectedSupplierForFilter] =
    useState("");
  const [allSupplierCompanies, setAllSupplierCompanies] = useState([]);
  const [rfqData, setRfqData] = useState([]);
  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const [supplierRFQData, setSupplierRFQData] = useState([]);
  const [supplierOrderTracker, setSupplierOrderTracker] = useState({
    rfqs: 0,
    purchaseOrders: 0,
    completedPOs: 0,
    issuePurchaseOrders: 0,
    shippedPOs: 0,
    deliveredPOs: 0,
    processingOrderValue: 0,
    shippedPOValue: 0,
    issuePOValue: 0,
    completedPOValue: 0,
    deliveredPOValue: 0,
  });
  const [supplierESDTrackerData, setSupplierESDTrackerData] = useState({
    esdOverDue: 0,
    lessThan7Days: 0,
    lessThan14Days: 0,
    greaterThan14Days: 0,
  });
  const [supplierLeadTimeTrackerData, setSupplierLeadTimeTrackerData] =
    useState({
      rfqs: 0,
      salesOrders: 0,
      lateOrders: 0,
      totalOrdersForLateOrders: 0,
    });

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      decode = jwt_decode(token);
      setUserData(decode);
      if (!decode) {
        navigate("/login");
        toast.error("Invalid Token found!");
      } else if (decode) {
        if (decode.userType == "admin") {
          fetchCustomerAndSupplierData();
        } else if (decode.userType == "user") {
          navigate("/saved-quote");
        } else {
          navigate("/login");
        }
      }
    }
  }, []);
  const fetchCustomerAndSupplierData = async () => {
    try {
      const customerCompanyResponse = getCompanies(token);
      const supplierCompanyResponse = getAllSuppliers(token);
      const getAllCustomerUsersResponse = getAllUsers(token);

      const data = await Promise.all([
        customerCompanyResponse,
        supplierCompanyResponse,
        getAllCustomerUsersResponse,
      ]);

      setAllCustomerCompanies(data[0]);
      if (data[1].status) {
        setAllSupplierCompanies(data[1].data);
      }
      setAllCustomerUsers(data[2]);
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchData = async (selectedYear, selectedMonth) => {
    try {
      setIsLoading(true);
      if (selectedYear) {
        let filtersToFetch = {
          selectedYear,
          selectedMonth: selectedMonth.filter((item) => item.checked).map(item => item.id),
        };
        const makePortalResponse = fetchDashboardDataForMake(
          token,
          filtersToFetch
        );
        const supplierPortalResponse = fetchDashboardDataForSupplier(
          token,
          filtersToFetch
        );
        const fetchedData = await Promise.all([
          makePortalResponse,
          supplierPortalResponse,
        ]);
        if (fetchedData[0].status) {
          let salesOrderData = fetchedData[0]?.data?.salesOrderData;
          let quotationData = fetchedData[0]?.data?.quotationData;
          let issueData = fetchedData[0]?.data?.issuesData;
          let allQuotationData = fetchedData[0]?.data?.allQuotationData;
          setQuotationData(quotationData);
          setSalesOrderData(salesOrderData);
          setAllQuotationData(allQuotationData);
          setAllIssueData(issueData);

          let inProcessQuotations = quotationData.filter(
            (item) =>
              !item.myorders &&
              !item.isCancelled &&
              !(
                moment(Date.now()).diff(item.createdAt) / (1000 * 3600 * 24) >
                45
              )
          );

          let orderIdWithIssue = [];
          issueData?.forEach((item) => {
            if (item?.orderId && !item.isIssueClosed) {
              orderIdWithIssue.push(item?.orderId);
            }
          });

          let localCompletedOrder = salesOrderData.filter(
            (item) =>
              item.status != "refunded" &&
              item.status != "cancelled" &&
              item.isCompleted &&
              !orderIdWithIssue.includes(item._id)
          );

          let localInProgressOrders = salesOrderData.filter(
            (item) =>
              item.status != "refunded" &&
              item.status != "cancelled" &&
              !item.isCompleted &&
              !item.expectedDelivery &&
              !item.shippedDate &&
              !orderIdWithIssue.includes(item._id)
          );

          let localShippedOrder = salesOrderData.filter(
            (item) =>
              item.status != "refunded" &&
              item.status != "cancelled" &&
              !item.isCompleted &&
              !item.expectedDelivery &&
              item.shippedDate &&
              !orderIdWithIssue.includes(item._id)
          );

          let localIssuesOrders = salesOrderData.filter((item) =>
            orderIdWithIssue.includes(item._id)
          );

          setCustomerOrderSummaryData({
            ...customerOrderSummaryData,
            quotations: inProcessQuotations?.length,
            salesOrders: localInProgressOrders?.length,
            shippedOrders: localShippedOrder?.length,
            completedOrders: localCompletedOrder?.length,
            issueSalesOrders: localIssuesOrders?.length,
          });

          let localQuotationDescriptionSummaryData = {
            CC: 0,
            CE: 0,
            DU: 0,
            AR: 0,
            CU: 0,
            RO: 0,
            SP: 0,
            AC: 0,
          };

          allQuotationData.forEach((item) => {
            if (item.versionDescription) {
              localQuotationDescriptionSummaryData[item.versionDescription]++;
            }
          });

          setQuotationDescriptionSummaryData(
            localQuotationDescriptionSummaryData
          );

          let localProcessSelectedSummaryData = Array.from(
            { length: 11 },
            () => ({
              numberOfOrder: 0,
              totalSalesValue: 0,
              totalSalesValueWithoutTax: 0,
            })
          );

          salesOrderData.forEach((item) => {
            localProcessSelectedSummaryData[item.quoteId.selectedProcess - 1]
              .numberOfOrder++;
            localProcessSelectedSummaryData[
              item.quoteId.selectedProcess - 1
            ].totalSalesValue += parseFloat(calculatePriceValBasedOnData(item));
            localProcessSelectedSummaryData[
              item.quoteId.selectedProcess - 1
            ].totalSalesValueWithoutTax += parseFloat(calculatePriceValBasedOnDataWithoutTax(item));
          });

          setProcessSelectedSummaryData(localProcessSelectedSummaryData);
          let quotationCount = 0;
          let quotationLeadTime = 0;
          allQuotationData.forEach((item) => {
            if (item.quotationSentDate) {
              quotationCount++;
              quotationLeadTime += moment(item.quotationSentDate).diff(
                item.createdAt,
                "days"
              );
            }
          });

          let salesOrderCount = 0;
          let salesOrderLeadTime = 0;
          salesOrderData.forEach((item, index) => {
            if (item.isCompleted) {
              salesOrderCount++;
              salesOrderLeadTime += moment(item.completedOn).diff(
                item.createdAt,
                "days"
              );
            }
          });

          let lateOrderCount = 0;
          salesOrderData.forEach((item) => {
            if (item.quoteSelectedShipMethod == 0) {
              if (item.isCompleted) {
                if (
                  moment(item.completedOn).diff(item.createdAt, "days") >
                  item.quoteId.leadTime1
                ) {
                  lateOrderCount++;
                }
              } else {
                if (
                  moment(Date.now()).diff(item.createdAt, "days") >
                  item.quoteId.leadTime1
                ) {
                  lateOrderCount++;
                }
              }
            } else if (item.quoteSelectedShipMethod == 1) {
              if (item.isCompleted) {
                if (
                  moment(item.completedOn).diff(item.createdAt, "days") >
                  item.quoteId.leadTime2
                ) {
                  lateOrderCount++;
                }
              } else {
                if (
                  moment(Date.now()).diff(item.createdAt, "days") >
                  item.quoteId.leadTime2
                ) {
                  lateOrderCount++;
                }
              }
            } else if (item.quoteSelectedShipMethod == 2) {
              if (item.isCompleted) {
                if (
                  moment(item.completedOn).diff(item.createdAt, "days") >
                  item.quoteId.leadTime3
                ) {
                  lateOrderCount++;
                }
              } else {
                if (
                  moment(Date.now()).diff(item.createdAt, "days") >
                  item.quoteId.leadTime3
                ) {
                  lateOrderCount++;
                }
              }
            }
          });

          setLeadTimeTrackerData({
            ...leadTimeTrackerData,
            quotations:
              quotationCount > 0
                ? Math.round(quotationLeadTime / quotationCount)
                : 0,
            salesOrders:
              salesOrderCount > 0
                ? Math.round(salesOrderLeadTime / salesOrderCount)
                : 0,
            lateOrders: lateOrderCount,
            totalOrdersForLateOrders: salesOrderData.length,
          });

          const sevenDaysFromNow = moment().add(7, "days");
          const fourteenDaysFromNow = moment().add(14, "days");
          let ceddOverDue = 0;
          let lessThan7Days = 0;
          let lessThan14Days = 0;
          let greaterThan14Days = 0;

          localInProgressOrders.map((item) => {
            let ceddValue = item.customerExpectedDeliveryDate;
            if (ceddValue && moment(ceddValue).isBefore(moment())) {
              ceddOverDue++;
            } else if (
              ceddValue &&
              moment(ceddValue).isBefore(sevenDaysFromNow, "day") &&
              moment(ceddValue).isSameOrAfter(moment())
            ) {
              lessThan7Days++;
            } else if (
              ceddValue &&
              moment(ceddValue).isSameOrBefore(fourteenDaysFromNow, "day") &&
              moment(ceddValue).isSameOrAfter(sevenDaysFromNow, "day")
            ) {
              lessThan14Days++;
            } else if (
              ceddValue &&
              moment(ceddValue).isAfter(fourteenDaysFromNow, "day")
            ) {
              greaterThan14Days++;
            }
          });

          setCeddTrackerData({
            ...ceddTrackerData,
            ceddOverDue,
            lessThan7Days,
            lessThan14Days,
            greaterThan14Days,
          });
        };
        // Supplier Data starts here
        if (fetchedData[1].status) {
          let rfqData = fetchedData[1]?.data?.rfqData;
          let purchaseOrderData = fetchedData[1]?.data?.purchaseOrderData;
          let supplierRFQData = fetchedData[1]?.data?.supplierRFQData;
          setRfqData(rfqData);
          setPurchaseOrderData(purchaseOrderData);
          setSupplierRFQData(supplierRFQData);

          let rfqs = rfqData.filter(
            (item) =>
              item.rfqStatus == "in-progress" || item.rfqStatus == "active"
          );
          let processsingPurchaseOrders = purchaseOrderData?.filter(
            (item) => item.status == "Order Processing" && !item.isCompleted
          );
          let shippedPOs = purchaseOrderData?.filter(
            (item) => item.status == "Shipped" && !item.isCompleted
          );

          let deliveredPOs = purchaseOrderData?.filter(
            (item) => item.status == "Delivered" && !item.isCompleted
          );
          let issuePurchaseOrders = purchaseOrderData?.filter(
            (item) => item.status == "Under Resolution" && !item.isCompleted
          );
          let completedPOs = purchaseOrderData?.filter(
            (item) => item.isCompleted || item.status == "Closed"
          );

          let shippedPOValue = 0;
          let issuePOValue = 0;
          let completedPOValue = 0;
          let processingOrderValue = 0;
          let deliveredPOValue = 0;

          processsingPurchaseOrders.forEach(
            (item) => (processingOrderValue += item.totalAmount)
          );
          shippedPOs.forEach((item) => (shippedPOValue += item.totalAmount));
          issuePurchaseOrders.forEach(
            (item) => (issuePOValue += item.totalAmount)
          );
          completedPOs.forEach(
            (item) => (completedPOValue += item.totalAmount)
          );
          deliveredPOs.forEach(
            (item) => (deliveredPOValue += item.totalAmount)
          );

          setSupplierOrderTracker({
            rfqs: rfqs.length,
            purchaseOrders: processsingPurchaseOrders.length,
            issuePurchaseOrders: issuePurchaseOrders.length,
            shippedPOs: shippedPOs.length,
            completedPOs: completedPOs.length,
            deliveredPOs: deliveredPOs.length,
            processingOrderValue,
            shippedPOValue,
            issuePOValue,
            completedPOValue,
            deliveredPOValue,
          });

          const sevenDaysFromNow = moment().add(7, "days");
          const fourteenDaysFromNow = moment().add(14, "days");
          let esdOverDue = 0;
          let lessThan7Days = 0;
          let lessThan14Days = 0;
          let greaterThan14Days = 0;

          processsingPurchaseOrders.forEach((item) => {
            let esdValue =
              item?.expectedShippingDates?.length > 0
                ? moment(
                  item?.expectedShippingDates.reduce((maxObj, currentObj) => {
                    if (currentObj.updatedDate > maxObj.updatedDate) {
                      return currentObj;
                    }
                    return maxObj;
                  }).updateValue
                ).format("DD MMM YYYY")
                : item.poAcceptanceDate
                  ? moment(item.poAcceptanceDate)
                    .add(item?.supplierRFQId?.leadTime, "days")
                    .format("DD MMM YYYY")
                  : "";

            if (esdValue) {
              if (moment(esdValue).isBefore(moment())) {
                esdOverDue++;
              } else if (
                moment(esdValue).isBefore(sevenDaysFromNow, "day") &&
                moment(esdValue).isSameOrAfter(moment())
              ) {
                lessThan7Days++;
              } else if (
                moment(esdValue).isSameOrBefore(fourteenDaysFromNow, "day") &&
                moment(esdValue).isSameOrAfter(sevenDaysFromNow, "day")
              ) {
                lessThan14Days++;
              } else if (moment(esdValue).isAfter(fourteenDaysFromNow, "day")) {
                greaterThan14Days++;
              }
            }
          });

          setSupplierESDTrackerData({
            esdOverDue,
            lessThan7Days,
            lessThan14Days,
            greaterThan14Days,
          });
          let rfqCount = 0;
          let rfqLeadTime = 0;

          supplierRFQData.forEach((item) => {
            if (item.receivedDate && item.quoteSubmitDate) {
              rfqCount++;
              rfqLeadTime += moment(item.quoteSubmitDate).diff(
                item.receivedDate,
                "days"
              );
            }
          });

          let poCount = 0;
          let poLeadTime = 0;

          purchaseOrderData.forEach((item) => {
            if (item.deliveryDate && item.poApprovedDate) {
              poCount++;
              poLeadTime += moment(item.deliveryDate).diff(
                item.poApprovedDate,
                "days"
              );
            }
          });

          let latePOcount = 0;
          let totalOrderForLatePOs = purchaseOrderData.length;
          purchaseOrderData.forEach((item) => {
            if (item.deliveryDate && item.poApprovedDate) {
              if (
                moment(item.deliveryDate).diff(item.poApprovedDate, "days") >
                item.supplierRFQId.leadTime
              ) {
                latePOcount++;
              }
            }
          });

          setSupplierLeadTimeTrackerData({
            rfqs: rfqCount > 0 ? Math.round(rfqLeadTime / rfqCount) : 0,
            purchaseOrders: poCount > 0 ? Math.round(poLeadTime / poCount) : 0,
            latePOs: latePOcount,
            totalPOsForLateOrders: totalOrderForLatePOs,
          });
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("Error on line 539 ", err.message);
    }
  };
  useMemo(() => {
    if (!isNaN(parseInt(selectedYear))) {
      fetchData(selectedYear, selectedMonth);
    }
  }, [selectedYear, selectedMonth]);

  const handleYearChange = (e) => {
    const year = parseInt(e.target.value);
    setSelectedYear(year);
  };

  // const handleMonthChange = (e) => {
  //   const month = parseInt(e.target.value);
  //   setSelectedMonth(month);
  // };

  const handleCustomerFilterChange = (action, item) => {
    try {
      let customerId = item;
      if (!customerId) {
        return;
      }

      let localSelectedCustomerForFilter = selectedCustomerForFilter;
      setUserFilterValue("");
      setFilteredCustomerUser([]);

      if (action == "push") {
        localSelectedCustomerForFilter.add(customerId);
      } else if (action == "pop") {
        localSelectedCustomerForFilter.delete(customerId);
      }

      setSelectedCustomerForFilter(localSelectedCustomerForFilter);
      if (localSelectedCustomerForFilter.size == 0) {
        handleCompanyFilterChange(selectedCompanyForFilter);
        return;
      }

      let inProcessQuotations = quotationData.filter(
        (item) =>
          !item.myorders &&
          !item.isCancelled &&
          !(
            moment(Date.now()).diff(item.createdAt) / (1000 * 3600 * 24) >
            45
          ) &&
          localSelectedCustomerForFilter.has(item.userId._id)
      );

      let orderIdWithIssue = [];
      allIssueData?.forEach((item) => {
        if (item?.orderId && !item.isIssueClosed) {
          orderIdWithIssue.push(item?.orderId);
        }
      });

      let localCompletedOrder = salesOrderData.filter(
        (item) =>
          item.status != "refunded" &&
          item.status != "cancelled" &&
          item.isCompleted &&
          !orderIdWithIssue.includes(item._id) &&
          localSelectedCustomerForFilter.has(item.userId._id)
      );

      let localInProgressOrders = salesOrderData.filter(
        (item) =>
          item.status != "refunded" &&
          item.status != "cancelled" &&
          !item.isCompleted &&
          !item.expectedDelivery &&
          !item.shippedDate &&
          !orderIdWithIssue.includes(item._id) &&
          localSelectedCustomerForFilter.has(item.userId._id)
      );

      let localShippedOrder = salesOrderData.filter(
        (item) =>
          item.status != "refunded" &&
          item.status != "cancelled" &&
          !item.isCompleted &&
          !item.expectedDelivery &&
          item.shippedDate &&
          !orderIdWithIssue.includes(item._id) &&
          localSelectedCustomerForFilter.has(item.userId._id)
      );

      let localIssuesOrders = salesOrderData.filter(
        (item) =>
          orderIdWithIssue.includes(item._id) &&
          localSelectedCustomerForFilter.has(item.userId._id)
      );

      setCustomerOrderSummaryData({
        ...customerOrderSummaryData,
        quotations: inProcessQuotations?.length,
        shippedOrders: localShippedOrder?.length,
        salesOrders: localInProgressOrders?.length,
        completedOrders: localCompletedOrder?.length,
        issueSalesOrders: localIssuesOrders?.length,
      });

      let localQuotationDescriptionSummaryData = {
        CC: 0,
        CE: 0,
        DU: 0,
        AR: 0,
        CU: 0,
        RO: 0,
        SP: 0,
        AC: 0,
      };

      allQuotationData.forEach((item) => {
        if (
          item.versionDescription &&
          localSelectedCustomerForFilter.has(item.userId._id)
        ) {
          localQuotationDescriptionSummaryData[item.versionDescription]++;
        }
      });

      setQuotationDescriptionSummaryData(localQuotationDescriptionSummaryData);

      let localProcessSelectedSummaryData = Array.from({ length: 11 }, () => ({
        numberOfOrder: 0,
        totalSalesValue: 0,
        totalSalesValueWithoutTax: 0,
      }));

      salesOrderData.forEach((item) => {
        if (localSelectedCustomerForFilter.has(item.userId._id)) {
          localProcessSelectedSummaryData[item.quoteId.selectedProcess - 1]
            .numberOfOrder++;
          localProcessSelectedSummaryData[
            item.quoteId.selectedProcess - 1
          ].totalSalesValue += parseFloat(calculatePriceValBasedOnData(item));
          localProcessSelectedSummaryData[item.quoteId.selectedProcess - 1].totalSalesValueWithoutTax += parseFloat(calculatePriceValBasedOnDataWithoutTax(item))
        }
      });

      setProcessSelectedSummaryData(localProcessSelectedSummaryData);

      let quotationCount = 0;
      let quotationLeadTime = 0;
      allQuotationData.forEach((item) => {
        if (
          item.quotationSentDate &&
          localSelectedCustomerForFilter.has(item.userId._id)
        ) {
          quotationCount++;
          quotationLeadTime += moment(item.quotationSentDate).diff(
            item.createdAt,
            "days"
          );
        }
      });

      let salesOrderCount = 0;
      let salesOrderLeadTime = 0;
      salesOrderData.forEach((item, index) => {
        if (
          item.isCompleted &&
          localSelectedCustomerForFilter.has(item.userId._id)
        ) {
          salesOrderCount++;
          salesOrderLeadTime += moment(item.completedOn).diff(
            item.createdAt,
            "days"
          );
        }
      });

      let lateOrderCount = 0;
      let totalOrdersForLateOrders = 0;
      salesOrderData.forEach((item) => {
        if (localSelectedCustomerForFilter.has(item.userId._id)) {
          totalOrdersForLateOrders++;
          if (item.quoteSelectedShipMethod == 0) {
            if (item.isCompleted) {
              if (
                moment(item.completedOn).diff(item.createdAt, "days") >
                item.quoteId.leadTime1
              ) {
                lateOrderCount++;
              }
            } else {
              if (
                moment(Date.now()).diff(item.createdAt, "days") >
                item.quoteId.leadTime1
              ) {
                lateOrderCount++;
              }
            }
          } else if (item.quoteSelectedShipMethod == 1) {
            if (item.isCompleted) {
              if (
                moment(item.completedOn).diff(item.createdAt, "days") >
                item.quoteId.leadTime2
              ) {
                lateOrderCount++;
              }
            } else {
              if (
                moment(Date.now()).diff(item.createdAt, "days") >
                item.quoteId.leadTime2
              ) {
                lateOrderCount++;
              }
            }
          } else if (item.quoteSelectedShipMethod == 2) {
            if (item.isCompleted) {
              if (
                moment(item.completedOn).diff(item.createdAt, "days") >
                item.quoteId.leadTime3
              ) {
                lateOrderCount++;
              }
            } else {
              if (
                moment(Date.now()).diff(item.createdAt, "days") >
                item.quoteId.leadTime3
              ) {
                lateOrderCount++;
              }
            }
          }
        }
      });

      setLeadTimeTrackerData({
        ...leadTimeTrackerData,
        quotations:
          quotationCount > 0
            ? Math.round(quotationLeadTime / quotationCount)
            : 0,
        salesOrders:
          salesOrderCount > 0
            ? Math.round(salesOrderLeadTime / salesOrderCount)
            : 0,
        lateOrders: lateOrderCount,
        totalOrdersForLateOrders,
      });

      const sevenDaysFromNow = moment().add(7, "days");
      const fourteenDaysFromNow = moment().add(14, "days");
      let ceddOverDue = 0;
      let lessThan7Days = 0;
      let lessThan14Days = 0;
      let greaterThan14Days = 0;
      localInProgressOrders.map((item) => {
        if (localSelectedCustomerForFilter.has(item.userId._id)) {
          let ceddValue = item.customerExpectedDeliveryDate;
          if (ceddValue && moment(ceddValue).isBefore(moment())) {
            ceddOverDue++;
          } else if (
            ceddValue &&
            moment(ceddValue).isBefore(sevenDaysFromNow, "day") &&
            moment(ceddValue).isSameOrAfter(moment())
          ) {
            lessThan7Days++;
          } else if (
            ceddValue &&
            moment(ceddValue).isSameOrBefore(fourteenDaysFromNow, "day") &&
            moment(ceddValue).isSameOrAfter(sevenDaysFromNow, "day")
          ) {
            lessThan14Days++;
          } else if (
            ceddValue &&
            moment(ceddValue).isAfter(fourteenDaysFromNow, "day")
          ) {
            greaterThan14Days++;
          }
        }
      });

      setCeddTrackerData({
        ...ceddTrackerData,
        ceddOverDue,
        lessThan7Days,
        lessThan14Days,
        greaterThan14Days,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleCompanyFilterChange = (companyId) => {
    try {
      let isAllCompany = false;
      if (companyId == "all-company") {
        setCustomersForFilter([]);
        isAllCompany = true;
      }
      setSelectedCompanyForFilter(companyId);

      if (!companyId) {
        return;
      }

      let customersForCompanyId = allCustomerUsers.filter(
        (item) => item.companySiteId == companyId
      );
      setCustomersForFilter(customersForCompanyId);
      let inProcessQuotations = quotationData.filter(
        (item) =>
          !item.myorders &&
          !item.isCancelled &&
          !(
            moment(Date.now()).diff(item.createdAt) / (1000 * 3600 * 24) >
            45
          ) &&
          (item.userId.companySiteId._id == companyId || isAllCompany)
      );

      let orderIdWithIssue = [];
      allIssueData?.forEach((item) => {
        if (item?.orderId && !item.isIssueClosed) {
          orderIdWithIssue.push(item?.orderId);
        }
      });

      let localCompletedOrder = salesOrderData.filter(
        (item) =>
          item.status != "refunded" &&
          item.status != "cancelled" &&
          item.isCompleted &&
          !orderIdWithIssue.includes(item._id) &&
          (item.userId.companySiteId._id == companyId || isAllCompany)
      );

      let localInProgressOrders = salesOrderData.filter(
        (item) =>
          item.status != "refunded" &&
          item.status != "cancelled" &&
          !item.isCompleted &&
          !item.expectedDelivery &&
          !item.shippedDate &&
          !orderIdWithIssue.includes(item._id) &&
          (item.userId.companySiteId._id == companyId || isAllCompany)
      );

      let localIssuesOrders = salesOrderData.filter(
        (item) =>
          orderIdWithIssue.includes(item._id) &&
          (item.userId.companySiteId._id == companyId || isAllCompany)
      );
      setCustomerOrderSummaryData({
        ...customerOrderSummaryData,
        quotations: inProcessQuotations?.length,
        salesOrders: localInProgressOrders?.length,
        completedOrders: localCompletedOrder?.length,
        issueSalesOrders: localIssuesOrders?.length,
      });

      let localQuotationDescriptionSummaryData = {
        CC: 0,
        CE: 0,
        DU: 0,
        AR: 0,
        CU: 0,
        RO: 0,
        SP: 0,
        AC: 0,
      };

      allQuotationData.forEach((item) => {
        if (
          item.versionDescription &&
          (item.userId.companySiteId._id == companyId || isAllCompany)
        ) {
          localQuotationDescriptionSummaryData[item.versionDescription]++;
        }
      });

      setQuotationDescriptionSummaryData(localQuotationDescriptionSummaryData);

      let localProcessSelectedSummaryData = Array.from({ length: 11 }, () => ({
        numberOfOrder: 0,
        totalSalesValue: 0,
        totalSalesValueWithoutTax: 0,
      }));
      salesOrderData.forEach((item) => {
        if (item.userId.companySiteId._id == companyId || isAllCompany) {
          localProcessSelectedSummaryData[item.quoteId.selectedProcess - 1]
            .numberOfOrder++;
          localProcessSelectedSummaryData[
            item.quoteId.selectedProcess - 1
          ].totalSalesValue += parseFloat(calculatePriceValBasedOnData(item));
          localProcessSelectedSummaryData[
            item.quoteId.selectedProcess - 1
          ].totalSalesValueWithoutTax += parseFloat(calculatePriceValBasedOnDataWithoutTax(item));
          localProcessSelectedSummaryData[item.quoteId.selectedProcess - 1].totalSalesValueWithoutTax
        }
      });

      setProcessSelectedSummaryData(localProcessSelectedSummaryData);

      let quotationCount = 0;
      let quotationLeadTime = 0;
      allQuotationData.forEach((item) => {
        if (
          item.quotationSentDate &&
          (item.userId.companySiteId._id == companyId || isAllCompany)
        ) {
          quotationCount++;
          quotationLeadTime += moment(item.quotationSentDate).diff(
            item.createdAt,
            "days"
          );
        }
      });

      let salesOrderCount = 0;
      let salesOrderLeadTime = 0;
      salesOrderData.forEach((item, index) => {
        if (
          item.isCompleted &&
          (item.userId.companySiteId._id == companyId || isAllCompany)
        ) {
          salesOrderCount++;
          salesOrderLeadTime += moment(item.completedOn).diff(
            item.createdAt,
            "days"
          );
        }
      });

      let lateOrderCount = 0;
      let totalOrdersForLateOrders = 0;
      salesOrderData.forEach((item) => {
        if (item.userId.companySiteId._id == companyId || isAllCompany) {
          totalOrdersForLateOrders++;
          if (item.quoteSelectedShipMethod == 0) {
            if (item.isCompleted) {
              if (
                moment(item.completedOn).diff(item.createdAt, "days") >
                item.quoteId.leadTime1
              ) {
                lateOrderCount++;
              }
            } else {
              if (
                moment(Date.now()).diff(item.createdAt, "days") >
                item.quoteId.leadTime1
              ) {
                lateOrderCount++;
              }
            }
          } else if (item.quoteSelectedShipMethod == 1) {
            if (item.isCompleted) {
              if (
                moment(item.completedOn).diff(item.createdAt, "days") >
                item.quoteId.leadTime2
              ) {
                lateOrderCount++;
              }
            } else {
              if (
                moment(Date.now()).diff(item.createdAt, "days") >
                item.quoteId.leadTime2
              ) {
                lateOrderCount++;
              }
            }
          } else if (item.quoteSelectedShipMethod == 2) {
            if (item.isCompleted) {
              if (
                moment(item.completedOn).diff(item.createdAt, "days") >
                item.quoteId.leadTime3
              ) {
                lateOrderCount++;
              }
            } else {
              if (
                moment(Date.now()).diff(item.createdAt, "days") >
                item.quoteId.leadTime3
              ) {
                lateOrderCount++;
              }
            }
          }
        }
      });

      setLeadTimeTrackerData({
        ...leadTimeTrackerData,
        quotations:
          quotationCount > 0
            ? Math.round(quotationLeadTime / quotationCount)
            : 0,
        salesOrders:
          salesOrderCount > 0
            ? Math.round(salesOrderLeadTime / salesOrderCount)
            : 0,
        lateOrders: lateOrderCount,
        totalOrdersForLateOrders,
      });

      const sevenDaysFromNow = moment().add(7, "days");
      const fourteenDaysFromNow = moment().add(14, "days");
      let ceddOverDue = 0;
      let lessThan7Days = 0;
      let lessThan14Days = 0;
      let greaterThan14Days = 0;
      localInProgressOrders.map((item) => {
        if (item.userId.companySiteId._id == companyId || isAllCompany) {
          let ceddValue = item.customerExpectedDeliveryDate;
          if (ceddValue && moment(ceddValue).isBefore(moment())) {
            ceddOverDue++;
          } else if (
            ceddValue &&
            moment(ceddValue).isBefore(sevenDaysFromNow, "day") &&
            moment(ceddValue).isSameOrAfter(moment())
          ) {
            lessThan7Days++;
          } else if (
            ceddValue &&
            moment(ceddValue).isSameOrBefore(fourteenDaysFromNow, "day") &&
            moment(ceddValue).isSameOrAfter(sevenDaysFromNow, "day")
          ) {
            lessThan14Days++;
          } else if (
            ceddValue &&
            moment(ceddValue).isAfter(fourteenDaysFromNow, "day")
          ) {
            greaterThan14Days++;
          }
        }
      });

      setCeddTrackerData({
        ...ceddTrackerData,
        ceddOverDue,
        lessThan7Days,
        lessThan14Days,
        greaterThan14Days,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleSupplierFilterChange = (e) => {
    try {
      let isAllSupplier = false;
      if (e.target.value == "all-supplier") {
        isAllSupplier = true;
      }
      const suppierId = e.target.value;
      setSelectedSupplierForFilter(suppierId);

      if (!suppierId) {
        return;
      }

      let rfqs = [];
      let activeRfqs = rfqData.filter(
        (item) => item.rfqStatus == "in-progress" || item.rfqStatus == "active"
      );
      if (isAllSupplier) {
        rfqs = activeRfqs;
      } else {
        let arrayOfActiveRFQId = [];
        activeRfqs.forEach((item) => arrayOfActiveRFQId.push(item._id));
        let rfqForSupplier = supplierRFQData.filter(
          (item) =>
            arrayOfActiveRFQId.includes(item.rfqId) &&
            item.supplierId == suppierId
        );
        rfqs = rfqForSupplier;
      }

      let processsingPurchaseOrders = purchaseOrderData?.filter(
        (item) =>
          item.status == "Order Processing" &&
          !item.isCompleted &&
          (item.supplierId._id == suppierId || isAllSupplier)
      );
      let shippedPOs = purchaseOrderData?.filter(
        (item) =>
          item.status == "Shipped" &&
          !item.isCompleted &&
          (item.supplierId._id == suppierId || isAllSupplier)
      );

      let deliveredPOs = purchaseOrderData?.filter(
        (item) => item.status == "Delivered" && !item.isCompleted
      );

      let issuePurchaseOrders = purchaseOrderData?.filter(
        (item) =>
          item.status == ("Under Resolution" && !item.isCompleted) &&
          (item.supplierId._id == suppierId || isAllSupplier)
      );
      let completedPOs = purchaseOrderData?.filter(
        (item) =>
          (item.isCompleted || item.status == "Closed") &&
          (item.supplierId._id == suppierId || isAllSupplier)
      );

      let shippedPOValue = 0;
      let issuePOValue = 0;
      let completedPOValue = 0;
      let processingOrderValue = 0;
      let deliveredPOValue = 0;

      processsingPurchaseOrders.forEach(
        (item) => (processingOrderValue += item.totalAmount)
      );
      shippedPOs.forEach((item) => (shippedPOValue += item.totalAmount));
      issuePurchaseOrders.forEach((item) => (issuePOValue += item.totalAmount));
      completedPOs.forEach((item) => (completedPOValue += item.totalAmount));
      deliveredPOs.forEach((item) => (deliveredPOValue += item.totalAmount));

      setSupplierOrderTracker({
        rfqs: rfqs.length,
        purchaseOrders: processsingPurchaseOrders.length,
        issuePurchaseOrders: issuePurchaseOrders.length,
        shippedPOs: shippedPOs.length,
        completedPOs: completedPOs.length,
        deliveredPOs: deliveredPOs.length,
        processingOrderValue,
        shippedPOValue,
        issuePOValue,
        completedPOValue,
        deliveredPOValue,
      });

      const sevenDaysFromNow = moment().add(7, "days");
      const fourteenDaysFromNow = moment().add(14, "days");
      let esdOverDue = 0;
      let lessThan7Days = 0;
      let lessThan14Days = 0;
      let greaterThan14Days = 0;

      processsingPurchaseOrders.forEach((item) => {
        if (item.supplierId._id == suppierId || isAllSupplier) {
          let esdValue =
            item?.expectedShippingDates?.length > 0
              ? moment(
                item?.expectedShippingDates.reduce((maxObj, currentObj) => {
                  if (currentObj.updatedDate > maxObj.updatedDate) {
                    return currentObj;
                  }
                  return maxObj;
                }).updateValue
              ).format("DD MMM YYYY")
              : item.poAcceptanceDate
                ? moment(item.poAcceptanceDate)
                  .add(item?.supplierRFQId?.leadTime, "days")
                  .format("DD MMM YYYY")
                : "";

          if (esdValue) {
            if (moment(esdValue).isBefore(moment())) {
              esdOverDue++;
            } else if (
              moment(esdValue).isBefore(sevenDaysFromNow, "day") &&
              moment(esdValue).isSameOrAfter(moment())
            ) {
              lessThan7Days++;
            } else if (
              moment(esdValue).isSameOrBefore(fourteenDaysFromNow, "day") &&
              moment(esdValue).isSameOrAfter(sevenDaysFromNow, "day")
            ) {
              lessThan14Days++;
            } else if (moment(esdValue).isAfter(fourteenDaysFromNow, "day")) {
              greaterThan14Days++;
            }
          }
        }
      });

      setSupplierESDTrackerData({
        esdOverDue,
        lessThan7Days,
        lessThan14Days,
        greaterThan14Days,
      });

      let rfqCount = 0;
      let rfqLeadTime = 0;

      supplierRFQData.forEach((item) => {
        if (
          item.receivedDate &&
          item.quoteSubmitDate &&
          (item.supplierId == suppierId || isAllSupplier)
        ) {
          rfqCount++;
          rfqLeadTime += moment(item.quoteSubmitDate).diff(
            item.receivedDate,
            "days"
          );
        }
      });

      let poCount = 0;
      let poLeadTime = 0;

      purchaseOrderData.forEach((item) => {
        if (
          item.deliveryDate &&
          item.poApprovedDate &&
          (item.supplierId._id == suppierId || isAllSupplier)
        ) {
          poCount++;
          poLeadTime += moment(item.deliveryDate).diff(
            item.poApprovedDate,
            "days"
          );
        }
      });

      let latePOcount = 0;
      let totalOrderForLatePOs = 0;
      purchaseOrderData.forEach((item) => {
        if (item.supplierId == suppierId || isAllSupplier) {
          totalOrderForLatePOs++;
          if (item?.deliveryDate && item?.poApprovedDate) {
            if (
              moment(item.deliveryDate).diff(item.poApprovedDate, "days") >
              item.supplierRFQId.leadTime
            ) {
              latePOcount++;
            }
          }
        }
      });

      setSupplierLeadTimeTrackerData({
        rfqs: rfqCount > 0 ? Math.round(rfqLeadTime / rfqCount) : 0,
        purchaseOrders: poCount > 0 ? Math.round(poLeadTime / poCount) : 0,
        latePOs: latePOcount,
        totalPOsForLateOrders: totalOrderForLatePOs,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleDataChange = (type) => {
    if (
      (type == "customer" && state.dashboardFilter == "customer") ||
      (type == "supplier" && state.dashboardFilter == "supplier")
    ) {
      return;
    } else {
      if (type == "customer") {
        dispatch({
          type: ADMIN_ACTIONS.UPDATE_DASHBOARD_FILTER,
          payload: "customer",
        });
        // setDashboardFilter("customer");
      } else {
        dispatch({
          type: ADMIN_ACTIONS.UPDATE_DASHBOARD_FILTER,
          payload: "supplier",
        });
        // setDashboardFilter("supplier");
      }
    }
  };

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = 2020; year <= currentYear; year++) {
    years.push(year);
  }

  const handleMonthChange = (id) => {
    setSelectedMonth((pre) => {
      return pre.map((item) => {
        return (item.id == id) ? { ...item, checked: !item.checked } : item;
      })
    })
  };
  const handleNavigate = (path) => {
    // navigate(path);
    window.open(`${window.location.origin}${path}`, "_blank");
  };

  const searchUser = (e) => {
    setUserFilterValue(e.target.value);
    let localFilteredUser = [];

    if (e.target.value.length > 0) {
      localFilteredUser = customersForFilter.filter((item) =>
        (item.firstName + " " + item.lastName)
          .toUpperCase()
          .includes(e.target.value.toUpperCase())
      );
    }
    setFilteredCustomerUser(localFilteredUser);
  };

  return (
    <div className="admin-container">
      <span style={{ width: "100%" }}>
        <div className="right-container">
          <div className="center-heading">
            <h1 className="font-bold text-xl my-2">Welcome {userData?.firstName}</h1>
            <br />

            <div className="dashboard-div">
              <div className="header-container">
                <div className="col-md-3 header-div">
                  <button
                    className="req-quote-btn"
                    style={{
                      backgroundColor:
                        state.dashboardFilter == "customer"
                          ? "#2B96DC"
                          : "gray",
                      fontWeight: "bolder",
                    }}
                    onClick={() => handleDataChange("customer")}
                  >
                    CUSTOMER
                  </button>
                </div>

                <div className="col-md-3 header-div">
                  <button
                    className="req-quote-btn"
                    style={{
                      backgroundColor:
                        state.dashboardFilter == "supplier"
                          ? "#DC712B"
                          : "gray",
                      fontWeight: "bolder",
                    }}
                    onClick={() => handleDataChange("supplier")}
                  >
                    SUPPLIER
                  </button>
                </div>
                <div className="col-md-2 header-div ms-2 relative">
                  <label className="flex items-center justify-between  gap-x-2 bg-white w-44 h-10 mt-3 px-3 p-1 rounded" onClick={() => setIsMonthDropDownOn((pre) => !pre)}>
                    <span>Select Month</span>
                    <DropDownIcon additionalClasses={`h-5 ${isMonthDropDownOn && "rotate-180"}`} />
                  </label>
                  {isMonthDropDownOn && <div className="absolute flex  flex-col gap-y-2 z-10 py-2 w-44 px-3  bg-white">
                    {selectedMonth.map((month) => {
                      return <div class="flex " onClick={(e) => {
                        e.preventDefault();
                        handleMonthChange(month.id)
                      }}>
                        <input
                          checked={month.checked}
                          id="checked-checkbox"
                          type="checkbox"
                          className="w-4 h-4  text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
                        />
                        <label htmlFor="checked-checkbox" class="ms-2 text-sm font-medium text-gray-">{month.value}</label>
                      </div>
                    })}
                  </div>}

                </div>
                <div className="col-md-2 header-div ms-2">
                  <label> Select Year</label>
                  <Form.Select onChange={handleYearChange} value={selectedYear}>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
              <div className="content-container">
                {state.dashboardFilter == "customer" ? (
                  <>
                    <div className="order-tracker">
                      <div className="header-div">
                        <div className="customer-filter-div">
                          <div className="filter-item">
                            <label> Company Filter</label>
                            <div>
                              <Form.Select
                                onChange={(e) =>
                                  handleCompanyFilterChange(e.target.value)
                                }
                                defaultValue={
                                  selectedCompanyForFilter || "default"
                                }
                              >
                                <option disabled value="default">
                                  Select a company
                                </option>
                                <option value="all-company">All Company</option>
                                {allCustomerCompanies.map((item, index) => (
                                  <option key={index} value={item._id}>
                                    {item.companyName}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                          </div>
                          {selectedCompanyForFilter &&
                            selectedCompanyForFilter != "all-company" ? (
                            <>
                              <div className="filter-item">
                                <label> Customer Filter</label>
                                <div className="share-quote-div">
                                  <FormControl
                                    className="follower-input"
                                    value={userFilterValue}
                                    placeholder="Search user"
                                    onChange={(e) => searchUser(e)}
                                    style={{ width: "200px" }}
                                  />
                                  {filteredCustomerUser.length > 0 ? (
                                    <div
                                      className="follower-drop-down-div"
                                      style={{
                                        width: "200px",
                                        border: "1px solid #ced4da",
                                        borderRadius: "5px",
                                        padding: ".375rem .75rem",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      {filteredCustomerUser.length > 0 &&
                                        filteredCustomerUser?.map((item, i) => {
                                          return (
                                            <div
                                              key={i}
                                              className="follower-drop-down"
                                              onClick={() =>
                                                handleCustomerFilterChange(
                                                  "push",
                                                  item._id
                                                )
                                              }
                                            >
                                              <p>
                                                {item.firstName +
                                                  " " +
                                                  item.lastName}
                                              </p>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                              <div className="filter-item">
                                <label> Selected Users For Filter</label>
                                <ul>
                                  {allCustomerUsers
                                    .filter((item) =>
                                      selectedCustomerForFilter.has(item._id)
                                    )
                                    ?.map((item) => {
                                      return (
                                        <li>
                                          {item.firstName + " " + item.lastName}{" "}
                                          &nbsp;&nbsp;{" "}
                                          <img
                                            src={deleteImage}
                                            height="15px"
                                            style={{ height: "15px" }}
                                            onClick={() =>
                                              handleCustomerFilterChange(
                                                "pop",
                                                item._id
                                              )
                                            }
                                          />
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <h3>Order Tracker</h3>
                      </div>

                      <div className="row-div">
                        <div className="col-div-container">
                          <div className="col-div">
                            <h5 className="my-2">Order Summary</h5>
                            {isLoading ? (
                              <>
                                {/* <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={loadingImage}
                                    style={{ height: "20px" }}
                                  />{" "}
                                  Loading...
                                </span> */}
                                <SkeltonLoadingComponent />
                                <SkeltonLoadingComponent />

                              </>
                            ) : (
                              <>
                                <table>
                                  <thead>
                                    <tr
                                      style={{
                                        background: "#2B96DC",
                                        color: "white",
                                      }}
                                    >
                                      <th>Category</th>
                                      <th>No. of Order(s)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      onClick={() =>
                                        handleNavigate("/admin/quotes")
                                      }
                                    >
                                      <td className="label-td">
                                        Quotations :{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {customerOrderSummaryData?.quotations
                                          ? customerOrderSummaryData?.quotations
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr
                                      onClick={() =>
                                        handleNavigate("/admin/orders")
                                      }
                                    >
                                      <td className="label-td">
                                        Sales Orders :{" "}
                                      </td>
                                      <td>
                                        {customerOrderSummaryData?.salesOrders
                                          ? customerOrderSummaryData?.salesOrders
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr
                                      onClick={() =>
                                        handleNavigate("/admin/orders")
                                      }
                                    >
                                      <td className="label-td">
                                        Shipped Orders :{" "}
                                      </td>
                                      <td>
                                        {customerOrderSummaryData?.shippedOrders
                                          ? customerOrderSummaryData?.shippedOrders
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr
                                      onClick={() =>
                                        handleNavigate("/admin/orders")
                                      }
                                    >
                                      <td className="label-td">
                                        Orders Completed :{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {customerOrderSummaryData?.completedOrders
                                          ? customerOrderSummaryData?.completedOrders
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr
                                      onClick={() =>
                                        handleNavigate("/admin/orders")
                                      }
                                    >
                                      <td className="label-td">
                                        Sales Order Issues :{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {customerOrderSummaryData?.issueSalesOrders
                                          ? customerOrderSummaryData?.issueSalesOrders
                                          : 0}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            )}
                          </div>
                          <div className="col-div">
                            <h5 className="my-2">Quotation Description Summary</h5>
                            {isLoading ? (
                              <>
                                <SkeltonLoadingComponent />
                                <SkeltonLoadingComponent />
                              </>
                            ) : (
                              <>
                                <table>
                                  <thead>
                                    <tr
                                      style={{
                                        background: "#2B96DC",
                                        color: "white",
                                      }}
                                    >
                                      <th>Category</th>
                                      <th>No. of Order(s)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {quoteVersionDescriptions.map(
                                      (item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td className="label-td">
                                              {item.value}{" "}
                                            </td>
                                            <td>
                                              {" "}
                                              {
                                                quotationDescriptionSummaryData[
                                                item.id
                                                ]
                                              }
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="col-div">
                          <h5 className="my-2">Process Selected Summary</h5>

                          {isLoading ? (
                            <>
                              <SkeltonLoadingComponent />
                              <SkeltonLoadingComponent />
                              <SkeltonLoadingComponent />
                              <SkeltonLoadingComponent />
                            </>
                          ) : (
                            <>
                              <table>
                                <thead>
                                  <tr
                                    style={{
                                      background: "#2B96DC",
                                      color: "white",
                                    }}
                                  >
                                    <th>Process</th>
                                    <th>No. of Order(s)</th>
                                    <th>Total Sales Value (Inc Tax) </th>
                                    <th>Total Sales Value (Ex Tax) </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {processType.map((item, index) => {
                                    if (index == 8 || index == 9) {
                                      return (
                                        <tr key={index}>
                                          <td className="label-td">
                                            {item.name}{" "}
                                            {item.id <= 2 ? "(Automated)" : ""}{" "}
                                          </td>
                                          <td>
                                            {" "}
                                            {
                                              processSelectedSummaryData[
                                                item.id - 1
                                              ].numberOfOrder
                                            }
                                          </td>
                                          <td>
                                            {currencyFormatter(
                                              processSelectedSummaryData[
                                                item.id - 1
                                              ].totalSalesValue
                                            )}
                                          </td>
                                          <td>
                                            {currencyFormatter(
                                              processSelectedSummaryData[
                                                item.id - 1
                                              ].totalSalesValueWithoutTax
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })}
                                  {processType.map((item, index) => {
                                    if (index != 8 && index != 9) {
                                      return (
                                        <tr key={index}>
                                          <td className="label-td">
                                            {item.name}{" "}
                                            {item.id <= 2 ? "(Automated)" : ""}{" "}
                                          </td>
                                          <td>
                                            {" "}
                                            {
                                              processSelectedSummaryData[
                                                item.id - 1
                                              ].numberOfOrder
                                            }
                                          </td>
                                          <td>
                                            {currencyFormatter(
                                              processSelectedSummaryData[
                                                item.id - 1
                                              ].totalSalesValue
                                            )}
                                          </td>
                                          <td>
                                            {currencyFormatter(
                                              processSelectedSummaryData[
                                                item.id - 1
                                              ].totalSalesValueWithoutTax
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })}

                                  <tr >
                                    <td className="label-td">

                                      Total
                                    </td>
                                    <td>
                                      {
                                        processSelectedSummaryData.reduce(
                                          (total, obj) => total + obj.numberOfOrder,
                                          0
                                        )
                                      }
                                    </td>
                                    <td>
                                      {currencyFormatter(
                                        processSelectedSummaryData.reduce(
                                          (total, obj) => total + obj.totalSalesValue,
                                          0
                                        )
                                      )}
                                    </td>
                                    <td>
                                      {currencyFormatter(
                                        processSelectedSummaryData.reduce(
                                          (total, obj) => total + obj.totalSalesValueWithoutTax,
                                          0
                                        )
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="row-div">
                        <div className="col-div-container">
                          <h3>Lead Time Tracker</h3>
                          <div className="col-div">
                            {isLoading ? (
                              <>
                                <SkeltonLoadingComponent />
                                <SkeltonLoadingComponent />
                              </>
                            ) : (
                              <>
                                <table>
                                  <thead>
                                    <tr
                                      style={{
                                        background: "#2B96DC",
                                        color: "white",
                                      }}
                                    >
                                      <th>Category</th>
                                      <th>Average Data </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="label-td">
                                        Quotations (days):{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {leadTimeTrackerData?.quotations
                                          ? leadTimeTrackerData?.quotations
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="label-td">
                                        Sales Orders (days):{" "}
                                      </td>
                                      <td>
                                        {leadTimeTrackerData?.salesOrders
                                          ? leadTimeTrackerData?.salesOrders
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="label-td">
                                        Late Orders :{" "}
                                      </td>
                                      <td>
                                        {leadTimeTrackerData?.lateOrders
                                          ? leadTimeTrackerData?.lateOrders
                                          : 0}{" "}
                                        /{" "}
                                        {leadTimeTrackerData?.totalOrdersForLateOrders
                                          ? leadTimeTrackerData?.totalOrdersForLateOrders
                                          : 0}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="col-div-container">
                          <h3>CEDD Tracker</h3>
                          <div className="col-div">
                            {isLoading ? (
                              <>
                                <SkeltonLoadingComponent />
                                <SkeltonLoadingComponent />
                              </>
                            ) : (
                              <>
                                <table>
                                  <thead>
                                    <tr
                                      style={{
                                        background: "#2B96DC",
                                        color: "white",
                                      }}
                                    >
                                      <th>Category</th>
                                      <th>No. of Order(s)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="label-td">
                                        CEDD Overdue :{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {ceddTrackerData?.ceddOverDue
                                          ? ceddTrackerData?.ceddOverDue
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="label-td">
                                        Less than 7 Days :{" "}
                                      </td>
                                      <td>
                                        {ceddTrackerData?.lessThan7Days
                                          ? ceddTrackerData?.lessThan7Days
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="label-td">7-14 Days : </td>
                                      <td>
                                        {" "}
                                        {ceddTrackerData?.lessThan14Days
                                          ? ceddTrackerData?.lessThan14Days
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="label-td">
                                        Greater than 14 Days :{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {ceddTrackerData?.greaterThan14Days
                                          ? ceddTrackerData?.greaterThan14Days
                                          : 0}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {/* CODE FOR SUPPLIER DATA STARTS HERE */}
                    <div className="order-tracker">
                      <div className="header-div">
                        <div className="customer-filter-div">
                          <div className="filter-item">
                            <label> Supplier Filter</label>
                            <Form.Select
                              onChange={handleSupplierFilterChange}
                              defaultValue={
                                selectedSupplierForFilter || "default"
                              }
                            >
                              <option disabled value="default">
                                Select a supplier
                              </option>
                              <option value="all-supplier">All supplier</option>
                              {allSupplierCompanies.map((item, index) => (
                                <option key={index} value={item._id}>
                                  {item.companyName}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                      </div>

                      <div className="row-div">
                        <div className="col-div-container">
                          <h3>Order Tracker</h3>
                          <div className="col-div">
                            {isLoading ? (
                              <>
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={loadingImage}
                                    style={{ height: "20px" }}
                                  />{" "}
                                  Loading...
                                </span>
                              </>
                            ) : (
                              <>
                                <table>
                                  <thead>
                                    <tr
                                      style={{
                                        background: "#DC712B",
                                        color: "white",
                                      }}
                                    >
                                      <th>Category</th>
                                      <th>No. of Order(s)</th>
                                      <th>Total Sales Value</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      onClick={() =>
                                        handleNavigate("/admin/rfqs")
                                      }
                                    >
                                      <td className="label-td">RFQ : </td>
                                      <td>
                                        {" "}
                                        {supplierOrderTracker?.rfqs
                                          ? supplierOrderTracker?.rfqs
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr
                                      onClick={() =>
                                        handleNavigate("/admin/purchase-orders")
                                      }
                                    >
                                      <td className="label-td">
                                        Processing Orders :{" "}
                                      </td>
                                      <td>
                                        {supplierOrderTracker?.purchaseOrders
                                          ? supplierOrderTracker?.purchaseOrders
                                          : 0}
                                      </td>
                                      <td>
                                        {currencyFormatter(
                                          supplierOrderTracker?.processingOrderValue
                                            ? supplierOrderTracker?.processingOrderValue
                                            : 0
                                        )}
                                      </td>
                                    </tr>

                                    <tr
                                      onClick={() =>
                                        handleNavigate("/admin/purchase-orders")
                                      }
                                    >
                                      <td className="label-td">
                                        Shipped PO :{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {supplierOrderTracker?.shippedPOs
                                          ? supplierOrderTracker?.shippedPOs
                                          : 0}
                                      </td>
                                      <td>
                                        {currencyFormatter(
                                          supplierOrderTracker?.shippedPOValue
                                            ? supplierOrderTracker?.shippedPOValue
                                            : 0
                                        )}
                                      </td>
                                    </tr>

                                    <tr
                                      onClick={() =>
                                        handleNavigate("/admin/purchase-orders")
                                      }
                                    >
                                      <td className="label-td">
                                        Delivered PO :{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {supplierOrderTracker?.deliveredPOs
                                          ? supplierOrderTracker?.deliveredPOs
                                          : 0}
                                      </td>
                                      <td>
                                        {currencyFormatter(
                                          supplierOrderTracker?.deliveredPOValue
                                            ? supplierOrderTracker?.deliveredPOValue
                                            : 0
                                        )}
                                      </td>
                                    </tr>

                                    <tr
                                      onClick={() =>
                                        handleNavigate("/admin/purchase-orders")
                                      }
                                    >
                                      <td className="label-td">
                                        Completed PO :{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {supplierOrderTracker?.completedPOs
                                          ? supplierOrderTracker?.completedPOs
                                          : 0}
                                      </td>
                                      <td>
                                        {currencyFormatter(
                                          supplierOrderTracker?.completedPOValue
                                            ? supplierOrderTracker?.completedPOValue
                                            : 0
                                        )}
                                      </td>
                                    </tr>

                                    <tr
                                      onClick={() =>
                                        handleNavigate("/admin/purchase-orders")
                                      }
                                    >
                                      <td className="label-td">
                                        Purchase Order Issues :{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {supplierOrderTracker?.issuePurchaseOrders
                                          ? supplierOrderTracker?.issuePurchaseOrders
                                          : 0}
                                      </td>
                                      <td>
                                        {currencyFormatter(
                                          supplierOrderTracker?.issuePOValue
                                            ? supplierOrderTracker?.issuePOValue
                                            : 0
                                        )}
                                      </td>
                                    </tr>

                                    <tr >
                                      <td className="label-td">

                                        Total for PO
                                      </td>
                                      <td>
                                        {
                                          (
                                            (supplierOrderTracker?.purchaseOrders || 0) +
                                            (supplierOrderTracker?.shippedPOs || 0) +
                                            (supplierOrderTracker?.deliveredPOs || 0) +
                                            (supplierOrderTracker?.completedPOs || 0) +
                                            (supplierOrderTracker?.issuePurchaseOrders || 0))
                                        }
                                      </td>
                                      <td>
                                        {
                                          currencyFormatter(
                                            (supplierOrderTracker?.processingOrderValue || 0) +
                                            (supplierOrderTracker?.shippedPOValue || 0) +
                                            (supplierOrderTracker?.deliveredPOValue || 0) +
                                            (supplierOrderTracker?.completedPOValue || 0) +
                                            (supplierOrderTracker?.issuePOValue))
                                        }
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            )}
                          </div>
                        </div>

                        <div className="col-div-container">
                          <h3>ESD Tracker</h3>
                          <div className="col-div">
                            {isLoading ? (
                              <>
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={loadingImage}
                                    style={{ height: "20px" }}
                                  />{" "}
                                  Loading...
                                </span>
                              </>
                            ) : (
                              <>
                                <table>
                                  <thead>
                                    <tr
                                      style={{
                                        background: "#DC712B",
                                        color: "white",
                                      }}
                                    >
                                      <th>Category</th>
                                      <th>No. of Order(s)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="label-td">
                                        ESD Overdue :{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {supplierESDTrackerData?.esdOverDue
                                          ? supplierESDTrackerData?.esdOverDue
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="label-td">
                                        Less than 7 Days :{" "}
                                      </td>
                                      <td>
                                        {supplierESDTrackerData?.lessThan7Days
                                          ? supplierESDTrackerData?.lessThan7Days
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="label-td">7-14 Days : </td>
                                      <td>
                                        {" "}
                                        {supplierESDTrackerData?.lessThan14Days
                                          ? supplierESDTrackerData?.lessThan14Days
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="label-td">
                                        Greater than 14 Days :{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {supplierESDTrackerData?.greaterThan14Days
                                          ? supplierESDTrackerData?.greaterThan14Days
                                          : 0}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        className="row-div"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <div
                          className="col-div-container"
                          style={{ maxWidth: "50%" }}
                        >
                          <h3>Supplier Lead Time Tracker</h3>
                          <div className="col-div">
                            {isLoading ? (
                              <>
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={loadingImage}
                                    style={{ height: "20px" }}
                                  />{" "}
                                  Loading...
                                </span>
                              </>
                            ) : (
                              <>
                                <table>
                                  <thead>
                                    <tr
                                      style={{
                                        background: "#DC712B",
                                        color: "white",
                                      }}
                                    >
                                      <th>Category</th>
                                      <th>Average Data </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="label-td">
                                        RFQs (days):{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        {supplierLeadTimeTrackerData?.rfqs
                                          ? supplierLeadTimeTrackerData?.rfqs
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="label-td">
                                        Purchase Orders (days):{" "}
                                      </td>
                                      <td>
                                        {supplierLeadTimeTrackerData?.purchaseOrders
                                          ? supplierLeadTimeTrackerData?.purchaseOrders
                                          : 0}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="label-td">Late POs : </td>
                                      <td>
                                        {supplierLeadTimeTrackerData?.latePOs
                                          ? supplierLeadTimeTrackerData?.latePOs
                                          : 0}{" "}
                                        /{" "}
                                        {supplierLeadTimeTrackerData?.totalPOsForLateOrders
                                          ? supplierLeadTimeTrackerData?.totalPOsForLateOrders
                                          : 0}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </span>
    </div>
  );
};
export default AdminDashBoardComponent(AdminDashboard);
