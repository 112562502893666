import React, {
  useRef,
} from "react";

import {
  Color,
} from "three";

import { useThree, useFrame } from "react-three-fiber";

import Measure from "./Measure";
import Line from "./Line";

export default function GridFloor({visible, gridSize, measureScale, originPoint}) {
  const gridDivisions = 10;

  const {camera} = useThree();
  const sceneRef = useRef();
  useFrame(({gl}) => {
    gl.autoClear = false;
    gl.clippingPlanes = [];
    gl.render(sceneRef.current, camera);
  }, 3);

  return (
    <>
      <scene
        ref={sceneRef}
        visible={visible}
      >
        <group
          position={originPoint}
        >
          <gridHelper
            args={[gridSize, gridDivisions, new Color(0.025, 0.025, 0.025), new Color(0.025, 0.025, 0.025)]}
          />
          <Line
            point1={[gridSize/2, 0, gridSize/2]}
            point2={[gridSize/2, -1.5*gridSize/10, gridSize/2]}
          />
          <Measure
            point1={[gridSize/2, -0.5*gridSize/10, gridSize/2]}
            point2={[gridSize/2, -0.5*gridSize/10, gridSize/2-(gridSize/gridDivisions)]}
            scale={measureScale}
          />
          <Line
            point1={[gridSize/2, 0*gridSize/10, gridSize/2-(gridSize/gridDivisions)]}
            point2={[gridSize/2, -0.75*gridSize/10, gridSize/2-(gridSize/gridDivisions)]}
          />
          <Measure
            point1={[gridSize/2, -1.25*gridSize/10, gridSize/2]}
            point2={[gridSize/2, -1.25*gridSize/10, -gridSize/2]}
            scale={measureScale}
          />
          <Line
            point1={[gridSize/2, 0, -gridSize/2]}
            point2={[gridSize/2, -1.5*gridSize/10, -gridSize/2]}
          />
        </group>
      </scene>
    </>
  );
}