import { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { getToken, validateAdmin } from "../../utils/helper";
import {
  createEightXAddress,
  getEightXAddress,
  updateEightXAddress,
  deleteEightXAddress,
  setAddressAsDefault,
} from "../../utils/actions/allactions";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import moment from "moment";
import Switch from "react-input-switch";
import useDocumentTitle from "../../utils/useDocumentTitle";

const EightxAddressMaster = () => {
  let navigate = useNavigate();
  useDocumentTitle("8xParts Address");
  const [data, setData] = useState(null);
  const [addresses, setAddresses] = useState([]);
  let token = getToken();
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState("");

  const fetch8xPartsAddress = async () => {
    try {
      const addressData = await getEightXAddress(token);
      if (addressData.status) {
        setAddresses(addressData.data);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleEdit8xPartsAddress = (item) => {
    try {
      setData({ ...item });
      setIsEdit(true);
    } catch (err) {
      console.log(
        "Error in 8xParts address on 42" +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const validateData = () => {
    if (
      !data?.firstName ||
      !data?.addressLineOne ||
      !data?.city ||
      !data?.country
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleCreateNew = async () => {
    setError("");
    try {
      const isValidData = validateData();
      if (isValidData) {
        const response = await createEightXAddress(data);
        if (response.status) {
          setAddresses([...addresses, response.data]);
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
        return;
      }
      setError(
        "FirstName, AddressLineOne, City and Country are required fields."
      );
    } catch (error) {
      toast.error("Unable To Create New Address");
    }
  };

  const handleUpdateAddress = async () => {
    setError("");
    const isValidData = validateData();
    try {
        if (isValidData) {
            const response = await updateEightXAddress(data);
            if (response.status) {
              setAddresses([...addresses, response.data]);
              let localAddresses = [...addresses];
      
              for (let i = 0; i < localAddresses.length; i++) {
                if (localAddresses[i]._id == data._id) {
                  localAddresses[i] = data;
                }
              }
              setAddresses(localAddresses);
              toast.success(response.message);
              setIsEdit(false);
              setData(null);
            } else {
              toast.error(response.message);
            }
            return;
          }
          setError(
            "FirstName, AddressLineOne, City and Country are required fields."
          );
        
    } catch (error) {
        toast.error("Unable To Update Address");
    }
  };

  const handleDeleteAddress = async (id) => {
    try {
      const adminResponse = confirm(
        "Are you sure you want to delete this address?"
      );
      if (adminResponse) {
        const deleteResponse = await deleteEightXAddress(id);
        if (deleteResponse.status) {
          toast.success(deleteResponse.message);
          let localAddresses = [...addresses];
          localAddresses = localAddresses.filter((item) => item._id != id);
          setAddresses(localAddresses);
        } else {
          toast.error(deleteResponse.message);
        }
      }
    } catch (err) {
        toast.error("Unable To Delete Address");
    }
  };

  useEffect(() => {
    validateAdmin(token, navigate);
    fetch8xPartsAddress();
  }, []);

  const handleSwitchClick = async (addressData) => {
    try {
      if (addressData._id) {
        let adminResponse = confirm(
          "Are you sure you want to set this address as default address?"
        );
        if (!adminResponse) {
          return;
        }
        const response = await setAddressAsDefault(token, {
          addressId: addressData._id,
        });
        if (response.status) {
          setAddresses(response.data);
          toast.success("Default address changed successfully.");
        }
      }
    } catch (err) {
      console.log(
        "Error in 523 RFQ Details on " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  return (
    <div
      className="admin-container"
      style={{
        width: "100%",
        overflowX: "auto",
        backgroundColor: "#f4e6dd",
        minHeight: "100vh",
      }}
    >
      <Sidebar />
      <div className="right-container">
        <div className="center-heading">
          <div
            className="admin-user-container"
            style={{
              border: "1px solid #bdc1c6",
              borderRadius: "3px",
              padding: "10px 0px",
              background: "#fff",
            }}
          >
            <div className="admin-user-header">8xParts Address</div>
            <div className="admin-user-body">
              <div className="admin-user-name">
                <input
                  type="text"
                  placeholder="First name"
                  value={`${data?.firstName ? data?.firstName : ""}`}
                  onChange={(e) => {
                    setData({ ...data, firstName: e.target.value });
                  }}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={`${data?.lastName ? data?.lastName : ""}`}
                  onChange={(e) => {
                    setData({ ...data, lastName: e.target.value });
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    marginTop: "10px",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "40%" }}>
                    <PhoneInput
                      placeholder="Enter phone number"
                      name="phone"
                      value={`${data?.phoneNo ? data?.phoneNo : ""}`}
                      onChange={(val) => setData({ ...data, phoneNo: val })}
                    />
                  </div>
                </div>
              </div>
              <div className="admin-user-name">
                <input
                  type="text"
                  placeholder="Address Line 1"
                  value={`${data?.addressLineOne ? data?.addressLineOne : ""}`}
                  onChange={(e) => {
                    setData({ ...data, addressLineOne: e.target.value });
                  }}
                />
                <input
                  type="text"
                  placeholder="Address Line 2"
                  value={`${data?.addressLineTwo ? data?.addressLineTwo : ""}`}
                  onChange={(e) => {
                    setData({ ...data, addressLineTwo: e.target.value });
                  }}
                />

                <input
                  type="text"
                  placeholder="Postal Code"
                  value={`${data?.postalCode ? data?.postalCode : ""}`}
                  onChange={(e) => {
                    setData({ ...data, postalCode: e.target.value });
                  }}
                />
              </div>

              <div className="admin-user-name">
                <input
                  type="text"
                  placeholder="City"
                  value={`${data?.city ? data?.city : ""}`}
                  onChange={(e) => {
                    setData({ ...data, city: e.target.value });
                  }}
                />

                <input
                  type="text"
                  placeholder="State"
                  value={`${data?.state ? data?.state : ""}`}
                  onChange={(e) => {
                    setData({ ...data, state: e.target.value });
                  }}
                />

                <input
                  type="text"
                  placeholder="Country"
                  value={`${data?.country ? data?.country : ""}`}
                  onChange={(e) => {
                    setData({ ...data, country: e.target.value });
                  }}
                />
              </div>
            </div>
            {error ? <p style={{ color: "red" }}>{error}</p> : <></>}

            {isEdit ? (
              <>
                <button onClick={handleUpdateAddress} className="req-quote-btn">
                  Update Address
                </button>
              </>
            ) : (
              <>
                <button onClick={handleCreateNew} className="req-quote-btn">
                  Create Address
                </button>
              </>
            )}

            <p
              style={{
                fontSize: "14px",
                color: "red",
                textAlign: "left",
                padding: "0px 20px",
              }}
            >
              {`Note : These addresses are applicable to POs only.`}
            </p>
          </div>

          <div className="quotes-table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    First Name
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Last Name
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Address Line 1
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Address Line 2
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Phone Number
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Postal Code
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    City
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    State
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Country
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Default
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {addresses?.length > 0 &&
                  addresses.map((item, i) => {
                    return (
                      <tr key={i} style={{ backgroundColor: "#fff" }}>
                        <td>{item.firstName ? item.firstName : ""}</td>
                        <td>{item.lastName ? item.lastName : ""}</td>
                        <td>
                          {item.addressLineOne ? item.addressLineOne : ""}
                        </td>
                        <td>
                          {item.addressLineTwo ? item.addressLineTwo : ""}
                        </td>
                        <td>{item.phoneNo ? "+" + item.phoneNo : ""}</td>
                        <td>{item.postalCode ? item.postalCode : ""}</td>
                        <td>{item.city ? item.city : ""}</td>
                        <td>{item.state ? item.state : ""}</td>
                        <td>{item.country ? item.country : ""}</td>
                        <td>
                          <Switch
                            className="custom-switch"
                            on="true"
                            off="false"
                            value={item?.isDefault?.toString()}
                            onChange={() => {
                              if (item.isDefault) {
                                toast.error(
                                  "Selected address is already the default address."
                                );
                                return;
                              }
                              handleSwitchClick(item);
                            }}
                          />
                        </td>
                        <td>
                          <button
                            className="button-edit"
                            onClick={() => {
                              handleEdit8xPartsAddress(item);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="button-delete"
                            onClick={() => {
                              handleDeleteAddress(item._id);
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EightxAddressMaster;
