import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useEffect, useState, Fragment } from "react";
import { numberInputOnWheelPreventChange } from "../../../../utils/helper";
import { DeleteIcon } from "../../../../components/Icons";
import AutoResizingTextarea from "../../../../components/AutoResizeTextAreaComponent/AutoResizeTextAreaComponent";
import DynamicWidthInput from "../../../../components/AutoResizeTextAreaComponent/DynamicWithInput";

const ManualProcessLinesUI = (props) => {
  let { quoteId } = useParams();
  let index = props.index;
  let val = props.values[index];
  const [itemDescription, setItemDescription] = useState("");
  const [itemNotes, setItemNotes] = useState(val.Notes);
  const [itemQuantity, setItemQuantity] = useState(val.Qty);
  const [linePartName, setLinePartName] = useState(val.linePartName);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const repeatOrder = searchParams.get('RepeatOrder') == "true" ? true : false;
  const isRepeatOrderWithUpdate = searchParams.get('isRepeatOrderWithUpdate') == "true" ? true : false;



  const handleChangeRouteForRepeatOrder = () => {
    // let userConfirmation = confirm("Are you sure you want to change the selected files for the previous quotation? This action will generate a new version of the quotation, which will require admin review before you can proceed with creating an order.");
    if (userConfirmation) {
      navigate(`/create-instant-quotation/${quoteId}?isRepeatOrderWithUpdate=true`)
    }
  }


  useEffect(() => {
    if (quoteId) {
      setItemDescription(props.values[index].description)
      setItemNotes(props.values[index].Notes)
      setItemQuantity(props.values[index].Qty)
      setLinePartName(props.values[index].linePartName)
    }
  }, [])

  useEffect(() => {
    if (quoteId) {
      setItemDescription(props.values[index].description)
      setItemNotes(props.values[index].Notes)
      setItemQuantity(props.values[index].Qty)
      setLinePartName(props.values[index].linePartName)
    }
  }, [props.values])


  const onDescriptionChange = (event) => {
    if (repeatOrder) {
      handleChangeRouteForRepeatOrder()
      return;
    }
    setItemDescription(event.target.value);
    val.description = event.target.value;
  }

  const handlePartNameChange = (event) => {
    if (repeatOrder) {
      handleChangeRouteForRepeatOrder()
      return;
    }
    setLinePartName(event.target.value)
    val.linePartName = event.target.value;
  }


  const onNotesChange = (event) => {
    if (repeatOrder) {
      handleChangeRouteForRepeatOrder()
      return;
    }
    setItemNotes(event.target.value);
    val.Notes = event.target.value;
  }

  const onQuantityChange = (event) => {
    if (repeatOrder) {
      handleChangeRouteForRepeatOrder()
      return;
    }
    setItemQuantity(event.target.value);
    val.Qty = event.target.value;
  }

  const onQuantityChangeWithIcon = (value) => {
    if (repeatOrder) {
      handleChangeRouteForRepeatOrder()
      return;
    }
    if (value <= 0) {
      return;
    }
    setItemQuantity(value);
    val.Qty = value;
  }


  return (
    <Fragment>
      {
        (val.file || val.selectedFile) ? (
          <tr key={index}>
            <td >
              <input
                type="text"
                readOnly
                value={
                  (val.file ? val.file.originalname : val?.selectedFile?.path ? val?.selectedFile?.path : "Name not found!")?.length > 25
                    ? (val.file ? val.file.originalname : val?.selectedFile?.path ? val?.selectedFile?.path : "Name not found!").substring(0, 25) + "..."
                    : (val.file ? val.file.originalname : val?.selectedFile?.path ? val?.selectedFile?.path : "Name not found!")
                }
                className="border border-gray-300 rounded p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                title={
                  val.file
                    ? val.file.originalname
                    : val?.selectedFile?.path
                      ? val?.selectedFile?.path
                      : "Name not found!"
                }
              />

            </td>
            <td >
              {/* <textarea
                name="descripton"
                className="description"
                type="text"
                placeholder="Enter description here"
                value={itemDescription}
                onChange={onDescriptionChange}
              /> */}
              <AutoResizingTextarea
                value={itemDescription}
                onChange={onDescriptionChange}
                name="descripton"
                maxLength={50}
              />
            </td>
            <td >
              {/* <Form.Control
                name="notes"
                type="text"
                placeholder="Enter notes here"
                value={itemNotes}
                onChange={onNotesChange}
              /> */}
              <AutoResizingTextarea
                value={itemNotes}
                onChange={onNotesChange}
                name={'notes'}
                maxLength={100}
              />
            </td>
            <td className="flex items-center mt-3">
              {/* <Form.Control
                name="quantity"
                className="quantity"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                value={val.Qty}
                onChange={(e) => {
                  if (!e.target.value || Number(e.target.value) < 1) {
                    return;
                  }
                  onQuantityChange(e)
                }}
                style={{
                  display: 'inline'
                }}
              /> */}



              <DynamicWidthInput
                name={'quantity'}
                className='quantity'
                onChange={(e) => {
                  if (!e.target.value || Number(e.target.value) < 1) {
                    return;
                  }
                  onQuantityChange(e)
                }}
                value={val.Qty}
                minWidth={80}
              />
            </td>

            <td className="text-center">
              <button
                onClick={() => props.onDelete(index)}
              >
                <DeleteIcon />
              </button>
            </td>
          </tr>)
          : val.selectedFile ? (
            <tr key={index}>
              <td>
                <h6>{val.selectedFile.name}</h6>
              </td>
              <td >
                {/* <textarea
                  name="description"
                  className="description"
                  type="textarea"
                  placeholder="Enter description here"
                  value={itemDescription}
                  onChange={onDescriptionChange}
                /> */}
                <AutoResizingTextarea
                  value={itemDescription}
                  onChange={onDescriptionChange}
                  name="descripton"
                  maxLength={50}
                />

              </td>
              <td >
                <AutoResizingTextarea
                  value={itemNotes}
                  onChange={onNotesChange}
                  name={'notes'}
                  maxLength={100}
                />
              </td>
              <td className="flex items-center">
                {/* <Form.Control
                  name="quantity"
                  className="quantity"
                  type="number"
                  onWheel={numberInputOnWheelPreventChange}
                  onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  value={itemQuantity}
                  onChange={(e) => {
                    if (!e.target.value || Number(e.target.value) < 1) {
                      return;
                    }
                    onQuantityChange(e)
                  }}
                /> */}
                <DynamicWidthInput
                  name={'quantity'}
                  className='quantity mt-5'
                  onChange={(e) => {
                    if (!e.target.value || Number(e.target.value) < 1) {
                      return;
                    }
                    onQuantityChange(e)
                  }}
                  value={val.Qty}
                  minWidth={80}
                />
              </td>

              <td className="text-center">
                <button
                  onClick={() => props.onDelete(index)}
                >
                  <DeleteIcon />
                </button>
              </td>
            </tr>
          )
            : (
              <tr key={index}>
                <td>
                  <Form.Control
                    name='linePartName'
                    className="part-name"
                    type="text"
                    value={linePartName}
                    placeholder="Enter part name here"
                    onChange={handlePartNameChange}
                  />
                </td>
                <td >
                  <AutoResizingTextarea
                    value={itemDescription}
                    onChange={onDescriptionChange}
                    name="descripton"
                    maxLength={50}
                  />
                </td>
                <td >
                  {/* <Form.Control
                    name="notes"
                    type="text"
                    placeholder="Enter notes here"
                    value={itemNotes}
                    onChange={onNotesChange}
                  /> */}
                  <AutoResizingTextarea
                    value={itemNotes}
                    onChange={onNotesChange}
                    name={'notes'}
                    maxLength={100}
                  />
                </td>
                <td className="flex items-center">
                  <DynamicWidthInput
                    name={'quantity'}
                    className='quantity mt-3'
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value) < 1) {
                        return;
                      }
                      onQuantityChange(e)
                    }}
                    value={val.Qty}
                    minWidth={80}
                  />
                </td>

                <td className="text-center">
                  <button
                    onClick={() => props.onDelete(index)}
                  >
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
            )}

    </Fragment>
  )
};
export default ManualProcessLinesUI;