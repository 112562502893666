import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/Header/header';
import QuotationLoader from './CreateQuoteComponents/QuotationLoader/QuotationLoader';
import { 
  CREATE_QUOTE_ACTION, 
  useCreateQuoteContext 
} from '../../context/create.quote.context';
import { useNavigate, useParams, useLocation, Link, useSearchParams } from "react-router-dom";
import { getFileExtension, isValidExtension, validateUser } from '../../utils/helper';
import LeftSection from './CreateQuoteComponents/LeftSection/LeftSection';
import CenterSection from './CreateQuoteComponents/CenterSection/CenterSection';
import RightSection from './CreateQuoteComponents/RightSection/RightSection';
import { toast } from 'react-toastify';
import { commanFields, extractNameAndDomain, generateRandomString, isValidEmail, MATERAILS_VALID_FOR_ML, maxFileUploadSize } from '../../utils/constant';
import { firebaseSignOnLoginSuccess, storage } from "../../utils/firebase";
import { triggerFunc, triggerFuncLink } from '../../utils/trigger';
import moment from "moment";
import { config } from '../../environment/development';

// IMG Import
import logo from "../../images/logo.svg";
import { getAllTechnology, getMetalCCDataForMaterial, getSeq } from "../../utils/actions/adminActions";
import { resetCreateQuoteState, setCommanValues, setSameForAllField, setValues, updateSelectedProcess } from '../../context/create.quote.actions';
import { postApi, fetchApi, getPredictedValueForPart } from "../../utils/actions/commanActions";

import fileSaver from "file-saver";
import { Modal, Button } from "react-bootstrap";
import { forwardToPurchaserMailTriggerFunction, getDefaultAddressOfUser, searchUserByEmail } from "../../utils/actions/commanActions";
import ForwardToPurchaseModal from "../../components/ForwardToPurchaserModal/index"

import {
  getAllBulkPricing,
  getMaterial,
  getAllUsers,
  quoteSaveMail,
  getThumbnail,
  getAddressById,
  downloadPdf,
  handleQuoteCancel,
  moveQuoteToProgressStatus,
  performAnalysisForFile,
} from "../../utils/actions/allactions";

import Chat from "../../Chat";
import TeamCollaboration from "../../components/TeamCollaboration/TeamCollaboration";
import { LucidLoader } from "../../components/Icons";
import ShareModalComponent from "../../components/ShareComponent/ShareModalComponent";
import TargetCostUserModal from "../../components/TargetCostUserModal/TargetCostUserModal";
import TermsOfUseModal from './CreateQuoteComponents/TermsOfUseModal/TermsOfUseModal';
import SignInModal from '../../components/LoggedInModal/SignInModal';
import { loginViaPassword } from '../../utils/actions/loginactions';
import { checkCreateUser } from '../../utils/actions/userActions';



const CreateInstantQuotation = () => {

  const { quoteId } = useParams();
  let _id = quoteId;
  const navigate = useNavigate();
  let token = localStorage.getItem('Token');
  const myRef = useRef();
  const userEmail = localStorage.getItem("email");

  const [step, setStep] = useState(quoteId ? 2 : 1);

  const location = useLocation();

  const [isEditQuote, setIsEditQuote] = useState(false);
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();
  const [isLoading, setIsLoading] = useState();
  const [isProcessing, setIsProcessing] = useState();
  const [isGlobeLoader, setIsGlobeLoader] = useState();
  const [selectedQuote, setSelectedQuote] = useState();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isTargetCostModalOpen, setIsTargetCostModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [pricingData, setPricingData] = useState([]);
  const [isAdminUser, setIsAdminUser] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [allFilteredUsers, setAllFilteredUsers] = useState([]);
  const [userIdToEditQuote, setUserIdToEditQuote] = useState({})
  const [isValidUser, setIsValidUser] = useState(true);
  const [isAdminShare, setIsAdminShare] = useState(false)
  const [matSubMatMap, setMatSubMatMap] = useState(new Map());
  const [isQuoteCreationInProcess, setIsQuoteCreationInProcess] = useState(false);
  const [showQuoteUpdateModal, setShowQuoteUpdateModal] = useState(false);
  const [dataForQuote, setDataForQuote] = useState(null);
  const [isSaveForFunctionalityState, setIsSaveForFunctionalityState] = useState(null);
  const [searchParams] = useSearchParams();
  const repeatOrder = searchParams.get('RepeatOrder') == "true" ? true : false;
  const isRepeatOrderWithUpdate = searchParams.get('isRepeatOrderWithUpdate') == "true" ? true : false;
  const funcName = searchParams.get("functionalityName");
  const [masterThreeDTechnologyData, setMasterThreeDTechnologyData] = useState([]);
  const [minCostTechMachineMaterialPostProcess, setMinCostTechMachineMaterialPostProcess] = useState({});
  const [isInchSelected, setIsInchSelected] = useState(false);
  const [draftQuoteId, setDraftQuoteId] = useState('');
  const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);
  const [isForwardToPurchaserModalOpen, setIsForwardToPurchaserModalOpen] = useState(false);
  const [teamModalShow, setTeamModalShow] = useState(false);
  let projectName = createQuoteState?.commanValues?.projectName;
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isUserLoggOut, setIsUserLoggOut] = useState(false);
  const [isNavigationPending, setIsNavigationPending] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLogin') == "true" ? true : false)
  const [twoDFiles, setTwoDFiles] = useState([])
  const [isQuoteChanged, setIsQuoteChanged] = useState(false);
  const [bounding, setBounding] = useState([])
  const [boxDimension, setBoxDimension] = useState([])
  const [isSamplePartUploaded, setIsSamplePartUploaded] = useState();
  const [selectedQuoteRef, setSelectedQuoteRef] = useState("");
  const [isInvalidFiles, setIsInvalidFiles] = useState(false);
  const [material, setMaterial] = useState([]);
  const [threeDError, setThreeDError] = useState("Manual review is required for the parts you have uploaded.");
  const [subMaterial, setSubMaterial] = useState([]);
  const [partmarking, setPartMarking] = useState([]);
  const [surfaceFinish, setSurfaceFinish] = useState([]);
  const [surfaceTreatment, setSurfaceTreatment] = useState([]);
  const [tolerance, setTolerance] = useState([]);
  const [quoteUserEmail, setQuoteUserEmail] = useState('');
  const [showTermsOfUseModal, setShowTermsOfUseModal] = useState(false);
  const [isFunctionalityPending, setIsFunctionalityPending] = useState(false);
  const [pendingArgument, setPendingArgument] = useState(null);
  const [isRequestReviewPending, setIsRequestReviewPending] = useState(false);
  const [isPtcPending, setIsPtcPending] = useState(false);
  const [email, setEmail] = useState(() => {
    return localStorage.getItem("email") || "";
  });

  const [isUserInfoReceived, setIsUserInfoReceived] = useState(false);
  const [loggedInUserId, setLoggedInUserId] = useState();

  const [isFirstFileUploaded, setIsFirstFileUploaded] = useState(false);

  // let cncError = "We are unable to fetch quotation for some of the selections at the moment. Please sent quote for review, Our applications engineering team will review and get back to you shortly."
  let currentAcceptableProcess = [
    "3D Printing",
    "CNC Machining",
    "Vacuum casting",
    "Sheet Metal Fabrication",
    "Injection Moulding/Casting",
    "Complete Assembly"
  ];

  

  useEffect(() => {
    const defaultUnit = localStorage.getItem("defaultUnit");
    if (!defaultUnit) {
      localStorage.setItem("defaultUnit", "inch");
    }
  }, [])


  useEffect(() => {
    if (isUserInfoReceived && isFirstFileUploaded) {
      (async () => {
        // console.log('setIsProcessing 1')
        setIsProcessing(true);
        try {
          // console.log("New Quote Run Run Run ");
          // debugger
          // console.log("Quote Id :", quoteId);
          // console.log("Deps :", isUserInfoReceived, files?.length, selectedQuote?._id, createQuoteState?.commanValues, createQuoteState?.values?.length)
          const responseApiCall = await saveApiCall(null, "new");
          // console.log("ABCDEF", responseApiCall);
          setIsProcessing(false)
          // setIsUserExists(false);
          setIsUserInfoReceived(false);
          navigate(`/create-instant-quotation/${responseApiCall._id}`);

        } catch (error) {
          console.log("Error IN main User Effect :", error);
          // toast.error('Unable To Check User At The Moment');
          setIsProcessing(false)
        } finally {
          setIsProcessing(false)
        }
      })()
    }

  }, [isUserInfoReceived, isFirstFileUploaded]);


  useEffect(() => {

    let timeout;

    (async () => {
      if (quoteId && selectedQuote?._id) {
        // console.log("Update Quote Run Run Run ");

        clearTimeout(timeout);
        timeout = setTimeout(() => {
          updateQuote('update'); 
        }, 2000);
      }
    })();
    return () => clearTimeout(timeout);
  }, [
    selectedQuote?._id,
    createQuoteState?.commanValues,
    createQuoteState?.values?.length,
    files?.length,
    twoDFiles,
  ]);



  useEffect(() => {
    setIsSignInModalOpen(false);
  }, [])

  useEffect(() => {
    if (location.state) {
      const { pathname } = location.state;
      // console.log("Path Name :", pathname);
      if (pathname) {
        setIsNavigationPending(true);
        setIsSignInModalOpen(true);
      }
    }

  }, [location.state]);

  useEffect(() => {
    let isValidUserLoggedIn = validateUser(token);
    setIsLoggedIn(isValidUserLoggedIn)
    firebaseSignOnLoginSuccess();
  }, [])



  useEffect(() => {
    (async () => {
      const response = await getSeq();
      if (response || response.success) {
        const { data: { seq } } = response;
        createQuoteDispatch({
          type: CREATE_QUOTE_ACTION.UPDATE_PROJECT_NUMBER,
          payload: seq,
        });

      }
    })()
  }, [])

  useEffect(() => {
    if (createQuoteState?.commanValues.price || createQuoteState?.commanValues.price1) {
      // console.log('createQuoteState?.commanValues came here 333', { ...createQuoteState?.commanValues, hidePrice: false })
      setCommanValues({ ...createQuoteState?.commanValues, hidePrice: false }, createQuoteDispatch, createQuoteState)
    }
  }, [createQuoteState?.commanValues.price, createQuoteState?.commanValues.price1]);


  useEffect(() => {
    if (funcName == 'chat') {
      if (selectedQuoteRef) setIsChatOpen(true);
    }
    if (funcName == 'ftp') {
      setIsForwardToPurchaserModalOpen(true);
    }
    if (funcName == 'teams') {
      setTeamModalShow(true);
    }
    if (funcName == 'share' && selectedQuote) {
      setIsShareModalOpen(true);
    }
    if (funcName == 'stc') {
      setIsTargetCostModalOpen(true);
    }
  }, [selectedQuoteRef, location.search, selectedQuote]);



  useEffect(() => {
    if (funcName == 'dq' && selectedQuote && createQuoteState?.values?.length && isAllDataLoaded) {
      // console.log("Download Quotation Run ")
      handleDownloadQuotation();
    }
  }, [selectedQuote]);


  const handleDownloadQuotation = async () => {
    // console.log("Handle Download Quotation Is Triggered");
    try {
      const id = localStorage.getItem('_id');
      if (!selectedQuote) {
        toast.error("Unable To Download Quote At The Moment");
        return;
      }

      const response = await getDefaultAddressOfUser(id);
      if (!response || !response?.success) {
        throw new Error();
      }
      const defaultAddress = response?.data;

      const selectedShipMethod = selectedQuote?.selectedShipMethod;

      let data = {
        fname: defaultAddress.firstName,
        lname: defaultAddress.lastName,
        email: localStorage.getItem("email"),
        quoteId: selectedQuote.RefId,
        total: selectedQuote.price,
        addressline1: defaultAddress.addressLineOne,
        addressline2: defaultAddress.addressLineTwo,
        city: defaultAddress.city,
        state: defaultAddress.state,
        country: defaultAddress.country,
        zipcode: defaultAddress.postalCode,
        partData: JSON.stringify(selectedQuote.partsData),
        process: selectedQuote.selectedProcess,
        price: selectedQuote.price,
        price1: selectedQuote.price1,
        price2: selectedQuote.price2,
        selectedShipMethod: selectedShipMethod,
        sheetMetalAndInjectionDesc: selectedQuote.sheetAndInjectionDiscription,
        sheetMetalAndInjectionMP: selectedQuote.sheetAndInjectionManualPrice,
        sheetAndInjectionQuantity: selectedQuote.sheetAndInjectionQuantity,
        manualPrice: selectedQuote.stManualPrice,
        forInvoice: false,
        hidePrice: selectedQuote.hidePrice,
        isQrTrackingRequested: selectedQuote.isQrTrackingRequested,
        isDimensionalReportRequested: selectedQuote.isDimensionalReportRequested,
        isConformanceCertificateRequested:
          selectedQuote.isConformanceCertificateRequested,
        additionalCost:
          selectedShipMethod == 0
            ? selectedQuote.additionalCost1
            : selectedShipMethod == 1
              ? selectedQuote.additionalCost2
              : selectedQuote.additionalCost3,
        shippingCharge:
          selectedShipMethod == 0
            ? selectedQuote.shippingCharge1
            : selectedShipMethod == 1
              ? selectedQuote.shippingCharge2
              : selectedQuote.shippingCharge3,
        leadTime:
          selectedShipMethod == 0
            ? selectedQuote.leadTime1
            : selectedShipMethod == 1
              ? selectedQuote.leadTime2
              : selectedQuote.leadTime3,
        projectName: selectedQuote.projectName,
        date: selectedQuote.createdAt,
        version: selectedQuote.version,
        quotationNotes: selectedQuote.quotationNotes,
        quotationTnc: selectedQuote.quotationTnc,
      };

      let priceValue =
        selectedShipMethod == 0
          ? selectedQuote.price
          : selectedShipMethod == 1
            ? selectedQuote.price1
            : selectedQuote.selectedProcess <= 2
              ? selectedQuote.stManualPrice
              : selectedQuote.sheetAndInjectionManualPrice;
      let fileName = `${selectedQuote.RefId}-V-${selectedQuote.version}-${Number(
        priceValue
      ).toFixed(2)}.pdf`;

      downloadPdf(data, token)
        .then((res) => {
          fileSaver.saveAs(
            `${config.backEnd}/public/docs/` + res?.data?.data,
            fileName
          );
        })
        .catch((err) => {
          console.log("Error 1 in catch", err);
          console.log(
            "error in quote version pb on 267 on:: " +
            moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
            err
          );
        });


    } catch (error) {
      console.log("Error 2 in catch", error);
      toast.error("Unable To Download Quotation At The Moment");
    }

  }


  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await getAllTechnology(token);
        if (!response || !response.success) {
          toast.error("Unable To Fetch Technology 1");
          return;
        }
        const { data } = response;
        const minCostMachineMaterialPostProcessMap = {};
        data.forEach((tech) => {
          const { machines, name: techName } = tech;
          machines?.forEach((machine) => {
            const { name: machineName } = machine;
            const { materials } = machine;
            materials?.forEach((material) => {
              const { name: materialName, minCost } = material;
              // console.log(minCost);
              const { postProcess } = material;
              postProcess?.forEach((pProcess) => {
                const { name: postProcessName, color } = pProcess;
                const key = techName + materialName;
                minCostMachineMaterialPostProcessMap[key] = minCost;
              })

            })

          })

        })
        // console.log(minCostMachineMaterialPostProcessMap);
        setMinCostTechMachineMaterialPostProcess(minCostMachineMaterialPostProcessMap);
        setMasterThreeDTechnologyData(data);
        setIsLoading(false);

      } catch (error) {
        console.log("Error in useEffect", error)
        setIsLoading(false);
        toast.error("Unable To Fetch Technology 3");
      }
    })()

  }, [quoteId])


  useEffect(() => {
    // console.log("Fetch data Run :", "Run Run Run ");
    if (masterThreeDTechnologyData.length && quoteId) {
      fetchData();
    }
  }, [quoteId, masterThreeDTechnologyData])



  useEffect(() => {
    const defaultUnit = localStorage.getItem("defaultUnit");
    if (defaultUnit == 'inch') {
      setIsInchSelected(true);
      return;
    }
    setIsInchSelected(false);
    fetchMaterialData()
  }, [])



  useEffect(() => {
    setIsInvalidFiles(false)
    async function handlePricing() {
      let localValues = [...createQuoteState?.values];
      let isAllFilesValid = true;
      for (let i = 0; i < localValues.length; i++) {
        let extension = getFileExtension(localValues[i]?.file?.originalname || localValues[i]?.selectedFile?.name);
        if (!(extension == 'stp' || extension == 'step' || extension == 'iges' || extension == 'stl' ||
          extension == 'STP' || extension == 'STEP' || extension == 'IGES' || extension == 'STL')) {
          isAllFilesValid = false;
        }
      }
      if (isAllFilesValid) {
        if (createQuoteState?.commanValues?.selectedProcess == 1) {
          handleCNCPriceUpdate();
        } else {
          handleThreeDPriceUpdate();
        }
      } else {
        setIsInvalidFiles(true);
        let updatedValues = [...createQuoteState?.values];
        updatedValues.forEach(value => {
          value.price = 0;
          value.price1 = 0;
          value.price2 = 0;
          value.stManualPrice = 0;
          value.sheetAndInjectionManualPrice = 0;
        })
        // console.log("Came here 5")
        setCommanValues({
          ...createQuoteState.commanValues,
          hidePrice: true,
          price: 0,
          price1: 0,
          price2: 0,
          leadTime1: 0,
          leadTime2: 0,
          isQrTrackingRequested: false
        }, createQuoteDispatch, createQuoteState)
        setValues(updatedValues, createQuoteDispatch, createQuoteState)
      }
    }
    if (createQuoteState?.commanValues?.selectedProcess <= 2) {
      handlePricing();
    }

  }, [createQuoteState?.commanValues?.selectedProcess, bounding, files]);




  useEffect(() => {
    const boundingBox = files?.map((fl) => {
      return bounding.filter((box) => {
        return ((fl?.name || fl?.originalname || fl?.selectedFile?.name || fl?.selectedFile?.path || fl?.file?.originalname) === box?.fileName);
      });
    });
    setBoxDimension(boundingBox);
  }, [bounding,])


  const updateAlgorithmPrice = (itemCost1) => {
    return itemCost1;
  }

  const fetchMaterialData = async () => {
    try {
      const materialResponse = await getMaterial(token);
      setMaterial(materialResponse.data.material);
      setSubMaterial(materialResponse.data.submaterial)
      setPartMarking(materialResponse.data.partMarking);
      setSurfaceTreatment(materialResponse.data.surfacetreat);
      setSurfaceFinish(materialResponse.data.surafcefinish);
      setTolerance(materialResponse.data.tolerance)
    } catch (error) {
      console.log("Error fetching the materails")
    }
  }

  const fetchData = async () => {
    try {
      // console.log('setIsProcessing 2')
      setIsProcessing(true);

      if (!quoteId) {
        setIsProcessing(false);
        return;
      }

      const response = await fetchDataFromApi(`comman/fetch-quote-byId/${quoteId}`);
      const userId = localStorage.getItem("_id");
      const allAddress = await getAddressById(userId, token);
      // console.log("All User Address :", allAddress);
      setSelectedQuote(response);
      if (userEmail?.split('@')[1] != response?.userId?.email?.split("@")[1] && userEmail?.split('@')[1] != "8xparts.com") {
        setIsValidUser(false);
      }
      setQuoteUserEmail(response?.userId?.email)
      setSelectedQuoteRef(response.RefId);
      setUserIdToEditQuote(response.userId._id);

      if (response) {
        const updatedValues = []
        for (var p = 0; p < response.partsData.length; p++) {
          const singleUpdatedValue = { ...commanFields }
          const singlePart = response.partsData[p]
          for (var key in singlePart) {
            singleUpdatedValue[key] = singlePart[key]
            singleUpdatedValue.errors[key] = false
          }
          updatedValues.push(singleUpdatedValue)
        }

        // Fetch files
        // for (let val of response.partsData) {
        //   if (val.file || val.selectedFile) {
        //     let ext = getFileExtension(val?.file?.originalname || val?.selectedFile?.path);
        //     if (['stp', 'step', 'iges', 'stl', 'STP', 'STEP', 'IGES', 'STL'].includes(ext) && response?.selectedProcess && response?.selectedProcess <= 2) {
        //       try {
        //         // setIsLoading(true);
        //         const res = await fetch(`${config.backEnd}/public/uploads/${val._id}.${ext}`);
        //         // setIsLoading(false);
        //         triggerFuncLink(
        //           res.url,
        //           ext,
        //           setBounding,
        //           setIsProcessing,
        //           val?.file?.originalname || val?.selectedFile?.path
        //         );
        //         setIsEditQuote(true);
        //       } catch (error) {
        //         console.error('Error fetching file:', error);
        //         setIsLoading(false);
        //         break;
        //       }
        //     } else {
        //       break;
        //     }
        //   }
        // }
        // console.log("Response :", response);
        if (response.partsData.length > 0) {
          const updatedCommanValues = { ...createQuoteState?.commanValues }
          updatedCommanValues.price = response.price
          updatedCommanValues.price1 = response.price1
          updatedCommanValues.price2 = response.price2
          updatedCommanValues.selectedShipMethod = response.selectedShipMethod
          updatedCommanValues.sheetAndInjectionDiscription = response.sheetAndInjectionDiscription
          updatedCommanValues.sheetAndInjectionQuantity = response.sheetAndInjectionQuantity
          updatedCommanValues.leadTime1 = response.leadTime1;
          updatedCommanValues.leadTime2 = response.leadTime2;
          updatedCommanValues.leadTime3 = response.leadTime3;
          updatedCommanValues.hidePrice = response.hidePrice
          updatedCommanValues.isConformanceCertificateRequested = response.isConformanceCertificateRequested
          updatedCommanValues.isDimensionalReportRequested = response.isDimensionalReportRequested
          updatedCommanValues.isQrTrackingRequested = response.isQrTrackingRequested
          updatedCommanValues.isUpdatedByAdmin = response.isUpdatedByAdmin;
          updatedCommanValues.isRepeatOrderQuotation = response.isRepeatOrderQuotation;
          updatedCommanValues.additionalCost1 = response.additionalCost1;
          updatedCommanValues.additionalCost2 = response.additionalCost2;
          updatedCommanValues.additionalCost3 = response.additionalCost3;
          updatedCommanValues.shippingCharge1 = response.shippingCharge1;
          updatedCommanValues.shippingCharge2 = response.shippingCharge2;
          updatedCommanValues.shippingCharge3 = response.shippingCharge3;
          updatedCommanValues.myorders = response.myorders;
          updatedCommanValues.projectName = response.projectName;
          updatedCommanValues.createdAt = response.createdAt;
          updatedCommanValues.version = response.version;
          if (response) {
            // console.log("createQuoteState?.commanValues Came here 2",updatedCommanValues)
            setCommanValues(updatedCommanValues, createQuoteDispatch, createQuoteState)
            setValues(response.partsData, createQuoteDispatch, createQuoteState)
        
            setSameForAllField(response.sameForAllField, createQuoteDispatch, createQuoteState)
          }

          if (response.selectedProcess && response.selectedProcess > 0) {
            updateSelectedProcess(response.selectedProcess, createQuoteDispatch, createQuoteState)
          }


          let twoFileDataFetched = {};
          response.partsData.forEach((item, i) => {
            if (item?.twoDFile) {
              twoFileDataFetched = { ...twoFileDataFetched, [i]: { file: item?.twoDFile, fileName: item?.twoDFile?.originalname || item?.twoDFile?.filename } }
            }
          });

          setTwoDFiles({ ...twoFileDataFetched });
        }
      }
      setIsProcessing(false);
      setIsAllDataLoaded(true);
    } catch (err) {
      console.log("ERror ing our here", err)
      setIsProcessing(false);
      let msg = err && err.message ? err.message : "Something went wrong during fetch quotes";
      toast.error(msg)
    } finally {
      setIsProcessing(false)
    }
  }

  const onSelectfile = async (filesData, ext) => {
    try {
      setIsProcessing(true)
      if (!createQuoteState?.values?.length) {
        setShowTermsOfUseModal(true)
      }

      if (!(filesData && filesData.length > 0)) {
        toast.error("Please select a valid file. Files exceeding 25 MB are not accepted.")
        return;
      };

      // const { name, domain } = extractNameAndDomain(email);

      // let userId = generateRandomString(20);
      let userName = generateRandomString(20);

      // for (const file of filesData) {
      //   setIsProcessing(true);
      //   const res = await storage.ref(`/quotation-files/3D/${userName}/${Date.now() + "_FileName_" + (file?.originalname || file?.name || file?.path)}`).put(file);
      //   let extension = getFileExtension(file?.name || file?.originalname);
      //   if ((extension == 'stp' || extension == 'step' || extension == 'iges' || extension == 'stl' || ext == 'stp' ||
      //     extension == 'STP' || extension == 'STEP' || extension == 'IGES' || extension == 'STL' || ext == 'STP'
      //   ) && createQuoteState?.commanValues?.selectedProcess && createQuoteState?.commanValues?.selectedProcess <= 2) {

      //     //only selected formate     
      //     const { metadata: { fullPath } } = res;
      //     const serverResponse = await getThumbnail(fullPath);
      //     if (serverResponse.success) {
      //       const { image } = serverResponse;
      //       file.thumbnail = image;
      //     }
      //   }
      //   else {
      //     setIsProcessing(false);
      //   }
      // };
      const fileAnalysisData = new Array(filesData.length);
      // console.log('fileAnalysisData before', fileAnalysisData)
      for (let index = 0; index < filesData.length; index++) {
        setIsProcessing(true);
        const file = filesData[index];

        let extension = getFileExtension(file?.name || file?.originalname);
        // console.log('extension: ' + extension);

        const validFileExtensionCheck = isValidExtension(extension || ext);
        // console.log('validFileExtensionCheck', validFileExtensionCheck);

        if (validFileExtensionCheck) {
          try {
            const response = await performAnalysisForFile(file);
            // console.log("Response from Quaoar:", response);
            // console.log('fileAnalysisData?.[index]', fileAnalysisData?.[index])
            if (!fileAnalysisData[index]) {
              fileAnalysisData[index] = {};
            }
            fileAnalysisData[index].s3QrFileUrl = response?.filesS3Data || null;
            fileAnalysisData[index].jsonFileUrl = response?.quaoarResponseFiles?.[0]?.json || null;
            fileAnalysisData[index].thumbnail = response?.quaoarResponseFiles?.[1]?.png || null;

            const gltfFiles = response?.quaoarResponseFiles?.[2]?.glb || [];
            fileAnalysisData[index].gltfPlainFileUrl = gltfFiles[0] || null;
            fileAnalysisData[index].gltfColoredFileUrl = gltfFiles[1] || null;
            fileAnalysisData[index].stagingPartsData = response?.stagingPartResponse||null;
            // console.log('fileAnalysisData?.[index] after processing:', fileAnalysisData[index])

          } catch (error) {
            console.error("Error during performAnalysisForFile:", error);
            toast.error("Error during file analysis.");
          }
        } else {
          setIsProcessing(false);
        }
      }

      setIsQuoteChanged(true);

      let index = 0;
      // for (let val of filesData) {
      //   let extension = getFileExtension(val?.name || val?.originalname);
      //   if ((extension == 'stp' || extension == 'step' || extension == 'iges' || extension == 'stl' || ext == 'stp' ||
      //     extension == 'STP' || extension == 'STEP' || extension == 'IGES' || extension == 'STL' || ext == 'STP'
      //   ) && createQuoteState?.commanValues?.selectedProcess && createQuoteState?.commanValues?.selectedProcess <= 2) {

      //     if (ext) {
      //       await triggerFuncLink(
      //         val,
      //         ext,
      //         setBounding,
      //         setIsProcessing,
      //         val?.name || val?.originalname,
      //         bounding
      //       );
      //     } else {
      //       await triggerFunc(val, extension, setBounding, setIsProcessing, bounding);
      //     }
      //   }
      //   index++;
      // }

      const valueCollection = [...createQuoteState.values];
      const filesCollection = [...files];
      for (let f = 0; f < filesData.length; f++) {
        const singleValue = {
          selectedFile: filesData[f],
          thumbnail: fileAnalysisData[f]?.thumbnail,
          s3QrFileUrl: fileAnalysisData[f]?.s3QrFileUrl,
          jsonFileUrl: fileAnalysisData[f]?.jsonFileUrl,
          gltfPlainFileUrl: fileAnalysisData[f]?.gltfPlainFileUrl,
          gltfColoredFileUrl: fileAnalysisData[f]?.gltfColoredFileUrl,
          stagingPartsDataId: fileAnalysisData[f]?.stagingPartsData,
          partFileName: filesData[f]?.path || filesData[f]?.name,
          ...commanFields
        }
        valueCollection.push(singleValue)
        filesCollection.push(Object.assign(filesData[f], {
          isNotFromMongoDB: true,
        }))
      }
      setFiles(filesCollection);
      if (!createQuoteState?.values?.length) {
        setIsFirstFileUploaded(true)
      }

      if (valueCollection && valueCollection.length > 0) {
        setValues(valueCollection, createQuoteDispatch, createQuoteState)
      }

    } catch (err) {

      console.log("Errorout", err);
      setIsProcessing(false);
      let message = err && err.message ? err.message : "Error occur during file select"
      toast.error(message)
    } finally {
      setIsProcessing(false)
    }
  }


  const handleCNCPriceUpdate = async (index) => {
    try {
      if (!createQuoteState?.values?.length) return;
      if (repeatOrder) return;
      if (!createQuoteState?.commanValues?.selectedProcess || createQuoteState?.commanValues?.selectedProcess != 1) {
        return;
      }
      setIsGlobeLoader(true);
      setIsInvalidFiles(false);
      if (createQuoteState?.commanValues.hidePrice || isInvalidFiles || createQuoteState?.commanValues.price === 0) index = -1;

      let localValueCollection = [...createQuoteState?.values];

      let isAllMaterialValidForML = true;
      createQuoteState?.values?.forEach((value) => {
        // material.forEach(item=> console.log("material Id",item._id, "line Matereial I d",value.MaterialType))
        const selectedMaterial = material.find(item => item._id === value.MaterialType)?.name;
        // console.log('selectedMaterial', selectedMaterial, value)
        if (!selectedMaterial || !MATERAILS_VALID_FOR_ML.includes(selectedMaterial)) {
          isAllMaterialValidForML = false;
        }
      });

      if (!isAllMaterialValidForML) {
        throw {
          message: "Atleast one part is having invalid material selected for them."
        }
      }

      if (index > 0 && !createQuoteState?.commanValues?.sameForAllField) {
        let boundingData = bounding?.find(ele => ele.fileName === (localValueCollection[index]?.selectedFile?.name || localValueCollection[index]?.file?.filename || localValueCollection[index].file?.originalname));
        if (!boundingData) {
          return;
        }

        let data = {
          MaterialType: localValueCollection[index].MaterialType,
          MaterialTypeName: material.filter((item) => { return item._id === localValueCollection[index].MaterialType })[0]?.name.toUpperCase(),
          SubMaterial: localValueCollection[index].SubMaterial,
          tolerance: localValueCollection[index].tolerance,
          surfaceTreatment: localValueCollection[index].surfaceTreatment,
          surfaceFinish: localValueCollection[index].surfaceFinish,
          partMarking: localValueCollection[index].partMarking,
          File_Analysis_Data: boundingData,
          Xdim: Math.abs(boundingData?.boundingBox.xmax - boundingData?.boundingBox.xmin),
          Ydim: Math.abs(boundingData?.boundingBox.ymax - boundingData?.boundingBox.ymin),
          Zdim: Math.abs(boundingData?.boundingBox.zmax - boundingData?.boundingBox.zmin),
          selectedFile: localValueCollection[index].selectedFile,
          fileFromServer: { ...localValueCollection[index].file, lineId: localValueCollection[index]._id },
        }

        let percent = 0;

        let fetchedPartCost = 0;

        let predictedPriceResponse = await getPredictedValueForPart(data, token);
        if (predictedPriceResponse.status && predictedPriceResponse?.data?.price > 0) {
          fetchedPartCost = predictedPriceResponse?.data?.price;
          let algorithmPriceFromApi = updateAlgorithmPrice(fetchedPartCost);
          fetchedPartCost = Number(fetchedPartCost).toFixed(2);
          let localItemAtIndex = localValueCollection[index];
          let subMaterialAtIndex = subMaterial.filter(item => item?._id == localItemAtIndex?.SubMaterial);
          let partMarkingAtIndex = partmarking.filter(item => item?._id == localItemAtIndex?.partMarking);
          let surfaceFinishAtIndex = surfaceFinish.filter(item => item?._id == localItemAtIndex?.surfaceFinish);
          let surfaceTreatmentAtIndex = surfaceTreatment.filter(item => item?._id == localItemAtIndex?.surfaceTreatment)
          let toleranceAtIndex = tolerance.filter(item => item?._id == localItemAtIndex?.tolerance);
          let subMaterialFactorAtIndex = subMaterialAtIndex.length > 0 ? Number(subMaterialAtIndex[0].SubMatFactor) : 0;
          let partMarkingFactorAtIndex = partMarkingAtIndex.length > 0 ? Number(partMarkingAtIndex[0].PMFactor) : 0;
          let surfaceFinishFactorAtIndex = surfaceFinishAtIndex.length > 0 ? Number(surfaceFinishAtIndex[0].SfFactor) : 0;
          let surfaceTreatmentFactorAtIndex = surfaceTreatmentAtIndex.length > 0 ? Number(surfaceTreatmentAtIndex[0].StFactor) : 0;
          let toleranceFactorAtIndex = toleranceAtIndex.length > 0 ? Number(toleranceAtIndex[0].TolFactor) : 0;
          fetchedPartCost = (fetchedPartCost * (1 + subMaterialFactorAtIndex + surfaceFinishFactorAtIndex + surfaceTreatmentFactorAtIndex + toleranceFactorAtIndex)) + partMarkingFactorAtIndex;
          localItemAtIndex.algorithmPrice = algorithmPriceFromApi
          localItemAtIndex.algorithmPrice1 = createQuoteState?.commanValues?.selectedProcess == 1 ? (0.8 * algorithmPriceFromApi).toFixed(2) : 0;
          localItemAtIndex.price = (fetchedPartCost * (100 - percent) / 100).toFixed(2);
          localItemAtIndex.price1 = createQuoteState?.commanValues?.selectedProcess == 1 ? (0.8 * fetchedPartCost * (100 - percent) / 100).toFixed(2) : 0;
          localValueCollection[index] = localItemAtIndex;
        } else {
          throw {
            message: "Could not fetch the part price"
          }
        }
      } else {
        for (let i = 0; i < localValueCollection.length; i++) {
          let boundingData = bounding?.find(ele => ele.fileName === (localValueCollection[i]?.selectedFile?.name || localValueCollection[i]?.file?.filename || localValueCollection[i].file?.originalname));
          if (!boundingData) {
            throw {
              message: "No bounding data found for part"
            };
          }
          let data = {
            MaterialType: localValueCollection[i].MaterialType,
            MaterialTypeName: material.filter((item) => { return item._id === localValueCollection[i].MaterialType })[0]?.name.toUpperCase(),
            SubMaterial: localValueCollection[i].SubMaterial,
            tolerance: localValueCollection[i].tolerance,
            surfaceTreatment: localValueCollection[i].surfaceTreatment,
            surfaceFinish: localValueCollection[i].surfaceFinish,
            partMarking: localValueCollection[i].partMarking,
            File_Analysis_Data: boundingData,
            Xdim: Math.abs(boundingData?.boundingBox.xmax - boundingData?.boundingBox.xmin),
            Ydim: Math.abs(boundingData?.boundingBox.ymax - boundingData?.boundingBox.ymin),
            Zdim: Math.abs(boundingData?.boundingBox.zmax - boundingData?.boundingBox.zmin),
            selectedFile: localValueCollection[i].selectedFile,
            fileFromServer: { ...localValueCollection[i].file, lineId: localValueCollection[i]._id },
          };


          if (!isAllMaterialValidForML) {
            throw {
              message: "At least one part is having invalid material selected for them."
            };
          }

          let predictedPriceResponse;
          try {
            predictedPriceResponse = await getPredictedValueForPart(data, token);
          } catch (error) {
            console.log("err in cnc", error)
            throw {
              message: `Error in getting predicted value for part: ${localValueCollection[i]._id}`,
              error: error
            };
          }

          let fetchedPartCost = predictedPriceResponse?.data?.price;
          let algorithmPriceFromApi = 0;
          if (fetchedPartCost > 0) {
            let percent = 0;

            algorithmPriceFromApi = updateAlgorithmPrice(fetchedPartCost);
            fetchedPartCost = Number(fetchedPartCost).toFixed(2);
            let localItemAtIndex = localValueCollection[i];
            let subMaterialAtIndex = subMaterial.filter(item => item?._id == localItemAtIndex?.SubMaterial);
            let partMarkingAtIndex = partmarking.filter(item => item?._id == localItemAtIndex?.partMarking);
            let surfaceFinishAtIndex = surfaceFinish.filter(item => item?._id == localItemAtIndex?.surfaceFinish);
            let surfaceTreatmentAtIndex = surfaceTreatment.filter(item => item?._id == localItemAtIndex?.surfaceTreatment);
            let toleranceAtIndex = tolerance.filter(item => item?._id == localItemAtIndex?.tolerance);

            let subMaterialFactorAtIndex = subMaterialAtIndex.length > 0 ? Number(subMaterialAtIndex[0].SubMatFactor) : 0;
            let partMarkingFactorAtIndex = partMarkingAtIndex.length > 0 ? Number(partMarkingAtIndex[0].PMFactor) : 0;
            let surfaceFinishFactorAtIndex = surfaceFinishAtIndex.length > 0 ? Number(surfaceFinishAtIndex[0].SfFactor) : 0;
            let surfaceTreatmentFactorAtIndex = surfaceTreatmentAtIndex.length > 0 ? Number(surfaceTreatmentAtIndex[0].StFactor) : 0;
            let toleranceFactorAtIndex = toleranceAtIndex.length > 0 ? Number(toleranceAtIndex[0].TolFactor) : 0;

            fetchedPartCost = (fetchedPartCost * (1 + subMaterialFactorAtIndex + surfaceFinishFactorAtIndex + surfaceTreatmentFactorAtIndex + toleranceFactorAtIndex)) + partMarkingFactorAtIndex;

            localItemAtIndex.algorithmPrice = algorithmPriceFromApi;
            localItemAtIndex.algorithmPrice1 = createQuoteState?.commanValues?.selectedProcess == 1 ? (0.8 * algorithmPriceFromApi).toFixed(2) : 0;
            localItemAtIndex.price = (fetchedPartCost * (100 - percent) / 100).toFixed(2);
            localItemAtIndex.price1 = createQuoteState?.commanValues?.selectedProcess == 1 ? (0.8 * fetchedPartCost * (100 - percent) / 100).toFixed(2) : 0;

            localValueCollection[i] = localItemAtIndex;
          } else {
            throw {
              message: "Price could not be fetched for one of the parts."
            };
          }
        }
      }

      let totalCost1 = localValueCollection?.reduce((acc, item) => {
        return acc + (item.price * item.Qty);
      }, 0);

      let totalCost2 = localValueCollection?.reduce((acc, item) => {
        return acc + (item.price1 * item.Qty);
      }, 0);

      let totalQuantityOfItems = 0;
      for (let i = 0; i < localValueCollection.length; i++) {
        totalQuantityOfItems += localValueCollection[i].Qty;
      }

      let additionalCost = 0;

      let shippingCharge = 0;
      setValues([...localValueCollection], createQuoteDispatch, createQuoteState)
      if (totalCost1 > 0) {
        // console.log("Came here 7")
        setCommanValues({
          ...createQuoteState?.commanValues,
          price: totalCost1 ? totalCost1.toFixed(2) : 0,
          price1: totalCost2 ? totalCost2.toFixed(2) : 0,
          hidePrice: false,
          additionalCost1: additionalCost,
          additionalCost2: additionalCost,
          shippingCharge1: shippingCharge,
          shippingCharge2: shippingCharge,
          leadTime1: 15,
          leadTime2: 20,
          hidePrice: false,
        }, createQuoteDispatch, createQuoteState)
      } else {
        throw {
          message: "Invalid total cost"
        }
      }
      // console.log('gloabe loader 2')
      setIsGlobeLoader(false);
    } catch (err) {
      console.log("Error 6  in catch", err);
      setIsGlobeLoader(false);
      setIsInvalidFiles(true);
      let updatedValues = [...createQuoteState?.values];
      updatedValues.forEach(value => {
        value.price = 0;
        value.price1 = 0;
        value.price2 = 0;
        value.stManualPrice = 0;
        value.sheetAndInjectionManualPrice = 0;
      })
      setValues(updatedValues, createQuoteDispatch, createQuoteState)
      setCommanValues({
        ...createQuoteState.commanValues,
        hidePrice: true,
        price: 0,
        price1: 0,
        price2: 0,
        leadTime1: 0,
        leadTime2: 0,
        isQrTrackingRequested: false
      }, createQuoteDispatch, createQuoteState)
    }
  }



  const handleThreeDPriceUpdate = async () => {
    try {
      if (!createQuoteState?.values.length) return;
      if (repeatOrder) return;
      setIsGlobeLoader(true);

      setCommanValues({ ...createQuoteState?.commanValues, hidePrice: false }, createQuoteDispatch, createQuoteState)
      setIsInvalidFiles(false);
      const localValueCollection = [...createQuoteState?.values];
      let index = -1;
      let totalLeadTime = 0;
      const techMaterialMap = {};

      let isOkay = true;

      for (const item of localValueCollection) {
        ++index;
        const selectedTechnologyId = item?.threeDTechnology;
        const selectedMaterialId = item?.threeDMaterial;
        const selectedProcessId = item?.threeDPostProcessing;

        // console.log("Selected Tech :", selectedTechnologyId);
        // console.log("Selected Material  :", selectedMaterialId);
        // console.log("Selected PostProcess :", selectedProcessId);
        let selectedMachine;
        let selectedPostProcess;
        let selectedTechnology;
        let selectedMaterial;
        outerLoop:
        for (const tech of masterThreeDTechnologyData) {
          const { machines, name: techName, _id: techId } = tech;
          for (const machine of machines || []) {
            const { name: machineName } = machine;
            const { materials } = machine;

            for (const material of materials || []) {
              const { name: materialName, minCost = 0, _id: materialId } = material;
              const { postProcess } = material;

              for (const pProcess of postProcess || []) {
                const { name: postProcessName, color, _id: postProcessId } = pProcess;

                if (postProcessId == selectedProcessId && selectedTechnologyId == techId && selectedMaterialId == materialId) {
                  selectedMachine = machine;
                  selectedMaterial = material;
                  selectedPostProcess = pProcess;
                  selectedTechnology = tech;
                  break outerLoop;
                }
              }
            }
          }

          setIsGlobeLoader(false);
        }


        const isManualReviewRequired = selectedPostProcess?.manualReviewRequired || item?.isOutOfSpec;
        // console.log("Manual Review", isManualReviewRequired);
        //checking is manual review
        if (isManualReviewRequired) {
          // console.log("manual Review Required");
          isOkay = false;
          item.twoDFileRequired = true;
          continue;
        }
        else {
          item.twoDFileRequired = false;
        }
        //checking bouding box
        const boundingData = bounding?.find(ele => ele.fileName == (item?.selectedFile?.name || item?.file?.filename || item?.file?.originalname));

        if (!boundingData) {
          // console.log("bounding data");
          isOkay = false;
          break;
        }
        //calculating volume

        const volumeInCC = Math.ceil(Number(boundingData?.analysis?.volume) / 1000) * Number(item.Qty);
        const singleVolumeInCC = Math.ceil(Number(boundingData?.analysis?.volume) / 1000);

        // console.log(volumeInCC, "volume:");
        // const singleVolumeInCC = Math.ceil(Number(boundingData?.analysis?.volume) / 1000);
        if (!volumeInCC) {
          // console.log("volume")
          isOkay = false;
          continue;
        }
        //if process custom
        if (selectedPostProcess?.name.toLocaleLowerCase() == 'custom'.toLocaleLowerCase()) {
          // console.log("post post post")
          isOkay = false;
          item.twoDFileRequired = treu;
          item.price = 0;
          continue;
        }
        // console.log("Selected Material :", selectedMaterial)
        // console.log("Selected selectedPostProcess :", selectedPostProcess)
        // console.log("Selected selectedTechnology :", selectedTechnology)
        // console.log("Selected selectedMachine :", selectedMachine)
        if (selectedPostProcess && selectedTechnology && selectedMachine && selectedMaterial) {
          item.threeDMachine = selectedMachine?._id;
          item.twoDFileRequired = false;
          if (selectedTechnology?.technologyType?.toLocaleLowerCase() == 'metal') {
            const material_id = selectedMaterial?._id;
            const response = await getMetalCCDataForMaterial(token, material_id);
            if (!response.success) {
              throw new Error("Cannot Fetch Data");
            };

            const { data: { rowData } } = response;
            let isFindValidRow = false;
            if (Array.isArray(rowData)) {
              for (const roD of rowData) {
                const { minCCValue, maxCCValue, costPerCC, fixedCost } = roD;
                if (volumeInCC >= minCCValue && volumeInCC <= maxCCValue) {
                  const surfaceFinishCost = selectedPostProcess?.costPerCC ? Number(selectedPostProcess?.costPerCC) : 0;
                  const priceOfSurfaceFinish = volumeInCC * surfaceFinishCost;
                  const totalCost = fixedCost + (volumeInCC * costPerCC) + priceOfSurfaceFinish;
                  item.price = (totalCost / item.Qty);
                  item.algorithmPrice = (totalCost / item.Qty);
                  isFindValidRow = true;
                  break;
                }
              }
              isOkay = isFindValidRow;
            }
            else {
              //manual review
              isOkay = false;
              continue;
            }

          }
          else {
            //plastic logic

            const { name: techName } = selectedTechnology;
            const { name: materialName } = selectedMaterial;
            const { name: postProcessName, color } = selectedPostProcess;
            const { name: machineName } = selectedMachine;
            const key = techName + materialName;
            const materialCostPerCC = selectedMaterial?.costPerCC ? Number(selectedMaterial?.costPerCC) : 0;
            // console.log("Material Cost :", materialCostPerCC);
            // console.log("Material Per cc", materialCostPerCC);
            // console.log("Material Per cc", materialCostPerCC);
            const priceOfMaterial = volumeInCC * materialCostPerCC;
            const surfaceFinishCost = selectedPostProcess?.costPerCC ? Number(selectedPostProcess?.costPerCC) : 0;
            // console.log("Surface  Per cc", surfaceFinishCost);
            const priceOfSurfaceFinish = volumeInCC * surfaceFinishCost;
            const totalCost = (priceOfMaterial + priceOfSurfaceFinish);
            if (!techMaterialMap[key]) {
              techMaterialMap[key] = { totalCost, count: 1 };
              // ab + cd
            }
            else {
              techMaterialMap[key].totalCost += totalCost;
              techMaterialMap[key].count++;
            }
            item.price = (totalCost / item.Qty);
            item.algorithmPrice = (totalCost / item.Qty);
          }
        }
        else {
          // console.log("else")
          isOkay = false;
          item.twoDFileRequired = true;
          // break;
        }
        let leadTime = 0;
        // console.log(selectedPostProcess, "oso");
        // const { leadtimeData: { rowData = null } } = selectedPostProcess;
        const rowData = selectedPostProcess?.leadtimeData?.rowData || [];

        if (Array.isArray(rowData)) {
          for (const row of rowData) {
            const { minCCValue, maxCCValue, days } = row;
            if (volumeInCC >= minCCValue && volumeInCC <= maxCCValue) {
              leadTime = days;
              totalLeadTime = Math.max(leadTime, totalLeadTime);
              item.twoDFileRequired = false;
              break;
            }
          }
        }
        else {
          isOkay = false;
          item.twoDFileRequired = true;
        }
      }

      // console.log(localValueCollection);

      for (const item of localValueCollection) {
        const selectedTechnologyId = item?.threeDTechnology;
        const selectedMaterialId = item?.threeDMaterial;
        const selectedProcessId = item?.threeDPostProcessing;

        // console.log("Selected Tech :", selectedTechnologyId);
        // console.log("Selected Material  :", selectedMaterialId);
        // console.log("Selected PostProcess :", selectedProcessId);
        let selectedMachine;
        let selectedPostProcess;
        let selectedTechnology;
        let selectedMaterial;

        outerLoop:
        for (const tech of masterThreeDTechnologyData) {
          const { machines, name: techName, _id: techId } = tech;

          for (const machine of machines || []) {
            const { name: machineName } = machine;
            const { materials } = machine;

            for (const material of materials || []) {
              const { name: materialName, minCost = 0, _id: materialId } = material;
              const { postProcess } = material;

              for (const pProcess of postProcess || []) {
                const { name: postProcessName, color, _id: postProcessId } = pProcess;

                if (postProcessId == selectedProcessId) {
                  selectedMachine = machine;
                  selectedMaterial = material;
                  selectedPostProcess = pProcess;
                  selectedTechnology = tech;
                  break outerLoop;
                }
              }
            }
          }





        }
        // console.log("Selected Machine :", selectedMachine);
        if (selectedPostProcess && selectedTechnology && selectedMachine && selectedMaterial) {
          const type = selectedTechnology?.technologyType;
          // console.log("Type: ", type);
          if (type?.toLocaleLowerCase() == 'plastic') {
            //plastic logic
            const { name: techName } = selectedTechnology;
            const { name: materialName } = selectedMaterial;
            // console.log(materialName);
            const { name: PostProcessName, color } = selectedPostProcess;
            const { name: machineName } = selectedMachine;
            const key = techName + materialName;
            if (techMaterialMap[key]) {
              const { totalCost: myValue, count } = techMaterialMap[key];
              const minCost = minCostTechMachineMaterialPostProcess[key];
              // console.log("Key :", key);
              // console.log("minCost", minCost);
              if (myValue < minCost) {
                const remaningValue = minCost - myValue;
                const costToAdd = (remaningValue / count) / item.Qty;
                item.price += costToAdd;
              }
            }

          }
        }
        else {
          isOkay = false;
          // break;

        }
      }




      // }

      const totalCost = localValueCollection.reduce((acc, item) => {
        if (item.twoDFileRequired) return acc;
        const selectedTechnologyId = item?.threeDTechnology;
        const selectedMaterialId = item?.threeDMaterial;
        const selectedProcessId = item?.threeDPostProcessing;

        // console.log("Selected Tech :", selectedTechnologyId);
        // console.log("Selected Material  :", selectedMaterialId);
        // console.log("Selected PostProcess :", selectedProcessId);
        let selectedMachine;
        let selectedPostProcess;
        let selectedTechnology;
        let selectedMaterial;
        outerLoop:
        for (const tech of masterThreeDTechnologyData) {
          const { machines, name: techName, _id: techId } = tech;
          for (const machine of machines || []) {
            const { name: machineName } = machine;
            const { materials } = machine;

            for (const material of materials || []) {
              const { name: materialName, minCost = 0, _id: materialId } = material;
              const { postProcess } = material;

              for (const pProcess of postProcess || []) {
                const { name: postProcessName, color, _id: postProcessId } = pProcess;

                if (postProcessId == selectedProcessId && selectedTechnologyId == techId && selectedMaterialId == materialId) {
                  selectedMachine = machine;
                  selectedMaterial = material;
                  selectedPostProcess = pProcess;
                  selectedTechnology = tech;
                  break outerLoop;
                }
              }
            }
          }
        }

        if (selectedTechnology?.technologyType?.toLocaleLowerCase() == 'metal') {
          return acc + item.price;
        }
        return acc + item.price * item.Qty;


      }, 0)

      // console.log("Full Total Cost Value :", totalCost);
      // console.log(newUpdatedValues);
      setValues([...localValueCollection], createQuoteDispatch, createQuoteState)
      if (totalCost > 0 && isOkay) {
        setIsInvalidFiles(false);
        // console.log("Came here 12", {
        //   ...createQuoteState.commanValues,
        //   hidePrice: false,
        //   price: totalCost,
        //   price1: 0,
        //   leadTime1: totalLeadTime,
        //   isQrTrackingRequested: false
        // })

        setCommanValues({
          ...createQuoteState.commanValues,
          hidePrice: false,
          price: totalCost,
          price1: 0,
          leadTime1: totalLeadTime,
          isQrTrackingRequested: false
        }, createQuoteDispatch, createQuoteState)
      }
      else {
        setIsInvalidFiles(false);
        // console.log("Came here 12", {
        //   ...createQuoteState.commanValues,
        //   hidePrice: true,
        //   price: 0,
        //   price1: 0,
        //   leadTime1: totalLeadTime,
        //   isQrTrackingRequested: false
        // })

        setCommanValues({
          ...createQuoteState.commanValues,
          hidePrice: false,
          price: 0,
          price1: 0,
          leadTime1: totalLeadTime,
          isQrTrackingRequested: false
        }, createQuoteDispatch, createQuoteState)
      }
      setIsGlobeLoader(false);

    } catch (error) {
      console.log("Error 64  in catch", error);
      setIsInvalidFiles(true);
      let updatedValues = [...createQuoteState?.values];
      updatedValues.forEach(value => {
        value.price = 0;
        value.price1 = 0;
        value.price2 = 0;
        value.stManualPrice = 0;
        value.sheetAndInjectionManualPrice = 0;
      })
      console.log("Came here 13")
      setCommanValues({
        ...createQuoteState.commanValues,
        hidePrice: true,
        price: 0,
        price1: 0,
        price2: 0,
        leadTime1: 0,
        leadTime2: 0,
        isQrTrackingRequested: false
      }, createQuoteDispatch, createQuoteState)
      setValues(updatedValues, createQuoteDispatch, createQuoteState)
      setIsGlobeLoader(false);
      console.log('globe loader 5');
      console.log("Error in price fetch", error)
    }


  }

  const handleIsUserLogin = () => {
    const isLogin = localStorage.getItem("isLoggedIn");
    if (!isLogin) {
      return false;
    }
    return true
  };

  const withUserLogin = (fn) => {
    return (...args) => {
      const isLoggedIn = handleIsUserLogin();
      // console.log("is User logged In", isLoggedIn);
      if (isLoggedIn) {
        fn(...args);
      }
      else {
        setIsSignInModalOpen(true);
        setIsFunctionalityPending(true);
        setPendingArgument(...args);
      }
    }
  }


  const handleArchiveQuotation = async () => {
    try {
      if (selectedQuote?.isCancelled) {
        toast.error("Quotation is already archived");
        return;
      }

      if (selectedQuote.myorders) {
        toast.error("This quotation cannot be archived as the order has already been created.");
        return;
      }
      const quoteCancelResponse = confirm(
        "Are you sure that you want to archive this version of  quotation?"
      );

      if (quoteCancelResponse) {
        let data = {
          quoteId: selectedQuote._id,
        };
        const response = await handleQuoteCancel(token, data);
        if (response.status) {
          setSelectedQuote({
            ...selectedQuote,
            isCancelled: true
          });
          toast.success("Quotation achieved successfully.");
        }
      }
    } catch (err) {
      console.log('error', err);
      toast.error("Something went wrong.Please try again later")
    }
  }

  const handleMoveToProgress = async () => {
    try {
      let data = {
        quoteId: selectedQuote._id,
      };
      const response = await moveQuoteToProgressStatus(token, data);
      if (response.status) {
        setSelectedQuote({
          ...selectedQuote,
          isCancelled: false
        })
        toast.success(response.message);
      } else {
        toast.error("Something went wrong.Please try again later!");
      }
    } catch (e) {
      console.log("Error 64353  in catch", e);

      toast.error(e.message || "Something went wrong.Please try again later!");
    }
  };

  const handleAuthUser = async () => {
    if (isNavigationPending) {
      const { pathname } = location.state;
      navigate(pathname);
    }
    if (isFunctionalityPending) {
      helperQuotation(pendingArgument);
    }
    if (isPtcPending) {
      onSubmit(null, { ptc: true });
    }
    if (isRequestReviewPending) {
      onSubmit(null, { saveQuote: true });
    }
  }

  const helperQuotation = async ({ functionalityName }) => {
    let newId = quoteId;
    if (!quoteId) {
      const response = await saveApiCall(null, "new");
      navigate(`/create-instant-quotation/${response._id}?functionalityName=${functionalityName}`);
    }
    else {
      if (functionalityName == 'chat') {
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsChatOpen(true);
      }
      if (functionalityName == 'ftp') {
        setIsForwardToPurchaserModalOpen(true);
        setTeamModalShow(false);
        setIsChatOpen(false);
      }
      if (functionalityName == 'teams') {
        setTeamModalShow(true);
        setIsForwardToPurchaserModalOpen(false);
        setIsChatOpen(false);
      }
      if (functionalityName == 'dq') {
        handleDownloadQuotation();
      }
      if (functionalityName == 'share') {
        setIsShareModalOpen(true)
      }
      if (functionalityName == 'move-to-progress') {
        handleMoveToProgress();
      }
      if (functionalityName == 'archive') {
        handleArchiveQuotation();
      }
      if (functionalityName == 'stc') {
        setIsTargetCostModalOpen(true);
      }
    }
  };



  const handleDraftQuoteFunctionality = withUserLogin(helperQuotation);

  const updateValueOnChange = (index, key, value, name) => {
    // console.log(index, key, value, name)
    return new Promise(async (resolve, reject) => {
      try {
        if (repeatOrder) {
          // let userConfirmation = confirm("Are you sure you want to change the material selected for the previous quotation? This will create a new version of quotation and will need admin review before you can place an order for this quotation.");
          // if (userConfirmation) {


          if (createQuoteState?.commanValues.selectedProcess == 1) {
            let userConfirmation = confirm("Are you sure you want to change the material selected for the previous quotation? This will create a new version of quotation and will need admin review before you can place an order for this quotation.");
            if (userConfirmation) {
              navigate(`/create-instant-quotation/${_id}?isRepeatOrderWithUpdate=true`)
              return;
            }
          }
          navigate(`/create-instant-quotation/${_id}?isRepeatOrderWithUpdate=true`)
          return;

        }
        let initialMaterialId = '';
        if (key == 'MaterialType') {
          initialMaterialId = createQuoteState?.values[index].MaterialType;
        }
        const updatedValues = [...createQuoteState?.values];
        if (createQuoteState?.commanValues?.sameForAllField) {
          if (key == 'Notes' || key == 'finalCost' || key == "description" || key == "noOfThread" || key == "Qty") {
            const singleErrors = updatedValues[index].errors || {}
            singleErrors[key] = false
            updatedValues[index][key] = value
            if (!value) {
              singleErrors[key] = true
            }
            updatedValues[index].errors = { ...singleErrors }
          }
          else {
            for (let v = 0; v < updatedValues.length; v++) {
              const singleErrors = updatedValues[v].errors || {}
              singleErrors[key] = false
              updatedValues[v][key] = value
              if (!value) {
                if (name === 'stp' || name === 'step' || name === 'iges' || name === 'obj' || name === 'STP' || name === 'STEP' || name === 'IGES' || name === 'OBJ') {
                  if (key == "SubMaterial") {
                    singleErrors[key] = false;
                  } else {
                    singleErrors[key] = true
                  }
                }
              }
              updatedValues[v].errors = { ...singleErrors }
            }
          }
        } else {
          const singleErrors = updatedValues[index]?.errors || {}
          singleErrors[key] = false
          updatedValues[index][key] = value
          if (!value) {
            if (name === 'stp' || name === 'step' || name === 'iges' || name === 'obj' || name === 'STP' || name === 'STEP' || name === 'IGES' || name === 'OBJ' || name === "STL" || name === "stl") {
              if (key == "SubMaterial") {
                singleErrors[key] = false;
              } else {
                singleErrors[key] = true
              }
            }
          }
          updatedValues[index].errors = { ...singleErrors }
        }

        if (key === 'Qty') {
          if (createQuoteState?.commanValues.selectedProcess === 1) {
            let updatedBulkPercentageForItem = Number(name);
            if (updatedBulkPercentageForItem > 0) {
              updatedValues[index].price = updatedValues[index].algorithmPrice * (100 - updatedBulkPercentageForItem) / 100;
              updatedValues[index].price1 = 0.8 * updatedValues[index].algorithmPrice * (100 - updatedBulkPercentageForItem) / 100;
            } else {
              updatedValues[index].price = updatedValues[index].algorithmPrice;
              updatedValues[index].price1 = 0.8 * updatedValues[index].algorithmPrice;
            }

            let totalCost1 = updatedValues?.reduce((acc, item) => {
              return acc + (item.price * item.Qty);
            }, 0)

            let totalCost2 = updatedValues?.reduce((acc, item) => {
              return acc + (item.price1 * item.Qty);
            }, 0);

            let localValueCollection = [...createQuoteState?.values];

            let totalQuantityOfItems = 0;
            for (let i = 0; i < localValueCollection.length; i++) {
              totalQuantityOfItems += localValueCollection[i].Qty;
            }

            let additionalCost = 0;

            let shippingCharge = 0;
            console.log("Came here 14")
            setCommanValues({
              ...createQuoteState?.commanValues,
              price: totalCost1.toFixed(2),
              price1: createQuoteState?.commanValues.selectedProcess == 1 ? (totalCost2 ? totalCost2.toFixed(2) : 0) : 0,
              additionalCost1: additionalCost,
              additionalCost2: additionalCost,
              shippingCharge1: shippingCharge,
              shippingCharge2: shippingCharge,
            }, createQuoteDispatch, createQuoteState)
          }
          if (createQuoteState?.commanValues.selectedProcess === 2) {
            handleThreeDPriceUpdate()
          }
        }
        if (key == 'threeDTechnology') {
          updatedValues[index][key] = value;
        }
        if (key == 'threeDMachine') {
          updatedValues[index][key] = value;
        }
        if (key == 'threeDMaterial') {
          updatedValues[index][key] = value;
        }
        if (key == 'threeDPostProcessing') {
          // updatedValuesm 4
          updatedValues[index][key] = value;
        }
        if (key == 'isOutOfSpec') {
          updatedValues[index][key] = value;
        }
        // console.log("Running");
        setValues(updatedValues, createQuoteDispatch, createQuoteState)

        let fieldForUpdatePriceCnc = ['MaterialType', 'partMarking', 'noOfThread', 'sameForAllField', 'SubMaterial', 'Qty', 'surfaceFinish', 'surfaceTreatment', 'tolerance'];
        let fieldForUpdatePriceThreeD = ['threeDTechnology', 'threeDMachine', 'threeDMaterial', 'threeDPostProcessing'];
        if (fieldForUpdatePriceCnc.includes(key) || fieldForUpdatePriceThreeD.includes(key)) {
          let isAllFilesValid = true;
          for (let i = 0; i < updatedValues.length; i++) {
            let extension = getFileExtension(updatedValues[i]?.file?.originalname || updatedValues[i]?.selectedFile?.name);
            if (!(extension == 'stp' || extension == 'step' || extension == 'iges' || extension == 'stl' ||
              extension == 'STP' || extension == 'STEP' || extension == 'IGES' || extension == 'STL')) {
              isAllFilesValid = false;
            }
          }

          if (isAllFilesValid) {
            if (createQuoteState?.commanValues.selectedProcess == 1 && key == "MaterialType" && fieldForUpdatePriceCnc.includes(key)) {
              if (value && (initialMaterialId != value)) {
                handleCNCPriceUpdate(index);
              }
            } else if (createQuoteState?.commanValues.selectedProcess == 1 && (key == "partMarking" || key == "SubMaterial" || key == "surfaceFinish" || key == "surfaceTreatment" || key == 'tolerance') && fieldForUpdatePriceCnc.includes(key)) {
              handleCNCValueUpdateOnValuesChange(index, updatedValues);
            }
            else if (createQuoteState?.commanValues.selectedProcess == 2 && fieldForUpdatePriceThreeD.includes(key)) {
              handleThreeDPriceUpdate();
            }
          } else {
            setIsInvalidFiles(true);
            let updatedValues = [...createQuoteState?.values];
            updatedValues.forEach(value => {
              value.price = 0;
              value.price1 = 0;
              value.price2 = 0;
              value.stManualPrice = 0;
              value.sheetAndInjectionManualPrice = 0;
            })
            console.log("Came here 15")
            setCommanValues({
              ...createQuoteState.commanValues,
              hidePrice: true,
              price: 0,
              price1: 0,
              price2: 0,
              leadTime1: 0,
              leadTime2: 0,
              isQrTrackingRequested: false
            }, createQuoteDispatch, createQuoteState)
            setValues(updatedValues, createQuoteDispatch, createQuoteState)
            setThreeDError("Manual review is required for the parts you have uploaded.")
          }
        }
        return resolve(updatedValues)
      } catch (err) {
        console.log("Error 1 65464", err)

        return reject(err)
      }
    });
  }

  const handleCNCValueUpdateOnValuesChange = (index, newValuesCollection) => {
    let localItemAtIndex = newValuesCollection[index];
    let itemCost1 = updateAlgorithmPrice(localItemAtIndex.algorithmPrice);
    let algorithmPriceFromApi = updateAlgorithmPrice(itemCost1)
    let percent = 0;
    let isCncPriceZero = false;
    let isError = { status: false, errMessage: '' };
    if (!localItemAtIndex.price) {
      isCncPriceZero = true;
      isError.status = true;
      isError.errMessage = "Error fetching price";
    }
    let subMaterialAtIndex = subMaterial.filter(item => item?._id == localItemAtIndex?.SubMaterial);
    let partMarkingAtIndex = partmarking.filter(item => item?._id == localItemAtIndex?.partMarking);
    let surfaceFinishAtIndex = surfaceFinish.filter(item => item?._id == localItemAtIndex?.surfaceFinish);
    let surfaceTreatmentAtIndex = surfaceTreatment.filter(item => item?._id == localItemAtIndex?.surfaceTreatment)
    let toleranceAtIndex = tolerance.filter(item => item?._id == localItemAtIndex?.tolerance);
    let subMaterialFactorAtIndex = subMaterialAtIndex.length > 0 ? Number(subMaterialAtIndex[0].SubMatFactor) : 0;
    let partMarkingFactorAtIndex = partMarkingAtIndex.length > 0 ? Number(partMarkingAtIndex[0].PMFactor) : 0;
    let surfaceFinishFactorAtIndex = surfaceFinishAtIndex.length > 0 ? Number(surfaceFinishAtIndex[0].SfFactor) : 0;
    let surfaceTreatmentFactorAtIndex = surfaceTreatmentAtIndex.length > 0 ? Number(surfaceTreatmentAtIndex[0].StFactor) : 0;
    let toleranceFactorAtIndex = toleranceAtIndex.length > 0 ? Number(toleranceAtIndex[0].TolFactor) : 0;
    itemCost1 = (itemCost1 * (1 + subMaterialFactorAtIndex + surfaceFinishFactorAtIndex + surfaceTreatmentFactorAtIndex + toleranceFactorAtIndex)) + partMarkingFactorAtIndex;
    localItemAtIndex.algorithmPrice = algorithmPriceFromApi
    localItemAtIndex.algorithmPrice1 = createQuoteState?.commanValues?.selectedProcess == 1 ? (0.8 * algorithmPriceFromApi).toFixed(2) : 0;
    localItemAtIndex.price = (itemCost1 * (100 - percent) / 100).toFixed(2);
    localItemAtIndex.price1 = createQuoteState?.commanValues?.selectedProcess == 1 ? (0.8 * itemCost1 * (100 - percent) / 100).toFixed(2) : 0;
    newValuesCollection[index] = localItemAtIndex;
    let totalCost1 = newValuesCollection?.reduce((acc, item) => {
      return acc + (item.price * item.Qty);
    }, 0);

    let totalCost2 = newValuesCollection?.reduce((acc, item) => {
      return acc + (item.price1 * item.Qty);
    }, 0);

    let totalQuantityOfItems = 0;
    for (let i = 0; i < newValuesCollection.length; i++) {
      totalQuantityOfItems += newValuesCollection[i].Qty;
    }

    let additionalCost = 0;
    let shippingCharge = 0;


    if (isCncPriceZero) {
      console.log("Came here 16")
      setCommanValues({
        ...createQuoteState?.commanValues,
        hidePrice: true,
      }, createQuoteDispatch, createQuoteState)
    } else {
      console.log("Came here 17")
      setCommanValues({
        ...createQuoteState?.commanValues,
        price: totalCost1 ? totalCost1.toFixed(2) : 0,
        price1: createQuoteState?.commanValues?.selectedProcess == 1 ? (totalCost2 ? totalCost2.toFixed(2) : 0) : 0,
        hidePrice: false,
        additionalCost1: additionalCost,
        additionalCost2: additionalCost,
        shippingCharge1: shippingCharge,
        shippingCharge2: shippingCharge,
        leadTime1: createQuoteState?.commanValues?.selectedProcess == 2 ? 18 : 10,
      }, createQuoteDispatch, createQuoteState)
    }
    setValues([...newValuesCollection], createQuoteDispatch, createQuoteState)
    if (isError?.status) {
      if (createQuoteState?.commanValues?.selectedProcess == 2) {
        setThreeDError("Manual review is required for the parts you have uploaded.");
      }
      console.log("Came here 18")
      setCommanValues({
        ...createQuoteState?.commanValues,
        hidePrice: true,
        price: 0,
        price1: 0,
      }, createQuoteDispatch, createQuoteState)
    }
  }

  const updateCommanValueOnChange = (key, value) => {
    return new Promise((resolve, reject) => {
      try {
        if (value === 3 && key == 'selectedShipMethod' && stmp == 0) {
          toast.error("Can't set delivery time with empty price")
          return;
        }
        const updatedValues = { ...createQuoteState?.commanValues }
        updatedValues[key] = value;
        // console.log('createQuoteState?.commanValues cam here 111', updatedValues)
        setCommanValues(updatedValues, createQuoteDispatch, createQuoteState)
        if (key === "sameForAllField") {
          if (value) {
            console.log("Came here 3")
            setSameForAllField(true, createQuoteDispatch, createQuoteState)
          } else {
            console.log("Came here 4")
            setSameForAllField(false, createQuoteDispatch, createQuoteState)
          }
          handleSameForAllField(key, value);
        }
        return resolve(updatedValues)
      } catch (err) {
        console.log("Error 5 in catch", err);
        return reject(err)
      }
    })
  }


  let handleSameForAllField = (key, value) => {
    try {
      let status = value;
      if (key !== 'sameForAllField') {
        return;
      }
      else if (status == true) {
        let updatedValues = [...createQuoteState?.values]
        if (updatedValues.length <= 1) {
          return;
        }
        else if (updatedValues.length > 1) {
          for (let v = 1; v < updatedValues.length; v++) {
            updatedValues[v].MaterialType = updatedValues[0].MaterialType;
            updatedValues[v].SubMaterial = updatedValues[0].SubMaterial;
            updatedValues[v].layer = updatedValues[0].layer;
            updatedValues[v].partMarking = updatedValues[0].partMarking;
            updatedValues[v].surfaceTreatment = updatedValues[0].surfaceTreatment;
            updatedValues[v].surfaceFinish = updatedValues[0].surfaceFinish;
            updatedValues[v].tolerance = updatedValues[0].tolerance;
            updatedValues[v].threeDTechnology = updatedValues[0].threeDTechnology;
            updatedValues[v].threeDMachine = updatedValues[0].threeDMachine;
            updatedValues[v].threeDMaterial = updatedValues[0].threeDMaterial;
            updatedValues[v].threeDPostProcessing = updatedValues[0].threeDPostProcessing;
          }

          setValues([...updatedValues], createQuoteDispatch, createQuoteState)
        }

        if (createQuoteState?.commanValues?.selectedProcess == 1) {
          handleCNCPriceUpdate()
        } else {
          handleThreeDPriceUpdate();
        }

      }
      else if (status == false) {
        return;
      }

    } catch (error) {
      console.log("Error 35436  in catch", error);

      return error
    }
  }

  const handleCloseUserModal = () => {
    setShowUserModal(false);
    setAllFilteredUsers(allUsers);
  }

  const searchUser = (e) => {
    let localFilteredUser = allUsers.filter(item => (item.firstName + " " + item.lastName).toUpperCase().includes((e.target.value).toUpperCase()));
    setAllFilteredUsers(localFilteredUser);
  }

  const handleAdminCreateQuote = async (user) => {
    let responseAdmin = confirm("Are you sure you want to create a quotation for the selected user?");
    try {
      if (responseAdmin) {
        setIsQuoteCreationInProcess(true);
        const response = await saveApiCall(user);
        setIsQuoteCreationInProcess(false);
        toast.success("Quotation Saved Successfully");
        if (isAdminShare) {
          navigate("/create-instant-quotation/" + response._id, { state: { functionalityName: 'Share', savedQuoteRefId: response.RefId } });
          setShowUserModal(false);
        } else {
          navigate('/admin/quotes')
        }
      }
    } catch (err) {
      setIsQuoteCreationInProcess(false)
      console.log("Error in cartch3423423", err)
      toast.error("Something went wrong while creating quotation.")
    }

  }

  const handleQuoteCreateUpdate = (updateType, dataFromOnSubmit, isSaveForFunctionalityFromOnSubmit) => {
    console.log("Update Type :", updateType);
    setShowQuoteUpdateModal(false);
    let data;
    let isSaveForFunctionality;

    if (!updateType) {
      data = dataFromOnSubmit;
      isSaveForFunctionality = isSaveForFunctionalityFromOnSubmit;
    } else {
      data = dataForQuote;
      isSaveForFunctionality = isSaveForFunctionalityState;
    }

    if (createQuoteState?.commanValues?.selectedProcess == 2) {
      data = dataFromOnSubmit;
      console.log("Data :", data);
    }

    return new Promise(async (resolve, reject) => {
      try {
        if (isSaveForFunctionality?.functionalityName == 'Chat' && isAdminUser) {
          alert("Chat and Manual quotation functionality is not available for admin user.Please save quote using save button to create a RFQ");
          return;
        }
        if (isSaveForFunctionality?.functionalityName == 'Share') {
          setIsAdminShare(true);
        }

        if (quoteId) {
          if (updateType == "isRepeatOrder") {
            // let userUpdateResponse = confirm("Are you sure you want to proceed with the current details? ? A new quotation version will be created for your repeat order on your confirmation.")
            // if (!userUpdateResponse) {
            //   return;
            // }
          } else if (updateType == "isRepeatOrderWithUpdate") {
            // let userUpdateResponse = confirm("Are you sure you want to proceed with the current details? A new quotation version will be created on your confirmation.")
            // if (!userUpdateResponse) {
            //   return;
            // }
          } else {
            // let userUpdateResponse = confirm(`Are you sure you want to update the quotation with the current details? ${updateType == "new" ? "A new quotation version will be created on your confirmation." : "Existing quotation will be updated on your confirmation."}`)
            // if (!userUpdateResponse) {
            //   return;
            // }
          }
        }


        // else {
        //   let userUpdateResponse = confirm("Are you sure you want to save the quotation with the current details?")
        //   if (!userUpdateResponse) {
        //     return;
        //   }
        // }




        if (createQuoteState?.values.length == 0) {
          toast.error("Please add parts in the quote to update the quote");
          return;
        }

        switch (true) {
          case (createQuoteState?.commanValues?.selectedProcess == 1):
            const cncRes = await validateCNCData()
            if (cncRes) {
              break;
            }
            return resolve(cncRes)
          case (createQuoteState?.commanValues?.selectedProcess == 2):
            const threeDRes = await validateThreeDPrinting()
            if (threeDRes) {
              break;
            }
            return resolve(threeDRes)
          case (createQuoteState?.commanValues?.selectedProcess == 3):
            const sheetRes = await validateSheetAndInjection()
            if (sheetRes) {
              break;
            }
            return resolve(sheetRes)
          case (createQuoteState?.commanValues?.selectedProcess == 4):
            const injectionRes = await validateSheetAndInjection()
            if (injectionRes) {
              break;
            }
            return resolve(injectionRes)
          case (createQuoteState?.commanValues?.selectedProcess > 4):
            const otherRes = await validateSheetAndInjection()
            if (otherRes) {
              break;
            }

          default:
            return resolve(true)
        }
        let response = null;
        if (!isAdminUser) {
          if (updateType === 'isRepeatOrder' || updateType == 'ptc') {
            response = await saveApiCall(null, "new");
            console.log("Response Response :", response);
            navigate("/get-instant-quote/checkout/" + response._id)
          } else if (updateType === "isRepeatOrderWithUpdate") {
            response = await saveApiCall(null, "new")
            if (createQuoteState?.commanValues?.selectedProcess == 1) {
              toast.success("Quotation sent to review successfully.Once prices are confirmed by admin you can create an order for this quotation.");
            } else if (createQuoteState?.commanValues?.selectedProcess == 2 && dataFromOnSubmit?.saveQuote) {
              navigate('/saved-quote')
            }
            else {
              navigate("/get-instant-quote/checkout/" + response._id)
            }
          } else if (createQuoteState?.commanValues?.selectedProcess == 2 && data?.ptc) {
            response = await saveApiCall(null, "new")
            if (createQuoteState?.commanValues?.selectedProcess == 1) {
              toast.success("Quotation sent to review successfully.Once prices are confirmed by admin you can create an order for this quotation.");
              navigate('/saved-quote')
            } else if (createQuoteState?.commanValues?.selectedProcess == 2 && data?.ptc) {
              navigate("/get-instant-quote/checkout/" + response._id)
            } else {
              navigate('/saved-quote')
            }
          } else {
            response = await saveApiCall(null, updateType)
            toast.success("Quotation sent to review Successfully");
            if (isSaveForFunctionality) {
              navigate("/create-instant-quotation/" + response._id, { state: { functionalityName: isSaveForFunctionality.functionalityName, savedQuoteRefId: response.RefId } });
            } else {
              navigate('/saved-quote')
            }
          }
          return resolve(response)
        } else {
          if (!quoteId) {
            setShowUserModal(true);
          } else {
            let response = await saveApiCall(null, updateType);

            toast.success("Quotation Updated Successfully");
            if (isSaveForFunctionality) {
              navigate("/create-instant-quotation/" + response._id, { state: { functionalityName: isSaveForFunctionality.functionalityName, savedQuoteRefId: response.RefId } });
            } else {
              navigate(`/admin/admin-quote/${response._id}`);
            }
          }

        }
      } catch (err) {
        console.log("error in erere", err)
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message)
          return;
        }
        let message = err && err.message ? err.message : "Something went wrong during save quote"
        toast.error(message)
        return reject(err)
      }
    })
  }





  const onSubmit = (e, data, isSaveForFunctionality) => {
    const isLoggedInUser = handleIsUserLogin();
    console.log("Ig Logged IN User :", isLoggedInUser);
    if (!isLoggedInUser) {
      setIsSignInModalOpen(true);
      if (data.saveQuote) {
        setIsRequestReviewPending(true);
      }
      else {

        setIsPtcPending(true);
      }
      return;
    }

    if (quoteId) {
      // debugger
      if (repeatOrder) {
        handleQuoteCreateUpdate("isRepeatOrder")
      } else if (isRepeatOrderWithUpdate) {
        handleQuoteCreateUpdate("isRepeatOrderWithUpdate")
      } else if (createQuoteState?.commanValues.myorders) {
        handleQuoteCreateUpdate("new")
      } else if (data.ptc) {
        if (createQuoteState?.commanValues.myorders) {
          handleQuoteCreateUpdate("new", data)
        } else {
          if (quoteId) {
            navigate(`/get-instant-quote/checkout/${quoteId}`);
          }
        }
      } else if (data.saveQuote) {
        handleQuoteCreateUpdate("", data, isSaveForFunctionality);
      }
      else {
        setShowQuoteUpdateModal(true)
        setIsSaveForFunctionalityState(isSaveForFunctionality)
        setDataForQuote(data);
      }
    } else {
      handleQuoteCreateUpdate("", data, isSaveForFunctionality);
    }
  }

  const validateCNCData = () => {
    return new Promise((resolve, reject) => {
      try {
        let isValidated = true;
        const validateForString = ['MaterialType'];
        const validateForNumber = ['Qty']
        const updatedValues = [...createQuoteState?.values]
        for (let v = 0; v < updatedValues.length; v++) {
          const singleVaue = updatedValues[v];
          const singleErrors = updatedValues[v].errors || {}
          if (!isValidFileName(updatedValues[v]?.selectedFile?.name || updatedValues[v]?.file?.filename)) return resolve(true);
          Object.keys(singleErrors).map(function (key) {
            if ((validateForNumber.includes(key) || validateForString.includes(key)) && singleErrors[key]) {
              isValidated = false
            }
          });
          Object.keys(singleVaue).map(function (key) {
            if (validateForNumber.includes(key) && (!singleVaue[key] || singleVaue[key] === 0 || isNaN(singleVaue[key]))) {
              isValidated = false
              singleErrors[key] = true
            }
            if (validateForString.includes(key) && (!singleVaue[key] || singleVaue[key] === "")) {
              isValidated = false
              singleErrors[key] = true
            }
          });
          updatedValues[v].errors = { ...singleErrors }
        }
        setValues([...updatedValues], createQuoteDispatch, createQuoteState)
        myRef?.current?.scrollIntoView();
        return resolve(isValidated)
      } catch (err) {

        console.log("error in catch erere34")
        return reject(err)
      }
    })
  }
  const validateThreeDPrinting = () => {
    return new Promise((resolve, reject) => {
      try {
        let isValidated = true;
        const validateForString = ['MaterialType']
        const validateForNumber = ['Qty']
        const updatedValues = [...createQuoteState?.values]
        for (let v = 0; v < updatedValues.length; v++) {
          const singleVaue = updatedValues[v];
          const singleErrors = updatedValues[v].errors || {}
          if (!isValidFileName(updatedValues[v].selectedFile?.name)) return resolve(true);
          Object.keys(singleErrors).map(function (key) {
            if ((validateForNumber.includes(key) || validateForString.includes(key)) && singleErrors[key]) {
              isValidated = false
            }
          });
          Object.keys(singleVaue).map(function (key) {
            if (validateForNumber.includes(key) && (!singleVaue[key] || singleVaue[key] === 0 || isNaN(singleVaue[key]))) {
              isValidated = false
              singleErrors[key] = true
            };
            if (validateForString.includes(key) && (!singleVaue[key] || singleVaue[key] === "")) {
              isValidated = false
              singleErrors[key] = true
            }
          });
          updatedValues[v].errors = { ...singleErrors }
        }

        setValues([...updatedValues], createQuoteDispatch, createQuoteState)
        return resolve(isValidated)
      } catch (err) {

        console.log('error in error sdfsd', err)
        return reject(err)
      }
    })
  }
  const validateSheetAndInjection = () => {
    let isValidated = true;
    let errorMessage = "";
    return new Promise((resolve, reject) => {
      try {
        createQuoteState?.values?.map((value) => {
          if (createQuoteState?.commanValues.errors.sheetAndInjectionQuantity) {
            isValidated = false
          }
          else if (value.Qty < 1 || !value.Qty) {
            isValidated = false;
            errorMessage = 'Please enter valid value for quantity';
          }
          if (!(value.file || value.selectedFile)) {
            if (value.description.length == 0) {
              isValidated = false;
              errorMessage = "Line items without uploaded files must have a description.";
            }
          }
        })
        if (!isValidated) {
          toast.error(errorMessage)
        }
        return resolve(isValidated)
      } catch (err) {
        console.log('error in carther', err)
        return reject(err)
      }
    })
  }

  const isValidFileName = (name) => {
    console.log('name for extension', name)
    const type = getFileExtension(name)?.toUpperCase();
    if (type === "STP" || type === "STEP" || type === "IGES" || type === "OBJ" || type === "STL") return true;

    return false;
  }


  const saveApiCall = (user, updateType) => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        const uniqueFileNameInBoundingSet = new Set();
        const uniqueFileNameInValuesSet = new Set();
        const uniqueBoundingArr = [];
        const uniqueFromBounding = [];

        for (let i = 0; i < bounding.length; i++) {
          if (uniqueFileNameInBoundingSet.has(bounding[i].fileName)) {
            continue;
          } else {
            uniqueFromBounding.push(bounding[i]);
            uniqueFileNameInBoundingSet.add(bounding[i].fileName);
          }
        }

        for (let i = 0; i < createQuoteState?.values?.length; i++) {
          uniqueFileNameInValuesSet.add(createQuoteState?.values[i]?.selectedFile?.path || createQuoteState?.values[i]?.file?.originalname || createQuoteState?.values[i]?.selectedFile?.name);
        }

        for (let j = 0; j < uniqueFromBounding.length; j++) {
          if (uniqueFileNameInValuesSet.has(uniqueFromBounding[j].fileName)) {
            uniqueBoundingArr.push(uniqueFromBounding[j]);
          }
        }


        let updatedValues = []
        let updatedCommanValues = { ...createQuoteState?.commanValues }
        for (let u = 0; u < createQuoteState?.values.length; u++) {
          let singleValue = createQuoteState?.values[u];
          let newObject = {}
          Object.keys(singleValue).map(function (key) {
            if (key !== 'errors') {
              if (singleValue[key] && (singleValue[key] !== "" || singleValue[key] !== ''))
                newObject[key] = singleValue[key]
            }
          })
          newObject = {
            ...newObject,
            MaterialTypeName: material.filter((item) => { return item._id === newObject.MaterialType })[0]?.name.toUpperCase()
          }
          updatedValues.push(newObject)
        }


        let user_id = localStorage.getItem("_id")

        // console.log("User Id :", user_id);

        if (isAdminUser && quoteId && userIdToEditQuote) {
          user_id = userIdToEditQuote
        }

        // if (sample) {
        //   for (let i = 0; i < updatedValues.length; i++) {
        //     if (updatedValues[i].selectedFile?.name == "SamplePart.stp") {
        //       updatedValues[i].selectedFile = { path: 'SamplePart.stp' }
        //     }
        //   }
        // };

        let formData = new FormData();
        let threeDfileCount = 0;
        let threeDfileNameArray = [];
        files.forEach(item => {
          if (item.isNotFromMongoDB) {
            threeDfileCount++;
            threeDfileNameArray.push(item.path)
          }
        })
        formData.append('uploaded3dFilesNameArray', JSON.stringify(threeDfileNameArray))
        formData.append('number3DOfFiles', threeDfileCount)
        let twoDFileCount = 0;
        let twoDFileNameArray = [];
        for (const key in twoDFiles) {
          const value = twoDFiles[key];
          if (value.file && value.isNotFromMongoDB) {
            twoDFileCount++;
            twoDFileNameArray.push(value.fileName)
          }
        }
        // console.log(updatedValues);
        formData.append('uploaded2DFilesNameArray', JSON.stringify(twoDFileNameArray))
        formData.append('numberOf2DFiles', twoDFileCount)
        formData.append('twoDFilesData', JSON.stringify(twoDFiles))
        formData.append('boundingBox', JSON.stringify(uniqueBoundingArr));
        formData.append('partsData', JSON.stringify(updatedValues));
        formData.append('selectedProcess', createQuoteState?.commanValues?.selectedProcess);
        formData.append("userId", user_id);
        formData.append('price', updatedCommanValues.price);
        formData.append('price1', updatedCommanValues.price1);
        formData.append('price2', updatedCommanValues.price2);
        formData.append('selectedShipMethod', updatedCommanValues.selectedShipMethod);
        formData.append('sheetAndInjectionDiscription', updatedCommanValues.sheetAndInjectionDiscription);
        formData.append('sheetAndInjectionQuantity', updatedCommanValues.sheetAndInjectionQuantity);
        formData.append('sameForAllField', createQuoteState?.commanValues?.sameForAllField)
        formData.append('hidePrice', updatedCommanValues.hidePrice);
        formData.append('isInvalidFiles', isInvalidFiles)
        formData.append('projectName', projectName)
        formData.append('isQrTrackingRequested', updatedCommanValues.isQrTrackingRequested)
        formData.append('isDimensionalReportRequested', updatedCommanValues.isDimensionalReportRequested)
        formData.append('isConformanceCertificateRequested', updatedCommanValues.isConformanceCertificateRequested)
        formData.append('additionalCost1', updatedCommanValues.additionalCost1)
        formData.append('additionalCost2', updatedCommanValues.additionalCost2)
        formData.append('additionalCost3', updatedCommanValues.additionalCost3)
        formData.append("certifications", JSON.stringify(updatedCommanValues.certifications));
        formData.append('shippingCharge1', updatedCommanValues.shippingCharge1)
        formData.append('shippingCharge2', updatedCommanValues.shippingCharge2)
        formData.append('shippingCharge3', updatedCommanValues.shippingCharge3)
        formData.append('leadTime1', updatedCommanValues.leadTime1 || 0)
        formData.append('leadTime2', updatedCommanValues.leadTime2 || 0)
        formData.append('leadTime3', updatedCommanValues.leadTime3 || 0)
        formData.append('updateTypeForQuote', updateType)
        formData.append('isRepeatOrderQuotation', repeatOrder);
        for (const key in twoDFiles) {
          const value = twoDFiles[key];
          formData.append('twoDFiles', value?.file);
        }
        files.map((val, i) => {
          formData.append('selectedFile', val?.selectedFile || val)
        })
        if (!token) {
          token = localStorage.getItem("Token");
        }
        if (quoteId) {

          const updateResponse = await postApi(`comman/update-quote/${quoteId}`, formData, token);
          if (!updateResponse && !updateResponse.status) {
            throw { message: updateResponse.message || "Something went wrong" };
          }
          let mailData;

          if (updateResponse.data.selectedProcess == 2 && updateResponse.data.hidePrice == true) {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: quoteId,
              id: updateResponse.data.RefId,
              process: updateResponse.data?.selectedProcess,
              sheetAndInjectionDesc: updateResponse.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(updateResponse.data.partsData),
              subTotal: updateResponse.data.stManualPrice,
              hidePrice: updateResponse.data.hidePrice,
              price: updateResponse.data.price,
              price1: updateResponse.data.price1,
              stManualPrice: updateResponse.data.stManualPrice,
              version: updateResponse.data.version,
            }
          }
          else if (updateResponse.data?.selectedProcess == 1) {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: quoteId,
              id: updateResponse.data.RefId,
              process: updateResponse.data?.selectedProcess,
              sheetAndInjectionDesc: updateResponse.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(updateResponse.data.partsData),
              subTotal: updateResponse.data.stManualPrice,
              hidePrice: updateResponse.data.hidePrice,
              price: updateResponse.data.price,
              price1: updateResponse.data.price1,
              stManualPrice: updateResponse.data.stManualPrice,
              version: updateResponse.data.version,
            }
          }
          else if (updateResponse.data?.selectedProcess >= 3) {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: quoteId,
              id: updateResponse.data.RefId,
              process: updateResponse.data?.selectedProcess,
              sheetAndInjectionDesc: updateResponse.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(updateResponse.data.partsData),
              subTotal: updateResponse.data.sheetAndInjectionManualPrice,
              hidePrice: updateResponse.data.hidePrice,
              version: updateResponse.data.version,
              sheetAndInjectionManualPrice: updateResponse.data.sheetAndInjectionManualPrice,
            }
          }
          else {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: quoteId,
              id: updateResponse.data.RefId,
              process: updateResponse.data?.selectedProcess,
              sheetAndInjectionDesc: updateResponse.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(updateResponse.data.partsData),
              subTotal: updateResponse.data.selectedShipMethod == 1 ? updateResponse.data.price1 : (updateResponse.data.selectedShipMethod == 2 ? updateResponse.data.price2 : 0),
              hidePrice: updateResponse.data.hidePrice,
              version: updateResponse.data.version,
              sheetAndInjectionManualPrice: updateResponse.data.sheetAndInjectionManualPrice,
            }
          }
          quoteSaveMail(token, mailData);
          setIsLoading(false)
          return resolve(updateResponse.data);
        } else {

          const response = await postApi('comman/save-quote', formData, token);
          console.log("Response Of Save Api Call :", response);
          if (!response && !response.status) {
            throw { message: response.message || "Something went wrong" };
          }
          let mailData;

          if (response?.data?.selectedProcess == 2 && response.data.hidePrice == true) {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: response.data._id,
              id: response.data.RefId,
              process: response.data?.selectedProcess,
              sheetAndInjectionDesc: response.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(response.data.partsData),
              subTotal: response.data.stManualPrice,
              hidePrice: response.data.hidePrice,
              price: response.data.price,
              price1: response.data.price1,
              stManualPrice: response.data.stManualPrice,
              version: response.data.version,
            }
          }
          else if (response.data?.selectedProcess == 1) {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: response.data._id,
              id: response.data.RefId,
              process: response.data?.selectedProcess,
              sheetAndInjectionDesc: response.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(response.data.partsData),
              subTotal: response.data.stManualPrice,
              hidePrice: response.data.hidePrice,
              price: response.data.price,
              price1: response.data.price1,
              stManualPrice: response.data.stManualPrice,
              version: response.data.version,
            }
          }
          else if (response.data?.selectedProcess >= 3) {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: response.data._id,
              id: response.data.RefId,
              process: response.data?.selectedProcess,
              sheetAndInjectionDesc: response.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(response.data.partsData),
              subTotal: response.data.sheetAndInjectionManualPrice,
              hidePrice: response.data.hidePrice,
              sheetAndInjectionManualPrice: response.data.sheetAndInjectionManualPrice,
              version: response.data.version,
            }
          }
          else {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: response.data._id,
              id: response.data.RefId,
              process: response.data?.selectedProcess,
              sheetAndInjectionDesc: response.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(response.data.partsData),
              subTotal: response.data.selectedShipMethod == 1 ? response.data.price1 : (response.data.selectedShipMethod == 2 ? response.data.price2 : 0),
              hidePrice: response.data.hidePrice,
              sheetAndInjectionManualPrice: response.data.sheetAndInjectionManualPrice,
              price: response.data.price,
              price1: response.data.price1,
              stManualPrice: response.data.stManualPrice,
              version: response.data.version,
            }
          }
          quoteSaveMail(token, mailData);
          setIsLoading(false)
          return resolve(response.data);
        }
      } catch (err) {
        console.log("Error 2 345435", err)
        setIsLoading(false)
        return reject(err)
      }
    })
  }
  const updateQuote = (updateType) => {
    return new Promise(async (resolve, reject) => {
      try {
        const uniqueFileNameInBoundingSet = new Set();
        const uniqueFileNameInValuesSet = new Set();
        const uniqueBoundingArr = [];
        const uniqueFromBounding = [];

        for (let i = 0; i < bounding.length; i++) {
          if (uniqueFileNameInBoundingSet.has(bounding[i].fileName)) {
            continue;
          } else {
            uniqueFromBounding.push(bounding[i]);
            uniqueFileNameInBoundingSet.add(bounding[i].fileName);
          }
        }

        for (let i = 0; i < createQuoteState?.values?.length; i++) {
          uniqueFileNameInValuesSet.add(createQuoteState?.values[i]?.selectedFile?.path || createQuoteState?.values[i]?.file?.originalname || createQuoteState?.values[i]?.selectedFile?.name);
        }

        for (let j = 0; j < uniqueFromBounding.length; j++) {
          if (uniqueFileNameInValuesSet.has(uniqueFromBounding[j].fileName)) {
            uniqueBoundingArr.push(uniqueFromBounding[j]);
          }
        }


        let updatedValues = []
        let updatedCommanValues = { ...createQuoteState?.commanValues };
        // console.log("Updated Comman Values :", updatedCommanValues);
        for (let u = 0; u < createQuoteState?.values.length; u++) {
          let singleValue = createQuoteState?.values[u];
          if (updatedCommanValues?.selectedProcess >= 3) {
            singleValue.price = 0;
            singleValue.price1 = 0;
            singleValue.price2 = 0;
          }
          let newObject = {}
          Object.keys(singleValue).map(function (key) {
            if (key !== 'errors') {
              if (singleValue[key] && (singleValue[key] !== "" || singleValue[key] !== ''))
                newObject[key] = singleValue[key]
            }
          })
          newObject = {
            ...newObject,
            MaterialTypeName: material.filter((item) => { return item._id === newObject.MaterialType })[0]?.name.toUpperCase(),
          }
          updatedValues.push(newObject)
        }


        let user_id = localStorage.getItem("_id")

        // console.log("User Id :", user_id);

        if (isAdminUser && quoteId && userIdToEditQuote) {
          user_id = userIdToEditQuote
        }

        let formData = new FormData();
        let threeDfileCount = 0;
        let threeDfileNameArray = [];
        files.forEach(item => {
          if (item.isNotFromMongoDB) {
            threeDfileCount++;
            threeDfileNameArray.push(item.path)
          }
        })
        formData.append('uploaded3dFilesNameArray', JSON.stringify(threeDfileNameArray))
        formData.append('number3DOfFiles', threeDfileCount)
        let twoDFileCount = 0;
        let twoDFileNameArray = [];
        for (const key in twoDFiles) {
          const value = twoDFiles[key];
          if (value.file && value.isNotFromMongoDB) {
            twoDFileCount++;
            twoDFileNameArray.push(value.fileName)
          }
        }
        // console.log(updatedValues);
        formData.append('uploaded2DFilesNameArray', JSON.stringify(twoDFileNameArray))
        formData.append('numberOf2DFiles', twoDFileCount)
        formData.append('twoDFilesData', JSON.stringify(twoDFiles))
        formData.append('boundingBox', JSON.stringify(uniqueBoundingArr));
        formData.append('partsData', JSON.stringify(updatedValues));
        formData.append('selectedProcess', createQuoteState?.commanValues?.selectedProcess);
        formData.append("userId", user_id);
        formData.append('price', updatedCommanValues.price);
        formData.append('price1', updatedCommanValues.price1);
        formData.append('price2', updatedCommanValues.price2);
        formData.append('selectedShipMethod', updatedCommanValues.selectedShipMethod);
        formData.append('sheetAndInjectionDiscription', updatedCommanValues.sheetAndInjectionDiscription);
        formData.append('sheetAndInjectionQuantity', updatedCommanValues.sheetAndInjectionQuantity);
        formData.append('sameForAllField', createQuoteState?.commanValues?.sameForAllField)
        formData.append('hidePrice', updatedCommanValues.hidePrice);
        formData.append('isInvalidFiles', isInvalidFiles)
        formData.append('projectName', projectName)
        formData.append('isQrTrackingRequested', updatedCommanValues.isQrTrackingRequested)
        formData.append('isDimensionalReportRequested', updatedCommanValues.isDimensionalReportRequested)
        formData.append('isConformanceCertificateRequested', updatedCommanValues.isConformanceCertificateRequested)
        formData.append('additionalCost1', updatedCommanValues.additionalCost1)
        formData.append('additionalCost2', updatedCommanValues.additionalCost2)
        formData.append('additionalCost3', updatedCommanValues.additionalCost3)
        formData.append("certifications", JSON.stringify(updatedCommanValues.certifications));
        formData.append('shippingCharge1', updatedCommanValues.shippingCharge1)
        formData.append('shippingCharge2', updatedCommanValues.shippingCharge2)
        formData.append('shippingCharge3', updatedCommanValues.shippingCharge3)
        formData.append('leadTime1', updatedCommanValues.leadTime1 || 0)
        formData.append('leadTime2', updatedCommanValues.leadTime2 || 0)
        formData.append('leadTime3', updatedCommanValues.leadTime3 || 0)
        formData.append('updateTypeForQuote', updateType)
        formData.append('isRepeatOrderQuotation', repeatOrder);
        for (const key in twoDFiles) {
          const value = twoDFiles[key];
          formData.append('twoDFiles', value?.file);
        }
        files.map((val, i) => {
          formData.append('selectedFile', val?.selectedFile || val)
        })
        if (!token) {
          token = localStorage.getItem("Token");
        }
        if (quoteId) {
          const updateResponse = await postApi(`comman/update-quote/${quoteId}`, formData, token);
          // console.log("updateResponse", updateResponse)
          if (!updateResponse && !updateResponse.status) {
            throw { message: updateResponse.message || "Something went wrong" };
          }else{
            if(updateType=="new"){
              navigate(`/create-instant-quotation/${updateResponse?.data?._id}`);
            }
          }
          return resolve(updateResponse.data);
        }
      } catch (err) {
        console.log("Error 3 345435", err)
        setIsLoading(false)
        return reject(err)
      }
    })
  }

  const fetchDataFromApi = (endPoint, payload = []) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetchApi(endPoint, payload);
        if (!response && !response.status) {
          throw { message: response.message || "Something went wrong" };
        }
        return resolve(response.data);
      } catch (err) {
        console.log("Error 4 5645654", err)
        return reject(err);
      }
    });
  };

  const handleDelete = (index) => {
    try {
      let afterFltr = values.filter((val, i) => i !== index)
      let fileName = (values[index]?.selectedFile?.name || values[index]?.file?.originalname) + index;
      if (matSubMatMap.get(fileName)) {
        let localMatSubMap = matSubMatMap;
        localMatSubMap.delete(fileName);
        setMatSubMatMap(localMatSubMap);
      }
      let boundingAfterFltr = bounding.filter((val, i) => i !== index)

      setValues([...afterFltr], createQuoteDispatch, createQuoteState);
      setBounding(boundingAfterFltr)

      let afterDeleteFile = files?.filter((val, i) => i !== index);

      if (quoteId) {
        for (let val of afterDeleteFile) {
          let ext = getFileExtension(val?.originalname || val?.selectedFile?.path);
          if (ext == 'stp' || ext == 'step' || ext == 'iges' || ext == 'stl' ||
            ext == 'STP' || ext == 'STEP' || ext == 'IGES' || ext == 'STL'
          ) {
            setIsInvalidFiles(false);
            continue;
          }
          else {
            break;
          }
        }
      }
      else if (!quoteId) {
        for (let val of afterDeleteFile) {
          let ext = getFileExtension(val?.name || val?.selectedFile?.path);
          if (ext == 'stp' || ext == 'step' || ext == 'iges' || ext == 'stl' ||
            ext == 'STP' || ext == 'STEP' || ext == 'IGES' || ext == 'STL'
          ) {
            setIsInvalidFiles(false);
            continue;
          }
          else {
            break;
          }
        }
      }
      setFiles([...afterDeleteFile]);
      try {
        let local2DFiles = { ...twoDFiles };
        delete local2DFiles[index];
        for (let i = index + 1; i < Object.keys(local2DFiles).length + 1; i++) {
          // console.log('local2D fiels at i-1',local2DFiles[i - 1])
          // console.log('local2D fiels at i',local2DFiles[i])
          local2DFiles[i - 1] = local2DFiles[i];
          delete local2DFiles[i];
        }
        setTwoDFiles({ ...local2DFiles });
      } catch (err) {
        console.log('err', err);
      }
    }
    catch (err) {
      console.log("err", err)
    }
  }


  // ================


  const OnDropFunction = (files) => {
    try {
      const validFiles = files.filter(file => file.size <= maxFileUploadSize);
      // console.log(validFiles);
      // console.log(files);

      if (validFiles.length > 0) {
        onSelectfile(validFiles);
      }

      if (validFiles.length < files.length) {
        toast.error("Some files were too large and have been excluded (max size: 25MB).");
      }
    } catch (e) {
      console.log("Error in OnDropFunction", e);
      return e;
    }
  };



  const handleOnProceedTOU = async () => {

    const isLoggedInUser = handleIsUserLogin();
    if (!email) {
      toast.error("Kindly enter your email to continue.");
      return;
    }
    if (email && email.split("@")[1] == '8xparts.com') {
      toast.error("The requested domain is not allowed.");
      return;
    }

    if (!isValidEmail(email)) {
      toast.error("Please Enter Valid Email");
      return;
    }
    const response = await checkCreateUser(email);
    console.log("Response :", response);
    if (!response || !response.success) {
      throw new Error();
    };
    const { data } = response;
    const { _id, token } = data;
    if (_id) {
      localStorage.setItem("_id", _id);
    }
    if (token) {
      localStorage.setItem("Token", JSON.stringify(token));
    }

    setIsUserInfoReceived(true);
    setShowTermsOfUseModal(false)


  }

  const handleOnCancelTOU = () => {
    setShowTermsOfUseModal(false)
    resetCreateQuoteState(createQuoteState?.commanValues?.selectedProcess, createQuoteDispatch, createQuoteState)
    setIsProcessing(false);
    setIsGlobeLoader(false)
  }

  return (
    <div className='bg-white'>
      {/* Code not related to screen, such as modal, loader etc. */}
      <QuotationLoader
        selectedProcess={createQuoteState?.commanValues?.selectedProcess}
        processing={isProcessing}
        globeLoader={isGlobeLoader}
        isLoading={isLoading}
      />

      {showTermsOfUseModal ?
        <>
          <TermsOfUseModal
            onProceed={handleOnProceedTOU}
            onCancel={handleOnCancelTOU}
            email={email}
            setEmail={setEmail}
            setIsUserLoggOut={setIsUserLoggOut}
            isUserLoggOut={isUserLoggOut}
          />
        </> : <></>}



      {/* Code for UI */}
      {isLoggedIn ?
        <>
          <Header
            isUserLoggOut={isUserLoggOut} />
        </>
        :
        <>
          <div className="h-15 flex justify-between items-center px-10 bg-newPrimaryColor text-white">
            <Link to="">
              <img className="h-[80px]" src={logo} />
            </Link>{" "}
            {/* <Link to="/login" className="back-btn bg-white text-black px-3 py-1 rounded">
              Login
            </Link> */}
          </div>
        </>}



      {/* Create Quote D */}
      <div className={`grid h-full ${createQuoteState?.values?.length ? "grid-cols-1 sm:grid-cols-5" : "grid-cols-1 sm:grid-cols-4"} gap-0 min-h-screen`}>
        <LeftSection
          currentAcceptableProcess={currentAcceptableProcess}
          setIsProcessing={setIsProcessing}
          setIsGlobeLoader={setIsGlobeLoader}
          files={files}
          setFiles={setFiles}
          setIsQuoteChanged={setIsQuoteChanged}
          setBounding={setBounding}
          bounding={bounding}
          OnDropFunction={OnDropFunction}
          onSelectfile={onSelectfile}
        />
        <CenterSection
          handleDraftQuoteFunctionality={handleDraftQuoteFunctionality}
          setIsProcessing={setIsProcessing}
          setIsGlobeLoader={setIsGlobeLoader}
          files={files}
          setFiles={setFiles}
          setIsQuoteChanged={setIsQuoteChanged}
          isQuoteChanged={isQuoteChanged}
          setBounding={setBounding}
          bounding={bounding}
          masterThreeDTechnologyData={masterThreeDTechnologyData}
          isEditQuote={isEditQuote}
          boxDimension={boxDimension}
          processing={isProcessing}
          globeLoader={isGlobeLoader}
          sample={isSamplePartUploaded}
          setSample={setIsSamplePartUploaded}
          twoDFiles={twoDFiles}
          setTwoDFiles={setTwoDFiles}
          setIsInchSelected={setIsInchSelected}
          isInchSelected={isInchSelected}
          onSubmit={onSubmit}
          updateCommanValueOnChange={updateCommanValueOnChange}
          OnDropFunction={OnDropFunction}
          onSelectfile={onSelectfile}
          updateValueOnChange={updateValueOnChange}
          setIsInvalidFiles={setIsInvalidFiles}
          updateQuote={updateQuote}
          selectedQuote={selectedQuote}
        />
        {createQuoteState?.values?.length ?
          <>
            <RightSection
              handleDraftQuoteFunctionality={handleDraftQuoteFunctionality}
              updateCommanValueOnChange={updateCommanValueOnChange}
              onSubmit={onSubmit}
              selectedQuote={selectedQuote}
            />
          </> : <></>}

      </div>

      <SignInModal
        show={isSignInModalOpen}
        onSignIn={handleAuthUser}
        onClose={() => setIsSignInModalOpen(false)}
        userEmail={email}

      />
      <ForwardToPurchaseModal
        isForwardToPurchaserModalOpen={isForwardToPurchaserModalOpen}
        setIsForwardToPurchaserModalOpen={setIsForwardToPurchaserModalOpen}
        quoteId={_id}
        selectedQuote={selectedQuote}
        setSelectedQuote={setSelectedQuote}
      />
      <ShareModalComponent
        isShareModalOpen={isShareModalOpen}
        setIsShareModalOpen={setIsShareModalOpen}
        selectedQuote={selectedQuote}
      />
      <TargetCostUserModal
        setIsTargetCostModalOpen={setIsTargetCostModalOpen}
        isTargetCostModalOpen={isTargetCostModalOpen}
        selectedQuote={selectedQuote}
        setSelectedQuote={setSelectedQuote}
      />
      <Chat
        RefId={selectedQuoteRef}
        open={isChatOpen}
        onDrawerClose={() => setIsChatOpen(false)}
      />
      <TeamCollaboration
        teamMemberModalShow={teamModalShow}
        setTeamMemberModalShow={setTeamModalShow}
        selectedQuoteForTeam={selectedQuote}
        updateQuoteData={setSelectedQuote}
        fetchDataForUser={() => null}
      />



    </div>
  )
}

export default CreateInstantQuotation;