import React, { useState } from "react";
import "./QRTrackingHoverInfo.scss";
import infoImage from "../../images/info.svg";

const QRTrackingHoverInfo = ({isRight}) => {

  return (
    <div className="relative inline-block group">
      <img className="h-4 cursor-pointer" src={infoImage} alt="Info" />
      <div className={`absolute z-50 md:right-2 top-5 hidden group-hover:block w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg ${isRight && "md:right-[-200px]"}`}>
        A unique QR code will be etched on all the parts to allow documentation
        tracking. All documents are retained for up to 5 years as per retention
        policies. QR Tracking is only available for the materials given:
        Aluminium, Brass, Copper, Mild Steel, Stainless Steel, Tool Steel, and
        Cast Iron.
      </div>
    </div>
  );
};

export default QRTrackingHoverInfo;
