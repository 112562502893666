import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { useState } from "react";
import {
  getLayerThicknessDetailsById,
  updateLayerThickness,
} from "../../../utils/actions/allactions";
import {
  validateAdmin,
  numberInputOnWheelPreventChange,
  getToken,
} from "../../../utils/helper";
import { toast } from "react-toastify";
import useDocumentTitle from "../../../utils/useDocumentTitle";
import SkeltonLoadingComponent from "../../../components/LoadingComponents/SkeltonLoadingComponent";

const EditLayerThicknessMaster = () => {
  const token = getToken();
  const navigate = useNavigate();
  const [initialValue, setInitialValue] = useState(null);
  const [error, setError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id") || null;

  useDocumentTitle("Edit Layer Thickness");

  useEffect(() => {
    // validateAdmin(token, navigate);
    const fetchData = async () => {
      try {
        const data = await getLayerThicknessDetailsById(id);
        console.log(data);
        if (data && data.success) {
          setInitialValue(data.data);
        } else {
          setError("Unable To Fetch Data");
        }
      } catch (error) {
        toast.error("Unable To Fetch Layer Thickness Details");
      }
    };
    fetchData();
  }, []);

  const validateLayerThicknessVal = () => {
    if (!initialValue.name || !initialValue.LTFactor) {
      return false;
    }
    return true;
  };
  const handeEditLayerThickness = async () => {
    setError("");
    const isLayerThicknessValid = validateLayerThicknessVal();

    if (!isLayerThicknessValid) {
      setError("Enter valid values for all the field");
      return;
    }
    try {
      const updatedLayerThickness = await updateLayerThickness(
        token,
        initialValue
      );

      if (updatedLayerThickness?.data?.data?.code == 11000) {
        toast.error("Layer Thickness already exists");
      } else {
        navigate("/admin/layer-thickness");
        toast.success("Layer Thickness updated successfully");
      }
    } catch (error) {
      toast.error("Unable To Update Layer Thickness Details");
    }
  };

  return (
    <div className="admin-container">
      <div>
        <Sidebar />
      </div>
      <div className="admin-panel-container">
        <h1 className="text-center text-2xl font-bold">
          {" "}
          Edit Layer Thickness{" "}
        </h1>

        {initialValue ? (
          <div className="edit-company-container">
            <div>
              <label htmlFor="name"> Name </label>: &nbsp;
              <input
                name="name"
                type="text"
                placeholder="Enter  name"
                value={initialValue.name}
                onChange={(e) => {
                  setInitialValue({ ...initialValue, name: e.target.value });
                }}
              />
            </div>

            <div>
              <label htmlFor="LTFactor"> LTFactor </label>: &nbsp;
              <input
                name="LTFactor"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                placeholder="Enter LTFactor"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                value={initialValue.LTFactor || ""}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    LTFactor: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <SkeltonLoadingComponent />
        )}
        <div className="center-heading">
          <button className="button-search" onClick={handeEditLayerThickness}>
            {" "}
            Update{" "}
          </button>
        </div>

        {error ? (
          <div
            style={{
              color: "red",
              fontSize: "15px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            {error}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EditLayerThicknessMaster;
