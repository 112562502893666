import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Context } from "./Context"

import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import {
  Box3,
  Vector3,
  Color,
  BufferGeometry,
  BufferAttribute,
  FrontSide,
  BackSide,
  Geometry,
  MeshBasicMaterial,
  MeshStandardMaterial,
} from "three";
import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils";

import { SceneUtils } from "three/examples/jsm/utils/SceneUtils";

const setProgress = (dispatch, file, fileIndex, progress) => {
  if(file.properties?.onProgress !== undefined) {
    file.properties.onProgress(progress);
  }
  dispatch({ type: "set-file-properties", payload: {
    fileIndex,
    properties: {
      ...file.properties,
      analysisProgress: progress,
    }
  }});
}

export default function CADFile ({openCascadeWorker, file, fileIndex, visible, renderStyle}) {
  const {dispatch} = useContext(Context);

  const [mmo, setMmo] = useState();

  const [lineGeometry, setLineGeometry] = useState();
  useEffect(() => {
    if(openCascadeWorker === undefined || file === undefined) {
      return;
    }
    if(file.properties.analysisProgress === 0) {
      (async () => {
        const release = await openCascadeWorker.mutex.acquire();
        setProgress(dispatch, file, fileIndex, 0.01);
        if(file.properties.type === "STEP" || file.properties.type === "IGES") {
          const textContent = (new TextDecoder()).decode(file.properties.content);
          await openCascadeWorker.worker.shapeFromFile(textContent, file.properties.type);
        }
        if(file.properties.type === "STL") {
          const loader = new STLLoader();
          const geometry = loader.parse(file.properties.content);
          await openCascadeWorker.worker.shapeFromBufferGeometry(geometry);
        }
        if(file.properties.type === "OBJ") {
          const textContent = (new TextDecoder()).decode(file.properties.content);
          const loader = new OBJLoader();
          const object = loader.parse(textContent);
          const bufferGeometries = [];
          object.traverse(o => {
            if(o.geometry) {
              bufferGeometries.push(o.geometry);
            }
          });
          const geometry = BufferGeometryUtils.mergeBufferGeometries(bufferGeometries, false);
          await openCascadeWorker.worker.shapeFromBufferGeometry(geometry);
        }
        setProgress(dispatch, file, fileIndex, 0.2);
        await openCascadeWorker.worker.tessellate();
        setProgress(dispatch, file, fileIndex, 0.4);
        await openCascadeWorker.worker.joinPrimitives();
        setProgress(dispatch, file, fileIndex, 0.6);
        const [vertices, faces] = await openCascadeWorker.worker.generateGeometry();
        setProgress(dispatch, file, fileIndex, 0.8);
        let edgeList = await openCascadeWorker.worker.getEdgesFromShape();

        let numWireAttrs = 0;
        for(let i = 0; i < edgeList.length; i++) {
          const edge = edgeList[i];
          for(let j = 0; j < edge.length-1; j++) {
            numWireAttrs += 3 * 3;
          }
        }
        const wireFramePositions = new Float32Array(numWireAttrs);
        let index = 0;
        for(let i = 0; i < edgeList.length; i++) {
          const edge = edgeList[i];
          for(let j = 0; j < edge.length-1; ++j) {
            wireFramePositions[index+0] = edge[j+0].x;
            wireFramePositions[index+1] = edge[j+0].y;
            wireFramePositions[index+2] = edge[j+0].z;
            wireFramePositions[index+3] = edge[j+1].x;
            wireFramePositions[index+4] = edge[j+1].y;
            wireFramePositions[index+5] = edge[j+1].z;
            wireFramePositions[index+6] = edge[j+1].x;
            wireFramePositions[index+7] = edge[j+1].y;
            wireFramePositions[index+8] = edge[j+1].z;
            index+=9;
          }
        }

        const wireFrameGeometry = new BufferGeometry();
        wireFrameGeometry.setAttribute("position", new BufferAttribute(wireFramePositions, 3));
        setLineGeometry(wireFrameGeometry);

        const aabbDimensions = await openCascadeWorker.worker.calculateAABB();
        setProgress(dispatch, file, fileIndex, 0.9);
        const aabb = new Box3(
          new Vector3(aabbDimensions.xmin, aabbDimensions.ymin, aabbDimensions.zmin),
          new Vector3(aabbDimensions.xmax, aabbDimensions.ymax, aabbDimensions.zmax)
        );

        const analysisResults = await openCascadeWorker.worker.analyze();

        dispatch({ type: "set-file-properties", payload: {
          fileIndex: fileIndex,
          properties: {
            ...file.properties,
            aabb,
            analysisProgress: 1,
            analysisResults,
          }
        }});

        const objectMat = new MeshStandardMaterial({
          color: new Color(0.9, 0.9, 0.9),
          side: FrontSide,
          polygonOffset: true,
          polygonOffsetFactor: 1,
          polygonOffsetUnits: 1,
        });
        var cutMat = new MeshBasicMaterial({
          color: new Color(0.025, 0.025, 0.025),
          side: BackSide,
          polygonOffset: true,
          polygonOffsetFactor: 1,
          polygonOffsetUnits: 1,
        });
        let g = new Geometry();
        g.vertices = vertices;
        g.faces = faces;
        const a = SceneUtils.createMultiMaterialObject(g, [objectMat, cutMat]);
        setMmo(a);

        release();
        if(file.properties?.resolve !== undefined) {
          file.properties.resolve({aabb, analysisResults});
        }
      })();
    }
  }, [
    dispatch,
    openCascadeWorker,
    file,
    fileIndex,
  ]);

  const objectRef = useRef();
  useEffect(() => {
    if(!mmo) return;
    mmo.children.forEach(o => {
      o.material.transparent = renderStyle?.meshStyle === "transparent" ? true : false;
      o.material.opacity = renderStyle?.meshStyle === "transparent" ? 0.5 : 1;
    });
  }, [mmo, renderStyle]);
  
  const edgeMat = useMemo(() => <lineBasicMaterial
    attach="material"
    color={new Color(0.025, 0.025, 0.025)}
    linewidth={1}
    transparent={renderStyle?.meshStyle === "transparent" ? true : false}
    opacity={renderStyle?.meshStyle === "transparent" ? 0.8 : 1}
  />, [renderStyle]);
  const hiddenEdgeMat = useMemo(() => <lineBasicMaterial
    attach="material"
    color={new Color(0.025, 0.025, 0.025)}
    linewidth={1}
    transparent={renderStyle?.meshStyle === "transparent" ? true : false}
    opacity={renderStyle?.meshStyle === "transparent" ? 0.05 : 1}
    depthTest={renderStyle?.meshStyle === "transparent" ? false : true}
  />, [renderStyle]);

  window.r = objectRef;

  useEffect(() => {
    if(!objectRef.current) return;
    while (objectRef.current.children.length) objectRef.current.remove(objectRef.current.children[0]);
    if(visible && mmo) {
      objectRef.current.add(mmo);
    }
  }, [mmo, visible]);

  window.r = objectRef.current;

  return (
    <group>
      <group ref={objectRef} />
        {
          visible ? (
            lineGeometry && mmo && renderStyle?.edgeStyle === "shown" ? (
              <group>
                {/* visible lines */}
                <lineSegments>
                  <wireframeGeometry
                    attach="geometry"
                    args={[lineGeometry]}
                  />
                  {edgeMat}
                </lineSegments>
                {/* hidden lines */}
                <lineSegments
                  renderOder={1}
                >
                  <wireframeGeometry
                    attach="geometry"
                    args={[lineGeometry]}
                  />
                  {hiddenEdgeMat}
                </lineSegments>
              </group>
            ) : null
          ) : null
        }
    </group>
  );
}
