import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { useState } from "react";
import { addTolerance } from '../../../utils/actions/allactions';
import { validateAdmin, getToken,numberInputOnWheelPreventChange } from "../../../utils/helper";
import { toast } from "react-toastify";
import useDocumentTitle from "../../../utils/useDocumentTitle";

function AddToleranceMaster() {
  const token = getToken();
  const navigate = useNavigate();
  useDocumentTitle('Add Tolerance')
  const [error, setError] = useState("");
  const [initialValue, setInitialValue] = useState({
    name: '',
    TolFactor: ''
  });


  const validateToleranceVal = () => {
    if (!initialValue.name ||
      !initialValue.TolFactor) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    validateAdmin(token, navigate);
  }, []);

  const handleAddTolerance = async () => {
    // console.log(initialValue)
    setError('');
    const isToleranceValid = validateToleranceVal();

    if (!isToleranceValid) {
      setError("Enter valid values for all the field")
    } else {
      const addedTolerance = await addTolerance(token, initialValue);

     
      if(addedTolerance?.data?.data?.code==11000){
        toast.error("Tolerance already exists")
      }else{
        navigate('/admin/tolerance');
        toast.success("Tolerance added successfully")
      }
    }
  }


  return (
    <div  className="admin-container">
      <div>
        <Sidebar />
      </div>
      <div className="admin-panel-container">
        <h1 className="center-heading">  Add Tolerance </h1>

        <div className="edit-company-container">
          <div>
            <label htmlFor="name" > Name </label>: &nbsp;
            <input
              name="name"
              type="text"
              placeholder="Enter  name"
              onChange={(e) => { setInitialValue({ ...initialValue, name: e.target.value }) }}
            />
          </div>

          <div>
            <label htmlFor="TolFactor" > TolFactor </label>: &nbsp;
            <input
              name="TolFactor"
              type="number"
              onWheel={numberInputOnWheelPreventChange}
              placeholder="Enter TolFactor"
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={(e) => { setInitialValue({ ...initialValue, TolFactor: e.target.value }) }}
            />
          </div>

          <div className="center-heading">
            <button className="button-search" onClick={handleAddTolerance}>  Create </button>
          </div>
          {
            error ?
              <div style={{ color: 'red', fontSize: '15px', textAlign: 'center', marginTop: '10px' }}>{error}</div>
              : null
          }
        </div>
      </div>
    </div>
  )
}
export default AddToleranceMaster;




