import process from "../../../images/process.svg";
import { processType } from "../../../utils/constant";
import { Row, Col, Modal, Form } from "react-bootstrap";
import SelectFileSection from "./SelectFileSection";
import shareico from "../../../images/share.svg";
import tickwhite from "../../../images/tick-white.svg";
import cnc from "../../../images/cnc.svg";
import closeImg from "../../../images/close.svg";
import printing from "../../../images/printing.svg";
import metal from "../../../images/metal.svg";
import injection from "../../../images/injection.svg";
import { Link, useParams, useSearchParams } from "react-router-dom";
import saveico from "../../../images/save-ico.svg";
import SheetInjectionInput from "./SheetInjectionInput";
import MachinePrintingInput from "./MachinePrintingInput";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  shareQuoteToEmails,
  OptimizedQuoteEmail,
  fetchQuoteByRefId,
  getOrderForQuotationId,
} from "../../../utils/actions/allactions";
import TailwindModalComponent from "../../../components/TailwindModalComponent/TailwindModelComponent";
import { ArrowRightLeft, ClockIcon, CodeIcon, GitVersionIcon, ProccedToCheckOutIconComponent, SaveChangeIconComponent, SendIconComponent, SettingIcon, ShareIcon } from "../../../components/Icons";
import { getAllTechnology } from "../../../utils/actions/adminActions";
import Switch from "react-input-switch";
import { Clock } from "three";
import moment from "moment";

const CollectData = (props) => {
  const { id, handleDraftQuoteFunctionality, selectedQuote } = props;
  let loggedInUserEmail = localStorage.getItem("email");
  let email = localStorage.getItem("email");
  const [shareQuoteModalShow, setShareQuoteModalShow] = useState(false);
  const [processChangeModal, setProcessChangeModal] = useState(false);
  const [emailToShareQuote, setEmailToShareQuote] = useState(
    new Set([loggedInUserEmail])
  );
  const [inputEmail, setInputEmail] = useState("");
  const [isManualPriceUpdated, setIsManualPriceUpdated] = useState(false);
  const [isOrderAlreadyCreated, setIsOrderAlreadyCreated] = useState(false);

  // const [isModalOpen,setIsModalOpen] = useState(true);
  let lineItemTemplate = {
    selectedFile: "",
    Notes: "",
    Qty: 1,
    description: "",
    MaterialType: "",
    SubMaterial: "",
    file: "",
    layer: "",
    partMaking: "",
    surfaceFinish: "",
    surfaceTreatment: "",
    tolerance: "",
    price: 0,
    price1: 0,
    price2: 0,
    partName: "",
    linePartName: "",
  };
  const { _id } = useParams();
  const [searchParams] = useSearchParams();
  const funcName = searchParams.get("functionalityName");
  const repeatOrder = searchParams.get("RepeatOrder") == "true" ? true : false;

  const handleQuotationShare = async () => {
    try {
      if (emailToShareQuote.size === 0) {
        toast.error(
          "Please enter atleast one mail to share the quotation details"
        );
        return;
      } else {
        let userId = localStorage.getItem("_id");
        if (window.location.host === "make.8xparts.com") {
          // window?.dataLayer?.push({
          //   event: "shareQuotation",
          //   userId: userId,
          //   quoteRefId: props?.quoteRef,
          //   referenceId:
          //     email?.split("@")[0] + email?.split("@")[1]?.split(".")[0],
          // });
        }

        let data = {
          quoteId: props.quoteId,
          selectedShipMethod: props.commanValues.selectedShipMethod,
          emailToShareQuote: [...emailToShareQuote],
        };
        let token = localStorage.getItem("Token");
        let response = await shareQuoteToEmails(token, data);
        if (response.statusCode == 200) {
          setShareQuoteModalShow(false);
          setEmailToShareQuote(new Set([loggedInUserEmail]));
          toast.success("Quotation details shared successfully");
        } else {
          toast.error("Something went wrong while sharing the quote details.");
        }
      }
    } catch (err) {
      toast.error("Something went wrong.Please try again later.");
    }
  };

  const handleShareQuoteClose = () => {
    setShareQuoteModalShow(false);
  };

  const handleHideProcess = () => {
    setProcessChangeModal(false);
  };

  const handleShareQuoteModalOpen = async () => {

    if (props.quoteId) {
      const userResponse = confirm(
        "To use the share quote functionality you need to save the quote. Please confirm if you want to save the quote with current details?"
      );
      if (userResponse) {
        await props.onSubmit(
          null,
          { saveQuote: true },
          { functionalityName: "Share" }
        );
        return;
      }
      setShareQuoteModalShow(true);
    }
  };

  const handleAddButtonToReceiverList = () => {
    let response = validateEmail(inputEmail);
    if (response) {
      if (emailToShareQuote.has(inputEmail)) {
        toast.error("Email already added to receivers list");
      } else {
        let localEmailList = new Set(emailToShareQuote);
        localEmailList.add(inputEmail);
        setEmailToShareQuote(localEmailList);
        setInputEmail("");
      }
    } else {
      toast.error("Please enter a valid email");
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleAddItem = () => {
    props.values.push(lineItemTemplate);
  };

  useEffect(() => {
    if (props.saveForFunctionality) {
      if (props.saveForFunctionality == "Share") {
        setShareQuoteModalShow(true);
      }
    }

    const fetchQuoteData = async () => {
      if (props.quoteRef) {
        let token = localStorage.getItem("Token");
        let fetchedQuoteVersion = await fetchQuoteByRefId(
          props.quoteRef,
          token
        );
        for (let i = 0; i < fetchedQuoteVersion.data.data.length; i++) {
          if (
            (fetchedQuoteVersion.data.data[i].isUpdatedByAdmin &&
              fetchedQuoteVersion.data.data[i].isSent) ||
            fetchedQuoteVersion.data.data[i].isRepeatOrderQuotation ||
            repeatOrder
          ) {
            setIsManualPriceUpdated(true);
          }
        }

        let orderResponse = await getOrderForQuotationId(token, props.quoteId);
        if (orderResponse.status) {
          setIsOrderAlreadyCreated(true);
        } else {
          setIsOrderAlreadyCreated(false);
        }
      }
    };
    fetchQuoteData();
  }, []);
  console.log("CommonValues :", props.commanValues);


  const handleChangeProcess = (processId) => {
    if (isManualPriceUpdated) {
      setProcessChangeModal(true);
    } else {
      props.setCommanValues({
        ...props.commanValues,
        leadTime1: props.selectedProcess == 2 ? 18 : 10,
      });
      props.setSelectedProcess(processId);
    }
  };

  const handleDeleteShareEmail = (item) => {
    let localEmailList = new Set(emailToShareQuote);
    localEmailList.delete(item);
    setEmailToShareQuote(localEmailList);
  };
  console.log("Props", props);

  return (
    <div className="content-wrapper">
      <div className="dashboard-holder">
        <div className="sm-container">

          <div className="common-bx">
            <p className=" py-2   text-center text-xl lg:text-2xl font-bold">
              {
                (() => {
                  if ((props?.isRepeatOrderWithUpdate || id) && !funcName) return <span>Edit Quotation</span>;
                  if (props.selectedProcess == 2 && props?.commanValues?.myorders) return <span>Repeat Orders</span>
                })()
              }
            </p>
            {!_id ? <div className="process-bar-bx green-line">
              <span className="process-ico">
                <img src={process} alt="process" />
              </span>
              <h5>Select a process </h5>
              <div className="process-thumbs">
                <Row role="group" aria-label="Basic radio toggle button group">
                  {props.selectedProcess < 3 ? (
                    processType &&
                    processType.length > 0 &&
                    processType.map((singleProcess, index) => {
                      if (index < 2) {
                        return (
                          <Col
                            key={`process-${index}`}
                            onClick={() =>
                              handleChangeProcess(singleProcess.id)
                            }
                          >
                            <div
                              className={
                                props.selectedProcess === singleProcess.id
                                  ? "box-pr pr-checked"
                                  : "box-pr"
                              }
                            >
                              <div className="pr-radio">
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio"
                                  id="btnradio1"
                                  autoComplete="off"
                                  value={singleProcess.id}
                                  onChange={(e) =>
                                    handleValueChange(singleProcess.id)
                                  }
                                />
                                <label
                                  className="btn box-in"
                                  htmlFor={`btnradio-${singleProcess.id}`}
                                >
                                  <span className="triangle-0">
                                    <img src={tickwhite} alt="tick-white" />
                                  </span>
                                  <span className="bx-icon">
                                    {singleProcess.id === 1 && (
                                      <img src={cnc} alt="cnc" />
                                    )}
                                    {singleProcess.id === 2 && (
                                      <img src={printing} alt="printing" />
                                    )}
                                    {singleProcess.id === 3 && (
                                      <img src={metal} alt="metal" />
                                    )}
                                    {singleProcess.id >= 4 && (
                                      <img src={injection} alt="injection" />
                                    )}
                                  </span>
                                  {singleProcess.name}
                                </label>
                              </div>
                            </div>
                          </Col>
                        );
                      }
                    })
                  ) : (
                    <>
                      <Col style={{ maxWidth: "50%", minWidth: "200px" }}>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => handleChangeProcess(e.target.value)}
                          className="process-select"
                          value={props.selectedProcess || "default"}
                        >
                          <option value="default" disabled>
                            Select a process
                          </option>
                          {processType &&
                            processType.length > 0 &&
                            processType.map((singleProcess, index) => {
                              if (index >= 2) {
                                return (
                                  <option
                                    key={`process-${index}`}
                                    value={singleProcess.id}
                                  >
                                    {singleProcess.name}
                                  </option>
                                );
                              }
                              return null;
                            })}
                        </Form.Select>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            </div>
              :
              <div className=" my-2 py-2 px-2 flex justify-between flex-wrap  ">
                <div className="flex items-center gap-x-2  ">
                  <div className="flex items-center justify-center h-10 aspect-square !bg-slate-100 rounded-full ">
                    <CodeIcon />
                  </div>
                  <div className="font-bold">
                    <span className="">Project Name : </span>
                    <span>{props?.commanValues?.projectName || "NA"}</span>
                  </div>
                </div>
                <div className="flex items-center gap-x-2  ">
                  <div className="flex items-center justify-center h-10 aspect-square !bg-slate-100 rounded-full ">
                    <GitVersionIcon />
                  </div>
                  <div className="font-bold ">
                    <span className="">Version : </span>
                    <span>{props?.commanValues?.version}</span>
                  </div>
                </div>
                <div className="flex items-center gap-x-2  ">
                  <div className="flex items-center justify-center h-10 aspect-square !bg-slate-100 rounded-full ">
                    <ClockIcon />
                  </div>
                  <div className="font-bold">
                    <span className="">Created : </span>
                    <span>{props?.commanValues?.createdAt ? moment(props.commanValues.createdAt).format('YYYY-MM-DD HH:mm:ss') : "NA"}</span>

                  </div>
                </div>
                <div className="flex items-center gap-x-2  ">
                  <div className="flex items-center justify-center h-10 aspect-square !bg-slate-100 rounded-full ">
                    <SettingIcon />
                  </div>
                  <div className="font-bold">
                    <span className="">Selected Process : </span>
                    <span> {props?.selectedProcess ? processType[props?.selectedProcess - 1].name : null} </span>
                  </div>
                </div>
              </div>
            }
            <div className={`upload-req`}>
              <SelectFileSection
                handleDraftQuoteFunctionality={handleDraftQuoteFunctionality}
                stManualPrice={props.stManualPrice}
                handleAddItem={handleAddItem}
                file={props.file}
                setFiles={props.setFiles}
                sheetAndInjectionManualPrice={
                  props.sheetAndInjectionManualPrice
                }
                selectedProcess={props.selectedProcess}
                onSelectfile={props.onSelectfile}
                setIsProcessNotSelected={props.setIsProcessNotSelected}
                values={props.values}
                quoteId={props.quoteId}
                onSubmit={props.onSubmit}
                isInvalidFiles={props.isInvalidFiles}
                sample={props.sample}
                askFO={props.askFO}
                setSample={props.setSample}
                updateValueOnChange={props.updateValueOnChange}
                commanValues={props.commanValues}
                setCommanValues={props.setCommanValues}
                updateCommanValueOnChange={props.updateCommanValueOnChange}
                saveForFunctionality={props.saveForFunctionality}
                isQuoteChanged={props.isQuoteChanged}
                setIsQuoteChanged={props.setIsQuoteChanged}
                threeDError={props.threeDError}
                cncError={props.cncError}
                quoteRef={props.quoteRef}
                savedQuoteRefId={props.savedQuoteRefId}
                isAdminUser={props.isAdminUser}
                isValidValuesForQR={props.isValidValuesForQR}
                checkIfAllMaterialsValidForQR={
                  props.checkIfAllMaterialsValidForQR
                }
                selectedQuote={selectedQuote}
                id={id}
              />
              <div className=" top-1 right-2  flex items-center space-x-3 text-sm my-3">
                <span className={props.isInchSelected ? 'text-gray-500' : 'font-bold text-black'}>
                  (mm)
                </span>
                <Switch

                  styles={{
                    track: {
                      backgroundColor: props.isInchSelected ? "#2B96DC" : "white"
                    },
                    trackChecked: {
                      backgroundColor: '#2B96DC'
                    },
                    button: {
                      backgroundColor: !props.isInchSelected ? "#2B96DC" : "white"
                    },
                    // buttonChecked: {
                    //   backgroundColor: 'blue'
                    // }
                  }}
                  className={`custom-switch w-6 h-4 ${!props.isInchSelected && "border !border-primaryColor"} rounded-lg`} // Adjust width and height for a smaller toggle
                  on="true"
                  value={props.isInchSelected?.toString()}
                  onChange={() => props.setIsInchSelected(!props.isInchSelected)}
                  off="false"
                />
                <ArrowRightLeft additionalClasses="w-4 h-4 text-muted-foreground" />
                <span className={props.isInchSelected ? 'font-bold text-black' : 'text-gray-500'}>
                  (inch)
                </span>
              </div>

              {(props.selectedProcess === 1 || props.selectedProcess === 2) && (
                <>
                  <div className="saved-quote ">
                    <div className="row">
                      <div className="col-lg-7"></div>
                      <div className="col-lg-5"></div>

                    </div>
                  </div>

                  <div className="product-thumb border  -translate-y-8">


                    {props.values &&
                      props.values.length > 0 &&
                      props.values.map((singleValue, index) => {
                        return (
                          <MachinePrintingInput
                            masterThreeDTechnologyData={props.masterThreeDTechnologyData}
                            stManualPrice={props.stManualPrice}
                            myRef={props.myRef}
                            key={`machingPrinting-${index}`}
                            singleValue={singleValue}
                            index={index}
                            onDelete={props.onDelete}
                            file={props.file}
                            setFiles={props.setFiles}
                            isEditQuote={props.isEditQuote}
                            boxDimension={props?.boxDimension}
                            processing={props.processing}
                            globeLoader={props.globeLoader}
                            keyValue={index + 1}
                            sample={props.sample}
                            values={props.values}
                            setValues={props.setValues}
                            isInvalidFiles={props.isInvalidFiles}
                            selectedProcess={props.selectedProcess}
                            updateValueOnChange={props.updateValueOnChange}
                            commanValues={props.commanValues}
                            updateCommanValueOnChange={
                              props.updateCommanValueOnChange
                            }
                            updateBulkValueOnChange={
                              props.updateBulkValueOnChange
                            }
                            isQuoteChanged={props.isQuoteChanged}
                            setIsQuoteChanged={props.setIsQuoteChanged}
                            sameForAllField={props.sameForAllField}
                            setSameForAllField={props.setSameForAllField}
                            twoDFiles={props.twoDFiles}
                            setTwoDFiles={props.setTwoDFiles}
                            setIsInchSelected={props.setIsInchSelected}
                            isInchSelected={props.isInchSelected}
                          />
                        );
                      })}
                  </div>
                </>
              )}

              {props.selectedProcess >= 3 && (
                <>
                  <div>
                    <div className="pro-dm">
                      <h5 style={{ marginTop: "15px" }}>
                        Enter Description with Parts specification
                      </h5>
                      <div className="parts-table">
                        <table>
                          <tbody>
                            <tr>
                              <th style={{ cursor: "pointer" }}>File Name</th>
                              <th style={{ cursor: "pointer" }}>Description</th>
                              <th style={{ cursor: "pointer" }}>Notes</th>
                              <th style={{ cursor: "pointer" }}>Quantity</th>
                              <th style={{ cursor: "pointer" }}>Action</th>
                            </tr>
                            {props.values &&
                              props.values.length > 0 &&
                              props.values.map((singleValue, index) => {
                                return (
                                  <SheetInjectionInput
                                    singleValue={singleValue}
                                    onSubmit={props.onSubmit}
                                    keyValue={index + 1}
                                    key={`sheetInjectionInput-${index}`}
                                    file={props.file}
                                    sheetAndInjectionManualPrice={
                                      props.sheetAndInjectionManualPrice
                                    }
                                    onDelete={props.onDelete}
                                    stManualPrice={props.stManualPrice}
                                    index={index}
                                    values={props.values}
                                    selectedProcess={props.selectedProcess}
                                    updateValueOnChange={
                                      props.updateValueOnChange
                                    }
                                    commanValues={props.commanValues}
                                    updateCommanValueOnChange={
                                      props.updateCommanValueOnChange
                                    }
                                    updateBulkValueOnChange={
                                      props.updateBulkValueOnChange
                                    }
                                  />
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="btm-buttons">
                    <Row>
                      <Col md={2}>
                        <div className="price-box">
                          <div className="button-bx">
                            <Link to="" onClick={handleAddItem}>
                              <span className="process-btn">Add Item</span>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span style={{ fontSize: "12px", color: "red" }}>
                          *If you want you add an item without file, you can
                          click on the "Add Item" button above
                        </span>
                      </Col>
                    </Row>
                  </div>
                </>
              )}

              
            </div>
          </div>
          {/* <TailwindModalComponent 
          isModalOpen={isModalOpen} 
          setIsModalOpen={setIsModalOpen}
          ModalText1={'Yes'}
          ModalText2={'No'}
          /> */}

          <div className="privacy-text">
            <strong>Privacy:</strong> 8xParts 3D secures all uploaded files,
            protecting your intellectual property. If you can’t upload your
            files at the moment, use our sample part to try out our instant
            quoting and DfM tools or contact us on{" "}
            <a href="mailto: info@8xparts.com">info@8xparts.com</a> to sign an
            NDA.
          </div>
        </div>
      </div>
      <Modal
        size="sm"
        dialogClassName="qoute-version-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={shareQuoteModalShow}
        onHide={handleShareQuoteClose}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "15px" }}
          >
            Share Quotation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="share-quote-div">
            <h6>
              Emails entered below will receive an email with Quotation details
              in their mailbox.
            </h6>
            <div className="add-email-div">
              <input
                placeholder="Enter the email to share the quotation details"
                value={inputEmail}
                onChange={(e) => setInputEmail(e.target.value)}
                className="border p-1 me-2 my-2 outline-none "
              />
              <button onClick={handleAddButtonToReceiverList}>
                + Add Email
              </button>
            </div>
            <div className="share-content-div">
              <h6>Receiver's List</h6>
              <div className="email-list-share-div">
                {emailToShareQuote.size > 0
                  ? Array.from(emailToShareQuote).map((item, index) => {
                    return (
                      <span className="email-item" key={index}>
                        {item}{" "}
                        <img
                          src={closeImg}
                          className="close-img"
                          onClick={(e) => handleDeleteShareEmail(item)}
                        />
                      </span>
                    );
                  })
                  : null}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="share-quote-div-footer">
            <div className="share-button" onClick={handleQuotationShare}>
              Share
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        size="sm"
        dialogClassName="qoute-version-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={processChangeModal}
        onHide={handleHideProcess}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "15px" }}
          >
            Cannot change the process
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="share-quote-div">
            <p>
              The Price for this RFQ is confirmed by our applications
              Engineering team. To check prices for alternative processes,
              please initiate a new request from the dashboard.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="share-quote-div-footer">
            <div className="share-button" onClick={handleHideProcess}>
              Close
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default CollectData;
