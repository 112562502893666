import React, { useEffect, useState, useContext, useLayoutEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Sidebar";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { processType, raiseIssueSteps } from "../../utils/constant";
import moment from "moment";
import fileSaver from "file-saver";
import downloadImage from "../../images/download.svg";
import deleteImageEnabled from "../../images/reddelete.svg";
import closeImg from "../../images/close.svg";
import uploadImage from "../../images/upload.svg";
import { Row, Col, Accordion, FormControl } from "react-bootstrap";
import Chat from "../../Chat";
import { ChatRefContext } from "../../context/chatRef.context";
import Dropzone from "react-dropzone";
import { getEightXAddress, getMaterial } from "../../utils/actions/allactions";
import {
  getPurchaseOrderById,
  downloadPurchaseOrderPdf,
  //new api for bill
  getBillById,
  updateBillAdminComment,
  updateBillQualityDoc,
  updateBillConsignmentPhotos,
  updateShippedDateForBill,
  updateDeliveryLinkForBill,
  approveBill,
  rejectBill,
  updateRemittanceDateForBill,
  updateBillShippingCarrier,
  DeleteBillsDocumentById,
  updatePurchaseOrderNumber,
  uploadBillDocument
} from "../../utils/actions/supplierActions";
import {
  capitalizedStatus,
  currencyFormatter,
  extractURLAndEncodedFilename,
} from "../../utils/helper";
import useDocumentTitle from "../../utils/useDocumentTitle";
import CaptchaModal from "../../components/CaptchaModal/CaptchaModal";
import ConsignmentPhotoViewer from "../../components/ConsignmentPhotoViewer/ConsignmentPhotoViewer";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";
import { DeleteIcon, DownloadIcon, EditIcon } from "../../components/Icons";
import { Loader } from "@chatscope/chat-ui-kit-react";
import { Loading } from "../../components/Loading";
// import { DeleteBillsDocumentById, updatePurchaseOrderNumber } from "../../api/supplier/bills/adminBillsApi";
import { CorrectIcon, CrossIcon } from "../../components/GlobalProjectNameComponent";

const BillDetails = () => {
  let navigate = useNavigate();
  useDocumentTitle("Bill Details");
  let { id } = useParams();
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [qualityDocs, setQualityDocs] = useState([]);
  let token = localStorage.getItem("Token");
  let decode;
  const [isChanged, setIsChanged] = useState({
    shippingAddress: false,
    orderInfo: false,
    qualityDoc: false,
    consignmentPhoto: false,
    deliveryDetails: false,
    isIssueClosed: false,
    qRDetailsChanged: false,
    adminComment: false,
    chargesChanged: false,
    poDocInfo: false,
    checkBoxInfo: false,
  });
  const [open, setOpen] = useState(null);
  const { setSelectedChatRefId, setChatType } = useContext(ChatRefContext);
  const [addresses, setAddresses] = useState([]);
  const [material, setMaterial] = useState([]);
  const [subMaterial, setSubMaterial] = useState([]);
  const [partMarking, setPartMarking] = useState([]);
  const [surfaceFinish, setSurfaceFinish] = useState([]);
  const [surfaceTreatment, setSurfaceTreatment] = useState([]);
  const [tolerance, setTolerance] = useState([]);
  const [layer, setLayer] = useState([]);
  const [isPoNumberEditing, setIsPoNumberEditing] = useState(false);
  const poInputRef = useRef();


  let qualityDocObj = {
    name: "",
    file: [],
    // isAdminApproved:true,
  };
  const [consignmentPhoto, setConsignmentPhoto] = useState([]);
  const [deliveryTrackingLink, setDeliveryTackingLink] = useState();
  const [isShippingChanged, setIsShippingChanged] = useState(false);
  const [billData, setBillData] = useState({});
  const [isRemittanceChanged, setIsRemittanceChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [poId, setPoId] = useState(null);
  const showDrawer = (id) => {
    setOpen(id);
    setSelectedChatRefId(id);
    setChatType("supplier");
  };

  const onClose = () => {
    setOpen(null);
    setSelectedChatRefId(null);
    setChatType(null);
  };

  useEffect(() => {
    try {
      if (!token) {
        navigate("/login");
      } else {
        let decode = jwt_decode(token);
        if (!decode) {
          navigate("/login");
          toast.error("Invalid Token found!");
        } else if (decode) {
          if (decode.userType == "admin") {
            handleDataFetch();
          } else if (decode.userType == "user") {
            navigate("/saved-quote");
            // toast.error('Unauthorized Access!');
          } else {
            navigate("/login");
            // toast.error('Unauthorized Access!');
          }
        }
      }
    } catch (err) {
      console.log(
        "Error in 168 purcase order details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err.message
      );
      0;
      toast.error("Something  went wrong while fetching data.");
    }
  }, []);

  const handleDataFetch = async () => {
    fetchOrderAndBillDetails();
    fetchMaterialData();
    fetch8xAddress();
  };

  const fetchMaterialData = async () => {
    try {
      setLoading(true);
      const response = await getMaterial(token);
      if (response.status) {
        setMaterial(response.data.material);
        setSubMaterial(response.data.submaterial);
        setPartMarking(response.data.partMarking);
        setSurfaceFinish(response.data.surafcefinish);
        setSurfaceTreatment(response.data.surfacetreat);
        setTolerance(response.data.tolerance);
        setLayer(response.data.layer);
      } else {
        toast.error("Something went wrong while fetching the materials");
      }
    } catch (error) {
      toast.error("Something went wrong while fetching the materials");
    } finally {
      setLoading(false);
    }
  };



  const fetch8xAddress = async () => {
    try {
      const addressData = await getEightXAddress(token);
      if (addressData.status) {
        setAddresses(addressData.data);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  const fetchOrderAndBillDetails = async () => {
    try {
      const billResponse = await getBillById(token, id);
      if (!billResponse.status) {
        toast.error("Bill not found");
      }
      setPoId(billResponse.data.purchaseOrderId?._id);
      setBillData(billResponse.data);
      const purchaseOrderData = await getPurchaseOrderById(
        billResponse.data.purchaseOrderId?._id,
        token
      );
      setPurchaseOrder(purchaseOrderData.data);
      setDeliveryTackingLink(billResponse.data.deliveryTrackingLink);
      setQualityDocs(billResponse.data.qualityDocuments);
      setConsignmentPhoto(billResponse.data.consignmentPhotos);
    } catch (err) {
      console.log(
        "Error in 248 purcase order details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
      toast.error("Something went wrong.");
    }
  };

  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  const handleBillAdminCommentUpdate = async () => {
    if (isChanged.adminComment) {
      let data = {
        billId: billData._id,
        adminComment: billData.adminComment,
      };
      const response = await updateBillAdminComment(token, data);
      if (response.status) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  };

  const handleBack = () => {
    const userResponse = confirm(
      "Unsaved information will be lost, are you sure you want to go back ?"
    );
    if (userResponse) {
      navigate(-1);
    }
    return;
  };

  const handleAddQualityDoc = () => {
    setIsChanged({ ...isChanged, qualityDoc: true });
    let localQualityDocs = [...qualityDocs];

    localQualityDocs.push(qualityDocObj);

    setQualityDocs(localQualityDocs);
  };

  const handleQualityDocDelete = (index) => {
    setIsChanged({ ...isChanged, qualityDoc: true });
    let localQualityDocs = [...qualityDocs];
    localQualityDocs[index].file = [];
    setQualityDocs(localQualityDocs);
  };

  const handleQualityDocRemove = (index) => {
    setIsChanged({ ...isChanged, qualityDoc: true });
    let localQualityDocs = [...qualityDocs];
    localQualityDocs.splice(index, 1);
    setQualityDocs(localQualityDocs);
  };

  const handleQualityDocNameChange = (event, index) => {
    setIsChanged({ ...isChanged, qualityDoc: true });
    let localQualityDocs = [...qualityDocs];
    localQualityDocs[index].name = event.target.value;
    setQualityDocs(localQualityDocs);
  };

  const handleQualityDocUpload = (event, index) => {
    let files = Array.from(event.target.files);
    let isAllFilesValid = true;
    files.forEach(file => {
      if (file.size > 10485760) {
        isAllFilesValid = false;
      }
    })

    if (!isAllFilesValid) {
      toast.error("File size should be less than 10 MB");
      return;
    }

    setIsChanged({ ...isChanged, qualityDoc: true });
    let localQualityDocs = [...qualityDocs];
    localQualityDocs[index].file = event.target.files[0];
    setQualityDocs(localQualityDocs);
  };

  const handleConsignmentFileDelete = (index) => {
    let localConsignmentPhoto = consignmentPhoto;
    localConsignmentPhoto.splice(index, 1);
    setConsignmentPhoto([...localConsignmentPhoto]);
    setIsChanged({ ...isChanged, consignmentPhoto: true });
  };

  const handleUpdateConsignmentPhoto = async () => {
    let formData = new FormData();
    formData.append("billId", billData._id);
    formData.append("consignmentPhotoData", JSON.stringify(consignmentPhoto));
    for (let i = 0; i < consignmentPhoto.length; i++) {
      formData.append("consignmentPhoto", consignmentPhoto[i]);
    }

    await updateBillConsignmentPhotos(formData, token);
  };

  const handleFileSelect = (files, rejectedFiles) => {
    let isAllFilesValid = true;
    files.forEach(file => {
      if (file.size > 10485760) {
        isAllFilesValid = false;
      }
    });

    if (!isAllFilesValid) {
      toast.error("You can upload files with a maximum size of 10MB.");
      return;
    }

    let localConsignmentPhoto = [...consignmentPhoto];
    files.map((item) => {
      localConsignmentPhoto.push(item);
    });
    setConsignmentPhoto([...localConsignmentPhoto]);
    setIsChanged({ ...isChanged, consignmentPhoto: true });
  };

  const handleRemoveDeliveryLink = () => {
    setIsChanged({ ...isChanged, deliveryDetails: true });
    setBillData({ ...billData, deliveryTrackingLink: "" });
    setDeliveryTackingLink("");
  };

  function isUrlValid(str) {
    str = str ? str : "";
    var a = document.createElement("a");
    a.href = str;
    return a.host && a.host != window.location.host;
  }

  const handleDeliveryTrackingNumber = async () => {
    try {
      const externalLink = `https://www.dhl.com/ie-en/home/tracking/tracking-express.html?submit=1&tracking-id=${billData.deliveryTrackingLink}`;
      window.open(externalLink, "_blank");
    } catch (err) {
      console.log(
        "Error in 500 purcase order details on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
      toast.error("Please enter valid tracking details");
    }
  };

  const handleShippedDate = async (e) => {
    setBillData({ ...billData, shippedDate: e.target.value });
    setIsChanged({ ...isChanged, deliveryDetails: true });
    setIsShippingChanged(true);
  };

  const handleRemittanceDate = async (e) => {
    setBillData({ ...billData, remittanceDate: e.target.value });
    setIsChanged({ ...isChanged, deliveryDetails: true });
    setIsRemittanceChanged(true);
  };

  const handleUpdateShippedDate = async () => {
    let data = {
      shippedDate: billData.shippedDate,
      id: billData._id,
    };

    let response = await updateShippedDateForBill(token, data);
    if (!response.data.status) {
      toast.error("Something went wrong while updating shipping date.");
    }
  };

  const handleUpdateRemittanceDate = async () => {
    let data = {
      remittanceDate: billData.remittanceDate,
      id: billData._id,
    };

    let response = await updateRemittanceDateForBill(token, data);
    if (!response.data.status) {
      toast.error("Something went wrong while updating remittance date.");
      setBillData({ ...billData, status: "paid" });
    }
  };

  const handleDeliveryTrackingLinkUpdate = async () => {
    let data = {
      deliveryTrackingLink: deliveryTrackingLink,
      billId: billData._id,
    };
    await updateDeliveryLinkForBill(token, data);
  };

  const handleQualityDocUpdate = async () => {
    let formData = new FormData();
    for (let i = 0; i < qualityDocs.length; i++) {
      if (
        !(!qualityDocs[i].file?.filename || !qualityDocs[i].file?.name) ||
        !qualityDocs[i].name
      ) {
        return false;
      }
      formData.append("fileToUpload", qualityDocs[i].file);
    }
    formData.append("qualityDoc", JSON.stringify(qualityDocs));
    formData.append("billId", billData._id);
    const updateOrder = await updateBillQualityDoc(formData, token);
    return updateOrder;
  };

  const handleUpdateBill = async () => {
    try {
      if (isChanged.qualityDoc) {
        let response = await handleQualityDocUpdate();
        if (response === false || response?.response?.status === 500) {
          toast.error("Please enter valid quality document information");
          return;
        }
      }

      if (isChanged.consignmentPhoto) {
        await handleUpdateConsignmentPhoto();
      }

      if (isChanged.deliveryDetails) {
        if (isShippingChanged) {
          handleUpdateShippedDate();
        }
        handleDeliveryTrackingLinkUpdate();

        if (isRemittanceChanged) {
          handleUpdateRemittanceDate();
        }
      }

      if (isChanged.shippingCarrier) {
        let data = {
          billId: billData._id,
          shippingCarrier: billData.shippingCarrier,
        };
        await updateBillShippingCarrier(token, data);
      }

      toast.success("Bill updated successfully");
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleApproveBill = async () => {
    try {
      let isAllDocumentsValid = true;
      if (
        !billData.consignmentPhotos.length ||
        !billData.qualityDocuments.length ||
        !billData.shippedDate ||
        !billData.deliveryTrackingLink
      ) {
        isAllDocumentsValid = false;
      }

      if (!isAllDocumentsValid) {
        let adminResponse = confirm(
          "Once of the following information is missing: Consignment Photos , Quality Documents, Shipped Date, delivery Tracking Link. Are you sure you want to approve the bill?"
        );
        if (!adminResponse) {
          return;
        }
      }

      const approveResponse = await approveBill(billData._id, token);
      if (approveResponse.status) {
        toast.success(approveResponse.message);
        setBillData({ ...billData, status: "approved", isApproved: true });
      } else {
        toast.error(approveResponse.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleRejectBill = async () => {
    try {
      const rejectResponse = await rejectBill(billData._id, token);
      if (rejectResponse.status) {
        setBillData({
          ...billData,
          status: rejectResponse.data.status,
          isRejected: rejectResponse.data.isRejected,
        });
        toast.success(rejectResponse.message);
      } else {
        toast.error(rejectResponse.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  const handleBillDocumentDelete = async () => {
    setLoading(true);
    try {
      const data = await DeleteBillsDocumentById(token, billData._id);
      setLoading(false);
      if (data.success) {
        toast.success("Document Deleted Succesfully");
        // window.location.reload();
        // fetchOrderAndBillDetails()
        await fetchOrderAndBillDetails();
      }
      else {
        toast.error("Unable To Delete Document This Time");
      }


    } catch (error) {
      setLoading(false);
      toast.error("Unable To Delete Document This Time");
    }
  };

  const handlePoDocumentNameEdit = () => {
    setIsPoNumberEditing(true);
    setTimeout(() => poInputRef.current?.focus(), 200)
  };
  const handlePoDocumentNameEditCancel = () => {
    window.location.reload();
  };
  const handlePoDocumentNameSubmit = async () => {
    setLoading(true);
    try {
      if (!poId) {
        toast.error("Unable To Find Purchase Order Id");
        return;
      };
      const response = await updatePurchaseOrderNumber(token, {
        id: poId,
        data: purchaseOrder.purchaseOrderNumber,
      });
      setLoading(false);
      if (response && response.status) {
        toast.success("Po Number Edited Successfully");
      }
      else {
        toast.error("Unable To Edit Name At The Moment");
      }


    } catch (error) {
      setLoading(false);
      toast.error("Unable To Edit Name At The Moment");
    } finally {
      setIsPoNumberEditing(false);
    }

  }
  const handleNewBillDocumentChange = async (e) => {
    setLoading(true);
    try {
      const file = e.target.files[0];
      if (!file) {
        toast.error("Unable To Upload Document");

      }
      const response = await uploadBillDocument(token, {
        id: billData._id,
        file,
      });
      if (response.success) {
        toast.success("Document Uploaded Successfully");
        await fetchOrderAndBillDetails();

      }
      else {
        toast.error("Unable To Update Document At The Moment");
      }

    } catch (error) {
      toast.error("Unable To Update Document At The Moment");
    } finally {
      setLoading(false);
    }


  }
  return (
    <div className="admin-container ">
      <span style={{ width: "100%" }}>
        <div className="center-heading">
          <h1 className="text-2xl font-bold mt-2">Bill Details</h1>
          <div className="right-container ">
            <Chat
              RefId={purchaseOrder.pORefId}
              open={open}
              onDrawerClose={onClose}
            />
            {purchaseOrder.pORefId && (
              <div className="order-container">
                <div
                  className="header"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <Row>
                    <Col>
                      <div className="left-header">
                        <p>Shipping Address:</p>
                        {purchaseOrder.shippingAddress ? (
                          <>
                            <div className="addressContainer">
                              <div className="name">
                                {purchaseOrder.shippingAddress.firstName}{" "}
                                {purchaseOrder.shippingAddress?.lastName}
                              </div>

                              <div className="address">
                                {purchaseOrder.shippingAddress?.addressLineOne}
                                {purchaseOrder.shippingAddress
                                  ?.addressLinetwo ? (
                                  <div className="landMark">
                                    {
                                      purchaseOrder.shippingAddress
                                        ?.addressLinetwo
                                    }
                                  </div>
                                ) : null}

                                <div className="state">
                                  {purchaseOrder.shippingAddress?.state}{" "}
                                </div>
                                <div className="postal-code">
                                  {purchaseOrder.shippingAddress?.postalCode}
                                </div>
                                <div className="country">
                                  {purchaseOrder.shippingAddress?.country}
                                </div>
                                <div className="mobile">
                                  {purchaseOrder.shippingAddress?.phoneNo}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>

                      <div className="left-header">
                        <p>Supplier Name:</p>
                        <h6>{purchaseOrder.supplierId?.companyName}</h6>

                        <p>Bill Ref :</p>
                        <h6>{billData?.billNumber}</h6>

                        <p>Bill Document:</p>
                        <h6>
                          {billData?.billDocument ? <div className="flex items-center">
                            <a
                              className="downloadables text-primaryColor flex gap-x-2  items-center"
                              href={
                                (billData?.billDocument?.originalname,
                                  `${process.env.REACT_APP_SUPPLIER_BACKEND}/${billData?.billDocument?.path}`)
                              }
                              download={billData?.billDocument?.filename}
                              target="_blank"
                            >
                              {billData?.billDocument?.originalname}
                              <DownloadIcon />
                            </a>
                            <div onClick={handleBillDocumentDelete}>
                              <DeleteIcon
                                additionalClasses={"text-red-500 mx-2"}
                              />
                            </div>
                          </div> : <div>
                            <input onChange={handleNewBillDocumentChange} id="upload-doc" type="file" hidden />
                            <label className="h-7 flex items-center justify-center w-48 rounded-sm text-[13px] font-medium px-5 bg-white text-primaryColor" htmlFor="upload-doc">Upload Doc</label>
                          </div>}
                        </h6>

                        <p>Due Date :</p>
                        <h6>
                          {billData?.dueDate
                            ? moment(billData?.dueDate).format("DD MMM YYYY")
                            : "NA"}
                        </h6>
                      </div>
                    </Col>
                    <Col>
                      <div className="right-header">
                        <p>Order Number:</p>
                        <h6 style={{ display: "inline", marginRight: "10px" }}>
                          #{purchaseOrder.pORefId}
                        </h6>
                      </div>
                      <div className="right-header">
                        <p>Project Name :</p>
                        <h6 style={{ display: "inline", marginRight: "10px" }}>
                          {purchaseOrder?.supplierRFQId?.rfqId?.projectName ||
                            "Not Found"}
                        </h6>
                      </div>

                      <div className="right-header po-file-container">
                        <p>PO Number :</p>
                        <h6 className="flex  gap-x-2 items-center">
                          <FormControl
                            ref={poInputRef}
                            type="text"
                            style={{ width: "300px" }}
                            value={
                              purchaseOrder.purchaseOrderNumber
                                ? purchaseOrder.purchaseOrderNumber
                                : ""
                            }
                            onChange={(e) => setPurchaseOrder((pre) => ({ ...pre, ['purchaseOrderNumber']: e.target.value }))}
                            disabled={!isPoNumberEditing}
                          />
                          {
                            isPoNumberEditing ?
                              <div className="flex gap-x-2">

                                <CorrectIcon
                                  onclickFunction={handlePoDocumentNameSubmit}
                                  additionalClasses={'h-4'}
                                />

                                <div onClick={handlePoDocumentNameEditCancel}>
                                  <CrossIcon
                                    additionalClasses={'text-red-500 h-4'}
                                  />
                                </div>
                              </div> :
                              <div onClick={handlePoDocumentNameEdit}>
                                <EditIcon
                                  additionalClass={'h-3 text-primaryColor'}
                                />
                              </div>
                          }

                        </h6>

                        <p>PO Created At :</p>
                        <h6>
                          {purchaseOrder && purchaseOrder.status == "Draft" ? (
                            <>
                              {moment(purchaseOrder.createdAt).format(
                                "DD MMM YYYY"
                              )}
                            </>
                          ) : (
                            <>
                              {moment(
                                purchaseOrder.poApprovedDate ||
                                purchaseOrder.createdAt
                              ).format("DD MMM YYYY")}
                            </>
                          )}
                        </h6>

                        <p>Bill Created At :</p>
                        <h6>
                          {moment(billData.createdAt).format("DD MMM YYYY")}
                        </h6>

                        <p>Supplier Bill Number :</p>
                        <h6>{billData?.supplierBillNumber}</h6>

                        <p>Bill Status :</p>
                        <h6>{capitalizedStatus(billData.status)}</h6>

                        {billData.status == "paid" ? (
                          <>
                            <p>Payment Date :</p>
                            <h6>
                              {moment(billData?.remittanceDate).format(
                                "DD MMM YYYY"
                              )}
                            </h6>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="left-header">
                      <p className="font-medium ">Admin Comments :</p>
                      <textarea
                        className="p-2"
                        rows="3"
                        style={{ width: "100%" }}
                        placeholder="Enter comment here"
                        value={billData?.adminComment}
                        onChange={(e) => {
                          setIsChanged({ ...isChanged, adminComment: true });
                          setBillData({
                            ...billData,
                            adminComment: e.target.value,
                          });
                        }}
                      ></textarea>
                      <button
                        className="common-supplier-button"
                        style={{ marginBottom: "30px" }}
                        onClick={handleBillAdminCommentUpdate}
                      >
                        Update Comment
                      </button>
                      <button
                        className="common-supplier-button"
                        style={{ marginBottom: "30px" }}
                        onClick={() =>
                          navigate(
                            `/admin/purchase-order-details/${purchaseOrder?._id}`
                          )
                        }
                      >
                        Go To PO
                      </button>
                    </div>
                  </Row>
                </div>

                <Accordion>
                  <div className="grid-div or-details-row">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="req-box">
                          <h6>Order Information</h6>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ background: "#edd8ca" }}>
                        {purchaseOrder.supplierRFQId ? (
                          <div
                            className="show-accordiion"
                            style={{ width: "100%", overflowX: "auto" }}
                          >
                            <table className="qoute-version-table">
                              <>
                                <thead>
                                  <tr className="heading-row">
                                    <th scope="col">Part Name</th>
                                    <th scope="col">Part Description</th>
                                    <th scope="col">Notes</th>
                                    <th scope="col">Part Notes</th>
                                    <th scope="col">Qty</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {purchaseOrder.supplierRFQId.partsData
                                    .length > 0 &&
                                    purchaseOrder.supplierRFQId.partsData.map(
                                      (val, i) => {
                                        return (
                                          <tr key={i}>
                                            <td
                                              style={{
                                                textAlign: "left",
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                                whiteSpace: "normal",
                                                maxWidth: "150px",
                                              }}
                                            >
                                              {val.partName}
                                            </td>

                                            <td
                                              style={{
                                                textAlign: "left",
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                                whiteSpace: "normal",
                                                maxWidth: "150px",
                                              }}
                                            >
                                              {purchaseOrder.supplierRFQId
                                                .selectedProcess >= 3 ? (
                                                <>
                                                  <li> {val?.description}</li>
                                                </>
                                              ) : (
                                                <>
                                                  <div className="desc-part">
                                                    <li> {val?.description}</li>
                                                    {val?.materialType ? (
                                                      <li>
                                                        {
                                                          material.filter(
                                                            (materialItem) =>
                                                              materialItem._id ==
                                                              val.materialType
                                                          )[0]?.name
                                                        }
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {val?.subMaterial ? (
                                                      <li>
                                                        {
                                                          subMaterial.filter(
                                                            (subMaterialItem) =>
                                                              subMaterialItem._id ==
                                                              val.subMaterial
                                                          )[0]?.name
                                                        }
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {val?.surfaceTreatment ? (
                                                      <li>
                                                        {
                                                          surfaceTreatment.filter(
                                                            (
                                                              surfaceTreatmentItem
                                                            ) =>
                                                              surfaceTreatmentItem._id ==
                                                              val.surfaceTreatment
                                                          )[0]?.name
                                                        }
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {val?.surfaceFinish ? (
                                                      <li>
                                                        {
                                                          surfaceFinish.filter(
                                                            (
                                                              surfaceFinishItem
                                                            ) =>
                                                              surfaceFinishItem._id ==
                                                              val.surfaceFinish
                                                          )[0]?.name
                                                        }
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {val?.tolerance ? (
                                                      <li>
                                                        {
                                                          tolerance.filter(
                                                            (toleranceItem) =>
                                                              toleranceItem._id ==
                                                              val.tolerance
                                                          )[0]?.name
                                                        }
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {val?.partMarking ? (
                                                      <li>
                                                        {
                                                          partMarking.filter(
                                                            (partMarkingItem) =>
                                                              partMarkingItem._id ==
                                                              val.partMarking
                                                          )[0]?.name
                                                        }
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {val?.layer ? (
                                                      <li>
                                                        {
                                                          layer.filter(
                                                            (layerItem) =>
                                                              layerItem._id ==
                                                              val.layer
                                                          )[0]?.name
                                                        }
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {val?.noOfThread ? (
                                                      <li>{val?.noOfThread}</li>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </>
                                              )}
                                            </td>
                                            <td>
                                              <p>{val.notes}</p>
                                            </td>
                                            <td>{val?.partNotes}</td>
                                            <td>
                                              {billData?.partsData.filter(
                                                (item) =>
                                                  item.partItemId == val?._id
                                              )[0]?.shippedQty || 0}
                                            </td>
                                            <td>
                                              {currencyFormatter(val?.price)}
                                            </td>
                                            <td>
                                              <p>
                                                {" "}
                                                {currencyFormatter(
                                                  Number(
                                                    (billData?.partsData.filter(
                                                      (item) =>
                                                        item.partItemId ==
                                                        val?._id
                                                    )[0]?.shippedQty || 0) *
                                                    val.price
                                                  ).toFixed(2)
                                                )}
                                              </p>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </>
                            </table>

                            {purchaseOrder.supplierRFQId.partsData.length >
                              0 ? (
                              <div
                                style={{ marginTop: "10px", textAlign: "left" }}
                              >
                                <h6>Documentation required : </h6>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "20px",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div className="apply-check">
                                    <input
                                      type="checkbox"
                                      id="sign-check-cc"
                                      name="checkbox"
                                      value={
                                        purchaseOrder?.supplierRFQId?.rfqId
                                          ?.isConformanceCertificateRequested
                                      }
                                      checked={
                                        purchaseOrder?.supplierRFQId?.rfqId
                                          ?.isConformanceCertificateRequested
                                      }
                                      disabled
                                    />
                                    <label
                                      htmlFor="sign-check-cc"
                                      style={{ fontSize: "12px" }}
                                    >
                                      Certificate of conformance
                                    </label>
                                  </div>

                                  <div className="apply-check">
                                    <input
                                      type="checkbox"
                                      id="sign-check-dr"
                                      name="checkbox"
                                      value={
                                        purchaseOrder?.supplierRFQId?.rfqId
                                          ?.isDimensionalReportRequested
                                      }
                                      checked={
                                        purchaseOrder?.supplierRFQId?.rfqId
                                          ?.isDimensionalReportRequested
                                      }
                                      disabled
                                    />
                                    <label
                                      htmlFor="sign-check-dr"
                                      style={{ fontSize: "12px" }}
                                    >
                                      Dimensional Inspection Report
                                    </label>
                                  </div>

                                  <div className="apply-check">
                                    <input
                                      type="checkbox"
                                      id="sign-check-qr"
                                      name="checkbox"
                                      value={
                                        purchaseOrder?.supplierRFQId?.rfqId
                                          ?.isQrTrackingRequested
                                      }
                                      checked={
                                        purchaseOrder?.supplierRFQId?.rfqId
                                          ?.isQrTrackingRequested
                                      }
                                      disabled
                                    />
                                    <label
                                      htmlFor="sign-check-qr"
                                      style={{ fontSize: "12px" }}
                                    >
                                      QR Tracking
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div className="total-amount-order">
                              <h6 className="order-price">
                                Total Amount :{" "}
                                {currencyFormatter(
                                  Number(billData.billTotalAmount).toFixed(2)
                                )}
                              </h6>
                            </div>
                          </div>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                  <div className="grid-div  or-details-row ">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div className="req-box">
                          <h6>Quality Documents</h6>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body
                        style={{ background: "#edd8ca" }}
                        className="show-accordiion"
                      >
                        <div className="qr-code-div">
                          {purchaseOrder?.supplierRFQId?.partsData?.length >
                            0 ? (
                            <div
                              style={{ marginTop: "10px", textAlign: "left" }}
                            >
                              <h6 className="font-bold  my-2">
                                Documentation required :{" "}
                              </h6>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  marginBottom: "20px",
                                }}
                              >
                                <div
                                  className="apply-check"
                                  style={{ width: "33%" }}
                                >
                                  <input
                                    type="checkbox"
                                    id="sign-check-cc-info"
                                    name="checkbox-info"
                                    value={
                                      purchaseOrder?.supplierRFQId?.rfqId
                                        ?.isConformanceCertificateRequested
                                    }
                                    checked={
                                      purchaseOrder?.supplierRFQId?.rfqId
                                        ?.isConformanceCertificateRequested
                                    }
                                    disabled
                                  />
                                  <label
                                    htmlFor="sign-check-cc-info"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Certificate of conformance
                                  </label>
                                </div>

                                <div
                                  className="apply-check"
                                  style={{ width: "33%" }}
                                >
                                  <input
                                    type="checkbox"
                                    id="sign-check-dr-info"
                                    name="checkbox-info"
                                    value={
                                      purchaseOrder?.supplierRFQId?.rfqId
                                        ?.isDimensionalReportRequested
                                    }
                                    checked={
                                      purchaseOrder?.supplierRFQId?.rfqId
                                        ?.isDimensionalReportRequested
                                    }
                                    disabled
                                  />
                                  <label
                                    htmlFor="sign-check-dr-info"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Dimensional Inspection Report
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <span>
                            Select quality document as per the Customer
                            requirement :{" "}
                          </span>
                          <button
                            className="white-btn"
                            style={{
                              border: "1px solid lightgray",
                              marginLeft: "30px",
                            }}
                            onClick={handleAddQualityDoc}
                          >
                            Add quality document
                          </button>
                          {qualityDocs?.length > 0
                            ? qualityDocs.map((item, i) => {
                              return (
                                <div
                                  key={i}
                                  className="quality-doc-div flex justify-between items-center w-full"
                                >
                                  <div className="child-item">
                                    <span>Document Name : </span>
                                    <input
                                      type="text"
                                      value={item?.name}
                                      // disabled
                                      onChange={(e) =>
                                        handleQualityDocNameChange(e, i)
                                      }
                                    />
                                  </div>
                                  {!(
                                    qualityDocs[i].file?.filename ||
                                    qualityDocs[i].file?.name
                                  ) ? (
                                    <div
                                      className="child-item"
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-end",
                                        minWidth: "150px",
                                      }}
                                    >
                                      <label
                                        className="upload-doc-btn"
                                        htmlFor={`upload${i}`}
                                      >
                                        Upload File
                                      </label>
                                      <input
                                        id={`upload${i}`}
                                        className="quality-doc-item-input"
                                        type="file"
                                        onChange={(e) =>
                                          handleQualityDocUpload(e, i)
                                        }
                                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                                      />
                                      <img
                                        src={closeImg}
                                        style={{
                                          marginLeft: "20px",
                                          height: "15px",
                                          marginBottom: "15px",
                                        }}
                                        onClick={() =>
                                          handleQualityDocRemove(i)
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="child-item flex justify-between px-2 items-center "
                                    >
                                      <div
                                        className="downloadables"
                                        style={{
                                          marginRight: "40px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {qualityDocs[i].file?.filename ? (
                                          <div className="flex  ">
                                            <a
                                              className="downloadables"
                                              href={
                                                qualityDocs[i].file?.name
                                                  ? URL.createObjectURL(
                                                    qualityDocs[i].file
                                                  )
                                                  : (qualityDocs[i].file
                                                    ?.originalname,
                                                    `${process.env.REACT_APP_SUPPLIER_BACKEND}/${item.file?.path}`)
                                              }
                                              download={
                                                qualityDocs[i].file?.filename
                                              }
                                            >
                                              <span>
                                                {
                                                  qualityDocs[i].file
                                                    ?.originalname
                                                }
                                              </span>
                                            </a>
                                            <img
                                              src={deleteImageEnabled}
                                              className="!h-4"
                                              onClick={(e) =>
                                                handleQualityDocDelete(i)
                                              }
                                              style={{
                                                margin: "0px 20px",
                                                cursor: "pointer",
                                              }}
                                            />
                                            <img
                                              src={closeImg}
                                              style={{
                                                marginLeft: "20px",
                                                height: "15px",
                                              }}
                                              onClick={() =>
                                                handleQualityDocRemove(i)
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <div className="flex justify-center gap-x-2 items-center">
                                            <a
                                              className="downloadables"
                                              href={
                                                qualityDocs[i].file?.name
                                                  ? URL.createObjectURL(
                                                    qualityDocs[i].file
                                                  )
                                                  : (qualityDocs[i].file
                                                    ?.originalname,
                                                    `${process.env.REACT_APP_SUPPLIER_BACKEND}/${item.file?.path}`)
                                              }
                                              download={
                                                qualityDocs[i].file?.filename
                                              }
                                            >
                                              {qualityDocs[i].file?.name}
                                            </a>
                                            <a
                                              href={
                                                qualityDocs[i].file?.name
                                                  ? URL.createObjectURL(
                                                    qualityDocs[i].file
                                                  )
                                                  : (qualityDocs[i].file
                                                    ?.originalname,
                                                    `${process.env.REACT_APP_SUPPLIER_BACKEND}/${item.file?.path}`)
                                              }
                                              download={
                                                qualityDocs[i].file?.filename
                                              }
                                            >
                                              <DownloadIcon />
                                            </a>
                                            <img
                                              src={deleteImageEnabled}
                                              style={{ cursor: "pointer" }}
                                              className="h-4"
                                              onClick={() =>
                                                handleQualityDocDelete(i)
                                              }
                                            />
                                            <img
                                              src={closeImg}
                                              style={{
                                                marginLeft: "20px",
                                                height: "15px",
                                              }}
                                              onClick={() =>
                                                handleQualityDocRemove(i)
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })
                            : null}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                  <div className="grid-div  or-details-row">
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <div className="req-box">
                          <h6>Pre-delivery Consignment Photo</h6>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ background: "#edd8ca" }}>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            background: "#edd8ca",
                          }}
                          // style={{ background: "#edd8ca" }}
                          className="show-accordiion"
                        >
                          <div
                            className="upload-req po-dropzone "
                          // style={{ minWidth: "200px", float: 'left' }}
                          >
                            <Dropzone
                              onDrop={handleFileSelect}
                              minSize={1024}
                              accept={{
                                "image/*": [],
                              }}
                              reject="*.js"
                              maxSize={10 * 1024 * 1024}
                              multiple={true}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  {...getRootProps({
                                    className:
                                      " upload-box step-to-upload dropzone",
                                  })}
                                >
                                  <span className="up-icon">
                                    {" "}
                                    <img src={uploadImage} />{" "}
                                  </span>

                                  <div className="up-text">
                                    <span className="inp-txt">
                                      Drop files here to upload or
                                    </span>
                                    <div className="file-button">
                                      <input {...getInputProps()} />
                                      <span
                                        style={{
                                          color: "#2B96DC",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        Browse
                                      </span>
                                    </div>
                                  </div>
                                  <p>
                                    {consignmentPhoto
                                      ? consignmentPhoto.length
                                      : "0"}{" "}
                                    Files Selected!
                                  </p>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            className="po-consignment-pic-right"
                            style={{ flex: 1 }}
                          >
                            {consignmentPhoto && consignmentPhoto.length > 0 ? (
                              <>
                                <h6
                                  style={{
                                    textDecoration: "underline",
                                    marginBottom: "10px",
                                  }}
                                  className="font-bold "
                                >
                                  Files Uploaded
                                </h6>
                                <div
                                  className="file-grid gap-3 mx-2"
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  <ConsignmentPhotoViewer
                                    images={consignmentPhoto}
                                    altText={"consignment-photo"}
                                    handleConsignmentFileDelete={
                                      handleConsignmentFileDelete
                                    }
                                    serverType={"supplier"}
                                  />
                                  {/* {consignmentPhoto.map((photo, i) => {
                                    return (
                                      <div
                                        style={{ margin: "5px" }}
                                        key={i}
                                      >
                                        {photo?.filename ? (
                                          <img
                                            src={
                                              `${process.env.REACT_APP_SUPPLIER_BACKEND}/public/uploads/` +
                                              photo?.filename
                                            }
                                            height="100px"
                                            width="150px"
                                            style={{ marginLeft: "3px" }}
                                          />
                                        ) : (
                                          <img
                                            src={URL.createObjectURL(
                                              photo?.file
                                            )}
                                            height="100px"
                                            width="150px"
                                            style={{ marginLeft: "3px" }}
                                          />
                                        )}
                                        <div className="file-name">
                                          {photo?.originalname ? (
                                            <>
                                              <span>
                                                <span
                                                  onClick={() =>
                                                    download(
                                                      photo?.originalname,
                                                      `${process.env.REACT_APP_SUPPLIER_BACKEND}/` +
                                                      photo?.path
                                                    )
                                                  }
                                                  className="downloadables"
                                                >
                                                  <span>
                                                    {photo?.originalname
                                                      ? photo?.originalname
                                                      : photo?.file.name}
                                                  </span>
                                                  <img
                                                    src={downloadImage}
                                                    height="10px"
                                                    style={{
                                                      marginLeft: "5px",
                                                    }}
                                                  />
                                                </span>
                                                <img
                                                  onClick={(e) =>
                                                    handleConsignmentFileDelete(
                                                      i
                                                    )
                                                  }
                                                  src={deleteImageEnabled}
                                                  height="15px"
                                                  style={{
                                                    marginLeft: "10px",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </span>

                                            </>
                                          ) : (
                                            <>
                                              <span>
                                                <a
                                                  className="downloadables"
                                                  href={
                                                    photo?.file?.name
                                                      ? URL.createObjectURL(
                                                        photo?.file
                                                      )
                                                      : ""
                                                  }
                                                  download={
                                                    photo?.file?.name
                                                      ? photo?.file?.name
                                                      : ""
                                                  }
                                                >
                                                  <span>
                                                    {photo?.filename
                                                      ? photo?.filename
                                                      : photo?.file?.name}
                                                  </span>
                                                  <img
                                                    src={downloadImage}
                                                    height="10px"
                                                  />
                                                </a>
                                                <img
                                                  onClick={(e) =>
                                                    handleConsignmentFileDelete(
                                                      i
                                                    )
                                                  }
                                                  src={deleteImageEnabled}
                                                  height="15px"
                                                  style={{
                                                    marginLeft: "10px",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </span>

                                            </>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })} */}
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>

                  <div className="grid-div  or-details-row">
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <div className="req-box">
                          <h6>Delivery Tracking </h6>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ background: "#edd8ca" }}>
                        <div className="delivery-div show-accordiion">
                          <div className="delivery-tracking-div">
                            <div>
                              <p>Delivery tracking information : </p>
                            </div>
                            <div>
                              {billData.deliveryTrackingLink ? (
                                <>
                                  {isUrlValid(billData.deliveryTrackingLink) ? (
                                    <>
                                      <a
                                        href={billData.deliveryTrackingLink}
                                        target="_blank"
                                      >
                                        {billData.deliveryTrackingLink}
                                      </a>

                                      <img
                                        src={deleteImageEnabled}
                                        height="20px"
                                        style={{
                                          marginLeft: "20px",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleRemoveDeliveryLink}
                                      />
                                    </>
                                  ) : !isNaN(billData.deliveryTrackingLink) ? (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <span className="inline-flex items-center mt-1">
                                          <span className="inline-flex items-center">
                                            <span className="text-primaryColor">{billData.deliveryTrackingLink}</span>
                                            <span
                                              onClick={handleRemoveDeliveryLink}
                                            >
                                              <DeleteIcon additionalClasses={'h-4 text-red-500'} />
                                            </span>
                                          </span>
                                          <button
                                            className="h-5 w-max px-3 py-1  bg-primaryColor text-white flex items-center justify-center text-[13px] mx-2 rounded"
                                            onClick={handleDeliveryTrackingNumber}
                                          >
                                            Track
                                          </button>
                                        </span>
                                      </div>
                                    </>
                                  ) : billData.deliveryTrackingLink ? (
                                    <>
                                      <span>
                                        {billData.deliveryTrackingLink + "  "}
                                      </span>

                                      <img
                                        src={deleteImageEnabled}
                                        height="20px"
                                        style={{
                                          marginLeft: "20px",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleRemoveDeliveryLink}
                                      />
                                    </>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  <input
                                    type="text"
                                    className="input-delivery border p-1"
                                    placeholder="Enter delivery tracking information"
                                    style={{ minWidth: "250px" }}
                                    onChange={(e) => {
                                      setDeliveryTackingLink(e.target.value);
                                      setIsChanged({
                                        ...isChanged,
                                        deliveryDetails: true,
                                      });
                                    }}
                                  />
                                </>
                              )}
                              <br />
                              <br />
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                  width: "250px",
                                }}
                              >
                                *If DHL number is provided, the API will
                                retrieve the data. Otherwise, please enter the
                                delivery tracking link for other carriers.
                              </p>
                            </div>
                          </div>

                          <div className="delivery-date-div">
                            <div>
                              <p>Shipped date:</p>
                            </div>

                            <div className="sub-btn">
                              <input
                                type="date"
                                className="attach-name"
                                value={
                                  billData.shippedDate
                                    ? new Date(billData.shippedDate)
                                      .toISOString()
                                      .slice(0, 10)
                                    : ""
                                }
                                onChange={handleShippedDate}
                              />
                            </div>
                          </div>

                          <div className="delivery-date-div">
                            <div>
                              <p>Shipping Carrier:</p>
                            </div>

                            <div className="sub-btn">
                              <input
                                type="text"
                                className="input-delivery border p-1"
                                placeholder="Enter shipping carrier"
                                style={{ minWidth: "250px" }}
                                value={billData.shippingCarrier}
                                onChange={(e) => {
                                  setBillData({
                                    ...billData,
                                    shippingCarrier: e.target.value,
                                  });
                                  setIsChanged({
                                    ...isChanged,
                                    shippingCarrier: true,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {billData.status != "pending for approval" ? (
                            <>
                              <div className="delivery-date-div">
                                <div>
                                  <p>Remittance:</p>
                                </div>

                                <div className="sub-btn">
                                  <input
                                    type="date"
                                    className="attach-name"
                                    value={
                                      billData.remittanceDate
                                        ? new Date(billData.remittanceDate)
                                          .toISOString()
                                          .slice(0, 10)
                                        : ""
                                    }
                                    onChange={handleRemittanceDate}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <br />
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                </Accordion>
              </div>
            )}
            <div className="order-stage-div">
              <button
                className="supplier-stage-button"
                onClick={handleUpdateBill}
              >
                Update Bill
              </button>
              {billData?.status == "pending for approval" ? (
                <>
                  <button
                    className="supplier-stage-button"
                    onClick={handleApproveBill}
                  >
                    Approve
                  </button>

                  <button
                    className="supplier-stage-button"
                    onClick={handleRejectBill}
                  >
                    Reject
                  </button>
                </>
              ) : (
                <></>
              )}
              <button className="supplier-stage-button" onClick={handleBack}>
                Back
              </button>
            </div>
          </div>
        </div>
      </span>
      {loading && (
        <div className="fixed inset-0 bg-white opacity-80">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default AdminDashboardComponents(BillDetails, {
  width: "100%",
  overflowX: "auto",
  backgroundColor: "#f4e6dd",
  minHeight: "100vh",
});
