import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents';
import { toast } from 'react-toastify';
import { deleteShippingRates, getAllShippingRates, upsertShippingRates } from '../../utils/actions/adminActions';

const ShippingRates = () => {
    const [shippingRates, setShippingRates] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [newRate, setNewRate] = useState({
        order_min_weight_kgs: '',
        order_max_weight_kgs: '',
        from_zone1_US_cost1: '',
        from_zone2_EU_UK_cost2: '',
        from_zone3_IN_MY_CH_cost3: '',
        from_zone1_US_per_kg_cost1: '',
        from_zone2_EU_UK_per_kg_cost2: '',
        from_zone3_IN_MY_CH_per_kg_cost3: '',
    });
    const [isReload, setIsReload] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {

        (async () => {
            try {
                const response = await getAllShippingRates();
                if (!response || !response.success) {
                    throw new Error();
                }
                const { data } = response;
                setShippingRates(data);
            } catch (error) {
                toast.error("Unable To Fetch All Shipping Rates At The Moment");
            }
        })()

    }, [isReload]);
    function resetData() {
        setNewRate({
            order_min_weight_kgs: '',
            order_max_weight_kgs: '',
            from_zone1_US_cost1: '',
            from_zone2_EU_UK_cost2: '',
            from_zone3_IN_MY_CH_cost3: '',
            from_zone1_US_per_kg_cost1: '',
            from_zone2_EU_UK_per_kg_cost2: '',
            from_zone3_IN_MY_CH_per_kg_cost3: '',
        });

    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name.includes("cost")) {
            if (/^\d*(\.\d{0,2})?$/.test(value)) {
                setNewRate((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
            }
        }
        else {
            setNewRate((prevState) => ({
                ...prevState,
                [name]: value,
            }));

        }

    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await upsertShippingRates(newRate);
            if (!response || !response.success) {
                throw new Error();
            };
            const { data } = response;
            toast.success(`Shipping ${newRate?._id ? "Updated" : "Created "} Succesfully`);
            setIsReload((pre) => !pre);
        } catch (error) {
            return false;
        } finally {
            resetData();
            setShowForm(false);
        }

    };

    const handleDelete = async (item) => {
        const userResposne = confirm("Are You Sure You Want To Delete ");
        if (!userResposne) return;
        try {

            const id = item?._id;

            const response = await deleteShippingRates(id);
            if (!response || !response.success) {
                throw new Error();
            };
            toast.success("Item Deleted Succesfully");
            setIsReload((pre) => !pre);

        } catch (error) {
            toast.success("Unable To Delete Item At The Moment");
        }
    };

    const handleUpdate = (item) => {
        setShowForm(true);
        setNewRate(item);
    }

    return (
        <div className="max-w-7xl mx-auto p-6 bg-gray-50 min-h-screen">
            <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Shipping Rates</h1>

            <div className="flex justify-end mb-6">
                <button
                    onClick={() => {
                        resetData();
                        setShowForm(!showForm);
                    }}
                    className="bg-primaryColor text-white px-4 py-2 rounded-lg "
                >
                    {showForm ? 'Close Form' : 'Create New'}
                </button>
            </div>

            {showForm && (
                <form
                    onSubmit={handleFormSubmit}
                    className="bg-white p-6 rounded-lg shadow-md mb-6"
                >
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Min Weight (kgs)
                            </label>
                            <input
                                type="number"
                                name="order_min_weight_kgs"
                                value={newRate.order_min_weight_kgs}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Max Weight (kgs)
                            </label>
                            <input
                                type="number"
                                name="order_max_weight_kgs"
                                value={newRate.order_max_weight_kgs}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Zone 1 US Cost $
                            </label>
                            <input
                                type="number"
                                name="from_zone1_US_cost1"
                                value={newRate.from_zone1_US_cost1}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Zone 2 EU/UK Cost $
                            </label>
                            <input
                                type="number"
                                name="from_zone2_EU_UK_cost2"
                                value={newRate.from_zone2_EU_UK_cost2}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Zone 3 IN/MY/CH Cost $
                            </label>
                            <input
                                type="number"
                                name="from_zone3_IN_MY_CH_cost3"
                                value={newRate.from_zone3_IN_MY_CH_cost3}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Zone 1 US Per KG Cost $
                            </label>
                            <input
                                type="number"
                                name="from_zone1_US_per_kg_cost1"
                                value={newRate.from_zone1_US_per_kg_cost1}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Zone 2 EU/UK Per KG Cost $
                            </label>
                            <input
                                type="number"
                                name="from_zone2_EU_UK_per_kg_cost2"
                                value={newRate.from_zone2_EU_UK_per_kg_cost2}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Zone 3 IN/MY/CH Per KG Cost $
                            </label>
                            <input
                                type="number"
                                name="from_zone3_IN_MY_CH_per_kg_cost3"
                                value={newRate.from_zone3_IN_MY_CH_per_kg_cost3}
                                onChange={handleInputChange}
                                className="mt-1 block w-fullc p-2 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                        <button
                            type="submit"
                            className="bg-primaryColor text-white px-4 py-2 rounded-lg  transition"
                        >
                            {newRate?._id ? "Update" : "Add"} Shipping Rate
                        </button>
                    </div>
                </form>
            )}

            <div>
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    Existing Shipping Rates
                </h2>
                <div className="overflow-x-auto">
                    <table className="table-auto   w-full border-collapse border border shadow-lg rounded-lg overflow-hidden">
                        <thead className="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                            <tr>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Min Weight</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Max Weight</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Zone 1 US Cost</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Zone 2 EU/UK Cost</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Zone 3 IN/MY/CH Cost</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Zone 1 US Per KG</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Zone 2 EU/UK Per KG</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Zone 3 IN/MY/CH Per KG</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Actions</th>
                            </tr>
                        </thead>
                        <tbody className=''>
                            {shippingRates.map((rate, index) => (
                                <tr key={index}
                                    className={` hover:bg-gray-200 transition duration-200 `}>
                                    <td className="px-4 py-2 border border-gray-200">{rate.order_min_weight_kgs}</td>
                                    <td className="px-4 py-2 border border-gray-200">{rate.order_max_weight_kgs}</td>
                                    <td className="px-4 py-2 border border-gray-200">{rate.from_zone1_US_cost1}</td>
                                    <td className="px-4 py-2 border border-gray-200">{rate.from_zone2_EU_UK_cost2}</td>
                                    <td className="px-4 py-2 border border-gray-200">{rate.from_zone3_IN_MY_CH_cost3}</td>
                                    <td className="px-4 py-2 border border-gray-200">{rate.from_zone1_US_per_kg_cost1}</td>
                                    <td className="px-4 py-2 border border-gray-200">{rate.from_zone2_EU_UK_per_kg_cost2}</td>
                                    <td className="px-4 py-2 border border-gray-200">{rate.from_zone3_IN_MY_CH_per_kg_cost3}</td>
                                    <td className="px-4 py-2  border-gray-200   flex-col flex text-[14px] text-white">
                                        <button onClick={() => handleUpdate(rate)} className='bg-yellow-500 rounded mx-2 p-1'>
                                            Update
                                        </button>
                                        <button onClick={() => handleDelete(rate)} className='bg-red-500 rounded mx-2 p-1 my-1'>
                                            Del
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AdminDashboardComponents(ShippingRates);
