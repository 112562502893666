import { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { getSurfaceFinish } from "../../utils/actions/allactions";
import { useNavigate } from "react-router-dom";
import { getToken, validateAdmin } from "../../utils/helper";
import useDocumentTitle from "../../utils/useDocumentTitle";
import AdminLoadingComponent from "../../components/LoadingComponents/AdminLoadingComponent";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";

const SurfaceFinishMaster = () => {
  let navigate = useNavigate();
  useDocumentTitle("Surface Finish Master");
  const [data, setData] = useState(null);
  let token = getToken();

  useEffect(() => {
    const fetchSurfaceFinish = async () => {
      try {
        const surfaceFinishData = await getSurfaceFinish(token);
        setData(surfaceFinishData);
        
      } catch (error) {
        toast.error("Unable To Fetch Surface Finish");
      }
     
    };
    fetchSurfaceFinish();
  }, []);

  const handleCreateNew = () => {
    navigate("/admin/add-surface-finish-master");
  };

  const handleEditSurfaceFinishMaster = (id) => {
    navigate(`/admin/edit-surface-finish-master?id=${id}`);
  };

  return (
    <div className="admin-container">
     
      <div className="right-container">
        <div className="center-heading">
          <h1 className="text-2xl font-bold"> SurfaceFinish Master </h1>
          <div className="search-bar">
            <div className="row">
              <div className="col-md-9"></div>
              <div className="col-md-3">
                <div className="search-btn">
                  <button onClick={handleCreateNew} className="pr-edit-btn">
                    Create New
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="quotes-table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Name{" "}
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    SF Factor
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {data && (
                <tbody>
                  {data &&
                    data.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.name ? item.name : ""}</td>
                          <td>{item.SfFactor ? item.SfFactor : "NA"}</td>
                          <td>
                            {" "}
                            {/* <button
                              className="button-edit"
                              onClick={() => {
                                handleEditSurfaceFinishMaster(item);
                              }}
                            >
                              Edit/Deactivate
                            </button> */}
                            <Button
                              text={"Edit /Deactivate"}
                              additionalClassName={
                                "border-red-500  px-4 py-2 bg-yellow-500 shadow-sm text-white rounded text-sm"
                              }
                              onClickFunction={() =>
                                handleEditSurfaceFinishMaster(item._id)
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              )}
              {!data && <AdminLoadingComponent rows={3} columns={3} />}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardComponents(SurfaceFinishMaster);
