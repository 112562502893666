import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/header";
import jwt_decode from "jwt-decode";
import useDocumentTitle from "../../utils/useDocumentTitle";
import Button from "../../components/Button/Button";
import { CREATE_QUOTE_ACTION, useCreateQuoteContext } from "../../context/create.quote.context";
import { setProjectName } from "../../context/create.quote.actions";


const ProjectName = () => {
  let token = localStorage.getItem("Token");
  useDocumentTitle("8xParts | A reliable manufacturing platform");
  let userName = localStorage.getItem("firstName");
  const loggedInUser = localStorage.getItem("Token");
  const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();

  const setProjectName = (newValue) => {
    createQuoteDispatch({
      type: CREATE_QUOTE_ACTION.UPDATE_COMMAN_VALUES,
      payload: { ...createQuoteState?.commanValues, projectName: newValue }
    })
  }


  const navigate = useNavigate();

  useEffect(() => {
    // setProjectName("");
    setProjectName("",createQuoteDispatch,createQuoteState)
  }, [])


  const handleNavigate = () => {
    if (createQuoteState?.commanValues?.projectName?.length === 0) {
      alert("Enter a valid Project Name.");
    } else {
      navigate("/dashboard/option");
    }
  };

  const onKeyDown = (e) => {
    const code = e.keyCode ? e.keyCode : e.which;
    if (code == 13) {
      e.preventDefault();
      if (createQuoteState?.commanValues?.projectName?.length === 0) {
        alert("Enter a valid Project Name.")
      } else {
        navigate("/dashboard/option")
      }
    }
  };

  return (
    <div>
      {loggedInUser ? (
        <div>
          <Header activeTab="/" />
          <div className="bg-slate-100 h-screen ">
            <div className="h-full  ">
              <div className="h-20 bg-white shadown-sm  md:px-32 py-3 px-10 ">
                <h3 className="md:text-xl font-bold ">
                  Hey {userName || ""} Welcome !
                </h3>
                <p className="text-slate-500 text-xs md:text-sm line-clamp-2 ">
                  The power of digital manufacturing at your fingertips
                </p>
              </div>
              <div className="bg-white mx-3 my-3  py-2 px-7 md:px-12 lg:px-24 rounded-xl">
                <div className="flex justify-between items-center  px-2">
                  <div className="flex flex-col mt-2">
                    <label
                      for="project-name"
                      class="block md:text-xl  text-sm lg:text-2xl my-2 mb-3 font-normal  text-gray-900 "
                    >
                      Please enter the name of your new project :
                    </label>

                    <input
                      value={createQuoteState?.commanValues?.projectName}
                      type="text"
                      onChange={(e) => setProjectName(e.target.value,createQuoteDispatch,createQuoteState) }
                      id="project-name"
                      class="bg-gray-50 border border-gray-300 focus:border focus:border-primaryColor text-gray-900 text-sm  block  p-2.5 md:!w-96 !w-48 h-7 md:h-10 "
                      maxLength="50"
                      onKeyDown={onKeyDown}
                    />
                    <Button
                      onClickFunction={handleNavigate}
                      text={"Proceed to Instant Quotation"}
                      additionalClassName={
                        "bg-primaryColor rounded-md !mb-10 text-sm ms-5 md:!px-3 lg:!py-3 lg:!px-4 lg:!py-3  text-white my-3 "
                      }
                      IconChild={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="lucide lucide-move-right h-4 md:h-5"
                        >
                          <path d="M18 8L22 12L18 16" />
                          <path d="M2 12H22" />
                        </svg>
                      }
                    />
                  </div>

                </div>
              </div>
              <div className="bg-white mx-2 px-4 my-3 text-sm  py-4 rounded-xl text-slate-500 shadow-sm ">
                <strong>Privacy:</strong> 8xParts 3D secures all uploaded files,
                protecting your intellectual property. If you can’t upload your
                files at the moment, use our sample part to try out our instant
                quoting and DfM tools or contact us on{" "}
                <a href="mailto: info@8xparts.com">info@8xparts.com</a> to sign
                an NDA.
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>Please Login First</div>
      )}
    </div>
  );
};
export default ProjectName;
