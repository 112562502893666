import { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { getSurfaceTreatment } from "../../utils/actions/allactions";
import { useNavigate } from "react-router-dom";
import { getToken, validateAdmin } from "../../utils/helper";
import useDocumentTitle from "../../utils/useDocumentTitle";
import AdminLoadingComponent from "../../components/LoadingComponents/AdminLoadingComponent";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";

const SurfaceTreatmentMaster = () => {
  let navigate = useNavigate();
  useDocumentTitle("Surface Treatment Master");
  const [data, setData] = useState(null);
  let token = getToken();

  const fetchSurfaceTreatment = async () => {
    try {
      const surfaceTreatmentData = await getSurfaceTreatment(token);
      setData(surfaceTreatmentData);
      
    } catch (error) {
      toast.error("Unable To Fetch Surface Treatment Data");
    }
   
  };

  useEffect(() => {
    validateAdmin(token, navigate);
    fetchSurfaceTreatment();
  }, []);

  const handleCreateNew = () => {
    navigate("/admin/add-surface-treatment-master");
  };

  const handleEditSurfaceTreatmentMaster = (id) => {
    navigate(`/admin/edit-surface-treatment-master?id=${id}`);
  };

  return (
    <div className="admin-container">
     
      <div className="right-container">
        <div className="center-heading">
          <h1 className="text-2xl font-bold"> Surface Treatment Master </h1>

          <div className="search-bar">
            <div className="row">
              <div className="col-md-9"></div>
              <div className="col-md-3">
                <div className="search-btn">
                  <button onClick={handleCreateNew} className="pr-edit-btn">
                    Create New
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="quotes-table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Name{" "}
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    ST Factor
                  </th>
                  <th scope="col" style={{ cursor: "pointer" }}>
                    Materials
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {data && (
                <tbody>
                  {data.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.name ? item.name : ""}</td>
                        <td>{item.StFactor ? item.StFactor : ""}</td>
                        <td>
                          {item?.materialMasters?.length > 0
                            ? item.materialMasters.map((item, i) => (
                                <li key={i}>{item.name}</li>
                              ))
                            : ""}
                        </td>
                        <td>
                          <Button
                            text={"Edit /Deactivate"}
                            additionalClassName={
                              "border-red-500  px-4 py-2 bg-yellow-500 shadow-sm text-white rounded text-sm"
                            }
                            onClickFunction={() =>
                                handleEditSurfaceTreatmentMaster(item._id)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
              {!data && <AdminLoadingComponent rows={13} columns={4} />}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardComponents(SurfaceTreatmentMaster);
