import React, { useState } from 'react'
import { processType } from '../../../../utils/constant';
import { useCreateQuoteContext } from '../../../../context/create.quote.context';
import { updateSelectedProcess } from '../../../../context/create.quote.actions';

const ProcessTabs = ({ name }) => {
    const { createQuoteState, createQuoteDispatch } = useCreateQuoteContext();
    
    const [currentProcessId, setCurrentProcessId] = useState(
        name === "CNC Machining" ? 1 :
            name === "3D Printing" ? 2 :
                processType.find((process) => process.name === name)?.id || null
    );

    const handleProcessChange = (processId) => {



        updateSelectedProcess(processId, createQuoteDispatch, createQuoteState);
    }

    return (
        <div className="pr-radio m-1">
            <label
                className={
                    createQuoteState?.commanValues?.selectedProcess === currentProcessId
                        ? "btn box-in border-2 border-gray-300 rounded-md p-2 cursor-pointer [border-primaryColor] shadow-lg relative"
                        : "btn box-in border-2 border-gray-300 rounded-md p-2 cursor-pointer"
                }

                style={{border:createQuoteState?.commanValues?.selectedProcess === currentProcessId?"1px solid #2B96DC":""}}
                htmlFor={`btnradio-${currentProcessId}`}
                onClick={() => handleProcessChange(currentProcessId)}
            >
                {name}
            </label>
        </div>
    )
}

export default ProcessTabs