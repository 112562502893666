import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useEffect, useState, Fragment } from "react";
import { numberInputOnWheelPreventChange} from "../../../utils/helper";

const SheetInjectionInput = (props) => {
  let { _id } = useParams();
  let index = props.index;
  let val = props.values[index];
  const [itemDescription, setItemDescription] = useState("");
  const [itemNotes, setItemNotes] = useState(val.Notes);
  const [itemQuantity, setItemQuantity] = useState(val.Qty);
  const [linePartName,setLinePartName] = useState(val.linePartName);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const repeatOrder = searchParams.get('RepeatOrder') == "true" ? true : false;
  const isRepeatOrderWithUpdate = searchParams.get('isRepeatOrderWithUpdate') == "true" ? true : false;



  const handleChangeRouteForRepeatOrder = ()=>{
      let userConfirmation = confirm("Are you sure you want to change the selected files for the previous quotation? This action will generate a new version of the quotation, which will require admin review before you can proceed with creating an order.");
      if (userConfirmation) {
        navigate(`/get-instant-quote/${_id}?isRepeatOrderWithUpdate=true`)
      }
  }


  useEffect(() => {
    if (_id) {
      setItemDescription(props.values[index].description)
      setItemNotes(props.values[index].Notes)
      setItemQuantity(props.values[index].Qty)
      setLinePartName(props.values[index].linePartName)
    }
  }, [])

  useEffect(() => {
    if (_id) {
      setItemDescription(props.values[index].description)
      setItemNotes(props.values[index].Notes)
      setItemQuantity(props.values[index].Qty)
      setLinePartName(props.values[index].linePartName)
    }
  }, [props.values])


  const onDescriptionChange = (event) => {
    if(repeatOrder){
      handleChangeRouteForRepeatOrder()
      return;
    }
    setItemDescription(event.target.value);
    val.description = event.target.value;
  }

  const handlePartNameChange = (event)=>{
    if(repeatOrder){
      handleChangeRouteForRepeatOrder()
      return;
    }
    setLinePartName(event.target.value)
    val.linePartName = event.target.value;
  }


  const onNotesChange = (event) => {
    if(repeatOrder){
      handleChangeRouteForRepeatOrder()
      return;
    }
    setItemNotes(event.target.value);
    val.Notes = event.target.value;
  }

  const onQuantityChange = (event) => {
    if(repeatOrder){
      handleChangeRouteForRepeatOrder()
      return;
    }
    setItemQuantity(event.target.value);
    val.Qty = event.target.value;
  }

  const onQuantityChangeWithIcon =(value)=>{
    if(repeatOrder){
      handleChangeRouteForRepeatOrder()
      return;
    }
    if(value<=0){
      return;
    }
    setItemQuantity(value);
    val.Qty = value;
  }


  return (
    <Fragment>
      {
        (val.file || val.selectedFile )? (
          <tr key={index}>
            <td >
              <h6>{(val.file ? val.file.originalname :val?.selectedFile?.path? val?.selectedFile?.path : 'Name not found!')?.length>25?
                (val.file ? val.file.originalname :val?.selectedFile?.path? val?.selectedFile?.path : 'Name not found!').substring(0, 25)+"..."
                :(val.file ? val.file.originalname :val?.selectedFile?.path? val?.selectedFile?.path : 'Name not found!')
                }
              </h6>
            </td>
            <td >
              <Form.Control
                name="descripton"
                className="description"
                type="text"
                placeholder="Enter description here"
                value={itemDescription}
                onChange={onDescriptionChange}
              />
            </td>
            <td >
              <Form.Control
                name="notes"
                type="text"
                placeholder="Enter notes here"
                value={itemNotes}
                onChange={onNotesChange}
              />
            </td>
            <td  style={{width:'140px',display: 'block',display:'flex',alignItems:'flex-end'}}>
            <Button className="qty-btn" onClick={()=>onQuantityChangeWithIcon(Number(val.Qty)-1)}>-</Button>
              <Form.Control
                name="quantity"
                className="quantity"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                value={val.Qty}
                onChange={(e) => {
                  if (!e.target.value || Number(e.target.value)<1) {
                    return;
                  }
                  onQuantityChange(e)
                }}
                style={{
                  display:'inline'
                }}
              />
              <Button className="qty-btn" onClick={()=>onQuantityChangeWithIcon(Number(val.Qty)+1)}>+</Button>
            </td>

            <td >
              <Link
                to=""
                className="del white-btn"
                style={{ color: 'red' }}
                onClick={() => props.onDelete(index)}
              >
                Delete
              </Link>
            </td>
          </tr>)
          : val.selectedFile ? (
            <tr key={index}>
              <td>
                <h6>{val.selectedFile.name}</h6>
              </td>
              <td >
                <Form.Control
                  name="description"
                  className="description"
                  type="text"
                  placeholder="Enter description here"
                  value={itemDescription}
                  onChange={onDescriptionChange}
                />
              </td>
              <td >
                <Form.Control
                  name="notes"
                  type="text"
                  placeholder="Enter notes here"
                  value={itemNotes}
                  onChange={onNotesChange}
                />
              </td>
              <td style={{width:'140px',display: 'block',display:'flex',alignItems:'flex-end'}}>
              <Button className="qty-btn" onClick={()=>onQuantityChangeWithIcon(Number(val.Qty)-1)}>-</Button>
                <Form.Control
                  name="quantity"
                  className="quantity"
                  type="number"
                  onWheel={numberInputOnWheelPreventChange}
                  onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  value={itemQuantity}
                  onChange={(e) => {
                    if (!e.target.value || Number(e.target.value)<1) {
                      return;
                    }
                    onQuantityChange(e)
                  }}
                />
                <Button className="qty-btn" onClick={()=>onQuantityChangeWithIcon(Number(val.Qty)+1)}>+</Button>
              </td>

              <td>
                <Link
                  to=""
                  className="del white-btn"
                  style={{ color: 'red' }}
                  onClick={() => props.onDelete(index)}
                >
                  Delete
                </Link>
              </td>
            </tr>
          )
            : (
              <tr key={index}>
                <td>
                  <Form.Control
                    name='linePartName'
                    className="part-name"
                    type="text"
                    value={linePartName}
                    placeholder="Enter part name here"
                    onChange={handlePartNameChange}
                  />
                </td>
                <td >
                  <Form.Control
                    name="description"
                    type="text"
                    className="description"
                    placeholder="Enter description here"
                    value={itemDescription}
                    onChange={onDescriptionChange}
                  />
                </td>
                <td >
                  <Form.Control
                    name="notes"
                    type="text"
                    placeholder="Enter notes here"
                    value={itemNotes}
                    onChange={onNotesChange}
                  />
                </td>
                <td style={{width:'140px',display: 'block',display:'flex',alignItems:'flex-end'}}>
                  <Button className="qty-btn" onClick={()=>onQuantityChangeWithIcon(Number(val.Qty)-1)}>-</Button>
                  <Form.Control
                    name="quantity"
                    className="quantity"
                    type="number"
                    onWheel={numberInputOnWheelPreventChange}
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    value={itemQuantity}
                    style={{display:'inline'}}
                    onChange={(e) => {
                      if (!e.target.value || Number(e.target.value)<1) {
                        return;
                      }
                      onQuantityChange(e)
                    }}
                  />
                  <Button className="qty-btn" onClick={()=>onQuantityChangeWithIcon(Number(val.Qty)+1)}>+</Button>
                </td>

                <td>
                  <Link
                    to=""
                    className="del white-btn"
                    style={{ color: 'red' }}
                    onClick={() => props.onDelete(index)}
                  >
                    Delete
                  </Link>
                </td>
              </tr>
            )}

    </Fragment>
  )
};
export default SheetInjectionInput;