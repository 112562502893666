import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { useState } from "react";
import {
  updateSurfaceTreatment,
  getMaterialMaster,
  getSurfaceTreatmentDetailsById,
} from "../../../utils/actions/allactions";
import {
  validateAdmin,
  numberInputOnWheelPreventChange,
  getToken,
} from "../../../utils/helper";
import { toast } from "react-toastify";
import crossImage from "../../../images/close_red.svg";
import useDocumentTitle from "../../../utils/useDocumentTitle";
import SkeltonLoadingComponent from "../../../components/LoadingComponents/SkeltonLoadingComponent";

function EditSurfaceTreatmentMaster() {
  const token = getToken();
  const navigate = useNavigate();
  useDocumentTitle("Edit Surface Treatment");
  const [error, setError] = useState("");
  const [materialMaster, setMaterialMaster] = useState([]);
  const [initialValue, setInitialValue] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id") || null;

  const setMaterialMasterVal = async () => {
    try {
      const materialMasterData = await getMaterialMaster(token);
      setMaterialMaster(materialMasterData);
    } catch (error) {
      setError("Unable To Fetch Materials");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSurfaceTreatmentDetailsById(id);
        if (data && data?.success) {
          setInitialValue(data.data);
        }
        
      } catch (error) {
        toast.error("Unable To Fetch Surface Treatment Details");
      }
     
    };
    fetchData();

    setMaterialMasterVal();
  }, []);

  const validateSurfaceTreatmentMaster = () => {
    if (
      !initialValue.name ||
      !initialValue.StFactor ||
      initialValue.materialMasters.length == 0
    ) {
      return false;
    }
    return true;
  };

  const handleEditSurfaceTreatment = async () => {
    setError("");
    const isSurfaceTreatmentValid = validateSurfaceTreatmentMaster();

    if (!isSurfaceTreatmentValid) {
      setError("Enter valid values for all the field");
      return;
    } 
      try {
        const updateddSurfaceTreatment = await updateSurfaceTreatment(
          token,
          initialValue
        );
  
        if (updateddSurfaceTreatment?.data?.data?.code == 11000) {
          toast.error("Surface treatment already exists");
        } else {
          navigate("/admin/surface-treatment");
          toast.success("Surface treatment updated successfully");
        }
        
      } catch (error) {
        toast.error("Unable To Update Surface Treatment Data");
      }
      
    
  };

  const handleSelectMaterial = (event) => {
    const selectedMaterialId = event.target.value;
    const selectedMaterialObject = materialMaster.filter((item, i) => {
      if (item._id == selectedMaterialId) {
        return item;
      }
    });
    let localMaterialMasters = initialValue.materialMasters;
    for (let i = 0; i < localMaterialMasters.length; i++) {
      if (localMaterialMasters[i]._id == selectedMaterialId) {
        toast.error("Material is already selected");
        return;
      }
    }
    localMaterialMasters.push(selectedMaterialObject[0]);
    setInitialValue({ ...initialValue, materialMasters: localMaterialMasters });
  };

  const handleMaterialRemove = (MaterialId) => {
    let localMaterialMasters = initialValue.materialMasters;
    let filteredMaterialMasters = localMaterialMasters.filter(
      (item) => item._id != MaterialId
    );
    setInitialValue({
      ...initialValue,
      materialMasters: filteredMaterialMasters,
    });
  };

  return (
    <div className="admin-container">
      <div>
        <Sidebar />
      </div>
      <div className="admin-panel-container">
        <h1 className="center-heading"> Edit Surface Treatment </h1>

        {initialValue ? (
          <div className="edit-company-container">
            <div>
              <label htmlFor="name"> Name </label>: &nbsp;
              <input
                name="name"
                type="text"
                placeholder="Enter  name"
                value={initialValue.name}
                onChange={(e) => {
                  setInitialValue({ ...initialValue, name: e.target.value });
                }}
              />
            </div>

            <div>
              <label htmlFor="StFactor"> StFactor </label>: &nbsp;
              <input
                name="StFactor"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                placeholder="Enter StFactor"
                value={initialValue.StFactor}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    StFactor: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <label htmlFor="masterMaterialId">Material List</label>: &nbsp;
              <select
                name="masterMaterialId"
                onChange={(e) => handleSelectMaterial(e)}
              >
                <option value="" selected disabled>
                  {" "}
                  Select Material
                </option>
                {materialMaster
                  ? materialMaster.map((item, index) => {
                      return (
                        <option key={index} value={item._id}>
                          {item.name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>

            <div>
              {initialValue?.materialMasters?.length > 0 ? (
                <>
                  <label> Selected Materials :</label>: &nbsp;
                  <ul className="ms-2">
                    {initialValue?.materialMasters?.map((item, i) => {
                      return (
                        <span key={i}>
                          <li
                            style={{
                              width: "400px",
                              display: "flex",
                              justifyContent: "space-between",
                              margin: "15px 0px",
                            }}
                          >
                            {" "}
                            {item.name}{" "}
                            <img
                              src={crossImage}
                              onClick={() => handleMaterialRemove(item._id)}
                              style={{
                                border: "1px solid red",
                                padding: "0px 8px",
                                borderRadius: "5px",
                              }}
                            />
                          </li>
                        </span>
                      );
                    })}
                  </ul>
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="center-heading">
              <button
                className="button-search"
                onClick={handleEditSurfaceTreatment}
              >
                {" "}
                Update{" "}
              </button>
            </div>
            {error ? (
              <div
                style={{
                  color: "red",
                  fontSize: "15px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {error}
              </div>
            ) : null}
          </div>
        ) : (
          <SkeltonLoadingComponent />
        )}
      </div>
    </div>
  );
}
export default EditSurfaceTreatmentMaster;
