import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import { toast } from "react-toastify";
import { updateUser } from '../../utils/actions/allactions';
import { validateAdmin} from "../../utils/helper";
import useDocumentTitle from "../../utils/useDocumentTitle";

function EditAdminUser() {
    let navigate = useNavigate();
    useDocumentTitle('Edit Admin User')
    const token = localStorage.getItem('Token');
    const [adminValue, setAdminValue] = useState(JSON.parse(localStorage.getItem('adminUserToBeEdited')));

    const [error, setError] = useState("");

    useEffect(() => {
        validateAdmin(token, navigate);
        setAdminValue({ ...adminValue, email: adminValue.email.substring(0, adminValue.email.length - 12) })
    }, []);

    const handleEditAdminUser = async () => {
        setError("");

        if (!adminValue.firstName ||
            !adminValue.lastName ||
            !adminValue.email ||
            !adminValue.accountStatus
        ) {
            setError("Enter valid values for all the field")
        }
        else {
            const data = { ...adminValue }
            const letters = /^[A-Za-z]+$/;
            if (data.email.match(letters)) {
                setError("");
                // console.log(data)
                const finalData = { ...adminValue, email: adminValue.email + "@8xparts.com" }
                // console.log(finalData)
                const createdUser = await updateUser(token, finalData);
                if (createdUser.data.message === "Error creating a user") {
                    toast.error('Error creating a user');
                } else {
                    navigate('/admin/admin-users');
                    toast.success("Admin user updated successfully")
                }
            }
            else {
                setError("Enter only string email");
            }
        }
    }

    return (
        <div className="admin-container">
            <Sidebar />
            <div className="right-container">
                <div className="center-heading">
                    <h1>  Edit Admin Users  </h1>
                    <div className="admin-user-container">
                        <div className="admin-user-header">
                            User Information
                        </div>
                        <div className="admin-user-body">
                            <div className="admin-user-name">
                                <input
                                    type="text"
                                    placeholder="First name *"
                                    value={adminValue.firstName}
                                    onChange={(e) => { setAdminValue({ ...adminValue, firstName: e.target.value }) }}
                                />
                                <input
                                    type="text"
                                    placeholder="Surname *"
                                    value={adminValue.lastName}
                                    onChange={(e) => { setAdminValue({ ...adminValue, lastName: e.target.value }) }}
                                />
                            </div>

                            <div className="admin-user-email">
                                <input
                                    type="text"
                                    placeholder="Primary email *"
                                    value={adminValue.email}
                                    onChange={(e) => { setAdminValue({ ...adminValue, email: e.target.value }) }}
                                />
                                <input className="disabled-email" placeholder="@8xparts.com" disabled />
                            </div>

                            <div className="admin-user-email">
                                <p>This will be the email that the user signs in with</p>
                            </div>

                            <div className="admin-user-email">
                                <div className="admin-status-div">
                                    <label htmlFor="admin-status" >Admin Status</label>: &nbsp;
                                    <input
                                        name="admin-status"
                                        type="radio"
                                        value="Active"
                                        checked={adminValue.accountStatus === 'Active' ? true : ''}
                                        onChange={(e) => { setAdminValue({ ...adminValue, accountStatus: e.target.value }) }}
                                    />Active

                                    <input
                                        name="admin-status"
                                        type="radio"
                                        value="Inactive"
                                        checked={adminValue.accountStatus === 'Inactive' ? true : ''}
                                        onChange={(e) => { setAdminValue({ ...adminValue, accountStatus: e.target.value }) }}
                                    />Inactive
                                </div>

                            </div>
                        </div>
                    </div>
                    <button className="button-add" onClick={handleEditAdminUser}>Edit Admin User</button>

                    {
                        error ?
                            <div style={{ color: 'red', fontSize: '15px', textAlign: 'center', marginTop: '10px' }}>{error}</div>
                            : null
                    }
                </div>
            </div>
        </div>

    );
}

export default EditAdminUser