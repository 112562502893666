import React, { useEffect, useReducer } from "react";
import { Tabs, Tab, Form, Button, Row, Col, Pagination } from "react-bootstrap";
import Header from "../../components/Header/header";
import { Loading } from "../../components/Loading";
import jwt_decode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import search from "../../images/search.svg";
import secure from "../../images/secure-icon.svg";
import gearimg from "../../images/dash-img.png";
import { USER_ACTIONS, useUserContext } from "../../context/user.context";
import activeOrdersImg from "../../images/activeOrders.svg";
import cancelledImage from "../../images/unapprovedQuotes.svg";
import completedImage from "../../images/closedOrders.svg";
import MyOrdersTable from "../../components/MyOrdersTable/MyOrdersTable";
import { fetchOrdersForParameters } from "../../utils/actions/userActions";


const MyOrders = () => {
  let navigate = useNavigate();
  let user_id = localStorage.getItem("_id");
  let loggedInUserToken = localStorage.getItem("Token");
  let userName = localStorage.getItem("firstName");
  const initialMyOrdersState = {
    ordersToShow: [],
    loading: false,
    activeTab: "inprogress",
    pageNum: 1,
    isLoading: false,
    searchQuery: "",
    poNumberSearchQuery: "",
    createdFromFilter: "",
    createdToFilter: "",
    partNameFilter: "",
    totalPages: 0,
    completedOrdersCount: 0,
    inProgressOrdersCount: 0,
    totalNumberOfOrders: 0,
  };

  const MY_ORDERS_ACTIONS = {
    UPDATE_ACTIVE_TAB: "update-active-tab",
    UPDATE_ORDERS_TO_SHOW: "update-orders-to-show",
    UPDATE_SEARCH_QUERY: "update-search-query",
    UPDATE_PO_NUMBER_SEARCH_QUERY: "update-po-number-search-query",
    UPDATE_LOADING: "update-loading",
    UPDATE_SELECTED_SHIP_MAP: "update-selected-ship-map",
    UPDATE_TOTAL_PAGES: "update-total-pages",
    UPDATE_PAGE_NUM: "update-page-num",
    UPDATE_TOTAL_NUMBER_ORDERS: "update-total-number-orders",
    UPDATE_DATA: "update-data",
  };

  const myOrdersReducer = (state, action) => {
    switch (action.type) {
      case MY_ORDERS_ACTIONS.UPDATE_ORDERS_TO_SHOW:
        return { ...state, ordersToShow: action.payload };
      case MY_ORDERS_ACTIONS.UPDATE_ACTIVE_TAB:
        return { ...state, activeTab: action.payload };
      case MY_ORDERS_ACTIONS.UPDATE_SEARCH_QUERY:
        return { ...state, searchQuery: action.payload };
        case MY_ORDERS_ACTIONS.UPDATE_PO_NUMBER_SEARCH_QUERY:
        return { ...state, poNumberSearchQuery: action.payload };
      case MY_ORDERS_ACTIONS.UPDATE_LOADING:
        return { ...state, isLoading: action.payload };
      case MY_ORDERS_ACTIONS.UPDATE_TOTAL_PAGES:
        return { ...state, totalPages: action.payload };
      case MY_ORDERS_ACTIONS.UPDATE_PAGE_NUM:
        return { ...state, pageNum: action.payload };
      case MY_ORDERS_ACTIONS.UPDATE_TOTAL_NUMBER_ORDERS:
        return { ...state, totalNumberOfOrders: action.payload };
      case MY_ORDERS_ACTIONS.UPDATE_DATA:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };
  const [myOrdersState, myOrdersDispatch] = useReducer(
    myOrdersReducer,
    initialMyOrdersState
  );

  const { state, dispatch } = useUserContext();

  useEffect(() => {
    let decode;
    if (!loggedInUserToken) {
      navigate("/login");
    } else {
      decode = jwt_decode(loggedInUserToken);
      if (!decode) {
        navigate("/login");
        toast.error("Invalid Token found!");
      } else if (decode) {
        if (decode.userType == "user") {
        } else if (decode.userType == "admin") {
          navigate("/admin");
        } else {
          navigate("/login");
        }
      }
    }
  }, []);

  useEffect(() => {
    try {
      fetchOrdersForUser();
    } catch (err) {
      myOrdersDispatch({
        type: MY_ORDERS_ACTIONS.UPDATE_LOADING,
        payload: false,
      });
      console.log("Err", err);
    }
  }, [
    myOrdersState.pageNum,
    myOrdersState.activeTab,
    myOrdersState.searchQuery,
    myOrdersState.poNumberSearchQuery,
    myOrdersState.createdFromFilter,
    myOrdersState.createdToFilter,
    myOrdersState.partNameFilter,
    state.orderFilterActive,
  ]);

  const handleOrdersTypeChange = (e) => {
    try {
      if (e.target.value == "my-order") {
        dispatch({
          type: USER_ACTIONS.UPDATE_ORDER_ACTIVE_FILTER,
          payload: "my-order",
        });
      } else if (e.target.value == "team-order") {
        dispatch({
          type: USER_ACTIONS.UPDATE_ORDER_ACTIVE_FILTER,
          payload: "team-order",
        });
      } else if (e.target.value == "all-order") {
        dispatch({
          type: USER_ACTIONS.UPDATE_ORDER_ACTIVE_FILTER,
          payload: "all-order",
        });
      }
      myOrdersDispatch({
        type: MY_ORDERS_ACTIONS.UPDATE_PAGE_NUM,
        payload: 1,
      });
    } catch (err) {
      console.log(
        "err in my quote 570 on" +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a a"),
        err
      );
    }
  };

  const handleStartDateChange = (e) => {
    let data = { createdFromFilter: e.target.value };
    myOrdersDispatch({ type: MY_ORDERS_ACTIONS.UPDATE_DATA, payload: data });
  };

  const handleEndDateChange = (e) => {
    let data = { createdToFilter: e.target.value };
    myOrdersDispatch({ type: MY_ORDERS_ACTIONS.UPDATE_DATA, payload: data });
  };

  const handleResetFilter = () => {
    let data = {
      createdToFilter: "",
      createdFromFilter: "",
      searchQuery: "",
      partNameFilter: "",
      poNumberSearchQuery: "",
    };
    myOrdersDispatch({ type: MY_ORDERS_ACTIONS.UPDATE_DATA, payload: data });
  };

  const fetchOrdersForUser = async () => {
    try {
      myOrdersDispatch({
        type: MY_ORDERS_ACTIONS.UPDATE_LOADING,
        payload: true,
      });
      let data = {
        pageNum: myOrdersState.pageNum,
        activeTab: myOrdersState.activeTab,
        filterProjectName: myOrdersState.searchQuery,
        filterPONumber: myOrdersState.poNumberSearchQuery,
        orderActiveFilter: state.orderFilterActive,
        userId: user_id,
        createdFromFilter: myOrdersState.createdFromFilter,
        createdToFilter: myOrdersState.createdToFilter,
        partNameFilter: myOrdersState.partNameFilter,
      };
      const response = await fetchOrdersForParameters(loggedInUserToken, data, true);
      if (response.status) {
        myOrdersDispatch({
          type: MY_ORDERS_ACTIONS.UPDATE_ORDERS_TO_SHOW,
          payload: response.data.ordersData,
        });

        myOrdersDispatch({
          type: MY_ORDERS_ACTIONS.UPDATE_TOTAL_PAGES,
          payload: response.data.totalPages,
        });

        myOrdersDispatch({
          type: MY_ORDERS_ACTIONS.UPDATE_TOTAL_NUMBER_ORDERS,
          payload: response.data.totalNumberOfOrders,
        });

        let data = {
          orderCount:
            response.data.inProgressCount + response.data.completedCount,
          inProgressOrdersCount: response.data.inProgressCount,
          completedOrdersCount: response.data.completedCount,
        };

        dispatch({ type: USER_ACTIONS.UPDATE_ORDER_COUNT, payload: data });
      }

      myOrdersDispatch({
        type: MY_ORDERS_ACTIONS.UPDATE_LOADING,
        payload: false,
      });
    } catch (error) {
      myOrdersDispatch({
        type: MY_ORDERS_ACTIONS.UPDATE_LOADING,
        payload: false,
      });
      console.log("Error", error);
    }
  };

  const items = [];
  for (let number = 1; number <= myOrdersState.totalPages; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === myOrdersState.pageNum}
        onClick={() => handleActivePage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  let handleActivePage = async (value) => {
    let pageNum = value || 1;
    myOrdersDispatch({
      type: MY_ORDERS_ACTIONS.UPDATE_PAGE_NUM,
      payload: pageNum,
    });
  };

  return (
    <div className="content-wrapper">
      <Header activeTab="/my-orders" />
      {loggedInUserToken ? (
        <div>
          <div style={{ position: "sticky", top: "64px", zIndex: 1 }}>
            <div
              className="intro-bar"
              style={{ padding: '10px', justifyContent: 'center' }}
            >
              <div className="updated-search-bar">
                <div style={{ width: '240px' }}>
                  <Form.Control
                    type="text"
                    placeholder="Search by project name"
                    value={myOrdersState.searchQuery}
                    onChange={(e) =>
                      myOrdersDispatch({
                        type: MY_ORDERS_ACTIONS.UPDATE_SEARCH_QUERY,
                        payload: e.target.value,
                      })
                    }
                  />
                  <Button>
                    <img src={search} />
                  </Button>
                </div>

                <div style={{ width: '240px' }}>
                  <Form.Control
                    type="text"
                    placeholder="Search by PO Number"
                    value={myOrdersState.poNumberSearchQuery}
                    onChange={(e) =>
                      myOrdersDispatch({
                        type: MY_ORDERS_ACTIONS.UPDATE_PO_NUMBER_SEARCH_QUERY,
                        payload: e.target.value,
                      })
                    }
                  />
                  <Button>
                    <img src={search} />
                  </Button>
                </div>

                <div style={{ width: '240px' }}>
                  <Form.Control
                    type="text"
                    placeholder="Filter by part name"
                    value={myOrdersState.partNameFilter}
                    onChange={(e) =>
                      myOrdersDispatch({
                        type: MY_ORDERS_ACTIONS.UPDATE_DATA,
                        payload: { partNameFilter: e.target.value },
                      })
                    }
                  />
                  <Button>
                    <img src={search} />
                  </Button>
                </div>

                <div style={{ border: '0px', marginLeft: '-10px', marginRight: '0px' }}>
                  {/* <div className="search-bar"> */}
                  <Form.Select
                    onChange={handleOrdersTypeChange}
                    style={{
                      border: '1px solid #eaedf0',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '10px',
                      borderRadius: '3px',
                      minWidth: '240px',
                    }}
                  >
                    <option value="all-order">All Projects</option>
                    <option value="my-order">My Projects</option>
                    <option value="team-order">Team Projects</option>
                  </Form.Select>
                  {/* </div> */}
                </div>

                <div>
                  <p>Created From</p>
                  <Form.Group>
                    <Form.Control
                      type="date"
                      value={myOrdersState.createdFromFilter || ""}
                      onChange={handleStartDateChange}
                    />
                  </Form.Group>
                </div>

                <div>
                  <p>Created To</p>
                  <Form.Group>
                    <Form.Control
                      type="date"
                      value={myOrdersState.createdToFilter || ""}
                      onChange={handleEndDateChange}
                    />
                  </Form.Group>
                </div>


                <div style={{ border: 0 }}>
                  <Button
                    style={{
                      backgroundColor: "#2B96DC",
                      fontSize: "14px",
                      height: "38px",
                      width: '100%'
                    }}
                    onClick={handleResetFilter}
                  >
                    Reset Filters
                  </Button>


                </div>



              </div>

            </div>
            <div className="tabsContainer">
              <Tabs
                id="controlled-tab-example"
                activeKey={myOrdersState.activeTab}
                onSelect={(k) => {
                  myOrdersDispatch({
                    type: MY_ORDERS_ACTIONS.UPDATE_ACTIVE_TAB,
                    payload: k,
                  });
                  myOrdersDispatch({
                    type: MY_ORDERS_ACTIONS.UPDATE_PAGE_NUM,
                    payload: 1,
                  });
                }}
                className="qoute-version-tabs"
                style={{ marginBottom: "-10px" }}
              >
                <Tab
                  eventKey={"inprogress"}
                  title={
                    <span className="tab-with-image">
                      <img
                        className="tab-image"
                        src={activeOrdersImg}
                        alt="inprogress-img"
                      />
                      {`In Progress (${myOrdersState.activeTab === 'inprogress'?myOrdersState?.ordersToShow?.length: state.inProgressOrdersCount || 0})`}
                    </span>
                  }
                ></Tab>

                <Tab
                  eventKey={"completed"}
                  title={
                    <span className="tab-with-image">
                      <img
                        className="tab-image"
                        src={completedImage}
                        alt="completed-img"
                      />
                      {`Completed( ${myOrdersState.activeTab === 'completed'?myOrdersState?.ordersToShow?.length: state.completedOrdersCount || 0} )`}
                    </span>
                  }
                ></Tab>

                <Tab
                  eventKey={"cancelled"}
                  title={
                    <span className="tab-with-image">
                      <img
                        className="tab-image"
                        src={cancelledImage}
                        alt="cancelled-img"
                      />
                      {`Cancelled`}
                    </span>
                  }
                ></Tab>
              </Tabs>

            </div>
          </div>
          <div className="dashboard-holder">
            <div className="dashboard-in" style={{ padding: "15px" }}>
              {myOrdersState.isLoading ? (
                <>
                  <Loading />
                </>
              ) : (
                <>


                  {(myOrdersState.activeTab === 'inprogress' && state.inProgressOrdersCount === 0) ?
                    <>
                      <div>
                        <div className="dashboard-in" style={{boxShadow:'0px 0px 0px'}} >
                          <div className="dash-left" style={{ textAlign: 'left' }}>
                            <div className="ds-heading">
                              Hi {userName},
                            </div>
                            <div className="ds-heading">
                              It looks like you currently don't have any orders in production.
                            </div>

                            <div className="ds-btn">
                              {" "}
                              <Link to="/project-name" className="quote-btn">
                                Click here to start your new project!
                              </Link>{" "}
                            </div>


                            <p>
                              <span>
                                <img src={secure} />
                              </span>{" "}
                              Your uploads are secure with us.
                            </p>

                          </div>
                          <div className="dash-img">
                            {" "}
                            <img src={gearimg} />{" "}
                          </div>
                        </div>


                      </div>
                    </> : <>
                      <div style={{ marginLeft: "auto", fontSize: "12px" }}>
                        Showing {(myOrdersState.pageNum - 1) * 10 + 1} -{" "}
                        {Math.min(
                          myOrdersState.pageNum * 10,
                          myOrdersState.totalNumberOfOrders
                        )}{" "}
                        of {myOrdersState.totalNumberOfOrders}
                      </div>
                      <MyOrdersTable
                        ordersList={myOrdersState.ordersToShow}
                        activeTab={myOrdersState.activeTab}
                        isLoading={myOrdersState.isLoading}
                        myOrdersDispatch={myOrdersDispatch}
                        MY_ORDERS_ACTIONS={MY_ORDERS_ACTIONS}
                        myOrdersState={myOrdersState}
                        fetchOrdersForUser={fetchOrdersForUser}
                      />
                    </>}


                </>
              )}

              {myOrdersState?.ordersToShow?.length > 0 ? <> 
              <div style={{ margin: "10px 20px 10px auto" }}>
                <Pagination>
                  <Pagination.Prev
                    onClick={() => handleActivePage(myOrdersState.pageNum - 1)}
                    disabled={myOrdersState.pageNum === 1}
                  />
                  {items}
                  <Pagination.Next
                    onClick={() => handleActivePage(myOrdersState.pageNum + 1)}
                    disabled={
                      myOrdersState.pageNum === myOrdersState.totalPages
                    }
                  />
                </Pagination>
              </div></> : <></>}

            </div>
          </div>
        </div>
      ) : (
        <></>
      )}


      <div className="dashboard-holder">
        <div className="sm-container">
          <div className="privacy-text" style={{ marginTop: '-30px' }}>
            <strong>Privacy:</strong> 8xParts 3D secures all uploaded files,
            protecting your intellectual property. If you can’t upload your
            files at the moment, use our sample part to try out our instant
            quoting and DfM tools or contact us on <a href="mailto: info@8xparts.com">info@8xparts.com</a> to sign an NDA.
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyOrders;
