import React, { useEffect, useState } from 'react';
import AdminDashboardComponents from '../../components/HigherOrderComponents/AdminDashboardComponents';
import { CrossIcon } from '../../components/Icons';
import { toast } from 'react-toastify';
import { deleteMachine, getAllCncMachines, upsertMachine } from '../../utils/actions/adminActions';
import { useNavigate } from 'react-router-dom';

const MultistepForm = () => {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    cnc_machine: '',
    cnc_max_platform_length_mm: '',
    cnc_max_platform_width_mm: '',
    cnc_max_platform_height_mm: '',
    cnc_max_paltform_diameter_mm: '',
    cnc_single_part_max_wt_kg: '',
  });

  const [allMachineData, setAllMachineData] = useState([]);
  const [isReload, setIsReload] = useState(false);

  const navigate = useNavigate()


  useEffect(() => {
    (async () => {
      try {
        const response = await getAllCncMachines();
        if (!response || !response.success) {
          throw new Error();
        }
        const { data } = response;
        setAllMachineData(data);
      } catch (error) {
        toast.error("Unable To Fetch Machine At The Moment");
      }
    })()

  }, [isReload])


  console.log("All Machine Data :", allMachineData);

  function resetData() {
    setFormData({
      cnc_machine: '',
      cnc_max_platform_length_mm: '',
      cnc_max_platform_width_mm: '',
      cnc_max_platform_height_mm: '',
      cnc_max_paltform_diameter_mm: '',
      cnc_single_part_max_wt_kg: '',
    })
  }

  const handleFieldChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    if (!formData.cnc_machine) {
      toast.error("Machine Name Is Required");
      return;
    }
    if (!formData.cnc_max_platform_length_mm) {
      toast.error("Max Platform Length is Required");
      return;
    }
    if (!formData.cnc_max_platform_width_mm) {
      toast.error("Max Platform Width is Required");
      return;
    }
    if (!formData.cnc_max_platform_height_mm) {
      toast.error("Max Platform Height is Required");
      return;
    }
    if (!formData.cnc_max_paltform_diameter_mm) {
      toast.error("Max Platform Diameter is Required");
      return;
    }
    if (!formData.cnc_single_part_max_wt_kg) {
      toast.error("Single Part Max Weight is Required ");
      return;
    };
    try {
      const response = await upsertMachine(formData);
      if (!response || !response.success) {
        throw new Error();
      }
      toast.success("Machine Added Succesfully");


    } catch (error) {
      toast.error("Unable To Add Machine At The Moment");
    } finally {
      resetData();
      setShowModal(false);
      setIsReload((pre => !pre));
    }

  };

  const handleDelete = async (item) => {
    try {
      console.log(item);
      const userResposne = confirm("Are You Sure You Want To Delete ");
      if (!userResposne) return;
      const id = item?._id;
      const response = await deleteMachine(id);
      if (!response || !response.success) {
        throw new Error();
      }
      toast.success("Machine Deleted Succesfully");
      setIsReload((pre) => !pre);

    } catch (error) {
      toast.error("Unable To Delete Item At The Moment");
    }
  };

  const handleUpdate = async (item) => {
    // console.log(item);
    if (item?.cnc_materials) {
      delete item.cnc_materials
    }
    setFormData(item);
    setShowModal(true);
  }



  return (
    <div className="w-screen mx-auto mt-10 relatives">
      <h1 className='text-center font-bold text-2xl'>Machines</h1>
      <button
        onClick={() => {
          resetData();
          setShowModal(true);
        }}
        className="bg-primaryColor absolute right-5 top-4 text-white px-4 py-2 rounded"
      >
        Create New
      </button>
      <table className="table-auto w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden mt-5">
        <thead className="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
          <tr>
            <th className="px-4 py-2 border border-gray-200">Machine Name</th>
            <th className="px-4 py-2 border border-gray-200">Max Length (mm)</th>
            <th className="px-4 py-2 border border-gray-200">Max Width (mm)</th>
            <th className="px-4 py-2 border border-gray-200">Max Height (mm)</th>
            <th className="px-4 py-2 border border-gray-200">Max Diameter (mm)</th>
            <th className="px-4 py-2 border border-gray-200">Single Part Max Weight (kg)</th>
            <th className="px-4 py-2 border border-gray-200">Config Materials</th>
            <th className="px-4 py-2 border border-gray-200"> Cost</th>
            <th className="px-4 py-2 border border-gray-200">Actions</th>
          </tr>
        </thead>
        <tbody>
          {allMachineData.length ? (
            allMachineData.map((item, index) => (
              <tr
                key={index}
                className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                  } hover:bg-gray-200 transition duration-200`}
              >
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_machine || 'NA'}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_max_platform_length_mm || 'NA'}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_max_platform_width_mm || 'NA'}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_max_platform_height_mm || 'NA'}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_max_paltform_diameter_mm || 'NA'}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  {item.cnc_single_part_max_wt_kg || 'NA'}
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  <button
                    onClick={() => navigate('/admin/materials-cnc-select', { state: item })}
                    className="bg-yellow-500 text-white px-2 py-1 rounded mx-1"
                  >
                    Select
                  </button>
                </td>
                <td className="px-4 py-2 border border-gray-200">
                  <button
                    onClick={() => navigate("/admin/machine-options-cnc", { state: item })}
                    className="bg-yellow-500 text-white px-2 py-1 rounded mx-1"
                  >
                    Config
                  </button>
                </td>
                <td className="px-4 py-2 border border-gray-200 flex">
                  <button
                    onClick={() => handleUpdate(item)}
                    className="bg-yellow-500 text-white px-2 py-1 rounded mx-1"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => handleDelete(item)}
                    className="bg-red-500 text-white px-2 py-1 rounded mx-1"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan="4"
                className="px-4 py-2 text-center text-gray-500 border border-gray-200"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center ">
          <div className="bg-white w-3/4 p-6 rounded shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Create New Machine</h2>


            <div className='relative'>
              <div onClick={() => setShowModal(false)} className='absolute right-3 -top-12'>
                <CrossIcon />
              </div>
              {step === 1 && (
                <div className="grid grid-cols-2 gap-4 ">

                  <div>
                    <label className="block text-sm font-medium mb-1">Machine Name:</label>
                    <input
                      type="text"
                      value={formData.cnc_machine}
                      onChange={(e) => handleFieldChange('cnc_machine', e.target.value)}
                      className="border p-2 w-full rounded"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">Max Platform Length (mm):</label>
                    <input
                      type="number"
                      value={formData.cnc_max_platform_length_mm}
                      onChange={(e) => handleFieldChange('cnc_max_platform_length_mm', e.target.value)}
                      className="border p-2 w-full rounded"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">Max Platform Width (mm):</label>
                    <input
                      type="number"
                      value={formData.cnc_max_platform_width_mm}
                      onChange={(e) => handleFieldChange('cnc_max_platform_width_mm', e.target.value)}
                      className="border p-2 w-full rounded"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">Max Platform Height (mm):</label>
                    <input
                      type="number"
                      value={formData.cnc_max_platform_height_mm}
                      onChange={(e) => handleFieldChange('cnc_max_platform_height_mm', e.target.value)}
                      className="border p-2 w-full rounded"
                    />
                  </div>
                </div>
              )}

              {step === 2 && (
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">Max Platform Diameter (mm):</label>
                    <input
                      type="number"
                      value={formData.cnc_max_paltform_diameter_mm}
                      onChange={(e) => handleFieldChange('cnc_max_paltform_diameter_mm', e.target.value)}
                      className="border p-2 w-full rounded"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">Single Part Max Weight (kg):</label>
                    <input
                      type="number"
                      value={formData.cnc_single_part_max_wt_kg}
                      onChange={(e) => handleFieldChange('cnc_single_part_max_wt_kg', e.target.value)}
                      className="border p-2 w-full rounded"
                    />
                  </div>
                </div>
              )}

              <div className="flex justify-between mt-6">
                {step > 1 && (
                  <button
                    onClick={handlePrevious}
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                  >
                    Previous
                  </button>
                )}
                {step < 2 ? (
                  <button
                    onClick={handleNext}
                    className="bg-primaryColor text-white px-4 py-2 rounded"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    onClick={handleSubmit}
                    className="bg-primaryColor text-white px-4 py-2 rounded"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboardComponents(MultistepForm);
