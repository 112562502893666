import React, {
  useContext,
} from "react";

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Context } from "./Context"

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function numberWithCommas(x) {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export default function AnalysisResultsPanel(props) {
  const {state} = useContext(Context);

  const faces = state.files[state.activeFileIndex]?.properties?.analysisResults?.faces || {};
  const volume = state.files[state.activeFileIndex]?.properties?.analysisResults?.volume;
  const aabb = state.files[state.activeFileIndex]?.properties?.aabb;

  return (
    <div
      style={{
        ...props.style,
        margin: "16px",
      }}
    >
      <TableContainer
        component={Paper}
      >
        <Table size="small">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                Face Type
              </StyledTableCell>
              <StyledTableCell align="center" colSpan={2}>
                Area
              </StyledTableCell>
              <StyledTableCell align="right">
                Count
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {
              Object.keys(faces).map(f => (
                <StyledTableRow key={f}>
                  <StyledTableCell
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {f.toLowerCase()}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    style={{
                      padding: 0,
                    }}
                  >
                    {numberWithCommas(faces[f].area.toFixed(2))}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    style={{
                      padding: 0,
                    }}
                  >
                    mm²
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {numberWithCommas(faces[f].count)}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            }
          </TableBody>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                Totals
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{
                  padding: 0,
                }}
              >
                {numberWithCommas(Object.values(faces).reduce((n, e) => e.area+n, 0).toFixed(2))}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  padding: 0,
                }}
              >
                mm²
              </StyledTableCell>
              <StyledTableCell align="right">
                {Object.values(faces).reduce((n, e) => e.count+n, 0)}
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer
        component={Paper}
        style={{
          marginTop: "32px",
        }}
      >
        <Table size="small" >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                Solid Volume
              </StyledTableCell>
              <StyledTableCell align="right">
                Value
              </StyledTableCell>
              <StyledTableCell align="left">
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody
            style={{
              display: volume ? "table-row-group" : "none",
            }}
          >
            <StyledTableRow>
              <StyledTableCell
                style={{
                  textTransform: "capitalize",
                }}
              >
                Total Volume
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{
                  padding: 0,
                }}
              >
                {volume ? numberWithCommas(volume.toFixed(2)) : null}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  padding: 0,
                }}
              >
                mm³
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer
        component={Paper}
        style={{
          marginTop: "32px",
        }}
      >
        <Table size="small">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                Bounding Box Side
              </StyledTableCell>
              <StyledTableCell align="right">
                Length
              </StyledTableCell>
              <StyledTableCell align="left">
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody
            style={{
              display: aabb ? "table-row-group" : "none",
            }}
          >
            <StyledTableRow>
              <StyledTableCell
                style={{
                  textTransform: "capitalize",
                }}
              >
                X
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{
                  padding: 0,
                }}
              >
                {aabb ? numberWithCommas((aabb.max.x-aabb.min.x)?.toFixed(2)) : undefined}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  padding: 0,
                }}
              >
                mm
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                style={{
                  textTransform: "capitalize",
                }}
              >
                Y
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{
                  padding: 0,
                }}
              >
                {aabb ? numberWithCommas((aabb.max.y-aabb.min.y)?.toFixed(2)) : undefined}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  padding: 0,
                }}
              >
                mm
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                style={{
                  textTransform: "capitalize",
                }}
              >
                Z
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{
                  padding: 0,
                }}
              >
                {aabb ? numberWithCommas((aabb.max.z-aabb.min.z)?.toFixed(2)) : undefined}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  padding: 0,
                }}
              >
                mm
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}