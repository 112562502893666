import React, {
  useMemo,
  useRef,
  useState,
} from "react";

import { Text } from "drei";

import {
  Color,
  Vector3,
} from "three";

import {
  useFrame,
  useThree,
  useUpdate,
} from "react-three-fiber";

import Line from "./Line";

const calcOutsideLength = (camera) => 35 / camera.zoom;
const calcCenterPoint = (point1Vec, point2Vec) => point1Vec?.clone().addScaledVector(point2Vec.clone().sub(point1Vec), 0.5);
const calcDistance = (point1Vec, point2Vec) => point1Vec?.distanceTo(point2Vec);
const calcDirection1 = (point1Vec, point2Vec) => point2Vec?.clone().sub(point1Vec).normalize();
const calcDirection2 = direction1 => direction1?.clone().multiplyScalar(-1);
const calcInsideMeasure = (distance, camera) => distance * camera.zoom > 50;
const calcArrow1 = (direction1, direction2, distance, insideMeasure, outsideLength) => insideMeasure
  ? [direction1, new Vector3(0, 0, 0)]
  : [direction2, direction1.clone().multiplyScalar(distance/2+outsideLength)];
const calcArrow2 = (direction1, direction2, distance, insideMeasure, outsideLength) => insideMeasure
  ? [direction2, new Vector3(0, 0, 0)]
  : [direction1, direction2.clone().multiplyScalar(distance/2+outsideLength)];
const calcMeasureText = (distance, precision, units) => (Math.round(distance*Math.pow(10, precision || 2))/Math.pow(10, precision || 2)).toString()+(units || "mm");
const calcLine = (direction1, direction2, distance, outsideLength) => [direction1.clone().multiplyScalar(distance/2+outsideLength), direction2.clone().multiplyScalar(distance/2+outsideLength)];

const Measure = React.forwardRef(({units, point1, point2, color, precision, scale, linewidth = 1, textAnchorY = "top"}, ref) => {
  const point1Vec = useMemo(() => Array.isArray(point1)
    ? new Vector3(point1[0], point1[1], point1[2])
    : point1,
    [point1]);
  const point2Vec = useMemo(() => Array.isArray(point2)
    ? new Vector3(point2[0], point2[1], point2[2])
    : point2,
    [point2]);

  const {camera} = useThree();
  const colColor = useMemo(() => typeof(color) === "string" ? new Color(color) : (color || new Color(0.025, 0.025, 0.025)), [color]);

  const arrow1Ref = useUpdate(arrow => {
    arrow.setColor(colColor.clone().offsetHSL(0, -0.3, 0.15));
    arrow.getObjectByProperty("type", "Line").material.linewidth = linewidth;
  }, [colColor, scale, linewidth]);

  const arrow2Ref = useUpdate(arrow => {
    arrow.setColor(colColor.clone().offsetHSL(0, -0.3, 0.15));
    arrow.getObjectByProperty("type", "Line").material.linewidth = linewidth;
  }, [colColor, scale, linewidth]);

  const linePoint1 = useRef();
  const linePoint2 = useRef();
  const [insideMeasure, setInsideMeasure] = useState();
  const mainRef = useRef();
  const textRef = useRef();
  useFrame(() => {
    const scaleFac = 1 / camera.zoom * 15;
    if(textRef.current) {
      textRef.current.rotation.copy(camera.rotation);
      textRef.current.material.depthTest = false;
      textRef.current.scale.setScalar(scaleFac);
    }

    const headLength = scaleFac;
    const headWidth = 0.4 * headLength;
    const outsideLength = calcOutsideLength(camera);
    const p1 = point1Vec.current || point1Vec;
    const p2 = point2Vec.current || point2Vec;
    if(!p1.isVector3 || !p2.isVector3) {
      mainRef.current.visible = false;
      return;
    }
    mainRef.current.visible = true;
    const centerPoint = calcCenterPoint(p1, p2);
    const distance = calcDistance(p1, p2);
    const direction1 = calcDirection1(p1, p2);
    const direction2 = calcDirection2(direction1);
    setInsideMeasure(calcInsideMeasure(distance, camera));
    const [arrow1Dir, arrow1Pos] = calcArrow1(direction1, direction2, distance, insideMeasure, outsideLength);
    const [arrow2Dir, arrow2Pos] = calcArrow2(direction1, direction2, distance, insideMeasure, outsideLength);
    mainRef.current.position.copy(centerPoint);
    arrow1Ref.current.position.copy(arrow1Pos);
    arrow1Ref.current.setDirection(arrow1Dir);
    arrow2Ref.current.position.copy(arrow2Pos);
    arrow2Ref.current.setDirection(arrow2Dir);

    if(!insideMeasure) {
      const [lp1, lp2] = calcLine(direction1, direction2, distance, outsideLength);
      linePoint1.current = lp1;
      linePoint2.current = lp2;
    }

    const measureText = calcMeasureText(distance, precision, units);
    textRef.current.text = measureText;
    textRef.current.geometry._maxInstanceCount = measureText.length; // fixes bug where text is not updating

    arrow1Ref.current.setLength(insideMeasure ? distance / 2 : outsideLength, headLength, headWidth);
    arrow2Ref.current.setLength(insideMeasure ? distance / 2 : outsideLength, headLength, headWidth);
  });

  return (
    <>
      <group
        ref={ref}
        >
        <group
          ref={mainRef}
        >
          <Text
            ref={textRef}
            color={color || new Color(0.025, 0.025, 0.025)}
            fontSize={1}
            textAlign={"left"}
            anchorX="center"
            anchorY={textAnchorY}
            renderOrder={1}
          >
          </Text>
          <arrowHelper
            args={[]}
            ref={arrow1Ref}
          />
          <arrowHelper
            args={[]}
            ref={arrow2Ref}
          />
          {
            !insideMeasure
            ? (
              <Line
                point1={linePoint1}
                point2={linePoint2}
                color={colColor.clone().offsetHSL(0, -0.3, 0.15)}
                // point1={direction1.clone().multiplyScalar(distance/2+outsideLength)}
                // point2={direction2.clone().multiplyScalar(distance/2+outsideLength)}
              />)
            : null
          }
        </group>
      </group>
    </>
  );
});
export default Measure;
