import React, { useState, useRef, useEffect } from "react";

const DynamicWidthInput = ({ name, value, onChange, className, minWidth = 50 }) => {
    const inputRef = useRef(null);
    const [inputWidth, setInputWidth] = useState(`${minWidth}px`);

    useEffect(() => {
        if (inputRef.current) {
            const tempSpan = document.createElement("span");
            tempSpan.style.visibility = "hidden";
            tempSpan.style.position = "absolute";
            tempSpan.style.whiteSpace = "pre";
            tempSpan.style.font = getComputedStyle(inputRef.current).font;
            tempSpan.textContent = value || "0"; 
            document.body.appendChild(tempSpan);

            const calculatedWidth = Math.max(tempSpan.offsetWidth + 10, minWidth); 
            setInputWidth(`${calculatedWidth}px`);

            document.body.removeChild(tempSpan);
        }
    }, [value, minWidth]);

    return (
        <input
            ref={inputRef}
            name={name}
            type="number"
            value={value}
            onChange={(e) => {
                if (!e.target.value || Number(e.target.value) < 1) {
                    return;
                }
                onChange(e);
            }}
            onWheel={(e) => e.preventDefault()} // Prevent scrolling to change the value
            onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            style={{
                width: inputWidth,
                minWidth: `${minWidth}px`, // Enforce minimum width
                display: "inline-block",
                transition: "width 0.2s ease-in-out",
            }}
            placeholder="Enter a number"
            className={`border h-10 border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 ${className}`}
        />
    );
};

export default DynamicWidthInput;
