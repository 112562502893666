import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import { toast } from "react-toastify";
import { createUser, getAdminUsers } from '../../utils/actions/allactions';
import { validateAdmin } from "../../utils/helper";
import useDocumentTitle from "../../utils/useDocumentTitle";

const AdminUsers = () => {
    let navigate = useNavigate();
    useDocumentTitle('Admin Users')
    const token = localStorage.getItem('Token');
    const [adminUsers, setAdminUsers] = useState([]);

    const [error, setError] = useState("");
    const [adminValue, setAdminValue] = useState({
        firstName: '',
        lastName: '',
        email: '',
        userType: 'admin',
        password: '',
        accountStatus: ''
    });




    const handleAddAdminUser = async () => {
        setError("");

        if (!adminValue.firstName ||
            !adminValue.lastName ||
            !adminValue.email ||
            !adminValue.accountStatus
        ) {
            setError("Enter valid values for all the field")
        }
        else {
            const data = { ...adminValue }
            const letters = /^[A-Za-z]+$/;
            if (data.email.match(letters)) {
                setError("");
                // console.log(data)
                const finalData = { ...adminValue, email: adminValue.email.toLocaleLowerCase() + "@8xparts.com" }
                // console.log(finalData)
                const createdUser = await createUser(token, finalData);
                
                if (createdUser.data.data.code === 11000) {
                    toast.error("Admin user already exists")
                }
                else if (createdUser.data.message === "Error creating a user") {
                    toast.error('Error creating a user');
                } else {
                    setAdminUsers([...adminUsers,createdUser.data.data])
                    toast.success("Admin user added successfully")
                    navigate('/admin/admin-users');
                }
            }
            else {
                setError("Enter only string email");
            }
        }
    }

    const fetchAdminUsers = async () => {
        try {
            const adminUserData = await getAdminUsers(token);
            setAdminUsers(adminUserData);
            
        } catch (error) {
            toast.error(error);
        }
     
    }


    useEffect(() => {
        validateAdmin(token, navigate);
        fetchAdminUsers();
    }, []);


    const handleEditAdminUser = (adminUserToBeEdited) => {
        localStorage.setItem("adminUserToBeEdited", JSON.stringify(adminUserToBeEdited));
        navigate('/admin/edit-admin-user');
    }


    return (
        <div className="admin-container">
            <Sidebar />
            <div className="right-container">
                <div className="center-heading">
                    <h1 className="font-bold text-xl my-2">  Admin Users  </h1>
                    <div className="admin-user-container">
                        <div className="admin-user-header">
                            User Information
                        </div>
                        <div className="admin-user-body">
                            <div className="admin-user-name">
                                <input
                                    type="text"
                                    placeholder="First name *"
                                    onChange={(e) => { setAdminValue({ ...adminValue, firstName: e.target.value }) }}
                                />
                                <input
                                    type="text"
                                    placeholder="Surname *"
                                    onChange={(e) => { setAdminValue({ ...adminValue, lastName: e.target.value }) }}
                                />
                            </div>

                            <div className="admin-user-email">
                                <input
                                    type="text"
                                    placeholder="Primary email *"
                                    onChange={(e) => { setAdminValue({ ...adminValue, email: e.target.value }) }}
                                />
                                <input className="disabled-email" placeholder="@8xparts.com" disabled />
                            </div>

                            <div className="admin-user-email">
                                <p>This will be the email that the user signs in with</p>
                            </div>

                            <div className="admin-user-email">
                                <div className="admin-status-div">
                                    <label htmlFor="admin-status" >Admin Status</label>: &nbsp;
                                    <input
                                        name="admin-status"
                                        type="radio"
                                        value="Active"
                                        onChange={(e) => { setAdminValue({ ...adminValue, accountStatus: e.target.value }) }}
                                    />Active

                                    <input
                                        name="admin-status"
                                        type="radio"
                                        value="Inactive"
                                        onChange={(e) => { setAdminValue({ ...adminValue, accountStatus: e.target.value }) }}
                                    />Inactive
                                </div>

                            </div>
                        </div>
                    </div>
                    <button className="button-add" onClick={handleAddAdminUser}>Add Admin User</button>

                    {
                        error ?
                            <div style={{ color: 'red', fontSize: '15px', textAlign: 'center', marginTop: '10px' }}>{error}</div>
                            : null
                    }
                </div>


                <div className="quotes-table" style={{ marginTop: '50px' }}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col" style={{ cursor: 'pointer' }}>User Name </th>
                                <th scope="col" style={{ cursor: 'pointer' }} >First Name</th>
                                <th scope="col" style={{ cursor: 'pointer' }} >Last name </th>
                                <th scope="col" style={{ cursor: 'pointer' }} >Phone Number </th>
                                <th scope="col" style={{ cursor: 'pointer' }} >Account Status </th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {adminUsers?.length > 0 &&
                                adminUsers.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item.email ? item.email : ''}</td>
                                            <td>{item.firstName ? item.firstName : ''}</td>
                                            <td>{item.lastName ? item.lastName : ''}</td>
                                            <td>{item.phoneNumber ? item.phoneNumber : ''}</td>
                                            <td>{item.accountStatus ? item.accountStatus : ''}</td>
                                            < td > <button className="button-edit" onClick={() => { handleEditAdminUser(item) }}>Edit/Deactivate</button></td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )

}

export default AdminUsers;