import React from "react";
import tickwhite from "../../../images/tick-white.svg";
import { currencyFormatter } from "../../../utils/helper";

const ShowPriceBox = (props) => {
  return (
    <div
      className="pr-radio"
      key={props.value}
      onClick={() => {
        props.updateCommanValueOnChange('selectedShipMethod', props.value);
      }}
    >
      <label className="btn box-in">
        <span className="triangle-0">
          <img src={tickwhite} />
        </span>
      
        <span className="price-sp">{currencyFormatter(props.price?(Number(props.price)).toFixed(2):0)}</span>
        <span className="vld-bx"> {props.business}</span>
        <span className="ship-bx">{props.Shipped}</span>
      </label>
    </div>
  );
};
export default ShowPriceBox;
