export const processType = [
    { id: 1, name: 'CNC Machining' },
    { id: 2, name: '3D Printing' },
    { id: 3, name: 'Sheet Metal Fabrication' },
    { id: 4, name: 'Injection Moulding/Casting' },
    { id: 5, name: 'Complete Assembly' },
    { id: 6, name: 'Vacuum casting' },
    { id: 7, name: 'Jigs and Fixtures' },
    { id: 8, name: 'Off-the-shelf parts (OEM)' },
    { id: 9, name: '3D Printing' },
    { id: 10, name: 'CNC Machining' },
    { id: 11, name: 'Other' }
]
export const commanFields = {
    MaterialType: "",
    surfaceFinish: "",
    SubMaterial: "",
    surfaceTreatment: "",
    tolerance: "",
    partMarking: "",
    Notes: "",
    noOfThread: 0,
    Attachment: [],
    Qty: 1,
    price: 0,
    layer: "",
    XDimensionValue: 0,
    YDimensionValue: 0,
    ZDimensionValue: 0,
    zAxisOfPrinting: 0,
    cylindricalArea: 0,
    planarArea: 0,
    factorForExcessVolume: 0,
    densityOfMaterial: 0,
    costOfMaterial: 0,
    rateOfPrinting: 0,
    rateOfRecoating: 0,
    printingCost: 0,
    algorithmMaterialCost: 0,
    timeTakenForPrintingInHours: 0,
    timeTakenOrRecoatingInHours: 0,
    algorithmOverheadsCost: 0,
    algorithmPrice: 0,
    partMakingFactor: 0,
    finalCost: 0,
    algorithmPrice1: 0,
    algorithmPrice2: 0,
    errors: {
        MaterialType: false,
        surfaceFinish: false,
        SubMaterial: false,
        surfaceTreatment: false,
        tolerance: false,
        partMarking: false,
        Notes: false,
        noOfThread: false,
        Attachment: false,
        SelectProcess: false,
        Qty: false,
        price: false,
        layer: false,
        XDimensionValue: false,
        YDimensionValue: false,
        ZDimensionValue: false,
        zAxisOfPrinting: false,
        cylindricalArea: false,
        planarArea: false,
        factorForExcessVolume: false,
        densityOfMaterial: false,
        costOfMaterial: false,
        rateOfPrinting: false,
        rateOfRecoating: false,
        printingCost: false,
        algorithmMaterialCost: false,
        timeTakenForPrintingInHours: false,
        timeTakenOrRecoatingInHours: false,
        algorithmOverheadsCost: false,
        algorithmPrice: false,
        partMakingFactor: false,
        finalCost: false,
        algorithmPrice1: false,
        algorithmPrice2: false,
    }
}


export const raiseIssueSteps = ["Select parts with issues", "Select issue(s)", "Issue Description"]
export const qualityRelatedIssue = ["Wrong part(s)", "Wrong Dimensions", "Missing Parts", "Wrong Material"]
export const orderRelatedIssue = ["Wrong billing address", "Wrong shipping address", "Missing Parts", "Wrong Material"]
export const quoteVersionDescriptions = [
    { id: 'CC', value: 'Customer Created' },
    { id: 'CE', value: 'Customer Edits' },
    { id: 'DU', value: 'Design Update' },
    { id: 'AR', value: 'Admin Review' },
    { id: 'CU', value: 'Cost Updated' },
    { id: 'RO', value: 'Repeat Order' },
    { id: 'SP', value: 'Spare Parts' },
    { id: 'AC', value: 'Admin Created' },
]

export const pathNameForPublicAccess = ["/Sustainability-CSR", "/privacy-policy", "/terms-of-service", "/view-order-details/"];
export const projectCategory = [
    { name: "3D Printing", path: "/projects/three-d-printing" },
    { name: "Casting", path: "/projects/casting" },
    { name: "CNC Machining", path: "/projects/cnc-machining" },
    { name: "Completed Assembly", path: "/projects/complete-assembly" },
    // {name:"Packaging",path:"/projects/packaging"}
];

export const calculatePriceOfCertifications = (quote) => {
    if (!quote) return 0;
    let { certifications: certificationArray } = quote;
    const { partsData } = quote;
    let totalCost = 0;
    certificationArray = Array.isArray(certificationArray) ? certificationArray : [];
    certificationArray.map((cert) => {
        const { price, certificationType } = cert;
        if (certificationType == 'per order') {
            totalCost += price;
        }
        if (certificationType == 'per line item') {
            totalCost += (partsData.length * price);
        }
    })
    return totalCost;

}

export const MATERAILS_VALID_FOR_ML = ['Aluminium', "Nylon", "PEEK", "PMMA", "Polycarbonate", "PTFE", "Stainless Steel",]

export const miliMeterToInchFactor = 25.4;

export const maxFileUploadSize = 26214400;


export function isValidEmail(email) {

    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return emailRegex.test(email);
}

export function extractNameAndDomain(email) {
    // Validate the email format
    if (typeof email !== 'string' || !email.includes('@')) {
        throw new Error("Invalid email address");
    }

    const username = email.split('@')[0];

    const domain = email.split('@')[1];

    const formattedName = username
        .replace(/[\._-]/g, ' ')  // Replace special characters with spaces
        .split(' ')               // Split into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
        .join(' ');               // Rejoin as a single string

    // Return both the formatted name and domain
    return {
        name: formattedName,
        domain: domain
    };
}


export function generateRandomString(length = 8) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < length; i++) {
        randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomString;
}
export function calculateVolume(x, y, z, type) {

    // if (Number.isNaN(x)) {
    //     x = Number(x);
    // } x
    // if (Number.isNaN(y)) {
    //     y = Number(y);
    // }
    // if (Number.isNaN(z)) {
    //     z = Number(z);
    // }



    // Calculate volume in mm^3
    let volumeMm3 = x * y * z;

    // Return volume in the requested unit
    if (type === "mm") {
        return volumeMm3;
    } else if (type === "in") {
        // Convert volume to cubic inches (1 inch = 25.4 mm)
        let volumeIn3 = volumeMm3 / (25.4 ** 3); // Convert mm^3 to in^3
        return volumeIn3;
    } else {
        return "Invalid type. Please use 'mm' or 'in'.";
    }
}

// Example Usage:
// Volume in cubic millimeters
console.log(calculateVolume(100, 50, 25, "mm")); // "125000.00 mm³"

// Volume in cubic inches
console.log(calculateVolume(100, 50, 25, "in")); // "7.63 in³"
