import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function PhotoViewer({ images, altText,materialNames }) {
  const [show, setShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = (index) => {
    setCurrentIndex(index);
    setShow(true);
  };

  const handleNext = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  return (
    <div className='photo-view-div'>
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={altText}
          onClick={() => handleShow(index)}
          className="photo-viewer-card"
          loading="lazy"
        />
      ))}

      <Modal show={show} onHide={handleClose} centered>
        {materialNames?.length>0?
        <>
          <Modal.Header closeButton>
            <Modal.Title style={{fontSize:'15px'}}>{materialNames[currentIndex]}</Modal.Title>
          </Modal.Header>
        </>
        :
        <>
        </>}
      
        <Modal.Body style={{ position: 'relative',textAlign:'center' }}>
          <img src={images[currentIndex]} alt={altText} style={{height:"60vh",maxWidth:'100%'}} />
          <div style={{ position: 'absolute', top: '45%', left: '2%', cursor: 'pointer',fontSize:'100px',backgroundColor:'lightgray',padding:'0px 10px',opacity :0.5 }} onClick={handlePrevious}>
            <span style={{ opacity :1 }}>&#8249;</span>
          </div>
          <div style={{ position: 'absolute', top: '45%', right: '2%', cursor: 'pointer',fontSize:'100px',backgroundColor:'lightgray',padding:'0px 10px',opacity :0.5 }} onClick={handleNext}>
            <span style={{ opacity :1 }}>&#8250;</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PhotoViewer;
