import React, {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef,
  Fragment,

} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Sidebar";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { processType, raiseIssueSteps } from "../../utils/constant";
import moment from "moment";
import fileSaver from "file-saver";
import Switch from "react-input-switch";
import downloadImage from "../../images/download.svg";
import tickwhite from "../../images/tick-white.svg";
import deleteImageEnabled from "../../images/reddelete.svg";
import starsIcon from "../../images/stars.svg";
import starsIconGrey from "../../images/stars-grey.svg";
import downArrow from "../../images/downArrow.svg";
import upArrow from "../../images/upArrow.svg";
import closeImg from "../../images/close.svg";
import deleteImageDisabled from "../../images/blackdelete.svg";
import loadingImage from "../../images/loader.gif";
import uploadImage from "../../images/upload.svg";
import {
  Row,
  Col,
  Accordion,
  Button,
  Modal,
  Tabs,
  Tab,
  Form,
  FormControl,
} from "react-bootstrap";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import { ChatRefContext } from "../../context/chatRef.context";
import {
  getEightXAddress,
  getMaterial,
  getPurchaseOrderRelatedIssueUser,
  getQualityRelatedIssueUser,
} from "../../utils/actions/allactions";
import {
  getPurchaseOrderById,
  updatePurchaseOrderRating,
  updatePOAdminComment,
  handleSubmitFeedBackForPO,
  updateDeliveryDateForPO,
  updateShippedDateForPO,
  updateDeliveryLinkForPO,
  closePurchaseOrder,
  handlePartsDataUpdateForPO,
  shippingAddressUpdateForPO,
  submitRaiseIssueForPO,
  getIssuesForPO,
  handleIssueCloseForPO,
  handlePOCancel,
  handlePoDocInfoUpdate,
  handleQRInformationUpdateForPO,
  handleQualityDocOrderUpdateForPO,
  handleConsignMentPictureUploadForPO,
  handleUpdateRFQCheckBox,
  handleDeleteDraftOrder,
  handleApprovePurchaseOrder,
  handleAcceptPO,
  handleSendEmailConfirmation,
  downloadPurchaseOrderPdf,
  updatePONotes,
  updateOrderChargesForPO,
  getBillsByPoId,
  createBillForPurchaseOrder,
  updatePurchaseOrderNumber,
} from "../../utils/actions/supplierActions";
import {
  numberInputOnWheelPreventChange,
  currencyFormatter,
  extractURLAndEncodedFilename,
  capitalizedStatus,
} from "../../utils/helper";
import useDocumentTitle from "../../utils/useDocumentTitle";
import CaptchaModal from "../../components/CaptchaModal/CaptchaModal";
import { Loading } from "../../components/Loading";
import { DeleteIcon, DownloadIcon, EditIcon, LucidLoader } from "../../components/Icons";
import { CorrectIcon } from "../../components/GlobalProjectNameComponent";

const PurchaseOrderDetails = () => {
  let navigate = useNavigate();
  useDocumentTitle("Purchase Order Details");
  let { _id } = useParams();
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [qualityDocs, setQualityDocs] = useState([]);
  let token = localStorage.getItem("Token");
  let decode;
  const [isChanged, setIsChanged] = useState({
    shippingAddress: false,
    orderInfo: false,
    qualityDoc: false,
    consignmentPhoto: false,
    deliveryDetails: false,
    isIssueClosed: false,
    qRDetailsChanged: false,
    adminComment: false,
    chargesChanged: false,
    poDocInfo: false,
    checkBoxInfo: false,
  });
  const [open, setOpen] = useState(null);
  const { setSelectedChatRefId, setChatType } = useContext(ChatRefContext);
  const [addresses, setAddresses] = useState([]);
  let [showModal, setShowModal] = useState(false);
  const [material, setMaterial] = useState([]);
  const [subMaterial, setSubMaterial] = useState([]);
  const [partMarking, setPartMarking] = useState([]);
  const [surfaceFinish, setSurfaceFinish] = useState([]);
  const [surfaceTreatment, setSurfaceTreatment] = useState([]);
  const [tolerance, setTolerance] = useState([]);
  const [layer, setLayer] = useState([]);
  const [isPartsEditable, setIsPartsEditable] = useState([]);
  const [consignmentPhoto, setConsignmentPhoto] = useState([]);
  const [deliveryTrackingLink, setDeliveryTackingLink] = useState();
  const [isShippingChanged, setIsShippingChanged] = useState(false);
  const [isDeliveryChanged, setIsDeliveryChanged] = useState(false);
  const [isOrderClosed, setIsOrderClosed] = useState();
  const [raiseIssueModal, setRaiseIssueModal] = useState(false);
  const [raiseIssueStepCount, setRaiseIssueStepCount] = useState(0);
  const [partsWithIssue, setPartsWithIssue] = useState([]);
  const [isPartIssue, setIsPartIssue] = useState(true);
  const [qualityRelatedIssue, setQualityRelatedIssue] = useState([]);
  const [orderRelatedIssue, setOrderRelatedIssue] = useState([]);
  const [issueDescription, setIssueDescription] = useState("");
  const [issueFileAttachment, setIssueFileAttachment] = useState([]);
  const [allIssuesForOrder, setAllIssuesForOrder] = useState([]);
  const [orderIssue, setOrderIssue] = useState([]);
  const [key, setKey] = useState(0);
  const [showExpectedDateModal, setShowExpectedDateModal] = useState(false);
  const [esdModalForAcceptPO, setEsdModalForAcceptPO] = useState(false);
  const [expectedShippingDateSelected, setExpectedShippingDateSelected] =
    useState(null);
  const [esdChangeReason, setEsdChangeReason] = useState("");
  const [loading, setLoading] = useState(true);
  const [poNotes, setPoNotes] = useState(
    `Terms & Conditions
Please read these terms and conditions carefully you are deemed to have understood and agreed to all the terms and conditions
contained herein.
1. Supplier to acknowledge receipt of orders, completion of order will be regarded as acceptance of purchasers conditions.
2. All merchandise on this order is subject to purchasers inspection with right of rejection thereafter irrespective of prior payment.
3. Merchandise rejected will be returned to supplier at suppliers expense, no replacement will be made unless so authorized by purchaser.
4. 8xParts expect their suppliers to comply with applicable environmental, health and safety laws and regulations.
5. Special clause : In the instance of goods being shipped on wooden pallets or crates to the ship location, no fumigated pallet or crates are accepted. We only accept heated treated wood and same must be stamped on the pallet or crate.
6. Suppliers fulfilling purchase orders shall make no changes in process or composition of the material without prior notification from 8xParts.
7. Terms of payment are 60 days or as otherwise agreed.
8. In the event that a delivery date falls on a Saturday, Sunday or bank holiday, please ensure delivery occurs by the previous Friday morning as there may be no receiving facility available at the weekends.`
  );
  const [qrCodeLink, setQrCodeLink] = useState(false);
  const [isSendEmailInProgress, setIsSendEmailInProgress] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const [billsForPurchaseOrder, setBillsForPurchaseOrder] = useState([]);
  const [billPartsData, setBillPartsData] = useState({});
  const [availableQtyArr, setAvailableQtyArr] = useState({});
  const [billTotalAmount, setBillTotalAmount] = useState(0);
  const [billDocument, setBillDocument] = useState(null);
  const [supplierBillNumber, setSupplierBillNumber] = useState("");
  const [isCreateBillActive, setIsCreateBillActive] = useState(false);
  const [isPoNumberEditing, setIsPoNumberEditing] = useState(false);
  const poInputRef = useRef();
  const orderInformationRef = useRef();

  useEffect(() => {
    setLoading(true);
    let billForPoArr = [...billsForPurchaseOrder];
    billForPoArr = billForPoArr.filter((item) => !item.isRejected);
    let localAvailableQtyData = {};
    purchaseOrder?.supplierRFQId?.partsData?.forEach((lineItem) => {
      localAvailableQtyData[lineItem._id] = lineItem.qty;
    });
    if (billForPoArr.length > 0) {
      billForPoArr.forEach((item) => {
        item?.partsData?.forEach((lineItem) => {
          localAvailableQtyData[lineItem.partItemId] =
            localAvailableQtyData[lineItem.partItemId] - lineItem?.shippedQty;
        });
      });
    }

    setAvailableQtyArr(localAvailableQtyData);
    setLoading(false);
  }, [billsForPurchaseOrder]);

  const handeBillItemChange = (event, lineId) => {
    let localBillPartsData = { ...billPartsData };
    if (availableQtyArr[lineId] < parseInt(event.target.value)) {
      toast.error(
        "The invoice quantity cannot be more than order item quantity"
      );
      localBillPartsData[lineId] = 0;
    } else {
      localBillPartsData[lineId] = parseInt(event.target.value);
    }

    setBillPartsData(localBillPartsData);
    let localBilTotalAmount = 0;
    purchaseOrder?.supplierRFQId?.partsData.forEach((item) => {
      localBilTotalAmount =
        localBilTotalAmount + (localBillPartsData[item._id] || 0) * item.price;
    });

    setBillTotalAmount(localBilTotalAmount);
  };

  const handleCancelCreateBill = () => {
    setIsCreateBillActive(false);
    setBillPartsData({});
    setBillTotalAmount(0);
    setSupplierBillNumber("");
    setBillDocument(null);
  };

  const handleCreateBill = async () => {
    try {
      if (purchaseOrder.status == "Draft") {
        toast.error("Cannot create bill for draft order.");
        return;
      }

      if (purchaseOrder.status == "Awaiting PO Acceptance") {
        toast.error("Order need to be accepted in order to create bill.");
        return;
      }

      if (!purchaseOrder?.purchaseOrderNumber) {
        toast.error("Purchase order number is not updated.");
        return;
      }

      if (billTotalAmount <= 0) {
        toast.error(
          "Please enter valid shipping details for parts. Bill amount cannot be zero."
        );
        return;
      }

      if (!billDocument) {
        toast.error("Please upload bill document");
        return;
      }

      if (!supplierBillNumber) {
        toast.error("Please enter supplier bill number");
        return;
      }

      let userResponse = confirm(
        "Are you sure you want to sent this details for approval?"
      );
      if (!userResponse) {
        return;
      }

      let formData = new FormData();
      formData.append("billPartsData", JSON.stringify(billPartsData));
      formData.append("billTotalAmount", billTotalAmount);
      formData.append("supplierBillNumber", supplierBillNumber);
      formData.append("poId", purchaseOrder?._id);
      formData.append("pORefId", purchaseOrder.pORefId);
      formData.append("supplierId", purchaseOrder.supplierId._id);
      formData.append("shippedDate", "");
      formData.append("deliveryTrackingLink", "");
      formData.append("shippingCarrier", "");
      formData.append("isNoQCDocChecked", false);
      formData.append("isNoConsignmentsChecked", false);
      formData.append("billQualityDocumentsData", JSON.stringify([]));
      formData.append("billDocument", billDocument);

      const createBillResponse = await createBillForPurchaseOrder(
        token,
        formData
      );

      if (createBillResponse.status) {
        toast.success("Bill created successfully and sent for approval.");
        setIsCreateBillActive(false);
        setBillTotalAmount(0);
        setBillDocument(null);
        setSupplierBillNumber("");
        setBillPartsData({});
        navigate(`/admin/bill-details/${createBillResponse.data._id}`);
      }
    } catch (err) {
      console.log("err", err);
      toast.error(
        err.response.data.message ||
          "Something went wrong while creating bill for purchase order"
      );
    }
  };

  const handleCreateNewBillClick = () => {
    orderInformationRef.current.click();
    let isAllInvoiced = true;
    let availableQtyValuesArr = Object.values(availableQtyArr);
    availableQtyValuesArr.forEach((item) => {
      if (item > 0) {
        isAllInvoiced = false;
      }
    });

    if (!isAllInvoiced) {
      setIsCreateBillActive(true);
    } else {
      toast.error("All items for this order are already billed");
    }
  };

  const handleCloseCaptchaModal = () => setShowCaptchaModal(false);
  const handleUpdatePONotes = async () => {
    try {
      let data = {
        orderId: purchaseOrder._id,
        poNotes,
      };

      const response = await updatePONotes(token, data);
      if (response.status) {
        toast.success(response.message);
        setPurchaseOrder({
          ...purchaseOrder,
          poNotes: response?.data?.poNotes,
        });
      }
    } catch (err) {
      console.log(
        "Error in admin quote 137 on: " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleCloseESDAcceptPOModal = () => setEsdModalForAcceptPO(false);

  const handleClose = () => setShowModal(false);

  const showDrawer = (id) => {
    setOpen(id);
    setSelectedChatRefId(id);
    setChatType("supplier");
  };

  const onClose = () => {
    setOpen(null);
    setSelectedChatRefId(null);
    setChatType(null);
  };

  useEffect(() => {
    try {
      if (!token) {
        navigate("/login");
      } else {
        decode = jwt_decode(token);
        if (!decode) {
          navigate("/login");
          toast.error("Invalid Token found!");
        } else if (decode) {
          if (decode.userType == "admin") {
            handleDataFetch();
          } else if (decode.userType == "user") {
            navigate("/saved-quote");
            // toast.error('Unauthorized Access!');
          } else {
            navigate("/login");
            // toast.error('Unauthorized Access!');
          }
        }
      }
    } catch (err) {
      console.log(
        "Error in 168 purcase order details on " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err.message
      );
      toast.error("Something  went wrong while fetching data.");
    }
  }, []);

  const handleDataFetch = async () => {
    fetchOrdersDetails();
    fetch8xAddress();
    fetchMaterialData();
  };

  const fetchMaterialData = async () => {
    try {
      setLoading(true);
      const response = await getMaterial(token);
      if (response.status) {
        setMaterial(response.data.material);
        setSubMaterial(response.data.submaterial);
        setPartMarking(response.data.partMarking);
        setSurfaceFinish(response.data.surafcefinish);
        setSurfaceTreatment(response.data.surfacetreat);
        setTolerance(response.data.tolerance);
        setLayer(response.data.layer);
      } else {
        toast.error("Something went wrong while fetching the materials");
      }
    } catch (error) {
      toast.error("Something went wrong while fetching the materials");
    } finally {
      setLoading(false);
    }
  };

  const fetch8xAddress = async () => {
    try {
      const addressData = await getEightXAddress(token);
      if (addressData.status) {
        setAddresses(addressData.data);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const fetchSecondaryData = async (poId) => {
    try {
      getBillsByPoId(token, poId).then((response) =>
        setBillsForPurchaseOrder(response.data)
      );
    } catch (err) {
      toast.error(err.message);
    }
  };

  const fetchOrdersDetails = async () => {
    try {
      const purchaseOrderData = await getPurchaseOrderById(_id, token);
      setPurchaseOrder(purchaseOrderData.data);
      fetchSecondaryData(purchaseOrderData.data._id);
      setQrCodeLink(
        `${window.location.origin}/view-order-details/${purchaseOrderData.data.salesOrderId}`
      );
      setPoNotes(purchaseOrderData?.data?.poNotes || poNotes);

      setDeliveryTackingLink(purchaseOrderData.data.deliveryTrackingLink);
      setIsOrderClosed(purchaseOrderData.data.isCompleted);
      setPartsWithIssue(purchaseOrderData.data.supplierRFQId?.partsData);
      setIsPartsEditable(
        [
          ...new Array(purchaseOrderData.data.supplierRFQId?.partsData?.length),
        ].map(() => 0)
      );

      setQualityDocs(purchaseOrderData.data.qualityDocuments);
      setConsignmentPhoto(purchaseOrderData.data.consignmentPhoto);

      let poRelatedIssueResponse = await getPurchaseOrderRelatedIssueUser(
        token
      );
      let qualityRelatedIssueResponse = await getQualityRelatedIssueUser(token);
      setOrderRelatedIssue(poRelatedIssueResponse.data.data);
      setQualityRelatedIssue(qualityRelatedIssueResponse.data.data);

      let allIssue = await getIssuesForPO(token);

      let localOrderIssue = [];
      if (allIssue.data.length > 0) {
        allIssue.data.map((item, i) => {
          if (item?.orderId === _id) {
            localOrderIssue.push(item);
          }
        });
      }

      if (localOrderIssue.length > 0) {
        setAllIssuesForOrder(localOrderIssue);
        setOrderIssue(localOrderIssue[localOrderIssue.length - 1]);
        setKey(localOrderIssue.length - 1);
      }
    } catch (err) {
      console.log(
        "Error in 248 purcase order details on " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
      toast.error("Something went wrong.");
    }
  };

  const download = async (name, url) => {
    const data = extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  const handleOrderAdminCommentUpdate = async () => {
    if (isChanged.adminComment) {
      let data = {
        orderId: purchaseOrder._id,
        adminComment: purchaseOrder.adminComment,
      };
      const response = await updatePOAdminComment(data);
      if (response.status) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  };

  const handleChangeAddress = () => {
    setShowModal(true);
  };

  const handleBack = () => {
    const userResponse = confirm(
      "Unsaved information will be lost, are you sure you want to go back ?"
    );
    if (userResponse) {
      navigate(-1);
    }
    return;
  };

  const handleQualityDocUpdate = async () => {
    let formData = new FormData();
    for (let i = 0; i < qualityDocs.length; i++) {
      if (
        !(!qualityDocs[i].file?.filename || !qualityDocs[i].file?.name) ||
        !qualityDocs[i].name
      ) {
        return false;
      }
      formData.append("fileToUpload", qualityDocs[i].file);
    }
    formData.append("qualityDoc", JSON.stringify(qualityDocs));
    formData.append("orderId", purchaseOrder._id);
    const updateOrder = await handleQualityDocOrderUpdateForPO(formData, token);
    return updateOrder;
  };

  const handleItemFieldEdit = (event, index, field) => {
    let localQuoteData = purchaseOrder.supplierRFQId;
    let localTotalAmount = purchaseOrder.totalAmount;
    if (field === "qty") {
      localQuoteData.partsData[index].qty = event.target.value;
    } else if (field === "price") {
      localQuoteData.partsData[index].price = event.target.value;
    } else if (field === "partNotes") {
      localQuoteData.partsData[index].partNotes = event.target.value;
    } else if (field === "description") {
      localQuoteData.partsData[index].description = event.target.value;
    }
    localTotalAmount = localQuoteData.partsData.reduce((acc, item) => {
      return acc + item.price * item.qty;
    }, 0);
    setPurchaseOrder({
      ...purchaseOrder,
      rfqId: localQuoteData,
      totalAmount: localTotalAmount,
    });
    setIsChanged({ isChanged, orderInfo: true });
  };

  const handleDeleteItemFromOrder = (index) => {
    if (isPartsEditable[index]) {
      let localQuoteData = purchaseOrder.supplierRFQId;
      if (localQuoteData.partsData.length == 1) {
        toast.error("Cannot delete all lines from this order.");
        return;
      }
      let localTotalAmount = purchaseOrder.totalAmount;
      localQuoteData.partsData.splice(index, 1);
      localTotalAmount = localQuoteData.partsData.reduce((acc, item) => {
        return acc + item.price * item.qty;
      }, 0);
      setPurchaseOrder({
        ...purchaseOrder,
        rfqId: localQuoteData,
        totalAmount: localTotalAmount,
      });
      let localIsPartsEditable = isPartsEditable;
      localIsPartsEditable.splice(index, 1);
      setIsPartsEditable(localIsPartsEditable);
    }
    setIsChanged({ isChanged, orderInfo: true });
  };

  const handleEditableField = (index) => {
    if (billsForPurchaseOrder.length > 0) {
      toast.error(
        "Bills for order is already created. Cannot edit at this stage."
      );
      return;
    }
    let localIsPartsEditable = [...isPartsEditable];
    if (localIsPartsEditable[index] === 0) {
      localIsPartsEditable[index] = 1;
    } else {
      localIsPartsEditable[index] = 0;
    }
    setIsPartsEditable([...localIsPartsEditable]);
  };

  const handleUpdateConsignmentPhoto = async () => {
    let formData = new FormData();
    formData.append("orderId", purchaseOrder._id);
    formData.append("consignmentPhotoData", JSON.stringify(consignmentPhoto));
    for (let i = 0; i < consignmentPhoto.length; i++) {
      formData.append("consignmentPhoto", consignmentPhoto[i].file);
    }

    const updateOrder = await handleConsignMentPictureUploadForPO(
      formData,
      token
    );
  };

  const handleQRInformationChange = async () => {
    try {
      let formData = new FormData();
      formData.append("orderId", purchaseOrder._id);
      formData.append("qrCodeImage", purchaseOrder.qrCodeImage);
      formData.append("qrCodeDrawingFile", purchaseOrder.qrCodeDrawingFile);
      formData.append(
        "qrCodeImageData",
        JSON.stringify(purchaseOrder.qrCodeImage)
      );
      formData.append(
        "qrCodeDrawingFileData",
        JSON.stringify(purchaseOrder.qrCodeDrawingFile)
      );
      const updateOrder = await handleQRInformationUpdateForPO(formData, token);
    } catch (err) {
      console.log(
        "Error in 430 purcase order details on " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleRating = async (rating, key) => {
    try {
      setPurchaseOrder({ ...purchaseOrder, [key]: rating });
      const response = await updatePurchaseOrderRating(token, {
        orderId: purchaseOrder?._id,
        [key]: rating,
      });
    } catch (err) {
      console.log(
        "Error in 444 purcase order details on " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleFeedBackSubmit = async () => {
    const data = {
      orderId: purchaseOrder._id,
      feedBackComment: purchaseOrder.feedBackComment,
    };
    const response = await handleSubmitFeedBackForPO(token, data);
    if (response.status == 200) {
      toast.success("Feedback submitted successfully");
    } else {
      toast.error("Something went wrong while submitting feedback");
    }
  };

  const handleUpdateDeliveryDate = async () => {
    try {
      let data = {
        deliveryDate: purchaseOrder.deliveryDate,
        id: purchaseOrder._id,
      };
      await updateDeliveryDateForPO(token, data);
    } catch (err) {
      console.log(
        "Error in 530 purcase order details on " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleUpdateShippedDate = async () => {
    let data = {
      shippedDate: purchaseOrder.shippedDate,
      id: purchaseOrder._id,
    };

    let response = await updateShippedDateForPO(token, data);
    if (!response.data.status) {
      toast.error("Something went wrong while updating shipping date.");
    }
  };

  const handleDeliveryTrackingLinkUpdate = async () => {
    let data = {
      deliveryTrackingLink: deliveryTrackingLink,
      orderId: purchaseOrder._id,
    };
    await updateDeliveryLinkForPO(token, data);
  };

  const handleComplete = async () => {
    let data = {
      orderId: purchaseOrder._id,
      deliveryComment: purchaseOrder.deliveryComment,
    };
    await closePurchaseOrder(token, data);
  };

  const handleOrderInfoUpdate = async () => {
    let data = {
      partsData: JSON.stringify(purchaseOrder.supplierRFQId.partsData),
      orderId: purchaseOrder._id,
      totalAmount: purchaseOrder.totalAmount,
    };
    const updateOrder = await handlePartsDataUpdateForPO(data, token);
  };

  const handleShippingAddressChange = async (shippingAddressData) => {
    try {
      let data = {
        orderId: purchaseOrder._id,
        shippingAddress: shippingAddressData,
      };
      let response = await shippingAddressUpdateForPO(token, data);
      if (response.status) {
        toast.success(response.message);
        setPurchaseOrder({
          ...purchaseOrder,
          shippingAddress: shippingAddressData,
        });
      }
    } catch (err) {
      console.log(
        "Error in 584 purcase order details on " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  const handleOrderUpdate = async () => {
    try {
      if (
        purchaseOrder.status == "refunded" ||
        purchaseOrder.status == "Cancelled"
      ) {
        toast.error("Order is already cancelled");
        return;
      }

      if (!purchaseOrder.purchaseOrderNumber) {
        toast.error("Purchase order number is mandatory.");
        return;
      }

      if (
        purchaseOrder.deliveryDate &&
        new Date(purchaseOrder?.shippedDate) >
          new Date(purchaseOrder.deliveryDate)
      ) {
        toast.error("Delivery date cannot come before shipped date");
        return;
      }

      //   if ( (order.shippedDate && !(moment.utc(order.shippedDate,'YYYY-MM-DD').isSameOrAfter(moment.utc(order.createdAt,'YYYY-MM-DD'))))
      //   || (order.expectedDelivery && !(moment.utc(order?.expectedDelivery,'YYYY-MM-DD').isSameOrAfter(moment.utc(order.createdAt,'YYYY-MM-DD'))))
      // )

      if (
        (purchaseOrder.shippedDate &&
          !moment
            .utc(purchaseOrder?.shippedDate, "YYYY-MM-DD")
            .isSameOrAfter(
              moment.utc(purchaseOrder.createdAt, "YYYY-MM-DD")
            )) ||
        (purchaseOrder.deliveryDate &&
          !moment
            .utc(purchaseOrder?.deliveryDate, "YYYY-MM-DD")
            .isSameOrAfter(moment.utc(purchaseOrder.createdAt, "YYYY-MM-DD")))
      ) {
        toast.error(
          "Shipped date and delivered date cannot be before order creation date."
        );
        return;
      }

      if (isOrderClosed) {
        toast.error("Order is already closed.");
        return;
      }

      if (isChanged.chargesChanged) {
        let data = {
          orderId: purchaseOrder._id,
          totalAmount: purchaseOrder?.totalAmount,
          adjustmentValue: purchaseOrder?.adjustmentValue,
        };

        await updateOrderChargesForPO(token, data);
      }

      if (isChanged.checkBoxInfo) {
        let data = {
          rfqId: purchaseOrder?.supplierRFQId?.rfqId?._id,
          isDimensionalReportRequested:
            purchaseOrder?.supplierRFQId?.rfqId?.isDimensionalReportRequested,
          isQrTrackingRequested:
            purchaseOrder?.supplierRFQId?.rfqId?.isQrTrackingRequested,
          isConformanceCertificateRequested:
            purchaseOrder?.supplierRFQId?.rfqId
              ?.isConformanceCertificateRequested,
        };
        await handleUpdateRFQCheckBox(token, data);
      }

      if (isChanged.qRDetailsChanged) {
        await handleQRInformationChange();
      }

      if (isChanged.orderInfo) {
        await handleOrderInfoUpdate();
      }

      if (isChanged.poDocInfo) {
        if (!purchaseOrder.purchaseOrderNumber) {
          toast.error("Purchase Order Number is required.");
          return;
        }

        let data = {
          id: purchaseOrder._id,
          purchaseOrderNumber: purchaseOrder.purchaseOrderNumber,
        };

        let poNumberResponse = await handlePoDocInfoUpdate(token, data);
        if (poNumberResponse.status) {
          setIsChanged({ ...isChanged, poDocInfo: false });
        }
      }

      if (isChanged.deliveryDetails) {
        if (purchaseOrder.status == "Draft") {
          toast.error("Cannot update delivery details for draft order.");
          return;
        }

        await handleDeliveryTrackingLinkUpdate();

        if (isShippingChanged) {
          if (!purchaseOrder.shippedDate) {
            if (
              purchaseOrder.deliveryDate ||
              purchaseOrder.isCompleted ||
              isOrderClosed
            ) {
              toast.error("Please enter shipped date");
              return;
            }
          }

          let adminResponse = confirm(
            "Are you sure you want to update the order shipping date?"
          );
          if (adminResponse) {
            await handleUpdateShippedDate();
            setIsShippingChanged(false);
          }
        }

        if (isDeliveryChanged) {
          if (!purchaseOrder.shippedDate && purchaseOrder.deliveryDate) {
            alert(
              "Shipping date cannot be empty if delivery date is selected."
            );
            return;
          }

          if (
            !purchaseOrder.deliveryDate &&
            (purchaseOrder.isCompleted || isOrderClosed)
          ) {
            toast.error("Please enter expected delivery details");
            return;
          }

          let adminResponse = confirm(
            "Are you sure you want to update the order delivery date?"
          );
          if (adminResponse) {
            await handleUpdateDeliveryDate();
            setIsDeliveryChanged(false);
          }
        }
      }

      if (isChanged.qualityDoc) {
        if (purchaseOrder.status == "Draft") {
          toast.error("Cannot update quality documents for draft order.");
          return;
        }
        let response = await handleQualityDocUpdate();
        if (response === false || response?.response?.status === 500) {
          toast.error("Please enter valid quality document information");
          return;
        }
      }

      if (
        consignmentPhoto.length > 0 ||
        purchaseOrder.consignmentPhoto.length > 0 ||
        isChanged.consignmentPhoto === true
      ) {
        if (purchaseOrder.status == "Draft") {
          toast.error("Cannot update consignment photos for draft order.");
          return;
        }
        await handleUpdateConsignmentPhoto();
      }

      if (isChanged.isIssueClosed) {
        if (purchaseOrder.status == "Draft") {
          toast.error("Cannot close issue for draft order.");
          return;
        }
        let data = {
          orderId: orderIssue.orderId,
          issueId: orderIssue._id,
        };
        await handleIssueCloseForPO(token, data);
      }

      if (purchaseOrder.isCompleted && !isOrderClosed) {
        if (purchaseOrder.status == "Draft") {
          toast.error("Cannot close draft order.");
          return;
        }

        if (!purchaseOrder.shippedDate || !purchaseOrder.deliveryDate) {
          toast.error("Please enter shipping date and delivery date.");
          return;
        }

        let adminResponse = confirm(
          "Are you sure you want to close the order?"
        );
        if (adminResponse) {
          await handleComplete();
        }
      }

      toast.success("Order updated successfully");
    } catch (err) {
      console.log(
        "Error in 744 purcase order details on " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
      toast.error("Something went wrong while updating the order");
    }
  };

  const handleRaiseIssueClose = () => {
    setRaiseIssueModal(false);
  };

  const handleRaiseIssueNext = () => {
    if (raiseIssueStepCount == 2) {
      if (issueDescription == "" || issueDescription == false) {
        toast.error("Issue description is required.");
        return;
      }
      let response = confirm("Are you sure you want to submit the issue?");
      if (response) {
        handleSubmitIssue();
      }
    } else {
      let countOfSelectPartIssue = partsWithIssue?.reduce((acc, item) => {
        if (item.selected) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);

      if (countOfSelectPartIssue === 0 && isPartIssue) {
        toast.error(
          "If there is no issue in any parts, kindly select the not a part issue option"
        );
        return;
      }

      setRaiseIssueStepCount(raiseIssueStepCount + 1);
    }
  };

  const handleRaiseIssueBack = () => {
    if (raiseIssueStepCount == 0) {
    } else {
      setRaiseIssueStepCount(raiseIssueStepCount - 1);
    }
  };

  const handleNotPartIssueSelect = () => {
    setIsPartIssue(!isPartIssue);
    if (isPartIssue) {
      partsWithIssue?.forEach((item) => {
        item.selected = false;
      });
    }
  };

  const handleSelectPartWithIssue = (index) => {
    if (!isPartIssue) {
      toast.error(
        'You cannot select any parts when you have selected the "Not a part issue. Purchase/ order related issue" checkbox '
      );
      return;
    }
    const localPartsWithIssue = [...partsWithIssue];
    localPartsWithIssue[index].selected = !localPartsWithIssue[index].selected;
    setPartsWithIssue(localPartsWithIssue);
  };

  const handleQualityRelatedIssueSelect = (index) => {
    const localQualityRelatedIssue = [...qualityRelatedIssue];
    localQualityRelatedIssue[index].selected =
      !localQualityRelatedIssue[index].selected;
    setQualityRelatedIssue(localQualityRelatedIssue);
  };

  const handlePORelatedIssueSelect = (index) => {
    const localPORelatedIssues = [...orderRelatedIssue];
    localPORelatedIssues[index].selected =
      !localPORelatedIssues[index].selected;
    setOrderRelatedIssue(localPORelatedIssues);
  };

  const handleIssueFileAttachmentAdd = (files) => {
    let localIssueAttachment = [...issueFileAttachment];
    const filesUploaded = Object.values(files);
    for (let i = 0; i < filesUploaded.length; i++) {
      localIssueAttachment.push(filesUploaded[i]);
    }
    setIssueFileAttachment(localIssueAttachment);
  };

  const handleDeleteIssueAttachment = (index) => {
    let localIssueAttachment = [...issueFileAttachment];
    localIssueAttachment.splice(index, 1);
    setIssueFileAttachment(localIssueAttachment);
  };

  const handleSubmitIssue = async () => {
    let qualityRelatedIssueSelected = qualityRelatedIssue.filter(
      (item) => item.selected
    );
    let orderRelatedIssueSelected = orderRelatedIssue.filter(
      (item) => item.selected
    );
    let partsWithIssueSelected = partsWithIssue?.filter(
      (item) => item.selected
    );

    let formData = new FormData();
    formData.append("orderId", purchaseOrder?._id);
    formData.append(
      "qualityRelatedIssue",
      qualityRelatedIssueSelected.length > 0
        ? JSON.stringify(qualityRelatedIssueSelected)
        : ""
    );
    formData.append(
      "orderRelatedIssue",
      orderRelatedIssueSelected?.length > 0
        ? JSON.stringify(orderRelatedIssueSelected)
        : ""
    );
    formData.append("isPartIssue", isPartIssue);
    formData.append(
      "partsWithIssue",
      partsWithIssueSelected?.length > 0
        ? JSON.stringify(partsWithIssueSelected)
        : ""
    );
    formData.append("issueDescription", issueDescription);

    for (let i = 0; i < issueFileAttachment.length; i++) {
      formData.append("issueFileAttachment", issueFileAttachment[i]);
    }

    try {
      setRaiseIssueModal(false);
      let response = await submitRaiseIssueForPO(token, formData);
      if (response.status) {
        setAllIssuesForOrder([...allIssuesForOrder, response.data]);
      }

      if (response.statusCode === 200) {
        toast.success("Issue submitted successfully");
        setIsPartIssue(true);
        setIssueDescription("");
        setQualityRelatedIssue([]);
        setPartsWithIssue([]);
        setOrderRelatedIssue([]);
        setIssueFileAttachment([]);
        setRaiseIssueStepCount(0);
      }
    } catch (err) {
      console.log(
        "Error in 878 purcase order details on " +
          moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
      toast.error(
        "Something went wrong while submitting the issue.Please try again later"
      );
    }
  };

  const handleShowSelectedIssue = (key) => {
    setOrderIssue(allIssuesForOrder[key]);
  };

  const handleCancelPurchaseOrder = async () => {
    try {
      if (purchaseOrder.status == "Cancelled") {
        toast.error("Order is already cancelled");
        return;
      }
      let adminResponse = confirm(
        `Are you sure you want to cancel the order ${purchaseOrder.pORefId} ?`
      );
      if (!adminResponse) {
        return;
      }
      let cancelResponse = await handlePOCancel(token, {
        orderId: purchaseOrder._id,
      });
      if (cancelResponse.status) {
        toast.success(cancelResponse.message);
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  const handleAcceptPOClick = async () => {
    setEsdModalForAcceptPO(true);
  };

  const handleDeleteOrderClick = async () => {
    let adminResponse = confirm(
      "Are you sure you  want to delete this draft order? Note: Date will be in accessable and the RFQ will move to in progress status."
    );
    if (!adminResponse) {
      return;
    }
    let data = {
      purchaseOrderId: purchaseOrder._id,
    };
    const deletedResponse = await handleDeleteDraftOrder(data, token);
    if (deletedResponse.status) {
      toast.success(deletedResponse.message);
      navigate("/admin/purchase-orders");
    }
  };

  const handleApprovePOClick = async () => {
    if (!purchaseOrder.purchaseOrderNumber) {
      toast.error(
        "Purchase order number is mandatory to use this functionality."
      );
      return;
    }

    if (purchaseOrder.purchaseOrderNumber && isChanged.poDocInfo) {
      toast.error("Please update order to use this functionality.");
      return;
    }

    let adminResponse = confirm(
      "Are you sure you  want to approve this draft order? Once approved, this order will  be sent to  Supplier Portal in Awaiting PO Acceptance state."
    );
    if (!adminResponse) {
      return;
    }
    let data = {
      purchaseOrderId: purchaseOrder._id,
    };
    const approvedResponse = await handleApprovePurchaseOrder(data, token);
    if (approvedResponse.status) {
      toast.success(approvedResponse.message);
      fetchOrdersDetails();
    }
  };

  const handleProceedAcceptPOClick = async () => {
    try {
      if (!expectedShippingDateSelected) {
        toast.error("Please selected expected shipping date.");
        return;
      }

      let expectedShippingDateValue = null;
      if (
        !(
          moment(expectedShippingDateSelected).format("DD MMM YYYY") ==
          moment(Date.now())
            .add(purchaseOrder?.supplierRFQId?.leadTime, "days")
            .format("DD MMM YYYY")
        )
      ) {
        if (!esdChangeReason) {
          toast.error(
            "Please enter reason to change the Expected Shipping date."
          );
          return;
        }
        expectedShippingDateValue = expectedShippingDateSelected;
      }

      let data = {
        orderId: purchaseOrder?._id,
        expectedShippingDate: expectedShippingDateValue,
        reasonToChange: esdChangeReason,
      };
      const response = await handleAcceptPO(token, data);
      if (response.data.status) {
        setPurchaseOrder(response.data.data);
        setEsdModalForAcceptPO(false);
        setExpectedShippingDateSelected(null);
        setEsdChangeReason("");
        toast.success(response.data.message);
      } else {
        toast.error("Something went wrong while accepting PO");
      }
    } catch (err) {
      console.log("err", err.message);
      return;
    }
  };

  const handleSendEmailConfirmationClick = async () => {
    try {
      setIsSendEmailInProgress(true);
      let adminResponse = true;
      if (purchaseOrder.isEmailConfirmationSent) {
        adminResponse = confirm(
          "The email confirmation for this order is already sent. Are you sure you want to send it again?"
        );
      }

      if (!adminResponse) {
        setIsSendEmailInProgress(false);
      }

      let filterEightXAddress = addresses.filter((item) => item.isDefault);
      if (filterEightXAddress.length == 0) {
        toast.error(
          "Please set default 8xParts address in  8xParts address master."
        );
        return;
      }

      let data = {
        orderId: purchaseOrder?._id,
        eightXAddress: filterEightXAddress[0],
      };

      let responseFromDownload = await downloadPurchaseOrderPdf(data, token);

      if (adminResponse) {
        let data = {
          orderId: purchaseOrder?._id,
          material,
          subMaterial,
          partMarking,
          surfaceFinish,
          surfaceTreatment,
          tolerance,
          layer,
          filePath: responseFromDownload?.data?.data,
        };
        const response = await handleSendEmailConfirmation(token, data);
        if (response.status) {
          toast.success(response.message);
          setPurchaseOrder({ ...purchaseOrder, isEmailConfirmationSent: true });
          setIsSendEmailInProgress(false);
        }
      }
    } catch (err) {
      setIsSendEmailInProgress(false);
      console.log("error in purchase order details 1045", err);
    }
  };

  const handlePODownloadClick = async () => {
    setLoading(true);
    try {
      if (!purchaseOrder.purchaseOrderNumber) {
        toast.error(
          "Purchase order number is mandatory to use this functionality."
        );
        return;
      }

      if (purchaseOrder.purchaseOrderNumber && isChanged.poDocInfo) {
        toast.error("Please update order to use this functionality.");
        return;
      }
      let filterEightXAddress = addresses.filter((item) => item.isDefault);
      if (filterEightXAddress.length == 0) {
        toast.error(
          "Please set default 8xParts address in  8xParts address master."
        );
        return;
      }

      let data = {
        orderId: purchaseOrder?._id,
        eightXAddress: filterEightXAddress[0],
      };

      let response = await downloadPurchaseOrderPdf(data, token).then((res) => {
        fileSaver.saveAs(
          `${process.env.REACT_APP_SUPPLIER_BACKEND}/public/docs/` +
            res?.data?.data,
          `${purchaseOrder.pORefId}.pdf`
        );
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("err", err);
      toast.error(err.message);
    }
  };

  const handleChargeChange = (type, amount) => {
    setIsChanged({ ...isChanged, chargesChanged: true });
    let localTotal = purchaseOrder.supplierRFQId.partsData.reduce(
      (accumulator, item) => {
        return accumulator + Number(item.qty) * Number(item?.price);
      },
      0
    );
    let orderAdjustMentValue =
      type == "adjustmentValue"
        ? Number(amount)
        : Number(
            purchaseOrder.adjustmentValue ? purchaseOrder.adjustmentValue : 0
          );
    setPurchaseOrder({
      ...purchaseOrder,
      totalAmount: (Number(localTotal) + orderAdjustMentValue).toFixed(2),
      adjustmentValue: orderAdjustMentValue,
    });

    localTotal = Number(localTotal) + orderAdjustMentValue;
  };
  const handlePoNumberEditing = ()=>
  {
    setIsPoNumberEditing(true);
    poInputRef.current.focus();
  };
  const handlePoNumberChangeCancel = async()=>
  {
    setLoading(true);
    try {
      setIsPoNumberEditing(false);
      await fetchOrdersDetails();
      
    } catch (error) {
      window.location.reload();
    }
    finally{
      setLoading(false);
      
    }
  };
  const handleNewPoNumberSubmit = async()=>
  {
    setLoading(true);
    try {
        const response = await updatePurchaseOrderNumber(token,{
          id:purchaseOrder._id,
          data:purchaseOrder.purchaseOrderNumber
        });
        setLoading(false);
        if(response && response.status)
        {
           toast.success("Po Number Edited Successfully");
           setIsPoNumberEditing(false);
           await fetchOrdersDetails();
        }
        else
        {
          toast.error("Unable To Edit Name At The Moment");
        }
        
      
    } catch (error) {
        toast.error("Unable To Edit Po Number At The Moment");
    }
    finally{
      setLoading(false);
      
      
    }
  }

  return (
    <div
      className="admin-container"
      style={{
        width: "100%",
        overflowX: "auto",
        backgroundColor: "#f4e6dd",
        minHeight: "100vh",
      }}
    >
      <Sidebar />
      <CaptchaModal
        showModal={showCaptchaModal}
        handleClose={handleCloseCaptchaModal}
        handleSendEmail={handleSendEmailConfirmationClick}
        buttonText="Send Email"
      />
      <span style={{ width: "100%" }}>
        <div className="center-heading">
          <h1 className="text-2xl font-bold mt-2">Purchase Orders Details</h1>
          <div className="right-container ">
            <Modal
              size="lg"
              dialogClassName="qoute-version-modal"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showExpectedDateModal}
              onHide={() => setShowExpectedDateModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  style={{ fontSize: "15px" }}
                >
                  Expected Shipping Date Change Record
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div style={{ textAlign: "center" }}>
                  <br />
                  <div className="parts-table" style={{ fontSize: "14px" }}>
                    <table>
                      <thead>
                        <tr>
                          <th style={{ fontSize: "12px" }}>Updated On</th>
                          <th style={{ fontSize: "12px" }}>
                            Expected Shipping Date
                          </th>
                          <th style={{ fontSize: "12px" }}>Reason To Change</th>
                        </tr>
                      </thead>
                      <tbody>
                        {purchaseOrder?.expectedShippingDates?.length > 0 ? (
                          <>
                            <tr style={{ fontSize: "13px" }}>
                              <td>
                                {moment(
                                  purchaseOrder.poApprovedDate ||
                                    purchaseOrder.createdAt
                                ).format("DD MMM YYYY")}{" "}
                                (Order creation date)
                              </td>
                              <td>
                                {purchaseOrder.poAcceptanceDate
                                  ? moment(purchaseOrder.poAcceptanceDate)
                                      .add(
                                        purchaseOrder?.supplierRFQId?.leadTime,
                                        "days"
                                      )
                                      .format("DD MMM YYYY")
                                  : "NA"}
                              </td>
                              <td>Original Promised Shipping Date</td>
                            </tr>
                            {purchaseOrder?.expectedShippingDates?.map(
                              (item, index) => {
                                return (
                                  <tr key={index} style={{ fontSize: "13px" }}>
                                    <td>
                                      {moment(item.updatedDate).format(
                                        "DD MMM YYYY h:mm A"
                                      )}
                                    </td>
                                    <td>
                                      {moment(item.updateValue).format(
                                        "DD MMM YYYY"
                                      )}
                                    </td>
                                    <td>{item.reasonToChange}</td>
                                  </tr>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <tr style={{ fontSize: "13px" }}>
                            <td>
                              {moment(
                                purchaseOrder.poApprovedDate ||
                                  purchaseOrder.createdAt
                              ).format("DD MMM YYYY")}{" "}
                              (Order creation date)
                            </td>
                            <td>
                              {purchaseOrder?.poAcceptanceDate
                                ? moment(purchaseOrder?.poAcceptanceDate)
                                    .add(
                                      purchaseOrder?.supplierRFQId?.leadTime,
                                      "days"
                                    )
                                    .format("DD MMM YYYY")
                                : ""}
                            </td>
                            <td>Original Promised Shipping Date</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="white-btn"
                  style={{
                    backgroundColor: "#2B96DC",
                    color: "#fff",
                    border: "1px solid #2B96DC",
                  }}
                  onClick={() => setShowExpectedDateModal(false)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              size="lg"
              dialogClassName="qoute-version-modal"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={esdModalForAcceptPO}
              onHide={handleCloseESDAcceptPOModal}
            >
              <Modal.Header closeButton>
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  style={{ fontSize: "15px" }}
                >
                  Please select the expected shipping date (ESD)
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div style={{ textAlign: "left" }}>
                  <p>
                    PSD:
                    {purchaseOrder?.poAcceptanceDate ? (
                      <>
                        {moment(purchaseOrder?.poAcceptanceDate)
                          .add(purchaseOrder?.supplierRFQId?.leadTime, "days")
                          .format("DD MMM YYYY")}
                      </>
                    ) : (
                      <>
                        {moment(Date.now())
                          .add(purchaseOrder?.supplierRFQId?.leadTime, "days")
                          .format("DD MMM YYYY")}
                      </>
                    )}
                  </p>
                  <h6>Select ESD : </h6>
                  <FormControl
                    type="date"
                    value={
                      expectedShippingDateSelected
                        ? expectedShippingDateSelected
                        : ""
                    }
                    onChange={(e) =>
                      setExpectedShippingDateSelected(e.target.value)
                    }
                    style={{ width: "300px" }}
                  />
                  <br />
                  {expectedShippingDateSelected &&
                  moment(Date.now())
                    .add(purchaseOrder?.supplierRFQId?.leadTime, "days")
                    .format("DD MMM YYYY") !=
                    moment(expectedShippingDateSelected).format(
                      "DD MMM YYYY"
                    ) ? (
                    <>
                      <h6>Reason to change ESD : </h6>
                      <FormControl
                        type="text"
                        value={esdChangeReason}
                        onChange={(e) => setEsdChangeReason(e.target.value)}
                        placeholder="Enter reason to change ESD (100 character max)"
                        maxLength="100"
                      />
                      <p style={{ color: "red", fontSize: "12px" }}>
                        Please provide an explanation for the discrepancy
                        between the expected shipping date (
                        {moment(expectedShippingDateSelected).format(
                          "DD MMM YYYY"
                        )}
                        ) and the promised shipping date(
                        {moment(Date.now())
                          .add(purchaseOrder?.supplierRFQId?.leadTime, "days")
                          .format("DD MMM YYYY")}
                        ).
                      </p>
                    </>
                  ) : (
                    <></>
                  )}

                  {purchaseOrder?.expectedShippingDates?.length > 0 ? (
                    <>
                      <br />
                      <h6>Expected Shipping Date Record : </h6>
                      <div className="parts-table" style={{ fontSize: "14px" }}>
                        <table>
                          <thead>
                            <tr>
                              <th style={{ fontSize: "12px" }}>Updated On</th>
                              <th style={{ fontSize: "12px" }}>
                                Expected Shipping Date
                              </th>
                              <th style={{ fontSize: "12px" }}>
                                Reason To Change
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {purchaseOrder?.expectedShippingDates?.map(
                              (item, index) => {
                                return (
                                  <tr key={index} style={{ fontSize: "13px" }}>
                                    <td>
                                      {moment(item.updatedDate).format(
                                        "DD MMM YYYY h:mm A"
                                      )}
                                    </td>
                                    <td>
                                      {moment(item.updateValue).format(
                                        "DD MMM YYYY"
                                      )}
                                    </td>
                                    <td>{item.reasonToChange}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="supplierButton"
                  onClick={() => {
                    handleProceedAcceptPOClick();
                  }}
                >
                  Proceed
                </Button>
                <Button
                  className="supplierButton"
                  onClick={handleCloseESDAcceptPOModal}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showModal}
              onHide={handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  style={{ fontSize: "15px" }}
                >
                  User Addresses
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="addresses">
                  <div className="cardContainer">
                    {addresses?.map((elem, index) => {
                      return (
                        <div key={index} style={{ marginBottom: "10px" }}>
                          <div className="card">
                            <div className="addressContainer">
                              <div className="name">
                                {elem.firstName} {elem?.lastName}
                              </div>

                              <div className="address">
                                {elem?.addressLineOne}
                                {elem?.addressLinetwo ? (
                                  <div className="landMark">
                                    {elem?.addressLinetwo}
                                  </div>
                                ) : null}

                                <div className="state">
                                  {elem?.city} {elem?.state}{" "}
                                </div>
                                <div className="postal-code">
                                  {elem?.postalCode}
                                </div>
                                <div className="country">{elem?.country}</div>
                                <div className="mobile">{elem?.phoneNo}</div>
                              </div>
                            </div>
                            <div className="addressButtons">
                              <div>
                                <button
                                  className="white-btn"
                                  style={{ height: "25px" }}
                                  onClick={() =>
                                    handleShippingAddressChange(elem)
                                  }
                                >
                                  Select
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleClose} className="req-quote-btn">
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              size="sm"
              dialogClassName="qoute-version-modal"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={raiseIssueModal}
              onHide={handleRaiseIssueClose}
            >
              <Modal.Header closeButton>
                <Modal.Title
                  id="contained-modal-title-vcenter"
                  style={{ fontSize: "15px" }}
                >
                  Raise an issue
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="raise-issue-div ">
                  <div className="progress-dots">
                    {/* +(raiseIssueStepCount===2)?'active':(raiseIssueStepCount>2)?"complete":"inactive" */}
                    <span
                      className={
                        raiseIssueStepCount === 0
                          ? "dot active"
                          : raiseIssueStepCount > 0
                          ? "dot complete"
                          : "dot inactive"
                      }
                    ></span>
                    <span
                      className={
                        raiseIssueStepCount === 1
                          ? "dot active"
                          : raiseIssueStepCount > 1
                          ? "dot complete"
                          : "dot inactive"
                      }
                    ></span>
                    <span
                      className={
                        raiseIssueStepCount === 2
                          ? "dot active"
                          : raiseIssueStepCount > 2
                          ? "dot complete"
                          : "dot inactive"
                      }
                    ></span>
                  </div>
                  <h5>
                    Step {`${raiseIssueStepCount + 1}`} of 3 :{" "}
                    {raiseIssueSteps[raiseIssueStepCount]}
                  </h5>
                  {raiseIssueStepCount === 0 ? (
                    <div className="raise-issue-part-div">
                      {partsWithIssue?.map((item, index) => {
                        return (
                          <div key={index} className="raise-issue-item">
                            <div className="price-box">
                              <div
                                className={
                                  item.selected ? "box-pr pr-checked" : "box-pr"
                                }
                                onClick={(e) =>
                                  handleSelectPartWithIssue(index)
                                }
                              >
                                <div className="pr-radio">
                                  <label
                                    className="btn box-in"
                                    htmlFor="btnradio4"
                                  >
                                    <span className="triangle-0">
                                      <img src={tickwhite} />
                                    </span>
                                    <span className="item-name">
                                      {item.selectedPartFile?.filename
                                        ? item.selectedPartFile?.filename
                                        : item.selectedPartFile?.originalname
                                        ? item.selectedPartFile?.originalname
                                        : "Item Description (No file was uploaded):\n" +
                                          item.description}
                                    </span>
                                    <span className="qty-div">
                                      Qty:{item.qty}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="raise-issue-check-div">
                        <div className="rd-left rd-box">
                          <input
                            type="checkbox"
                            id="testA"
                            name="radio-group"
                            checked={!isPartIssue}
                            onChange={handleNotPartIssueSelect}
                          />
                          <label htmlFor="testA">
                            Not a part issue. Purchase/ order related issue
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : raiseIssueStepCount === 1 ? (
                    <div>
                      <div className="quality-related-issue">
                        <p className="text-bold text-xl my-1">
                          Quality related
                        </p>
                        {qualityRelatedIssue.length > 0
                          ? qualityRelatedIssue.map((item, index) => {
                              return (
                                <div key={index} className="raise-issue-item">
                                  <div className="price-box">
                                    <div
                                      className={
                                        !item.selected
                                          ? "box-pr"
                                          : "box-pr pr-checked"
                                      }
                                      onClick={(e) =>
                                        handleQualityRelatedIssueSelect(index)
                                      }
                                    >
                                      <div className="pr-radio">
                                        <label
                                          className="btn box-in"
                                          htmlFor="btnradio4"
                                        >
                                          <span className="triangle-0">
                                            <img src={tickwhite} />
                                          </span>
                                          <span className="item-name">
                                            {item.name}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </div>

                      <div className="quality-related-issue">
                        <p>Purchase/Order related</p>
                        {orderRelatedIssue.length > 0
                          ? orderRelatedIssue.map((item, index) => {
                              return (
                                <div key={index} className="raise-issue-item">
                                  <div className="price-box">
                                    <div
                                      className={
                                        !item.selected
                                          ? "box-pr"
                                          : "box-pr pr-checked"
                                      }
                                      onClick={(e) =>
                                        handlePORelatedIssueSelect(index)
                                      }
                                    >
                                      <div className="pr-radio">
                                        <label
                                          className="btn box-in"
                                          htmlFor="btnradio4"
                                        >
                                          <span className="triangle-0">
                                            <img src={tickwhite} />
                                          </span>
                                          <span className="item-name">
                                            {item.name}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  ) : (
                    <div className="issue-description-div">
                      <div className="issue-description">
                        <textarea
                          placeholder="Write issue description here"
                          value={issueDescription}
                          onChange={(e) => setIssueDescription(e.target.value)}
                        />
                      </div>
                      <div className="downloadables">
                        <input
                          type="file"
                          id="BtnBrowseHidden"
                          name="files"
                          style={{ display: "none" }}
                          multiple
                          onChange={(e) => {
                            let filesForCheck = Array.from(e.target.files);
                            let isAllFilesValid = true;
                            filesForCheck.forEach(file => {
                                if (file.size > 10485760) {
                                  isAllFilesValid = false;
                                }
                              })
                        
                            if(!isAllFilesValid){
                              toast.error("File size should be less than 10 MB");
                              return;
                            }
                            handleIssueFileAttachmentAdd(e.target.files);
                          }}
                        />
                        <label htmlFor="BtnBrowseHidden" id="LblBrowse">
                          <p>+ Add Attachment</p>
                        </label>

                        {issueFileAttachment.length > 0
                          ? issueFileAttachment.map((item, i) => {
                              return (
                                <div key={i} className="issue-file-div">
                                  {item.name}
                                  <img
                                    src={closeImg}
                                    height="15px"
                                    onClick={() => {
                                      handleDeleteIssueAttachment(i);
                                    }}
                                  />
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="raise-issue-footer" style={{ width: "100%" }}>
                  <span
                    className="left-modal-footer"
                    onClick={() =>
                      (window.location = "mailto:info@8xparts.com")
                    }
                  >
                    Contact Customer Service
                  </span>
                  <div>
                    <span className="mx-3 " onClick={handleRaiseIssueBack}>
                      Back
                    </span>
                    <div
                      onClick={handleRaiseIssueNext}
                      className="req-quote-btn"
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      {raiseIssueStepCount === 2 ? "Submit" : "Next"}
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>

            <Chat
              RefId={purchaseOrder.pORefId}
              open={open}
              onDrawerClose={onClose}
            />
            {purchaseOrder.pORefId && (
              <div className="order-container">
                <div
                  className="header"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <Row>
                    <Col>
                      <div className="left-header">
                        <p>Shipping Address:</p>
                        {purchaseOrder.shippingAddress ? (
                          <>
                            <div className="addressContainer">
                              <div className="name">
                                {purchaseOrder.shippingAddress.firstName}{" "}
                                {purchaseOrder.shippingAddress?.lastName}
                              </div>

                              <div className="address">
                                {purchaseOrder.shippingAddress?.addressLineOne}
                                {purchaseOrder.shippingAddress
                                  ?.addressLinetwo ? (
                                  <div className="landMark">
                                    {
                                      purchaseOrder.shippingAddress
                                        ?.addressLinetwo
                                    }
                                  </div>
                                ) : null}

                                <div className="state">
                                  {purchaseOrder.shippingAddress?.city}{" "}
                                  {purchaseOrder.shippingAddress?.state}{" "}
                                </div>
                                <div className="postal-code">
                                  {purchaseOrder.shippingAddress?.postalCode}
                                </div>
                                <div className="country">
                                  {purchaseOrder.shippingAddress?.country}
                                </div>
                                <div className="mobile">
                                  {purchaseOrder.shippingAddress?.phoneNo}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                        <button
                          className="white-btn"
                          onClick={handleChangeAddress}
                        >
                          Change Address
                        </button>
                      </div>

                      <div className="left-header">
                        <p>Supplier Name:</p>
                        <h6>{purchaseOrder.supplierId?.companyName}</h6>

                        <p>PO Status :</p>

                        <h6>
                          {purchaseOrder.status == "Shipped"
                            ? purchaseOrder.status
                            : billsForPurchaseOrder.length > 0 &&
                              purchaseOrder.status == "Order Processing"
                            ? purchaseOrder.status + " | Shipped"
                            : purchaseOrder.status}
                        </h6>
                        {/* {purchaseOrder?.status} */}
                        {purchaseOrder?.poAcceptanceDate ? (
                          <>
                            <p>Acceptance Date :</p>
                            <h6>
                              {moment(purchaseOrder?.poAcceptanceDate).format(
                                "DD MMM YYYY"
                              )}
                            </h6>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Col>
                    <Col>
                      <div className="right-header">
                        <p>Order Number:</p>
                        <h6 style={{ display: "inline", marginRight: "10px" }}>
                          #{purchaseOrder.pORefId}
                        </h6>
                      </div>
                      <div className="right-header">
                        <p>Project Name :</p>
                        <h6 style={{ display: "inline", marginRight: "10px" }}>
                          {purchaseOrder?.supplierRFQId?.rfqId?.projectName ||
                            "Not Found"}
                        </h6>
                      </div>

                      <div className="right-header po-file-container">
                        <p>Process Type :</p>
                        <h6>
                          {purchaseOrder?.supplierRFQId?.rfqId?.selectedProcess
                            ? processType[
                                purchaseOrder?.supplierRFQId?.rfqId
                                  ?.selectedProcess - 1
                              ]?.name || "Unavailable"
                            : "Unavailable"}
                        </h6>

                        <p>PO Number :</p>
                        <h6 className="flex gap-x-2 items-center">
                          <FormControl
                            type="text"
                            disabled={!isPoNumberEditing}
                            style={{ width: "300px" }}
                              ref={poInputRef} 
                              onChange={(e) => {
                              // setIsChanged({ ...isChanged, poDocInfo: true });
                              setPurchaseOrder({
                                ...purchaseOrder,
                                purchaseOrderNumber: e.target.value,
                              });
                            }}
                            value={
                              purchaseOrder.purchaseOrderNumber
                                ? purchaseOrder.purchaseOrderNumber
                                : ""
                            }
                            placeholder="Enter PO Number"
                          />
                          {!isPoNumberEditing ? (
                            <div onClick={handlePoNumberEditing}>
                              <EditIcon additionalClass={"text-primaryColor"} />
                            </div>
                          ) : (
                            <Fragment>
                              <div onClick={handleNewPoNumberSubmit}>
                                <CorrectIcon/>
                              </div>
                              <div onClick={handlePoNumberChangeCancel}>
                                <DeleteIcon additionalClasses={'text-red-500'}/>
                              </div>
                            </Fragment>
                          )}
                        </h6>
                        <div className="po-doc-left">
                          <p>PO Document :</p>
                          <button
                            className="common-supplier-button"
                            onClick={handlePODownloadClick}
                          >
                            Download PO{" "}
                          </button>
                        </div>

                        <p>PO Created At :</p>
                        <h6>
                          {purchaseOrder && purchaseOrder.status == "Draft" ? (
                            <>
                              {moment(purchaseOrder.createdAt).format(
                                "DD MMM YYYY"
                              )}
                            </>
                          ) : (
                            <>
                              {moment(
                                purchaseOrder.poApprovedDate ||
                                  purchaseOrder.createdAt
                              ).format("DD MMM YYYY")}
                            </>
                          )}
                        </h6>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="left-header">
                      <div style={{ textAlign: "left" }}>
                        <h6 className="font-bold my-2">PO TnC :&nbsp;&nbsp;</h6>
                        <textarea
                          rows="3"
                          style={{ width: "100%" }}
                          placeholder="Enter notes here"
                          value={poNotes}
                          onChange={(e) => setPoNotes(e.target.value)}
                          className="p-1"
                        ></textarea>
                      </div>
                      <div>
                        <div>
                          <button
                            className="common-supplier-button"
                            onClick={handleUpdatePONotes}
                          >
                            Update PO TnC
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="left-header">
                      <p className="font-bold my-2">Admin Comments :</p>
                      <textarea
                        rows="3"
                        style={{ width: "100%" }}
                        placeholder="Enter comment here"
                        className="p-1"
                        value={purchaseOrder?.adminComment}
                        onChange={(e) => {
                          setIsChanged({ ...isChanged, adminComment: true });
                          setPurchaseOrder({
                            ...purchaseOrder,
                            adminComment: e.target.value,
                          });
                        }}
                      ></textarea>
                      <button
                        className="common-supplier-button"
                        style={{ marginBottom: "30px" }}
                        onClick={handleOrderAdminCommentUpdate}
                      >
                        Update Comment
                      </button>
                      <button
                        className="common-supplier-button"
                        style={{ marginBottom: "30px" }}
                        onClick={() =>
                          navigate(
                            `/admin/rfq-details/${purchaseOrder?.supplierRFQId?.rfqId?._id}`
                          )
                        }
                      >
                        Go To RFQ
                      </button>
                      <button
                        className="common-supplier-button"
                        style={{ marginBottom: "30px" }}
                        onClick={() => setShowExpectedDateModal(true)}
                      >
                        View ESD Record
                      </button>
                    </div>
                  </Row>
                </div>

                <Accordion>
                  <div className="grid-div or-details-row">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="req-box" ref={orderInformationRef}>
                          <h6>Order Information</h6>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body
                        style={{ background: "#edd8ca" }}
                        className="show-accordiion"
                      >
                        {isCreateBillActive ? (
                          <>
                            <div
                              className="bill-form-container"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="bill-form-row">
                                <div>
                                  <label htmlFor="billNumber">
                                    Enter Bill Number:
                                  </label>
                                  <Form.Control
                                    id="billNumber"
                                    type="text"
                                    value={supplierBillNumber}
                                    placeholder="Enter Bill Number"
                                    onChange={(e) =>
                                      setSupplierBillNumber(e.target.value)
                                    }
                                  />
                                </div>
                                <div>
                                  <label htmlFor="billDocument">
                                    Bill Document:
                                  </label>
                                  <br />
                                  {billDocument?.name ? (
                                    <div className="flex justify-center gap-x-2 items-center">
                                      <a
                                        className="downloadables"
                                        href={URL.createObjectURL(billDocument)}
                                        download={billDocument?.filename}
                                        target="_blank"
                                        style={{ fontSize: "12px" }}
                                      >
                                        {billDocument?.name}
                                      </a>
                                      <a
                                        href={URL.createObjectURL(billDocument)}
                                        download={billDocument?.filename}
                                        target="_blank"
                                      >
                                        <DownloadIcon
                                          additionalClass={"text-primaryColor"}
                                        />
                                      </a>

                                      <div
                                        onClick={() => setBillDocument(null)}
                                      >
                                        <DeleteIcon
                                          additionalClasses={"text-red-500"}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <label
                                        // className="bill-button"
                                        className="h-7   w-max px-4 text-sm rounded bg-white !text-primaryColor font-medium  border border-red-500 flex justify-center items-center "
                                        htmlFor={`upload-bill-doc`}
                                      >
                                        Upload Bill Document
                                      </label>
                                      <Form.Control
                                        id={`upload-bill-doc`}
                                        type="file"
                                        style={{ visibility: "hidden" }}
                                        onChange={(e) => {
                                          let filesForCheck = Array.from(
                                            e.target.files
                                          );
                                          let isAllFilesValid = true;
                                          filesForCheck.forEach(file => {
                                              if (file.size > 10485760) {
                                                isAllFilesValid = false;
                                              }
                                            })
                                      
                                          if(!isAllFilesValid){
                                            toast.error("File size should be less than 10 MB");
                                            return;
                                          }

                                          setBillDocument(e.target.files[0]);
                                        }}
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {purchaseOrder.supplierRFQId ? (
                          <div
                            className="table-container"
                            style={{
                              width: "100%",
                              overflowX: "auto",
                              padding: "10px",
                            }}
                          >
                            <table className="">
                              <>
                                <thead>
                                  <tr className="heading-row">
                                    <th>Part Name</th>
                                    <th>Part Description</th>
                                    <th>Notes</th>
                                    <th>Part Notes</th>
                                    <th>PO Qty</th>
                                    <th>Available Qty</th>
                                    <th>Bill Qty</th>
                                    <th>Price</th>
                                    <th>Amount</th>
                                    <th>Delete</th>
                                    <th>Edit</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {purchaseOrder.supplierRFQId.partsData
                                    .length > 0 &&
                                    purchaseOrder.supplierRFQId.partsData.map(
                                      (val, i) => {
                                        return (
                                          <tr key={i}>
                                            <td
                                              style={{
                                                textAlign: "left",
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                                whiteSpace: "normal",
                                                maxWidth: "150px",
                                              }}
                                            >
                                              {val.partName}
                                            </td>

                                            <td
                                              style={{
                                                textAlign: "left",
                                                wordWrap: "break-word",
                                                wordBreak: "break-all",
                                                whiteSpace: "normal",
                                                maxWidth: "150px",
                                              }}
                                            >
                                              {purchaseOrder.supplierRFQId
                                                .selectedProcess >= 3 ? (
                                                <>
                                                  <textarea
                                                    style={{ width: "100%" }}
                                                    value={val?.description}
                                                    type="text"
                                                    placeholder="Enter Part Description"
                                                    onChange={(e) =>
                                                      handleItemFieldEdit(
                                                        e,
                                                        i,
                                                        "description"
                                                      )
                                                    }
                                                    disabled={
                                                      !isPartsEditable[i]
                                                    }
                                                    className="border p-1"
                                                  />
                                                </>
                                              ) : (
                                                <>
                                                  <div className="desc-part">
                                                    <textarea
                                                      style={{ width: "100%" }}
                                                      value={val?.description}
                                                      type="text"
                                                      placeholder="Enter Part Description"
                                                      onChange={(e) =>
                                                        handleItemFieldEdit(
                                                          e,
                                                          i,
                                                          "description"
                                                        )
                                                      }
                                                      disabled={
                                                        !isPartsEditable[i]
                                                      }
                                                      className="border p-1 min-w-[150px]"
                                                    />
                                                    {val?.materialType ? (
                                                      <li>
                                                        {
                                                          material.filter(
                                                            (materialItem) =>
                                                              materialItem._id ==
                                                              val.materialType
                                                          )[0]?.name
                                                        }
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {val?.subMaterial ? (
                                                      <li>
                                                        {
                                                          subMaterial.filter(
                                                            (subMaterialItem) =>
                                                              subMaterialItem._id ==
                                                              val.subMaterial
                                                          )[0]?.name
                                                        }
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {val?.surfaceTreatment ? (
                                                      <li>
                                                        {
                                                          surfaceTreatment.filter(
                                                            (
                                                              surfaceTreatmentItem
                                                            ) =>
                                                              surfaceTreatmentItem._id ==
                                                              val.surfaceTreatment
                                                          )[0]?.name
                                                        }
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {val?.surfaceFinish ? (
                                                      <li>
                                                        {
                                                          surfaceFinish.filter(
                                                            (
                                                              surfaceFinishItem
                                                            ) =>
                                                              surfaceFinishItem._id ==
                                                              val.surfaceFinish
                                                          )[0]?.name
                                                        }
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {val?.tolerance ? (
                                                      <li>
                                                        {
                                                          tolerance.filter(
                                                            (toleranceItem) =>
                                                              toleranceItem._id ==
                                                              val.tolerance
                                                          )[0]?.name
                                                        }
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {val?.partMarking ? (
                                                      <li>
                                                        {
                                                          partMarking.filter(
                                                            (partMarkingItem) =>
                                                              partMarkingItem._id ==
                                                              val.partMarking
                                                          )[0]?.name
                                                        }
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {val?.layer ? (
                                                      <li>
                                                        {
                                                          layer.filter(
                                                            (layerItem) =>
                                                              layerItem._id ==
                                                              val.layer
                                                          )[0]?.name
                                                        }
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {val?.noOfThread ? (
                                                      <li>{val?.noOfThread}</li>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </>
                                              )}
                                            </td>
                                            <td>
                                              <p>{val.notes}</p>
                                            </td>
                                            <td>
                                              <textarea
                                                style={{ width: "100%" }}
                                                className="border p-1 min-w-[150px]"
                                                value={val?.partNotes}
                                                type="text"
                                                placeholder="Enter Part Notes"
                                                onChange={(e) =>
                                                  handleItemFieldEdit(
                                                    e,
                                                    i,
                                                    "partNotes"
                                                  )
                                                }
                                                disabled={!isPartsEditable[i]}
                                              />
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              <input
                                                className="border p-1"
                                                value={val?.qty}
                                                style={{ width: "60px" }}
                                                type="number"
                                                onWheel={
                                                  numberInputOnWheelPreventChange
                                                }
                                                placeholder="Enter Quantity"
                                                onChange={(e) =>
                                                  handleItemFieldEdit(
                                                    e,
                                                    i,
                                                    "qty"
                                                  )
                                                }
                                                onKeyDown={(e) =>
                                                  ["e", "E", "+", "-"].includes(
                                                    e.key
                                                  ) && e.preventDefault()
                                                }
                                                disabled={!isPartsEditable[i]}
                                              ></input>{" "}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                              {" "}
                                              {availableQtyArr[val?._id]}
                                            </td>

                                            <td style={{ textAlign: "center" }}>
                                              <input
                                                className="border p-1"
                                                type="number"
                                                value={billPartsData[val?._id]}
                                                onWheel={
                                                  numberInputOnWheelPreventChange
                                                }
                                                placeholder="Bill Qty"
                                                onChange={(e) =>
                                                  handeBillItemChange(
                                                    e,
                                                    val?._id
                                                  )
                                                }
                                                onKeyDown={(e) =>
                                                  ["e", "E", "+", "-"].includes(
                                                    e.key
                                                  ) && e.preventDefault()
                                                }
                                                disabled={
                                                  !availableQtyArr[val?._id] ||
                                                  !isCreateBillActive
                                                }
                                                style={{ width: "60px" }}
                                              />
                                            </td>
                                            <td>
                                              <input
                                                className="border p-1"
                                                type="number"
                                                onWheel={
                                                  numberInputOnWheelPreventChange
                                                }
                                                placeholder="Enter item price"
                                                onChange={(e) =>
                                                  handleItemFieldEdit(
                                                    e,
                                                    i,
                                                    "price"
                                                  )
                                                }
                                                onKeyDown={(e) =>
                                                  ["e", "E", "+", "-"].includes(
                                                    e.key
                                                  ) && e.preventDefault()
                                                }
                                                value={val?.price}
                                                disabled={!isPartsEditable[i]}
                                                style={{ width: "60px" }}
                                              ></input>
                                            </td>
                                            <td>
                                              <p>
                                                {" "}
                                                {currencyFormatter(
                                                  Number(
                                                    val.qty * val.price
                                                  ).toFixed(2)
                                                )}
                                              </p>
                                            </td>
                                            <td>
                                              <div
                                                disabled={!isPartsEditable[i]}
                                                onClick={() =>
                                                  handleDeleteItemFromOrder(i)
                                                }
                                              >
                                                <DeleteIcon
                                                  additionalClasses={` ${
                                                    isPartsEditable[i]
                                                      ? "text-red-500"
                                                      : "text-gray-500"
                                                  } mx-auto h-5`}
                                                />
                                              </div>
                                            </td>

                                            <td>
                                              <Switch
                                                value={
                                                  isPartsEditable[i]
                                                    ? isPartsEditable[i]
                                                    : 0
                                                }
                                                onChange={() => {
                                                  handleEditableField(i);
                                                }}
                                              />
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  <tr>
                                    <td></td>
                                    <td>Adjustments </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      ${" "}
                                      <input
                                        value={purchaseOrder?.adjustmentValue}
                                        style={{ width: "60px" }}
                                        type="number"
                                        onKeyDown={(e) =>
                                          ["e", "E", "+"].includes(e.key) &&
                                          e.preventDefault()
                                        }
                                        onChange={(e) => {
                                          handleChargeChange(
                                            "adjustmentValue",
                                            e.target.value
                                          );
                                        }}
                                        disabled={isCreateBillActive}
                                      />
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </>
                            </table>

                            {purchaseOrder.supplierRFQId.partsData.length >
                            0 ? (
                              <div className="w-full px-2 my-2 h-5">
                                <h6 className="text-start font-bold my-1">
                                  Documentation required :{" "}
                                </h6>
                                <div className="flex justify-between gap-x-2 w-full ">
                                  <div>
                                    <input
                                      type="checkbox"
                                      id="sign-check-cc"
                                      name="checkbox"
                                      value={
                                        purchaseOrder?.supplierRFQId?.rfqId
                                          ?.isConformanceCertificateRequested
                                      }
                                      checked={
                                        purchaseOrder?.supplierRFQId?.rfqId
                                          ?.isConformanceCertificateRequested
                                      }
                                      onChange={() => {
                                        setPurchaseOrder({
                                          ...purchaseOrder,
                                          supplierRFQId: {
                                            ...purchaseOrder?.supplierRFQId,
                                            rfqId: {
                                              ...purchaseOrder?.supplierRFQId
                                                ?.rfqId,
                                              isConformanceCertificateRequested:
                                                !purchaseOrder?.supplierRFQId
                                                  ?.rfqId
                                                  ?.isConformanceCertificateRequested,
                                            },
                                          },
                                        });
                                        setIsChanged({
                                          ...isChanged,
                                          checkBoxInfo: true,
                                        });
                                      }}
                                    />
                                    <label
                                      htmlFor="sign-check-cc"
                                      style={{ fontSize: "12px" }}
                                    >
                                      Certificate of conformance
                                    </label>
                                  </div>

                                  <div>
                                    <input
                                      type="checkbox"
                                      id="sign-check-dr"
                                      name="checkbox"
                                      value={
                                        purchaseOrder?.supplierRFQId?.rfqId
                                          ?.isDimensionalReportRequested
                                      }
                                      checked={
                                        purchaseOrder?.supplierRFQId?.rfqId
                                          ?.isDimensionalReportRequested
                                      }
                                      onChange={() => {
                                        setPurchaseOrder({
                                          ...purchaseOrder,
                                          supplierRFQId: {
                                            ...purchaseOrder?.supplierRFQId,
                                            rfqId: {
                                              ...purchaseOrder?.supplierRFQId
                                                ?.rfqId,
                                              isDimensionalReportRequested:
                                                !purchaseOrder?.supplierRFQId
                                                  ?.rfqId
                                                  ?.isDimensionalReportRequested,
                                            },
                                          },
                                        });
                                        setIsChanged({
                                          ...isChanged,
                                          checkBoxInfo: true,
                                        });
                                      }}
                                    />
                                    <label
                                      htmlFor="sign-check-dr"
                                      style={{ fontSize: "12px" }}
                                    >
                                      Dimensional Inspection Report
                                    </label>
                                  </div>

                                  <div>
                                    <input
                                      type="checkbox"
                                      id="sign-check-qr"
                                      name="checkbox"
                                      value={
                                        purchaseOrder?.supplierRFQId?.rfqId
                                          ?.isQrTrackingRequested
                                      }
                                      checked={
                                        purchaseOrder?.supplierRFQId?.rfqId
                                          ?.isQrTrackingRequested
                                      }
                                      onChange={() => {
                                        setPurchaseOrder({
                                          ...purchaseOrder,
                                          supplierRFQId: {
                                            ...purchaseOrder?.supplierRFQId,
                                            rfqId: {
                                              ...purchaseOrder?.supplierRFQId
                                                ?.rfqId,
                                              isQrTrackingRequested:
                                                !purchaseOrder?.supplierRFQId
                                                  ?.rfqId
                                                  ?.isQrTrackingRequested,
                                            },
                                          },
                                        });
                                        setIsChanged({
                                          ...isChanged,
                                          checkBoxInfo: true,
                                        });
                                      }}
                                    />
                                    <label
                                      htmlFor="sign-check-qr"
                                      style={{ fontSize: "12px" }}
                                    >
                                      QR Tracking
                                    </label>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div className="total-amount-order flex font-bold mt-5 justify-end  w-full">
                              <h6 className="order-price">
                                Total Amount :{" "}
                                {currencyFormatter(
                                  Number(purchaseOrder.totalAmount).toFixed(2)
                                )}
                              </h6>
                            </div>
                          </div>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                  <div className="grid-div  or-details-row ">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div className="req-box">
                          <h6>QR File </h6>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body
                        style={{ background: "#edd8ca" }}
                        className="show-accordiion"
                      >
                        <div className="qr-code-div ">
                          <div className="apply-check h-5 flex justify-start my-2 items-center">
                            <input
                              type="checkbox"
                              id="sign-check-qr-info"
                              name="checkbox-info"
                              value={
                                purchaseOrder?.supplierRFQId?.rfqId
                                  ?.isQrTrackingRequested
                              }
                              checked={
                                purchaseOrder?.supplierRFQId?.rfqId
                                  ?.isQrTrackingRequested
                              }
                              disabled
                            />
                            <label
                              htmlFor="sign-check-qr-info"
                              className="gap-x-2"
                              style={{ fontSize: "15px" }}
                            >
                              QR Tracking Requested?
                            </label>
                          </div>

                          <p style={{ fontSize: "14px" }}>
                            Link for QR code creation :{" "}
                          </p>
                          {purchaseOrder.salesOrderId ? (
                            <div style={{ fontSize: "12px" }}>
                              <p>{qrCodeLink}</p>
                              <button
                                className="white-btn"
                                onClick={() => {
                                  try {
                                    navigator.clipboard.writeText(qrCodeLink);
                                  } catch (err) {
                                    toast.error("Something went wrong");
                                  }
                                }}
                              >
                                Copy url
                              </button>
                            </div>
                          ) : (
                            <>
                              <p style={{ color: "red", fontSize: "12px" }}>
                                No sales order is associated with this purchase
                                order.
                              </p>
                            </>
                          )}

                          <div className="qr-file-container">
                            <div
                              style={{ textAlign: "left", marginTop: "10px" }}
                            >
                              <p>QR Code Image : </p>
                              {purchaseOrder?.qrCodeImage?.name ||
                              purchaseOrder?.qrCodeImage?.filename ? (
                                <div className="flex items-center ">
                                  {purchaseOrder?.qrCodeImage?.name ? (
                                    <div className="flex gap-x-2 items-center text-[12px]">
                                      <a
                                        className="downloadables "
                                        href={URL.createObjectURL(
                                          purchaseOrder?.qrCodeImage
                                        )}
                                        download={
                                          purchaseOrder?.qrCodeImage?.name
                                        }
                                      >
                                        {purchaseOrder?.qrCodeImage?.name}
                                      </a>
                                      <a
                                        href={URL.createObjectURL(
                                          purchaseOrder?.qrCodeImage
                                        )}
                                        download={
                                          purchaseOrder?.qrCodeImage?.name
                                        }
                                      >
                                        <DownloadIcon
                                          additionalClass={"text-primaryColor"}
                                        />
                                      </a>
                                    </div>
                                  ) : (
                                    <div className="flex gap-x-2 items-center">
                                      <span
                                        className="downloadables"
                                        onClick={() =>
                                          download(
                                            purchaseOrder?.qrCodeImage
                                              .originalname,
                                            `${process.env.REACT_APP_SUPPLIER_BACKEND}/` +
                                              purchaseOrder?.qrCodeImage.path
                                          )
                                        }
                                      >
                                        {purchaseOrder?.qrCodeImage
                                          ?.originalname ||
                                          purchaseOrder?.qrCodeImage?.name}
                                      </span>
                                      <span>
                                        <DownloadIcon
                                          additionalClass={"text-primaryColor"}
                                        />
                                      </span>
                                    </div>
                                  )}
                                  <div
                                    src={deleteImageEnabled}
                                    alt="delete"
                                    className="h-5"
                                    onClick={() => {
                                      setPurchaseOrder({
                                        ...purchaseOrder,
                                        qrCodeImage: "",
                                      });
                                      setIsChanged({
                                        ...isChanged,
                                        qRDetailsChanged: true,
                                      });
                                    }}
                                  >
                                    <DeleteIcon
                                      additionalClasses={"text-red-500"}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <p className="upload-po-text">
                                    Upload QR Code Image
                                  </p>
                                </>
                              )}
                              {!purchaseOrder?.qrCodeImage && (
                                <div>
                                  <input
                                    type="file"
                                    id="qr-code-image"
                                    accept="image/png, image/jpeg"
                                    className="input-qr-image  my-2"
                                    onChange={(e) => {
                                      let filesForCheck = Array.from(
                                        e.target.files
                                      );
                                      let isAllFilesValid = true;
                                      filesForCheck.forEach((file) => {
                                        if (file.size > 10485760) {
                                          isAllFilesValid = false;
                                        }
                                      });

                                      if (!isAllFilesValid) {
                                        toast.error(
                                          "File size should be less than 10 MB"
                                        );
                                        return;
                                      }

                                      setPurchaseOrder({
                                        ...purchaseOrder,
                                        qrCodeImage: e.target.files[0],
                                      });
                                      setIsChanged({
                                        ...isChanged,
                                        qRDetailsChanged: true,
                                      });
                                    }}
                                  />
                                  <label
                                    // className="qr-file-input__label"
                                    htmlFor="qr-code-image"
                                  >
                                    <span className="h-8  w-max px-4 text-sm rounded bg-white text-primaryColor font-medium  border border-red-500 flex justify-center items-center ">
                                      Browse
                                    </span>
                                  </label>
                                </div>
                              )}
                            </div>

                            <div
                              style={{ textAlign: "left", marginTop: "10px" }}
                            >
                              <p>QR Code Drawing File : </p>
                              {purchaseOrder?.qrCodeDrawingFile?.name ||
                              purchaseOrder?.qrCodeDrawingFile?.originalname ? (
                                <div className="flex gap-x-2">
                                  {purchaseOrder?.qrCodeDrawingFile?.name ? (
                                    <div className="flex items-center gap-x-2 ">
                                      <a
                                        className="downloadables text-[12px]"
                                        href={URL.createObjectURL(
                                          purchaseOrder?.qrCodeDrawingFile
                                        )}
                                        download={
                                          purchaseOrder?.qrCodeDrawingFile?.name
                                        }
                                      >
                                        {purchaseOrder?.qrCodeDrawingFile?.name}
                                      </a>
                                      <a
                                        href={URL.createObjectURL(
                                          purchaseOrder?.qrCodeDrawingFile
                                        )}
                                        download={
                                          purchaseOrder?.qrCodeDrawingFile?.name
                                        }
                                      >
                                        <DownloadIcon
                                          additionalClass={"text-primaryColor"}
                                        />
                                      </a>
                                    </div>
                                  ) : (
                                    <span
                                      className="downloadables flex gap-x-2 items-center"
                                      onClick={() =>
                                        download(
                                          purchaseOrder?.qrCodeDrawingFile
                                            .originalname,
                                          `${process.env.REACT_APP_SUPPLIER_BACKEND}/` +
                                            purchaseOrder?.qrCodeDrawingFile
                                              .path
                                        )
                                      }
                                    >
                                      <span className="text-[12px]">
                                        {purchaseOrder?.qrCodeDrawingFile
                                          ?.originalname ||
                                          purchaseOrder?.qrCodeDrawingFile
                                            ?.name}
                                        <div>
                                          <DownloadIcon />
                                        </div>
                                      </span>
                                    </span>
                                  )}
                                  <div
                                    className="border flex justify-center items-center gap-x-2"
                                    onClick={() => {
                                      setPurchaseOrder({
                                        ...purchaseOrder,
                                        qrCodeDrawingFile: "",
                                      });
                                      setIsChanged({
                                        ...isChanged,
                                        qRDetailsChanged: true,
                                      });
                                    }}
                                  >
                                    <DeleteIcon
                                      additionalClasses={"text-red-500"}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <p className="upload-po-text">
                                    Upload QR Code Drawing File
                                  </p>
                                </>
                              )}
                              {!purchaseOrder?.qrCodeDrawingFile && (
                                <div>
                                  {" "}
                                  <input
                                    type="file"
                                    id="qr-code-drawing-file"
                                    className="input-qr-image"
                                    onChange={(e) => {
                                      let filesForCheck = Array.from(
                                        e.target.files
                                      );
                                      let isAllFilesValid = true;
                                      filesForCheck.forEach((file) => {
                                        if (file.size > 10485760) {
                                          isAllFilesValid = false;
                                        }
                                      });

                                      if (!isAllFilesValid) {
                                        toast.error(
                                          "File size should be less than 10 MB"
                                        );
                                        return;
                                      }

                                      setPurchaseOrder({
                                        ...purchaseOrder,
                                        qrCodeDrawingFile: e.target.files[0],
                                      });
                                      setIsChanged({
                                        ...isChanged,
                                        qRDetailsChanged: true,
                                      });
                                    }}
                                  />
                                  <label
                                    // className="qr-file-input__label"
                                    htmlFor="qr-code-drawing-file"
                                  >
                                    <span className="h-8 mt-2 w-max px-4 text-sm rounded bg-white text-primaryColor font-medium  border border-red-500 flex justify-center items-center ">
                                      Browse
                                    </span>
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>

                  <div className="grid-div  or-details-row">
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        <div className="req-box">
                          <h6>Bills </h6>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body
                        style={{ background: "#edd8ca" }}
                        className="show-accordiion"
                      >
                        <div className="table-container">
                          <table>
                            <thead>
                              <tr>
                                <th>Bill Ref</th>
                                <th>Supplier Bill Number</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Sent Date</th>
                                <th>Shipping Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {billsForPurchaseOrder.length > 0 ? (
                                billsForPurchaseOrder?.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <Link
                                          className="text-primaryColor"
                                          to={`/admin/bill-details/${item._id}`}
                                        >
                                          {item.billNumber}
                                        </Link>
                                      </td>
                                      <td>{item.supplierBillNumber}</td>
                                      <td>
                                        {currencyFormatter(
                                          item.billTotalAmount
                                        )}
                                      </td>
                                      <td>{capitalizedStatus(item.status)}</td>

                                      <td>
                                        {moment(item.createdAt).format(
                                          "DD MMM YYYY"
                                        )}
                                      </td>

                                      <td>
                                        {item.shippedDate
                                          ? moment(item.shippedDate).format(
                                              "DD MMM YYYY"
                                            )
                                          : "NA"}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                          {billsForPurchaseOrder.length == 0 ? (
                            <>
                              <p
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                }}
                                className=" text-sm"
                              >
                                {" "}
                                No Bill is created for this purchase order.
                                Click on "Create New Bill" to create bill for
                                this purchase order.
                              </p>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>

                  {purchaseOrder.isCompleted ? (
                    <>
                      <div className="grid-div  or-details-row">
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            <div className="req-box">
                              <h6> Feedback </h6>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body style={{ background: "#edd8ca" }}>
                            <>
                              <div className="pro-bar show-accordiion" >
                                <div
                                  className="pro-text"
                                  style={{ textAlign: "left" }}
                                >
                                  <h6>
                                    Share feedback here, this rating and
                                    feedback will be visible to supplier in
                                    supplier portal:
                                  </h6>
                                  <div className="pro-bar-two">
                                    <div className="col">
                                      <h6>Quality</h6>
                                      <div className="flex my-2">
                                        <img
                                          src={
                                            purchaseOrder?.qualityRating >= 1
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(1, "qualityRating")
                                          }
                                        />
                                        <img
                                          src={
                                            purchaseOrder?.qualityRating >= 2
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(2, "qualityRating")
                                          }
                                        />
                                        <img
                                          src={
                                            purchaseOrder?.qualityRating >= 3
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(3, "qualityRating")
                                          }
                                        />
                                        <img
                                          src={
                                            purchaseOrder?.qualityRating >= 4
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(4, "qualityRating")
                                          }
                                        />
                                        <img
                                          src={
                                            purchaseOrder?.qualityRating >= 5
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(5, "qualityRating")
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <h6>Value for money</h6>
                                      <div className="flex my-2">
                                        <img
                                          src={
                                            purchaseOrder?.valueForMoneyRating >=
                                            1
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(
                                              1,
                                              "valueForMoneyRating"
                                            )
                                          }
                                        />
                                        <img
                                          src={
                                            purchaseOrder?.valueForMoneyRating >=
                                            2
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(
                                              2,
                                              "valueForMoneyRating"
                                            )
                                          }
                                        />
                                        <img
                                          src={
                                            purchaseOrder?.valueForMoneyRating >=
                                            3
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(
                                              3,
                                              "valueForMoneyRating"
                                            )
                                          }
                                        />
                                        <img
                                          src={
                                            purchaseOrder?.valueForMoneyRating >=
                                            4
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(
                                              4,
                                              "valueForMoneyRating"
                                            )
                                          }
                                        />
                                        <img
                                          src={
                                            purchaseOrder?.valueForMoneyRating >=
                                            5
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(
                                              5,
                                              "valueForMoneyRating"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <h6>Overall Rating</h6>
                                      <div className="flex  my-2">
                                        <img
                                          src={
                                            purchaseOrder?.overAllRating >= 1
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(1, "overAllRating")
                                          }
                                        />
                                        <img
                                          src={
                                            purchaseOrder?.overAllRating >= 2
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(2, "overAllRating")
                                          }
                                        />
                                        <img
                                          src={
                                            purchaseOrder?.overAllRating >= 3
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(3, "overAllRating")
                                          }
                                        />
                                        <img
                                          src={
                                            purchaseOrder?.overAllRating >= 4
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(4, "overAllRating")
                                          }
                                        />
                                        <img
                                          src={
                                            purchaseOrder?.overAllRating >= 5
                                              ? starsIcon
                                              : starsIconGrey
                                          }
                                          alt="rating"
                                          onClick={(e) =>
                                            handleRating(5, "overAllRating")
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col">
                                      <h6>Comment / Suggestions : </h6>
                                      <textarea
                                        className="border p-1"
                                        cols="30"
                                        rows="5"
                                        placeholder="Enter you feedback here"
                                        value={purchaseOrder?.feedBackComment}
                                        onChange={(e) =>
                                          setPurchaseOrder({
                                            ...purchaseOrder,
                                            feedBackComment: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div
                                      style={{
                                        position: "relative",
                                        marginTop: "116px",
                                      }}
                                    >
                                      <span
                                        className="white-btn"
                                        onClick={handleFeedBackSubmit}
                                      >
                                        Submit
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {Object.keys(orderIssue).length > 0 ? (
                    <div className="grid-div  or-details-row">
                      <Accordion.Item eventKey="6">
                        <Accordion.Header>
                          <div className="req-box">
                            <h6>Issues </h6>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => {
                              setKey(k);
                              handleShowSelectedIssue(k);
                            }}
                            className="mb-3 qoute-version-tabs"
                          >
                            {allIssuesForOrder.map((item, i) => {
                              return (
                                <Tab
                                  eventKey={i}
                                  key={i}
                                  title={`Issues ${i + 1}`}
                                ></Tab>
                              );
                            })}
                          </Tabs>
                          {Object.keys(orderIssue).length > 0 && (
                            <div className="issue-div-admin">
                              <div className="issue-col-admin">
                                <h6>Issue Creation Date :</h6>
                                <p>
                                  {" "}
                                  {moment(orderIssue.createdAt).format(
                                    "DD MMM YYYY"
                                  )}
                                </p>
                                <h6>Parts with Issue : </h6>
                                {orderIssue?.partsWithIssue?.length > 0 ? (
                                  orderIssue.partsWithIssue.map(
                                    (orderIssueIn, i) => {
                                      return orderIssueIn?.selectedPartFile
                                        ?.originalname ? (
                                        <p
                                          className="downloadables"
                                          style={{ fontSize: "13px" }}
                                          key={i}
                                          onClick={(e) => {
                                            let url = `${process.env.REACT_APP_SUPPLIER_BACKEND}/${orderIssueIn?.selectedPartFile?.path} `;
                                            download(
                                              orderIssueIn.selectedPartFile
                                                .originalname,
                                              url
                                            );
                                          }}
                                        >
                                          {
                                            orderIssueIn?.selectedPartFile
                                              ?.originalname
                                          }
                                          <img src={downloadImage} />
                                        </p>
                                      ) : (
                                        <p key={i}>
                                          (No file uploaded) Item description :{" "}
                                          {orderIssueIn.description}{" "}
                                        </p>
                                      );
                                    }
                                  )
                                ) : (
                                  <p>No parts found</p>
                                )}
                              </div>
                              <div className="issue-col-admin">
                                <h6>Purchase Order Related Issues :</h6>
                                <ul>
                                  {orderIssue?.orderRelatedIssue?.length > 0 ? (
                                    orderIssue.orderRelatedIssue.map(
                                      (orderIssue, i) => {
                                        return (
                                          <li key={i}>{orderIssue?.name}</li>
                                        );
                                      }
                                    )
                                  ) : (
                                    <li>No Order related issue</li>
                                  )}
                                </ul>
                                <h6>Quality Related Issue :</h6>
                                <ul>
                                  {orderIssue?.qualityRelatedIssue?.length >
                                  0 ? (
                                    orderIssue.qualityRelatedIssue.map(
                                      (orderIssue, i) => {
                                        return (
                                          <li key={i}>{orderIssue.name}</li>
                                        );
                                      }
                                    )
                                  ) : (
                                    <li>No Quality related issue</li>
                                  )}
                                </ul>

                                <h6>Files attached :</h6>
                                <ul
                                  style={{
                                    width: "150px",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {orderIssue?.issueFileAttachment?.length >
                                  0 ? (
                                    orderIssue.issueFileAttachment.map(
                                      (fileAttached, i) => {
                                        return (
                                          <li
                                            key={i}
                                            onClick={(e) => {
                                              let url = `${process.env.REACT_APP_SUPPLIER_BACKEND}/public/uploads/${fileAttached.filename}`;
                                              download(
                                                fileAttached.originalname,
                                                url
                                              );
                                            }}
                                          >
                                            <p
                                              className="downloadables"
                                              style={{ fontSize: "13px" }}
                                            >
                                              {fileAttached?.originalname}
                                              <img src={downloadImage} />
                                            </p>
                                          </li>
                                        );
                                      }
                                    )
                                  ) : (
                                    <li>No files attached</li>
                                  )}
                                </ul>
                              </div>

                              <div className="issue-col-admin">
                                <h6>Is Part Issue : </h6>
                                <p>
                                  {orderIssue.isPartIssue
                                    ? "It is a part related issue"
                                    : "Not a part related issue"}
                                </p>
                                <h6>Issue Description :</h6>
                                <div className="item-description">
                                  <p>{orderIssue.issueDescription}</p>
                                </div>
                                <h6>Issue status : </h6>
                                {orderIssue.isIssueClosed ? (
                                  <span>
                                    <p>Closed</p>
                                  </span>
                                ) : (
                                  <>
                                    <span>
                                      <p>Open</p>
                                    </span>
                                    <br />
                                    <button
                                      className="common-supplier-button"
                                      onClick={() => {
                                        setOrderIssue({
                                          ...orderIssue,
                                          isIssueClosed: true,
                                        });
                                        setPurchaseOrder({
                                          ...purchaseOrder,
                                          isCompleted: true,
                                        });
                                        setIsChanged({
                                          ...isChanged,
                                          isIssueClosed: true,
                                          deliveryDetails: true,
                                        });
                                      }}
                                    >
                                      Close Issue
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  ) : null}
                </Accordion>
              </div>
            )}
            <div className="order-stage-div">
              {isCreateBillActive ? (
                <>
                  <button
                    className="supplier-stage-button"
                    onClick={handleCreateBill}
                  >
                    Create Bill
                  </button>
                  <button
                    className="supplier-stage-button"
                    onClick={handleCancelCreateBill}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="supplier-stage-button"
                    onClick={handleOrderUpdate}
                  >
                    Update Order
                  </button>

                  {purchaseOrder.status == "Draft" ? (
                    <>
                      <button
                        className="supplier-stage-button"
                        onClick={handleApprovePOClick}
                      >
                        Approve Final PO
                      </button>
                      <button
                        className="supplier-stage-button"
                        onClick={handleDeleteOrderClick}
                      >
                        Delete Order
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="supplier-stage-button"
                        onClick={() => showDrawer(purchaseOrder.pORefId)}
                      >
                        <ChatButton
                          RefId={purchaseOrder.pORefId}
                          showDrawer={() => showDrawer(purchaseOrder.pORefId)}
                          additionalClass={
                            "!bg-[#DC712B] hover:!bg-complementaryHoverColor"
                          }
                        />
                      </button>

                      {isSendEmailInProgress ? (
                        <>
                          <button
                            className="supplier-stage-button 
                            !w-[189px] flex justify-center items-center"
                            // style={{ minWidth: "200px" }}
                          >
                            <LucidLoader additionalClass={'h-5'}/>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="supplier-stage-button"
                            onClick={() => setShowCaptchaModal(true)}
                          >
                            Send Email Confirmation
                          </button>
                        </>
                      )}

                      <button
                        className="supplier-stage-button"
                        onClick={handleCreateNewBillClick}
                      >
                        Create New Bill
                      </button>
                      <button
                        className="supplier-stage-button"
                        onClick={() => {
                          setRaiseIssueModal(true);
                        }}
                      >
                        Raise Issue
                      </button>
                      <button
                        className="supplier-stage-button"
                        onClick={handleCancelPurchaseOrder}
                      >
                        Cancel Order
                      </button>
                      {purchaseOrder.status == "Awaiting PO Acceptance" &&
                      !purchaseOrder.isCompleted ? (
                        <>
                          <button
                            className="supplier-stage-button"
                            onClick={handleAcceptPOClick}
                          >
                            Accept PO For Supplier
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  <button
                    className="supplier-stage-button"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                </>
              )}
            </div>
          </div>
          {loading && (
            <div className="fixed bg-white flex justify-center items-center opacity-80">
              <Loading />
            </div>
          )}
        </div>
      </span>
    </div>
  );
};

export default PurchaseOrderDetails;
