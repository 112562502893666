import {createContext, useState} from "react";


export const ChatRefContext = createContext({
    selectedChatRefId:null,
    setSelectedChatRefId: ()=>null,
    chatType:null,
    setChatType:()=>null,
});

export const ChatRefProvider = ({children}) =>{
    const [selectedChatRefId,setSelectedChatRefId] = useState(null);
    const [chatType,setChatType] =useState(null);

    const value = {selectedChatRefId,setSelectedChatRefId,setChatType,chatType}
    return <ChatRefContext.Provider value={value}>{children}</ChatRefContext.Provider>
}