import { Row, Col, Form, FormControl } from "react-bootstrap";
import { useEffect, useState } from "react";
import process from "../../../images/process.svg";
import cnc from "../../../images/cnc.svg";
import printing from "../../../images/printing.svg";
import metal from "../../../images/metal.svg";
import injection from "../../../images/injection.svg";
import tickwhite from "../../../images/tick-white.svg";
import { processType } from "../../../utils/constant";

const ProcessSection = (props) => {
  const handleValueChange = (value) => {
    try {
      props.setSelectedProcess(value);
      props.setIsProcessNotSelected(false)
    } catch (err) {
      return err;
    }
  };
  
  return (
    <div className="content-wrapper">
      <div className={`process-bar-bx ${props.selectedProcess > 0 ? 'green-line' : ''}`}>
        <span className="process-ico">
          <img src={process} />
        </span>
        <h5>Select a process </h5>
        <div className="process-thumbs">
          <Row role="group" aria-label="Basic radio toggle button group">
            {props?.option && props.option == 1 ?
              processType &&
              processType.length > 0 &&
              processType.map((singleProcess, index) => {
                if (index < 2) {
                  return (
                    <Col key={`process-${index}`} onClick={() => handleValueChange(singleProcess.id)}>
                      <div
                        className={
                          props.selectedProcess === singleProcess.id
                            ? "box-pr pr-checked"
                            : "box-pr"
                        }
                      >
                        <div className="pr-radio">
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio1"
                            autoComplete="off"
                            value={singleProcess.id}
                            onChange={(e) => handleValueChange(singleProcess.id)}
                          />
                          <label
                            className="btn box-in"
                            htmlFor={`btnradio-${singleProcess.id}`}
                          >
                            <span className="triangle-0">
                              <img src={tickwhite} alt="tick-white" />
                            </span>
                            <span className="bx-icon">
                              {singleProcess.id === 1 && (
                                <img src={cnc} alt="cnc" />
                              )}
                              {singleProcess.id === 2 && (
                                <img src={printing} alt="printing" />
                              )}
                              {singleProcess.id === 3 && (
                                <img src={metal} alt="metal" />
                              )}
                              {singleProcess.id >= 4 && (
                                <img src={injection} alt="injection" />
                              )}
                            </span>
                            {singleProcess.name}
                          </label>
                        </div>
                      </div>
                    </Col>
                  );
                }
              }) : <>
                <Col style={{maxWidth:'50%',minWidth:'200px'}}>

                  <Form.Select aria-label="Default select example"
                    onChange={(e) => handleValueChange(e.target.value)}
                    className="process-select custom-select-with-arrow"
                    value={props.selectedProcess || "default"}>
                    <option value="default" disabled>Select a process</option>
                    {processType &&
                      processType.length > 0 &&
                      processType.map((singleProcess, index) => {
                        if (index >= 2) {
                          return (
                            <option key={`process-${index}`} value={singleProcess.id}>
                              {singleProcess.name}
                            </option>
                          );
                        }
                        return null;
                      })
                    }
                  </Form.Select>
                </Col>
              </>
            }
          </Row>
        </div>
        {props.isProcessNotSelected && (
          <div className="error">
            <p>Please Select a Process</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default ProcessSection;
