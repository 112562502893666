import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { useState } from "react";
import {
  updateSubmaterial,
  getMaterialMaster,
  getSubMaterialById,
} from "../../../utils/actions/allactions";
import {
  validateAdmin,
  numberInputOnWheelPreventChange,
  getToken,
} from "../../../utils/helper";
import { toast } from "react-toastify";
import useDocumentTitle from "../../../utils/useDocumentTitle";
import SkeltonLoadingComponent from "../../../components/LoadingComponents/SkeltonLoadingComponent";

function EditSubmaterialMaster() {
  const token = getToken();
  const navigate = useNavigate();
  useDocumentTitle("Edit Submaterial");
  const [error, setError] = useState("");
  const [materialMaster, setMaterialMaster] = useState([]);
  // let submaterialToBeEdited = JSON.parse(
  //   localStorage.getItem("submaterialToBeEdited")
  // );
  const [initialValue, setInitialValue] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id") || null;

  const setMaterialMasterVal = async () => {
    try {
      const materialMasterData = await getMaterialMaster(token);
      console.log(materialMaster);
      setMaterialMaster(materialMasterData);
      
    } catch (error) {
      toast.error("Unable To Fetch Material Master");
    }
   
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSubMaterialById(id);
        if (data?.success) {
          setInitialValue(data?.data);
        } else {
          setError("Failed To Fetch Details");
        }
        
      } catch (error) {
        toast.error("Unable To Get Material Data Id");
      }
    
    };
    fetchData();
    setMaterialMasterVal();
  }, []);

  const validateSubmaterialVal = () => {
    if (
      !initialValue.name ||
      !initialValue.SubMatFactor ||
      !initialValue.masterMaterialId
    ) {
      return false;
    }
    return true;
  };

  const handleEditSubmaterial = async () => {
    // console.log(initialValue)
    setError("");
    const isSubmaterialValid = validateSubmaterialVal();
    if (!isSubmaterialValid) {
      setError("Enter valid values for all the field");
      return;
    } 

      try {
        const updatedSubmaterial = await updateSubmaterial(token, initialValue);
        if (updatedSubmaterial?.data?.data?.code == 11000) {
          toast.error("Submaterial already exists");
        } else {
          navigate("/admin/subMaterial");
          toast.success("Submaterial updated successfully");
        }
        
      } catch (error) {
        toast.error("Unable To  Update Submaterial Data");
      }
     
   
  };

  const handleSelectMaterial = (event) => {
    const selectedMaterialName = event.target.value;
    const selectedMaterialObject = materialMaster.filter((item, i) => {
      if (item.name == selectedMaterialName) {
        return item._id;
      }
    });
    setInitialValue({
      ...initialValue,
      masterMaterialId: selectedMaterialObject[0]._id,
    });
  };

  return (
    <div className="admin-container">
      <div>
        <Sidebar />
      </div>
      <div className="admin-panel-container">
        <h1 className="center-heading text-2xl my-2 font-bold"> Edit Submaterial </h1>

        {initialValue ? (
          <div className="edit-company-container">
            <div>
              <label htmlFor="name"> Name </label>: &nbsp;
              <input
                name="name"
                type="text"
                placeholder="Enter  name"
                value={initialValue.name}
                onChange={(e) => {
                  setInitialValue({ ...initialValue, name: e.target.value });
                }}
              />
            </div>

            <div>
              <label htmlFor="SubMatFactor"> SubMatFactor </label>: &nbsp;
              <input
                name="SubMatFactor"
                type="number"
                onWheel={numberInputOnWheelPreventChange}
                placeholder="Enter SubMatFactor"
                value={initialValue.SubMatFactor || ""}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    SubMatFactor: e.target.value,
                  });
                }}
              />
            </div>

            <div>
              <label htmlFor="masterMaterialId">Material</label>: &nbsp;
              <select
                name="masterMaterialId"
                onChange={(e) => handleSelectMaterial(e)}
              >
                {materialMaster
                  ? materialMaster.map((item, index) => {
                    console.log(item,initialValue);
                      return (
                        <option
                          key={index}
                          value={item.name}
                          selected={
                            item.name === initialValue.masterMaterialId.name
                          }
                        >
                          {item.name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>

            <div className="admin-status-div">
              <label htmlFor="is3dPrinting">Is 3d Printing?</label>: &nbsp;
              <input
                name="is3dPrinting"
                type="radio"
                value="true"
                checked={initialValue.isThreeD ? true : ""}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    isThreeD: e.target.value === "true" ? true : "",
                  });
                }}
              />
              Yes
              <input
                name="is3dPrinting"
                type="radio"
                value="false"
                checked={!initialValue.isThreeD ? true : null}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    isThreeD: e.target.value === "false" ? false : "",
                  });
                }}
              />
              No
            </div>

            <div className="admin-status-div">
              <label htmlFor="isMachining">Is Machining?</label>: &nbsp;
              <input
                name="isMachining"
                type="radio"
                value="true"
                checked={initialValue.isMachining ? true : ""}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    isMachining: e.target.value === "true" ? true : "",
                  });
                }}
              />
              Yes
              <input
                name="isMachining"
                type="radio"
                value="false"
                checked={!initialValue.isMachining ? true : null}
                onChange={(e) => {
                  setInitialValue({
                    ...initialValue,
                    isMachining: e.target.value === "false" ? false : "",
                  });
                }}
              />
              No
            </div>

            <div className="center-heading">
              <button className="button-search" onClick={handleEditSubmaterial}>
                {" "}
                Update{" "}
              </button>
            </div>
            {error ? (
              <div
                style={{
                  color: "red",
                  fontSize: "15px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {error}
              </div>
            ) : null}
          </div>
        ) : (
          <SkeltonLoadingComponent />
        )}
      </div>
    </div>
  );
}
export default EditSubmaterialMaster;
