import React from "react";
import PhotoViewer from "../../../components/PhotoViewer/PhotoViewer";
import img1 from "../ProjectsImages/Packaging/img (1).jpg";
import img2 from "../ProjectsImages/Packaging/img (2).jpg";
import img3 from "../ProjectsImages/Packaging/img (3).jpg";
import img4 from "../ProjectsImages/Packaging/img (4).jpg";
import img5 from "../ProjectsImages/Packaging/img (5).jpg";
import img6 from "../ProjectsImages/Packaging/img (6).jpg";
import img7 from "../ProjectsImages/Packaging/img (7).jpg";
import img8 from "../ProjectsImages/Packaging/img (8).jpg";
import img9 from "../ProjectsImages/Packaging/img (9).jpg";
import img10 from "../ProjectsImages/Packaging/img (10).jpg";
import img11 from "../ProjectsImages/Packaging/img (11).jpg";
import img12 from "../ProjectsImages/Packaging/img (12).jpg";
import logo from "../../../images/logo.svg";
import { Link } from "react-router-dom";

const PackagingProjects = () => {
    const imageArr = [
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        img7,
        img8,
        img9,
        img10,
        img11,
        img12,
      ];
    
    
    
      return (
        <>
          <div className="pvtop-bar">
            <Link to="">
              <img src={logo} />
            </Link>{" "}
            <Link to="/projects" className="back-btn">
              Back
            </Link>
          </div>
          <div className="csr-section">
            <h4 style={{ textAlign: "center" }}>Packaging Standards</h4>

              <span>
                <PhotoViewer images={imageArr} altText={`image`} />
              </span>

          </div>
          
        </>
      );
}

export default PackagingProjects;
