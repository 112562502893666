import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useLocation } from 'react-router-dom';

const PrivateRoute = () => {
    let isLoggedIn = localStorage.getItem('isLoggedIn');
    let token = localStorage.getItem('Token');


    console.log("Is LoggedIn Value :", isLoggedIn);
    console.log("Token :", token);



    const location = useLocation();
    const { pathname } = location;

    if (!token || !isLoggedIn) {
        return <Navigate
            to={'/'}
            state={
                { pathname: pathname || '/' }
            }
        />
    }

    if (token || isLoggedIn) {
        const decode = jwt_decode(token);
        if (!decode) {
            return <Navigate
                to={'/'}
            />
        }
        if (decode?.userType == 'admin' || decode?.userType == 'user') {
            return <Outlet />
        }
    }
    return <Navigate
        to={'/'}

    />

    // if ((token == null || token == '') && (isLoggedIn == null || isLoggedIn == '')) {
    //     auth = false;
    // }
    // else if (isLoggedIn && token !== null) {
    //     // alert("hey Hey Hey");
    //     let decode = jwt_decode(token);
    //     console.log("Decoded Value :", decode);

    //     if (!decode) {
    //         auth = false;
    //     }
    //     else if (decode.userType == 'admin') {
    //         auth = true;
    //     }
    //     else if (decode.userType == 'user') {
    //         auth = true;
    //     }
    //     else {
    //         auth = false;
    //     }
    // }
    // else if (isLoggedIn && (token == null || token == '')) {
    //     auth = false;
    // }
    // else {
    //     auth = false
    // }
    // localStorage.setItem("pathAfterLogin", JSON.stringify(location))
    // // If authorized, return an outlet that will render child elements
    // // If not, return element that will navigate to login page
    // return true ? <Outlet /> : <Navigate to="/login" replace={true} />;
}

export default PrivateRoute;