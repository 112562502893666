import { useState, useEffect, useContext, useReducer, useRef } from "react";
import {
  getAdminUsers,
  getAllUsers,
  getCompanies,
} from "../../utils/actions/allactions";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-paginate";
import ChatButton from "../../Chat/ChatButton";
import Chat from "../../Chat";
import waitingImage from "../../images/waiting.svg";
import tickGreenImage from "../../images/tick-green.svg";
import cancelledImage from "../../images/close_red.svg";
import settingImage from "../../images/setting-green.svg";
import sendImage from "../../images/send.svg";
import { ChatRefContext } from "../../context/chatRef.context";
import HoverInfo from "../../components/HoverInfo/HoverInfo";
import { ADMIN_ACTIONS, useAdminContext } from "../../context/admin.context";
import { currencyFormatter, validateAdmin,capitalizeString } from "../../utils/helper";
import { Form } from "react-bootstrap";
import useDocumentTitle from "../../utils/useDocumentTitle";
import {
  changeInvoiceAdminOwner,
  fetchFilteredInvoices,
  fetchInvoiceForSalesRefSearch,
} from "../../utils/actions/adminActions";
import { Loading } from "../../components/Loading";
import allValuesImage from "../../images/all-entity.svg";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";


const CustomerInvoiceMaster = () => {
  const navigate = useNavigate();
  useDocumentTitle("Invoice ");
  const { state, dispatch } = useAdminContext();
  const { selectedChatRefId, setSelectedChatRefId } = useContext(ChatRefContext);
  let token = localStorage.getItem("Token");
  const [adminUsers, setAdminUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [directSalesRefInvoiceResult, setDirectSalesRefInvoiceResult] = useState([]);
  const [directSalesRefValue, setDirectSalesRefValue] = useState("");

  const [allCustomerUsers, setAllCustomerUsers] = useState([]);
  const [selectedCustomerForFilter, setSelectedCustomerForFilter] = useState(new Set());
  const [filteredCustomerUser, setFilteredCustomerUser] = useState([]);
  const [isUserDropDownVisible, setIsUserDropDownVisible] = useState(false);

  const userDropDownRef = useRef(null);
  const companyDropDownRef = useRef(null);

  const [allCompanies, setAllCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState(new Set());
  const [isCompanyDropDownVisible, setIsCompanyDropDownVisible] = useState(false);


  const initialInvoiceState = {
    createdFrom: "",
    createdTo: "",
    adminOwnerId: "",
    sortBy: "createdAt",
    sortOrder: 1,
    pageNum: 1,
    totalPages: 0,
    invoiceList: [],
    invoiceCount: {},
    poNumber: '',
    invoiceNumber: '',
    companyName: '',
    userName: '',
  };

  const INVOICE_ACTIONS = {
    UPDATE_CREATED_FROM: "update-created-from",
    UPDATE_CREATED_TO: "update-created-to",
    UPDATE_ADMIN_OWNER_ID: "update-owner-id",
    UPDATE_SORT_BY: "update-sort-by",
    UPDATE_SORT_ORDER: "update-sort-order",
    UPDATE_PAGE_NUM: "update-page-num",
    UPDATE_TOTAL_PAGE: "update-total-page",
    UPDATE_INVOICE_LIST: "update-invoices",
    UPDATE_INVOICE_COUNT: "update-invoice-count",
    UPDATE_INVOICE_FILTER: 'update-invoice-filter',
    UPDATE_PO_NUMBER: 'update-po-number',
    UPDATE_INVOICE_NUMBER: 'update-invoice-number',
    UPDATE_USER_NAME: "UPDATE_USER_NAME",
    UPDATE_COMPANY_NAME: "UPDATE_COMPANY_NAME",
  };

  const invoiceReducer = (state, action) => {
    switch (action.type) {
      case INVOICE_ACTIONS.UPDATE_CREATED_FROM: return { ...state, createdFrom: action.payload };
      case INVOICE_ACTIONS.UPDATE_USER_NAME: return { ...state, userName: action.payload };
      case INVOICE_ACTIONS.UPDATE_CREATED_TO: return { ...state, createdTo: action.payload };
      case INVOICE_ACTIONS.UPDATE_ADMIN_OWNER_ID: return { ...state, adminOwnerId: action.payload };
      case INVOICE_ACTIONS.UPDATE_SORT_BY: return { ...state, sortBy: action.payload };
      case INVOICE_ACTIONS.UPDATE_SORT_ORDER: return { ...state, sortOrder: action.payload };
      case INVOICE_ACTIONS.UPDATE_PAGE_NUM: return { ...state, pageNum: action.payload };
      case INVOICE_ACTIONS.UPDATE_TOTAL_PAGE: return { ...state, totalPages: action.payload };
      case INVOICE_ACTIONS.UPDATE_INVOICE_LIST: return { ...state, invoiceList: action.payload };
      case INVOICE_ACTIONS.UPDATE_INVOICE_COUNT: return { ...state, invoiceCount: action.payload };
      case INVOICE_ACTIONS.UPDATE_INVOICE_FILTER: return { ...state, ...action.payload };
      case INVOICE_ACTIONS.UPDATE_PO_NUMBER: return { ...state, poNumber: action.payload };
      case INVOICE_ACTIONS.UPDATE_INVOICE_NUMBER: return { ...state, invoiceNumber: action.payload };
      case INVOICE_ACTIONS.UPDATE_COMPANY_NAME: return { ...state, companyName: action.payload };
      default: return state;
    }
  };

  const [invoiceState, invoiceDispatch] = useReducer(
    invoiceReducer,
    initialInvoiceState
  );

  useEffect(() => {
    validateAdmin(token, navigate);
    if (token) {
      async function fetchCommonData() {
        getAdminUsers(token).then(response => setAdminUsers(response)).catch(toast.error);
        getAllUsers(token).then(response => setAllCustomerUsers(response)).catch(toast.error);
        getCompanies(token).then(companyResponse => setAllCompanies(companyResponse)).catch(toast.error);
      }
      fetchCommonData();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let data = {
          createdFrom: invoiceState.createdFrom,
          createdTo: invoiceState.createdTo,
          adminOwnerId: invoiceState.adminOwnerId,
          sortBy: invoiceState.sortBy,
          sortOrder: invoiceState.sortOrder,
          pageNum: invoiceState.pageNum,
          poNumber: invoiceState.poNumber,
          invoiceNumber: invoiceState.invoiceNumber,
          invoiceFilter: state.invoiceActiveFilter,
          userName: selectedCustomerForFilter.size ? [...selectedCustomerForFilter].join(',') : "",
          companyName: selectedCompanies.size ? [...selectedCompanies].join(',') : "",
        }

        const response = await fetchFilteredInvoices(token, data);


        invoiceDispatch({ type: INVOICE_ACTIONS.UPDATE_INVOICE_LIST, payload: response?.data?.invoices })
        let invoiceBucketCount = response?.data?.invoiceCategoryCount;
        invoiceDispatch({ type: INVOICE_ACTIONS.UPDATE_INVOICE_COUNT, payload: invoiceBucketCount });
        let totalPages = Math.ceil(Number(state.invoiceActiveFilter == 'draft' ? invoiceBucketCount.draftCount : state.invoiceActiveFilter == 'sent' ? invoiceBucketCount.sentCount : state.invoiceActiveFilter == 'approved' ? invoiceBucketCount.approvedCount : state.invoiceActiveFilter == 'paid' ? invoiceBucketCount.paidCount : invoiceBucketCount.allCount) / 10);
        invoiceDispatch({ type: INVOICE_ACTIONS.UPDATE_TOTAL_PAGE, payload: totalPages || 0 })
        setLoading(false);
      } catch (err) {
        if (err.message != "canceled") {
          toast.error(err.message)
          setLoading(false);
        }
        console.log("err", err)
      }

    }

    fetchData();
  }, [
    invoiceState.createdFrom,
    invoiceState.createdTo,
    invoiceState.adminOwnerId,
    invoiceState.sortBy,
    invoiceState.sortOrder,
    invoiceState.pageNum,
    invoiceState.poNumber,
    invoiceState.invoiceNumber,
    state.invoiceActiveFilter,
    selectedCustomerForFilter.size,
    selectedCompanies.size,
  ]);


  useEffect(() => {
    function handleClickOutside(event) {
      if (userDropDownRef.current && !userDropDownRef.current.contains(event.target)) {
        setIsUserDropDownVisible(false);
      }

      if (companyDropDownRef.current && !companyDropDownRef.current.contains(event.target)) {
        setIsCompanyDropDownVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const handleUserFocus = () => {
    setIsUserDropDownVisible(true);
  }


  const handleCompanyFocus = () => {
    setIsCompanyDropDownVisible(true);
  }


  const searchUser = (e) => {
    invoiceDispatch({
      type: INVOICE_ACTIONS.UPDATE_USER_NAME,
      payload: e.target.value,
    })
    // setUserFilterValue(e.target.value);
    let localFilteredUser = [];

    if (e.target.value.length > 0) {
      localFilteredUser = allCustomerUsers.filter((item) =>
        (item.firstName + " " + item.lastName)
          .toUpperCase()
          .includes(e.target.value.toUpperCase())
      );
    }
    if (localFilteredUser.length > 0) {
      setIsUserDropDownVisible(true)
    }
    setFilteredCustomerUser(localFilteredUser);
  };

  const searchCompany = (e) => {
    invoiceDispatch({
      type: INVOICE_ACTIONS.UPDATE_COMPANY_NAME,
      payload: e.target.value,
    })
    let localFilteredCompany = [];

    if (e.target.value.length > 0) {
      localFilteredCompany = allCompanies.filter((item) =>
        (item.companyName)
          .toUpperCase()
          .includes(e.target.value.toUpperCase())
      );
    }
    if (localFilteredCompany.length > 0) {
      setIsCompanyDropDownVisible(true)
    }
    setFilteredCompanies(localFilteredCompany);
  };

  const handleCustomerFilterChange = (action, item) => {
    try {
      let customerId = item;
      if (!customerId) {
        return;
      }

      let localSelectedCustomerForFilter = selectedCustomerForFilter;
      invoiceDispatch({
        type: INVOICE_ACTIONS.UPDATE_USER_NAME,
        payload: "",
      })
      setFilteredCustomerUser([]);

      if (action == "push") {
        localSelectedCustomerForFilter.add(customerId);
      } else if (action == "pop") {
        localSelectedCustomerForFilter.delete(customerId);
      }
      dispatch({
        type: ADMIN_ACTIONS.UPDATE_INVOICE_ACTIVE_FILTER,
        payload: 'all-invoice',
      });
      setSelectedCustomerForFilter(localSelectedCustomerForFilter);
    } catch (err) {
      console.log("err", err);
    }
  };


  const handleCompanyFilterChange = (action, item) => {
    try {
      let companyId = item;
      if (!companyId) {
        return;
      }

      let localSelectedCompanies = selectedCompanies;
      invoiceDispatch({
        type: INVOICE_ACTIONS.UPDATE_COMPANY_NAME,
        payload: "",
      })
      setFilteredCompanies([]);

      if (action == "push") {
        localSelectedCompanies.add(companyId);
      } else if (action == "pop") {
        localSelectedCompanies.delete(companyId);
      }
      dispatch({
        type: ADMIN_ACTIONS.UPDATE_INVOICE_ACTIVE_FILTER,
        payload: 'all-invoice',
      });
      setSelectedCompanies(localSelectedCompanies);
    } catch (err) {
      console.log("err", err);
    }
  };




  const showDrawer = (id) => {
    setSelectedChatRefId(id);
  };

  const onClose = () => {
    setSelectedChatRefId(null);
  };

  const handleAdminOwnerChange = async (e, invoiceId) => {
    try {
      let adminUserSelected = adminUsers.filter(
        (item) => item._id == e.target.value
      )[0];
      let adminResponse = confirm(`Are you sure you want to assign this invoice to ${adminUserSelected.firstName + " " + adminUserSelected.lastName}?`);
      if (!adminResponse) {
        return;
      }

      let data = {
        invoiceId: invoiceId,
        adminId: e.target.value,
      };
      let quoteResponse = await changeInvoiceAdminOwner(token, data);
      if (quoteResponse.status) {
        let localInvoiceList = [...invoiceState.invoiceList];
        localInvoiceList.forEach(item => {
          if (item._id == invoiceId) {
            item.adminOwnerId = adminUserSelected._id;
          }
        });
        invoiceDispatch({ type: INVOICE_ACTIONS.UPDATE_INVOICE_LIST, payload: localInvoiceList })
        toast.success("Admin owner changed successfully.");
      }
    } catch (err) {
      console.log(
        "Error in 86 Invoice on " +
        moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
        err
      );
    }
  };

  let handleActivePage = async (value) => {
    let pageNum = value?.selected + 1 || 1;
    invoiceDispatch({ type: INVOICE_ACTIONS.UPDATE_PAGE_NUM, payload: pageNum })
  };


  const handleBucketFilterClick = (field) => {
    dispatch({
      type: ADMIN_ACTIONS.UPDATE_INVOICE_ACTIVE_FILTER,
      payload: field,
    });

    invoiceDispatch({
      type: INVOICE_ACTIONS.UPDATE_INVOICE_FILTER, payload: {
        createdFrom: "",
        createdTo: "",
        adminOwnerId: "",
        sortBy: "",
        sortOrder: 1,
        pageNum: 1,
        totalPages: 0,
        poNumber: '',
        invoiceNumber: '',
        companyName: '',
        userName: '',
      }
    })
  };


  let handleSort = (field) => {
    invoiceDispatch({
      type: INVOICE_ACTIONS.UPDATE_SORT_BY,
      payload: field,
    })

    let sortStatus = invoiceState.sortBy == field ? invoiceState.sortOrder == 1 ? -1 : 1 : 1;
    invoiceDispatch({
      type: INVOICE_ACTIONS.UPDATE_SORT_ORDER,
      payload: sortStatus,
    })
  };



  const handleSalesRefInvoiceSearch = async (e) => {
    try {
      setDirectSalesRefValue(e.target.value);
      if (!e.target.value) {
        setDirectSalesRefInvoiceResult([]);
        return;
      }

      let response = await fetchInvoiceForSalesRefSearch(token, e.target.value);
      if (response.status) {
        setDirectSalesRefInvoiceResult(response.data);
      } else {
        setDirectSalesRefInvoiceResult([])
        toast.error("Something went wrong while fetching the data.")
      }
    } catch (err) {
      console.log("error", err);
    }
  };



  const handleRefresh = () => {
    invoiceDispatch({
      type: INVOICE_ACTIONS.UPDATE_INVOICE_FILTER, payload: {
        createdFrom: "",
        createdTo: "",
        userName: "",
        adminOwnerId: "",
        sortBy: "createdAt",
        sortOrder: -1,
        pageNum: 1,
        totalPages: 0,
        poNumber: '',
        invoiceNumber: '',
        companyName: '',
        userName: '',
      }
    })

    dispatch({
      type: ADMIN_ACTIONS.UPDATE_INVOICE_ACTIVE_FILTER,
      payload: 'all-invoice',
    });

    setDirectSalesRefValue("")
    setSelectedCustomerForFilter(new Set());
    setSelectedCompanies(new Set());
  }

  return (
    <div className="admin-container">
      <div style={{ height: 'auto' }}>
        {loading ?
          <Loading />
          : ""}
      </div>
      <span style={{ width: "100%" }}>
        <div className="center-heading">
          <h1 className="text-2xl font-bold mt-2">Customer Invoices</h1>
          <div className="right-container ">
            <div className="bucketFilterDiv">

              <div className={`bucketFilterOptions ${state.invoiceActiveFilter == 'all-invoice' ? "selected" : ''}`} onClick={() => handleBucketFilterClick('all-invoice')}>
                <span className="textBox">
                  <p>All Invoices</p>
                  <h5>{invoiceState?.invoiceCount?.allCount || 0}</h5>
                </span>
                <img className='filterIcon' src={allValuesImage} />
              </div>


              <div
                className={`bucketFilterOptions ${state.invoiceActiveFilter == "draft" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("draft")}
              >
                <span className="textBox">
                  <p>Draft</p>
                  <h5>{invoiceState?.invoiceCount?.draftCount}</h5>
                </span>
                <img className="filterIcon" src={waitingImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.invoiceActiveFilter == "approved" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("approved")}
              >
                <span className="textBox">
                  <p>Approved</p>
                  <h5>{invoiceState?.invoiceCount?.approvedCount}</h5>
                </span>
                <img className="filterIcon" src={settingImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.invoiceActiveFilter == "sent" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("sent")}
              >
                <span className="textBox">
                  <p>Sent</p>
                  <h5>{invoiceState?.invoiceCount?.sentCount}</h5>
                </span>
                <img className="filterIcon" src={sendImage} />
              </div>

              <div
                className={`bucketFilterOptions ${state.invoiceActiveFilter == "paid" ? "selected" : ""
                  }`}
                onClick={() => handleBucketFilterClick("paid")}
              >
                <span className="textBox">
                  <p>Paid</p>
                  <h5>{invoiceState?.invoiceCount?.paidCount}</h5>
                </span>
                <img className="filterIcon" src={tickGreenImage} />
              </div>

            </div>

            <>
              <Chat RefId={selectedChatRefId} open={selectedChatRefId?.length > 0} onDrawerClose={onClose} />


              <div className="search-bar">
                <div className="row" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                  <div className="search-block">
                    <label className="sr-label">Admin Owner</label>
                    <Form.Select
                      aria-label="Default select example"
                      value={invoiceState?.adminOwnerId || "default"}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        dispatch({
                          type: ADMIN_ACTIONS.UPDATE_INVOICE_ACTIVE_FILTER,
                          payload: 'all-invoice',
                        });
                        invoiceDispatch({
                          type: INVOICE_ACTIONS.UPDATE_ADMIN_OWNER_ID,
                          payload: e.target.value,
                        })
                      }
                      }
                    >
                      <option value="default" disabled>
                        {" "}
                        Select owner
                      </option>
                      {adminUsers?.map((val, i) => {
                        return (
                          <option key={i} value={val._id}>
                            {val.firstName + " " + val.lastName}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>

                  <div className="search-block">
                    <label className="sr-label">Invoice No</label>
                    <input
                      type="text"
                      className="form-control"
                      value={invoiceState.invoiceNumber}
                      placeholder="Search Invoice No"
                      onChange={(e) => {
                        dispatch({
                          type: ADMIN_ACTIONS.UPDATE_INVOICE_ACTIVE_FILTER,
                          payload: 'all-invoice',
                        });
                        invoiceDispatch({
                          type: INVOICE_ACTIONS.UPDATE_INVOICE_NUMBER,
                          payload: e.target.value,
                        })
                      }}
                    />
                  </div>

                  <div className="search-block">
                    <label className="sr-label">Ref ID</label>
                    <input
                      type="text"
                      className="form-control"
                      value={directSalesRefValue}
                      placeholder="Search Ref ID"
                      onChange={handleSalesRefInvoiceSearch}
                    />
                    {(directSalesRefValue.trim().length > 0 && directSalesRefInvoiceResult.length > 0) ? (
                      <div className="direct-search-drop-down-div ">
                        <table className="search-table">
                          <thead>
                            <tr>
                              <th>Invoice Number</th>
                              <th>Total Amount</th>
                              <th>Ref ID</th>
                              <th>PO Number</th>
                              <th>Status</th>
                              <th>Invoice Created At</th>
                              <th>Due Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(directSalesRefValue.trim().length > 0 && directSalesRefInvoiceResult.length > 0) &&
                              directSalesRefInvoiceResult?.map((val, i) => {
                                return (
                                  <tr
                                    key={i}
                                    className="direct-search-drop-down"
                                    onClick={() =>
                                      window.open(
                                        `/admin/invoice-details/${val?._id}`, "_blank"
                                      )
                                    }
                                  >


                                    <td style={{ textAlign: "left" }}>
                                      {val?.invoiceNumber}
                                    </td>
                                    <td>
                                      {currencyFormatter(val.invoiceTotalAmount)}
                                    </td>
                                    <td>
                                      {val.salesOrderId.RefId}
                                    </td>
                                    <td>
                                      {val.salesOrderId.purchaseOrderNumber}
                                    </td>
                                    <td>
                                      {val.status}
                                    </td>
                                    <td>
                                      {moment(val.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </td>

                                    <td>
                                      {val?.dueDate ? moment(val.dueDate).format("DD MMM YYYY") : "NA"}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <></>
                    )}

                  </div>

                  <div className="search-block">
                    <label className="sr-label">User Filter</label>
                    <div className="filter-item">
                      <div className="share-quote-div">
                        <input
                          type="text"
                          className="form-control"
                          value={invoiceState.userName}
                          placeholder={`${selectedCustomerForFilter.size} users selected`}
                          onChange={(e) => searchUser(e)}
                          onFocus={handleUserFocus}
                        />

                        {isUserDropDownVisible ? (
                          <div
                            className="follower-drop-down-div"
                            style={{
                              width: "200px",
                              border: "1px solid #ced4da",
                              borderRadius: "5px",
                              // padding: ".375rem .75rem",
                              maxHeight: "200px",
                              overflowY: "auto",
                              fontSize: "12px",
                              textAlign: 'left'
                            }}
                            ref={userDropDownRef}
                          >
                            {filteredCustomerUser.length > 0 ?
                              filteredCustomerUser.map((item, i) => {
                                return (
                                  <div
                                    key={i + "fil"}
                                    className="follower-drop-down"
                                    style={{ display: 'flex', alignItems: 'center', margin: '2px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"sign-check" + i + 'fil'}
                                      name="checkbox"
                                      value={item._id}
                                      checked={selectedCustomerForFilter.has(item._id)}
                                      onClick={() =>
                                        handleCustomerFilterChange(selectedCustomerForFilter.has(item._id) ? 'pop' : 'push', item._id)
                                      }
                                    />
                                    <label htmlFor={"sign-check" + i + 'file'} style={{ marginLeft: '5px', fontSize: '11px' }}
                                      onClick={() =>
                                        handleCustomerFilterChange(selectedCustomerForFilter.has(item._id) ? 'pop' : 'push', item._id)
                                      }
                                    >
                                      {item.firstName + " " + item.lastName}
                                    </label>
                                  </div>
                                )
                              }) :
                              allCustomerUsers.length > 0 && allCustomerUsers.map((item, i) => {
                                return (
                                  <div
                                    key={i + 'all'}
                                    className="follower-drop-down"
                                    style={{ display: 'flex', alignItems: 'center', margin: '2px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"sign-check" + i + 'all'}
                                      name="checkbox"
                                      value={item._id}
                                      checked={selectedCustomerForFilter.has(item._id)}
                                      onClick={() =>
                                        handleCustomerFilterChange(selectedCustomerForFilter.has(item._id) ? 'pop' : 'push', item._id)
                                      }
                                    />
                                    <label htmlFor={"sign-check" + i + 'all'} style={{ marginLeft: '5px', fontSize: '11px' }}>
                                      {item.firstName + " " + item.lastName}
                                    </label>
                                  </div>
                                );
                              })
                            }
                          </div>
                        ) : (
                          <></>
                        )}

                      </div>
                    </div>
                  </div>

                  <div className="search-block">
                    <label className="sr-label">Company Filter</label>
                    <div className="filter-item">
                      <div className="share-quote-div">
                        <input
                          type="text"
                          className="form-control"
                          value={invoiceState.companyName}
                          placeholder={`${selectedCompanies.size} companies selected`}
                          onChange={(e) => searchCompany(e)}
                          onFocus={handleCompanyFocus}
                        />

                        {isCompanyDropDownVisible ? (
                          <div
                            className="follower-drop-down-div"
                            style={{
                              width: "200px",
                              border: "1px solid #ced4da",
                              borderRadius: "5px",
                              // padding: ".375rem .75rem",
                              maxHeight: "200px",
                              overflowY: "auto",
                              fontSize: "12px",
                              textAlign: 'left'
                            }}
                            ref={companyDropDownRef}
                          >
                            {filteredCompanies.length > 0 ?
                              filteredCompanies.map((item, i) => {
                                return (
                                  <div
                                    key={i + "fil-com"}
                                    className="follower-drop-down"
                                    style={{ display: 'flex', alignItems: 'center', margin: '2px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"sign-check" + i + 'fil-com'}
                                      name="checkbox"
                                      value={item._id}
                                      checked={selectedCompanies.has(item._id)}
                                      onClick={() =>
                                        handleCompanyFilterChange(selectedCompanies.has(item._id) ? 'pop' : 'push', item._id)
                                      }
                                    />
                                    <label htmlFor={"sign-check" + i + 'fil-com'} style={{ marginLeft: '5px', fontSize: '11px' }}>
                                      {item.companyName}
                                    </label>
                                  </div>
                                )
                              }) :
                              allCompanies?.length > 0 && allCompanies.map((item, i) => {
                                return (
                                  <div
                                    key={i + 'all-com'}
                                    className="follower-drop-down"
                                    style={{ display: 'flex', alignItems: 'center', margin: '2px' }}
                                  >
                                    <input
                                      type="checkbox"
                                      id={"sign-check" + i + 'all-com'}
                                      name="checkbox"
                                      value={item._id}
                                      checked={selectedCompanies.has(item._id)}
                                      onClick={() =>
                                        handleCompanyFilterChange(selectedCompanies.has(item._id) ? 'pop' : 'push', item._id)
                                      }
                                    />
                                    <label htmlFor={"sign-check" + i + 'all'} style={{ marginLeft: '5px', fontSize: '11px' }}
                                      onClick={() =>
                                        handleCompanyFilterChange(selectedCompanies.has(item._id) ? 'pop' : 'push', item._id)
                                      }
                                    >
                                      {item.companyName}
                                    </label>
                                  </div>
                                );
                              })
                            }
                          </div>
                        ) : (
                          <></>
                        )}

                      </div>
                    </div>
                  </div>



                  <div className="search-block">
                    <label className="sr-label">PO Number</label>
                    <input
                      type="text"
                      className="form-control"
                      value={invoiceState.poNumber}
                      placeholder="Search PO Number"
                      onChange={(e) => {
                        dispatch({
                          type: ADMIN_ACTIONS.UPDATE_INVOICE_ACTIVE_FILTER,
                          payload: 'all-invoice',
                        });
                        invoiceDispatch({
                          type: INVOICE_ACTIONS.UPDATE_PO_NUMBER,
                          payload: e.target.value,
                        })
                      }}
                    />
                  </div>

                  <div className="search-block">
                    <label className="sr-label">Invoice Created From</label>
                    <input
                      type="date"
                      value={invoiceState.createdFrom}
                      className="form-control"
                      onChange={(e) => {
                        dispatch({
                          type: ADMIN_ACTIONS.UPDATE_INVOICE_ACTIVE_FILTER,
                          payload: 'all-invoice',
                        });

                        invoiceDispatch({
                          type: INVOICE_ACTIONS.UPDATE_CREATED_FROM,
                          payload: e.target.value,
                        })
                      }
                      }
                    />
                  </div>

                  <div className="search-block">
                    <label className="sr-label">Invoice Created To</label>
                    <input
                      type="date"
                      value={invoiceState.createdTo}
                      className="form-control"
                      onChange={(e) => {
                        dispatch({
                          type: ADMIN_ACTIONS.UPDATE_INVOICE_ACTIVE_FILTER,
                          payload: 'all-invoice',
                        });
                        invoiceDispatch({
                          type: INVOICE_ACTIONS.UPDATE_CREATED_TO,
                          payload: e.target.value,
                        })
                      }
                      }
                    />
                  </div>

                  <div className="search-btn" style={{ marginTop: '10px' }}>
                    <button onClick={handleRefresh} className="button-search">Reset all filters</button>
                  </div>

                </div>
              </div>

              <div className="quotes-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("invoiceNumber")}
                      >
                        Invoice Number {invoiceState.sortBy == 'invoiceNumber' ? invoiceState.sortOrder == 1 ? "↓" : "↑" : "↓"}{" "}
                      </th>
                      <th scope="col" style={{ cursor: "pointer" }}>
                        {" "}
                        Admin Owner{" "}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("createdAt")}
                      >
                        Invoice Created at {invoiceState.sortBy == 'createdAt' ? invoiceState.sortOrder == 1 ? "↓" : "↑" : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("orderRef")}
                      >
                        Order Ref{invoiceState.sortBy == 'orderRef' ? invoiceState.sortOrder == 1 ? "↓" : "↑" : "↓"}
                      </th>

                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("purchaseOrderNumber")}
                      >
                        PO Number {invoiceState.sortBy == 'purchaseOrderNumber' ? invoiceState.sortOrder == 1 ? "↓" : "↑" : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("invoiceTotalAmount")}
                      >
                        Amount {invoiceState.sortBy == 'invoiceTotalAmount' ? invoiceState.sortOrder == 1 ? "↓" : "↑" : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("shippedDate")}
                      >
                        Shipping Date {invoiceState.sortBy == 'shippedDate' ? invoiceState.sortOrder == 1 ? "↓" : "↑" : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("deliveryDate")}
                      >
                        Delivery Date {invoiceState.sortBy == 'deliveryDate' ? invoiceState.sortOrder == 1 ? "↓" : "↑" : "↓"}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSort("dueDate")}
                      >
                        Due Date{invoiceState.sortBy == 'dueDate' ? invoiceState.sortOrder == 1 ? "↓" : "↑" : "↓"}
                      </th>
                      <th  scope="col"
                        style={{ cursor: "pointer" }}
                        className="text-center">
                        Chat
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceState.invoiceList?.length > 0 &&
                      invoiceState.invoiceList?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                width: "150px",
                                wordWrap: "break-word",
                                wordBreak: "break-all",
                                whiteSpace: "normal",
                              }}
                            >
                              <div className="flex gap-x-1 items-center">

                              
                              <Link 
                              to={`/admin/invoice-details/${item?._id}`}
                              className="text-primaryColor"
                              
                              >
                                {item.invoiceNumber}
                              </Link>
                              <HoverInfo content={item.adminComment} />
                              </div>
                              {state.invoiceActiveFilter == 'all-invoice' && item?.status
                                ? <><br />{`(${capitalizeString(item?.status)})`}</>
                                : ""}
                            </td>
                            <td>
                              <Form.Select
                                aria-label="Default select example"
                                value={item?.adminOwnerId || "default"}
                                style={{ width: "100%", marginTop: "-10px" }}
                                onChange={(e) =>
                                  handleAdminOwnerChange(e, item._id)
                                }
                              >
                                <option value="default" disabled>
                                  {" "}
                                  Select owner
                                </option>
                                {adminUsers?.map((val, i) => {
                                  return (
                                    <option key={i} value={val._id}>
                                      {val.firstName + " " + val.lastName}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </td>
                            <td>
                              {moment(item.createdAt).format("DD MMM YYYY")}
                            </td>
                            <td>
                              {item?.salesOrderId?.RefId}
                            </td>
                            <td>
                              {item?.salesOrderId?.purchaseOrderNumber}
                            </td>
                            <td> {currencyFormatter(item.invoiceTotalAmount)}</td>
                            <td>{item.shippedDate ? moment(item.shippedDate).format('DD MMM YYYY') : "NA"}</td>
                            <td>{item.deliveryDate ? moment(item.deliveryDate).format('DD MMM YYYY') : "NA"}</td>
                            <td>
                              {/* {item.paymentTerm} */}
                              {item?.dueDate ? moment(item.dueDate).format("DD MMM YYYY") : "NA"}
                            </td>

                            <td>
                              <div>
                                <button
                                  type="button"
                                  className="up-button w-[100px]"
                                  // style={{
                                  //   marginTop: "3px 0px",
                                  //   background: "white",
                                  //   color: "#2B96DC",
                                  //   width: "100px",
                                  //   border: "1px solid #a8e5ff",
                                  // }}
                                >
                                  <ChatButton
                                    RefId={item.RefId}
                                    showDrawer={() => showDrawer(item?.salesOrderId?.RefId)}
                                  />
                                </button>
                              </div>
                            </td>

                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="floatRight">
                <Pagination
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  pageCount={invoiceState?.totalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handleActivePage}
                  containerClassName="pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  activeClassName="active"
                />
              </div>
            </>

          </div>
        </div>
      </span>
    </div>
  );
};

export default AdminDashboardComponents(CustomerInvoiceMaster);
