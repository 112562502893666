import React, { useEffect, useState } from "react";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";
import { async } from "regenerator-runtime";
import { toast } from "react-toastify";
import { deleteMaterial, getAllMaterialData, upsertMaterial } from "../../utils/actions/adminActions";
import { useNavigate } from "react-router-dom";

const MaterialMasterComponent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allMaterialsData, setAllMaterialsData] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [isReload, setIsReload] = useState();
    const [formData, setFormData] = useState({
        cnc_material: "",
        cnc_grade: "",
        cnc_material_desc: "",
        cnc_material_density_gms_cm3: "",
        cnc_material_review: "false",
    });

    function resetData() {
        setFormData({
            cnc_material: "",
            cnc_grade: "",
            cnc_material_desc: "",
            cnc_material_density_gms_cm3: "",
            cnc_material_review: "false",
        });
    }

    useEffect(() => {
        (async () => {
            const resposne = await getAllMaterialData();
            if (!resposne || !resposne.success) {
                throw new Error();
            }
            const { data } = resposne;
            setAllMaterialsData(data);
        })();
    }, [isReload]);

    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await upsertMaterial(formData);
            if (!response || !response.success) {
                throw new Error();
            };
            toast.success(`Material ${formData?._id ? "Updated" : "Created"} Succesfully`);
        } catch (error) {
            toast.error("Unable To Add Material At The moment");
        }
        finally {
            setIsModalOpen(false);
            resetData();
            setIsReload(pre => !pre);
        }


    };

    const handleDelete = async (item) => {
        const { _id } = item;
        try {
            const userResposne = confirm("Are You Sure You Want To Delete ");
            if (!userResposne) return;
            const response = await deleteMaterial(_id);
            if (!response || !response.success) {
                throw new Error();
            };

            toast.success("Item Deleted Succesfully");
            setIsReload((pre) => !pre);

        } catch (error) {
            toast.error("Unable To Delete Item At The Moment");
        }
    }

    const handleUpdate = (item) => {
        setFormData(item);
        setIsModalOpen(true);
    }

    return (
        <div className="w-screen relative my-2">
            <h2 className="font-bold my-4 text-center text-2xl">Materials</h2>
            <button
                onClick={() => {
                    resetData();
                    setIsModalOpen(true);
                }}
                className="bg-primaryColor rounded absolute right-5 top-3 px-2 py-2 text-sm text-white"
            >
                Create New
            </button>
            <table class="table-auto  w-full border-collapse border border-gray-200 shadow-lg rounded-lg overflow-hidden">
                <thead class="bg-gray-100 text-gray-600 uppercase text-sm font-semibold">
                    <tr>
                        <th class="px-4 py-2 border border-gray-200 ">Material</th>
                        <th class="px-4 py-2 border border-gray-200">Grade </th>
                        <th class="px-4 py-2 border border-gray-200">Description </th>
                        <th class="px-4 py-2 border border-gray-200">Density (g/cm³) </th>
                        <th class="px-4 py-2 border border-gray-200">Manual Review </th>
                        <th class="px-4 py-2 border border-gray-200"> Parameters </th>
                        <th class="px-4 py-2 border border-gray-200">Cost Model $ </th>
                        <th class="px-4 py-2 border border-gray-200">Actions </th>
                    </tr>
                </thead>
                <tbody>
                    {allMaterialsData.length
                        ? allMaterialsData.map((item, index) => (
                            <tr
                                key={index}
                                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                                    } hover:bg-gray-200 transition duration-200`}
                            >
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_grade || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material_desc || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material_density_gms_cm3 || "NA"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200">
                                    {item.cnc_material_review ? "true" : "false"}
                                </td>
                                <td className="px-4 py-2 border border-gray-200 text-white">
                                    <button onClick={() => navigate('/admin/cnc/select-sub-materials', { state: item })} className='bg-yellow-500 rounded mx-2 p-1'>
                                        Select
                                    </button>
                                </td>
                                <td className="px-4 py-2 border border-gray-200 text-white">
                                    <button onClick={() => navigate('/admin/cnc/material-options', { state: item })} className='bg-yellow-500 rounded mx-2 p-1'>
                                        Configure
                                    </button>
                                </td>
                                <td className="px-4 py-2  border border-gray-200 flex-col flex text-[14px] text-white">
                                    <button onClick={() => handleUpdate(item)} className='bg-yellow-500 rounded mx-2 p-1'>
                                        Update
                                    </button>
                                    <button onClick={() => handleDelete(item)} className='bg-red-500 rounded mx-2 p-1 my-1'>
                                        Del
                                    </button>
                                </td>
                            </tr>
                        ))
                        : (
                            <tr>
                                <td
                                    colSpan="3"
                                    className="px-4 py-2 text-center text-gray-500 border border-gray-200"
                                >
                                    No data available
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>

            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded w-1/3">
                        <h3 className="text-lg font-bold mb-4">Create New Material</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-sm font-medium mb-2">Material Name</label>
                                <input
                                    type="text"
                                    name="cnc_material"
                                    value={formData.cnc_material}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium mb-2">Grade</label>
                                <input
                                    type="text"
                                    name="cnc_grade"
                                    value={formData.cnc_grade}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium mb-2">Description</label>
                                <textarea
                                    name="cnc_material_desc"
                                    value={formData.cnc_material_desc}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                    required
                                ></textarea>
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium mb-2">Density (g/cm³)</label>
                                <input
                                    type="number"
                                    name="cnc_material_density_gms_cm3"
                                    value={formData.cnc_material_density_gms_cm3}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium mb-2">Review Material</label>
                                <select
                                    name="cnc_material_review"
                                    value={formData.cnc_material_review}
                                    onChange={handleInputChange}
                                    className="w-full p-2 border rounded"
                                    required
                                >
                                    <option value={false}>False</option>
                                    <option value={true}>True</option>
                                </select>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => setIsModalOpen(false)}
                                    className="bg-gray-300 px-4 py-2 rounded mr-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="bg-primaryColor text-white px-4 py-2 rounded"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminDashboardComponents(MaterialMasterComponent);
