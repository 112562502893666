import React, {
  useContext,
  useRef,
  useState,
} from "react";

import CADViewer from "./CADViewer";

import { Canvas } from 'react-three-fiber';

import { Context, ContextProvider } from './Context';

import { FrontendToolbar } from "./Toolbar";

import { useApiFunctions, useFixCanvas, useTweenUpdate } from "./endCommons";

function Main({openCascadeWorkerClass, addFileFromURLRef, addFileFromArrayBufferRef, setActiveFileIndexRef}) {
  const {state, dispatch} = useContext(Context);
  const [overlayContent, setOverlayContent] = useState();

  useFixCanvas();
  useTweenUpdate();
  useApiFunctions(state, addFileFromArrayBufferRef, addFileFromURLRef, dispatch, setActiveFileIndexRef);

  const controlsRef = useRef();
  const cameraRef = useRef();
  const [grid, setGrid] = useState();
  const [boundingBox, setBoundingBox] = useState();
  const [resetCamera, setResetCamera] = useState({});
  const [renderStyle, setRenderStyle] = useState();

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          flexBasis: 0,
          position: "relative",
        }}
      >
        <Context.Consumer>
          {
            value => (
              <Canvas
                orthographic
                id="viewport"
                colorManagement
              >
                <Context.Provider
                  value={value}
                >
                  <CADViewer
                    setOverlayContent={setOverlayContent}
                    grid={grid}
                    boundingBox={boundingBox}
                    resetCamera={resetCamera}
                    renderStyle={renderStyle}
                    openCascadeWorkerClass={openCascadeWorkerClass}
                    controlsRef={controlsRef}
                    cameraRef={cameraRef}
                  />
                </Context.Provider>
              </Canvas>
            )
          }
        </Context.Consumer>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {overlayContent}
        </div>
        <FrontendToolbar
          onGrid={x => setGrid(x)}
          onBoundingBox={x => setBoundingBox(x)}
          onResetCamera={x => setResetCamera(x)}
          onRenderStyle={x => setRenderStyle(x)}
          controlsRef={controlsRef}
          cameraRef={cameraRef}
        />
      </div>
    </div>
  );
}

function Frontend({openCascadeWorkerClass, addFileFromURLRef, addFileFromArrayBufferRef, setActiveFileIndexRef}) {
  return (
    <ContextProvider>
      <Main
        openCascadeWorkerClass={openCascadeWorkerClass}
        addFileFromURLRef={addFileFromURLRef}
        addFileFromArrayBufferRef={addFileFromArrayBufferRef}
        setActiveFileIndexRef={setActiveFileIndexRef}
      />
    </ContextProvider>
  );
}

export default Frontend;
