import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Sidebar";
import { useState } from "react";
import { addLayerThickness } from '../../../utils/actions/allactions';
import { getToken,validateAdmin,numberInputOnWheelPreventChange } from "../../../utils/helper";
import { toast } from "react-toastify";
import useDocumentTitle from '../../../utils/useDocumentTitle';

function AddLayerThicknessMaster() {
    const token = getToken();
    const navigate = useNavigate();
    useDocumentTitle('Add Layer Thickness')
    const [error, setError] = useState("");
    const [initialValue, setInitialValue] = useState({
        name: '',
        LTFactor: ''
    });


    const validateLayerThicknessVal = () => {
        return !(!initialValue.name || !initialValue.LTFactor);
    }

    useEffect(() => {
        validateAdmin(token, navigate);
    }, []);

    const handleAddLayerThickness = async () => {
        setError('');
        const isLayerThicknessValid = validateLayerThicknessVal();

        if (!isLayerThicknessValid) {
            setError("Enter valid values for all the field")
        } else {
            const addedLayerThickness = await addLayerThickness(token, initialValue);
            if(addedLayerThickness?.data?.data?.code==11000){
                toast.error("Layer Thickness already exists")
              }else{
                toast.success("Layer Thickness added successfully")
                navigate('/admin/layer-thickness');
              }

        }
    }


    return (
        <div  className="admin-container">
            <div>
                <Sidebar />
            </div>
            <div className="admin-panel-container">
                <h1 className="center-heading">  Add Layer Thickness </h1>

                <div className="edit-company-container">
                    <div>
                        <label htmlFor="name" > Name </label>: &nbsp;
                        <input
                            name="name"
                            type="text"
                            placeholder="Enter  name"
                            onChange={(e) => { setInitialValue({ ...initialValue, name: e.target.value }) }}
                        />
                    </div>

                    <div>
                        <label htmlFor="LTFactor" > LTFactor </label>: &nbsp;
                        <input
                            name="LTFactor"
                            type="number"
                            placeholder="Enter LTFactor"
                            onWheel={numberInputOnWheelPreventChange}
                            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                            onChange={(e) => { setInitialValue({ ...initialValue, LTFactor: e.target.value }) }}
                        />
                    </div>

                    <div className="center-heading">
                        <button className="button-search" onClick={handleAddLayerThickness}>  Create </button>
                    </div>
                    {
                        error ?
                            <div style={{ color: 'red', fontSize: '15px', textAlign: 'center', marginTop: '10px' }}>{error}</div>
                            : null
                    }
                </div>
            </div>
        </div>
    )
}
export default AddLayerThicknessMaster;






