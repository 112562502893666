import React, { useEffect, useState } from "react";
import settingw from "../../images/setting-white.svg";
import orderIcon from "../../images/orderIcon.svg";
import shippingIcon from "../../images/shippingIcon.svg";
import tickwhite from "../../images/tick-white.svg";
import logo from "../../images/logo.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Modal,
  Tabs,
  Tab,
  FormControl
} from "react-bootstrap";
import { toast } from "react-toastify";
import { processType, } from "../../utils/constant";
import {
  downloadPdfForViewOrder,
  getTrackingDetails,
  fetchOrderById,
  sendViewOrderOTPToEmail,
  verifyViewOrderOTP,
  handleDownloadCocForOrder
} from "../../utils/actions/allactions";
import fileSaver from "file-saver";
import DownloadImage from "../../images/download.svg";
import moment from "moment";
import { config } from "../../environment/development";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import OrderTable from "../../components/OrderTable/OrderTable";
import starsIcon from "../../images/stars.svg";
import starsIconGrey from "../../images/stars-grey.svg";
import downArrowIcon from "../../images/downArrow.svg";
import upArrowIcon from "../../images/upArrow.svg";
import { currencyFormatter, extractURLAndEncodedFilename, numberInputOnWheelPreventChange } from "../../utils/helper";

const ViewOrderDetails = () => {
  let navigate = useNavigate();
  const [userEmail,setUserEmail] = useState('');
  const [expanded, setExpanded] = React.useState(false);
  const [key, setKey] = useState("final-order");
  const [dhlShipmentInformation,setDhlShipmentInformation] = useState({});
  const [showDeliveryTrackingInformation,setShowDeliveryTrackingInformation]=useState(false)
  const [showShipmentPartsData,setShowShipmentPartsData] = useState(false);
  const [orders, setOrders, ] = useState([]);
  const [deliveryTrackingLink,setDeliveryTackingLink] = useState('');
  const [qualityDocModal,setQualityDocModal] = useState(false);
  const [consignmentModal,setConsignmentModal] = useState(false);
  const [isValidUser,setIsValidUser] = useState(true);
  let { _id } = useParams();
  const [isOtpValid,setIsOtpValid] = useState(false);
  const [otpModal,setOtpModal] = useState(false);
  const [userOTP,setUserOTP] = useState('');

  useEffect(() => {
    fetchOrderById(_id)
      .then((res) => {
        if(!res.data.data){
          toast.error("Order details not found.")
          return;
        }
        const newOrder = res.data.data;
        setOrders(newOrder);
        setDeliveryTackingLink(newOrder?.deliveryTrackingLink)
      })
      .catch((err) => {
        console.log("Error in view order details 82 on"+moment(Date.now()).format('DD MMM YYYY hh:mm:ss a'),err)
        return err;
      });
  }, []);


  function isUrlValid(str) {
      str= str?str:'';
      if (!str.startsWith('http://') && !str.startsWith('https://')) {
        str = 'http://' + str;
      }
    var a  = document.createElement('a');
    a.href = str;
    return (a.host && a.host != window.location.host);
  }
  const handleTrackingDetailsClose = () =>{
    setShowDeliveryTrackingInformation(false)
  }

  const handleDeliveryTrackingNumber = async () =>{
    try{
      let userId = localStorage.getItem('_id');
      if (window.location.host === 'make.8xparts.com') {
      // window?.dataLayer?.push({
      //   event: 'trackShipment',
      //   userId: userId,
      //   salesOrderRefId:orders.RefId,
      // })
      }
      if(!isNaN(deliveryTrackingLink)){
        const externalLink = `https://www.dhl.com/ie-en/home/tracking/tracking-express.html?submit=1&tracking-id=${deliveryTrackingLink}`; 
        window.open(externalLink, '_blank');
        // if(Object.keys(dhlShipmentInformation).length===0){
        //   let response = await getTrackingDetails(deliveryTrackingLink)
        //   setDhlShipmentInformation(response.shipments[0]) 
        // }
        // setShowDeliveryTrackingInformation(true);
      }
    }
    catch(err){
      toast.error("Please enter valid tracking details")
    }    
  }



  const handleTabChange = (key) => {
    if (key === "quote-version") {
      navigate(
        "/quote-versions/" +
          orders?.quoteId?.RefId
      );
    }
  };


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  let handleFrmURL = (url, name) => {
    const data=  extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };


  let handleDownload = () => {
    // let additionalCost = orders?.quoteId?.selectedShipMethod==0?orders?.quoteId?.additionalCost1:orders?.quoteId?.selectedShipMethod==1?orders?.quoteId?.additionalCost2:orders?.quoteId?.additionalCost3;
    // let shippingCharge = orders?.quoteId?.selectedShipMethod==0?orders?.quoteId?.shippingCharge1:orders?.quoteId?.selectedShipMethod==1?orders?.quoteId?.shippingCharge2:orders.quoteId?.shippingCharge3;
    if(!orders.expectedDelivery){
      alert("Invoice will be downloadable once the order is delivered")
      return;
    }
    let data = {
      fname: orders?.userId.firstName,
      lname: orders?.userId.lastName,
      email: orders?.userId.email,
      orderId: orders?.RefId,
      total: orders?.subTotal-orders?.additionalCost-orders?.shippingCharge,
      shipToFirstName:orders?.shippingAddress?.firstName,
      shipToLastName:orders?.shippingAddress?.lastName,
      addressline1: orders?.shippingAddress?.addressLineOne,
      addressline2: orders?.shippingAddress?.addressLineTwo,
      city: orders?.shippingAddress?.city,
      state: orders?.shippingAddress?.state,
      country: orders?.shippingAddress?.country,
      zipcode: orders?.shippingAddress?.postalCode,
      partData: JSON.stringify(orders?.quoteId.partsData),
      process: orders?.quoteId.selectedProcess,
      price: orders?.quoteId.price,
      price1: orders?.quoteId.price1,
      price2: orders?.quoteId.price2,
      selectedShipMethod: orders?.quoteId.selectedShipMethod,
      sheetMetalAndInjectionDesc: orders?.quoteId.sheetAndInjectionDiscription,
      sheetMetalAndInjectionMP:orders?.quoteId.sheetAndInjectionManualPrice,
      sheetAndInjectionQuantity: orders?.quoteId.sheetAndInjectionQuantity,
      forInvoice: true,
      invoiceDate: orders?.expectedDelivery? orders.expectedDelivery : orders?.createdAt,
      hidePrice: orders?.quoteId.hidePrice,
      poNumber : orders?.purchaseOrderNumber,
      isQrTrackingRequested:orders?.quoteId?.isQrTrackingRequested,
      isDimensionalReportRequested:orders?.quoteId?.isDimensionalReportRequested,
      isConformanceCertificateRequested:orders?.quoteId?.isConformanceCertificateRequested,
      additionalCost:orders?.additionalCost,
      shippingCharge:  orders?.shippingCharge,
      leadTime :orders?.quoteId?.selectedShipMethod==0? orders?.quoteId.leadTime1:orders?.quoteId?.selectedShipMethod==1? orders?.quoteId.leadTime2:orders?.quoteId.leadTime3,
      projectName: orders?.quoteId.projectName,
      adjustmentValue:orders?.adjustmentValue,
    };

    downloadPdfForViewOrder(data)
      .then((res) => {
        fileSaver.saveAs(
          `${config.backEnd}/public/docs/` + res.data.data,
          `invoice-${orders?.RefId}.pdf`
        );
      })
      .catch((err) => {
        console.log("error in view order 195:: "+moment(Date.now()).format('DD MMM YYYY hh:mm:ss a'), err);
      });
  };





  const handleQualityDocModal = () =>{
    let userId = localStorage.getItem('_id');
    if (window.location.host === 'make.8xparts.com') {
    // window?.dataLayer?.push({
    //   event: 'viewQualityDocument',
    //   userId: userId,
    //   salesOrderRefId: orders?.RefId,
    // })
    }
    setQualityDocModal(true)
  }
  const handleConsignmentModal = () =>{
    let userId = localStorage.getItem('_id');
    if (window.location.host === 'make.8xparts.com') {
      // window?.dataLayer?.push({
      //   event: 'viewConsignmentPhotos',
      //   userId: userId,
      //   salesOrderRefId:orders.RefId,
      // })
    }
    setConsignmentModal(true)
  }

  const handleViewQualityDocClose = ()=>{
    setQualityDocModal(false);
  }

  const handleViewConsignmentClose = () =>{
    setConsignmentModal(false)
  }
  const download = async (name, url) => {
    const data=  extractURLAndEncodedFilename(url);
    let fileUrl = `${data.url}/${data.encodedFilename}`;
    fileSaver.saveAs(fileUrl, name);
  };

  const handleSendOTPToEmail = async () =>{
    let data ={
      email: userEmail.toLowerCase().trim(),
      orderId:orders?._id,
    }
    let response = await sendViewOrderOTPToEmail(data);
    if(response.status){
      setOtpModal(true);
      toast.success(response.message)
      // navigate('/');
    }
  }

  const isValidEmail = () => {
    let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    return regex.test(userEmail);
  }



  const handleSubmitClick =()=>{
    try{
      let isUserEmailValid = isValidEmail();
      if(!isUserEmailValid){
        toast.error("Enter valid email.")
        return;
      }
      let userEmailDomain = userEmail.toLowerCase().split('@')[1].trim();
      let orderUserEmailDomain = orders?.email.toLowerCase().split("@")[1].trim();
      if(userEmailDomain == orderUserEmailDomain){
          setIsValidUser(true);
          handleSendOTPToEmail();
      }else{
        setIsValidUser(false);
      }
      
    }catch(err){
      console.log('Error in view order details 280 on '+moment(Date.now()).format('DD MMM YYYY HH:MM:SS a'),err)
    }
  }

  const handleSubmitOTPClickForViewOrder = async()=>{
    try{
      let isValidOTP =false;

      const verifyPayload ={
          email:userEmail.toLowerCase().trim(),
          orderId:orders?._id,
          otp:userOTP,
      }

      let approvalOTPResponse = await verifyViewOrderOTP(verifyPayload);
      if(approvalOTPResponse.status){
          isValidOTP = true;
      }else{
          isValidOTP = false;
      }

      if(isValidOTP){
        setIsOtpValid(true);
        setOtpModal(false);
        setUserOTP("");
      }else{
        toast.error("Invalid OTP");
        setIsOtpValid(false);
        setUserOTP("");
        setOtpModal(false);
      }
    }catch(err){
      console.log("Error on 718 in order admin "+moment(Date.now()).format('DD MMM YYYY hh:mm:ss a'),err);
    }
  }

  const onKeyDown = (e) => {
    const code = (e.keyCode ? e.keyCode : e.which);
    if (code == 13) {
      e.preventDefault();
      handleSubmitClick();
    }
  }

  const handleDownloadCOC = async()=>{
    try{

        const response = await handleDownloadCocForOrder(orders._id);
        if(response.status){
            let url = `${config.backEnd}/public/docs/${response.data}`;
            download(`Certificate_Of_Conformace-${orders?.RefId}.pdf`,url);
        }
    }catch(err){
        toast.error("Something went wrong while downloading the pdf file.")
        console.log("err in order admin 952 on"+moment(Date.now()).format('DD MMM YYYY hh:mm:ss a'),err);
    }
}
  


  return (
    <div className="content-wrapper">
      <div className="pvtop-bar">
          <img src={logo} />
      </div>
      <Modal
            size="md"
            show={otpModal}
            onHide={() => {
                            setOtpModal(false);
                            setUserOTP("")
                          }
                  }
            dialogClassName="d-flex align-items-center justify-content-center custom-modal-view-order"
          > 
            
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm" style={{fontSize:'15px'}}>
                Please Enter OTP
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormControl 
                type='number' 
                value={userOTP}
                onWheel={numberInputOnWheelPreventChange}
                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                onChange={(e) => setUserOTP(e.target.value)}
                placeholder="Enter OTP here"
              />
              <br/>
              <Button onClick={handleSubmitOTPClickForViewOrder} className="req-quote-btn" style={{marginTop:'-10px'}}>
                Submit
              </Button>
            </Modal.Body>
          </Modal>

        {(isValidUser && isOtpValid)?
          <>
          <Modal
              size="sm"
              dialogClassName="qoute-version-modal"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={consignmentModal}
              onHide={handleViewConsignmentClose}
          >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter" style={{fontSize:'15px'}}>
                    Pre-Consignment Photos
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="raise-issue-div ">
                  {orders?.consignmentPhoto?.length>0?
                              <div className="col">
                                <div className="consignmentDiv">
                                  {orders?.consignmentPhoto.map((photo,i)=>{
                                  return photo?.file?.originalname?<div key={i} className="consignmentCard" onClick={() => download(photo?.file?.originalname, `${config.backEnd}/` + photo?.file?.path)}>
                                                              <img src={`${config.backEnd}/public/uploads/`+photo?.file?.filename} height='130px' width="180px" style={{marginLeft:'3px'}}/> 
                                                              <br/>
                                                              <span className="downloadables">
                                                                <span>{photo?.file?.originalname?photo?.file?.originalname:photo?.file?.name}</span>                                
                                                              </span>
                                                            </div>:
                                                            <></>;
                                })}                        
                                </div>
                              </div>
                              :
                              <></>
                            }      
                  </div>
              </Modal.Body>
              <Modal.Footer>
              <div className="raise-issue-footer"style={{width:'100%'}}>
                  <span className="left-modal-footer"></span>
                  <div>
                    <div onClick={handleViewConsignmentClose} className="req-quote-btn" style={{cursor:'pointer'}}> Close</div>
                  </div>
                </div>    
              </Modal.Footer>
          </Modal>
          <Modal
              size="sm"
              dialogClassName="qoute-version-modal"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={qualityDocModal}
              onHide={handleViewQualityDocClose}
          >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter" style={{fontSize:'15px'}}>
                      Quality Documents
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="raise-issue-div ">
                  {(orders?.qualityDocuments?.length>0 || orders?.shippedDate)?
                          <div className="col">
                            <>
                              <table>
                                <tbody>
                                  <tr>
                                    <th style={{textAlign:"left",paddingLeft:'20px'}}><p> Document Name</p> </th>
                                    <th  style={{textAlign:"left",paddingLeft:'20px'}}><p> File </p> </th>
                                    <th style={{textAlign:"left",paddingLeft:'20px'}}><p> Download</p></th>
                                  </tr>
                                  {orders?.qualityDocuments.map((item,i)=>{
                                    return <tr key={i}> 
                                              <td style={{textAlign:"left",wordWrap:'break-word',wordBreak:'break-all',whiteSpace:'normal',width:'300px',paddingLeft:'20px',}}><p>{item.name}</p></td>
                                              <td  style={{textAlign:"left",wordWrap:'break-word',wordBreak:'break-all',whiteSpace:'normal',width:'300px',paddingLeft:'20px',}}><p>{item?.file?.originalname}</p></td>
                                              <td style={{textAlign:"left",wordWrap:'break-word',wordBreak:'break-all',whiteSpace:'normal',width:'300px',paddingLeft:'20px',}}><button className="white-btn"  onClick={() => download(item?.file?.originalname, `${config.backEnd}/` + item?.file?.path)}>Download</button></td>
                                            </tr>
                                  })}

                                  {orders?.shippedDate?
                                  <>
                                    <tr> 
                                      <td style={{textAlign:"left",wordWrap:'break-word',wordBreak:'break-all',whiteSpace:'normal',width:'300px',paddingLeft:'20px',}}><p>Certificate Of Conformance</p></td>
                                      <td  style={{textAlign:"left",wordWrap:'break-word',wordBreak:'break-all',whiteSpace:'normal',width:'300px',paddingLeft:'20px',}}><p>{`Certificate_Of_Conformace-${orders?.RefId}.pdf`}</p></td>
                                      <td style={{textAlign:"left",wordWrap:'break-word',wordBreak:'break-all',whiteSpace:'normal',width:'300px',paddingLeft:'20px',}}><button className="white-btn"  onClick={handleDownloadCOC}>Download</button></td>
                                    </tr>
                                  </>
                                  :
                                  <></>
                                  }
                                  </tbody>
                              </table>
                            </>
                          </div>
                          :
                          <></>
                          } 
                  </div>
              </Modal.Body>
              <Modal.Footer>
              <div className="raise-issue-footer"style={{width:'100%'}}>
                  <span className="left-modal-footer"></span>
                  <div>
                    <div onClick={handleViewQualityDocClose} className="req-quote-btn" style={{cursor:'pointer'}}> Close</div>
                  </div>
                </div>    
              </Modal.Footer>
          </Modal>

          <div className="intro-bar">
            <div className="int-left">
              <h3>Order details</h3>
              <p>Order No. #{ orders?.RefId}</p>
            </div>
            <div className="int-right-view">  
              {(orders?.status=='refunded' || orders?.status=='cancelled')?<></>
              :
              <>
                  <button onClick={handleDownload} className="white-btn">
                    <img
                      src={DownloadImage}
                      alt="download"
                      style={{ paddingRight: "5px", paddingBottom: "2px" }}
                    />
                    Invoice
                  </button>
              </>}            
            </div>
          </div>

          <div className="dashboard-holder">
            <div className="md-container">
              <div className="common-bx">
                <div className="process-bar-bx green-line or-details-row">
                  <span className="process-ico green-fill">
                    <img src={settingw} />
                  </span>
                  <div className="req-box">
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                      className="requirement-box"
                    >
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                          <div className="accordion-title">
                            <div className="accordion-icon">
                              <span style={{display:'flex',flexWrap:'wrap',width:'100%',justifyContent:'space-between'}}>
                                <span>
                                  <h5>Requirements uploaded</h5>
                                  <div className="cnc-box">
                                    <p>
                                      {moment( orders?.quoteId?.createdAt ).format(
                                        "DD MMM YYYY"
                                      )}
                                    </p>
                                    <div className="cnc-box">
                                      {processType.map((val,i) => {
                                        if (val.id == orders?.quoteId?.selectedProcess) {
                                          return <p key={i}>{val.name}</p>;
                                        } else {
                                          return;
                                        }
                                      })}
                                    </div>
                                  </div>
                                </span>
                                <div style={{marginRight:'30px'}}>
                                  <span className="text" style={{ color: '#858585'}}>Project Name</span>
                                  <div className="name">{orders?.quoteId?.projectName || "Not Found"}</div>
                                </div>
                              </span> 
                              <img
                                src={
                                  expanded === "panel1"
                                    ? upArrowIcon
                                    : downArrowIcon
                                }
                                alt="arrowIcon"
                                style={{height:'8px',marginRight:'10px'}}
                              />
                            </div>
                          </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {orders?.quoteId?
                        <div className="version-tabs">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => {
                              setKey(k);
                              handleTabChange(k);
                            }}
                            className="mb-3 qoute-version-tabs"
                          >
                            <Tab eventKey="final-order" title={`Final Order`}>
                                <OrderTable selectedQuote={orders?.quoteId} selectedShipmentMethod={orders?.quoteSelectedShipMethod} orderDetails={orders}/>
                            </Tab>
                        </Tabs>
                      </div>
                        :
                        <>
                          <p>Loading</p>
                        </>}
                        
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
                <div className="process-bar-bx  upload-req green-line or-details-row">
                  <span className="process-ico green-fill">
                    <img src={orderIcon} />
                  </span>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <div className="accordion-title">
                          <div className="accordion-icon">
                            <span style={{display:'flex',flexWrap:'wrap',width:'100%',justifyContent:'space-between'}}>
                                <span>
                                  <h5>Order and Payment</h5>
                                  <div className="cnc-box">
                                    <p>
                                    <p>Order placed on {moment( orders?.createdAt).format("DD MMM YYYY")}</p>
                                    </p>
                                  </div>
                                </span>

                                <div style={{marginRight:'30px',display:'flex',width:'50%',justifyContent:'space-between'}}>
                                    <div className="shipto">
                                      <span className="ship">Ship to</span>
                                      <div className="name">
                                      {orders.shippingAddress?.firstName? orders.shippingAddress?.firstName + " " + orders.shippingAddress?.lastName : "Loading"}
                                      </div>
                                    </div>
                                    <div className="total">
                                      <span className="text" style={{ color: '#858585'}}>Total</span>
                                      <div className="amount">  {currencyFormatter(orders?.tax? ((orders?.subTotal) + (orders?.subTotal*orders?.tax/100) + orders?.adjustmentValue).toFixed(2):Number(orders?.subTotal+orders?.adjustmentValue))}</div>
                                    </div>
                                </div>
                            </span>
                          <img
                              src={
                                expanded === "panel2" ? upArrowIcon : downArrowIcon
                              }
                              alt="arrowIcon"
                              style={{height:'8px',marginRight:'10px'}}
                            />
                          </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="pro-bar">
                        <div className="pro-text">
                          <div className="pro-bar-one"></div>
                          <div className="pro-bar-two">
                            <div className="col"  style={{marginTop:'10px'}}>
                              <h6>Shipping Address:</h6>
                              <div className="desc-part">
                            <p>
                              {orders.shippingAddress?.firstName + " " + orders.shippingAddress?.lastName}
                            </p>

                            <p>{orders.shippingAddress?.addressLineOne}</p>

                            <p>{orders.shippingAddress?.addressLineTwo}</p>

                            <p>
                              {orders.shippingAddress?.city +
                                ", " +
                                orders.shippingAddress?.state +
                                ", " +
                                orders.shippingAddress?.country}
                            </p>

                            <p>{orders.shippingAddress?.postalCode}</p>
                              </div>
                            </div>

                            <div className="col" style={{marginTop:'10px'}}>
                              <h6>Payment Information</h6>
                              <p>{orders?.transactionId ? 'Card Payment' : 'Pay later was selected for this part'}</p>
                            </div>
                            <div className="col" style={{marginTop:'10px'}}>
                              <h6>PO Number</h6>
                              <p>
                                {orders?.purchaseOrderNumber || "Not Updated by Admin yet"}
                              </p>
                            </div>
                            <div className="col" style={{marginTop:'10px'}}>
                              <h6>PO Document </h6>
                              <p style={{ color: "dodgerblue" }}>
                                {orders?.poDocumentFile ? (
                                  <>
                                    {orders?.poDocumentFile.originalname}{" "}
                                    <span
                                      onClick={() =>
                                        handleFrmURL(
                                          `${config.backEnd}/` +
                                          orders?.poDocumentFile.path,
                                          orders?.poDocumentFile.originalname
                                        )
                                      }
                                    >
                                      <img
                                        src={DownloadImage}
                                        style={{
                                          marginLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                        alt="download"
                                      />
                                    </span>
                                  </>
                                ) : (
                                  "Not Updated by Admin yet "
                                )}
                              </p>
                            </div>
                            <div className="accordion-total" style={{marginTop:'10px'}}>
                              <h6>Order Summary</h6>
                              <div className="subtotal">
                                <p>Item(s) Subtotal : </p>
                                <p> {currencyFormatter((orders?.subTotal - orders?.shippingCharge - orders?.additionalCost)?.toFixed(2))}</p>
                              </div>
                              <div className="subtotal">
                                <p>Shipping & Duties : </p>
                                <p> {currencyFormatter(orders?.shippingCharge)}</p>
                              </div>

                              {orders?.quoteId?.isQrTrackingRequested?
                              <>
                                <div className="subtotal">
                                  <p>QR Tracking : </p>
                                  <p> {currencyFormatter(orders?.additionalCost)}</p>
                                </div>
                              </>:
                              <></>
                              }

                              <div className="subtotal">
                                <p>Taxes ( {orders?.tax}% ) : </p>
                                <p> {orders?.tax>0? currencyFormatter((((orders?.subTotal)*orders?.tax)/100).toFixed(2)) : 0 }</p>
                              </div>

                              {orders?.adjustmentValue!=0?
                              <>
                                <div className="subtotal">
                                  <p>Adjustments : </p>
                                  <p>{currencyFormatter(orders?.adjustmentValue)}</p>
                                </div>
                              </>:
                              <></>
                              }

                              <div className="total">
                                <p>Total : </p>
                                <p>
                                  { currencyFormatter((( orders?.subTotal)+(((orders?.subTotal)*orders?.tax)/100)+ orders?.adjustmentValue ).toFixed(2)) }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className={orders?.shippedDate?"process-bar-bx upload-req or-details-row green-line":"process-bar-bx upload-req or-details-row grey-line" }>
                  <span className={orders?.shippedDate?"process-ico green-fill":"process-ico grey-fill"}>
                    <img src={shippingIcon} />
                  </span>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                  >
                    <AccordionSummary
                      // expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3bh-content"
                      id="panel2bh-header"
                    >
                      <div>
                        <div className="accordion-title">
                          <div className="accordion-icon">
                            <h5>Shipping</h5>
                            <img
                              src={
                                expanded === "panel3" ? upArrowIcon : downArrowIcon
                              }
                              alt="arrowIcon"
                              style={{height:'8px',marginRight:'10px'}}
                            />
                          </div>
                        </div>
                        <div className='shipping-details'>
                          <p>{orders?.shippedDate && new Date(orders?.shippedDate) > new Date(Date.now())
                            ? `Shipping by ${moment(
                              orders?.shippedDate
                            ).format("DD MMM YYYY")}`
                            : orders?.shippedDate 
                              ? `Shipping on ${moment(
                                orders?.shippedDate
                              ).format("DD MMM YYYY")}`
                              : "Shipping Pending"}</p>
                        </div>  
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                    <div className="or-details-row">
                      <div className="right-btns">
                          {orders?.consignmentPhoto?.length > 0?
                            <button className="white-btn" style={{marginLeft:'auto'}} onClick={handleConsignmentModal}>View Pre-Consignment Photos</button>
                          :
                            <></>
                          }
                        </div>
                      <div className="pro-bar">
                        <div className="pro-text">
                          <div className="pro-bar-one"></div>

                          <div className="pro-bar-two">
                            <div className="col">
                              <h6>Shipping Date</h6>
                              <div className="desc-part">
                                <p>
                                  {orders?.shippedDate &&
                                    orders?.isCompleted == false
                                    ? `${moment(
                                      orders?.shippedDate
                                    ).format("DD MMM YYYY")}`
                                    : orders?.shippedDate
                                      ? `${moment(
                                        orders?.shippedDate
                                      ).format("DD MMM YYYY")}`
                                      : "Shipping Pending"}
                                </p>
                              </div>
                            </div>
                            <div className="col">

                              {
                                isUrlValid(deliveryTrackingLink)?
                                <a className="trk-order" target="_blank" href={deliveryTrackingLink} onClick={()=> {
                                  let userId = localStorage.getItem('_id');
                                  if (window.location.host === 'make.8xparts.com') {
                                  // window?.dataLayer?.push({
                                  //   event: 'trackShipment',
                                  //   userId: userId,
                                  //   salesOrderRefId:orders.RefId,
                                  // })
                                  }
                                }}>Track Order</a>
                                :(!isNaN(deliveryTrackingLink) && deliveryTrackingLink!='')? <div className="trk-order" onClick={handleDeliveryTrackingNumber}>Track Order</div>
                                :deliveryTrackingLink!=''?<div>  
                                  <div className="trk-unavailable">
                                  <div> <p>Order Tracking Information :</p></div> 
                                    <div> <p>{deliveryTrackingLink}</p></div>                                  
                                  </div>  
                                </div>
                                :
                                <div className="trk-unavailable">  
                                  <button disabled className="">Order Tracking Unavailable</button>
                                  <div> <p>*You will be able to track the order once tracking detail is updated by admin</p></div>  
                                </div>
                              }
                            </div>
                          </div>

                          {
                      (Object.keys(dhlShipmentInformation).length>0 && showDeliveryTrackingInformation)?
                      <div className="shipment-details">
                        <div className="shipment-details-header">
                          <div>
                              <p>Waybill Number : <strong>{" "+dhlShipmentInformation.id}</strong></p>
                              <p>Status : {dhlShipmentInformation.status?.description}</p>
                              {dhlShipmentInformation.status?.status==="delivered"?<a href={dhlShipmentInformation.details.proofOfDelivery?.documentUrl}>Get Proof of Delivery</a>:null}
                          </div>
                          <div>
                            <p>
                              {new Date(dhlShipmentInformation.events[0]?.timestamp).toLocaleString('en-US', {
                                dateStyle: 'full',
                                timeStyle: 'short',
                                hour12: false,
                              }) }
                            </p>

                            <span>
                              <p>Origin service area : </p>
                              <a href={dhlShipmentInformation.origin.servicePoint?.url}><h6>{dhlShipmentInformation.origin.address?.addressLocality}</h6></a>
                            </span>

                            <span>
                              <p>Destination Service Area:</p>
                              <a href={dhlShipmentInformation.destination.servicePoint?.url}><h6>{dhlShipmentInformation.destination.address?.addressLocality}</h6></a>
                            </span>
                          </div>
                            
                          <div>
                            {dhlShipmentInformation.details.pieceIds?.length} Piece/Pieces <img src={showShipmentPartsData?upArrowIcon:downArrowIcon} onClick={() => {
                                                                                            setShowShipmentPartsData(!showShipmentPartsData)
                                                                                          }}
                                                                                          />
                            {showShipmentPartsData?
                            <>
                              {dhlShipmentInformation.details.pieceIds.map((item,i)=>{
                                return <p style={{fontSize:"12px"}}>{item}</p>
                              })}
                            </>
                            :null}
                          </div>
                        </div>



                        <div className="tracking-details-div">
                              <table>
                                <thead>
                                <tr className="table-headers">
                                  <th></th>
                                  <th>
                                  {new Date(dhlShipmentInformation.events[0]?.timestamp).toLocaleString('en-US', {
                                    dateStyle: 'full',
                                    timeStyle: 'short',
                                    hour12: false,
                                  }) }
                                  </th>
                                  <th>
                                    Location
                                  </th>
                                  <th>
                                    Time
                                  </th>
                                  <th>
                                    Pieces
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                  {dhlShipmentInformation.events.map((item,i)=>{
                                    return<tr key={i} className="details-row">
                                            <td className="sr-no-td">{dhlShipmentInformation.events?.length-i}</td>
                                            <td className="description-td">{item.description}</td>
                                            <td>{item.location.address?.addressLocality}</td>
                                            <td>{new Date(item.timestamp).toLocaleString()}</td>
                                            <td className="piece-td">{dhlShipmentInformation.details.pieceIds?.length} Pieces</td>
                                          </tr>
                                  })}

                                </tbody>
                              </table>
                              <button className="trk-order" style={{marginTop:'30px'}} onClick={handleTrackingDetailsClose} >Close</button>
                        </div>
                      </div>
                      :null
                      }
                        </div>
                      </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="order-pay-box">
                  <span
                    className={
                      orders?.expectedDelivery
                        ? "order-ico green-fill"
                        : "order-ico grey-fill"
                    }
                  >
                    <img src={tickwhite} />
                  </span>
                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                  >
                    <AccordionSummary
                      aria-controls="panel4bh-content"
                      id="panel4bh-header"
                    >
                      <div className="accordion-title"> 
                        <div className="accordion-icon">
                          <h5 className="deliveryTitle">Delivery</h5>
                          <img
                            src={
                              expanded === "panel4" ? upArrowIcon : downArrowIcon
                            }
                            alt="arrowIcon"
                            style={{height:'8px',marginRight:'10px'}}
                          />
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="or-details-row">
                        <div className="right-btns">
                          {orders?.qualityDocuments?.length > 0 || orders?.shippedDate?
                            <button className="white-btn" style={{marginLeft:'auto'}} onClick={handleQualityDocModal}>View Quality Documents</button>
                          :
                            <></>
                          }           
                        </div>
                        <div className="pro-bar">
                          <div className="pro-text">
                            <div className="pro-bar-two">
                              <div className="col">
                                <h6>Status </h6>
                                {orders?.isCompleted ? (
                                  <p>Delivered</p>
                                ) : (
                                  <p>Pending</p>
                                )}
                              </div>
                              <div className="col">
                                <h6>Delivered Date</h6>
                                <p>
                                  {orders?.expectedDelivery ? 
                                  new Date(orders?.expectedDelivery)> new Date(Date.now())? `Delivery by ${moment(
                                        orders?.expectedDelivery
                                      ).format("DD MMM YYYY")}`
                                    :  `Delivered on ${moment(
                                        orders?.expectedDelivery
                                      ).format("DD MMM YYYY")}`
                                    : "Delivery Pending"}
                                </p>
                              </div>
                              <div className="col">
                                <h6>Delivery Comment</h6>
                                <p>
                                  {orders?.deliveryComment &&
                                  orders?.deliveryComment !== ""
                                    ? orders?.deliveryComment
                                    : "Comments not available"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {orders?.expectedDelivery ? 
                        <div className="pro-bar">
                          <div className="pro-text">
                            <h6>Your feedback is very valuable to us! Please share your feedback below:</h6>
                            <div className="pro-bar-two">
                              <div className="col">
                                <h6>Quality</h6>
                                <>
                                  <img src={orders?.qualityRating>=1?starsIcon:starsIconGrey} alt="rating" />
                                  <img src={orders?.qualityRating>=2?starsIcon:starsIconGrey} alt="rating" />
                                  <img src={orders?.qualityRating>=3?starsIcon:starsIconGrey} alt="rating" />
                                  <img src={orders?.qualityRating>=4?starsIcon:starsIconGrey} alt="rating" />
                                  <img src={orders?.qualityRating>=5?starsIcon:starsIconGrey} alt="rating" />
                                </>
                              </div>
                              <div className="col">
                                <h6>Value for money</h6>
                                <>
                                  <img src={orders?.valueForMoneyRating>=1?starsIcon:starsIconGrey} alt="rating" />
                                  <img src={orders?.valueForMoneyRating>=2?starsIcon:starsIconGrey} alt="rating" />
                                  <img src={orders?.valueForMoneyRating>=3?starsIcon:starsIconGrey} alt="rating" />
                                  <img src={orders?.valueForMoneyRating>=4?starsIcon:starsIconGrey} alt="rating" />
                                  <img src={orders?.valueForMoneyRating>=5?starsIcon:starsIconGrey} alt="rating" />
                                </>
                              </div>
                              <div className="col">
                              <h6>Overall Rating</h6>
                                <>
                                  <img src={orders?.overAllRating>=1?starsIcon:starsIconGrey} alt="rating" />
                                  <img src={orders?.overAllRating>=2?starsIcon:starsIconGrey} alt="rating" />
                                  <img src={orders?.overAllRating>=3?starsIcon:starsIconGrey} alt="rating" />
                                  <img src={orders?.overAllRating>=4?starsIcon:starsIconGrey} alt="rating" />
                                  <img src={orders?.overAllRating>=5?starsIcon:starsIconGrey} alt="rating" />
                                </>
                              </div>
                              <div className="col">
                                <h6>Comment / Suggestions : </h6>
                                <textarea disabled cols="30" rows="5" style={{maxWidth:'95%'}} placeholder="Enter you feedback here" value={orders?.feedBackComment} />
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <>
                        </>}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>

                {/* upload requirement close */}
              </div>

              <div className="privacy-text">
                <strong>Privacy:</strong> 8xParts 3D secures all uploaded files,
                protecting your intellectual property. If you can’t upload your
                files at the moment, use our sample part to try out our instant
                quoting and DfM tools or contact us on <a href="mailto: info@8xparts.com">info@8xparts.com</a> to sign an NDA.
              </div>
            </div>
          </div>
          </>
          :(!isValidUser)?
              <>
                <div className="dashboard-holder">
                    <div className="sm-container">
                      <div className="dashboard-option">
                        <div className="option-container">
                          <h2>You are not  authorized to view  this  order.</h2>
                        </div>
                      </div>   
                      <div className="privacy-text">
                          <strong>Privacy:</strong> 8xParts 3D secures all uploaded files,
                          protecting your intellectual property. If you can’t upload your
                          files at the moment, use our sample part to try out our instant
                          quoting and DfM tools or contact us on <a href="mailto: info@8xparts.com">info@8xparts.com</a> to sign an NDA.
                      </div>
                    </div>
                  </div>
              </>
            :
              <>
                <div className="dashboard-holder">
                  <div className="md-container">
                    
                  </div>
        
                  <div className="sm-container">
                          <div className="dashboard-option">
                              <h3>Welcome to 8xParts Make Portal ! </h3>
                              <p>We are delighted to have you here. You can use  this  portal to view the order details which was created in our portal. </p>
                              <p>To view the order details , please provide us with  you email address.</p>
                              <div>
                                <FormControl
                                value={userEmail}
                                placeholder="Enter you email address"
                                onChange={(e)=> setUserEmail(e.target.value)}
                                name="user-email"
                                style={{minWidth:'200px',maxWidth:'400px'}}
                                onKeyDown={(e) => onKeyDown(e)}
                                />
                                <button onClick={handleSubmitClick} className="white-btn" style={{border:'1px solid lightgray',marginTop:'10px'}}>
                                  Submit
                                </button>
                              </div>
                          </div>   
                        </div>
                </div>
             
              </>
        }
    </div>
  );
};
export default ViewOrderDetails;
