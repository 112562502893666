import React, { useState } from "react";

const EditProjectName = ({ show, currentName, onSave, onCancel }) => {
    const [projectName, setProjectName] = useState(currentName || "");

    if (!show) return null;

    const handleSave = () => {
        if (projectName.trim()) {
            onSave(projectName);
            onCancel();
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="flex flex-col items-center p-4 bg-white shadow-md rounded-md w-[400px] h-64 justify-between">
                <h3 className="text-lg font-semibold text-gray-700 mb-2 text-left">Edit Project Name</h3>
                <input
                    type="text"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    placeholder="Enter new project name"
                    className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-blue-300"
                />
                <div className="flex justify-end gap-2 mt-4 w-full">
                    <button
                        onClick={onCancel}
                        className="px-4 py-2 text-sm text-gray-500 bg-gray-200 rounded-md hover:bg-gray-300"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSave}
                        className="px-4 py-2 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditProjectName;
