import React, { useEffect, useState } from 'react'
import "./AllAddress.scss";
import Modal from "../Modal/Modal";
import { deleteAddress, getAllAddress } from '../../utils/actions/allactions';
import AddressModal from './AddressModal';
import { toast } from "react-toastify";

const AddressList = ({ showAddressList, setShowAddressList, values, setValues, setError, error,setRecentAddress,recentAddress }) => {
  const [allAddress, setAllAddress] = useState([]);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [editId, setEditId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const handleRecentAddressUpdate = ()=>{
    let localRecentAddress = recentAddress;
    // console.log("add address",allAddress)
    if(allAddress.length>2){
      for(let i=0;i<allAddress.length;i++){
        if(i<2){
          localRecentAddress[i] = allAddress[i];
        }
        if(i>=2){
          if(allAddress[i]._id == values.shippingAddress){
            localRecentAddress[0] = allAddress[i];
          }
        }
      }
      setRecentAddress(localRecentAddress);
    }else{
      setRecentAddress(allAddress);
    }
  }

  const token = localStorage.getItem("Token");
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getAllAddress(token);
        let reversedData = response.data.reverse();

        setAllAddress(reversedData);
      } catch (err) {
        return err;
      }
    }
    fetchData();
  }, []);


  const addNewHandler = () => {
    setShowAddressModal(true);
  }

  const editHandler = (id) => {
    setShowAddressModal(true);
    setIsEdit(true)
    setEditId(id)
  }
  const deleteData = async (_id) => {
    try {
      if(values.shippingAddress==_id){
        setValues({...values,shippingAddress:""})
      }

      const payload = {
        _id,
      }
      let response = await deleteAddress({ payload }, token);

      const newData = allAddress.filter((address, index) => {
        return address._id !== _id
      });
      setAllAddress(newData);

      if (response.data.status == false) {
        toast.error("something went wrong");
      }
      else if (response.data.status == true) {
        toast.success("Address Deleted Successfully");
      }
      handleRecentAddressUpdate();
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <AddressModal
        addressModal={showAddressModal}
        setShowAddressModal={setShowAddressModal}
        setRecentAddress={setAllAddress}
        recentAddress={allAddress}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        _id={editId}
        allAddress={allAddress}
        setAllAddress={setAllAddress}
        isAddressList={true}
      />
      
      <Modal
        isShowModal={showAddressList}
        onCloseModal={() => {setShowAddressList(false);handleRecentAddressUpdate();}}
        showClose={true}
        className="allAddressModal"
      >
        <div className="title">All Address</div>
        <div className="straightLine"></div>
        <div className="selectAddress">Select Address</div>
        <div className="addressContainer">
          <div className="addressDiv">
            {
              allAddress.map((el, id) => {
                return (
                  <div className="container" key={id}>
                    <div className="addressDiv">
                      <input
                        className="btn-radio"
                        type="radio"
                        name="rd1"
                        checked={values.shippingAddress == el._id}
                        value={el._id}
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                          setError({
                            ...error,
                            shippingAddress:"",
                          })
                          setValues({...values, shippingAddress: el._id})}}
                      />
                      <div className="address">
                        <div className="name">{el?.firstName}{" "}{el?.lastName}</div>
                        <div className="add">{el?.addressLineOne}{" "}{el?.addressLineTwo}{" "}{el?.city}{" "}{el?.state}{" "}{el.postalCode}{" "}{el.country}</div>
                      </div>
                    </div>
                    <div className="buttons">
                      <div className="edit" onClick={() => editHandler(el._id)}>Edit</div>|
                      <div className="delete" onClick={() => deleteData(el._id)}>Delete</div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="straightLine"></div>
        <div className="bottomLinks">
          <div className="addNew" onClick={addNewHandler}>
            + Add New Address
          </div>
          <div className="buttons">
            {/* <div className="back"}>
              Back
            </div> */}
            <div className="done"  onClick={() => {
                                                    setShowAddressList(false);
                                                    handleRecentAddressUpdate();
                                                  }
                                            }>
              Done
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AddressList