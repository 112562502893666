import React from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell, Legend } from 'recharts';

const data01 = [
    { name: 'Quotation', value: 400 },
    { name: 'Sales', value: 300 },
    { name: 'Shipped', value: 300 },
    { name: 'Completed', value: 200 },
    { name: 'Issues', value: 278 },
];


export const colorMap = {
    Quotation: '#8884d8',  
    Sales: '#82ca9d',
    Shipped: '#8dd1e1',
    Completed: '#a4de6c',  
    Issues: '#ff6666',     

};
export const colorMapForSupplier = {
    RFQ: '#4CAF50',
    Processing: '#FFC107',   
    Shipped: '#2196F3',      
    Delivered: '#FF9800',    
    Completed: '#9C27B0',    
    PurchaseOrder: '#F44336'  
  };
  

const Example = () => {
    return (
        <ResponsiveContainer width="100%" height='100%'>
            <PieChart>
                <Pie
                    dataKey="value"
                    data={data01}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label
                >
                    {data01.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colorMap[entry.name]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </ResponsiveContainer>
    );
}

export default Example;
