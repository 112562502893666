
import React, { useEffect, useState, useRef, } from "react";
import fileSaver from "file-saver";
import whiteLoader from "../../images/white-loader.gif";
import Header from "../../components/Header/header";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { useNavigate, useParams, useLocation, Link, useSearchParams } from "react-router-dom";
import ProcessSection from "./components/ProcessSection";
import SelectFileSection from './components/SelectFileSection';
import { commanFields, MATERAILS_VALID_FOR_ML } from '../../utils/constant'
import CollectData from './components/CollectData';
import { forwardToPurchaserMailTriggerFunction, getDefaultAddressOfUser, searchUserByEmail } from "../../utils/actions/commanActions";
import ForwardToPurchaseModal from "../../components/ForwardToPurchaserModal/index"
import { postApi, fetchApi, getPredictedValueForPart } from "../../utils/actions/commanActions";
import jwt_decode from 'jwt-decode';
import {
  getAllBulkPricing,
  getMaterial,
  getAllUsers,
  quoteSaveMail,
  getThumbnail,
  countOrdersUser,
  getAddressById,
  downloadPdf,
  handleQuoteCancel,
  moveQuoteToProgressStatus,
} from "../../utils/actions/allactions";
import { triggerFunc, triggerFuncLink, } from '../../utils/trigger';
import Processing from '../../images/processing.svg';
import GettingQuote from '../../images/getting-quote.svg';
import { storage } from "../../utils/firebase";
import { useCreateQuoteContext } from "../../context/create.quote.context";
import { getAllTechnology, getMetalCCDataForMaterial } from "../../utils/actions/adminActions";
import Chat from "../../Chat";
import TeamCollaboration from "../../components/TeamCollaboration/TeamCollaboration";
import { } from "../../components/ForwardToPurchaserModal";
import { LucidLoader } from "../../components/Icons";
import ShareModalComponent from "../../components/ShareComponent/ShareModalComponent";
import TargetCostUserModal from "../../components/TargetCostUserModal/TargetCostUserModal";

const config = require(`../../environment/development`).config;

const InstantQuote = () => {
  const myRef = useRef();
  const navigate = useNavigate();
  let { _id } = useParams();
  let token = localStorage.getItem('Token');
  const userEmail = localStorage.getItem("email");
  let email = localStorage.getItem("email");
  let user_id = localStorage.getItem("_id");
  let decode;
  const [step, setStep] = useState(_id ? 2 : 1);
  const [isEditQuote, setIsEditQuote,] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isProcessNotSelected, setIsProcessNotSelected] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState();
  const [newQuoteId, setNewQuoteId] = useState();
  const [values, setValues] = useState([]);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [commanValues, setCommanValues] = useState({
    price: '',
    price1: '',
    price2: '',
    selectedShipMethod: 0,
    sheetAndInjectionDiscription: "",
    sheetAndInjectionQuantity: 0,
    hidePrice: false,
    isConformanceCertificateRequested: false,
    isDimensionalReportRequested: false,
    isQrTrackingRequested: false,
    additionalCost1: 0,
    additionalCost2: 0,
    additionalCost3: 0,
    shippingCharge1: 0,
    shippingCharge2: 0,
    shippingCharge3: 0,
    leadTime1: 10,
    leadTime2: 15,
    errors: {
      MaterialType: false,
      SubMaterial: false,
      sheetAndInjectionDiscription: false,
      sheetAndInjectionQuantity: false
    },
    isUpdatedByAdmin: false,
    myorders: false,
  });

  useEffect(() => {

    if (commanValues.price || commanValues.price1) {
      setCommanValues((pre) => ({ ...pre, hidePrice: false }))
    }
  }, [commanValues.price, commanValues.price1])
  const [sameForAllField, setSameForAllField] = useState(false);
  const [isTargetCostModalOpen, setIsTargetCostModalOpen] = useState(false);
  let [stmp, setStmp] = useState(0);
  let [simp, setSimp] = useState(0);
  const [files, setFiles] = useState([])
  const [showUserModal, setShowUserModal] = useState(false);
  let [sample, setSample] = useState(false);
  let [askFOptm, setAskFOptm] = useState(false);
  const [showCad, setShowCad,] = useState(false);
  var [bounding, setBounding,] = useState([]);
  const [boxDimension, setBoxDimension,] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [isInvalidFiles, setIsInvalidFiles] = useState(false);
  const [globeLoader, setGlobeLoader] = useState(false);
  const [isQuoteChanged, setIsQuoteChanged] = useState(false);
  const [threeDError, setThreeDError] = useState("Manual review is required for the parts you have uploaded.");
  const [pricingData, setPricingData] = useState([]);
  const [material, setMaterial] = useState([]);
  const [subMaterial, setSubMaterial] = useState([]);
  const [partmarking, setPartMarking] = useState([]);
  const [surfaceFinish, setSurfaceFinish] = useState([]);
  const [surfaceTreatment, setSurfaceTreatment] = useState([]);
  const [tolerance, setTolerance] = useState([]);
  const { state } = useLocation();
  const [selectedQuoteRef, setSelectedQuoteRef] = useState("")
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [allFilteredUsers, setAllFilteredUsers] = useState([]);
  const [userIdToEditQuote, setUserIdToEditQuote] = useState({})
  const [isAdminShare, setIsAdminShare] = useState(false)
  let saveForFunctionality = state?.functionalityName;
  let savedQuoteRefId = state?.savedQuoteRefId;
  let option = state?.option;
  const [matSubMatMap, setMatSubMatMap] = useState(new Map());
  const [isValidUser, setIsValidUser] = useState(true);
  const [quoteUserEmail, setQuoteUserEmail] = useState('');
  const [isValidValuesForQR, setIsValidValuesForQR] = useState(true);
  const [twoDFiles, setTwoDFiles] = useState({});
  const [isQuoteCreationInProcess, setIsQuoteCreationInProcess] = useState(false);
  const [showQuoteUpdateModal, setShowQuoteUpdateModal] = useState(false);
  const [dataForQuote, setDataForQuote] = useState(null);
  const [isSaveForFunctionalityState, setIsSaveForFunctionalityState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const repeatOrder = searchParams.get('RepeatOrder') == "true" ? true : false;
  const isRepeatOrderWithUpdate = searchParams.get('isRepeatOrderWithUpdate') == "true" ? true : false;
  
  const [masterThreeDTechnologyData, setMasterThreeDTechnologyData] = useState([]);
  const [minCostTechMachineMaterialPostProcess, setMinCostTechMachineMaterialPostProcess] = useState({});
  const [isInchSelected, setIsInchSelected] = useState(false);
  const [draftQuoteId, setDraftQuoteId] = useState('');
  const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);

  const [isForwardToPurchaserModalOpen, setIsForwardToPurchaserModalOpen] = useState(false);
  const [teamModalShow, setTeamModalShow] = useState(false);

  const funcName = searchParams.get("functionalityName");
  // let { projectName } = useCreateQuoteContext();
  let {createQuoteState}  = useCreateQuoteContext();
  let projectName = createQuoteState?.commanValues?.projectName;
  const [isChatOpen, setIsChatOpen] = useState(false);


  const [getAllUserAddress, setAllUserAddress] = useState();




  useEffect(() => {
    if (funcName == 'chat') {
      if (selectedQuoteRef) setIsChatOpen(true);
    }
    if (funcName == 'ftp') {
      setIsForwardToPurchaserModalOpen(true);
    }
    if (funcName == 'teams') {
      setTeamModalShow(true);
    }
    if (funcName == 'share' && selectedQuote) {
      setIsShareModalOpen(true);
    }
    if (funcName == 'stc') {
      setIsTargetCostModalOpen(true);
    }
  }, [selectedQuoteRef, location.search, selectedQuote]);

  useEffect(() => {
    if (funcName == 'dq' && selectedQuote && step == 2 && isAllDataLoaded) {
      console.log("Download Quotation Run ")
      handleDownloadQuotation();
    }
  }, [selectedQuote]);



  const handleDownloadQuotation = async () => {
    try {
      const id = localStorage.getItem('_id');
      if (!selectedQuote) {
        toast.error("Unable To Download Quote At The Moment");
        return;
      }


      const response = await getDefaultAddressOfUser(id);
      if (!response || !response?.success) {
        throw new Error();
      }
      const defaultAddress = response?.data;

      const selectedShipMethod = selectedQuote?.selectedShipMethod;

      let data = {
        fname: defaultAddress.firstName,
        lname: defaultAddress.lastName,
        email: localStorage.getItem("email"),
        quoteId: selectedQuote.RefId,
        total: selectedQuote.price,
        addressline1: defaultAddress.addressLineOne,
        addressline2: defaultAddress.addressLineTwo,
        city: defaultAddress.city,
        state: defaultAddress.state,
        country: defaultAddress.country,
        zipcode: defaultAddress.postalCode,
        partData: JSON.stringify(selectedQuote.partsData),
        process: selectedQuote.selectedProcess,
        price: selectedQuote.price,
        price1: selectedQuote.price1,
        price2: selectedQuote.price2,
        selectedShipMethod: selectedShipMethod,
        sheetMetalAndInjectionDesc: selectedQuote.sheetAndInjectionDiscription,
        sheetMetalAndInjectionMP: selectedQuote.sheetAndInjectionManualPrice,
        sheetAndInjectionQuantity: selectedQuote.sheetAndInjectionQuantity,
        manualPrice: selectedQuote.stManualPrice,
        forInvoice: false,
        hidePrice: selectedQuote.hidePrice,
        isQrTrackingRequested: selectedQuote.isQrTrackingRequested,
        isDimensionalReportRequested: selectedQuote.isDimensionalReportRequested,
        isConformanceCertificateRequested:
          selectedQuote.isConformanceCertificateRequested,
        additionalCost:
          selectedShipMethod == 0
            ? selectedQuote.additionalCost1
            : selectedShipMethod == 1
              ? selectedQuote.additionalCost2
              : selectedQuote.additionalCost3,
        shippingCharge:
          selectedShipMethod == 0
            ? selectedQuote.shippingCharge1
            : selectedShipMethod == 1
              ? selectedQuote.shippingCharge2
              : selectedQuote.shippingCharge3,
        leadTime:
          selectedShipMethod == 0
            ? selectedQuote.leadTime1
            : selectedShipMethod == 1
              ? selectedQuote.leadTime2
              : selectedQuote.leadTime3,
        projectName: selectedQuote.projectName,
        date: selectedQuote.createdAt,
        version: selectedQuote.version,
        quotationNotes: selectedQuote.quotationNotes,
        quotationTnc: selectedQuote.quotationTnc,
      };

      let priceValue =
        selectedShipMethod == 0
          ? selectedQuote.price
          : selectedShipMethod == 1
            ? selectedQuote.price1
            : selectedQuote.selectedProcess <= 2
              ? selectedQuote.stManualPrice
              : selectedQuote.sheetAndInjectionManualPrice;
      let fileName = `${selectedQuote.RefId}-V-${selectedQuote.version}-${Number(
        priceValue
      ).toFixed(2)}.pdf`;

      downloadPdf(data, token)
        .then((res) => {
          fileSaver.saveAs(
            `${config.backEnd}/public/docs/` + res?.data?.data,
            fileName
          );
        })
        .catch((err) => {
          console.log(err);
          console.log(
            "error in quote version pb on 267 on:: " +
            moment(Date.now()).format("DD MMM YYYY hh:mm:ss a"),
            err
          );
        });


    } catch (error) {
      console.log(error);
      toast.error("Unable To Download Quotation At The Moment");
    }

  }





  let cncError = "We are unable to fetch quotation for some of the selections at the moment. Please sent quote for review, Our applications engineering team will review and get back to you shortly."
  useEffect(() => {
    (async () => {
      try {
        setProcessing(true);
        const response = await getAllTechnology(token);
        if (!response || !response.success) {
          toast.error("Unable To Fetch Technology");
          return;
        }
        const { data } = response;
        const minCostMachineMaterialPostProcessMap = {};
        data.forEach((tech) => {
          const { machines, name: techName } = tech;
          machines?.forEach((machine) => {
            const { name: machineName } = machine;
            const { materials } = machine;
            materials?.forEach((material) => {
              const { name: materialName, minCost } = material;
              console.log(minCost);
              const { postProcess } = material;
              postProcess?.forEach((pProcess) => {
                const { name: postProcessName, color } = pProcess;
                const key = techName + materialName;
                minCostMachineMaterialPostProcessMap[key] = minCost;
              })

            })

          })

        })
        console.log(minCostMachineMaterialPostProcessMap);
        setMinCostTechMachineMaterialPostProcess(minCostMachineMaterialPostProcessMap);
        setMasterThreeDTechnologyData(data);
        setProcessing(false);

      } catch (error) {
        setLoading(false);
        toast.error("Unable To Fetch Technology");
      }
    })()

  }, [_id])

  
  useEffect(() => {
    if (masterThreeDTechnologyData.length && _id && step == 2) {
      fetchData();
    }
  }, [_id, masterThreeDTechnologyData])




  useEffect(() => {
    decode = jwt_decode(token);
    if (!token) {
      navigate('/');
    }
    else {
      if (!decode) {
        navigate('/');
        toast.error('Invalid Token found!');
      }
      else if (decode) {
        if ((projectName == undefined || projectName.length == 0) && (!_id)) {
          alert("Project name not found.Please enter the project name to continue.")
          navigate("/project-name")
        }
        if (decode.userType == 'user') {
          fetchBulkPricingData();
        }
        else if (decode.userType == 'admin') {
          fetchBulkPricingData();
          setIsAdminUser(true);
        }
        else {
          navigate('/');
        }
      }
    }
  }, [])


  // Use effect with no dependencies end

  // Use effect with dependencies start

  useEffect(() => {
    setIsInvalidFiles(false)
    async function handlePricing() {
      let localValues = [...values];
      let isAllFilesValid = true;
      for (let i = 0; i < localValues.length; i++) {
        let extension = getFileExtension(localValues[i]?.file?.originalname || localValues[i]?.selectedFile?.name);
        if (!(extension == 'stp' || extension == 'step' || extension == 'iges' || extension == 'stl' ||
          extension == 'STP' || extension == 'STEP' || extension == 'IGES' || extension == 'STL')) {
          isAllFilesValid = false;
        }
      }
      if (isAllFilesValid) {
        if (selectedProcess == 1) {
          handleCNCPriceUpdate();
        } else {
          handleThreeDPriceUpdate();
        }
      } else {
        setIsInvalidFiles(true);
        let updatedValues = [...values];
        updatedValues.forEach(value => {
          value.price = 0;
          value.price1 = 0;
          value.price2 = 0;
          value.stManualPrice = 0;
          value.sheetAndInjectionManualPrice = 0;
        })
        setCommanValues((val) => ({
          ...val,
          hidePrice: true,
          price: 0,
          price1: 0,
          price2: 0,
          leadTime1: 0,
          leadTime2: 0,
          isQrTrackingRequested: false
        }))
        setValues(updatedValues);
      }
    }
    if (selectedProcess <= 2) {
      handlePricing();
    }

  }, [selectedProcess, bounding, files]);


  useEffect(() => {
    const boundingBox = files?.map((fl) => {
      return bounding.filter((box) => {
        return ((fl?.name || fl?.originalname || fl?.selectedFile?.name || fl?.selectedFile?.path || fl?.file?.originalname) === box?.fileName);
      });
    });
    setBoxDimension(boundingBox);
  }, [bounding,])

  useEffect(() => {
    if (!isValidValuesForQR) {
      setCommanValues({ ...commanValues, isQrTrackingRequested: false });
    }
  }, [isValidValuesForQR])


  // useffect with dependencies end

  const updateAlgorithmPrice = (itemCost1) => {
    return itemCost1;
  }



  const fetchData = async () => {
    console.log("runnning :", 300);

    try {
      setProcessing(true);
      const materialResponse = await getMaterial(token);
      setMaterial(materialResponse.data.material);
      setSubMaterial(materialResponse.data.submaterial)
      setPartMarking(materialResponse.data.partMarking);
      setSurfaceTreatment(materialResponse.data.surfacetreat);
      setSurfaceFinish(materialResponse.data.surafcefinish);
      setTolerance(materialResponse.data.tolerance)

      if (!_id) {
        setProcessing(false);
        return;
      }

      const response = await fetchDataFromApi(`comman/fetch-quote-byId/${_id}`);
      const userId = localStorage.getItem("_id");
      const allAddress = await getAddressById(userId, token);
      console.log("All User Address :", allAddress);
      setSelectedQuote(response);
      if (userEmail?.split('@')[1] != response?.userId?.email?.split("@")[1] && userEmail?.split('@')[1] != "8xparts.com") {
        setIsValidUser(false);
      }
      setQuoteUserEmail(response?.userId?.email)
      setSelectedQuoteRef(response.RefId);
      setUserIdToEditQuote(response.userId._id);

      if (response) {
        const updatedValues = []
        for (var p = 0; p < response.partsData.length; p++) {
          const singleUpdatedValue = { ...commanFields }
          const singlePart = response.partsData[p]
          for (var key in singlePart) {
            singleUpdatedValue[key] = singlePart[key]
            singleUpdatedValue.errors[key] = false
          }
          updatedValues.push(singleUpdatedValue)
        }
        setStmp(response.stManualPrice);
        setSimp(response.sheetAndInjectionManualPrice)

        // Fetch files
        for (let val of response.partsData) {
          if (val.file || val.selectedFile) {
            let ext = getFileExtension(val?.file?.originalname || val?.selectedFile?.path);
            if (['stp', 'step', 'iges', 'stl', 'STP', 'STEP', 'IGES', 'STL'].includes(ext) && response?.selectedProcess && response?.selectedProcess <= 2) {
              try {
                // setIsLoading(true);
                const res = await fetch(`${config.backEnd}/public/uploads/${val._id}.${ext}`);
                // setIsLoading(false);
                triggerFuncLink(
                  res.url,
                  ext,
                  setBounding,
                  setProcessing,
                  val?.file?.originalname || val?.selectedFile?.path
                );
                setIsEditQuote(true);
              } catch (error) {
                console.error('Error fetching file:', error);
                setIsLoading(false);
                break;
              }
            } else {
              break;
            }
          }
        }

        if (response.partsData.length > 0) {
          const updatedCommanValues = { ...commanValues }
          updatedCommanValues.price = response.price
          updatedCommanValues.price1 = response.price1
          updatedCommanValues.price2 = response.price2
          updatedCommanValues.selectedShipMethod = response.selectedShipMethod
          updatedCommanValues.sheetAndInjectionDiscription = response.sheetAndInjectionDiscription
          updatedCommanValues.sheetAndInjectionQuantity = response.sheetAndInjectionQuantity
          updatedCommanValues.leadTime1 = response.leadTime1;
          updatedCommanValues.leadTime2 = response.leadTime2;
          updatedCommanValues.leadTime3 = response.leadTime3;
          updatedCommanValues.hidePrice = response.hidePrice
          updatedCommanValues.isConformanceCertificateRequested = response.isConformanceCertificateRequested
          updatedCommanValues.isDimensionalReportRequested = response.isDimensionalReportRequested
          updatedCommanValues.isQrTrackingRequested = response.isQrTrackingRequested
          updatedCommanValues.isUpdatedByAdmin = response.isUpdatedByAdmin;
          updatedCommanValues.isRepeatOrderQuotation = response.isRepeatOrderQuotation;
          updatedCommanValues.additionalCost1 = response.additionalCost1;
          updatedCommanValues.additionalCost2 = response.additionalCost2;
          updatedCommanValues.additionalCost3 = response.additionalCost3;
          updatedCommanValues.shippingCharge1 = response.shippingCharge1;
          updatedCommanValues.shippingCharge2 = response.shippingCharge2;
          updatedCommanValues.shippingCharge3 = response.shippingCharge3;
          updatedCommanValues.myorders = response.myorders;
          updatedCommanValues.projectName = response.projectName;
          updatedCommanValues.createdAt = response.createdAt;
          updatedCommanValues.version = response.version;

          if (response) {
            setCommanValues(updatedCommanValues)
            setValues(response.partsData)
            setSameForAllField(response.sameForAllField)
            setAskFOptm(response.askForOptimize)
          }

          if (response.selectedProcess && response.selectedProcess > 0) {
            setSelectedProcess(response.selectedProcess)
            setStep(2)
          }
          let twoFileDataFetched = {};
          response.partsData.forEach((item, i) => {
            if (item?.twoDFile) {
              twoFileDataFetched = { ...twoFileDataFetched, [i]: { file: item?.twoDFile, fileName: item?.twoDFile?.originalname || item?.twoDFile?.filename } }
            }
          });
          setTwoDFiles({ ...twoFileDataFetched });
        }
      }
      setProcessing(false);
      setIsAllDataLoaded(true);
    } catch (err) {
      setProcessing(false);
      let msg = err && err.message ? err.message : "Something went wrong during fetch quotes";
      toast.error(msg)
    }
  }


  let getFileExtension = (filename) => {
    return filename?.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
  }

  const onSelectfile = async (filesData, ext) => {
    try {
      if (!(filesData && filesData.length > 0)) {
        toast.error("Please select a valid file. Files exceeding 10 MB are not accepted.")
        return;
      };
      let userId = localStorage.getItem("_id")
      let userName = localStorage.getItem("firstName") + "_" + localStorage.getItem("lastName") + "_" + userId;
      // setProcessing(true);

      for (const file of filesData) {
        setProcessing(true);
        const res = await storage.ref(`/quotation-files/3D/${userName}/${Date.now() + "_FileName_" + (file?.originalname || file?.name || file?.path)}`).put(file);
        console.log(res);
        let extension = getFileExtension(file?.name || file?.originalname);
        if ((extension == 'stp' || extension == 'step' || extension == 'iges' || extension == 'stl' || ext == 'stp' ||
          extension == 'STP' || extension == 'STEP' || extension == 'IGES' || extension == 'STL' || ext == 'STP'
        ) && selectedProcess && selectedProcess <= 2) {

          //only selected formate     
          const { metadata: { fullPath } } = res;
          const serverResponse = await getThumbnail(fullPath);
          if (serverResponse.success) {
            const { image } = serverResponse;
            file.thumbnail = image;
          }
        }
        else {
          setProcessing(false);
        }





      };
      setIsQuoteChanged(true);

      let index = 0;
      for (let val of filesData) {
        let extension = getFileExtension(val?.name || val?.originalname);
        if ((extension == 'stp' || extension == 'step' || extension == 'iges' || extension == 'stl' || ext == 'stp' ||
          extension == 'STP' || extension == 'STEP' || extension == 'IGES' || extension == 'STL' || ext == 'STP'
        ) && selectedProcess && selectedProcess <= 2) {

          if (ext) {
            await triggerFuncLink(
              val,
              ext,
              setBounding,
              setProcessing,
              val?.name || val?.originalname,
              bounding
            );
          } else {
            // console.log('cam eher to trigger', firebaseResponseForEachFile[index]);
            // const imageFileUrlForPart = await getFileImageFromThreeDFile(token, firebaseResponseForEachFile[index]);
            await triggerFunc(val, extension, setBounding, setProcessing, bounding);
          }
        }
        setStmp(0);
        setSimp(0);

        index++;
      }


      if (filesData[0]?.name == "test2.jpg") {
        const valueCollection = [...values]
        const filesCollection = [...files]
        for (let f = 0; f < filesData.length; f++) {
          const singleValue = {
            selectedFile: filesData[f],
            ...commanFields,
          }
          valueCollection.push(singleValue)
          filesCollection.push(Object.assign(filesData[f], {
            isNotFromMongoDB: true,
          }))
        }

        setFiles(filesCollection)
        if (valueCollection && valueCollection.length > 0) {
          setValues(valueCollection)
          setStep(2)
        }
        setSample(true);
      } else {
        const valueCollection = [...values];
        const filesCollection = [...files];
        for (let f = 0; f < filesData.length; f++) {
          // const file = filesData[f];
          // const formData = new FormData();
          // formData.append("file", file);
          // let thumbnail = null;
          // const response = await getThumbnail(formData);
          // if (response && response.success) {
          //   const { image } = response;
          //   thumbnail = image;
          // }
          const singleValue = {
            selectedFile: filesData[f],
            thumbnail: filesData[f].thumbnail,
            partFileName: filesData[f]?.path || filesData[f]?.name,
            ...commanFields
          }
          valueCollection.push(singleValue)
          filesCollection.push(Object.assign(filesData[f], {
            isNotFromMongoDB: true, // Set your metadata here
          }))
        }
        setFiles(filesCollection);
        if (valueCollection && valueCollection.length > 0) {
          setValues(valueCollection)
          setStep(2)
        }
      }
    } catch (err) {
      console.log(err);
      setProcessing(false);
      let message = err && err.message ? err.message : "Error occur during file select"
      toast.error(message)
    }
  }

  const handleCNCPriceUpdate = async (index) => {
    try {
      if (!values.length) return;
      if (repeatOrder) return;
      if (!selectedProcess || selectedProcess != 1) {
        return;
      }
      setGlobeLoader(true);
      setIsInvalidFiles(false);
      if (commanValues.hidePrice || isInvalidFiles || commanValues.price === 0) index = -1;

      let localValueCollection = [...values];

      let isAllMaterialValidForML = true;
      values.forEach((value) => {
        const selectedMaterial = material.find(item => item._id === value.MaterialType)?.name;
        if (!selectedMaterial || !MATERAILS_VALID_FOR_ML.includes(selectedMaterial)) {
          isAllMaterialValidForML = false;
        }
      });

      if (!isAllMaterialValidForML) {
        throw {
          message: "Atleast one part is having invalid material selected for them."
        }
      }

      if (index > 0 && !sameForAllField) {
        let boundingData = bounding?.find(ele => ele.fileName === (localValueCollection[index]?.selectedFile?.name || localValueCollection[index]?.file?.filename || localValueCollection[index].file?.originalname));
        if (!boundingData) {
          return;
        }

        let data = {
          MaterialType: localValueCollection[index].MaterialType,
          MaterialTypeName: material.filter((item) => { return item._id === localValueCollection[index].MaterialType })[0]?.name.toUpperCase(),
          SubMaterial: localValueCollection[index].SubMaterial,
          tolerance: localValueCollection[index].tolerance,
          surfaceTreatment: localValueCollection[index].surfaceTreatment,
          surfaceFinish: localValueCollection[index].surfaceFinish,
          partMarking: localValueCollection[index].partMarking,
          File_Analysis_Data: boundingData,
          Xdim: Math.abs(boundingData?.boundingBox.xmax - boundingData?.boundingBox.xmin),
          Ydim: Math.abs(boundingData?.boundingBox.ymax - boundingData?.boundingBox.ymin),
          Zdim: Math.abs(boundingData?.boundingBox.zmax - boundingData?.boundingBox.zmin),
          selectedFile: localValueCollection[index].selectedFile,
          fileFromServer: { ...localValueCollection[index].file, lineId: localValueCollection[index]._id },
        }

        let percent = 0;
        pricingData?.map((el) => {
          if (localValueCollection[index].Qty >= el.qty) {
            percent = el.percentage;
          }
          return el;
        });

        let fetchedPartCost = 0;

        let predictedPriceResponse = await getPredictedValueForPart(data, token);
        if (predictedPriceResponse.status && predictedPriceResponse?.data?.price > 0) {
          fetchedPartCost = predictedPriceResponse?.data?.price;
          let algorithmPriceFromApi = updateAlgorithmPrice(fetchedPartCost);
          fetchedPartCost = Number(fetchedPartCost).toFixed(2);
          let localItemAtIndex = localValueCollection[index];
          let subMaterialAtIndex = subMaterial.filter(item => item?._id == localItemAtIndex?.SubMaterial);
          let partMarkingAtIndex = partmarking.filter(item => item?._id == localItemAtIndex?.partMarking);
          let surfaceFinishAtIndex = surfaceFinish.filter(item => item?._id == localItemAtIndex?.surfaceFinish);
          let surfaceTreatmentAtIndex = surfaceTreatment.filter(item => item?._id == localItemAtIndex?.surfaceTreatment)
          let toleranceAtIndex = tolerance.filter(item => item?._id == localItemAtIndex?.tolerance);
          let subMaterialFactorAtIndex = subMaterialAtIndex.length > 0 ? Number(subMaterialAtIndex[0].SubMatFactor) : 0;
          let partMarkingFactorAtIndex = partMarkingAtIndex.length > 0 ? Number(partMarkingAtIndex[0].PMFactor) : 0;
          let surfaceFinishFactorAtIndex = surfaceFinishAtIndex.length > 0 ? Number(surfaceFinishAtIndex[0].SfFactor) : 0;
          let surfaceTreatmentFactorAtIndex = surfaceTreatmentAtIndex.length > 0 ? Number(surfaceTreatmentAtIndex[0].StFactor) : 0;
          let toleranceFactorAtIndex = toleranceAtIndex.length > 0 ? Number(toleranceAtIndex[0].TolFactor) : 0;
          fetchedPartCost = (fetchedPartCost * (1 + subMaterialFactorAtIndex + surfaceFinishFactorAtIndex + surfaceTreatmentFactorAtIndex + toleranceFactorAtIndex)) + partMarkingFactorAtIndex;
          localItemAtIndex.algorithmPrice = algorithmPriceFromApi
          localItemAtIndex.algorithmPrice1 = selectedProcess == 1 ? (0.8 * algorithmPriceFromApi).toFixed(2) : 0;
          localItemAtIndex.price = (fetchedPartCost * (100 - percent) / 100).toFixed(2);
          localItemAtIndex.price1 = selectedProcess == 1 ? (0.8 * fetchedPartCost * (100 - percent) / 100).toFixed(2) : 0;
          localValueCollection[index] = localItemAtIndex;
        } else {
          throw {
            message: "Could not fetch the part price"
          }
        }
      } else {
        for (let i = 0; i < localValueCollection.length; i++) {
          let boundingData = bounding?.find(ele => ele.fileName === (localValueCollection[i]?.selectedFile?.name || localValueCollection[i]?.file?.filename || localValueCollection[i].file?.originalname));
          if (!boundingData) {
            throw {
              message: "No bounding data found for part"
            };
          }
          let data = {
            MaterialType: localValueCollection[i].MaterialType,
            MaterialTypeName: material.filter((item) => { return item._id === localValueCollection[i].MaterialType })[0]?.name.toUpperCase(),
            SubMaterial: localValueCollection[i].SubMaterial,
            tolerance: localValueCollection[i].tolerance,
            surfaceTreatment: localValueCollection[i].surfaceTreatment,
            surfaceFinish: localValueCollection[i].surfaceFinish,
            partMarking: localValueCollection[i].partMarking,
            File_Analysis_Data: boundingData,
            Xdim: Math.abs(boundingData?.boundingBox.xmax - boundingData?.boundingBox.xmin),
            Ydim: Math.abs(boundingData?.boundingBox.ymax - boundingData?.boundingBox.ymin),
            Zdim: Math.abs(boundingData?.boundingBox.zmax - boundingData?.boundingBox.zmin),
            selectedFile: localValueCollection[i].selectedFile,
            fileFromServer: { ...localValueCollection[i].file, lineId: localValueCollection[i]._id },
          };

          if (!isAllMaterialValidForML) {
            throw {
              message: "At least one part is having invalid material selected for them."
            };
          }

          let predictedPriceResponse;
          try {
            predictedPriceResponse = await getPredictedValueForPart(data, token);
          } catch (error) {
            throw {
              message: `Error in getting predicted value for part: ${localValueCollection[i]._id}`,
              error: error
            };
          }

          let fetchedPartCost = predictedPriceResponse?.data?.price;
          let algorithmPriceFromApi = 0;
          if (fetchedPartCost > 0) {
            let percent = 0;
            pricingData?.forEach((el) => {
              if (localValueCollection[i].Qty >= el.qty) {
                percent = el.percentage;
              }
            });

            algorithmPriceFromApi = updateAlgorithmPrice(fetchedPartCost);
            fetchedPartCost = Number(fetchedPartCost).toFixed(2);
            let localItemAtIndex = localValueCollection[i];
            let subMaterialAtIndex = subMaterial.filter(item => item?._id == localItemAtIndex?.SubMaterial);
            let partMarkingAtIndex = partmarking.filter(item => item?._id == localItemAtIndex?.partMarking);
            let surfaceFinishAtIndex = surfaceFinish.filter(item => item?._id == localItemAtIndex?.surfaceFinish);
            let surfaceTreatmentAtIndex = surfaceTreatment.filter(item => item?._id == localItemAtIndex?.surfaceTreatment);
            let toleranceAtIndex = tolerance.filter(item => item?._id == localItemAtIndex?.tolerance);

            let subMaterialFactorAtIndex = subMaterialAtIndex.length > 0 ? Number(subMaterialAtIndex[0].SubMatFactor) : 0;
            let partMarkingFactorAtIndex = partMarkingAtIndex.length > 0 ? Number(partMarkingAtIndex[0].PMFactor) : 0;
            let surfaceFinishFactorAtIndex = surfaceFinishAtIndex.length > 0 ? Number(surfaceFinishAtIndex[0].SfFactor) : 0;
            let surfaceTreatmentFactorAtIndex = surfaceTreatmentAtIndex.length > 0 ? Number(surfaceTreatmentAtIndex[0].StFactor) : 0;
            let toleranceFactorAtIndex = toleranceAtIndex.length > 0 ? Number(toleranceAtIndex[0].TolFactor) : 0;

            fetchedPartCost = (fetchedPartCost * (1 + subMaterialFactorAtIndex + surfaceFinishFactorAtIndex + surfaceTreatmentFactorAtIndex + toleranceFactorAtIndex)) + partMarkingFactorAtIndex;

            localItemAtIndex.algorithmPrice = algorithmPriceFromApi;
            localItemAtIndex.algorithmPrice1 = selectedProcess == 1 ? (0.8 * algorithmPriceFromApi).toFixed(2) : 0;
            localItemAtIndex.price = (fetchedPartCost * (100 - percent) / 100).toFixed(2);
            localItemAtIndex.price1 = selectedProcess == 1 ? (0.8 * fetchedPartCost * (100 - percent) / 100).toFixed(2) : 0;

            localValueCollection[i] = localItemAtIndex;
          } else {
            throw {
              message: "Price could not be fetched for one of the parts."
            };
          }
        }
      }

      let totalCost1 = localValueCollection?.reduce((acc, item) => {
        return acc + (item.price * item.Qty);
      }, 0);

      let totalCost2 = localValueCollection?.reduce((acc, item) => {
        return acc + (item.price1 * item.Qty);
      }, 0);

      let totalQuantityOfItems = 0;
      for (let i = 0; i < localValueCollection.length; i++) {
        totalQuantityOfItems += localValueCollection[i].Qty;
      }

      let additionalCost = 0;
      let isMaterialsValidForQR = checkIfAllMaterialsValidForQR();
      if (isMaterialsValidForQR) {
        if (commanValues.isQrTrackingRequested) {
          additionalCost = (5 * totalQuantityOfItems);
          totalCost1 = totalCost1 + (5 * totalQuantityOfItems);
          totalCost2 = totalCost2 + (5 * totalQuantityOfItems);
        }
      } else {
        if (commanValues.isQrTrackingRequested) {
          additionalCost = 5 * totalQuantityOfItems;
        }
      }
      let shippingCharge = 0;
      setValues([...localValueCollection]);
      if (totalCost1 > 0) {
        setCommanValues({
          ...commanValues,
          price: totalCost1 ? totalCost1.toFixed(2) : 0,
          price1: totalCost2 ? totalCost2.toFixed(2) : 0,
          hidePrice: false,
          additionalCost1: additionalCost,
          additionalCost2: additionalCost,
          shippingCharge1: shippingCharge,
          shippingCharge2: shippingCharge,
          leadTime1: 15,
          leadTime2: 20,
          hidePrice: false,
        });
      } else {
        throw {
          message: "Invalid total cost"
        }
      }
      console.log('gloabe loader 2')
      setGlobeLoader(false);
    } catch (err) {
      console.log('gloabe loader 3')
      setGlobeLoader(false);
      console.log("Error in ", err)
      setIsInvalidFiles(true);
      let updatedValues = [...values];
      updatedValues.forEach(value => {
        value.price = 0;
        value.price1 = 0;
        value.price2 = 0;
        value.stManualPrice = 0;
        value.sheetAndInjectionManualPrice = 0;
      })
      setValues(updatedValues);
      setCommanValues((val) => ({
        ...val,
        hidePrice: true,
        price: 0,
        price1: 0,
        price2: 0,
        leadTime1: 0,
        leadTime2: 0,
        isQrTrackingRequested: false
      }))
    }
  }


  const handleThreeDPriceUpdate = async () => {
    try {
      if (!values.length) return;
      if (repeatOrder) return;
      setGlobeLoader(true);
      setCommanValues({ ...commanValues, hidePrice: false });
      setIsInvalidFiles(false);
      const localValueCollection = [...values];
      let index = -1;
      let totalLeadTime = 0;
      const techMaterialMap = {};

      let isOkay = true;

      for (const item of localValueCollection) {
        ++index;
        const selectedTechnologyId = item?.threeDTechnology;
        const selectedMaterialId = item?.threeDMaterial;
        const selectedProcessId = item?.threeDPostProcessing;

        console.log("Selected Tech :", selectedTechnologyId);
        console.log("Selected Material  :", selectedMaterialId);
        console.log("Selected PostProcess :", selectedProcessId);
        let selectedMachine;
        let selectedPostProcess;
        let selectedTechnology;
        let selectedMaterial;
        outerLoop:
        for (const tech of masterThreeDTechnologyData) {
          const { machines, name: techName, _id: techId } = tech;
          for (const machine of machines || []) {
            const { name: machineName } = machine;
            const { materials } = machine;

            for (const material of materials || []) {
              const { name: materialName, minCost = 0, _id: materialId } = material;
              const { postProcess } = material;

              for (const pProcess of postProcess || []) {
                const { name: postProcessName, color, _id: postProcessId } = pProcess;

                if (postProcessId == selectedProcessId && selectedTechnologyId == techId && selectedMaterialId == materialId) {
                  selectedMachine = machine;
                  selectedMaterial = material;
                  selectedPostProcess = pProcess;
                  selectedTechnology = tech;
                  break outerLoop;
                }
              }
            }
          }

          setGlobeLoader(false);
        }


        const isManualReviewRequired = selectedPostProcess?.manualReviewRequired;
        console.log("Manual Review", isManualReviewRequired);
        //checking is manual review
        if (isManualReviewRequired) {
          console.log("manual Review Required");
          isOkay = false;
          item.twoDFileRequired = true;
          continue;
        }
        else {
          item.twoDFileRequired = false;
        }
        //checking bouding box
        const boundingData = bounding?.find(ele => ele.fileName == (item?.selectedFile?.name || item?.file?.filename || item?.file?.originalname));

        if (!boundingData) {
          console.log("bounding data");
          isOkay = false;
          break;
        }
        //calculating volume

        const volumeInCC = Math.ceil(Number(boundingData?.analysis?.volume) / 1000) * Number(item.Qty);
        const singleVolumeInCC = Math.ceil(Number(boundingData?.analysis?.volume) / 1000);

        console.log(volumeInCC, "volume:");
        // const singleVolumeInCC = Math.ceil(Number(boundingData?.analysis?.volume) / 1000);
        if (!volumeInCC) {
          console.log("volume")
          isOkay = false;
          continue;
        }
        //if process custom
        if (selectedPostProcess?.name.toLocaleLowerCase() == 'custom'.toLocaleLowerCase()) {
          console.log("post post post")
          isOkay = false;
          continue;
        }
        console.log("Selected Material :", selectedMaterial)
        console.log("Selected selectedPostProcess :", selectedPostProcess)
        console.log("Selected selectedTechnology :", selectedTechnology)
        console.log("Selected selectedMachine :", selectedMachine)
        if (selectedPostProcess && selectedTechnology && selectedMachine && selectedMaterial) {
          item.threeDMachine = selectedMachine?._id;
          if (selectedTechnology?.technologyType?.toLocaleLowerCase() == 'metal') {
            const material_id = selectedMaterial?._id;
            const response = await getMetalCCDataForMaterial(token, material_id);
            if (!response.success) {
              throw new Error("Cannot Fetch Data");
            };

            const { data: { rowData } } = response;
            let isFindValidRow = false;
            if (Array.isArray(rowData)) {
              for (const roD of rowData) {
                const { minCCValue, maxCCValue, costPerCC, fixedCost } = roD;
                if (volumeInCC >= minCCValue && volumeInCC <= maxCCValue) {
                  const surfaceFinishCost = selectedPostProcess?.costPerCC ? Number(selectedPostProcess?.costPerCC) : 0;
                  const priceOfSurfaceFinish = volumeInCC * surfaceFinishCost;
                  const totalCost = fixedCost + (volumeInCC * costPerCC) + priceOfSurfaceFinish;
                  item.price = (totalCost / item.Qty);
                  item.algorithmPrice = (totalCost / item.Qty);
                  isFindValidRow = true;
                  break;
                }
              }
              isOkay = isFindValidRow;
            }
            else {
              //manual review
              isOkay = false;
              continue;
            }

          }
          else {
            //plastic logic

            const { name: techName } = selectedTechnology;
            const { name: materialName } = selectedMaterial;
            const { name: postProcessName, color } = selectedPostProcess;
            const { name: machineName } = selectedMachine;
            const key = techName + materialName;
            const materialCostPerCC = selectedMaterial?.costPerCC ? Number(selectedMaterial?.costPerCC) : 0;
            console.log("Material Cost :", materialCostPerCC);
            // console.log("Material Per cc", materialCostPerCC);
            // console.log("Material Per cc", materialCostPerCC);
            const priceOfMaterial = volumeInCC * materialCostPerCC;
            const surfaceFinishCost = selectedPostProcess?.costPerCC ? Number(selectedPostProcess?.costPerCC) : 0;
            // console.log("Surface  Per cc", surfaceFinishCost);
            const priceOfSurfaceFinish = volumeInCC * surfaceFinishCost;
            const totalCost = (priceOfMaterial + priceOfSurfaceFinish);
            if (!techMaterialMap[key]) {
              techMaterialMap[key] = { totalCost, count: 1 };
              // ab + cd
            }
            else {
              techMaterialMap[key].totalCost += totalCost;
              techMaterialMap[key].count++;
            }
            item.price = (totalCost / item.Qty);
            item.algorithmPrice = (totalCost / item.Qty);
          }
        }
        else {
          console.log("else")
          isOkay = false;
          // break;
        }
        let leadTime = 0;
        console.log(selectedPostProcess, "oso");
        // const { leadtimeData: { rowData = null } } = selectedPostProcess;
        const rowData = selectedPostProcess?.leadtimeData?.rowData || [];

        if (Array.isArray(rowData)) {
          for (const row of rowData) {
            const { minCCValue, maxCCValue, days } = row;
            if (volumeInCC >= minCCValue && volumeInCC <= maxCCValue) {
              leadTime = days;
              totalLeadTime = Math.max(leadTime, totalLeadTime);
              break;
            }
          }
        }
        else {
          isOkay = false;
        }
      }

      if (!isOkay) {
        setGlobeLoader(false);
        setIsInvalidFiles(true);
        setValues([...localValueCollection]);
        setCommanValues((val) => ({
          ...val,
          hidePrice: true,
          price: 0,
          price1: 0,
          price2: 0,
          leadTime1: 0,
          leadTime2: 0,
          isQrTrackingRequested: false
        }));
        setThreeDError("Manual review is required for the parts you have uploaded.");
        return;
      };
      console.log(localValueCollection);

      for (const item of localValueCollection) {
        const selectedTechnologyId = item?.threeDTechnology;
        const selectedMaterialId = item?.threeDMaterial;
        const selectedProcessId = item?.threeDPostProcessing;

        console.log("Selected Tech :", selectedTechnologyId);
        console.log("Selected Material  :", selectedMaterialId);
        console.log("Selected PostProcess :", selectedProcessId);
        let selectedMachine;
        let selectedPostProcess;
        let selectedTechnology;
        let selectedMaterial;

        outerLoop:
        for (const tech of masterThreeDTechnologyData) {
          const { machines, name: techName, _id: techId } = tech;

          for (const machine of machines || []) {
            const { name: machineName } = machine;
            const { materials } = machine;

            for (const material of materials || []) {
              const { name: materialName, minCost = 0, _id: materialId } = material;
              const { postProcess } = material;

              for (const pProcess of postProcess || []) {
                const { name: postProcessName, color, _id: postProcessId } = pProcess;

                if (postProcessId == selectedProcessId) {
                  selectedMachine = machine;
                  selectedMaterial = material;
                  selectedPostProcess = pProcess;
                  selectedTechnology = tech;
                  break outerLoop;
                }
              }
            }
          }





        }
        console.log("Selected Machine :", selectedMachine);
        if (selectedPostProcess && selectedTechnology && selectedMachine && selectedMaterial) {
          const type = selectedTechnology?.technologyType;
          console.log("Type: ", type);
          if (type?.toLocaleLowerCase() == 'plastic') {
            //plastic logic
            const { name: techName } = selectedTechnology;
            const { name: materialName } = selectedMaterial;
            console.log(materialName);
            const { name: PostProcessName, color } = selectedPostProcess;
            const { name: machineName } = selectedMachine;
            const key = techName + materialName;
            const { totalCost: myValue, count } = techMaterialMap[key];
            const minCost = minCostTechMachineMaterialPostProcess[key];
            console.log("Key :", key);
            console.log("minCost", minCost);
            if (myValue < minCost) {
              const remaningValue = minCost - myValue;
              const costToAdd = (remaningValue / count) / item.Qty;
              item.price += costToAdd;
            }
          }
        }
        else {
          isOkay = false;
          break;

        }
      }

      if (!isOkay) {
        setIsInvalidFiles(true);
        let updatedValues = [...values];
        updatedValues.forEach(value => {
          value.price = 0;
          value.price1 = 0;
          value.price2 = 0;
          value.stManualPrice = 0;
          value.sheetAndInjectionManualPrice = 0;
        })
        setCommanValues((val) => ({
          ...val,
          hidePrice: true,
          price: 0,
          price1: 0,
          price2: 0,
          leadTime1: 0,
          leadTime2: 0,
          isQrTrackingRequested: false
        }))
        setValues(updatedValues);
        setGlobeLoader(false);
        return;


      }


      const totalCost = localValueCollection.reduce((acc, item) => {
        const selectedTechnologyId = item?.threeDTechnology;
        const selectedMaterialId = item?.threeDMaterial;
        const selectedProcessId = item?.threeDPostProcessing;

        console.log("Selected Tech :", selectedTechnologyId);
        console.log("Selected Material  :", selectedMaterialId);
        console.log("Selected PostProcess :", selectedProcessId);
        let selectedMachine;
        let selectedPostProcess;
        let selectedTechnology;
        let selectedMaterial;
        outerLoop:
        for (const tech of masterThreeDTechnologyData) {
          const { machines, name: techName, _id: techId } = tech;
          for (const machine of machines || []) {
            const { name: machineName } = machine;
            const { materials } = machine;

            for (const material of materials || []) {
              const { name: materialName, minCost = 0, _id: materialId } = material;
              const { postProcess } = material;

              for (const pProcess of postProcess || []) {
                const { name: postProcessName, color, _id: postProcessId } = pProcess;

                if (postProcessId == selectedProcessId && selectedTechnologyId == techId && selectedMaterialId == materialId) {
                  selectedMachine = machine;
                  selectedMaterial = material;
                  selectedPostProcess = pProcess;
                  selectedTechnology = tech;
                  break outerLoop;
                }
              }
            }
          }
        }

        if (selectedTechnology?.technologyType?.toLocaleLowerCase() == 'metal') {
          return acc + item.price;
        }
        return acc + item.price * item.Qty;


      }, 0)

      console.log("Full Total Cost Value :", totalCost);
      // console.log(newUpdatedValues);
      setValues([...localValueCollection]);
      if (totalCost > 0) {
        setIsInvalidFiles(false);
        setCommanValues((val) => ({
          ...val,
          hidePrice: false,
          price: totalCost,
          price1: 0,
          leadTime1: totalLeadTime,
          isQrTrackingRequested: false
        }))
      }
      setGlobeLoader(false);

    } catch (error) {
      console.log(error);
      // alert(error);
      setIsInvalidFiles(true);
      let updatedValues = [...values];
      updatedValues.forEach(value => {
        value.price = 0;
        value.price1 = 0;
        value.price2 = 0;
        value.stManualPrice = 0;
        value.sheetAndInjectionManualPrice = 0;
      })
      setCommanValues((val) => ({
        ...val,
        hidePrice: true,
        price: 0,
        price1: 0,
        price2: 0,
        leadTime1: 0,
        leadTime2: 0,
        isQrTrackingRequested: false
      }))
      setValues(updatedValues);
      setGlobeLoader(false);
      console.log('globe loader 5');
      console.log("Error in price fetch", error)
    }


  }


  const handleIsUserLogin = () => {
    const userid = localStorage.getItem("_id");
    const isLogin = localStorage.getItem("isLogin");
    if (!userid || !isLogin) {
      console.log("cam here 534534511")
      navigate("/login");
      return false;
    }
    return true
  };

  const withUserLogin = (fn) => {
    return (...args) => {
      const isLoggedIn = handleIsUserLogin();
      if (isLoggedIn) {
        fn(...args);
      }
    }
  }
  const handleArchiveQuotation = async () => {
    try {
      if (selectedQuote?.isCancelled) {
        toast.error("Quotation is already archived");
        return;
      }

      if (selectedQuote.myorders) {
        toast.error("This quotation cannot be archived as the order has already been created.");
        return;
      }
      const quoteCancelResponse = confirm(
        "Are you sure that you want to archive this version of  quotation?"
      );

      if (quoteCancelResponse) {
        let data = {
          quoteId: selectedQuote._id,
        };
        const response = await handleQuoteCancel(token, data);
        if (response.status) {
          setSelectedQuote({
            ...selectedQuote,
            isCancelled: true
          });
          toast.success("Quotation achieved successfully.");
        }
      }
    } catch (err) {
      console.log('error', err);
      toast.error("Something went wrong.Please try again later")
    }
  }
  const handleMoveToProgress = async () => {
    try {
      let data = {
        quoteId: selectedQuote._id,
      };
      const response = await moveQuoteToProgressStatus(token, data);
      if (response.status) {
        setSelectedQuote({
          ...selectedQuote,
          isCancelled: false
        })
        toast.success(response.message);
      } else {
        toast.error("Something went wrong.Please try again later!");
      }
    } catch (e) {
      toast.error(e.message || "Something went wrong.Please try again later!");
    }
  }

  const handleDraftQuoteFunctionality = withUserLogin(async ({ functionalityName }) => {
    console.log("Click :", functionalityName)
    let newId = _id;
    if (!_id) {
      const response = await saveApiCall(null, "new");
      navigate(`/create-instant-quotation/${response._id}?functionalityName=${functionalityName}`);
    }
    else {
      if (functionalityName == 'chat') {
        setIsForwardToPurchaserModalOpen(false);
        setTeamModalShow(false);
        setIsChatOpen(true);
      }
      if (functionalityName == 'ftp') {
        setIsForwardToPurchaserModalOpen(true);
        setTeamModalShow(false);
        setIsChatOpen(false);
      }
      if (functionalityName == 'teams') {
        setTeamModalShow(true);
        setIsForwardToPurchaserModalOpen(false);
        setIsChatOpen(false);
      }
      if (functionalityName == 'dq') {
        handleDownloadQuotation();
      }
      if (functionalityName == 'share') {
        setIsShareModalOpen(true)
      }
      if (functionalityName == 'move-to-progress') {
        handleMoveToProgress();
      }
      if (functionalityName == 'archive') {
        handleArchiveQuotation();
      }
      if (functionalityName == 'stc') {
        setIsTargetCostModalOpen(true);
      }
    }


  });





  // const handleThreeDPriceUpdate = async () => {
  //   if (!values.length) return;
  //   if (repeatOrder) return;
  //   c
  //   setCommanValues({ ...commanValues, hidePrice: false });
  //   setIsInvalidFiles(false);
  //   try {
  //     let localValueCollection = [...values];
  //     for (let i = 0; i < localValueCollection.length; i++) {
  //       let boundingData = bounding?.find(ele => ele.fileName === (localValueCollection[i]?.selectedFile?.name || localValueCollection[i]?.file?.filename || localValueCollection[i].file?.originalname))
  //       if (!boundingData) {
  //         throw {
  //           message: "No bounding data found for part"
  //         };
  //       }
  //       let data = {
  //         MaterialType: localValueCollection[i].MaterialType,
  //         MaterialTypeName: material.filter((item) => { return item._id === localValueCollection[i].MaterialType })[0]?.name.toUpperCase(),
  //         SubMaterial: localValueCollection[i].SubMaterial,
  //         tolerance: localValueCollection[i].tolerance,
  //         surfaceTreatment: localValueCollection[i].surfaceTreatment,
  //         surfaceFinish: localValueCollection[i].surfaceFinish,
  //         partMarking: localValueCollection[i].partMarking,
  //         File_Analysis_Data: boundingData,
  //         Xdim: Math.abs(boundingData?.boundingBox.xmax - boundingData?.boundingBox.xmin),
  //         Ydim: Math.abs(boundingData?.boundingBox.ymax - boundingData?.boundingBox.ymin),
  //         Zdim: Math.abs(boundingData?.boundingBox.zmax - boundingData?.boundingBox.zmin),
  //         selectedFile: localValueCollection[i].selectedFile,
  //         fileFromServer: { ...localValueCollection[i].file, lineId: localValueCollection[i]._id },
  //         threeDTechnology:localValueCollection[i].threeDTechnology,
  //         threeDPostProcessing:localValueCollection[i].threeDPostProcessing,
  //         threeDMaterial:localValueCollection[i].threeDMaterial,
  //         threeDMachine:localValueCollection[i].threeDMachine
  //       }
  //       console.log(data);

  //       let percent = 0;
  //       // pricingData?.map((el) => {
  //       //   if (localValueCollection[i].Qty >= el.qty) {
  //       //     percent = el.percentage;
  //       //   }
  //       //   return el;
  //       // });

  //       let localItemAtIndex = localValueCollection[i];
  //       let materialId = localItemAtIndex.MaterialType;
  //       let materialName = material.filter((item) => { return item._id === localItemAtIndex.MaterialType })[0]?.name.toUpperCase();
  //       let subMaterialName = subMaterial.filter((item) => { return item._id === localItemAtIndex.SubMaterial && item.masterMaterialId == materialId })[0]?.name.toUpperCase();
  //       let itemCostResponse = await calculate3DData(data, localItemAtIndex, i, percent);
  //       if (itemCostResponse?.finalCost > 0) {
  //         localItemAtIndex.algorithmPrice = Number(itemCostResponse.algorithmPrice).toFixed(2);
  //         localItemAtIndex.price = Number(itemCostResponse.price).toFixed(2);
  //         let localMatSubMap = matSubMatMap;
  //         localMatSubMap.set((localItemAtIndex?.selectedFile?.name || localItemAtIndex?.file?.originalname) + i,
  //           {
  //             matSubMatKey: `${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`,
  //             itemPrice: (itemCostResponse.price * localItemAtIndex.Qty)
  //           }
  //         );
  //         setMatSubMatMap(localMatSubMap);
  //       } else {
  //         throw {
  //           message: "Price could not be fetched for one of the parts."
  //         };
  //       }
  //       localValueCollection[i] = localItemAtIndex;
  //     }
  //     if (selectedProcess == 2) {
  //       let localMatSubMatSumMap = new Map();
  //       let localMatSubMap = matSubMatMap;
  //       for (let [key, value] of localMatSubMap) {
  //         let localSum = localMatSubMatSumMap.get(value.matSubMatKey);
  //         localSum = value.itemPrice + (localSum ? localSum : 0);
  //         localMatSubMatSumMap.set(value.matSubMatKey, localSum);
  //       }

  //       let plasticLessThan60 = [];
  //       let metalLessThan100 = [];
  //       for (let [key, value] of localMatSubMatSumMap) {
  //         if (key.substring(0, 6) == 'ALUMIN' || key.substring(0, 6) == 'STAINL') {
  //           if (value < 100) {
  //             metalLessThan100.push(key);
  //           }
  //         } else {
  //           if (value < 60) {
  //             plasticLessThan60.push(key);
  //           }
  //         }

  //       }


  //       // Write a code to find the number of item for each material type  and the total amount of all the item of that material type
  //       let plasticMaterialTotalItemMap = new Map();
  //       let metalMaterialTotalItemMap = new Map();

  //       if (plasticLessThan60.length) {
  //         for (let i = 0; i < values.length; i++) {
  //           let localItemAtIndex = localValueCollection[i];
  //           let materialId = localItemAtIndex.MaterialType;
  //           let materialName = material.filter((item) => { return item._id === localItemAtIndex.MaterialType })[0]?.name.toUpperCase();
  //           let subMaterialName = subMaterial.filter((item) => { return item._id === localItemAtIndex.SubMaterial && item.masterMaterialId == materialId })[0]?.name.toUpperCase();

  //           if (plasticLessThan60.includes(`${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`)) {
  //             plasticMaterialTotalItemMap.set(`${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`,
  //               {
  //                 countOfItem: plasticMaterialTotalItemMap.get(`${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`)?.countOfItem ? plasticMaterialTotalItemMap.get(`${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`)?.countOfItem + 1 : 1,
  //                 totalCostOfMaterial: plasticMaterialTotalItemMap.get(`${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`)?.totalCostOfMaterial ? plasticMaterialTotalItemMap.get(`${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`)?.totalCostOfMaterial + localItemAtIndex?.price * localItemAtIndex?.Qty : localItemAtIndex?.price * localItemAtIndex?.Qty,
  //               }
  //             )
  //           }
  //         }
  //       }
  //       if (metalLessThan100.length) {
  //         for (let i = 0; i < values.length; i++) {
  //           let localItemAtIndex = localValueCollection[i];
  //           let materialId = localItemAtIndex.MaterialType;
  //           let materialName = material.filter((item) => { return item._id === localItemAtIndex.MaterialType })[0]?.name.toUpperCase();
  //           let subMaterialName = subMaterial.filter((item) => { return item._id === localItemAtIndex.SubMaterial && item.masterMaterialId == materialId })[0]?.name.toUpperCase();

  //           if (metalLessThan100.includes(`${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`)) {
  //             metalMaterialTotalItemMap.set(`${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`,
  //               {
  //                 countOfItem: metalMaterialTotalItemMap.get(`${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`)?.countOfItem ? metalMaterialTotalItemMap.get(`${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`)?.countOfItem + 1 : 1,
  //                 totalCostOfMaterial: metalMaterialTotalItemMap.get(`${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`)?.totalCostOfMaterial ? metalMaterialTotalItemMap.get(`${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`)?.totalCostOfMaterial + localItemAtIndex?.price * localItemAtIndex?.Qty : localItemAtIndex?.price * localItemAtIndex?.Qty,
  //               }
  //             )
  //           }
  //         }
  //       }

  //       // find avarage price need to increase for every materail type.
  //       let localAveragePriceIncreaseMap = new Map();
  //       for (let [key, value] of plasticMaterialTotalItemMap) {
  //         localAveragePriceIncreaseMap.set(key, (60 - value.totalCostOfMaterial) / value.countOfItem)
  //       }
  //       for (let [key, value] of metalMaterialTotalItemMap) {
  //         localAveragePriceIncreaseMap.set(key, (100 - value.totalCostOfMaterial) / value.countOfItem)
  //       }


  //       // increase price of each item with that value of that materail type.
  //       for (let i = 0; i < localValueCollection.length; i++) {
  //         let localItemAtIndex = localValueCollection[i];
  //         let materialId = localItemAtIndex.MaterialType;
  //         let materialName = material.filter((item) => { return item._id === localItemAtIndex.MaterialType })[0]?.name.toUpperCase();
  //         let subMaterialName = subMaterial.filter((item) => { return item._id === localItemAtIndex.SubMaterial && item.masterMaterialId == materialId })[0]?.name.toUpperCase();
  //         if (localAveragePriceIncreaseMap.get(`${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`) > 0) {
  //           localItemAtIndex.price = Number(localItemAtIndex.price) + localAveragePriceIncreaseMap.get(`${(materialName ? materialName : '')}${(subMaterialName ? subMaterialName : '')}`);
  //           localItemAtIndex.algorithmPrice = localItemAtIndex.algorithmPrice;
  //         }
  //         localValueCollection[i] = localItemAtIndex;
  //       }
  //     }
  //     let totalCost1 = localValueCollection?.reduce((acc, item) => {
  //       return acc + (item.price * item.Qty);
  //     }, 0);
  //     let totalCost2 = localValueCollection?.reduce((acc, item) => {
  //       return acc + (item.price1 * item.Qty);
  //     }, 0);
  //     let totalQuantityOfItems = 0;
  //     for (let i = 0; i < localValueCollection.length; i++) {
  //       totalQuantityOfItems += localValueCollection[i].Qty;
  //     }
  //     let additionalCost = 0;
  //     let isMaterialsValidForQR = checkIfAllMaterialsValidForQR();
  //     if (isMaterialsValidForQR) {
  //       if (commanValues.isQrTrackingRequested) {
  //         additionalCost = (5 * totalQuantityOfItems);
  //         totalCost1 = totalCost1 + (5 * totalQuantityOfItems);
  //         if (selectedProcess == 1) {
  //           totalCost2 = totalCost2 + (5 * totalQuantityOfItems);
  //         }

  //       }
  //     } else {
  //       if (commanValues.isQrTrackingRequested) {
  //         additionalCost = 5 * totalQuantityOfItems;
  //       }
  //     }
  //     let shippingCharge = 0;
  //     if (totalCost1 > 0) {
  //       setCommanValues({
  //         ...commanValues,
  //         price: totalCost1 ? totalCost1.toFixed(2) : 0,
  //         price1: selectedProcess == 1 ? (totalCost2 ? totalCost2.toFixed(2) : 0) : 0,
  //         hidePrice: false,
  //         additionalCost1: additionalCost,
  //         additionalCost2: additionalCost,
  //         shippingCharge1: shippingCharge,
  //         shippingCharge2: shippingCharge,
  //         leadTime1: selectedProcess == 2 ? 18 : 10,
  //       });
  //     } else {
  //       setCommanValues({
  //         ...commanValues,
  //         price: 0,
  //         price1: 0,
  //         hidePrice: true,
  //         additionalCost1: 0,
  //         additionalCost2: 0,
  //         shippingCharge1: 0,
  //         shippingCharge2: 0,
  //         leadTime1: 0,
  //       });
  //     }
  //     setValues([...localValueCollection]);
  //     console.log('gloabe loader 6')
  //     setGlobeLoader(false);
  //   } catch (err) {
  //     setIsInvalidFiles(true);
  //     let updatedValues = [...values];
  //     updatedValues.forEach(value => {
  //       value.price = 0;
  //       value.price1 = 0;
  //       value.price2 = 0;
  //       value.stManualPrice = 0;
  //       value.sheetAndInjectionManualPrice = 0;
  //     })
  //     setCommanValues((val) => ({
  //       ...val,
  //       hidePrice: true,
  //       price: 0,
  //       price1: 0,
  //       price2: 0,
  //       leadTime1: 0,
  //       leadTime2: 0,
  //       isQrTrackingRequested: false
  //     }))
  //     setValues(updatedValues);
  //     console.log('globe loader 5');
  //     setGlobeLoader(false);
  //     console.log("Error in price fetch", err)
  //   }
  // }


  // const calculate3DData = async (data, localItemAtIndex, index, percent) => {
  //   try {
  //     if (!selectedProcess || selectedProcess !== 2) return -1;
  //     const selectedMaterial = material.filter(item => item._id === data.MaterialType)[0];
  //     const fileAnalysisData = data.File_Analysis_Data;
  //     const itemBoundingBox = fileAnalysisData?.boundingBox;
  //     const XDimensionValue = Math.abs(itemBoundingBox?.xmax - itemBoundingBox?.xmin);
  //     const YDimensionValue = Math.abs(itemBoundingBox?.ymax - itemBoundingBox?.ymin);
  //     const ZDimensionValue = Math.abs(itemBoundingBox?.zmax - itemBoundingBox?.zmin);

  //     let greaterValue = 0;
  //     if (XDimensionValue > 250) {
  //       greaterValue = greaterValue + 1;
  //     }
  //     if (YDimensionValue > 250) {
  //       greaterValue = greaterValue + 1;
  //     }
  //     if (ZDimensionValue > 250) {
  //       greaterValue = greaterValue + 1;
  //     }
  //     if (greaterValue >= 2) {
  //       setThreeDError("Part size larger than standard sizes will need manual review. Please click ‘Request quote’ option to proceed.")
  //       let response = {
  //         finalCost: 0,
  //         algorithmPrice: 0,
  //         algorithmPrice1: 0,
  //         price: 0,
  //         price1: 0,
  //       }
  //       return response;
  //     }
  //     if (XDimensionValue > 300 || YDimensionValue > 300 || ZDimensionValue > 300) {
  //       setThreeDError("Part size larger than standard sizes will need manual review. Please click ‘Request quote’ option to proceed.")
  //       let response = {
  //         finalCost: 0,
  //         algorithmPrice: 0,
  //         algorithmPrice1: 0,
  //         price: 0,
  //         price1: 0,
  //       }
  //       return response;
  //     }
  //     let itemCostResponse;
  //     //-----------------Now check selected material-----------------------//
  //     if (selectedMaterial && (selectedMaterial?.name == "Stainless Steel" || selectedMaterial?.name == "Aluminium")) {
  //       itemCostResponse = await calculateSteelAndAluminiumCalculations(XDimensionValue, YDimensionValue, ZDimensionValue, selectedMaterial, fileAnalysisData, localItemAtIndex, index, percent)
  //     } else if (selectedMaterial) {
  //       itemCostResponse = await calcualteOtherMaterialCalculations(XDimensionValue, YDimensionValue, ZDimensionValue, selectedMaterial, fileAnalysisData, localItemAtIndex, index, percent)
  //     }
  //     return itemCostResponse;
  //   } catch (err) {
  //     let response = {
  //       finalCost: 0,
  //       algorithmPrice: 0,
  //       algorithmPrice1: 0,
  //       price: 0,
  //       price1: 0,
  //     }
  //     return response;
  //   }
  // }


  // const calculateSteelAndAluminiumCalculations = async (X, Y, Z, selectedMaterial, fileAnalysisData, localItemAtIndex, index, percent) => {
  //   try {
  //     let analysis = fileAnalysisData?.analysis;
  //     let zAxisOfPrinting = 0;
  //     let partVolume = analysis?.volume || 0;
  //     let cylindricalArea = analysis?.faces?.CYLINDER?.area;
  //     let planarArea = analysis?.faces?.PLANE?.area;
  //     if ((X < 250 && Y < 250 && Z < 250) && (cylindricalArea && planarArea && cylindricalArea > planarArea)) {
  //       zAxisOfPrinting = Math.max(X, Y, Z);
  //     } else if ((X < 250 && Y < 250 && Z < 250) && (cylindricalArea && planarArea && cylindricalArea < planarArea)) {
  //       zAxisOfPrinting = Math.min(X, Y, Z);
  //     }
  //     if (((250 < X && X < 300) && Y < 250 && Z < 250) ||
  //       ((250 < Y && Y < 300) && X < 250 && Z < 250) ||
  //       ((250 < Z && Z < 300) && Y < 250 && X < 250)) {
  //       zAxisOfPrinting = Z;
  //     }

  //     const factorForExcessVolume = selectedMaterial.threeDExcessVolume || 0;
  //     const densityOfMaterial = selectedMaterial.threeD_Material_Density || 0;
  //     const costOfMaterial = selectedMaterial.threeDMaterialCost || 0;
  //     const rateOfPrinting = selectedMaterial.threeDPrintingRate || 0;
  //     const rateOfRecoating = selectedMaterial.threeDRecoatingRate || 0;
  //     const printingCost = selectedMaterial.threeDPrintingHourly || 0;
  //     const algorithmMaterialCost = (partVolume * factorForExcessVolume * densityOfMaterial * costOfMaterial) / 1000000;
  //     const timeTakenForPrintingInHours = ((partVolume * factorForExcessVolume * densityOfMaterial) / rateOfPrinting) / 1000000;
  //     const timeTakenOrRecoatingInHours = (rateOfRecoating * zAxisOfPrinting) / 600;
  //     const algorithmOverheadsCost = (timeTakenForPrintingInHours + timeTakenOrRecoatingInHours) * printingCost

  //     let algorithmPrice = algorithmMaterialCost + algorithmOverheadsCost;

  //     if (algorithmPrice < 50) {
  //       algorithmPrice = 50;
  //     }
  //     let finalCost = algorithmPrice;
  //     if (localItemAtIndex.partMarking && localItemAtIndex.partMarking !== "") {
  //       const filterPartMakingData = partmarking.find((item) => item._id == localItemAtIndex.partMarking);
  //       const partMakingFactor = filterPartMakingData?.PMFactor || 0;
  //       finalCost = Number(algorithmPrice) + Number(partMakingFactor) + (Number(localItemAtIndex.noOfThread) * 5);
  //     }


  //     let response = {
  //       finalCost: finalCost,
  //       algorithmPrice: finalCost * 1.6,
  //       algorithmPrice1: selectedProcess == 1 ? (finalCost * 1.5) : 0,
  //       price: (finalCost * 1.6 * (100 - percent) / 100).toFixed(2),
  //       price1: selectedProcess == 1 ? ((finalCost * 1.5 * (100 - percent) / 100).toFixed(2)) : 0,
  //     }
  //     return response;
  //   } catch (err) {
  //     let response = {
  //       finalCost: 0,
  //       algorithmPrice: 0,
  //       algorithmPrice1: 0,
  //       price: 0,
  //       price1: 0,
  //     }
  //     return response;
  //   }
  // }

  // const calcualteOtherMaterialCalculations = async (X, Y, Z, selectedMaterial, fileAnalysisData, localItemAtIndex, index, percent) => {
  //   try {
  //     let analysis = fileAnalysisData?.analysis;
  //     let partVolume = analysis?.volume || 0;

  //     if (X > 200 || Y > 200 || Z > 200) {
  //       setThreeDError("Part size larger than standard sizes will need manual review. Please click ‘Request quote’ option to proceed.")
  //       let response = {
  //         finalCost: 0,
  //         algorithmPrice: 0,
  //         algorithmPrice1: 0,
  //         price: 0,
  //         price1: 0,
  //       }
  //       return response;
  //     }

  //     const factorForExcessVolume = selectedMaterial.threeDExcessVolume || 0;
  //     const costOfMaterial = selectedMaterial.threeDMaterialCost || 0;
  //     const algorithmMaterialCost = (partVolume * factorForExcessVolume * costOfMaterial) / 1000;
  //     const algorithmOverheadsCost = 0;
  //     let algorithmPrice = algorithmMaterialCost + algorithmOverheadsCost;


  //     if (algorithmPrice < 15) algorithmPrice = 15;

  //     let finalCost = algorithmPrice;
  //     if (localItemAtIndex.partMarking && localItemAtIndex.partMarking !== "") {
  //       const filterPartMakingData = partmarking.find((item) => item._id == localItemAtIndex.partMarking);
  //       const partMakingFactor = filterPartMakingData?.PMFactor || 0;
  //       finalCost = Number(algorithmPrice) + Number(partMakingFactor) + (Number(localItemAtIndex.noOfThread) * 5)
  //     }

  //     let response = {
  //       finalCost: finalCost,
  //       algorithmPrice: finalCost * 1.25,
  //       algorithmPrice1: selectedProcess == 1 ? (finalCost * 1.1) : 0,
  //       price: (finalCost * 1.25 * (100 - percent) / 100).toFixed(2),
  //       price1: selectedProcess == 1 ? ((finalCost * 1.1 * (100 - percent) / 100).toFixed(2)) : 0,
  //     }
  //     return response;
  //   } catch (err) {
  //     let response = {
  //       finalCost: 0,
  //       algorithmPrice: 0,
  //       algorithmPrice1: 0,
  //       price: 0,
  //       price1: 0,
  //     }
  //     return response;
  //   }
  // }



  const checkIfAllMaterialsValidForQR = () => {
    if (selectedProcess <= 2) {
      let validMaterialName = ['Aluminium', 'Brass', 'Copper', 'Mild Steel', 'Magnesium', 'Stainless Steel', 'Tool Steel', 'Cast Iron'];
      let isValidForQR = true;
      values.forEach(item => {
        let materialForTheValue = material.filter(materialItem => materialItem._id == item.MaterialType);
        if (materialForTheValue) {
          if (!validMaterialName.includes(materialForTheValue[0]?.name)) {
            isValidForQR = false;
          }
        }
      });

      if (!isValidForQR) {
        setCommanValues({ ...commanValues, isQrTrackingRequested: false });
      }
      setIsValidValuesForQR(isValidForQR);
      return isValidForQR;
    } else {
      return true;
    }
  }

  const updateValueOnChange = (index, key, value, name) => {
    console.log(index, key, value, name)
    return new Promise(async (resolve, reject) => {
      try {
        if (repeatOrder) {
          // let userConfirmation = confirm("Are you sure you want to change the material selected for the previous quotation? This will create a new version of quotation and will need admin review before you can place an order for this quotation.");
          // if (userConfirmation) {

          const { selectedProcess } = commanValues;
          if (selectedProcess == 1) {
            let userConfirmation = confirm("Are you sure you want to change the material selected for the previous quotation? This will create a new version of quotation and will need admin review before you can place an order for this quotation.");
            if (userConfirmation) {
              navigate(`/create-instant-quotation/${_id}?isRepeatOrderWithUpdate=true`)
              return;
            }
          }
          navigate(`/create-instant-quotation/${_id}?isRepeatOrderWithUpdate=true`)
          return;

        }
        let initialMaterialId = '';
        if (key == 'MaterialType') {
          initialMaterialId = values[index].MaterialType;
        }
        const updatedValues = [...values];
        if (sameForAllField) {
          if (key == 'Notes' || key == 'finalCost' || key == "description" || key == "noOfThread" || key == "Qty") {
            const singleErrors = updatedValues[index].errors || {}
            singleErrors[key] = false
            updatedValues[index][key] = value
            if (!value) {
              singleErrors[key] = true
            }
            updatedValues[index].errors = { ...singleErrors }
          }
          else {
            for (let v = 0; v < updatedValues.length; v++) {
              const singleErrors = updatedValues[v].errors || {}
              singleErrors[key] = false
              updatedValues[v][key] = value
              if (!value) {
                if (name === 'stp' || name === 'step' || name === 'iges' || name === 'obj' || name === 'STP' || name === 'STEP' || name === 'IGES' || name === 'OBJ') {
                  if (key == "SubMaterial") {
                    singleErrors[key] = false;
                  } else {
                    singleErrors[key] = true
                  }
                }
              }
              updatedValues[v].errors = { ...singleErrors }
            }
          }
        } else {
          const singleErrors = updatedValues[index]?.errors || {}
          singleErrors[key] = false
          updatedValues[index][key] = value
          if (!value) {
            if (name === 'stp' || name === 'step' || name === 'iges' || name === 'obj' || name === 'STP' || name === 'STEP' || name === 'IGES' || name === 'OBJ' || name === "STL" || name === "stl") {
              if (key == "SubMaterial") {
                singleErrors[key] = false;
              } else {
                singleErrors[key] = true
              }
            }
          }
          updatedValues[index].errors = { ...singleErrors }
        }

        if (key === 'Qty') {
          if (selectedProcess === 1) {
            let updatedBulkPercentageForItem = Number(name);
            if (updatedBulkPercentageForItem > 0) {
              updatedValues[index].price = updatedValues[index].algorithmPrice * (100 - updatedBulkPercentageForItem) / 100;
              updatedValues[index].price1 = 0.8 * updatedValues[index].algorithmPrice * (100 - updatedBulkPercentageForItem) / 100;
            } else {
              updatedValues[index].price = updatedValues[index].algorithmPrice;
              updatedValues[index].price1 = 0.8 * updatedValues[index].algorithmPrice;
            }

            let totalCost1 = updatedValues?.reduce((acc, item) => {
              return acc + (item.price * item.Qty);
            }, 0)

            let totalCost2 = updatedValues?.reduce((acc, item) => {
              return acc + (item.price1 * item.Qty);
            }, 0);

            let localValueCollection = [...values];

            let totalQuantityOfItems = 0;
            for (let i = 0; i < localValueCollection.length; i++) {
              totalQuantityOfItems += localValueCollection[i].Qty;
            }

            let additionalCost = 0;
            let isMaterialsValidForQR = checkIfAllMaterialsValidForQR();
            if (isMaterialsValidForQR) {
              if (commanValues.isQrTrackingRequested) {
                additionalCost = 5 * totalQuantityOfItems;
                totalCost1 = totalCost1 + (5 * totalQuantityOfItems);
                totalCost2 = totalCost2 + (5 * totalQuantityOfItems);
              }
            } else {
              if (commanValues.isQrTrackingRequested) {
                additionalCost = 5 * totalQuantityOfItems;
              }
            }


            let shippingCharge = 0;
            setCommanValues({
              ...commanValues,
              price: totalCost1.toFixed(2),
              price1: selectedProcess == 1 ? (totalCost2 ? totalCost2.toFixed(2) : 0) : 0,
              additionalCost1: additionalCost,
              additionalCost2: additionalCost,
              shippingCharge1: shippingCharge,
              shippingCharge2: shippingCharge,
            })
          }
          if (selectedProcess === 2) {
            handleThreeDPriceUpdate()
            // let updatedBulkPercentageForItem = Number(name);
            // if (updatedBulkPercentageForItem > 0) {
            //   updatedValues[index].price = updatedValues[index].algorithmPrice * (100 - updatedBulkPercentageForItem) / 100;
            // } else {
            //   updatedValues[index].price = updatedValues[index].algorithmPrice;
            // }

            // let totalCost1 = updatedValues?.reduce((acc, item) => {
            //   return acc + (item.price * item.Qty);
            // }, 0)

            // let totalCost2 = updatedValues?.reduce((acc, item) => {
            //   return acc + (item.price1 * item.Qty);
            // }, 0);


            // let localValueCollection = [...values];
            // let totalQuantityOfItems = 0;
            // for (let i = 0; i < localValueCollection.length; i++) {
            //   totalQuantityOfItems += localValueCollection[i].Qty;
            // }


            // let additionalCost = 0;
            // let isMaterialsValidForQR = checkIfAllMaterialsValidForQR();
            // if (isMaterialsValidForQR) {
            //   if (commanValues.isQrTrackingRequested) {
            //     additionalCost = 5 * totalQuantityOfItems;
            //     totalCost1 = totalCost1 + (5 * totalQuantityOfItems);
            //     totalCost2 = totalCost2 + (5 * totalQuantityOfItems);
            //   }
            // } else {
            //   if (commanValues.isQrTrackingRequested) {
            //     additionalCost = 5 * totalQuantityOfItems;
            //   }
            // }

            // let shippingCharge = 0;
            // setCommanValues({
            //   ...commanValues,
            //   price: totalCost1.toFixed(2),
            //   price1: selectedProcess == 1 ? (totalCost2 ? totalCost2.toFixed(2) : 0) : 0,
            //   additionalCost1: additionalCost,
            //   additionalCost2: additionalCost,
            //   shippingCharge1: shippingCharge,
            //   shippingCharge2: shippingCharge,
            //   leadTime1: selectedProcess == 2 ? 18 : 10,
            // })
          }
        }
        if (key == 'threeDTechnology') {
          updatedValues[index][key] = value;
        }
        if (key == 'threeDMachine') {
          updatedValues[index][key] = value;
        }
        if (key == 'threeDMaterial') {
          updatedValues[index][key] = value;
        }
        if (key == 'threeDPostProcessing') {
          // updatedValuesm 4
          updatedValues[index][key] = value;
        }
        // console.log("Running");
        setValues(updatedValues);

        let fieldForUpdatePriceCnc = ['MaterialType', 'partMarking', 'noOfThread', 'sameForAllField', 'SubMaterial', 'Qty', 'surfaceFinish', 'surfaceTreatment', 'tolerance'];
        let fieldForUpdatePriceThreeD = ['threeDTechnology', 'threeDMachine', 'threeDMaterial', 'threeDPostProcessing'];
        if (fieldForUpdatePriceCnc.includes(key) || fieldForUpdatePriceThreeD.includes(key)) {
          let isAllFilesValid = true;
          for (let i = 0; i < updatedValues.length; i++) {
            let extension = getFileExtension(updatedValues[i]?.file?.originalname || updatedValues[i]?.selectedFile?.name);
            if (!(extension == 'stp' || extension == 'step' || extension == 'iges' || extension == 'stl' ||
              extension == 'STP' || extension == 'STEP' || extension == 'IGES' || extension == 'STL')) {
              isAllFilesValid = false;
            }
          }

          if (isAllFilesValid) {
            if (selectedProcess == 1 && key == "MaterialType" && fieldForUpdatePriceCnc.includes(key)) {
              if (value && (initialMaterialId != value)) {
                handleCNCPriceUpdate(index);
              }
            } else if (selectedProcess == 1 && (key == "partMarking" || key == "SubMaterial" || key == "surfaceFinish" || key == "surfaceTreatment" || key == 'tolerance') && fieldForUpdatePriceCnc.includes(key)) {
              handleCNCValueUpdateOnValuesChange(index, updatedValues);
            }
            else if (selectedProcess == 2 && fieldForUpdatePriceThreeD.includes(key)) {
              handleThreeDPriceUpdate();
            }
          } else {
            setIsInvalidFiles(true);
            let updatedValues = [...values];
            updatedValues.forEach(value => {
              value.price = 0;
              value.price1 = 0;
              value.price2 = 0;
              value.stManualPrice = 0;
              value.sheetAndInjectionManualPrice = 0;
            })
            setCommanValues((val) => ({
              ...val,
              hidePrice: true,
              price: 0,
              price1: 0,
              price2: 0,
              leadTime1: 0,
              leadTime2: 0,
              isQrTrackingRequested: false
            }))
            setValues(updatedValues);
            setThreeDError("Manual review is required for the parts you have uploaded.")
          }
        }
        return resolve(updatedValues)
      } catch (err) {
        return reject(err)
      }
    });
  }

  const handleCNCValueUpdateOnValuesChange = (index, newValuesCollection) => {
    let localItemAtIndex = newValuesCollection[index];
    let itemCost1 = updateAlgorithmPrice(localItemAtIndex.algorithmPrice);
    let algorithmPriceFromApi = updateAlgorithmPrice(itemCost1)
    let percent = 0;
    pricingData?.map((el) => {
      if (newValuesCollection[index].Qty >= el.qty) {
        percent = el.percentage;
      }
      return el;
    });
    let isCncPriceZero = false;
    let isError = { status: false, errMessage: '' };
    if (!localItemAtIndex.price) {
      isCncPriceZero = true;
      isError.status = true;
      isError.errMessage = "Error fetching price";
    }
    let subMaterialAtIndex = subMaterial.filter(item => item?._id == localItemAtIndex?.SubMaterial);
    let partMarkingAtIndex = partmarking.filter(item => item?._id == localItemAtIndex?.partMarking);
    let surfaceFinishAtIndex = surfaceFinish.filter(item => item?._id == localItemAtIndex?.surfaceFinish);
    let surfaceTreatmentAtIndex = surfaceTreatment.filter(item => item?._id == localItemAtIndex?.surfaceTreatment)
    let toleranceAtIndex = tolerance.filter(item => item?._id == localItemAtIndex?.tolerance);
    let subMaterialFactorAtIndex = subMaterialAtIndex.length > 0 ? Number(subMaterialAtIndex[0].SubMatFactor) : 0;
    let partMarkingFactorAtIndex = partMarkingAtIndex.length > 0 ? Number(partMarkingAtIndex[0].PMFactor) : 0;
    let surfaceFinishFactorAtIndex = surfaceFinishAtIndex.length > 0 ? Number(surfaceFinishAtIndex[0].SfFactor) : 0;
    let surfaceTreatmentFactorAtIndex = surfaceTreatmentAtIndex.length > 0 ? Number(surfaceTreatmentAtIndex[0].StFactor) : 0;
    let toleranceFactorAtIndex = toleranceAtIndex.length > 0 ? Number(toleranceAtIndex[0].TolFactor) : 0;
    itemCost1 = (itemCost1 * (1 + subMaterialFactorAtIndex + surfaceFinishFactorAtIndex + surfaceTreatmentFactorAtIndex + toleranceFactorAtIndex)) + partMarkingFactorAtIndex;
    localItemAtIndex.algorithmPrice = algorithmPriceFromApi
    localItemAtIndex.algorithmPrice1 = selectedProcess == 1 ? (0.8 * algorithmPriceFromApi).toFixed(2) : 0;
    localItemAtIndex.price = (itemCost1 * (100 - percent) / 100).toFixed(2);
    localItemAtIndex.price1 = selectedProcess == 1 ? (0.8 * itemCost1 * (100 - percent) / 100).toFixed(2) : 0;
    newValuesCollection[index] = localItemAtIndex;
    let totalCost1 = newValuesCollection?.reduce((acc, item) => {
      return acc + (item.price * item.Qty);
    }, 0);

    let totalCost2 = newValuesCollection?.reduce((acc, item) => {
      return acc + (item.price1 * item.Qty);
    }, 0);

    let totalQuantityOfItems = 0;
    for (let i = 0; i < newValuesCollection.length; i++) {
      totalQuantityOfItems += newValuesCollection[i].Qty;
    }

    let additionalCost = 0;
    let isMaterialsValidForQR = checkIfAllMaterialsValidForQR();
    if (isMaterialsValidForQR) {
      if (commanValues.isQrTrackingRequested) {
        additionalCost = (5 * totalQuantityOfItems);
        totalCost1 = totalCost1 + (5 * totalQuantityOfItems);
        if (selectedProcess == 1) {
          totalCost2 = totalCost2 + (5 * totalQuantityOfItems);
        }

      }
    } else {
      if (commanValues.isQrTrackingRequested) {
        additionalCost = 5 * totalQuantityOfItems;
      }
    }
    let shippingCharge = 0;


    if (isCncPriceZero) {
      setCommanValues({
        ...commanValues,
        hidePrice: true,
      });
    } else {
      setCommanValues({
        ...commanValues,
        price: totalCost1 ? totalCost1.toFixed(2) : 0,
        price1: selectedProcess == 1 ? (totalCost2 ? totalCost2.toFixed(2) : 0) : 0,
        hidePrice: false,
        additionalCost1: additionalCost,
        additionalCost2: additionalCost,
        shippingCharge1: shippingCharge,
        shippingCharge2: shippingCharge,
        leadTime1: selectedProcess == 2 ? 18 : 10,
      });
    }
    setValues([...newValuesCollection]);
    if (isError?.status) {
      if (selectedProcess == 2) {
        setThreeDError("Manual review is required for the parts you have uploaded.");
      }
      setCommanValues({
        ...commanValues,
        hidePrice: true,
        price: 0,
        price1: 0,
      });
    }
  }

  const updateCommanValueOnChange = (key, value) => {
    return new Promise((resolve, reject) => {
      try {
        if (value === 3 && key == 'selectedShipMethod' && stmp == 0) {
          toast.error("Can't set delivery time with empty price")
          return;
        }
        const updatedValues = { ...commanValues }
        updatedValues.errors[key] = false
        updatedValues[key] = value
        if (!value) {
          updatedValues.errors[key] = true
        }
        setCommanValues(updatedValues)
        if (key === "sameForAllField") {
          if (value) {
            setSameForAllField(true);
          } else {
            setSameForAllField(false);
          }
          handleSameForAllField(key, value);
        }
        return resolve(updatedValues)
      } catch (err) {
        return reject(err)
      }
    })
  }

  let handleSameForAllField = (key, value) => {
    try {
      let status = value;
      if (key !== 'sameForAllField') {
        return;
      }
      else if (status == true) {
        let updatedValues = [...values]
        if (updatedValues.length <= 1) {
          return;
        }
        else if (updatedValues.length > 1) {
          for (let v = 1; v < updatedValues.length; v++) {
            updatedValues[v].MaterialType = updatedValues[0].MaterialType;
            updatedValues[v].SubMaterial = updatedValues[0].SubMaterial;
            updatedValues[v].layer = updatedValues[0].layer;
            updatedValues[v].partMarking = updatedValues[0].partMarking;
            updatedValues[v].surfaceTreatment = updatedValues[0].surfaceTreatment;
            updatedValues[v].surfaceFinish = updatedValues[0].surfaceFinish;
            updatedValues[v].tolerance = updatedValues[0].tolerance;
            updatedValues[v].threeDTechnology = updatedValues[0].threeDTechnology;
            updatedValues[v].threeDMachine = updatedValues[0].threeDMachine;
            updatedValues[v].threeDMaterial = updatedValues[0].threeDMaterial;
            updatedValues[v].threeDPostProcessing = updatedValues[0].threeDPostProcessing;
          }

          setValues([...updatedValues]);
        }

        if (selectedProcess == 1) {
          handleCNCPriceUpdate()
        } else {
          handleThreeDPriceUpdate();
        }

      }
      else if (status == false) {
        return;
      }

    } catch (error) {
      return error
    }
  }


  const updateBulkValueOnChange = async (index, bulkData = []) => {
    try {
      let localValueCollection = [...values];
      for (let i = 0; i < localValueCollection.length; i++) {
        if (i == index) {
          localValueCollection[i] = { ...localValueCollection[i], ...bulkData };
        }
      }

      for (let b = 0; b < bulkData.length; b++) {
        let key = bulkData[b].key
        let value = bulkData[b].value

        if (sameForAllField) {
          for (let v = 0; v < localValueCollection.length; v++) {
            const singleErrors = localValueCollection[v].errors || {}
            singleErrors[key] = false
            localValueCollection[v].key = value
            if (!value) {
              singleErrors[key] = true
            }
            localValueCollection[v].errors = { ...singleErrors }
          }
        }
      }


      let totalCost1 = localValueCollection?.reduce((acc, item) => {
        return acc + (item.price * item.Qty);
      }, 0)

      let totalCost2 = localValueCollection?.reduce((acc, item) => {
        return acc + (item.price1 * item.Qty);
      }, 0)

      let totalQuantityOfItems = 0;
      for (let i = 0; i < localValueCollection.length; i++) {
        totalQuantityOfItems += localValueCollection[i].Qty;
      }


      let additionalCost = 0;
      let isMaterialsValidForQR = checkIfAllMaterialsValidForQR();
      if (isMaterialsValidForQR) {
        if (commanValues.isQrTrackingRequested) {
          additionalCost = 5 * totalQuantityOfItems;
          totalCost1 = totalCost1 + (5 * totalQuantityOfItems);
          totalCost2 = totalCost2 + (5 * totalQuantityOfItems);
        }
      } else {
        if (commanValues.isQrTrackingRequested) {
          additionalCost = 5 * totalQuantityOfItems;
        }
      }
      let shippingCharge = 0;
      setCommanValues({
        ...commanValues,
        price: totalCost1.toFixed(2),
        price1: selectedProcess == 1 ? (totalCost2 ? totalCost2.toFixed(2) : 0) : 0,
        additionalCost1: additionalCost,
        additionalCost2: additionalCost,
        shippingCharge1: shippingCharge,
        shippingCharge2: shippingCharge,
        leadTime1: selectedProcess == 2 ? 18 : 10,
      })
      setValues(localValueCollection)
      return true
    } catch (err) {
      toast.error(err);
      return false;
    }
  }





  const handleCloseUserModal = () => {
    setShowUserModal(false);
    setAllFilteredUsers(allUsers);
  }

  const searchUser = (e) => {
    let localFilteredUser = allUsers.filter(item => (item.firstName + " " + item.lastName).toUpperCase().includes((e.target.value).toUpperCase()));
    setAllFilteredUsers(localFilteredUser);
  }

  const handleAdminCreateQuote = async (user) => {
    let userId = localStorage.getItem("_id");
    if (window.location.host === 'make.8xparts.com') {
      // window?.dataLayer?.push({
      //   event: 'saveQuote',
      //   userId: userId,
      //   referenceId: email?.split('@')[0] + email?.split('@')[1]?.split(".")[0],
      // });
    }

    let responseAdmin = confirm("Are you sure you want to create a quotation for the selected user?");
    try {
      if (responseAdmin) {
        setIsQuoteCreationInProcess(true);
        const response = await saveApiCall(user);
        setIsQuoteCreationInProcess(false);
        toast.success("Quotation Saved Successfully");
        if (isAdminShare) {
          navigate("/create-instant-quotation/" + response._id, { state: { functionalityName: 'Share', savedQuoteRefId: response.RefId } });
          setShowUserModal(false);
        } else {
          navigate('/admin/quotes')
        }
      }
    } catch (err) {
      setIsQuoteCreationInProcess(false)
      console.log("err", err)
      toast.error("Something went wrong while creating quotation.")
    }

  }

  const handleQuoteCreateUpdate = (updateType, dataFromOnSubmit, isSaveForFunctionalityFromOnSubmit) => {
    console.log("Update Type :", updateType);
    setShowQuoteUpdateModal(false);
    let data;
    let isSaveForFunctionality;

    if (!updateType) {
      data = dataFromOnSubmit;
      isSaveForFunctionality = isSaveForFunctionalityFromOnSubmit;
    } else {
      data = dataForQuote;
      isSaveForFunctionality = isSaveForFunctionalityState;
    }

    if (selectedProcess == 2) {
      data = dataFromOnSubmit;
      console.log("Data :", data);
    }

    return new Promise(async (resolve, reject) => {
      try {
        let userId = localStorage.getItem("_id");
        if (window.location.host === 'make.8xparts.com') {
          if (_id) {
            // window?.dataLayer?.push({
            //   event: 'updateQuote',
            //   userId: userId,
            //   referenceId: email?.split('@')[0] + email?.split('@')[1]?.split(".")[0],
            // })
          } else {
            // window?.dataLayer?.push({
            //   event: 'saveQuote',
            //   userId: userId,
            //   referenceId: email?.split('@')[0] + email?.split('@')[1]?.split(".")[0],
            // });
          }
        }
        if (isSaveForFunctionality?.functionalityName == 'Chat' && isAdminUser) {
          alert("Chat and Manual quotation functionality is not available for admin user.Please save quote using save button to create a RFQ");
          return;
        }
        if (isSaveForFunctionality?.functionalityName == 'Share') {
          setIsAdminShare(true);
        }

        if (_id) {
          if (updateType == "isRepeatOrder") {
            let userUpdateResponse = confirm("Are you sure you want to proceed with the current details? ? A new quotation version will be created for your repeat order on your confirmation.")
            if (!userUpdateResponse) {
              return;
            }
          } else if (updateType == "isRepeatOrderWithUpdate") {
            let userUpdateResponse = confirm("Are you sure you want to proceed with the current details? A new quotation version will be created on your confirmation.")
            if (!userUpdateResponse) {
              return;
            }
          } else {
            let userUpdateResponse = confirm(`Are you sure you want to update the quotation with the current details? ${updateType == "new" ? "A new quotation version will be created on your confirmation." : "Existing quotation will be updated on your confirmation."}`)
            if (!userUpdateResponse) {
              return;
            }
          }
        } else {
          let userUpdateResponse = confirm("Are you sure you want to save the quotation with the current details?")
          if (!userUpdateResponse) {
            return;
          }
        }

        if (values.length == 0) {
          toast.error("Please add parts in the quote to update the quote");
          return;
        }

        switch (true) {
          case (selectedProcess == 1):
            const cncRes = await validateCNCData()
            if (cncRes) {
              break;
            }
            return resolve(cncRes)
          case (selectedProcess == 2):
            const threeDRes = await validateThreeDPrinting()
            if (threeDRes) {
              break;
            }
            return resolve(threeDRes)
          case (selectedProcess == 3):
            const sheetRes = await validateSheetAndInjection()
            if (sheetRes) {
              break;
            }
            return resolve(sheetRes)
          case (selectedProcess == 4):
            const injectionRes = await validateSheetAndInjection()
            if (injectionRes) {
              break;
            }
            return resolve(injectionRes)
          case (selectedProcess > 4):
            const otherRes = await validateSheetAndInjection()
            if (otherRes) {
              break;
            }

          default:
            return resolve(true)
        }
        let response = null;
        if (!isAdminUser) {
          if (updateType === 'isRepeatOrder' || updateType == 'ptc') {
            response = await saveApiCall(null, "new")
            navigate("/get-instant-quote/checkout/" + response._id)
          } else if (updateType === "isRepeatOrderWithUpdate") {
            response = await saveApiCall(null, "new")
            if (selectedProcess == 1) {
              toast.success("Quotation sent to review successfully.Once prices are confirmed by admin you can create an order for this quotation.");
            } else if (selectedProcess == 2 && dataFromOnSubmit?.saveQuote) {
              navigate('/saved-quote')
            }
            else {
              navigate("/get-instant-quote/checkout/" + response._id)
            }
          } else if (selectedProcess == 2 && data?.ptc) {
            response = await saveApiCall(null, "new")
            if (selectedProcess == 1) {
              toast.success("Quotation sent to review successfully.Once prices are confirmed by admin you can create an order for this quotation.");
              navigate('/saved-quote')
            } else if (selectedProcess == 2 && data?.ptc) {
              navigate("/get-instant-quote/checkout/" + response._id)
            } else {
              navigate('/saved-quote')
            }
          } else {
            response = await saveApiCall(null, updateType)
            toast.success("Quotation sent to review Successfully");
            if (isSaveForFunctionality) {
              navigate("/create-instant-quotation/" + response._id, { state: { functionalityName: isSaveForFunctionality.functionalityName, savedQuoteRefId: response.RefId } });
            } else {
              navigate('/saved-quote')
            }
          }
          return resolve(response)
        } else {
          if (!_id) {
            setShowUserModal(true);
          } else {
            let response = await saveApiCall(null, updateType);

            toast.success("Quotation Updated Successfully");
            if (isSaveForFunctionality) {
              navigate("/create-instant-quotation/" + response._id, { state: { functionalityName: isSaveForFunctionality.functionalityName, savedQuoteRefId: response.RefId } });
            } else {
              navigate(`/admin/admin-quote/${response._id}`);
            }
          }

        }
      } catch (err) {
        console.log("err", err)
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message)
          return;
        }
        let message = err && err.message ? err.message : "Something went wrong during save quote"
        toast.error(message)
        return reject(err)
      }
    })
  }

  const handleUpdateQuoteModalClose = () => {
    setShowQuoteUpdateModal(false)
  }

  const onSubmit = (e, data, isSaveForFunctionality) => {

    console.log("Data :", data);


    if (_id) {
      if (repeatOrder) {
        handleQuoteCreateUpdate("isRepeatOrder")
      } else if (isRepeatOrderWithUpdate) {
        handleQuoteCreateUpdate("isRepeatOrderWithUpdate")
      } else if (commanValues.myorders) {
        handleQuoteCreateUpdate("new")
      } else if (data.ptc) {
        if (commanValues.myorders) {
          handleQuoteCreateUpdate("new", data)
        } else {
          handleQuoteCreateUpdate("", data)
        }
      } else {
        setShowQuoteUpdateModal(true)
        setIsSaveForFunctionalityState(isSaveForFunctionality)
        setDataForQuote(data);
      }
    } else {
      handleQuoteCreateUpdate("", data, isSaveForFunctionality);
    }
  }

  const validateCNCData = () => {
    return new Promise((resolve, reject) => {
      try {
        let isValidated = true;
        const validateForString = ['MaterialType'];
        const validateForNumber = ['Qty']
        const updatedValues = [...values]
        for (let v = 0; v < updatedValues.length; v++) {
          const singleVaue = updatedValues[v];
          const singleErrors = updatedValues[v].errors || {}
          if (!isValidFileName(updatedValues[v]?.selectedFile?.name || updatedValues[v]?.file?.filename)) return resolve(true);
          Object.keys(singleErrors).map(function (key) {
            if ((validateForNumber.includes(key) || validateForString.includes(key)) && singleErrors[key]) {
              isValidated = false
            }
          });
          Object.keys(singleVaue).map(function (key) {
            if (validateForNumber.includes(key) && (!singleVaue[key] || singleVaue[key] === 0 || isNaN(singleVaue[key]))) {
              isValidated = false
              singleErrors[key] = true
            }
            if (validateForString.includes(key) && (!singleVaue[key] || singleVaue[key] === "")) {
              isValidated = false
              singleErrors[key] = true
            }
          });
          updatedValues[v].errors = { ...singleErrors }
        }
        setValues([...updatedValues])
        myRef?.current?.scrollIntoView();
        return resolve(isValidated)
      } catch (err) {
        return reject(err)
      }
    })
  }
  const validateThreeDPrinting = () => {
    return new Promise((resolve, reject) => {
      try {
        let isValidated = true;
        const validateForString = ['MaterialType']
        const validateForNumber = ['Qty']
        const updatedValues = [...values]
        for (let v = 0; v < updatedValues.length; v++) {
          const singleVaue = updatedValues[v];
          const singleErrors = updatedValues[v].errors || {}
          if (!isValidFileName(updatedValues[v].selectedFile?.name)) return resolve(true);
          Object.keys(singleErrors).map(function (key) {
            if ((validateForNumber.includes(key) || validateForString.includes(key)) && singleErrors[key]) {
              isValidated = false
            }
          });
          Object.keys(singleVaue).map(function (key) {
            if (validateForNumber.includes(key) && (!singleVaue[key] || singleVaue[key] === 0 || isNaN(singleVaue[key]))) {
              isValidated = false
              singleErrors[key] = true
            };
            if (validateForString.includes(key) && (!singleVaue[key] || singleVaue[key] === "")) {
              isValidated = false
              singleErrors[key] = true
            }
          });
          updatedValues[v].errors = { ...singleErrors }
        }

        setValues([...updatedValues])
        return resolve(isValidated)
      } catch (err) {
        return reject(err)
      }
    })
  }
  const validateSheetAndInjection = () => {
    let isValidated = true;
    let errorMessage = "";
    return new Promise((resolve, reject) => {
      try {
        values.map((value) => {
          if (commanValues.errors.sheetAndInjectionQuantity) {
            isValidated = false
          }
          else if (value.Qty < 1 || !value.Qty) {
            isValidated = false;
            errorMessage = 'Please enter valid value for quantity';
          }
          if (!(value.file || value.selectedFile)) {
            if (value.description.length == 0) {
              isValidated = false;
              errorMessage = "Line items without uploaded files must have a description.";
            }
          }
        })
        if (!isValidated) {
          toast.error(errorMessage)
        }
        return resolve(isValidated)
      } catch (err) {
        return reject(err)
      }
    })
  }

  const isValidFileName = (name) => {
    const type = getFileExtension(name)?.toUpperCase();
    if (type === "STP" || type === "STEP" || type === "IGES" || type === "OBJ" || type === "STL") return true;

    return false;
  }


  const saveApiCall = (user, updateType) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true);
        const uniqueFileNameInBoundingSet = new Set();
        const uniqueFileNameInValuesSet = new Set();
        const uniqueBoundingArr = [];
        const uniqueFromBounding = [];

        for (let i = 0; i < bounding.length; i++) {
          if (uniqueFileNameInBoundingSet.has(bounding[i].fileName)) {
            continue;
          } else {
            uniqueFromBounding.push(bounding[i]);
            uniqueFileNameInBoundingSet.add(bounding[i].fileName);
          }
        }

        for (let i = 0; i < values.length; i++) {
          uniqueFileNameInValuesSet.add(values[i]?.selectedFile?.path || values[i]?.file?.originalname || values[i]?.selectedFile?.name);
        }

        for (let j = 0; j < uniqueFromBounding.length; j++) {
          if (uniqueFileNameInValuesSet.has(uniqueFromBounding[j].fileName)) {
            uniqueBoundingArr.push(uniqueFromBounding[j]);
          }
        }


        let updatedValues = []
        let updatedCommanValues = { ...commanValues }
        for (let u = 0; u < values.length; u++) {
          let singleValue = values[u];
          let newObject = {}
          Object.keys(singleValue).map(function (key) {
            if (key !== 'errors') {
              if (singleValue[key] && (singleValue[key] !== "" || singleValue[key] !== ''))
                newObject[key] = singleValue[key]
            }
          })
          newObject = {
            ...newObject,
            MaterialTypeName: material.filter((item) => { return item._id === newObject.MaterialType })[0]?.name.toUpperCase()
          }
          updatedValues.push(newObject)
        }

        let user_id;
        if (user) {
          user_id = user._id
        } else {
          user_id = localStorage.getItem("_id")
        }

        if (isAdminUser && _id && userIdToEditQuote) {
          user_id = userIdToEditQuote
        }

        if (sample) {
          for (let i = 0; i < updatedValues.length; i++) {
            if (updatedValues[i].selectedFile?.name == "SamplePart.stp") {
              updatedValues[i].selectedFile = { path: 'SamplePart.stp' }
            }
          }
        };

        let formData = new FormData();
        let threeDfileCount = 0;
        let threeDfileNameArray = [];
        files.forEach(item => {
          if (item.isNotFromMongoDB) {
            threeDfileCount++;
            threeDfileNameArray.push(item.path)
          }
        })
        formData.append('uploaded3dFilesNameArray', JSON.stringify(threeDfileNameArray))
        formData.append('number3DOfFiles', threeDfileCount)
        let twoDFileCount = 0;
        let twoDFileNameArray = [];
        for (const key in twoDFiles) {
          const value = twoDFiles[key];
          if (value.file && value.isNotFromMongoDB) {
            twoDFileCount++;
            twoDFileNameArray.push(value.fileName)
          }
        }
        console.log(updatedValues);
        formData.append('uploaded2DFilesNameArray', JSON.stringify(twoDFileNameArray))
        formData.append('numberOf2DFiles', twoDFileCount)
        formData.append('twoDFilesData', JSON.stringify(twoDFiles))
        formData.append('boundingBox', JSON.stringify(uniqueBoundingArr));
        formData.append('partsData', JSON.stringify(updatedValues));
        formData.append('selectedProcess', selectedProcess);
        formData.append("userId", user_id);
        formData.append('price', updatedCommanValues.price);
        formData.append('price1', updatedCommanValues.price1);
        formData.append('price2', updatedCommanValues.price2);
        formData.append('selectedShipMethod', updatedCommanValues.selectedShipMethod);
        formData.append('sheetAndInjectionDiscription', updatedCommanValues.sheetAndInjectionDiscription);
        formData.append('sheetAndInjectionQuantity', updatedCommanValues.sheetAndInjectionQuantity);
        formData.append('sameForAllField', sameForAllField)
        formData.append('hidePrice', updatedCommanValues.hidePrice);
        formData.append('isInvalidFiles', isInvalidFiles)
        formData.append('projectName', projectName)
        formData.append('isQrTrackingRequested', updatedCommanValues.isQrTrackingRequested)
        formData.append('isDimensionalReportRequested', updatedCommanValues.isDimensionalReportRequested)
        formData.append('isConformanceCertificateRequested', updatedCommanValues.isConformanceCertificateRequested)
        formData.append('additionalCost1', updatedCommanValues.additionalCost1)
        formData.append('additionalCost2', updatedCommanValues.additionalCost2)
        formData.append('additionalCost3', updatedCommanValues.additionalCost3)
        formData.append('shippingCharge1', updatedCommanValues.shippingCharge1)
        formData.append('shippingCharge2', updatedCommanValues.shippingCharge2)
        formData.append('shippingCharge3', updatedCommanValues.shippingCharge3)
        formData.append('leadTime1', updatedCommanValues.leadTime1 || 0)
        formData.append('leadTime2', updatedCommanValues.leadTime2 || 0)
        formData.append('leadTime3', updatedCommanValues.leadTime3 || 0)
        formData.append('updateTypeForQuote', updateType)
        formData.append('isRepeatOrderQuotation', repeatOrder);
        for (const key in twoDFiles) {
          const value = twoDFiles[key];
          formData.append('twoDFiles', value?.file);
        }
        files.map((val, i) => {
          formData.append('selectedFile', val?.selectedFile || val)
        })
        if (_id) {
          const updateResponse = await postApi(`comman/update-quote/${_id}`, formData, token);
          if (!updateResponse && !updateResponse.status) {
            throw { message: updateResponse.message || "Something went wrong" };
          }
          let mailData;

          if (updateResponse.data.selectedProcess == 2 && updateResponse.data.hidePrice == true) {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: _id,
              id: updateResponse.data.RefId,
              process: updateResponse.data.selectedProcess,
              sheetAndInjectionDesc: updateResponse.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(updateResponse.data.partsData),
              subTotal: updateResponse.data.stManualPrice,
              hidePrice: updateResponse.data.hidePrice,
              price: updateResponse.data.price,
              price1: updateResponse.data.price1,
              stManualPrice: updateResponse.data.stManualPrice,
              version: updateResponse.data.version,
            }
          }
          else if (updateResponse.data.selectedProcess == 1) {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: _id,
              id: updateResponse.data.RefId,
              process: updateResponse.data.selectedProcess,
              sheetAndInjectionDesc: updateResponse.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(updateResponse.data.partsData),
              subTotal: updateResponse.data.stManualPrice,
              hidePrice: updateResponse.data.hidePrice,
              price: updateResponse.data.price,
              price1: updateResponse.data.price1,
              stManualPrice: updateResponse.data.stManualPrice,
              version: updateResponse.data.version,
            }
          }
          else if (updateResponse.data.selectedProcess >= 3) {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: _id,
              id: updateResponse.data.RefId,
              process: updateResponse.data.selectedProcess,
              sheetAndInjectionDesc: updateResponse.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(updateResponse.data.partsData),
              subTotal: updateResponse.data.sheetAndInjectionManualPrice,
              hidePrice: updateResponse.data.hidePrice,
              version: updateResponse.data.version,
              sheetAndInjectionManualPrice: updateResponse.data.sheetAndInjectionManualPrice,
            }
          }
          else {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: _id,
              id: updateResponse.data.RefId,
              process: updateResponse.data.selectedProcess,
              sheetAndInjectionDesc: updateResponse.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(updateResponse.data.partsData),
              subTotal: updateResponse.data.selectedShipMethod == 1 ? updateResponse.data.price1 : (updateResponse.data.selectedShipMethod == 2 ? updateResponse.data.price2 : 0),
              hidePrice: updateResponse.data.hidePrice,
              version: updateResponse.data.version,
              sheetAndInjectionManualPrice: updateResponse.data.sheetAndInjectionManualPrice,
            }
          }
          quoteSaveMail(token, mailData);
          setLoading(false)
          return resolve(updateResponse.data);
        } else {
          const response = await postApi('comman/save-quote', formData, token);
          if (!response && !response.status) {
            throw { message: response.message || "Something went wrong" };
          }
          let mailData;

          if (response?.data?.selectedProcess == 2 && response.data.hidePrice == true) {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: response.data._id,
              id: response.data.RefId,
              process: response.data.selectedProcess,
              sheetAndInjectionDesc: response.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(response.data.partsData),
              subTotal: response.data.stManualPrice,
              hidePrice: response.data.hidePrice,
              price: response.data.price,
              price1: response.data.price1,
              stManualPrice: response.data.stManualPrice,
              version: response.data.version,
            }
          }
          else if (response.data.selectedProcess == 1) {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: response.data._id,
              id: response.data.RefId,
              process: response.data.selectedProcess,
              sheetAndInjectionDesc: response.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(response.data.partsData),
              subTotal: response.data.stManualPrice,
              hidePrice: response.data.hidePrice,
              price: response.data.price,
              price1: response.data.price1,
              stManualPrice: response.data.stManualPrice,
              version: response.data.version,
            }
          }
          else if (response.data.selectedProcess >= 3) {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: response.data._id,
              id: response.data.RefId,
              process: response.data.selectedProcess,
              sheetAndInjectionDesc: response.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(response.data.partsData),
              subTotal: response.data.sheetAndInjectionManualPrice,
              hidePrice: response.data.hidePrice,
              sheetAndInjectionManualPrice: response.data.sheetAndInjectionManualPrice,
              version: response.data.version,
            }
          }
          else {
            mailData = {
              username: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
              quoteId: response.data._id,
              id: response.data.RefId,
              process: response.data.selectedProcess,
              sheetAndInjectionDesc: response.data.sheetAndInjectionDiscription,
              partsData: JSON.stringify(response.data.partsData),
              subTotal: response.data.selectedShipMethod == 1 ? response.data.price1 : (response.data.selectedShipMethod == 2 ? response.data.price2 : 0),
              hidePrice: response.data.hidePrice,
              sheetAndInjectionManualPrice: response.data.sheetAndInjectionManualPrice,
              price: response.data.price,
              price1: response.data.price1,
              stManualPrice: response.data.stManualPrice,
              version: response.data.version,
            }
          }
          quoteSaveMail(token, mailData);
          setLoading(false)
          return resolve(response.data);
        }
      } catch (err) {
        setLoading(false)
        return reject(err)
      }
    })
  }
  const fetchDataFromApi = (endPoint, payload = []) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetchApi(endPoint, payload);
        if (!response && !response.status) {
          throw { message: response.message || "Something went wrong" };
        }
        return resolve(response.data);
      } catch (err) {
        return reject(err);
      }
    });
  };

  const handleDelete = (index) => {
    try {
      let afterFltr = values.filter((val, i) => i !== index)
      let fileName = (values[index]?.selectedFile?.name || values[index]?.file?.originalname) + index;
      if (matSubMatMap.get(fileName)) {
        let localMatSubMap = matSubMatMap;
        localMatSubMap.delete(fileName);
        setMatSubMatMap(localMatSubMap);
      }
      let boundingAfterFltr = bounding.filter((val, i) => i !== index)

      setValues(val => [...afterFltr])
      setBounding(boundingAfterFltr)

      let afterDeleteFile = files?.filter((val, i) => i !== index);

      if (_id) {
        for (let val of afterDeleteFile) {
          let ext = getFileExtension(val?.originalname || val?.selectedFile?.path);
          if (ext == 'stp' || ext == 'step' || ext == 'iges' || ext == 'stl' ||
            ext == 'STP' || ext == 'STEP' || ext == 'IGES' || ext == 'STL'
          ) {
            setIsInvalidFiles(false);
            continue;
          }
          else {
            break;
          }
        }
      }
      else if (!_id) {
        for (let val of afterDeleteFile) {
          let ext = getFileExtension(val?.name || val?.selectedFile?.path);
          if (ext == 'stp' || ext == 'step' || ext == 'iges' || ext == 'stl' ||
            ext == 'STP' || ext == 'STEP' || ext == 'IGES' || ext == 'STL'
          ) {
            setIsInvalidFiles(false);
            continue;
          }
          else {
            break;
          }
        }
      }
      setFiles([...afterDeleteFile]);
      try {
        let local2DFiles = { ...twoDFiles };
        delete local2DFiles[index];
        for (let i = index + 1; i < Object.keys(local2DFiles).length + 1; i++) {
          // console.log('local2D fiels at i-1',local2DFiles[i - 1])
          // console.log('local2D fiels at i',local2DFiles[i])
          local2DFiles[i - 1] = local2DFiles[i];
          delete local2DFiles[i];
        }
        setTwoDFiles({ ...local2DFiles });
      } catch (err) {
        console.log('err', err);
      }
    }
    catch (err) {
      console.log("err", err)
    }
  }

  const fetchBulkPricingData = async () => {
    try {
      const response = await getAllBulkPricing(token);
      setPricingData(response.data);
      let allUser = await getAllUsers(token);
      setAllUsers(allUser);
      setAllFilteredUsers(allUser);

    } catch (error) {
      toast.error("Unable to Fetch Bulk Pricing");

    }
  };








  if (showCad) {
    return (
      <Modal show={showCad}>
        <Modal.Body id='cad-analyzer'>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowCad(!showCad)} variant="secondary">
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    );
  }



  return (
    <>
      {
        processing && selectedProcess < 3 ? (
          <div className="processing" style={{ zIndex: 2 }}>
            <div><img src={Processing} alt='processing' /></div>
            <div>
              <h2 className="font-bold text-xl">{_id ? "Loading " : "Uploading"} your part...</h2>
              <div style={{ padding: '0 20px' }}>Dynamic algorithm is processing your design to provide an instant quotation for you.</div>
            </div>
          </div>
        ) : ((globeLoader && selectedProcess < 3)) && (
          <div className="processing" style={{ zIndex: 2 }}>
            <div><img src={GettingQuote} alt='instant quote' /></div>
            <div>
              <h2 className="font-bold text-xl">Getting Instant Quotation</h2>
              <p>Fetching the best price for you.</p>
            </div>
          </div>
        )
      }

      {isLoading && processing ? <Loading /> : ""}

      <div className="content-wrapper"
        style={{ height: '100vh', overflow: (processing || ((globeLoader) && !sample && (selectedProcess < 3))) ? 'hidden' : 'scroll' }}
      >
        <Header></Header>

        <Modal
          size="sm"
          dialogClassName="qoute-version-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showQuoteUpdateModal}
          onHide={handleUpdateQuoteModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '15px' }}>
              Do you want to create a new version or update the exist quote?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div style={{ textAlign: "center" }}>
              <Button
                className="req-quote-btn"
                onClick={() => {
                  handleQuoteCreateUpdate("new")
                }}
              >
                New Version
              </Button>
              <Button
                style={{ marginLeft: "20px" }}
                className="req-quote-btn"
                onClick={() => {
                  handleQuoteCreateUpdate("update")
                }}
              >
                Update Existing
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="req-quote-btn" onClick={handleUpdateQuoteModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>


        {isValidUser ?
          <>
            {loading ? <Loading /> : ""}
            {step === 1 &&
              <div className="dashboard-holder">
                <div className="sm-container">
                  <div className="common-bx">
                    <ProcessSection
                      selectedProcess={selectedProcess}
                      isProcessNotSelected={isProcessNotSelected}
                      setSelectedProcess={setSelectedProcess}
                      setIsProcessNotSelected={setIsProcessNotSelected}
                      option={option}
                    />
                    {/*--Below section call when CNC machining or 3d Prniting ---*/}
                    {selectedProcess >= 1 && <div className={`upload-req`}>
                      <SelectFileSection
                        handleDraftQuoteFunctionality={handleDraftQuoteFunctionality}
                        selectedProcess={selectedProcess}
                        onSelectfile={onSelectfile}
                        setIsProcessNotSelected={setIsProcessNotSelected}
                        values={values}
                        smaple={sample}
                        stManualPrice={stmp}
                        sheetAndInjectionManualPrice={simp}
                        file={files}
                        step={step}
                        setStep={setStep}
                        setValues={setValues}
                        isInvalidFiles={isInvalidFiles}
                        setFiles={setFiles}
                        setSample={setSample}
                        quoteId={_id}
                        askFO={askFOptm}
                        onSubmit={onSubmit}
                        updateValueOnChange={updateValueOnChange}
                        commanValues={commanValues}
                        setCommanValues={setCommanValues}
                        updateCommanValueOnChange={updateCommanValueOnChange}
                        saveForFunctionality={saveForFunctionality}
                        isQuoteChanged={isQuoteChanged}
                        threeDError={threeDError}
                        cncError={cncError}
                        quoteRef={selectedQuoteRef}
                        savedQuoteRefId={savedQuoteRefId}
                        isAdminUser={isAdminUser}
                        setIsQuoteChanged={setIsQuoteChanged}
                        sameForAllField={sameForAllField}
                        setSameForAllField={setSameForAllField}
                        isValidValuesForQR={isValidValuesForQR}
                        checkIfAllMaterialsValidForQR={checkIfAllMaterialsValidForQR}
                      />
                    </div>}
                    {/* upload requirement close */}
                  </div >

                  <div className="privacy-text">
                    <strong>Privacy:</strong> 8xParts 3D secures all uploaded files,
                    protecting your intellectual property. If you can’t upload your
                    files at the moment, use our sample part to try out our instant
                    quoting and DfM tools or contact us on <a href="mailto: info@8xparts.com">info@8xparts.com</a> to sign an NDA.
                  </div>
                </div >
              </div >
            }
            {
              step === 2 && <div>

                {selectedQuoteRef &&
                  <Chat
                    RefId={selectedQuoteRef}
                    open={isChatOpen}
                    onDrawerClose={() => setIsChatOpen(false)}
                  />
                }

                {
                  teamModalShow && <TeamCollaboration
                    teamMemberModalShow={teamModalShow}
                    setTeamMemberModalShow={setTeamModalShow}
                    selectedQuoteForTeam={selectedQuote}
                    updateQuoteData={setSelectedQuote}
                    fetchDataForUser={() => null}
                  />
                }



                <ForwardToPurchaseModal
                  isForwardToPurchaserModalOpen={isForwardToPurchaserModalOpen}
                  setIsForwardToPurchaserModalOpen={setIsForwardToPurchaserModalOpen}
                  quoteId={_id}
                  selectedQuote={selectedQuote}
                  setSelectedQuote={setSelectedQuote}
                />
                <ShareModalComponent
                  isShareModalOpen={isShareModalOpen}
                  setIsShareModalOpen={setIsShareModalOpen}
                  selectedQuote={selectedQuote}
                />
                <TargetCostUserModal
                  setIsTargetCostModalOpen={setIsTargetCostModalOpen}
                  isTargetCostModalOpen={isTargetCostModalOpen}
                  selectedQuote={selectedQuote}
                  setSelectedQuote={setSelectedQuote}
                />

                <CollectData
                  handleDraftQuoteFunctionality={handleDraftQuoteFunctionality}
                  id={_id}
                  isRepeatOrderWithUpdate={isRepeatOrderWithUpdate}
                  masterThreeDTechnologyData={masterThreeDTechnologyData}
                  stManualPrice={stmp}
                  myRef={myRef}
                  selectedProcess={selectedProcess}
                  setSelectedProcess={setSelectedProcess}
                  values={values}
                  updateValueOnChange={updateValueOnChange}
                  onSelectfile={onSelectfile}
                  isInvalidFiles={isInvalidFiles}
                  setIsProcessNotSelected={setIsProcessNotSelected}
                  sheetAndInjectionManualPrice={simp}
                  quoteId={_id}
                  sample={sample}
                  askFO={askFOptm}
                  file={files}
                  setFiles={setFiles}
                  isEditQuote={isEditQuote}
                  boxDimension={boxDimension}
                  processing={processing}
                  globeLoader={globeLoader}
                  setSample={setSample}
                  onSubmit={onSubmit}
                  onDelete={handleDelete}
                  commanValues={commanValues}
                  updateCommanValueOnChange={updateCommanValueOnChange}
                  updateBulkValueOnChange={updateBulkValueOnChange}
                  saveForFunctionality={saveForFunctionality}
                  setIsQuoteChanged={setIsQuoteChanged}
                  isQuoteChanged={isQuoteChanged}
                  threeDError={threeDError}
                  cncError={cncError}
                  quoteRef={selectedQuoteRef}
                  savedQuoteRefId={savedQuoteRefId}
                  isAdminUser={isAdminUser}
                  setCommanValues={setCommanValues}
                  sameForAllField={sameForAllField}
                  setSameForAllField={setSameForAllField}
                  isValidValuesForQR={isValidValuesForQR}
                  checkIfAllMaterialsValidForQR={checkIfAllMaterialsValidForQR}
                  twoDFiles={twoDFiles}
                  setTwoDFiles={setTwoDFiles}
                  setValues={setValues}
                  setIsInchSelected={setIsInchSelected}
                  isInchSelected={isInchSelected}
                  selectedQuote={selectedQuote}
                />
              </div>}

          </>
          :
          <>
            <div className="dashboard-holder">
              <div className="sm-container">
                <div className="dashboard-option">
                  <div className="dash-left">
                    <div className="ds-heading">
                      <Link to="/saved-quote">
                        <p className="req-quote-btn">Go To Saved Quotes  </p>
                      </Link>
                    </div>
                  </div>

                  <div style={{ textAlign: 'center' }}>
                    <h2>Quotation information is not available</h2>
                    <br />
                    <p>Only person from same company domain (@{quoteUserEmail.split("@")[1]}) can view quote details in this page.</p>
                  </div>
                </div>
                <div className="privacy-text">
                  <strong>Privacy:</strong> 8xParts 3D secures all uploaded files,
                  protecting your intellectual property. If you can’t upload your
                  files at the moment, use our sample part to try out our instant
                  quoting and DfM tools or contact us on <a href="mailto: info@8xparts.com">info@8xparts.com</a> to sign an NDA.
                </div>
              </div>
            </div>
          </>}
        <Modal
          size="sm"
          dialogClassName="qoute-version-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserModal}
          onHide={handleCloseUserModal}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '15px' }}>
              Select a user for this Quotation :
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              isQuoteCreationInProcess ?
                <>
                  <img src={whiteLoader} style={{ height: '20px' }} /> Please wait while the quotation is getting created...
                </>
                :
                <>
                  <input placeholder="Search for user" onChange={(e) => searchUser(e)} />
                  {allFilteredUsers.length && allFilteredUsers?.map((item, i) => {
                    return <div key={i} className="admin-quote-create">
                      <div >
                        <h6>{item.firstName + " " + item.lastName}</h6>
                        <p>Email : {item.email}</p>
                      </div>
                      <div>
                        <button className="white-btn" onClick={() => handleAdminCreateQuote(item)}>
                          Select
                        </button>
                      </div>
                    </div>
                  })}
                </>
            }

          </Modal.Body>
          <Modal.Footer>
            {
              isQuoteCreationInProcess ?
                <>
                </>
                :
                <>
                  <Button variant="secondary" onClick={handleCloseUserModal}>
                    Close
                  </Button>
                </>
            }

          </Modal.Footer>
        </Modal>

      </div>
    </>
  );
}
export default InstantQuote;
