import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Vector3,
} from "three";

import { Context } from "./Context";
import Tooltip from "@material-ui/core/Tooltip";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import GridOnIcon from "@material-ui/icons/GridOn";
import VideocamIcon from "@material-ui/icons/Videocam";
import PaletteIcon from "@material-ui/icons/Palette";
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';

import {useSpring, animated} from "react-spring";

import { setCameraPosition } from "./setCameraPosition";
import ClearFilesButton from "./ClearFilesButton";
import GenerateCSVButton from "./GenerateCSVButton";
import FileSelectorPanel from "./FileSelectorPanel";

import RenderSolidEdgesIcon from "./icons/renderSolidEdges.svg";
import RenderSolidNoEdgesIcon from "./icons/renderSolidNoEdges.svg";
import RenderTransparentEdgesIcon from "./icons/renderTransparentEdges.svg";
import RenderTransparentNoEdgesIcon from "./icons/renderTransparentNoEdges.svg";
import BoundingBoxIcon from "./icons/boundingBox.svg";
import ViewFrontIcon from "./icons/viewFront.svg";
import ViewBackIcon from "./icons/viewBack.svg";
import ViewLeftIcon from "./icons/viewLeft.svg";
import ViewRightIcon from "./icons/viewRight.svg";
import ViewTopIcon from "./icons/viewTop.svg";
import ViewBottomIcon from "./icons/viewBottom.svg";
import Icon from "@material-ui/core/Icon";

import LoadFileButton from "./LoadFileButton";
import MyToggleButton from "./MyToggleButton";

function Drawer({tooltip, children, expandedFixed, enabled, setEnabled, titleIcon, style}) {
  const [expanded, setExpanded] = useState(false);
  const {drawerWidth} = useSpring({drawerWidth: expanded || expandedFixed ? 100 : 0});

  const drawerRef = useRef();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          pointerEvents: "all",
          ...style,
        }}
      >
        <div
          ref={drawerRef}
          style={{
            display: "flex",
            flexDirection: "row",
          }}
          onMouseOver={() => setExpanded(true)}
          onMouseOut={e => {
            if(!drawerRef.current.contains(e.relatedTarget)) {
              setExpanded(false);
            }
          }}
        >
          <Tooltip title={tooltip}>
            <MyToggleButton
              value="enabled"
              selected={enabled}
              onChange={() => setEnabled(x => !x)}
            >
              {titleIcon}
            </MyToggleButton>
          </Tooltip>
          <animated.div
            style={{
              maxWidth: drawerWidth.interpolate(x => x+"%"),
              opacity: drawerWidth.interpolate(x => x+"%"),
              display: drawerWidth.interpolate(x => x>1 ? "flex" : "none"),
              overflow: "hidden",
              flexDirection: "row",
              flexWrap: "nowrap",
            }}
          >
            {children}
          </animated.div>
        </div>
      </div>
    </>
  );
}

function RenderStyleDrawer({onRenderStyle, style}) {
  const [meshStyle, setMeshStyle] = useState("solid");
  const [edgeStyle, setEdgeStyle] = useState("shown");
  
  useEffect(() => {
    onRenderStyle({meshStyle, edgeStyle});
  }, [edgeStyle, meshStyle]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Drawer
        tooltip="set display style"
        titleIcon={<PaletteIcon />}
        expandedFixed={false}
        enabled={false}
        setEnabled={() => {}}
        style={style}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "0 0.5rem",
          }}
        >
          <ToggleButtonGroup
              exclusive
              onChange={(e, s) => {
                if(s === null) return;
                const renderStyle = JSON.parse(s);
                setMeshStyle(renderStyle.meshStyle);
                setEdgeStyle(renderStyle.edgeStyle);
              }}
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
            {[
              {
                content:
                  <Icon viewBox="0 0 100 100" style={{display: "flex"}}>
                    <img src={RenderSolidEdgesIcon} style={{height: "100%"}} alt="solid+edges" />
                  </Icon>,
                tooltip: "solid shading, visible edges",
                meshStyle: "solid",
                edgeStyle: "shown",
              },
              {
                content:
                  <Icon viewBox="0 0 100 100" style={{display: "flex"}}>
                    <img src={RenderSolidNoEdgesIcon} style={{height: "100%"}} alt="solid+no edges" />
                  </Icon>,
                tooltip: "solid shading, hidden edges",
                meshStyle: "solid",
                edgeStyle: "hidden",
              },
              {
                content:
                  <Icon viewBox="0 0 100 100" style={{display: "flex"}}>
                    <img src={RenderTransparentEdgesIcon} style={{height: "100%"}} alt="transparent+edges" />
                  </Icon>,
                tooltip: "transparent shading, visible edges",
                meshStyle: "transparent",
                edgeStyle: "shown",
              },
              {
                content:
                  <Icon viewBox="0 0 100 100" style={{display: "flex"}}>
                    <img src={RenderTransparentNoEdgesIcon} style={{height: "100%"}} alt="transparent+no edges" />
                  </Icon>,
                tooltip: "transparent shading, hidden edges",
                meshStyle: "transparent",
                edgeStyle: "hidden",
              },
            ].map((button, i) => (
              <Tooltip
                key={i}
                title={button.tooltip}
              >
                <MyToggleButton
                  value={JSON.stringify({meshStyle: button.meshStyle, edgeStyle: button.edgeStyle})}
                  selected={meshStyle === button.meshStyle && edgeStyle === button.edgeStyle}
                >
                  {button.content}
                </MyToggleButton>
              </Tooltip>
            ))}
          </ToggleButtonGroup>
        </div>
      </Drawer>
    </>
  );
}

function GridBBoxDrawer({onGrid, onBoundingBox, style}) {
  const [type, setType] = useState("grid");
  useEffect(() => {
    if(type === "grid") {
      onBoundingBox({enabled: false});
      onGrid({enabled: true});
    } else{
      onBoundingBox({enabled: true});
      onGrid({enabled: false});
    }
  }, [type]); // eslint-disable-line

  return (
    <>
      <Drawer
        tooltip="set dimension reference"
        titleIcon={<LinearScaleIcon />}
        expandedFixed={false}
        enabled={false}
        setEnabled={() => {}}
        style={style}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "0 0.5rem",
          }}
        >
          <ToggleButtonGroup
            exclusive
            onChange={(e, s) => {
              setType(s);
            }}
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {[
              {
                content:
                  <GridOnIcon />,
                tooltip: "grid",
                value: "grid",
              },
              {
                content:
                  <Icon viewBox="0 0 100 100" style={{display: "flex"}}>
                    <img src={BoundingBoxIcon} style={{height: "100%"}} alt="" />
                  </Icon>,
                tooltip: "bounding box",
                value: "boundingBox",
              },
            ].map((button, i) => (
              <Tooltip
                key={i}
                title={button.tooltip}
              >
                <MyToggleButton
                  value={button.value}
                  selected={type === button.value}
                >
                  {button.content}
                </MyToggleButton>
              </Tooltip>
            ))}
          </ToggleButtonGroup>
        </div>
      </Drawer>
    </>
  );
}

function CameraViewDrawer({style, controlsRef, cameraRef}) {
  const {state} = useContext(Context);

  return (
    <>
      <Drawer
        tooltip="camera view"
        titleIcon={<ControlCameraIcon />}
        expandedFixed={false}
        enabled={false}
        setEnabled={() => {}}
        style={style}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "0 0.5rem",
          }}
        >
          <ToggleButtonGroup
            exclusive
            onChange={(e, s) => {
            }}
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {[
              {
                content:
                  <Icon viewBox="0 0 100 100" style={{display: "flex"}}>
                    <img src={ViewTopIcon} style={{height: "100%"}} alt="transparent+no edges" />
                  </Icon>,
                tooltip: "top view",
                value: "top",
                onClick: () => {
                  setCameraPosition(state.files[state.activeFileIndex]?.properties?.aabb, cameraRef.current, controlsRef.current, new Vector3(0, 1, 0), new Vector3(0, 0, -1));
                },
              },
              {
                content:
                  <Icon viewBox="0 0 100 100" style={{display: "flex"}}>
                    <img src={ViewBottomIcon} style={{height: "100%"}} alt="transparent+no edges" />
                  </Icon>,
                tooltip: "bottom view",
                value: "bottom",
                onClick: () => {
                  setCameraPosition(state.files[state.activeFileIndex]?.properties?.aabb, cameraRef.current, controlsRef.current, new Vector3(0, -1, 0), new Vector3(0, 0, -1));
                },
              },
              {
                content:
                  <Icon viewBox="0 0 100 100" style={{display: "flex"}}>
                    <img src={ViewLeftIcon} style={{height: "100%"}} alt="transparent+no edges" />
                  </Icon>,
                tooltip: "left view",
                value: "left",
                onClick: () => {
                  setCameraPosition(state.files[state.activeFileIndex]?.properties?.aabb, cameraRef.current, controlsRef.current, new Vector3(-1, 0, 0), new Vector3(0, 1, 0));
                },
              },
              {
                content:
                  <Icon viewBox="0 0 100 100" style={{display: "flex"}}>
                    <img src={ViewRightIcon} style={{height: "100%"}} alt="transparent+no edges" />
                  </Icon>,
                tooltip: "right view",
                value: "right",
                onClick: () => {
                  setCameraPosition(state.files[state.activeFileIndex]?.properties?.aabb, cameraRef.current, controlsRef.current, new Vector3(1, 0, 0), new Vector3(0, 1, 0));
                },
              },
              {
                content:
                  <Icon viewBox="0 0 100 100" style={{display: "flex"}}>
                    <img src={ViewFrontIcon} style={{height: "100%"}} alt="transparent+no edges" />
                  </Icon>,
                tooltip: "front view",
                value: "front",
                onClick: () => {
                  setCameraPosition(state.files[state.activeFileIndex]?.properties?.aabb, cameraRef.current, controlsRef.current, new Vector3(0, 0, 1), new Vector3(0, 1, 0));
                },
              },
              {
                content:
                  <Icon viewBox="0 0 100 100" style={{display: "flex"}}>
                    <img src={ViewBackIcon} style={{height: "100%"}} alt="transparent+no edges" />
                  </Icon>,
                tooltip: "back view",
                value: "back",
                onClick: () => {
                  setCameraPosition(state.files[state.activeFileIndex]?.properties?.aabb, cameraRef.current, controlsRef.current, new Vector3(0, 0, -1), new Vector3(0, 1, 0));
                },
              },
            ].map((button, i) => (
              <Tooltip
                key={i}
                title={button.tooltip}
              >
                <MyToggleButton
                  value={button.value}
                  selected={false}
                  onClick={button.onClick}
                >
                  {button.content}
                </MyToggleButton>
              </Tooltip>
            ))}
          </ToggleButtonGroup>
        </div>
      </Drawer>
    </>
  );
}

function ResetCameraButton({style, onResetCamera}) {
  return (
    <>
      <Tooltip
        title="reset camera"
        style={{
          pointerEvents: "all",
          ...style
        }}
      >
        <MyToggleButton
          value="enabled"
          onClick={() => onResetCamera({})}>
          <VideocamIcon />
        </MyToggleButton>
      </Tooltip>
    </>
  );
}

export function FrontendToolbar({onGrid, onBoundingBox, onResetCamera, onRenderStyle, controlsRef, cameraRef}) {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        pointerEvents: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      >
        <ResetCameraButton
          style={{
            margin: "16px",
          }}
          onResetCamera={onResetCamera}
        />
        <CameraViewDrawer
          style={{
            margin: "0 16px",
          }}
          controlsRef={controlsRef}
          cameraRef={cameraRef}
        />
        <RenderStyleDrawer
          onRenderStyle={onRenderStyle}
          style={{
            margin: "16px",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      >
        <GridBBoxDrawer
          style={{
            margin: "16px",
          }}
          onBoundingBox={onBoundingBox}
          onGrid={onGrid}
        />
      </div>
    </div>
  );  
}

export function BackendToolbar({onGrid, onBoundingBox, onResetCamera, onRenderStyle, controlsRef, cameraRef}) {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        pointerEvents: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <LoadFileButton
            style={{
              margin: "16px",
            }}
          />
          <FileSelectorPanel
            style={{
              minWidth: "200px",
            }}
          />
        </div>
        <ClearFilesButton
          style={{
            margin: "0 16px",
          }}
        />
        <GenerateCSVButton
          style={{
            margin: "16px",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      >
        <ResetCameraButton
          style={{
            margin: "0 16px",
          }}
          onResetCamera={onResetCamera}
        />
        <CameraViewDrawer
          style={{
            margin: "16px",
          }}
          controlsRef={controlsRef}
          cameraRef={cameraRef}
        />
        <RenderStyleDrawer
          onRenderStyle={onRenderStyle}
          style={{
            margin: "0 16px",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        }}
      >
        <GridBBoxDrawer
          style={{
            margin: "16px",
          }}
          onBoundingBox={onBoundingBox}
          onGrid={onGrid}
        />
      </div>
    </div>
  );  
}
