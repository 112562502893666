import axios from "axios";

const config = require(`../../environment/development`).config;
/*for sign up user */

export const signUpAction = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/registration`,
        payload
      );
      return resolve(response.data);
    } catch (err) {
      console.log(err)

      return reject(err);
    }
  });
};
export const signVerification = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/registrationVerify`, {
        userId: payload,
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const forgotPassword = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/resendForgotEmail`, payload);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
}

export const checkEmail = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/check-email`, payload);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const loginViaPassword = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/login/password`, payload);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const triggerLoginOTP = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/login/otp`, payload);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const loginViaOTP = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/login/verify-otp`, payload);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const registerNewUser = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/registration`, payload);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

/*for login user */
export const signInAction = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/login`, payload);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
/* for forget password */
export const forgetEmail = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(`${config.backEnd}/forgetEmail`, payload);
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
/* for Resend Forget Email */
export const resendForgetEmail = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/resendForgetEmail`,
        payload
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
/* for Reset Password */
export const ResetPassword = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/forgetPassword`,
        payload
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

//Change Password function
// export const ChangePassword = (payload) => {
//   let response = new Promise((resolve, reject) => {
//     axios.post(`${config.backEnd}/change/password`,payload).then(response => {
//       return resolve(response)
//     }).catch(err => {
//       return reject(err)
//     })
//   });
// }
//Change Password function ends
export const ChangePassword = (payload, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      let header = {
        'Authorization': `Bearer ${JSON.parse(token)}`,
        'Content-Type': 'application/json'
      }
      let response = await axios.post(`${config.backEnd}/change/password`, payload, { headers: header });
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  })
}


export const registerUser = async (data) => {

  try {

    const response = await axios.post(`${config.backEnd}/register-user`, data);
    return response.data;

  } catch (error) {
    return false;
  }
}