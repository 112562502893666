import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CaptchaModal = ({ showModal, handleClose, handleSendEmail,buttonText }) => {
  const [captchaValue, setCaptchaValue] = useState('');
  const [userInput, setUserInput] = useState('');
  const [isValidCaptcha, setIsValidCaptcha] = useState(false);

  useEffect(() => {
    // Generate a new captcha value when the modal is opened
    generateCaptcha();   
  }, [showModal]);

  const generateCaptcha = () => {
    const newCaptchaValue = generateRandomCaptcha();
    setCaptchaValue(newCaptchaValue);
    setUserInput('');
    setIsValidCaptcha(false);
  };

  const generateRandomCaptcha = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 4; // Adjust the length of the captcha as needed
    let captcha = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      captcha += characters.charAt(randomIndex);
    }

    return captcha;
  };

  const handleCaptchaChange = (e) => {
    const value = e.target.value;
    setUserInput(value);
    setIsValidCaptcha(value === captchaValue);
  };

  const handleSendEmailWithCaptcha = (e) => {
    e.preventDefault();
    if (isValidCaptcha) {
      handleSendEmail();
      handleClose(false);
    }
  };

  return (
    <Modal 
        show={showModal} 
        onHide={handleClose} 
        onShow={generateCaptcha} 
        centered
        dialogClassName="d-flex align-items-center justify-content-center custom-modal-view-order"
        >
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize:'15px'}}>Verify Captcha</Modal.Title>
      </Modal.Header>
      <Modal.Body>

          <Form.Group controlId="formCaptcha">
            <h1 className="d-block text-center text-xl font-bold" style={{userSelect:'none'}}>{captchaValue}</h1>
            <Form.Label className="d-block text-center text-sm">Enter the characters you see:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Captcha"
              value={userInput}
              onChange={handleCaptchaChange}
              className="mx-auto text-center"
            />
          </Form.Group>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button style={{backgroundColor:'#2B96DC'}} onClick={handleSendEmailWithCaptcha} disabled={!isValidCaptcha}>
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CaptchaModal;
