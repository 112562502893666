import {createContext, useContext, useReducer} from "react";

const userContext = createContext();

const initialState = {
    quoteCount:0,
    orderCount:0,
    quoteFilterActive:'all-quote',
    orderFilterActive:'all-order',
    inProgressQuoteCount:0,
    readyToOrderQuoteCount:0,
    completedQuoteCount:0,
    inProgressOrderCount:0,
    completedOrderCount:0,
    materials:[],
    subMaterials:[],
    surfaceFinish:[],
    surfaceTreatment:[],
    tolerance:[],
    partMarking:[],
    layers:[],
}

export const USER_ACTIONS = {
    UPDATE_QUOTE_COUNT:'update-quote-count',
    UPDATE_ORDER_COUNT:'update-order-count',
    UPDATE_QUOTE_ACTIVE_FILTER:'update-quote-active-filter',
    UPDATE_ORDER_ACTIVE_FILTER:'update-order-active-filter',
    UPDATE_INPROGRESS_ORDER_COUNT:'update-inprogress-order-count',
    UPDATE_COMPLETED_ORDER_COUNT:'update-completed-order-count',
    UPDATE_MATERIALS_DATA:'update-materials-data',
}

const reducer = (state,action)=>{
    switch(action.type){
        case USER_ACTIONS.UPDATE_QUOTE_COUNT: return {...state,...action.payload};
        case USER_ACTIONS.UPDATE_ORDER_COUNT: return {...state,...action.payload};
        case USER_ACTIONS.UPDATE_QUOTE_ACTIVE_FILTER: return {...state,quoteFilterActive:action.payload};
        case USER_ACTIONS.UPDATE_ORDER_ACTIVE_FILTER: return {...state,orderFilterActive:action.payload};
        case USER_ACTIONS.UPDATE_INPROGRESS_ORDER_COUNT: return {...state,inProgressOrderCount:action.payload};
        case USER_ACTIONS.UPDATE_COMPLETED_ORDER_COUNT: return {...state,completedOrderCount:action.payload};
        case USER_ACTIONS.UPDATE_MATERIALS_DATA: return {...state, ...action.payload};
        default: return state;
    }
}



export const UserContextProvider = ({children}) =>{
    const  [state,dispatch] = useReducer(reducer,initialState);
    return <userContext.Provider value={{state,dispatch}}>{children}</userContext.Provider>
}

export const useUserContext = () => {
    return useContext(userContext);
};