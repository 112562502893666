import React, { useEffect, useState } from "react";
import AdminDashboardComponents from "../../components/HigherOrderComponents/AdminDashboardComponents";
import { useLocation } from "react-router-dom";
import { getMaterialDataById, getMaterialOptionsById, upsertMaterial } from "../../utils/actions/adminActions";
import { toast } from "react-toastify";

const CncCostLeadTimeForm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const state = location.state;
        if (state) {
          const { _id } = state;
          if (_id) {
            const response = await getMaterialOptionsById(_id);
            if (!response || !response.success) {
              throw new Error();
            }
            const { data } = response;
            setData((pre) => {
              return { ...pre, ...data, };
            });
          }
        }
      } catch (error) {
        console.log(error);
        toast.error("Unable To Fetch Data At The Moment");
      }

    })()
  }, [isReload, location.search]); ``



  const [data, setData] = useState({
    cnc_material_per_kg_cost1: "",
    cnc_material_per_kg_cost2: "",
    cnc_material_per_kg_cost3: "",
    cnc_process_lead_time1: "",
    cnc_process_lead_time2: "",
    cnc_process_lead_time3: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {


      let isValid = true;

      for (let i = 1; i <= 3; i++) {
        const materialCost = data[`cnc_material_per_kg_cost${i}`];
        const leadTime = data[`cnc_process_lead_time${i}`];

        if (materialCost || leadTime) {
          if (!materialCost || !leadTime) {
            toast.error(`Please submit both Material Cost and Lead Time for Option ${i}`);
            isValid = false;
            return;
          }
        }
      }

      if (!data.cnc_material_per_kg_cost1 && !data.cnc_material_per_kg_cost2 && !data.cnc_material_per_kg_cost3) {
        toast.error("Add At Least One Option");
        return;
      }

      const response = await upsertMaterial(data);
      if (!response || !response.success) {
        throw new Error();
      }

      toast.success("Options Updated Succesfully");
      setIsReload((pre) => !pre);

    } catch (error) {
      toast.error("Unable To Submit Options At The moment");
    }

  };

  const resetForm = () => {
    setData({
      cnc_material_per_kg_cost1: "",
      cnc_material_per_kg_cost2: "",
      cnc_material_per_kg_cost3: "",
      cnc_process_lead_time1: "",
      cnc_process_lead_time2: "",
      cnc_process_lead_time3: "",
    });
  };

  return (
    <div className="w-full p-4">
      <div className="grid grid-cols-3 gap-4 text-center mb-2">
        <div>
          <h4 className="font-medium text-lg">Option 1</h4>
        </div>
        <div>
          <h4 className="font-medium text-lg">Option 2</h4>
        </div>
        <div>
          <h4 className="font-medium text-lg">Option 3</h4>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {/* Material Per Kg Cost */}
        {["1", "2", "3"].map((num) => (
          <div key={`material-per-kg-cost-${num}`} className="mb-2">
            <label className="block text-sm font-medium text-gray-700">
              Material Per Kg Cost $
            </label>
            <input
              type="number"
              name={`cnc_material_per_kg_cost${num}`}
              value={data[`cnc_material_per_kg_cost${num}`]}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*(\.\d{0,2})?$/.test(value)) {
                  handleInputChange(e);
                }
              }}
              className="w-full p-2 border rounded-md"
              placeholder="Enter Cost"
            />
          </div>
        ))}

        {/* Process Lead Time */}
        {["1", "2", "3"].map((num) => (
          <div key={`process-lead-time-${num}`} className="mb-2">
            <label className="block text-sm font-medium text-gray-700">
              Lead Time (days)
            </label>
            <input
              type="number"
              name={`cnc_process_lead_time${num}`}
              value={data[`cnc_process_lead_time${num}`]}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md"
              placeholder="Enter Lead Time"
            />
          </div>
        ))}
      </div>

      <div className="flex justify-end mt-4 space-x-2">
        <button
          onClick={resetForm}
          className="bg-gray-500 text-white px-4 py-2 rounded"
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="bg-primaryColor text-white px-4 py-2 rounded"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AdminDashboardComponents(CncCostLeadTimeForm);
