import React, { useState, useEffect, useRef } from 'react'
import AdminDashboardComponents from '../components/HigherOrderComponents/AdminDashboardComponents'
import AdminCustomerComponetNew from './AdminCustomerComponetNew';
import AdminSupplierComponentNew from './AdminSupplierComponentNew';
import { DropDownIcon, LucidLoader } from '../components/Icons';
import { getAllUsers, getCompanies } from '../utils/actions/allactions';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { getAllSuppliers } from '../utils/actions/supplierActions';


const AdminDashboardNew = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("Token");
    const [selectedMode, setSelectedMode] = useState(0);
    const [userData, setUserData] = useState('');
    const [loading, setLoading] = useState(true);
    const [isMonthSelectorDropDownOn, setIsMonthSelectorDropDownOn] = useState(false);
    const [isYearSelectorDropDownOn, setIsYearSelectorDropDownOn] = useState(false);
    const [isCustomerDropDownOn, setIsAllCustomerDropDownOn] = useState(false);
    const [isCompanyDropdownOn, setIsCompanyDropdownOn] = useState(false);
    const [allCompany, setAllCompany] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(() => {
        // const currentMonth = new Date().getMonth();
        return [
            { id: -1, value: 'All Months', checked: true },
            { id: 0, value: 'January', checked: true },
            { id: 1, value: 'February', checked: true },
            { id: 2, value: 'March', checked: true },
            { id: 3, value: 'April', checked: true },
            { id: 4, value: 'May', checked: true },
            { id: 5, value: 'June', checked: true },
            { id: 6, value: 'July', checked: true },
            { id: 7, value: 'August', checked: true },
            { id: 8, value: 'September', checked: true },
            { id: 9, value: 'October', checked: true },
            { id: 10, value: 'November', checked: true },
            { id: 11, value: 'December', checked: true },
        ];
    });
    const [selectedYear, setSelectedYear] = useState(() => {
        const years = [];
        let id = 0;
        const currentYear = new Date().getFullYear();
        for (let year = 2020; year <= currentYear; year++) {
            years.push({ id, checked: year == (new Date().getFullYear()), value: year });
            id++;
        };
        return years;
    });
    const [searchTerm, setSearchTerm] = useState("");
    const [allCustomer, setAllCustomer] = useState([{ value: "All customer", id: 0, checked: true }]);
    const handleMonthChange = (id) => {
        if (id == -1) {
            const val = selectedMonth[0].checked;
            setSelectedMonth((pre) => {
                return pre.map((month) => {
                    return { ...month, checked: !val }
                })
            });
        }
        else {
            setSelectedMonth((pre) => pre.map(month => month.id == id ? { ...month, checked: !month.checked } : month))
        }
    };
    const handleYearChange = (id) => {
        console.log(id);
        setSelectedYear((pre) => pre.map((year) => year.id == id ? { ...year, checked: !year.checked } : year))
    };
    const handleCustomerChange = (id) => {
        setAllCustomer((pre) => pre.map(cus => cus.id == id ? { ...cus, checked: !cus.checked } : cus));
    };
    const handleCompanyChange = (id) => {
        setAllCustomer((pre) => pre.map((item) => item.companySiteId != id ? { ...item, checked: false } : item))
        return setAllCompany((pre) => pre.map((item) => item.id == id ? { ...item, checked: !item.checked } : item));
    }
    let decode;
    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                if (selectedMode == 0) {
                    const customerCompanyResponse = await getCompanies(token);
                    const getAllCustomerUsersResponse = await getAllUsers(token);
                    setAllCompany(() => {
                        return customerCompanyResponse.map((item) => {
                            return { ...item, value: item.companyName, id: item._id, checked: false };
                        })
                    })
                    setAllCustomer(() => {
                        return getAllCustomerUsersResponse.map((item) => {
                            return { ...item, value: `${item.firstName} ${item.lastName}`, checked: false, id: item._id }
                        });
                    })

                }
                else if (selectedMode == 1) {
                    const supplierCompanyResponse = await getAllSuppliers(token);
                    if (supplierCompanyResponse.status) {
                        const data = supplierCompanyResponse.data;
                        setAllCompany(() => {
                            return data?.map((item) => {
                                return { ...item, value: item.companyName, id: item._id, checked: false };
                            })
                        })
                    }
                }
            } catch (error) {
                toast.error("Unable To Fetch Data At The Moment");
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [selectedMode]);
    const filteredCustomers = allCustomer.filter(customer => {
        const selectedCompanyIds = allCompany.filter(item => item.checked).map((item) => item.id);
        if (selectedCompanyIds.length == 0) {
            return customer.value.toLowerCase().includes(searchTerm.toLowerCase())
        }
        return customer.value.toLowerCase().includes(searchTerm.toLowerCase()) && selectedCompanyIds.includes(customer.companySiteId)
    }
    );
    useEffect(() => {
        if (!token) {
            navigate("/login");
            return;
        }

        decode = jwt_decode(token);
        setUserData(decode);
        if (!decode) {
            navigate("/login");
            toast.error("Invalid Token found!");
        }
        if (decode && decode.userType != 'admin') {
            navigate('/');
        }
    }, []);
    const monthDropDownRef = useRef();
    const yearDropDownRef = useRef();
    const companyDropDownRef = useRef();
    const customerDropDownRef = useRef();

    const handleDropDownOutSideClick = (e) => {
        if (monthDropDownRef.current && yearDropDownRef.current && customerDropDownRef.current && companyDropDownRef.current && !monthDropDownRef.current.contains(e.target)
            && !yearDropDownRef.current.contains(e.target)
            && !companyDropDownRef.current.contains(e.target)
            && !customerDropDownRef.current.contains(e.target)
        ) {
            setIsMonthSelectorDropDownOn(false);
            setIsYearSelectorDropDownOn(false);
            setIsAllCustomerDropDownOn(false);
            setIsCompanyDropdownOn(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleDropDownOutSideClick);
        return () => document.removeEventListener("mousedown", handleDropDownOutSideClick);

    }, []);
    return (
        !loading ? <div className='w-full border select-none  border-red-500 min-h-screen px-10'>
            <h1 className='text-center mt-5 font-bold  text-2xl my-2'>Welcome {userData?.firstName}</h1>
            <div className='w-full flex items-center justify-center relative'>
                <div className={`absolute transition-all ${selectedMode == 0 ? "me-3 -rotate-90" : "ms-3 rotate-90"}`}>
                    <Arrow direction={selectedMode === 0 ? 'customer' : 'supplier'} />
                </div>
                <div
                    onClick={() => setSelectedMode(0)}
                    className={`h-10 w-1/5 flex border text-white items-center justify-center cursor-pointer transition-all duration-300 ${selectedMode === 0 ? "bg-primaryColor shadow-lg" : "bg-sky-600 border-sky-500"}`}
                >
                    Customer
                </div>
                <div
                    onClick={() => setSelectedMode(1)}
                    className={`h-10 w-1/5 flex border text-white items-center justify-center cursor-pointer transition-all duration-300 ${selectedMode === 1 ? 'bg-complementaryPrimaryColor shadow-lg' : "!border border-complementaryPrimaryColor bg-complementaryHoverColor"}`}
                >
                    Supplier
                </div>
            </div>


            <div>
                <div className='filters flex items-center justify-between flex-wrap w-fu my-2 '>
                    <div className='relative' ref={monthDropDownRef}>
                        <div onClick={() => setIsMonthSelectorDropDownOn((pre) => !pre)} className='flex items-center gap-x-2 p-2  bg-white '>
                            <label className='font-medium text-sm'>Select Month ({selectedMonth.reduce((pre, item) => pre + Number(item.checked), 0)})</label>
                            <DropDownIcon additionalClasses={`h-4 ${isMonthSelectorDropDownOn && 'rotate-180 transition-all'}`} />
                        </div>
                        {isMonthSelectorDropDownOn && <div className='absolute top-10 py-3 flex flex-col gap-2 z-10 w-full bg-white'>
                            {selectedMonth.map((month) => {
                                return <div class="flex " onClick={(e) => {
                                    e.preventDefault();
                                    handleMonthChange(month.id)
                                }}>
                                    <input
                                        checked={month.checked}
                                        id="checked-checkbox"
                                        type="checkbox"
                                        className="w-4 h-4  text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
                                    />
                                    <label htmlFor="checked-checkbox" class="ms-2 text-sm font-medium text-gray-">{month.value}</label>
                                </div>
                            })}
                        </div>}
                    </div>
                    <div className='relative' ref={yearDropDownRef}>
                        <div
                            onClick={(e) => {
                                e.preventDefault();  // This is fine here
                                setIsYearSelectorDropDownOn((prev) => !prev);
                            }}
                            className='flex items-center gap-x-2 p-2 bg-white'>
                            <label className='font-medium text-sm'>Select Year ({selectedYear.reduce((pre, item) => pre + Number(item.checked), 0)})</label>
                            <DropDownIcon additionalClasses={`h-4 ${isYearSelectorDropDownOn ? 'rotate-180 transition-all' : ''}`} />
                        </div>

                        {isYearSelectorDropDownOn && (
                            <div className='absolute top-10 py-3 flex flex-col gap-2 z-10 w-full bg-white'>
                                {selectedYear.map((year) => (
                                    <div key={year.id} className="flex">
                                        <input
                                            checked={year.checked} // Bind checked state properly
                                            onChange={() => handleYearChange(year.id)} // Remove e.preventDefault() here
                                            id={`year-${year.id}`}
                                            type="checkbox"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                        />
                                        <label htmlFor={`year-${year.id}`} className="ms-2 text-sm font-medium text-gray-900">
                                            {year.value}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>



                    <div className='relative' ref={companyDropDownRef}>
                        <div onClick={() => setIsCompanyDropdownOn(pre => !pre)} className='flex items-center gap-x-2 p-2 !bg-white '>
                            <label className='font-medium text-sm'>Select Company ({allCompany.reduce((pre, item) => pre + Number(item.checked), 0) || "All"})</label>
                            <DropDownIcon additionalClasses={`h-4 ${isCompanyDropdownOn && 'rotate-180 transition-all'}`} />
                        </div>
                        {isCompanyDropdownOn && <div className='absolute top-10 py-3 flex flex-col gap-2 z-10 w-full bg-white'>
                            {allCompany.map((company) => {
                                return (<div class="flex " onClick={(e) => {
                                    e.preventDefault();
                                    handleCompanyChange(company.id)
                                }}>
                                    <input
                                        checked={company.checked}
                                        id="checked-checkbox"
                                        type="checkbox"
                                        className="w-4 h-4  text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 "
                                    />
                                    <label htmlFor="checked-checkbox" class="ms-2 text-sm font-medium text-gray-">{company.value}</label>
                                </div>);
                            })}
                        </div>
                        }
                    </div>
                    {selectedMode == 0 && <div className='relative' ref={customerDropDownRef}>
                        <div
                            onClick={() => setIsAllCustomerDropDownOn(prev => !prev)}
                            className='flex items-center gap-x-2 p-2 bg-white cursor-pointer'>
                            <label className='font-medium text-sm'>Select Customer ({allCustomer.reduce((pre, item) => pre + Number(item.checked), 0) || "All"})</label>
                            <span className={`h-4 ${isCustomerDropDownOn ? 'rotate-180 transition-all' : ''}`}>
                                <DropDownIcon
                                    additionalClasses={`h-4`}
                                />
                            </span>
                        </div>

                        {isCustomerDropDownOn && (
                            <div className='absolute px-1 top-10 py-3 flex flex-col gap-2 z-10 w-full bg-white border'>
                                <input
                                    type="text"
                                    className="p-2 border border-gray-300 rounded-md w-full outline-none"
                                    placeholder="Search Customers"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />

                                <div className='flex px-2 w-max flex-col gap-2 max-h-48 overflow-y-auto'>
                                    {filteredCustomers.map((company) => (
                                        <div className="flex items-center" key={company.id} >
                                            <input
                                                type="checkbox"
                                                checked={company.checked}
                                                onChange={() => handleCustomerChange(company.id)}
                                                id={`company-${company.id}`}
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                            />
                                            <label htmlFor={`company-${company.id}`} className="ml-2 text-sm font-medium text-gray-700">
                                                {company.value}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>}
                </div>
            </div>
            {/* <div className='flex justify-start gap-x-2 '>
            <button className='h-10 bg-primaryColor px-5 text-white  py-2 flex items-center justify-center rounded'>Search </button>
            <button className='h-10 bg-red-500 px-5  text-white py-2 flex items-center justify-center rounded'>Reset </button>
        </div> */}
            {selectedMode == 0 && <AdminCustomerComponetNew
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                setCompany={setAllCompany}
                allCompany={allCompany}
                allCustomers={allCustomer}
                setAllCustomer={setAllCustomer}


            // setUser={}
            />}
            {selectedMode == 1 && <AdminSupplierComponentNew
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                setCompany={setAllCompany}
                allCompany={allCompany}

            />}
        </div> :
            <div className='w-full h-screen  flex items-center justify-center'>
                <LucidLoader additionalClass={'text-primaryColor'} />
            </div>
    )
}

export default AdminDashboardComponents(AdminDashboardNew)


const Arrow = ({ direction }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white" stroke-linecap="round" stroke-linejoin="round" ><path d="M13.73 4a2 2 0 0 0-3.46 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z" /></svg>

    );
};
